import { useTranslation } from 'react-i18next'
import Table from './Table'
import BreadCrumb from '~/components/BreadCrumb'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_DEFINITION,
    ADMINISTRATOR_DEFINITION_SERVICECENTER,
    ADMINISTRATOR_DEFINITION_SERVICECENTER_NEW,
} from '~/constants/Routes'
import PageName from '~/components/PageName'
import ListToolbar from '~/components/ListToolbar'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { selectSnackbar } from '~/store/snackbar/selector'
import { useSnackbar } from '~/hooks/useSnackbar'
import { unsetSnackbar } from '~/store/snackbar/slice'
import ComponentTooltip from '~/components/ComponentTooltip'
import usePermissions from '~/hooks/usePermission'

const ServiceCenter = () => {
    const { t } = useTranslation()
    const { permissions } = usePermissions()
    const snackbarData = useSelector(selectSnackbar)
    const { showSnackbar, snackbar } = useSnackbar()
    const dispatch = useDispatch()
    useEffect(() => {
        if (snackbarData) {
            showSnackbar(snackbarData.message, snackbarData.type)
        }
        return () => {
            dispatch(unsetSnackbar({}))
        }
    })

    const BreadComponents = [
        { route: ADMINISTRATOR, title: t(`breadCrumb.Administrator`) },
        {
            route: ADMINISTRATOR_DEFINITION,
            title: 'Definitions',
        },
        {
            route: ADMINISTRATOR_DEFINITION_SERVICECENTER,
            title: 'ServiceCenter',
        },
    ]

    const toolBarButtons = useMemo(() => {
        return [
            {
                button: 'create',
                navigateLink: ADMINISTRATOR_DEFINITION_SERVICECENTER_NEW,
                icon: <AddIcon />,
                isPermission: permissions.isSuperAdmin,
            },
        ]
    }, [permissions.isSuperAdmin])

    return (
        <>
            {snackbar}
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_DEFINITION_SERVICECENTER}
            >
                <div>
                    <PageName name={t(`breadCrumb.ServiceCenter`)} />
                </div>
            </ComponentTooltip>

            <ListToolbar toolBarButton={toolBarButtons} />
            <Table />
        </>
    )
}

export default ServiceCenter
