import { useServiceCenterListQuery } from '~/pages/ServiceCenter/query'
import { useUsersQuery } from '~/pages/User/query'
import { useMemo } from 'react'

const useTeamFormData = () => {
    const { data: serviceCenters } = useServiceCenterListQuery({
        menuOption: true,
    })

    const { data: users } = useUsersQuery({
        menuOption: true,
    })

    const serviceCenterList = useMemo(() => {
        if (serviceCenters) {
            const result = serviceCenters.data.map((serviceCenter) => ({
                name: serviceCenter.name,
                value: serviceCenter.id,
            }))

            return result
        }
        return []
    }, [serviceCenters])

    const usersList = useMemo(() => {
        if (users) {
            const result = users.data.map((user) => ({
                name: `${user.name}--- ${user.email}`,
                value: user.id,
            }))

            return result
        }
        return []
    }, [users])

    const InputFieldGroup = useMemo(() => {
        return [
            {
                name: 'name',
                componentId: 'team_form_name',
                isRequired: true,
            },
            {
                name: 'description',
                componentId: 'team_form_description',
            },
        ]
    }, [])

    const selectGroup = [
        {
            title: 'serviceCenter',
            name: 'service_center_id',
            listResource: serviceCenterList,
            componentId: 'team_form_service_center',
        },
        {
            title: 'contactPerson',
            name: 'contact_person_id',
            listResource: usersList,
            componentId: 'team_form_contact_person',
        },
    ]
    return { InputFieldGroup, selectGroup }
}

export default useTeamFormData
