import productApi from '~/api/productApi'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { removeEmptyValue } from '~/utils/helpers'

export const useComponentsLinkedToProductsQuery = (params) => {
    const { id } = useParams()
    return useQuery(
        ['components-linked-to-products', id, params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await productApi.getComponentsLinkedToProducts(
                id,
                p,
            )
            return { data: data.data, total: data.meta.total }
        },
        {},
    )
}

export const useComponentsNotLinkedQuery = (params) => {
    const { id } = useParams()
    return useQuery(
        ['components-not-linked-to-products', id, params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await productApi.getComponentsNotLinkToProducts(
                id,
                p,
            )
            return { data: data.data, total: data.meta.total }
        },
        {},
    )
}
