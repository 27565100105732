import propTypes from 'prop-types'
import { useMemo } from 'react'
import TableFilters from '~/components/TableFilters'

const OrderTableSearchAndFilter = ({
    search,
    searchHandler,
    onEnterSearch,
    setServiceCenter,
    serviceCenter,
    serviceCenterApi,
    isServiceCenterLoadingSuccess,
}) => {
    const serviceCenterData = useMemo(() => {
        if (isServiceCenterLoadingSuccess) {
            const result = serviceCenterApi?.map((item) => ({
                id: item?.name,
                name: item?.name,
            }))
            result.unshift({ id: '', name: 'All' })
            return result
        }
        return []
    }, [isServiceCenterLoadingSuccess, serviceCenterApi])

    const selectGroup = useMemo(() => {
        return [
            {
                label: 'Service Center',
                handleFunc: setServiceCenter,
                dataSet: serviceCenterData,
                value: serviceCenter,
            },
        ]
    }, [serviceCenter, serviceCenterData, setServiceCenter])

    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='order'
            page='order'
        />
    )
}

OrderTableSearchAndFilter.propTypes = {
    search: propTypes.string,
    searchHandler: propTypes.func,
    onEnterSearch: propTypes.func,
    setServiceCenter: propTypes.func,
    serviceCenter: propTypes.string,
    serviceCenterApi: propTypes.array,
    isServiceCenterLoadingSuccess: propTypes.bool,
}

export default OrderTableSearchAndFilter
