import { Typography } from '@mui/material'
import Loading from '~/components/Loading'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useGetUsersOfTeamQuery } from '../query'
import Member from './Member'

const TeamMember = () => {
    const { id } = useParams()
    const { t } = useTranslation()
    const { data: members, isLoading, isError } = useGetUsersOfTeamQuery(id)

    if (isLoading) {
        return <Loading />
    }

    if (isError) {
        return <Typography>{t('message.somethingWrong')}</Typography>
    }

    return <Member members={members} />
}

export default TeamMember
