export const headCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: false,
        label: 'id',
        orderable: true,
    },
    {
        id: 'ticket_id',
        numeric: true,
        disablePadding: false,
        label: 'ticket_id',
        orderable: true,
    },
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'name',
        orderable: true,
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'status',
        orderable: true,
    },
    {
        id: 'state',
        numeric: true,
        disablePadding: false,
        label: 'state',
        orderable: true,
    },
    {
        id: 'bom_state',
        numeric: true,
        disablePadding: false,
        label: 'bom_state',
        orderable: true,
    },
    {
        id: 'tracking_no',
        numeric: true,
        disablePadding: false,
        label: 'tracking_no',
        orderable: true,
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: 'action',
        orderable: false,
    },
]
