import { useQuery as useReactQuery } from 'react-query'

const useQuery = (key, fetchFunction, options = {}) => {
    return useReactQuery(key, fetchFunction, {
        ...options,
        retry: (failureCount, error) => {
            // If the status code is 403, don't retry
            if (error?.response?.status === 403 || options.retry === false) {
                return false
            }

            // Otherwise, retry up to 3 times
            return failureCount < 3
        },
    })
}

export default useQuery
