import { isValidCron } from 'cron-validator'
import { string } from 'yup'

export const email = (t = () => '', label) =>
    string()
        .max(255)
        .label(label)
        .email(t('validation.emailInvalid'))
        .matches(
            /^[A-Za-z0-9._%+-]+@rentcompany\.(nl|be)$/,
            t('validation.emailNotMatch'),
        )
        .required(t('validation.emailRequired'))

export const regularEmail = (t = () => '', label) =>
    string().max(255).label(label).email(t('validation.emailInvalid'))

export const phone = (t = () => '', label) =>
    string()
        .max(255)
        .label(label)
        .matches(
            /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[\s./0-9]*$/,
            t('validation.phoneInvalid'),
        )

export const ip = (t = () => '', label) =>
    string()
        .max(255)
        .label(label)
        .matches(
            /^(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/,
            t('validation.ipInvalid'),
        )

export const multipleIp = (t = () => '', label) => {
    const ipRegexSlug = `(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])`
    const regexSlug = new RegExp(`^${ipRegexSlug}(,${ipRegexSlug})*$`)
    return string()
        .max(255)
        .label(label)
        .matches(regexSlug, t('validation.multipleIpNotMatch'))
}

export const multipleEmail = (t = () => '', label) => {
    const emailRegex = "[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+"
    const regexSlug = new RegExp(`^${emailRegex}(;\\s*${emailRegex})*$`)
    return string()
        .max(255)
        .label(label)
        .matches(regexSlug, t('validation.multipleEmail'))
}

export const cron = (t = () => '', label) => {
    return string().test({
        test(value, ctx) {
            if (!isValidCron(value)) {
                return ctx.createError({ message: t('validation.cron') })
            }
            return true
        },
    })
}

export const selectStatement = (t = () => '', label) => {
    const regexStatement = /^SELECT/i
    return string()
        .trim()
        .label(label)
        .matches(regexStatement, t('validation.selectStatement'))
}
export const integer = (t = () => '', label) => {
    const regexStatement = /^([0-9]*)$/
    return string()
        .label(label)
        .matches(regexStatement, t('validation.integer'))
}

export const workbenchLocation = (t = () => '', label) => {
    return string()
        .max(255)
        .label(label)
        .matches(/^[A-Za-z0-9\s]+$/, t('validation.workbenchLocation'))
}
