import { deviceApi } from '~/api'
import { useMutation, useQueryClient } from 'react-query'

export const useExportSerialMutation = () => {
    return useMutation(
        ['serial_export'],
        async (payload) => {
            const { data } = await deviceApi.getSerialsExport(payload)
            return data
        },
        {
            onSuccess: (data) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `serial.csv`)
                document.body.appendChild(link)
                link.click()
            },
            onError: () => {},
            retry: false,
        },
    )
}

export const useExportSerialQueueMutation = () => {
    return useMutation(
        ['serialQueue_export'],
        async () => {
            const { data } = await deviceApi.getSerialQueueExport()
            return data
        },
        {
            onSuccess: (data) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `serial_queue.csv`)
                document.body.appendChild(link)
                link.click()
            },
            onError: () => {},
            retry: false,
        },
    )
}

export const useImportSerialQueueMutation = () => {
    const queryClient = useQueryClient()

    return useMutation(
        ['partcode_import'],
        async (file) => {
            const data = new FormData()
            data.append('file', file)
            const results = await deviceApi.getSerialsImport(data)
            return results
        },
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries('device_serial_overview')
            },
            onError: () => {},
            retry: false,
        },
    )
}

export const useAddSerialMutation = () => {
    return useMutation(
        ['addSerial'],
        async (payload) => {
            const { data } = await deviceApi.addSerial(payload)
            return data.data
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}
