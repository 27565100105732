import { Box, LinearProgress } from '@mui/material'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ErrorMessageInput from '~/components/ErrorMessageInput'
import Search from '~/components/Search/Search'
import StyledModal from '~/components/StyledModal'
import { useOrderQuery } from '../query'

const ScanOrderNumber = ({ setOpen }) => {
    const { t } = useTranslation()
    const [orderNr, setOrderNr] = useState(undefined)
    const { isLoading, isError } = useOrderQuery(orderNr, {
        enabled: !!orderNr,
    })

    const handleClose = () => {
        setOpen(false)
    }

    const handleEnterSearch = (event) => {
        if (
            event.key === 'Enter' &&
            event.target.value &&
            event.target.value !== ''
        ) {
            setOrderNr(event.target.value)
        }
    }

    const scanError = useMemo(() => {
        if (isError && !!orderNr) {
            return "Production order number doesn't exist"
        }
    }, [isError, orderNr])

    return (
        <StyledModal
            open={open}
            handleClose={() => handleClose()}
            stackStyles={{ width: 'auto' }}
        >
            <Search
                label={t('pages.production.orders.scanOderNumber')}
                autoFocus
                onKeyPress={handleEnterSearch}
            />
            {isLoading && (
                <Box sx={{ width: '50%' }}>
                    <LinearProgress />
                </Box>
            )}
            {scanError && <ErrorMessageInput>{scanError}</ErrorMessageInput>}
        </StyledModal>
    )
}

ScanOrderNumber.propTypes = {
    setOpen: PropTypes.func,
}

export default ScanOrderNumber
