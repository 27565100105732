import { Autocomplete, TextField } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

function AutoCompleteInput({ input, onUpdate, items = [], noValue, ...props }) {
    const handleChange = (event, newValue) => {
        if (onUpdate && newValue?.value) {
            onUpdate(newValue?.value)
        }
        input.onChange(newValue?.value ?? '')
    }

    const [value, setValue] = useState({})
    useEffect(() => {
        if (!items) return
        setValue(
            items?.find((item) => {
                return input.value === item.value
            }),
        )
    }, [input, items])

    const autoCompleteValue = useMemo(
        () => (noValue ? null : { value: value || null }),
        [noValue, value],
    )

    return (
        <Autocomplete
            {...input}
            {...autoCompleteValue}
            options={items}
            getOptionLabel={(option) => option?.name ?? ''}
            renderInput={(params) => <TextField {...params} />}
            onChange={handleChange}
            {...props}
        />
    )
}

AutoCompleteInput.propTypes = {
    input: PropTypes.object,
    onUpdate: PropTypes.func,
    items: PropTypes.array,
    noValue: PropTypes.bool,
}

export default AutoCompleteInput
