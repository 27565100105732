import {
    Divider,
    Table as MuiTable,
    TableBody as MuiTableBody,
    Paper,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'
import { ProductionOrderHeadCells } from '~/components/HeaderTable/ProductionOderHeadCells'
import PaginationTable from '~/components/PaginationTable'
import ReferencedEntities from '~/components/ReferencedEntities'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import { useServiceCenterQuery } from '~/pages/Dispatch/AddDispatch/query'
import { useOrdersListQuery } from '../query'
import OrderTableSearchAndFilter from './OrderTableSearchAndFilter'

const TableBody = ({ isLoading, results }) => {
    if (isLoading) {
        return <TableLoading colSpan={ProductionOrderHeadCells.length} />
    }

    return (
        <MuiTableBody>
            {results.length > 0 &&
                results.map((row, index) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row?.id}>
                            <TableCell>{row?.id}</TableCell>
                            <ReferencedEntities
                                isTableCell={true}
                                dataType={'production-order'}
                                dataIdentifier={row?.production_order_nr}
                                dataDisplay={row?.production_order_nr}
                            />
                            <ReferencedEntities
                                isTableCell={true}
                                dataType={'projects-id'}
                                dataIdentifier={row?.project_nr}
                                dataDisplay={row?.project_nr}
                            />
                            <ReferencedEntities
                                isTableCell={true}
                                dataType={'images-id'}
                                dataIdentifier={row?.production_image_id}
                                dataDisplay={row?.image_name}
                            />
                            <TableCell>{row?.qty}</TableCell>
                            <TableCell>
                                {row?.order_lines?.length ?? 0}
                            </TableCell>
                            <TableCell>{row?.service_center_name}</TableCell>
                            <TableCell>
                                <DateTimeLocale datetime={row?.created_at} />
                            </TableCell>
                            <TableCell>
                                <DateTimeLocale datetime={row?.updated_at} />
                            </TableCell>
                        </TableRow>
                    )
                })}
        </MuiTableBody>
    )
}

TableBody.propTypes = {
    isLoading: PropTypes.bool,
    results: PropTypes.arrayOf(PropTypes.object),
}

const OrdersTable = () => {
    const [serviceCenter, setServiceCenter] = useState('')
    const {
        data,
        results,
        isLoading,
        page,
        setPage,
        order,
        orderBy,
        search,
        searchHandler,
        onEnterSearch,
        changePageHandler,
        sortHandler,
    } = useTable({
        query: useOrdersListQuery,
        initialStates: {
            orderBy: 'desc',
        },
        queryAttributes: {
            serviceCenter,
        },
    })

    // HANDLE SERVICE CENTER
    const { data: serviceCenterApi, isSuccess: isServiceCenterLoadingSuccess } =
        useServiceCenterQuery()

    const refValue = useRef({
        search: '',
        serviceCenter: '',
    })

    useEffect(() => {
        if (serviceCenter !== refValue.current.serviceCenter) {
            setPage(1)
            refValue.current.serviceCenter = serviceCenter
        }
    }, [serviceCenter, setPage])

    return (
        <Paper>
            {isServiceCenterLoadingSuccess && (
                <OrderTableSearchAndFilter
                    search={search}
                    searchHandler={searchHandler}
                    onEnterSearch={onEnterSearch}
                    setServiceCenter={setServiceCenter}
                    serviceCenter={serviceCenter}
                    serviceCenterApi={serviceCenterApi}
                    isServiceCenterLoadingSuccess={
                        isServiceCenterLoadingSuccess
                    }
                />
            )}
            <Divider />
            <TableContainer
                sx={{ width: '100%' }}
                aria-labelledby='tableTitle'
                size='medium'
            >
                <MuiTable>
                    <TableHeader
                        headCells={ProductionOrderHeadCells}
                        localization={'pages.production.orders.'}
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />
                    <TableBody isLoading={isLoading} results={results} />
                </MuiTable>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                size='medium'
                handleChangePage={changePageHandler}
            />
        </Paper>
    )
}

export default OrdersTable
