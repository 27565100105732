import { FormControl, Grid, Stack, Typography } from '@mui/material'
import arrayMutators from 'final-form-arrays'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { number, object, string } from 'yup'
import InputField from '~/components/FinalForm/InputField'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import StyledModal from '~/components/StyledModal'
import SubmitButton from '~/components/SubmitButton'
import {
    PRODUCTION_ADD_ONS,
    PRODUCTION_ADD_ONS_DETAIL,
} from '~/constants/Routes'
import useFormValidationCallback from '~/hooks/useFormValidationCallback'
import { useImportAddonMutation } from '../mutation'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from '~/hooks/useSnackbar'

const AddAddOn = ({ setOpen }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const handleClose = () => {
        setOpen(false)
    }
    const { showSnackbar } = useSnackbar()

    const localization = 'pages.production.addons'
    const pagePath = PRODUCTION_ADD_ONS
    const schema = object().shape({
        name: string().required(),
        version: number().required().min(0.0).max(10.0),
    })

    const { mutate: importAddOnMutate } = useImportAddonMutation({
        showSnackbar,
    })
    const validate = useFormValidationCallback(schema)

    const submit = useCallback(
        (payload) => {
            importAddOnMutate(payload, {
                onSuccess: (data) => {
                    navigate(PRODUCTION_ADD_ONS_DETAIL.replace(':id', data.id))
                },
            })
        },
        [importAddOnMutate, navigate],
    )

    const onSubmit = useCallback(
        (values) => {
            submit({
                name: values.name,
                version: values.version,
                filename: values.filename,
            })
        },
        [submit],
    )

    return (
        <StyledModal
            open={open}
            handleClose={() => handleClose()}
            stackStyles={{ width: 'auto', borderRadius: '10px' }}
        >
            <Typography variant='h6' sx={{ marginTop: '10px' }}>
                Add AddOns
            </Typography>
            <Form
                validate={validate}
                onSubmit={onSubmit}
                mutators={{
                    ...arrayMutators,
                }}
                keepDirtyOnReinitialize
                render={({ handleSubmit }) => (
                    <FormBodyWrapper>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputField
                                        localization={localization}
                                        name={'name'}
                                        componentId={'name'}
                                        pagePath={pagePath}
                                        isRequired={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputField
                                        localization={localization}
                                        name={'version'}
                                        componentId={'version'}
                                        pagePath={pagePath}
                                        isRequired={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputField
                                        localization={localization}
                                        name={'filename'}
                                        componentId={'filename'}
                                        pagePath={pagePath}
                                        isRequired={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction='row-reverse' spacing={2}>
                                    <SubmitButton
                                        handleSubmit={handleSubmit}
                                        name={`${t('button.add')}`}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </FormBodyWrapper>
                )}
            />
        </StyledModal>
    )
}

AddAddOn.propTypes = {
    setOpen: PropTypes.func,
}

export default AddAddOn
