import { Box, Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ExternalLink from '~/components/ExternalLink'
import { capitalize } from '~/utils/helpers'
import PropTypes from 'prop-types'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const handleShowData = (item) => {
    if (item.dateTime) {
        return (
            <DateTimeLocale
                datetime={item.value}
                formatHour={item.dateTime && item.notshowHour}
            />
        )
    }
    if (item.boolean) {
        return capitalize((!!item.value).toString())
    }

    if (item.externalLink) {
        return (
            <Stack direction='row' spacing={2}>
                <ExternalLink
                    extLink={item.externalLink}
                    param={item.value}
                    tailParam={item.tailParam}
                    variant='body1'
                />
                {item.subValue && (
                    <Typography variant='primary' component='div'>
                        {`(${item.subValue})`}
                    </Typography>
                )}
            </Stack>
        )
    }
    return item.value ?? 'N/A'
}

const InformationData = ({ content }) => {
    const { t } = useTranslation()
    return (
        <Box
            sx={{
                width: '100%',
                bgcolor: 'background.paper',
            }}
        >
            <Box sx={{ my: 1, mx: 2 }}>
                <Grid container alignItems='center' spacing={3}>
                    {content.map((item, index) => (
                        <Grid item xs={6} md={4} key={index}>
                            <Typography variant='subtitle2' component='div'>
                                {t(`pages.device.serial.detail.${item.label}`)}
                            </Typography>
                            <Typography variant='primary' component='div'>
                                {handleShowData(item)}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    )
}

InformationData.propTypes = { content: PropTypes.arrayOf(PropTypes.object) }

export default InformationData
