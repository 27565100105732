import { useMutation, useQueryClient } from 'react-query'
import projectsApi from '~/api/production/projectsApi'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useAssignAddonToProjectMutation = ({ showSnackbar }) => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['assign-addon-to-project'],
        async (payload) => {
            const { data } = await projectsApi.assignAddonToProject(payload)
            return data.data
        },
        {
            onSuccess: (data) => {
                clientQuery.invalidateQueries('production-projects')
                clientQuery.invalidateQueries([
                    'production-project',
                    data?.project_nr,
                ])
                clientQuery.invalidateQueries([
                    'production-project-addons',
                    data?.id,
                ])
                showSnackbar('Successfully assign', 'success')
            },
            onError: (error) => {
                showSnackbar(parseApiErrorMessage(error), 'error')
            },
            retry: false,
        },
    )
}

export const useImportProjectMutation = ({ showSnackbar }) => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['import-project'],
        async (payload) => {
            const { data } = await projectsApi.importProject(payload)
            return data.data
        },
        {
            onSuccess: (data) => {
                showSnackbar('Successfully added', 'success')
                clientQuery.invalidateQueries('production-projects')
            },
            onError: (error) => {
                showSnackbar(parseApiErrorMessage(error), 'error')
            },
            retry: false,
        },
    )
}

export const useUpdateProjectMutation = ({ id, showSnackbar }) => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['update-project', id],
        async (payload) => {
            const { data } = await projectsApi.updateProject({
                id,
                payload,
            })
            return data.data
        },
        {
            onSuccess: () => {
                showSnackbar('Successfully updated', 'success')
                clientQuery.invalidateQueries('production-projects')
                clientQuery.invalidateQueries(['production-project', id])
            },
            onError: (error) => {
                showSnackbar(parseApiErrorMessage(error), 'error')
            },
            retry: false,
        },
    )
}

export const useDeleteProjectAddonMutation = ({ id, showSnackbar }) => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['delete-project-addon'],
        async (payload) => {
            const { data } = await projectsApi.deleteProjectAddon(payload)
            return data.data
        },
        {
            onSuccess: () => {
                showSnackbar('Successfully deleted', 'success')
                clientQuery.invalidateQueries(['production-project-addons', id])
            },
            onError: (error) => {
                showSnackbar(parseApiErrorMessage(error), 'error')
            },
            retry: false,
        },
    )
}

export const useUpdateProjectAddonMutation = ({ id, showSnackbar }) => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['update-project-addon'],
        async (payload) => {
            const { data } = await projectsApi.editProjectAddon(payload)
            return data.data
        },
        {
            onSuccess: () => {
                showSnackbar('Successfully updated', 'success')
                clientQuery.invalidateQueries(['production-project-addons', id])
            },
            onError: (error) => {
                showSnackbar(parseApiErrorMessage(error), 'error')
            },
            retry: false,
        },
    )
}
