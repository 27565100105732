import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

const useTable = ({
    query,
    initialStates = { order: 'asc', page: 1, orderBy: 'id', search: '' },
    queryAttributes = {},
}) => {
    const [order, setOrder] = useState(initialStates?.order ?? 'desc')
    const [page, setPage] = useState(initialStates?.page ?? 1)
    const [orderBy, setOrderBy] = useState(initialStates?.orderBy ?? 'id')
    const [search, setSearch] = useState(initialStates?.search)

    const { data, isLoading, isSuccess } = query({
        order,
        page,
        orderBy,
        search,
        ...queryAttributes,
    })

    const refValue = useRef({
        search: initialStates?.search,
    })

    useEffect(() => {
        if (search !== refValue.current.search) {
            setPage(1)
            refValue.current.search = search
        }
    }, [search])

    const results = useMemo(() => {
        return data?.data || []
    }, [data])

    const sortHandler = useCallback(
        (event, property) => {
            const isAsc = orderBy === property && order === 'asc'
            setOrder(isAsc ? 'desc' : 'asc')
            setOrderBy(property)
        },
        [order, orderBy],
    )

    const searchHandler = useMemo(() => {
        return debounce((event) => {
            if (!(event.target.value.length < 3)) {
                setSearch(event.target.value)
            }
        }, 500)
    }, [])

    const onEnterSearch = useCallback((event) => {
        if (event.key === 'Enter') {
            setSearch(event.target.value)
        }
    }, [])

    const changePageHandler = (_, value) => {
        setPage(value)
    }
    return {
        order,
        page,
        orderBy,
        search,
        setOrder,
        setPage,
        setOrderBy,
        setSearch,
        isLoading,
        isSuccess,
        data,
        results,
        sortHandler,
        searchHandler,
        onEnterSearch,
        changePageHandler,
    }
}

export default useTable
