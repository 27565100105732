import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { useEffect } from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import StyledRadio from '../../../Diagnostic/components/StyledRadio'
import TableCell from '../../../Diagnostic/components/TableCell'
import useStyles from '../../../Diagnostic/hooks/useStyles'
import { LOCALIZATION } from '../constants'
import Layout from './Section/Layout'
import StyledTableRow from './Table/StyledTableRow'
import PropTypes from 'prop-types'

const options = [`request_complete_care`, `request_return_to_depot`]

const DispatchInfoSection = ({
    show,
    values,
    setReturnToDepot,
    isInEditPage = false,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    useEffect(() => {
        setReturnToDepot(!!Number.parseInt(values.request_return_to_depot))
    }, [setReturnToDepot, values.request_return_to_depot])

    if (!show) return <></>

    return (
        <Layout>
            <Typography variant='h6' gutterBottom>
                {t(`${LOCALIZATION}.dispatch_info`)}
            </Typography>
            <Table>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <TableCell>{t(LOCALIZATION + `.options`)}</TableCell>
                        <TableCell>{t(LOCALIZATION + `.yes`)}</TableCell>
                        <TableCell>{t(LOCALIZATION + `.no`)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {options.map((item) => (
                        <StyledTableRow key={item}>
                            <TableCell>
                                <div>{t(`${LOCALIZATION}.${item}`)}</div>
                            </TableCell>
                            <CheckboxesCells
                                name={item}
                                {...{ values }}
                                disabled={isInEditPage}
                            />
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </Layout>
    )
}

DispatchInfoSection.propTypes = {
    show: PropTypes.bool,
    values: PropTypes.array,
    setReturnToDepot: PropTypes.func,
    isInEditPage: PropTypes.bool,
}

const CheckboxesCells = ({ name, values, disabled = false }) => {
    return (
        <>
            <TableCell>
                <Field
                    name={name}
                    type='radio'
                    value={1}
                    checked={parseInt(values[name]) === 1}
                    component={StyledRadio}
                    disabled={disabled}
                />
            </TableCell>
            <TableCell>
                <Field
                    name={name}
                    type='radio'
                    value={0}
                    checked={parseInt(values[name]) === 0}
                    component={StyledRadio}
                    disabled={disabled}
                />
            </TableCell>
        </>
    )
}

CheckboxesCells.propTypes = {
    name: PropTypes.string,
    values: PropTypes.array,
    disabled: PropTypes.bool,
}

export default DispatchInfoSection
