import {
    Autocomplete,
    FormControl,
    FormHelperText,
    TextField,
    Typography,
} from '@mui/material'
import ComponentTooltip from '~/components/ComponentTooltip'
import AsterisksRequiredField from '~/components/FormInput/AsterisksRequiredField'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Proptypes from 'prop-types'

function AutoCompleteField({
    nameSelect,
    localization,
    isRequired,
    input,
    onUpdate,
    meta,
    children,
    items = [],
    disabled,
    ...props
}) {
    const { t } = useTranslation()
    const hasErrors = meta.touched && meta.invalid
    const helperText = hasErrors && (meta.error || meta.submitError)
    const handleChange = (event, newValue) => {
        if (onUpdate) {
            onUpdate(newValue?.value ?? '')
        }
        input.onChange(newValue?.value ?? '')
    }

    const [value, setValue] = useState({})
    useEffect(() => {
        if (!items) return
        setValue(
            items?.find((item) => {
                return input.value === item.value
            }),
        )
    }, [input, items])

    const title = (
        <ComponentTooltip
            componentId={props.componentId}
            pagePath={props.pagePath}
        >
            <Typography>
                {t(`${localization}.${nameSelect}`)}
                {isRequired && <AsterisksRequiredField />}
            </Typography>
        </ComponentTooltip>
    )
    return (
        <FormControl sx={{ minWidth: 120 }} error={!!helperText}>
            {title}
            <Autocomplete
                {...input}
                disabled={disabled}
                value={value || ''}
                options={items}
                getOptionLabel={(option) => option?.name ?? ''}
                renderInput={(params) => <TextField {...params} />}
                onChange={handleChange}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    )
}

AutoCompleteField.propTypes = {
    nameSelect: Proptypes.string,
    localization: Proptypes.string,
    isRequired: Proptypes.bool,
    input: Proptypes.any,
    onUpdate: Proptypes.func,
    meta: Proptypes.object,
    children: Proptypes.element,
    items: Proptypes.array,
    disabled: Proptypes.bool,
    componentId: Proptypes.string,
    pagePath: Proptypes.string,
}

export default AutoCompleteField
