import { Divider, Paper, TableBody, TableRow } from '@mui/material'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import DataNotFound from '~/components/DataNotFound'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'
import { ProductColumnConfig } from '~/components/HeaderTable/ProductColumnConfig'
import PaginationTable from '~/components/PaginationTable'
import ReferencedEntities from '~/components/ReferencedEntities'
import SearchToolBar from '~/components/SearchToolBar'
import TableContainer from '~/components/Table/TableContainer'
import TableLoading from '~/components/Table/TableLoading'
import TableToolbarContainer from '~/components/Table/TableToolbarContainer'
import { TableCell } from '~/components/TableCell'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import { useProductsListQuery } from './query'

const TableContent = ({ results, isLoading }) => {
    const colSpan = useMemo(() => {
        return ProductColumnConfig.length
    }, [])

    if (isLoading) {
        return <TableLoading colSpan={colSpan} />
    }
    return (
        <TableBody>
            {results.length > 0 ? (
                results.map((row) => (
                    <TableRow hover tabIndex={-1} key={row.id}>
                        <ReferencedEntities
                            isTableCell={true}
                            dataType={'products-id'}
                            dataIdentifier={row.id}
                            dataDisplay={row.id}
                        />
                        <TableCell cellValue={row?.sku} />
                        <TableCell cellValue={row?.name} />
                        <TableCell cellValue={row?.code} />
                        <TableCell cellValue={row?.brands?.name} />
                        <TableCell cellValue={row?.created_by?.name} />
                        <TableCell cellValue={row?.updated_by?.name} />
                        <TableCell
                            cellValue={
                                <DateTimeLocale datetime={row.created_at} />
                            }
                        />
                    </TableRow>
                ))
            ) : (
                <DataNotFound colSpan={colSpan} />
            )}
        </TableBody>
    )
}

TableContent.propTypes = {
    results: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
}

const ProductTable = () => {
    const {
        order,
        page,
        orderBy,
        search,
        data,
        isLoading,
        results,
        sortHandler,
        searchHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({ query: useProductsListQuery })
    return (
        <Paper>
            <TableToolbarContainer>
                <SearchToolBar
                    filterSearch={search}
                    onFilterSearch={searchHandler}
                    placeholder={`nameOrSku`}
                    sx={{
                        width: '20%',
                    }}
                    onEnterSearch={onEnterSearch}
                />
            </TableToolbarContainer>
            <Divider />
            <TableContainer>
                <TableHeader
                    headCells={ProductColumnConfig}
                    localization={'pages.administrator.product.'}
                    onRequestSort={sortHandler}
                    order={order}
                    orderBy={orderBy}
                />
                <TableContent results={results} isLoading={isLoading} />
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}

export default ProductTable
