import regionApi from '~/api/regionApi'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { removeEmptyValue } from '~/utils/helpers'

export const useRegionsListQuery = (params) => {
    return useQuery(
        ['regions', params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await regionApi.getRegions(p)
            return { data: data.data, total: data.meta?.total }
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useDellTechDirectApiQuery = () => {
    return useQuery(
        ['dell-tech-direct'],
        async () => {
            const { data } = await regionApi.getDellTechDirectApi()
            return { data: data.data, total: data.meta?.total }
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useGetRegionDetailQuery = (id) => {
    return useQuery(
        ['region_detail', id],
        async () => {
            const { data } = await regionApi.getRegionDetail(id)
            return { data: data.data }
        },
        {
            onSuccess: (data) => {},
        },
    )
}

export const useServiceCenterRegionQuery = (params) => {
    const { id } = useParams()
    return useQuery(
        ['region_service_center', params, id],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await regionApi.getServiceCenterRegion(p, id)
            return { data: data.data, total: data.meta?.total }
        },
        {
            onSuccess: (data) => {},
            enabled: !!id,
        },
    )
}
