import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import ListToolbar from '~/components/ListToolbar'
import PageName from '~/components/PageName'
import { PART, PART_PRODUCT } from '~/constants/Routes'
import ProductTable from './ProductTable'

const Product = () => {
    const BreadComponents = [
        { route: PART, title: 'Parts' },
        {
            route: PART_PRODUCT,
            title: 'Products',
        },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={PART_PRODUCT}
            >
                <div>
                    <PageName name='Products' />
                </div>
            </ComponentTooltip>

            <ListToolbar />
            <ProductTable />
        </>
    )
}

export default Product
