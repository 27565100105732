import {
    Box,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { LoadingButton } from '~/components/Button'
import ComponentTooltip from '~/components/ComponentTooltip'
import { ISSUE_ISSUE_REPAIR } from '~/constants/Routes'
import { useDispatchPartsQuery } from '~/pages/Dispatch/DispatchDetail/PartsSession/query'
import PartCodeDisplayer from './components/PartCodeDisplayer'
import { useMappingPartCodeDispatch } from './mutate'
import {
    useCheckProductSkuQuery,
    usePartDispatchShipmentReportQuery,
} from './query'
import { selectComponentPartCodeSelected } from '~/store/partcode/selector'
import propTypes from 'prop-types'

const MappingPartCode = ({ dispatchId, setDispatchId }) => {
    const [partCodes, setPartCodes] = useState([])
    const [droppedPartCodes, setDroppedPartCodes] = useState([])
    const [mappingPair, setMappingPair] = useState([])
    const [sku, setSku] = useState('')
    const [partNumber, setPartNumber] = useState()
    const [skuError, setSkuError] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(null)

    const componentPartCodeSelected = useSelector(
        selectComponentPartCodeSelected,
    )

    const { data: dispatchShipmentReport, isSuccess } =
        usePartDispatchShipmentReportQuery(dispatchId)
    const { data: dispatchPartCodes, isSuccess: isSuccessDispatchPartCodes } =
        useDispatchPartsQuery({
            all_list: true,
            id: dispatchId,
        })

    useCheckProductSkuQuery(
        sku,
        setSku,
        partNumber,
        currentIndex,
        mappingPair,
        setMappingPair,
        setSkuError,
    )

    const { mutate: handleSaveMapping, isLoading: savingMapping } =
        useMappingPartCodeDispatch(
            dispatchId,
            setDispatchId,
            partCodes.length === 0,
        )

    useEffect(() => {
        if (isSuccess) {
            const mappingData = dispatchShipmentReport.map((item) => {
                return {
                    part_number: item.part,
                    part_code: item.code,
                    is_linked: item.is_linked,
                    sku: item.sku,
                    is_drop: null,
                    is_component_choose: false,
                    is_stored: item.sku.length > 0,
                }
            })

            setMappingPair(mappingData)
        }
    }, [dispatchShipmentReport, isSuccess])

    useEffect(() => {
        if (isSuccessDispatchPartCodes) {
            const partCodeList = dispatchPartCodes.data.map((item) => {
                return {
                    code: item.part_code,
                    tip: `${item.device_part_code?.part_code_name} / ${item.device_part_code?.part_code_type}`,
                }
            })
            setPartCodes(partCodeList)
        }
    }, [dispatchPartCodes, isSuccessDispatchPartCodes])

    useEffect(() => {
        if (Object.keys(componentPartCodeSelected).length !== 0) {
            const newArr = [...mappingPair]
            const newMappingPair = newArr.map((item) => {
                const componentPartNumber =
                    componentPartCodeSelected[item.part_number]
                if (componentPartNumber !== undefined) {
                    return {
                        ...item,
                        part_code: componentPartNumber,
                        is_component_choose: true,
                    }
                }
                return item
            })

            setMappingPair(newMappingPair)
        }
    }, [componentPartCodeSelected, mappingPair])

    const dropToTable = (e, part_number) => {
        e.preventDefault()

        const code = e.dataTransfer.getData('code').replace(/<[^>]*>?/gm, '')
        const newArr = [...mappingPair]
        const newMappingPair = newArr.map((item) => {
            if (item.part_number === part_number) {
                return {
                    ...item,
                    part_code: code,
                    is_drop: true,
                    is_linked: false,
                    is_component_choose: false,
                }
            }
            return item
        })

        setMappingPair(newMappingPair)

        const newPartCodeList = [...partCodes]
        setDroppedPartCodes([
            ...droppedPartCodes,
            ...newPartCodeList.filter((part) => part.code === code),
        ])
        setPartCodes(newPartCodeList.filter((part) => part.code !== code))
    }

    const dropToWaitingList = (e) => {
        e.preventDefault()

        const code = e.dataTransfer.getData('code')
        const newArr = [...mappingPair]
        const newMappingPair = newArr.map((item) => {
            if (item.part_code === code) {
                return {
                    ...item,
                    part_code: null,
                    is_drop: null,
                    is_linked: false,
                    is_component_choose: false,
                }
            }
            return item
        })

        setMappingPair(newMappingPair)

        const newPartCodeList = [...partCodes]
        if (newPartCodeList.findIndex((part) => part.code === code) === -1) {
            const backPartCode = [...droppedPartCodes]
                .filter((part) => part.code === code)
                .shift()
            const newDroppedPartCodes = [...droppedPartCodes].filter(
                (part) => part.code !== code,
            )
            newPartCodeList.push(backPartCode)
            setPartCodes(newPartCodeList)
            setDroppedPartCodes(newDroppedPartCodes)
        }
    }

    const allowDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const drag = (e) => {
        e.dataTransfer.setData('code', e.target.innerText)
    }

    const onBlurOfSku = (e, part_number, index) => {
        const skuValue = e.target.value
        const currentArr = [...mappingPair]
        if (skuValue) {
            const data = mappingPair.some(
                (v) => v?.sku?.toLowerCase() === skuValue.toLowerCase(),
            )

            if (data) {
                setSkuError(true)
                return
            }
            setSkuError(false)

            setSku(skuValue)
            setPartNumber(part_number)
            setCurrentIndex(index)

            const arrIncSku = currentArr.map((item) => {
                if (item.part_number === part_number) {
                    return {
                        ...item,
                        sku: skuValue,
                    }
                }
                return item
            })

            setMappingPair(arrIncSku)
        } else {
            const arrIncSku = currentArr.map((item) => {
                if (item.part_number === part_number) {
                    return {
                        ...item,
                        sku: skuValue,
                        error: false,
                    }
                }
                return item
            })
            setMappingPair(arrIncSku)
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
            }}
        >
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ border: '1px solid black' }}>
                                <ComponentTooltip
                                    componentId={`part_number_header`}
                                    pagePath={ISSUE_ISSUE_REPAIR}
                                    noStack
                                >
                                    Part number
                                </ComponentTooltip>
                            </TableCell>

                            <TableCell sx={{ border: '1px solid black' }}>
                                <ComponentTooltip
                                    componentId={`part_code_header`}
                                    pagePath={ISSUE_ISSUE_REPAIR}
                                    noStack
                                >
                                    Part code
                                </ComponentTooltip>
                            </TableCell>

                            <TableCell sx={{ border: '1px solid black' }}>
                                SKU
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mappingPair.map((item, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell
                                        sx={{
                                            border: '1px solid black',
                                            background: item?.is_linked
                                                ? '#C0C0C0'
                                                : '',
                                        }}
                                    >
                                        <Typography>
                                            {item?.part_number}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        id={`part_${index}`}
                                        sx={{
                                            border: '1px solid black',
                                            background: item?.is_linked
                                                ? '#C0C0C0'
                                                : '',
                                            pointerEvents: item?.is_linked
                                                ? 'none'
                                                : '',
                                        }}
                                        onDrop={(e) =>
                                            dropToTable(e, item?.part_number)
                                        }
                                        onDragOver={allowDrop}
                                    >
                                        {item?.part_code &&
                                            item?.is_component_choose ===
                                                false && (
                                                <div
                                                    id={`existed_${index}`}
                                                    draggable
                                                    onDragStart={(e) =>
                                                        drag(e, index)
                                                    }
                                                    onDrop={(e) => {
                                                        e.stopPropagation()
                                                    }}
                                                    style={{
                                                        cursor: 'grab',
                                                    }}
                                                >
                                                    {item?.part_code}
                                                </div>
                                            )}
                                        {item?.part_code === null &&
                                            item?.is_drop === null &&
                                            item?.is_linked === false &&
                                            partCodes.length === 0 && (
                                                <PartCodeDisplayer
                                                    partCode={
                                                        componentPartCodeSelected[
                                                            item.part_number
                                                        ]
                                                    }
                                                    partNumber={
                                                        item?.part_number
                                                    }
                                                />
                                            )}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: '1px solid black',
                                            background:
                                                item?.is_stored && item?.sku
                                                    ? '#C0C0C0'
                                                    : '',
                                        }}
                                    >
                                        {!item?.is_stored ? (
                                            <Stack>
                                                <TextField
                                                    onBlur={(e) =>
                                                        onBlurOfSku(
                                                            e,
                                                            item?.part_number,
                                                            index,
                                                        )
                                                    }
                                                    id='outlined-basic'
                                                    variant='outlined'
                                                    error={item?.error}
                                                />
                                            </Stack>
                                        ) : (
                                            <p>{item?.sku}</p>
                                        )}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box>
                <ComponentTooltip
                    componentId={`part_name_header`}
                    pagePath={ISSUE_ISSUE_REPAIR}
                >
                    <Typography variant='h6'>
                        Drag these parts to their corresponding part number:
                    </Typography>
                </ComponentTooltip>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        width: '100%',
                        height: 30,
                        padding: '30px 10px',
                        border: '1px solid black',
                        borderRadius: '5px',
                        alignItems: 'center',
                    }}
                    id='init_part_code_box'
                    onDrop={dropToWaitingList}
                    onDragOver={allowDrop}
                >
                    {partCodes.map((part, index) => {
                        return (
                            <div
                                id={`drag_${index}`}
                                key={index}
                                draggable
                                onDragStart={drag}
                                onDrop={(e) => {
                                    e.stopPropagation()
                                }}
                                style={{
                                    cursor: 'grab',
                                }}
                            >
                                <Tooltip arrow title={part.tip} placement='top'>
                                    <span>{part.code}</span>
                                </Tooltip>
                            </div>
                        )
                    })}
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    gap: '8px',
                }}
            >
                {mappingPair.length > 0 && (
                    <LoadingButton
                        label={partCodes.length > 0 ? 'Save' : 'Save & Close'}
                        disabled={skuError}
                        onClick={() => {
                            handleSaveMapping({ data: [...mappingPair] })
                        }}
                        loading={savingMapping}
                    />
                )}
                <LoadingButton
                    label='Cancel'
                    bgColor='#102F44'
                    onClick={() => {
                        setDispatchId(null)
                    }}
                />
            </Box>
        </Box>
    )
}

MappingPartCode.propTypes = {
    dispatchId: propTypes.number,
    setDispatchId: propTypes.func,
}

export default MappingPartCode
