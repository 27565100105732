import FormBodyWrapper from '~/components/FormBodyWrapper'
import useServiceCenterFormData from '../hooks/useServiceCenterFormData'
import ServiceCenterForm from '../ServiceCenterForm'
import PropTypes from 'prop-types'

const Body = ({ localization }) => {
    const { InputFieldGroup, selectGroupCreateForm, branchesList } =
        useServiceCenterFormData()

    return (
        <FormBodyWrapper>
            <ServiceCenterForm
                {...{ localization, InputFieldGroup }}
                selectGroup={selectGroupCreateForm}
                branchesList={branchesList}
            />
        </FormBodyWrapper>
    )
}

Body.propTypes = { localization: PropTypes.string }

export default Body
