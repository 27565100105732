export const selectDevice = (state) => state.device.device

export const selectFleetState = (state) => state.fleetState.fleetState

export const selectVendorState = (state) => state.vendorState.vendorState

export const selectSerialState = (state) => state.serialState.serialState

export const selectSearchSerial = (state) => state.searchSerial.searchSerial

export const SelectErrorMessage = (state) =>
    state.deviceErrorMessage.errorMessage
