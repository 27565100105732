import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useOrderQuery } from '../query'
import {
    PRODUCTION_IMAGES_DETAIL,
    PRODUCTION_ORDERS,
    PRODUCTION_SYSTEMIMAGE,
} from '~/constants/Routes'
import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import { Box, Grid, Link, Paper, Typography } from '@mui/material'
import OrderLineTable from './OrderLineTable'
import { LoadingButton } from '~/components/Button'
import { useEffect, useState } from 'react'
import ScanSerial from '../Scan/ScanSerial'
import OrderDetailTable from './OrderDetailTable'
import { useEnrollingSerialMutation } from '../mutation'

const OrderDetail = () => {
    const { production_order_nr } = useParams()
    const location = useLocation()
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const localization = 'pages.production.orders.'

    const { data, isLoading } = useOrderQuery(production_order_nr, location)

    const breadCrumbConfig = [
        { route: PRODUCTION_SYSTEMIMAGE, title: 'Production' },
        { route: PRODUCTION_ORDERS, title: 'Orders' },
        { route: '#', title: 'detail' },
    ]

    const isPossibleScan = data?.data?.qty > data?.orderlines
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        if (
            searchParams.has('autoopen') &&
            production_order_nr &&
            isPossibleScan
        ) {
            setOpen(true)
        }
    }, [isPossibleScan, location.search, production_order_nr])

    const { mutate: enrollSerialMutate, isLoading: enrollingSerialLoading } =
        useEnrollingSerialMutation()
    const enrollSerialState = () => {
        enrollSerialMutate({ id: data?.data?.id })
    }

    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            {/* {order_number} - {qty}x {product.sku} {product.name} - {organisation.name} ({organisation.external_id}) - {project_id} */}
            <PageName
                name={
                    data?.data?.production_order_nr +
                    ' - ' +
                    data?.data?.qty +
                    'x ' +
                    data?.data?.product?.sku +
                    ' ' +
                    data?.data?.product?.name +
                    ' - ' +
                    data?.data?.organisation?.name +
                    ' (' +
                    data?.data?.organisation?.external_id +
                    ') - ' +
                    data?.data?.production_project_id
                }
            />
            <Grid
                container
                spacing={3}
                sx={{
                    marginBottom: '20px',
                }}
            >
                <Grid item xs={12} md={6}>
                    <Paper
                        sx={{
                            py: 2,
                            px: 2,
                            height: '100%',
                            marginBottom: '2px',
                        }}
                    >
                        <Grid item xs={12} md={12}>
                            <Typography>
                                {t(localization + `details`)} :{' '}
                                {t(localization + `imageId`)} :{' '}
                                <Link
                                    component='button'
                                    variant='inherit'
                                    to='#'
                                    underline='hover'
                                    onClick={() =>
                                        navigate(
                                            PRODUCTION_IMAGES_DETAIL.replace(
                                                ':id',
                                                data?.data
                                                    ?.production_image_version
                                                    ?.production_image_id,
                                            ),
                                        )
                                    }
                                >
                                    {
                                        data?.data?.production_image_version
                                            ?.production_image_id
                                    }
                                </Link>
                            </Typography>

                            {data?.data && (
                                <OrderDetailTable
                                    isLoading={isLoading}
                                    data={data}
                                />
                            )}
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            marginRight: '10px',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <LoadingButton
                            label={t('pages.production.orders.enrollSerial')}
                            onClick={() => {
                                enrollSerialState()
                            }}
                            disabled={
                                enrollingSerialLoading ||
                                data?.data?.production_project?.production_image
                                    ?.type !== 'Chrome OS' ||
                                data?.data?.order_lines?.length === 0
                            }
                            style={{ marginRight: '10px' }}
                        />

                        <LoadingButton
                            label={t('pages.production.orders.scanSerial')}
                            onClick={() => {
                                setOpen(true)
                            }}
                            disabled={!isPossibleScan}
                        />
                    </Box>
                </Grid>
            </Grid>

            {data?.data && <OrderLineTable />}
            {open && (
                <ScanSerial
                    setOpen={setOpen}
                    data={data?.data}
                    orderLineQty={data?.orderlines}
                />
            )}
        </>
    )
}

export default OrderDetail
