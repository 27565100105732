import { Box, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useApprovalTokenMutation } from './mutation'
import { useTranslation } from 'react-i18next'

const ApprovedUser = () => {
    const { token } = useParams()
    const { t } = useTranslation()

    const { mutate } = useApprovalTokenMutation()

    useEffect(() => {
        mutate(token)
    }, [token, mutate])
    return (
        <Box sx={{ width: '100%' }}>
            <Typography variant='h2' gutterBottom component='div'>
                {t(`message.approved`)}
            </Typography>
        </Box>
    )
}

export default ApprovedUser
