import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {
    Autocomplete,
    Chip,
    FormControl,
    FormHelperText,
    TextField,
    Typography,
} from '@mui/material'
import ComponentTooltip from '~/components/ComponentTooltip'
import AsterisksRequiredField from '~/components/FormInput/AsterisksRequiredField'

function MultiSelectAutoCompleteField({
    nameSelect,
    localization,
    isRequired,
    input,
    onUpdate,
    meta,
    children,
    items,
    componentId,
    pagePath,
    dictItems,
    ...props
}) {
    const { t } = useTranslation()
    const hasErrors = meta.touched && meta.invalid
    const helperText = hasErrors && (meta.error || meta.submitError)

    // Ensure the initial values array is set correctly from input.value
    const [selectedItems, setSelectedItems] = useState([])
    useEffect(() => {
        if (input.value?.length) {
            setSelectedItems(
                items.filter((item) => input.value.includes(item.value)),
            )
        }
    }, [items, input.value])

    const handleChange = (event, newValue) => {
        setSelectedItems(newValue)
        let valueToEmit = newValue.map((item) => item.value)
        if (onUpdate) {
            valueToEmit = onUpdate(valueToEmit) || valueToEmit
        }
        input.onChange(valueToEmit)
    }

    const title = (
        <ComponentTooltip componentId={componentId} pagePath={pagePath}>
            <Typography>
                {t(`${localization}.${nameSelect}`)}
                {isRequired && <AsterisksRequiredField />}
            </Typography>
        </ComponentTooltip>
    )

    return (
        <FormControl sx={{ minWidth: 120 }} error={!!helperText}>
            {title}
            <Autocomplete
                multiple
                id='multi-select-autocomplete'
                options={items}
                value={selectedItems}
                getOptionLabel={(option) => option?.name ?? ''}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant='outlined'
                        error={!!helperText}
                    />
                )}
                onChange={handleChange}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            key={option.value}
                            variant='outlined'
                            label={option.name}
                            onClick={getTagProps({ index }).onDelete}
                            {...getTagProps({ index })}
                        />
                    ))
                }
                {...props}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    )
}

MultiSelectAutoCompleteField.propTypes = {
    nameSelect: PropTypes.string,
    localization: PropTypes.string,
    isRequired: PropTypes.bool,
    input: PropTypes.object,
    onUpdate: PropTypes.func,
    meta: PropTypes.object,
    children: PropTypes.object,
    items: PropTypes.array,
    componentId: PropTypes.string,
    pagePath: PropTypes.string,
    dictItems: PropTypes.object,
}

export default MultiSelectAutoCompleteField
