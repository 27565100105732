import {
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    Typography,
} from '@mui/material'
import CustomAvatar from '~/components/CustomAvatar'
import LabelStatusUser from '~/pages/User/LabelStatusUser'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectUserDetailInformation } from '~/store/user/selector'
import UserData from '../UserData'
import PropTypes from 'prop-types'
import UserTimezone from '../UserTimezone'

const localization = 'pages.user.userDetail'

const UserRole = ({ useRole }) => {
    return (
        <Grid item xs={12} height={30} pb={6}>
            <Typography gutterBottom variant='h5' component='div'>
                {useRole?.length > 0 && useRole[0].name}
            </Typography>
        </Grid>
    )
}

UserRole.propTypes = { useRole: PropTypes.array }

const UserInformation = () => {
    const { t } = useTranslation()
    const selectedUser = useSelector(selectUserDetailInformation)
    return (
        <Grid item xs={12} md={6} lg={4}>
            <Card>
                <CardContent>
                    <Box
                        sx={{
                            width: '100%',
                            bgcolor: 'background.paper',
                        }}
                    >
                        <Box sx={{ my: 3, mx: 2 }}>
                            <Grid container alignItems='center'>
                                <UserRole
                                    useRole={
                                        selectedUser.roles
                                            ? selectedUser.roles
                                            : null
                                    }
                                />
                                <Grid item xs={12} md={2}>
                                    <CustomAvatar
                                        url={selectedUser?.media?.preview_url}
                                        sx={{ width: 65, height: 65 }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <Typography
                                        gutterBottom
                                        variant='h6'
                                        component='div'
                                    >
                                        {selectedUser?.name}
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant='h8'
                                        component='div'
                                    >
                                        {selectedUser?.email}
                                    </Typography>
                                    <Typography variant='h8' component='div'>
                                        <LabelStatusUser
                                            status={
                                                selectedUser?.disabled
                                                    ? 'disabled'
                                                    : selectedUser?.status
                                            }
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Divider variant='middle' />
                        <Box sx={{ m: 2 }}>
                            <Typography gutterBottom variant='body1'>
                                {t(
                                    `${localization}.organizationalUnit`,
                                ).toUpperCase()}
                            </Typography>
                            <Typography gutterBottom variant='body1'>
                                {selectedUser?.email.substring(
                                    selectedUser?.email.indexOf('@') + 1,
                                )}
                            </Typography>
                        </Box>

                        <Divider variant='middle' />
                        <Box sx={{ m: 2 }}>
                            <Typography gutterBottom variant='body1'>
                                {t(`${localization}.workbench`).toUpperCase()}
                            </Typography>
                            <Typography gutterBottom variant='body1'>
                                {selectedUser?.workbench_name}
                            </Typography>
                        </Box>

                        <Divider variant='middle' />
                        <UserData {...{ localization }} />
                        <Divider variant='middle' />
                        <Box sx={{ m: 2 }}>
                            <Typography gutterBottom variant='body1'>
                                {t(
                                    `${localization}.resetPassword`,
                                ).toUpperCase()}
                            </Typography>
                            <Typography gutterBottom variant='body1'>
                                {t(`${localization}.updateUser`).toUpperCase()}
                            </Typography>
                        </Box>

                        <Divider variant='middle' />
                        <UserTimezone {...{ localization }} />
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default UserInformation
