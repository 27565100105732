import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { RadioGroup } from '@mui/material'

const FormRadioGroup = ({ children, defaultValue, style }) => {
    const defaultStyle = useMemo(() => {
        return {
            paddingLeft: '16px',
            paddingTop: '16px',
            marginBottom: '-10px',
        }
    }, [])
    return (
        <RadioGroup
            row
            aria-labelledby='demo-row-radio-buttons-group-label'
            defaultValue={defaultValue}
            style={style || defaultStyle}
        >
            {children}
        </RadioGroup>
    )
}

FormRadioGroup.propTypes = {
    children: PropTypes.node,
    defaultValue: PropTypes.any,
    style: PropTypes.object,
}

export default FormRadioGroup
