import TableFilters from '~/components/TableFilters'
import { useSuppliersQuery } from '~/pages/OrderConfirmation/query'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const DeliveryTableFilters = ({
    search,
    searchHandler,
    supplier,
    setSupplier,
    onEnterSearch,
}) => {
    const { data: supplierData, isSuccess: isSuccessSuppliers } =
        useSuppliersQuery()

    const supplierList = useMemo(() => {
        if (isSuccessSuppliers) {
            const result = [...supplierData?.data]
            result.unshift({ id: '', name: 'All' })
            return result
        }
        return []
    }, [isSuccessSuppliers, supplierData?.data])

    const selectGroup = useMemo(() => {
        return [
            {
                label: 'Supplier',
                handleFunc: setSupplier,
                dataSet: supplierList,
                value: supplier,
            },
        ]
    }, [setSupplier, supplier, supplierList])
    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='dispatches'
            page='delivery'
        />
    )
}
DeliveryTableFilters.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    supplier: PropTypes.string,
    setSupplier: PropTypes.func,
    onEnterSearch: PropTypes.func,
}
export default DeliveryTableFilters
