import { Grid, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useTicketDetailsQuery } from '~/pages/Diagnostic/query'
import TicketDetails from '~/pages/Diagnostic/TicketDetails'
import PageName from '~/components/PageName'
import { StyledPaper } from '~/components/StyledPaper'
import styled from '@emotion/styled'
import PicturesSection from '~/pages/Diagnostic/PicturesSection'
import { useIssueQuery } from '~/pages/Issue/query'
import IssueLog from '~/pages/Diagnostic/IssueLog'
import DeviceHistory from '~/pages/Diagnostic/DeviceHistory'
import DeviceImages from '~/pages/Diagnostic/DeviceImages'
import { LoadingButton } from '~/components/Button'
import ConfirmImageIssuePopup from './ConfirmImageIssuePopup'
import { useMemo, useState } from 'react'
import usePermissions from '~/hooks/usePermission'
import { useQueryClient } from 'react-query'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import {
    useProceedIncorrectImageIssueMutation,
    useCancelImageIssueMutation,
} from '../mutation'
import { parseApiErrorMessage } from '~/utils/helpers'
import { qcFormCriteria } from '../QualityCheck/Component/qcFormCriteria'
import { ISSUE_ISSUE } from '~/constants/Routes'

const IssueImages = () => {
    const localization = 'pages.issues.images'
    const { issueId } = useParams()
    const navigate = useNavigate()
    const { permissions } = usePermissions()
    const queryClient = useQueryClient()
    const { openSnackbar } = useDialogContext()
    const { t } = useTranslation()

    const { data: ticketData } = useTicketDetailsQuery(issueId)
    const { data: issueDetail } = useIssueQuery(issueId)
    const { mutate, isLoading } = useCancelImageIssueMutation()
    const { mutate: proceedIssue, isLoading: loadingProceed } =
        useProceedIncorrectImageIssueMutation()

    const [isOpenProceed, setIsOpenProceed] = useState(false)
    const [isOpenCancel, setIsOpenCancel] = useState(false)

    const isHiddenCancelButton = useMemo(() => {
        if (
            issueDetail?.data?.state !== 'WAIT_FOR_IMAGE' &&
            issueDetail?.data?.state !== 'IMAGED'
        ) {
            return true
        }
        return !permissions.issue.images.canCancelImageIssue
    }, [issueDetail?.data?.state, permissions.issue.images.canCancelImageIssue])

    const handleCancelIssue = () => {
        mutate(
            { issueId },
            {
                onSuccess: () => {
                    setIsOpenCancel(false)

                    queryClient.invalidateQueries(['issue', issueId])
                    queryClient.invalidateQueries(['issue_logs'])
                    navigate({ pathname: ISSUE_ISSUE })
                    openSnackbar({
                        message: t('message.issueCanceled'),
                        type: 'success',
                    })
                },
                onError: (e) => {
                    openSnackbar({
                        message: parseApiErrorMessage(e),
                        type: 'error',
                    })
                },
            },
        )
    }

    const isShowProceedButton = useMemo(() => {
        const issueState = issueDetail?.data?.state
        const issueStatus = issueDetail?.data?.status
        const canProceedImageIssue =
            permissions.issue.images.canProceedImageIssue

        const isIncorrectImage =
            issueState === 'BLOCKED' &&
            issueStatus === 'INCORRECT_IMAGE' &&
            canProceedImageIssue

        const canSkipImage =
            issueState === 'WAIT_FOR_IMAGE' && canProceedImageIssue

        return canSkipImage || isIncorrectImage
    }, [
        issueDetail?.data?.state,
        issueDetail?.data?.status,
        permissions.issue.images.canProceedImageIssue,
    ])

    const handleProceedIssue = () => {
        const defaultAgreeQc = qcFormCriteria
            .filter((criteria) => criteria.leftSide === false)
            .map((criteria) => criteria.name)
        proceedIssue(
            { issueId, defaultAgreeQc },
            {
                onSuccess: () => {
                    setIsOpenProceed(false)

                    queryClient.invalidateQueries(['issue', issueId])
                    queryClient.invalidateQueries(['issue_logs'])
                    const searchParams = new URLSearchParams(location.search)
                    searchParams.set('tag', '5')
                    const newSearchString = searchParams.toString()
                    navigate({
                        pathname: window.location.pathname,
                        search: newSearchString,
                    })
                    openSnackbar({
                        message: 'The issue is proceeded to QC!',
                        type: 'success',
                    })
                },
                onError: (e) => {
                    openSnackbar({
                        message: parseApiErrorMessage(e),
                        type: 'error',
                    })
                },
            },
        )
    }

    const Section = styled(Grid, {
        shouldForwardProp: (prop) => prop !== 'show',
    })(({ show }) => {
        return {
            ...(!show && {
                display: 'none',
            }),
        }
    })

    return (
        <>
            <Stack
                direction='row-reverse'
                sx={{ display: 'flex', columnGap: '10px' }}
            >
                {!isHiddenCancelButton && (
                    <LoadingButton
                        label={t(`${localization}.imagefailed`)}
                        onClick={() => {
                            setIsOpenCancel(true)
                        }}
                    ></LoadingButton>
                )}
                {isShowProceedButton && (
                    <LoadingButton
                        label={t(`${localization}.proceedIssue`)}
                        onClick={() => {
                            setIsOpenProceed(true)
                        }}
                    ></LoadingButton>
                )}
            </Stack>
            <PageName name={t(`${localization}.title`, { issueId })} />
            <Grid container spacing={3}>
                <Section item xs={12} md={6} show={true}>
                    <StyledPaper variant='outlined'>
                        <TicketDetails data={ticketData} />
                    </StyledPaper>
                </Section>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <StyledPaper variant='outlined'>
                                <PicturesSection
                                    isFinish={
                                        !!issueDetail.diagnostic?.finished_at
                                    }
                                    isInPictureStep={
                                        issueDetail.diagnostic?.current_step ===
                                        1
                                    }
                                    isTriage={true}
                                />
                            </StyledPaper>
                        </Grid>
                    </Grid>
                </Grid>
                <Section item xs={12} show={true}>
                    <StyledPaper variant='outlined'>
                        <DeviceImages title='lastImageBefore' />
                    </StyledPaper>
                </Section>
                <Section item xs={12} show={true}>
                    <StyledPaper variant='outlined'>
                        <DeviceImages
                            title='firstImageAfter'
                            isBeforeRepair={false}
                        />
                    </StyledPaper>
                </Section>
                <Section item xs={12} show={true}>
                    <StyledPaper variant='outlined'>
                        <IssueLog />
                    </StyledPaper>
                </Section>
                <Section item xs={12} show={true}>
                    <StyledPaper variant='outlined'>
                        <DeviceHistory />
                    </StyledPaper>
                </Section>
            </Grid>

            <ConfirmImageIssuePopup
                message={t(`${localization}.message`)}
                open={isOpenCancel}
                onClose={() => setIsOpenCancel(false)}
                confirmlHandling={handleCancelIssue}
                loading={isLoading}
            />
            <ConfirmImageIssuePopup
                message={t(`${localization}.messageProceed`)}
                open={isOpenProceed}
                onClose={() => setIsOpenProceed(false)}
                confirmlHandling={handleProceedIssue}
                loading={loadingProceed}
            />
        </>
    )
}

export default IssueImages
