export const headCells = [
    {
        id: 'issue',
        numeric: true,
        disablePadding: false,
        label: 'issue',
        orderable: true,
    },
    {
        id: 'serial',
        numeric: false,
        disablePadding: false,
        label: 'serial',
        orderable: true,
    },
    {
        id: 'state',
        numeric: true,
        disablePadding: false,
        label: 'state',
        orderable: true,
    },
    {
        id: 'region',
        numeric: true,
        disablePadding: false,
        label: 'region',
        orderable: true,
    },
    {
        id: 'service_center',
        numeric: true,
        disablePadding: false,
        label: 'service_center',
        orderable: true,
    },
    {
        id: 'created_at',
        numeric: true,
        disablePadding: false,
        label: 'created_at',
        orderable: true,
    },
]
