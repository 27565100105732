export const FleetHeadCells = [
    {
        id: 'vendor_name',
        numeric: false,
        disablePadding: false,
        label: 'Vendor',
        orderable: true,
    },
    {
        id: 'category',
        numeric: false,
        disablePadding: false,
        label: 'Category',
        orderable: true,
    },
    {
        id: 'device_name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
        orderable: true,
    },
    {
        id: 'device_tag_name',
        numeric: false,
        disablePadding: false,
        label: 'Tags',
        orderable: true,
    },
    {
        id: 'device_count',
        numeric: true,
        disablePadding: false,
        label: 'count',
        orderable: true,
    },
    {
        id: 'oldest',
        numeric: true,
        disablePadding: false,
        label: 'Oldest',
        orderable: true,
    },
    {
        id: 'newest',
        numeric: true,
        disablePadding: false,
        label: 'Newest',
        orderable: true,
    },
    {
        id: 'in_warranty',
        numeric: true,
        disablePadding: false,
        label: 'In_warranty',
        orderable: true,
    },
    {
        id: 'out_of_warranty',
        numeric: true,
        disablePadding: false,
        label: 'Out_of_warranty',
        orderable: true,
    },
    {
        id: 'expire_in_year_warranty',
        numeric: true,
        disablePadding: false,
        label: 'Expired_this_year',
        orderable: true,
    },
    {
        id: 'active',
        numeric: true,
        disablePadding: false,
        label: 'Active',
        orderable: true,
    },
    {
        id: 'stock',
        numeric: true,
        disablePadding: false,
        label: 'Stock',
        orderable: true,
    },
    {
        id: 'other',
        numeric: true,
        disablePadding: false,
        label: 'Other',
        orderable: true,
    },
]
