import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import App from './App'
import './i18n'
import './index.scss'
import React from 'react'

// Check if the Sentry DSN is provided
if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_NODE_ENV,
    })
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
)
