import {
    Box,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import StyledModal from '~/components/StyledModal'
import { usePartCodeQuery } from '../query'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import Search from '~/components/Search/Search'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '~/components/Button'
import { useUpdateComponentsPartCode } from '../mutate'
import { useQueryClient } from 'react-query'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import useTable from '~/hooks/useTable'
import PropTypes from 'prop-types'

const ConfirmPopup = ({
    onClose = () => {},
    closeAll = () => {},
    partCode,
    componentId,
}) => {
    const { mutate, isLoading } = useUpdateComponentsPartCode()
    const queryClient = useQueryClient()
    const { openSnackbar } = useDialogContext()

    const updateComponentPartCode = useCallback(() => {
        mutate(
            { id: componentId, payload: { part_code: partCode } },

            {
                onSuccess: () => {
                    onClose()
                    closeAll()
                    queryClient.invalidateQueries('components')
                    queryClient.invalidateQueries(
                        'components-linked-to-products',
                    )
                    openSnackbar({
                        message: 'Update success!',
                        type: 'success',
                    })
                },
                onError: (error) => {
                    let message = 'Update failed!'
                    if (error.response.data.message) {
                        message = error.response.data.message
                    } else if (error.message) {
                        message = error.message
                    }
                    openSnackbar({
                        message,
                        type: 'error',
                    })
                },
            },
        )
    }, [
        mutate,
        componentId,
        partCode,
        onClose,
        closeAll,
        queryClient,
        openSnackbar,
    ])

    return (
        <StyledModal
            open={!!partCode}
            handleClose={onClose}
            stackProps={{
                width: 600,
                gap: 4,
            }}
        >
            <Typography variant='h6'>
                Do you want to change this component part code to {partCode}?
            </Typography>
            <Stack direction='row' gap={2}>
                <LoadingButton
                    label='Confirm'
                    onClick={updateComponentPartCode}
                    loading={isLoading}
                />
                <LoadingButton
                    label='Cancel'
                    onClick={onClose}
                    loading={isLoading}
                />
            </Stack>
        </StyledModal>
    )
}

ConfirmPopup.propTypes = {
    onClose: PropTypes.func,
    closeAll: PropTypes.func,
    partCode: PropTypes.object,
    componentId: PropTypes.number,
}

const PartCodeList = ({ selectedPartCode, componentId, onClose }) => {
    const { t } = useTranslation()

    const {
        results: renderedPartCodes,
        data,
        isLoading,
        page,
        searchHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({ query: usePartCodeQuery })
    const [selectPartCode, setSelectPartCode] = useState(null)

    return (
        <>
            <Search
                label={t(`pages.dispatches.dispatch.add.filter_part_code`)}
                onChange={searchHandler}
                onKeyDown={onEnterSearch}
                sx={{ mb: 2 }}
            />
            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Part code</TableCell>
                            <TableCell>Part name</TableCell>
                            <TableCell align='center'>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    {isLoading ? (
                        <TableLoading colSpan={3} height='550px' />
                    ) : (
                        <TableBody>
                            {renderedPartCodes.map((partCode) => (
                                <TableRow key={partCode.code}>
                                    <TableCell>{partCode.code}</TableCell>
                                    <TableCell>{partCode.name}</TableCell>
                                    <TableCell align='center'>
                                        <LoadingButton
                                            onClick={() =>
                                                setSelectPartCode(partCode.code)
                                            }
                                            label='Select'
                                            disabled={
                                                selectedPartCode ===
                                                partCode.code
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                size='small'
                handleChangePage={changePageHandler}
            />
            <ConfirmPopup
                componentId={componentId}
                partCode={selectPartCode}
                onClose={() => setSelectPartCode(null)}
                closeAll={onClose}
            />
        </>
    )
}

PartCodeList.propTypes = {
    selectedPartCode: PropTypes.string,
    componentId: PropTypes.number,
    onClose: PropTypes.func,
}

const PartCodeEditPopup = ({ open, onClose, partCode, componentId }) => {
    return (
        <StyledModal
            open={open}
            handleClose={onClose}
            stackProps={{
                gap: 2,
                sx: {
                    minWidth: 800,
                    maxHeight: '95vh',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar-thumb': {
                        borderRadius: '10px',
                        backgroundColor: 'rgba(0, 0, 0, .5)',
                    },
                    '&::-webkit-scrollbar': {
                        width: '10px',
                    },
                },
            }}
        >
            <Box>Current part code: {partCode ?? 'NONE '}</Box>
            <Box sx={{ width: '100%' }}>
                <PartCodeList
                    selectedPartCode={partCode}
                    componentId={componentId}
                    onClose={onClose}
                />
            </Box>
        </StyledModal>
    )
}

PartCodeEditPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    partCode: PropTypes.string,
    componentId: PropTypes.number,
}

export default PartCodeEditPopup
