import { componentApi } from '~/api'
import { useMutation } from 'react-query'

export const useExportComponentMutation = () => {
    return useMutation(
        ['fleet_export'],
        async (payload) => {
            const { data } = await componentApi.getComponentsExport(payload)
            return data
        },
        {
            onSuccess: (data) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `component.csv`)
                document.body.appendChild(link)
                link.click()
            },
            onError: () => {},
            retry: false,
        },
    )
}

export const useSetComponentsPartNumber = () => {
    return useMutation(async (payload) => {
        return await componentApi.setComponentsPartNumber(payload)
    })
}

export const useUpdateComponentsPartCode = () => {
    return useMutation(async ({ id, payload }) => {
        return await componentApi.setComponentsPartCode(id, payload)
    })
}
