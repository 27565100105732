import { FormControl, Stack, TextField, Typography } from '@mui/material'
import { useMemo } from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { string } from 'yup'
import ComponentTooltip from '~/components/ComponentTooltip'
import AsterisksRequiredField from '~/components/FormInput/AsterisksRequiredField'
import { ISSUE_DISPATCH_ADD } from '~/constants/Routes'
import useFormFieldValidationSyncCallback from '../../../../hooks/useFormFieldValidationSyncCallback'
import { LOCALIZATION } from '../constants'
import Layout from './Section/Layout'
import PropTypes from 'prop-types'

const pagePath = ISSUE_DISPATCH_ADD

const TextareaComponent = ({ input, meta, label, ...props }) => {
    const hasErrors = meta.touched && meta.invalid
    const helperText = hasErrors && (meta.error || meta.submitError)
    return (
        <TextField
            {...input}
            {...props}
            multiline
            minRows={5}
            maxRows={10}
            error={hasErrors}
            helperText={helperText}
            variant={'outlined'}
        />
    )
}

TextareaComponent.propTypes = {
    input: PropTypes.any,
    meta: PropTypes.object,
    label: PropTypes.string,
}

const DescriptionSection = ({
    show,
    innerRef,
    selectedCauses,
    isEditing = false,
}) => {
    const { t } = useTranslation()

    const stringLengthValidator = useFormFieldValidationSyncCallback(
        string().required().max(2500),
    )

    const troubleShootingNotes = useMemo(() => {
        if (isEditing) {
            return ''
        }
        if (selectedCauses) {
            return selectedCauses
                .map((cause) => `${cause.name}: ${cause.notes}`)
                .join(`\n`)
        } else {
            return ''
        }
    }, [isEditing, selectedCauses])

    if (!show) return <></>

    return (
        <Layout>
            <Stack spacing={2} ref={innerRef}>
                <FormControl>
                    <ComponentTooltip
                        componentId={`dispatch_add_troubleshooting_note`}
                        pagePath={pagePath}
                    >
                        <Typography>
                            {t(`${LOCALIZATION}.troubleshootingNote`)}
                            <AsterisksRequiredField />
                        </Typography>
                    </ComponentTooltip>
                    <Field
                        name='troubleshooting_note'
                        component={TextareaComponent}
                        initialValue={troubleShootingNotes}
                        validate={(value) => stringLengthValidator(value)}
                        label={t(`${LOCALIZATION}.troubleshootingNote`)}
                    />
                </FormControl>
            </Stack>
        </Layout>
    )
}

DescriptionSection.propTypes = {
    show: PropTypes.bool,
    selectedCauses: PropTypes.arrayOf(PropTypes.object),
    innerRef: PropTypes.any,
    isEditing: PropTypes.bool,
}

DescriptionSection.defaultProps = {
    isEditing: false,
}

export default DescriptionSection
