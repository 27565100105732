import CameraSelection from '~/components/CameraSelection'
import { useState } from 'react'

export const useCameraSelection = () => {
    const storedCamera = JSON.parse(localStorage.getItem('SELECTED_CAMERA'))

    const [selectedCamera, setSelectedCamera] = useState(
        storedCamera?.deviceId ?? '',
    )

    const cameraList = JSON.parse(localStorage.getItem('CAMERA_LIST'))

    const handleCameraChange = (event) => {
        setSelectedCamera(event.target.value)

        const camera = cameraList.find(
            (object) => object.deviceId === event.target.value,
        )

        localStorage.setItem('SELECTED_CAMERA', JSON.stringify(camera))
    }

    const cameraSelection = (
        <CameraSelection
            value={selectedCamera}
            changeCamera={handleCameraChange}
            cameraList={cameraList}
        />
    )

    return { cameraSelection, selectedCamera }
}

export default useCameraSelection
