import TableFilters from '~/components/TableFilters'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useCauseModelsQuery } from '~/pages/Cause/query'
import { useTranslation } from 'react-i18next'

const CauseTableFilters = ({
    search,
    searchHandler,
    onEnterSearch,
    models,
    setModels,
}) => {
    const { data } = useCauseModelsQuery()
    const { t } = useTranslation()
    const dataModels = useMemo(() => {
        if (data?.data?.length > 0) {
            return data.data.map((item) => ({
                id: item.id,
                name:
                    item.name?.toUpperCase() || item.description?.toUpperCase(),
            }))
        }
        return []
    }, [data])

    const selectGroup = useMemo(() => {
        return [
            {
                label: t('breadCrumb.Models'),
                handleFunc: setModels,
                dataSet: dataModels,
                value: models,
                multiple: true,
                sm: 9,
            },
        ]
    }, [dataModels, models, setModels, t])

    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='causes'
            page='causes'
            selectMultiple
        />
    )
}

CauseTableFilters.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    onEnterSearch: PropTypes.func,
    models: PropTypes.arrayOf(PropTypes.number),
    setModels: PropTypes.func,
}

export default CauseTableFilters
