import { FormControl, Grid } from '@mui/material'
import AutoCompleteField from '~/components/FinalForm/AutoCompleteField'
import InputField from '~/components/FinalForm/InputField'
import SelectField from '~/components/FinalForm/SelectField'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import { ADMINISTRATOR_NAMING_TAG_RULE_CREATE } from '~/constants/Routes'
import { useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import {
    useComponentQuery,
    useGetAllNamingTagsQuery,
    useVendorQuery,
} from '../query'
import PropTypes from 'prop-types'

const pagePath = ADMINISTRATOR_NAMING_TAG_RULE_CREATE

const BodyForm = ({ localization, data, values }) => {
    const { t } = useTranslation()

    const { data: vendors } = useVendorQuery()
    const [selectedVendorId, setSelectedVendorId] = useState()
    const [deviceTagsList, setDeviceTagsList] = useState([])
    const { data: deviceTagsListResult } = useGetAllNamingTagsQuery()
    useEffect(() => {
        if (data) {
            setSelectedVendorId(data?.vendor_id)
            setDeviceTagsList(
                data?.tag_list?.map((tag) => {
                    return { name: tag.name, value: tag.id }
                }),
            )
        } else {
            setSelectedVendorId(vendors?.vendor_id)
            setDeviceTagsList(deviceTagsListResult)
        }
    }, [data, deviceTagsListResult, vendors?.vendor_id])
    const { data: components } = useComponentQuery(selectedVendorId)
    return (
        <FormBodyWrapper>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputField
                        localization={localization}
                        name={`device_name`}
                        componentId={`tag_rule_form_device_name`}
                        pagePath={pagePath}
                        isRequired='true'
                        placeholder={t(`${localization}.device_name`)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl sx={{ width: '100%' }}>
                        <Field
                            name='vendor_id'
                            component={SelectField}
                            items={vendors}
                            localization={localization}
                            componentId={`tag_rule_form_vendor`}
                            pagePath={pagePath}
                            nameSelect='vendor_id'
                            isRequired={true}
                            onUpdate={(vendorId) => {
                                setSelectedVendorId(vendorId)
                                values.component_id = null
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl sx={{ width: '100%' }}>
                        <Field
                            name='component_id'
                            component={AutoCompleteField}
                            items={components}
                            localization={localization}
                            componentId={`tag_rule_form_component`}
                            pagePath={pagePath}
                            nameSelect='component_id'
                            isRequired={true}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl sx={{ width: '100%' }}>
                        <Field
                            name='device_tag_id'
                            component={SelectField}
                            items={deviceTagsList}
                            localization={localization}
                            componentId={`tag_rule_form_device_tag`}
                            pagePath={pagePath}
                            nameSelect='device_tag_id'
                            isRequired={true}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </FormBodyWrapper>
    )
}

BodyForm.propTypes = {
    localization: PropTypes.string,
    data: PropTypes.object,
    values: PropTypes.object,
}

export default BodyForm
