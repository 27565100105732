import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { Button } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import PropTypes from 'prop-types'

const useStyle = makeStyles()((theme) => ({
    button: {
        textTransform: 'none',
        backgroundColor: '#a59f9f',
        '&:hover': {
            backgroundColor: 'gray',
            color: 'with',
        },
    },
}))
const ArrowButton = ({ title, ...props }) => {
    const { classes } = useStyle()

    return (
        <Button
            className={classes.button}
            variant='contained'
            size='small'
            {...props}
        >
            {title}
        </Button>
    )
}

ArrowButton.propTypes = { title: PropTypes.string }

export const ArrowNextButton = (props) => (
    <ArrowButton title={<ArrowForwardIosIcon />} {...props} />
)
export const ArrowPreviousButton = (props) => (
    <ArrowButton title={<ArrowBackIosNewIcon />} {...props} />
)
