export const headCells = [
    {
        id: 'part_code',
        numeric: false,
        disablePadding: false,
        label: 'part_code',
        orderable: false,
    },
    {
        id: 'qty',
        numeric: true,
        disablePadding: false,
        label: 'qty',
        orderable: false,
    },
    {
        id: 'stock_label',
        numeric: false,
        disablePadding: false,
        label: 'stock_label',
        orderable: false,
    },
    {
        id: 'part_number',
        numeric: false,
        disablePadding: false,
        label: 'part_number',
        orderable: false,
    },
    {
        id: 'sku',
        numeric: false,
        disablePadding: false,
        label: 'sku',
        orderable: false,
    },
    {
        id: 'price',
        numeric: true,
        disablePadding: false,
        label: 'price',
        orderable: false,
    },
    {
        id: 'dispatch_code',
        numeric: true,
        disablePadding: false,
        label: 'dispatch_code',
        orderable: false,
    },
    {
        id: 'dps_number',
        numeric: true,
        disablePadding: false,
        label: 'dps_number',
        orderable: false,
    },
    {
        id: 'stock_level',
        numeric: true,
        disablePadding: false,
        label: 'stock_level',
        orderable: false,
    },
    {
        id: 'wms_order',
        numeric: true,
        disablePadding: false,
        label: 'wms_order',
        orderable: false,
    },
]

export const PARTS_NOT_READY = 'PARTS_NOT_READY'
export const DISPATCH_FAILED = 'DISPATCH_FAILED'
