import PropTypes from 'prop-types'
import { Container, CircularProgress } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'

const useStyles = makeStyles()((theme) => ({
    container: {
        textAlign: 'center',
        marginTop: theme.spacing(1),
        height: 50,
    },
}))

const LoadingCircle = ({ className }) => {
    const { classes } = useStyles()
    return (
        <Container className={clsx(classes.container, className)}>
            <CircularProgress />
        </Container>
    )
}

LoadingCircle.defaultProps = {
    className: null,
}

LoadingCircle.propTypes = {
    className: PropTypes.string,
}

export default LoadingCircle
