import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import PageName from '~/components/PageName'
import { PART, PART_PARTCODE } from '~/constants/Routes'
import PartCodeTable from './PartCodeTable'

const BreadComponents = [
    { route: PART, title: 'Parts' },
    {
        route: PART_PARTCODE,
        title: 'PartCode',
    },
]

const PartCode = () => {
    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={PART_PARTCODE}
            >
                <div>
                    <PageName name={'Part codes'} />
                </div>
            </ComponentTooltip>

            <PartCodeTable />
        </>
    )
}
export default PartCode
