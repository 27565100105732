import axiosClient from '~/api/base'

class OrdersApi {
    getOrders = (params) => {
        return axiosClient.get('api/production/orders', { params })
    }

    getOrder = (production_order_nr) => {
        return axiosClient.get(`api/production/orders/${production_order_nr}`)
    }

    getOrderLine = (production_order_nr, params) => {
        return axiosClient.get(
            `api/production/order-lines/${production_order_nr}`,
            { params },
        )
    }

    deleteOrderLine = (payload) => {
        axiosClient.post('api/production/delete-order-line', {
            serial: payload.serial,
            production_order_id: payload.production_order_id,
        })
    }

    importOrderLine = (payload) => {
        return axiosClient.post('api/production/order-line', {
            serial: payload.serial,
            production_order_nr: payload.production_order_nr,
        })
    }

    enrollingSerial = (payload) => {
        return axiosClient.post(
            `api/production/order/${payload.id}/enrolling-serial`,
        )
    }
}

export default new OrdersApi()
