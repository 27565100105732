import ListToolbarWrapper from '~/components/ListToolbarWrapper'
import ToolBarButton from '~/components/ToolBarButton'
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import StyledModal from '~/components/StyledModal'
import ListComponentsNotLinked from './ListComponentsNotLinked'
const AddComponent = () => {
    const { t } = useTranslation()
    const [openListComponents, setOpenListComponents] = useState(false)
    const handleCloseLinkConponentPopup = () => {
        setOpenListComponents(false)
    }

    return (
        <>
            <ListToolbarWrapper>
                <ToolBarButton
                    icon={<AddIcon />}
                    name={t(`pages.components.addComponents`)}
                    handleAction={() => {
                        setOpenListComponents(true)
                    }}
                />
            </ListToolbarWrapper>

            <StyledModal
                open={openListComponents}
                handleClose={handleCloseLinkConponentPopup}
            >
                <ListComponentsNotLinked />
            </StyledModal>
        </>
    )
}

export default AddComponent
