import {
    IconButton,
    Grid,
    Typography,
    InputAdornment,
    FormControl,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import FormFieldWithAction from '~/components/FinalForm/FormFieldWithAction'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { useGenerateTokenMutate } from './mutation'
import { Field } from 'react-final-form'
import { useGetAllRolesQuery } from './query'
import { SERVICE_ACCOUNT_GUARD_NAME } from '~/constants/constants'
import { useMemo } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import InputField from '~/components/FinalForm/InputField'
import { ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_CREATE } from '~/constants/Routes'
import SelectField from '~/components/FinalForm/SelectField'
import ComponentTooltip from '~/components/ComponentTooltip'
import PropTypes from 'prop-types'

const pagePath = ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_CREATE

function Body({ data, localization, showSnackbar, tokenValue, setTokenValue }) {
    const { t } = useTranslation()
    const { mutate, isLoading } = useGenerateTokenMutate({
        showSnackbar,
        setTokenValue,
    })

    const { data: roles } = useGetAllRolesQuery(SERVICE_ACCOUNT_GUARD_NAME)
    const rolesList = useMemo(() => {
        if (roles) {
            return roles.map((role) => ({
                name: role.name,
                value: role.id,
            }))
        }
    }, [roles])
    const handleCopy = (e) => {
        if (tokenValue !== '*******') {
            navigator.clipboard.writeText(tokenValue)
            showSnackbar(`Token copied to clipboard!`, 'success')
        } else {
            showSnackbar(`The generated token is not allowed to copy!`, 'info')
        }
    }

    return (
        <FormBodyWrapper>
            <Grid container spacing={2}>
                {data ? (
                    <>
                        <FormFieldWithAction
                            label={
                                <ComponentTooltip
                                    componentId={`service_account_create_token`}
                                    pagePath={
                                        ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_CREATE
                                    }
                                >
                                    <Typography>
                                        {t(localization + `.token`)}
                                    </Typography>
                                </ComponentTooltip>
                            }
                            actionTitle={t(localization + `.generate`)}
                            handler={mutate}
                            actionIcon={<AutorenewIcon />}
                            isLoading={isLoading}
                            input={{
                                value: tokenValue,
                                disabled: true,
                                placeholder: `Click button to generate token.`,
                            }}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        onClick={handleCopy}
                                        edge='end'
                                    >
                                        <ContentCopyIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <Grid item xs={12}>
                            <InputField
                                localization={localization}
                                name='name'
                                componentId={`service_account_create_name`}
                                pagePath={pagePath}
                                isRequired={true}
                                placeholder={t(`${localization}.name`)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl sx={{ width: '100%' }}>
                                <Field
                                    name='role'
                                    component={SelectField}
                                    items={rolesList}
                                    localization={localization}
                                    nameSelect='role'
                                    componentId={`service_account_create_role`}
                                    pagePath={pagePath}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <InputField
                                localization={localization}
                                name='contact'
                                componentId={`service_account_create_contact`}
                                pagePath={pagePath}
                                placeholder={t(`${localization}.contact`)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputField
                                localization={localization}
                                name='contact_email'
                                componentId={`service_account_create_contact_email`}
                                pagePath={pagePath}
                                placeholder={t(`${localization}.contact_email`)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputField
                                localization={localization}
                                name='description'
                                componentId={`service_account_create_description`}
                                pagePath={pagePath}
                                placeholder={t(`${localization}.description`)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputField
                                localization={localization}
                                name='whitelist'
                                componentId={`service_account_create_whitelist`}
                                pagePath={pagePath}
                                placeholder={t(`${localization}.whitelist`)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputField
                                localization={localization}
                                name='revoked'
                                componentId={`service_account_create_revoked`}
                                pagePath={pagePath}
                                placeholder={t(`${localization}.revoked`)}
                                disabled
                            />
                        </Grid>
                    </>
                ) : (
                    <Typography>Data not found</Typography>
                )}
            </Grid>
        </FormBodyWrapper>
    )
}

Body.propTypes = {
    data: PropTypes.object,
    localization: PropTypes.string,
    showSnackbar: PropTypes.func,
    tokenValue: PropTypes.string,
    setTokenValue: PropTypes.func,
}

export default Body
