import { object, string } from 'yup'
import useFormValidationCallback from '~/hooks/useFormValidationCallback'

const useFormValidate = () => {
    const schema = object().shape({
        page_url: string().label(`Page URL`).required(),
        component_id: string().label(`Component`).required(),
        tooltip: object().label(`Tooltip`).required(),
    })
    return useFormValidationCallback(schema)
}
export default useFormValidate
