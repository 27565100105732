import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone'
import {
    Box,
    Checkbox,
    FormControlLabel,
    IconButton,
    ImageListItem,
    ImageListItemBar,
    ImageList as MuiImageList,
    Stack,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { formatDateTime } from '~/utils/helpers'
import {
    ArrowNextButton,
    ArrowPreviousButton,
} from '~/pages/Diagnostic/components/ArrowButtons'
import StyledModal from '../StyledModal'
import { LoadingButton } from '../Button'
import { selectFormatDateState } from '~/store/formatdate/selector'
import { useSelector } from 'react-redux'
import DateTimeLocale from '../DateTimeLocale/DateTimeLocale'

const ImageList = ({
    images,
    handleRemoveImage,
    isFinish,
    imageListProps,
    showCheckbox = false,
    showRemoveImage = true,
    showRemoveCurrentImage = true,
    handleChecked = () => {},
    isPictureFromQC = false,
}) => {
    const format = useSelector(selectFormatDateState)
    const [reviewImage, setReviewImage] = useState(null)

    const switchImage = useCallback(
        (direction) => {
            if (!reviewImage) {
                return
            }
            const currentReviewImageIndex = images.findIndex(
                (image) => image.id === reviewImage.id,
            )

            let newReviewImageIndex
            if (direction === 'next') {
                newReviewImageIndex = currentReviewImageIndex + 1
            } else if (direction === 'prev') {
                newReviewImageIndex = currentReviewImageIndex - 1
            }

            setReviewImage(
                images[newReviewImageIndex] ?? images[currentReviewImageIndex],
            )
        },
        [images, reviewImage],
    )

    return (
        <>
            <MuiImageList {...imageListProps}>
                {images?.length > 0 &&
                    images.map((image, key) => {
                        const pop = image.title
                            ? {
                                  title: image.title,
                                  subtitle: `${formatDateTime(
                                      image.created_at,
                                      format,
                                  )}`,
                              }
                            : {}

                        return (
                            <ImageListItem key={key}>
                                <ImageListItemBar
                                    {...{ ...pop }}
                                    sx={{
                                        background: image.title ? '' : 'none',
                                        '& .MuiImageListItemBar-titleWrap': {
                                            py: 0,
                                            paddingTop: '15px',
                                            textAlign: 'center',
                                        },
                                    }}
                                    position='top'
                                    subtitle={
                                        <DateTimeLocale
                                            datetime={image.created_at}
                                        />
                                    }
                                    actionIcon={
                                        isFinish ||
                                        !showRemoveImage ||
                                        image.removeable === false ||
                                        image.diagnosticPhoto ||
                                        (image.is_current &&
                                            !showRemoveCurrentImage) ? null : (
                                            <IconButton
                                                size='small'
                                                onClick={(e) => {
                                                    handleRemoveImage(
                                                        isPictureFromQC
                                                            ? image.id
                                                            : image,
                                                    )
                                                    e.stopPropagation()
                                                }}
                                                sx={{
                                                    paddingTop: '0px',
                                                    top: '5px',
                                                }}
                                            >
                                                <HighlightOffTwoToneIcon
                                                    sx={{ color: 'red' }}
                                                />
                                            </IconButton>
                                        )
                                    }
                                    onClick={() => {
                                        setReviewImage(image)
                                    }}
                                />
                                <img
                                    src={image.src ?? image.preview_url}
                                    alt=''
                                    loading='lazy'
                                    onClick={() => {
                                        setReviewImage(image)
                                    }}
                                />
                                {showCheckbox && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={image.checked}
                                                onClick={(e) => {
                                                    if (
                                                        !(
                                                            image.removeable ===
                                                            false
                                                        )
                                                    ) {
                                                        handleChecked(
                                                            e.target.checked,
                                                            image,
                                                        )
                                                    }
                                                }}
                                                disabled={
                                                    image.removeable === false
                                                }
                                            />
                                        }
                                        label={
                                            <Box component='div' fontSize={12}>
                                                Add to Dispatch
                                            </Box>
                                        }
                                    />
                                )}
                            </ImageListItem>
                        )
                    })}
            </MuiImageList>
            <StyledModal
                open={!!reviewImage}
                handleClose={() => setReviewImage(false)}
                stackProps={{
                    width: 'auto',
                    sx: {
                        borderRadius: 5,
                    },
                }}
            >
                <Box
                    position='relative'
                    sx={{
                        minHeight: 400,
                        minWidth: 600,
                    }}
                >
                    <ArrowPreviousButton
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '1%',
                        }}
                        onClick={() => switchImage('prev')}
                    />
                    <img
                        src={reviewImage?.src ?? reviewImage?.preview_url}
                        alt=''
                        loading='lazy'
                    />
                    <ArrowNextButton
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '93%',
                        }}
                        onClick={() => switchImage('next')}
                    />

                    <Stack
                        direction='row'
                        gap={1}
                        sx={{ marginTop: '10px', justifyContent: 'center' }}
                    >
                        <LoadingButton
                            label='Close'
                            onClick={() => setReviewImage(false)}
                        />
                    </Stack>
                </Box>
            </StyledModal>
        </>
    )
}
ImageList.propTypes = {
    images: PropTypes.array.isRequired,
    handleRemoveImage: PropTypes.func,
    isFinish: PropTypes.bool,
    imageListProps: PropTypes.object,
    showCheckbox: PropTypes.bool,
    showRemoveImage: PropTypes.bool,
    showRemoveCurrentImage: PropTypes.bool,
    handleChecked: PropTypes.func,
    isPictureFromQC: PropTypes.bool,
}
export default ImageList
