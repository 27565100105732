export const DellStockColumnConfig = [
    {
        id: 'id',
        label: 'id',
        orderable: true,
    },
    {
        id: 'depot',
        label: 'depot',
        orderable: true,
    },
    {
        id: 'depot_name',
        label: 'depot_name',
        orderable: true,
    },
    {
        id: 'owner',
        label: 'owner',
        orderable: true,
    },
    {
        id: 'part_number',
        label: 'part_number',
        orderable: true,
    },
    {
        id: 'part_description',
        label: 'part_description',
        orderable: true,
    },
    {
        id: 'name',
        label: 'commodity',
        orderable: true,
    },
    {
        id: 'qty',
        label: 'available',
        orderable: true,
    },
    {
        id: 'price_usd',
        label: 'price_usd',
        orderable: true,
    },
    {
        id: 'price_eur',
        label: 'price_eur',
        orderable: true,
    },
    {
        id: 'price_gbp',
        label: 'price_gbp',
        orderable: true,
    },
    {
        id: 'timestamp',
        label: 'timestamp',
        orderable: true,
    },
]
