import axiosClient from '~/api/base'

class RegionApi {
    getRegions = (params) => {
        return axiosClient.get('api/definition/regions', {
            params,
        })
    }

    createRegion = (payload) => {
        return axiosClient.post('api/definition/regions', payload)
    }

    getRegionDetail = (id) => axiosClient.get(`api/definition/regions/${id}`)

    getServiceCenterRegion = (params, id) => {
        return axiosClient.get(`api/definition/regions/${id}/service-centers`, {
            params,
        })
    }

    updateRegion = (id, payload) => {
        return axiosClient.put(`api/definition/regions/${id}`, payload)
    }

    getDellTechDirectApi = () => {
        return axiosClient.get('api/dell-dispatch/configurations')
    }
}
export default new RegionApi()
