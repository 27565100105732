import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import PageName from '~/components/PageName'
import { FLEET_MODEL } from '~/constants/Routes'
import Table from './components/Table'
import { breadCrumbs } from './constants'

const ModelOverview = () => {
    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbs} />
            <ComponentTooltip componentId={`page_title`} pagePath={FLEET_MODEL}>
                <div>
                    <PageName name='Models' />
                </div>
            </ComponentTooltip>
            <Table />
        </>
    )
}
export default ModelOverview
