import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import Loading from '~/components/Loading'
import PageName from '~/components/PageName'
import { USER_SETTING } from '~/constants/Routes'
import { useParams } from 'react-router-dom'
import { useUserDetailQuery } from './query'
import Setting from './Setting'
import { route } from '~/utils/helpers'

const UserSetting = () => {
    const { id } = useParams()
    const BreadComponents = [
        { route: route(USER_SETTING, { id }), title: 'settingManagement' },
    ]

    const { data: user, isLoading } = useUserDetailQuery(id)
    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={route(USER_SETTING, { id })}
            >
                <div>
                    <PageName name='Setting and Management' />
                </div>
            </ComponentTooltip>
            {isLoading ? <Loading /> : <Setting user={user} />}
        </>
    )
}

export default UserSetting
