import {
    Box,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { LoadingButton } from '~/components/Button'
import PaginationTable from '~/components/PaginationTable'
import Search from '~/components/Search/Search'
import StyledModal from '~/components/StyledModal'
import TableLoading from '~/components/Table/TableLoading'
import useTable from '~/hooks/useTable'
import { partCodeSelected } from '~/store/partcode/slice'
import { useGetPartcodeWithVoteQuery } from '../query'
import Proptypes from 'prop-types'

const ConfirmPopup = ({
    onClose = () => {},
    closeAll = () => {},
    partCode,
    partNumber,
}) => {
    const dispatch = useDispatch()
    const updateComponentPartCode = useCallback(() => {
        dispatch(partCodeSelected({ part_number: partNumber, code: partCode }))
        onClose()
        closeAll()
    }, [dispatch, partNumber, partCode, onClose, closeAll])

    return (
        <StyledModal
            open={!!partCode}
            handleClose={onClose}
            stackProps={{
                width: 600,
                gap: 4,
            }}
        >
            <Typography variant='h6'>
                Do you want to change this component part code to {partCode}?
            </Typography>
            <Stack direction='row' gap={2}>
                <LoadingButton
                    label='Confirm'
                    onClick={updateComponentPartCode}
                />
                <LoadingButton label='Cancel' onClick={onClose} />
            </Stack>
        </StyledModal>
    )
}

const PartCodeList = ({ selectedPartCode, onClose, partNumber }) => {
    const { t } = useTranslation()

    const {
        results: renderedPartCodes,
        data,
        isLoading,
        page,
        searchHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({ query: useGetPartcodeWithVoteQuery })
    const [selectPartCode, setSelectPartCode] = useState(null)

    return (
        <>
            <Search
                label={t(`pages.dispatches.dispatch.add.filter_part_code`)}
                onChange={searchHandler}
                onKeyDown={onEnterSearch}
                sx={{ mb: 2 }}
            />
            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Part code</TableCell>
                            <TableCell>Part name</TableCell>
                            <TableCell align='center'>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    {isLoading ? (
                        <TableLoading colSpan={3} height='550px' />
                    ) : (
                        <TableBody>
                            {renderedPartCodes.map((partCode) => {
                                const votePlural =
                                    partCode?.component_votes.length === 1
                                        ? ' vote '
                                        : ' votes '
                                return (
                                    <>
                                        <TableRow key={partCode.code}>
                                            <TableCell>
                                                {partCode.code}
                                            </TableCell>
                                            <TableCell>
                                                {partCode.name}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    maxWidth: '90px',
                                                }}
                                            >
                                                <>
                                                    <LoadingButton
                                                        onClick={() =>
                                                            setSelectPartCode(
                                                                partCode.code,
                                                            )
                                                        }
                                                        label='Select'
                                                        disabled={
                                                            selectedPartCode ===
                                                            partCode.code
                                                        }
                                                    />
                                                    {partCode?.component_votes
                                                        .length > 0
                                                        ? ' ' +
                                                          partCode
                                                              ?.component_votes
                                                              .length +
                                                          votePlural
                                                        : ''}
                                                </>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                )
                            })}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                size='small'
                handleChangePage={changePageHandler}
            />
            <ConfirmPopup
                partCode={selectPartCode}
                onClose={() => setSelectPartCode(null)}
                closeAll={onClose}
                partNumber={partNumber}
            />
        </>
    )
}

const PartCodeEditPopup = ({ open, onClose, partCode, partNumber }) => {
    return (
        <StyledModal
            open={open}
            handleClose={onClose}
            stackProps={{
                width: 800,
                gap: 2,
            }}
        >
            <Box>Current part code: {partCode ?? 'NONE '}</Box>
            <Box sx={{ width: '100%' }}>
                <PartCodeList
                    selectedPartCode={partCode}
                    partNumber={partNumber}
                    onClose={onClose}
                    key={partCode}
                />
            </Box>
        </StyledModal>
    )
}

ConfirmPopup.propTypes = {
    partCode: Proptypes.string,
    partNumber: Proptypes.string.isRequired,
    onClose: Proptypes.func,
    closeAll: Proptypes.func,
}

PartCodeList.propTypes = {
    selectedPartCode: Proptypes.string,
    onClose: Proptypes.func,
    partNumber: Proptypes.string.isRequired,
}

PartCodeEditPopup.propTypes = {
    open: Proptypes.bool,
    onClose: Proptypes.func,
    partCode: Proptypes.string,
    partNumber: Proptypes.string.isRequired,
}

export default PartCodeEditPopup
