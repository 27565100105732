import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import {
    ISSUE_ISSUE,
    ISSUE_RACKSCAN_SCAN,
    ISSUE_RACKSCAN_SCAN_NEW,
    ISSUE_RACKSCAN_TICKET,
} from '~/constants/Routes'
import { useMemo } from 'react'
import AddIcon from '@mui/icons-material/Add'
import ListToolbar from '~/components/ListToolbar'
import Table from './Table'
import ComponentTooltip from '~/components/ComponentTooltip'
import usePermissions from '~/hooks/usePermission'

const Scan = () => {
    const { permissions } = usePermissions()

    const BreadComponents = [
        { route: ISSUE_ISSUE, title: 'Issues' },
        { route: ISSUE_RACKSCAN_TICKET, title: 'rackScans' },
        {
            route: '#',
            title: 'scan',
        },
    ]

    const toolBarButtons = useMemo(() => {
        return [
            {
                button: 'create',
                navigateLink: ISSUE_RACKSCAN_SCAN_NEW,
                icon: <AddIcon />,
                isPermission: permissions.issue.rackScan.canCreateScan,
            },
        ]
    }, [permissions.issue.rackScan.canCreateScan])
    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ISSUE_RACKSCAN_SCAN}
            >
                <div>
                    <PageName name={'Scans'} />
                </div>
            </ComponentTooltip>
            <ListToolbar toolBarButton={toolBarButtons} />
            <Table />
        </>
    )
}
export default Scan
