import { useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_DATASCHEDULER,
    ADMINISTRATOR_DATASCHEDULER_DETAIL,
    ADMINISTRATOR_DATASCHEDULER_NEW,
    ADMINISTRATOR_DEFINITION,
    ADMINISTRATOR_DEFINITION_CAUSE,
    ADMINISTRATOR_DEFINITION_CAUSE_EDIT,
    ADMINISTRATOR_DEFINITION_CAUSE_NEW,
    ADMINISTRATOR_DEFINITION_REGIONS,
    ADMINISTRATOR_DEFINITION_REGIONS_DETAIL,
    ADMINISTRATOR_DEFINITION_REGIONS_EDIT,
    ADMINISTRATOR_DEFINITION_REGIONS_NEW,
    ADMINISTRATOR_DEFINITION_SERVICECENTER,
    ADMINISTRATOR_DEFINITION_SERVICECENTER_DETAIL,
    ADMINISTRATOR_DEFINITION_SERVICECENTER_EDIT,
    ADMINISTRATOR_DEFINITION_SERVICECENTER_NEW,
    ADMINISTRATOR_DEFINITION_TEAM,
    ADMINISTRATOR_DEFINITION_TEAM_DETAIL,
    ADMINISTRATOR_DEFINITION_TEAM_EDIT,
    ADMINISTRATOR_DEFINITION_TEAM_NEW,
    ADMINISTRATOR_DEFINITION_WORKBENCH,
    ADMINISTRATOR_DEFINITION_WORKBENCH_DETAIL,
    ADMINISTRATOR_DEFINITION_WORKBENCH_NEW,
    ADMINISTRATOR_NAMING_TAG,
    ADMINISTRATOR_NAMING_TAG_CREATE,
    ADMINISTRATOR_NAMING_TAG_DETAIL,
    ADMINISTRATOR_NAMING_TAG_RULE_CREATE,
    ADMINISTRATOR_NAMING_TAG_RULE_DETAIL,
    ADMINISTRATOR_ROLE,
    ADMINISTRATOR_ROLE_CREATE,
    ADMINISTRATOR_ROLE_DETAIL,
    ADMINISTRATOR_SYSTEM,
    ADMINISTRATOR_SYSTEM_API,
    ADMINISTRATOR_SYSTEM_ERRORLOG,
    ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT,
    ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_CREATE,
    ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_DETAIL,
    ADMINISTRATOR_TEST_DATA_DIAGNOSE,
    ADMINISTRATOR_TEST_DATA_DISPATCH_SHIPMENT_REPORT,
    ADMINISTRATOR_TOOLTIP,
    ADMINISTRATOR_TOOLTIP_CREATE,
    ADMINISTRATOR_TOOLTIP_DETAIL,
    ADMINISTRATOR_USER,
    ADMINISTRATOR_USER_DETAIL,
    ADMINISTRATOR_USER_DISABLED,
    ADMINISTRATOR_USER_DISABLED_DETAIL,
    CLAIM,
    CLAIM_DISPUTE,
    CLAIM_DISPUTE_IMPORT_DISPATCH_REMITTANCE,
    CLAIM_INVOICE,
    CLAIM_RENUMERATION,
    DASHBOARD,
    DIAGNOSTIC,
    DIAGNOSTIC_DETAIL,
    FLEET_IMPORT_DEVICES,
    FLEET_MODEL,
    FLEET_MODEL_DETAIL,
    FLEET_OVERVIEW,
    FLEET_OVERVIEW_CHROME,
    FLEET_OVERVIEW_VENDOR,
    FLEET_OVERVIEW_WINDOW,
    FLEET_SERIAL,
    FLEET_SERIAL_DETAIL,
    FLEET_SERIAL_DETAIL_SEARCH,
    FLEET_SWAP,
    HOME,
    ISSUE_ARCHIVE,
    ISSUE_ARCHIVE_IMAGE,
    ISSUE_BOM,
    ISSUE_BOM_DETAIL,
    ISSUE_DETAIL,
    ISSUE_DISPATCH,
    ISSUE_DISPATCH_ADD,
    ISSUE_DISPATCH_COMPONENT,
    ISSUE_DISPATCH_DETAIL,
    ISSUE_DISPATCH_EDIT,
    ISSUE_DISPATCH_IMPORT_EXPORT,
    ISSUE_ISSUE,
    ISSUE_ISSUE_DETAIL,
    ISSUE_ISSUE_QC,
    ISSUE_ISSUE_REPAIR,
    ISSUE_OVERVIEW,
    ISSUE_RACKSCAN_SCAN,
    ISSUE_RACKSCAN_SCAN_DETAIL,
    ISSUE_RACKSCAN_SCAN_NEW,
    ISSUE_RACKSCAN_TICKET,
    KNOWLEDGE,
    PART,
    PART_COMPONENT,
    PART_COMPONENT_DETAIL,
    PART_DELL_STOCK,
    PART_PARTCODE,
    PART_PRODUCT,
    PART_PRODUCT_DETAIL,
    PART_USAGE,
    PROCUREMENT,
    PROCUREMENT_DELIVERY,
    PROCUREMENT_DELIVERY_DETAIL,
    PROCUREMENT_INVOICE,
    PROCUREMENT_ORDERCONFIRMATION,
    PROCUREMENT_PURCHASEORDER,
    PROCUREMENT_PURCHASEORDER_DETAIL,
    PROCUREMENT_PURCHASEORDER_LINE_DETAIL,
    PROCUREMENT_RMA,
    PRODUCTION,
    PRODUCTION_ADD_ONS,
    PRODUCTION_ADD_ONS_DETAIL,
    PRODUCTION_IMAGES_DETAIL,
    PRODUCTION_IMPORT_PROJECTS,
    PRODUCTION_ORDERS,
    PRODUCTION_ORDERS_DETAIL,
    PRODUCTION_ORGANIZATION,
    PRODUCTION_PROJECTS,
    PRODUCTION_PROJECTS_DETAIL,
    PRODUCTION_SERVERS,
    PRODUCTION_SERVERS_DETAIL,
    PRODUCTION_SYSTEMIMAGE,
    USER_SETTING,
} from '~/constants/Routes'
import Role from '~/pages/Acl/Role'
import RoleCreate from '~/pages/Acl/RoleCreate'
import RoleDetail from '~/pages/Acl/RoleDetail'
import BOMPage from '~/pages/BOM/Detail'
import BOMOverview from '~/pages/BOM/Overview'
import CauseCreate from '~/pages/Cause/CauseCreate'
import CauseEdit from '~/pages/Cause/CauseEdit'
import Cause from '~/pages/Cause/Overview'
import DispatchComponent from '~/pages/ComponentDispatches'
import Component from '~/pages/ComponentPage'
import ComponentDetail from '~/pages/ComponentPage/Detail'
import DataSchedulerCreate from '~/pages/DataScheduler/DataScheduleCreate'
import DataSchedulerEdit from '~/pages/DataScheduler/DataScheduleEdit'
import DataScheduler from '~/pages/DataScheduler/Overview'
import DeliveryDetail from '~/pages/Delivery/DeliveryDetail'
import Delivery from '~/pages/Delivery/Overview'
import FleetOverview from '~/pages/Device/Fleet/Overview/FleetOverview'
import VendorPage from '~/pages/Device/Fleet/Vendor/VendorPage'
import ImportDevicesPage from '~/pages/Device/ImportDevices'
import SerialOverview from '~/pages/Device/Serial/Overview/SerialOverview'
import SerialPage from '~/pages/Device/Serial/SerialDetail'
import Dispatch from '~/pages/Dispatch'
import DispatchDetailPage from '~/pages/Dispatch/DispatchDetail'
import EditDispatch from '~/pages/Dispatch/EditDispatch'
import Home from '~/pages/Home'
import ImportRemittancePage from '~/pages/ImportRemittance/ImportRemittancePage'
import Invoice from '~/pages/Invoices/Overview'
import IssueDetail from '~/pages/Issue/Detail'
import IssueOverview from '~/pages/Issue/Overview'
import IssueQualityCheck from '~/pages/Issue/QualityCheck'
import IssueRepairPage from '~/pages/Issue/Repair'
import FleetModelDetails from '~/pages/Model/Details'
import Model from '~/pages/Model/Overview'
import OrderConfirmation from '~/pages/OrderConfirmation/Overview'
import PartCode from '~/pages/PartCode'
import Product from '~/pages/Product'
import ProductDetail from '~/pages/Product/ProductDetail'
import PurchaseOrder from '~/pages/PurchaseOrder/Overview'
import PurchaseOrderDetail from '~/pages/PurchaseOrder/PurchaseOrderDetail'
import PurchaseOrderLine from '~/pages/PurchaseOrder/PurchaseOrderLine'
import Ticket from '~/pages/RackTicket/Overview'
import Region from '~/pages/Region/Overview'
import RegionCreate from '~/pages/Region/RegionCreate'
import RegionDetail from '~/pages/Region/RegionDetail'
import RegionEdit from '~/pages/Region/RegionEdit'
import Scan from '~/pages/Scan/Overview'
import ScanCreate from '~/pages/Scan/ScanCreate'
import ScanDetail from '~/pages/Scan/ScanDetail'
import ServiceAccount from '~/pages/ServiceAccount'
import ServiceAccountCreate from '~/pages/ServiceAccount/ServiceAccountCreate'
import ServiceAccountDetail from '~/pages/ServiceAccount/ServiceAccountDetail'
import ServiceCenter from '~/pages/ServiceCenter/Overview'
import ServiceCenterCreate from '~/pages/ServiceCenter/ServiceCenterCreate'
import ServiceCenterDetail from '~/pages/ServiceCenter/ServiceCenterDetail'
import ServiceCenterEdit from '~/pages/ServiceCenter/ServiceCenterEdit'
import UserSetting from '~/pages/Setting'
import Team from '~/pages/Team/Overview'
import TeamCreate from '~/pages/Team/TeamCreate'
import TeamDetail from '~/pages/Team/TeamDetail'
import TeamEdit from '~/pages/Team/TeamEdit'
import Tooltip from '~/pages/Tooltip'
import TooltipCreate from '~/pages/Tooltip/Create'
import TooltipDetail from '~/pages/Tooltip/Detail'
import User from '~/pages/User'
import DisabledUser from '~/pages/User/DisabledUser'
import UserDetail from '~/pages/UserDetail'
import WorkBench from '~/pages/Workbench'
import WorkbenchCreate from '~/pages/Workbench/create'
import WorkbenchEdit from '~/pages/Workbench/detail'
import DellStock from '../pages/DellStock'
import Diagnostic from '../pages/Diagnostic'
import DiagnosticDetail from '../pages/Diagnostic/Detail'
import AddDispatch from '../pages/Dispatch/AddDispatch'
import NamingTag from '../pages/NamingTag'
import NamingTagRuleCreate from '../pages/NamingTag/Rule/Create'
import NamingTagRuleDetail from '../pages/NamingTag/Rule/Detail'
import NamingTagCreate from '../pages/NamingTag/Tag/Create'
import NamingTagDetail from '../pages/NamingTag/Tag/Detail'
import TicketDetail from '../pages/Ticket/Detail'
import TicketOverview from '../pages/Ticket/Overview'
import Layout from './layout'
import Placeholder from '~/pages/Placeholder'
import TestDataCreateDiagnose from '~/pages/TestData/Diagnose'
import TestDataIssues from '~/pages/TestData/DispatchShipmentReport'
import OrdersOverview from '~/pages/Production/Orders/Overview'
import OrderDetail from '~/pages/Production/Orders/Detail/OrderDetail'
import AddonsOverview from '~/pages/Production/Addons/Overview'
import AddonDetail from '~/pages/Production/Addons/Detail'
import ServersOverview from '~/pages/Production/Servers/Overview'
import ServersDetail from '~/pages/Production/Servers/Detail/ServersDetail'
import ImagesOverview from '~/pages/Production/Images/Overview'
import ImageDetail from '~/pages/Production/Images/Detail/ImageDetail'
import ProjectOverview from '~/pages/Production/Projects/Overview'
import ProjectDetail from '~/pages/Production/Projects/Detail/infor'
import ImportProject from '~/pages/Production/Projects/ProjectAction/ImportProject'
import DispatchesImportExport from '~/pages/ImportExportDispatch'

const Navigator = () => {
    const navigate = useNavigate()

    useEffect(() => {
        const redirectPath = localStorage.getItem('REDIRECT_URL')
        if (redirectPath) {
            localStorage.removeItem('REDIRECT_URL')
            navigate(redirectPath)
        }
    })

    return (
        <Layout>
            <Routes>
                <Route path={HOME} element={<Home />} />
                <Route path={DASHBOARD} element={<Home />} />
                <Route path={ISSUE_OVERVIEW} element={<TicketOverview />} />
                <Route path={ISSUE_DETAIL} element={<TicketDetail />} />
                <Route path={ISSUE_ISSUE} element={<IssueOverview />} />
                <Route path={ISSUE_BOM} element={<BOMOverview />} />
                <Route path={ISSUE_BOM_DETAIL} element={<BOMPage />} />
                <Route path={ISSUE_ISSUE_DETAIL} element={<IssueDetail />} />
                <Route
                    path={ISSUE_ISSUE_REPAIR}
                    element={<IssueRepairPage />}
                />
                <Route
                    path={ISSUE_ISSUE_QC}
                    element={<IssueQualityCheck issueData={{}} />}
                />
                <Route path={ISSUE_DISPATCH} element={<Dispatch />} />
                <Route
                    path={ISSUE_DISPATCH_COMPONENT}
                    element={<DispatchComponent />}
                />
                <Route
                    path={ISSUE_DISPATCH_IMPORT_EXPORT}
                    element={<DispatchesImportExport />}
                />
                <Route path={ISSUE_DISPATCH_EDIT} element={<EditDispatch />} />
                <Route path={ISSUE_DISPATCH_ADD} element={<AddDispatch />} />
                <Route
                    path={ISSUE_DISPATCH_DETAIL}
                    element={<DispatchDetailPage />}
                />
                <Route path={ISSUE_RACKSCAN_TICKET} element={<Ticket />} />
                <Route path={ISSUE_RACKSCAN_SCAN} element={<Scan />} />
                <Route
                    path={ISSUE_RACKSCAN_SCAN_DETAIL}
                    element={<ScanDetail />}
                />
                <Route
                    path={ISSUE_RACKSCAN_SCAN_NEW}
                    element={<ScanCreate />}
                />
                <Route path={ISSUE_ARCHIVE} element={<Placeholder />} />
                <Route path={ISSUE_ARCHIVE_IMAGE} element={<Placeholder />} />
                <Route path={FLEET_OVERVIEW} element={<FleetOverview />} />
                <Route path={FLEET_OVERVIEW_VENDOR} element={<VendorPage />} />
                <Route path={FLEET_OVERVIEW_WINDOW} element={<Placeholder />} />
                <Route path={FLEET_OVERVIEW_CHROME} element={<Placeholder />} />
                <Route path={FLEET_SERIAL} element={<SerialOverview />} />
                <Route path={FLEET_SERIAL_DETAIL} element={<SerialPage />} />
                <Route
                    path={FLEET_SERIAL_DETAIL_SEARCH}
                    element={<SerialPage />}
                />
                <Route path={FLEET_OVERVIEW_WINDOW} element={<Placeholder />} />
                <Route path={FLEET_OVERVIEW_CHROME} element={<Placeholder />} />
                <Route
                    path={FLEET_IMPORT_DEVICES}
                    element={<ImportDevicesPage />}
                />
                <Route path={FLEET_MODEL} element={<Model />} />
                <Route
                    path={FLEET_MODEL_DETAIL}
                    element={<FleetModelDetails />}
                />
                <Route path={FLEET_SWAP} element={<Placeholder />} />
                <Route path={PART} element={<Placeholder />} />
                <Route path={PART_COMPONENT} element={<Component />} />
                <Route
                    path={PART_COMPONENT_DETAIL}
                    element={<ComponentDetail />}
                />
                <Route path={PART_USAGE} element={<Placeholder />} />
                <Route path={PART_PARTCODE} element={<PartCode />} />
                <Route path={KNOWLEDGE} element={<Placeholder />} />
                <Route path={CLAIM} element={<Placeholder />} />
                <Route path={CLAIM_RENUMERATION} element={<Placeholder />} />
                <Route path={CLAIM_INVOICE} element={<Placeholder />} />
                <Route path={CLAIM_DISPUTE} element={<Placeholder />} />
                <Route
                    path={CLAIM_DISPUTE_IMPORT_DISPATCH_REMITTANCE}
                    element={<ImportRemittancePage />}
                />
                <Route path={PROCUREMENT} element={<Placeholder />} />
                <Route
                    path={PROCUREMENT_PURCHASEORDER}
                    element={<PurchaseOrder />}
                />
                <Route
                    path={PROCUREMENT_PURCHASEORDER_DETAIL}
                    element={<PurchaseOrderDetail />}
                />
                <Route
                    path={PROCUREMENT_PURCHASEORDER_LINE_DETAIL}
                    element={<PurchaseOrderLine />}
                />
                <Route
                    path={PROCUREMENT_ORDERCONFIRMATION}
                    element={<OrderConfirmation />}
                />
                <Route path={PROCUREMENT_INVOICE} element={<Invoice />} />
                <Route path={PROCUREMENT_DELIVERY} element={<Delivery />} />
                <Route
                    path={PROCUREMENT_DELIVERY_DETAIL}
                    element={<DeliveryDetail />}
                />
                <Route path={PROCUREMENT_RMA} element={<Placeholder />} />
                <Route path={PRODUCTION} element={<Placeholder />} />
                <Route
                    path={PRODUCTION_SYSTEMIMAGE}
                    element={<ImagesOverview />}
                />
                <Route
                    path={PRODUCTION_IMAGES_DETAIL}
                    element={<ImageDetail />}
                />
                <Route
                    path={PRODUCTION_ORGANIZATION}
                    element={<Placeholder />}
                />
                <Route
                    path={PRODUCTION_PROJECTS}
                    element={<ProjectOverview />}
                />
                <Route
                    path={PRODUCTION_PROJECTS_DETAIL}
                    element={<ProjectDetail />}
                />
                <Route
                    path={PRODUCTION_IMPORT_PROJECTS}
                    element={<ImportProject />}
                />
                <Route path={PRODUCTION_ORDERS} element={<OrdersOverview />} />
                <Route
                    path={PRODUCTION_ORDERS_DETAIL}
                    element={<OrderDetail />}
                />
                <Route path={PRODUCTION_ADD_ONS} element={<AddonsOverview />} />
                <Route
                    path={PRODUCTION_ADD_ONS_DETAIL}
                    element={<AddonDetail />}
                />
                <Route
                    path={PRODUCTION_SERVERS}
                    element={<ServersOverview />}
                />
                <Route
                    path={PRODUCTION_SERVERS_DETAIL}
                    element={<ServersDetail />}
                />
                <Route path={ADMINISTRATOR} element={<Placeholder />} />
                <Route path={ADMINISTRATOR_USER} element={<User />} />
                <Route
                    path={ADMINISTRATOR_USER_DETAIL}
                    element={<UserDetail />}
                />
                <Route
                    path={ADMINISTRATOR_USER_DISABLED}
                    element={<DisabledUser />}
                />
                <Route
                    path={ADMINISTRATOR_USER_DISABLED_DETAIL}
                    element={<UserDetail />}
                />
                <Route path={ADMINISTRATOR_ROLE} element={<Role />} />
                <Route
                    path={ADMINISTRATOR_ROLE_DETAIL}
                    element={<RoleDetail />}
                />
                <Route
                    path={ADMINISTRATOR_ROLE_CREATE}
                    element={<RoleCreate />}
                />
                <Route path={ADMINISTRATOR_SYSTEM} element={<Placeholder />} />
                <Route
                    path={ADMINISTRATOR_SYSTEM_ERRORLOG}
                    element={<Placeholder />}
                />
                <Route
                    path={ADMINISTRATOR_SYSTEM_API}
                    element={<Placeholder />}
                />
                <Route
                    path={ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT}
                    element={<ServiceAccount />}
                />
                <Route
                    path={ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_DETAIL}
                    element={<ServiceAccountDetail />}
                />
                <Route
                    path={ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_CREATE}
                    element={<ServiceAccountCreate />}
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION}
                    element={<Placeholder />}
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_REGIONS}
                    element={<Region />}
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_REGIONS_DETAIL}
                    element={<RegionDetail />}
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_REGIONS_NEW}
                    element={<RegionCreate />}
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_REGIONS_EDIT}
                    element={<RegionEdit />}
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_SERVICECENTER}
                    element={<ServiceCenter />}
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_SERVICECENTER_DETAIL}
                    element={<ServiceCenterDetail />}
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_SERVICECENTER_NEW}
                    element={<ServiceCenterCreate />}
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_SERVICECENTER_EDIT}
                    element={<ServiceCenterEdit />}
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_TEAM}
                    element={<Team />}
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_TEAM_DETAIL}
                    element={<TeamDetail />}
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_TEAM_EDIT}
                    element={<TeamEdit />}
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_TEAM_NEW}
                    element={<TeamCreate />}
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_WORKBENCH}
                    element={<WorkBench />}
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_WORKBENCH_NEW}
                    element={<WorkbenchCreate />}
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_WORKBENCH_DETAIL}
                    element={<WorkbenchEdit />}
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_CAUSE}
                    element={<Cause />}
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_CAUSE_NEW}
                    element={<CauseCreate />}
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_CAUSE_EDIT}
                    element={<CauseEdit />}
                />
                <Route path={PART_PRODUCT} element={<Product />} />
                <Route path={PART_PRODUCT_DETAIL} element={<ProductDetail />} />
                <Route path={PART_DELL_STOCK} element={<DellStock />} />
                <Route
                    path={ADMINISTRATOR_NAMING_TAG}
                    element={<NamingTag />}
                />
                <Route
                    path={ADMINISTRATOR_NAMING_TAG_DETAIL}
                    element={<NamingTagDetail />}
                />
                <Route
                    path={ADMINISTRATOR_NAMING_TAG_CREATE}
                    element={<NamingTagCreate />}
                />
                <Route
                    path={ADMINISTRATOR_NAMING_TAG_RULE_DETAIL}
                    element={<NamingTagRuleDetail />}
                />
                <Route
                    path={ADMINISTRATOR_NAMING_TAG_RULE_CREATE}
                    element={<NamingTagRuleCreate />}
                />
                <Route path={ADMINISTRATOR_TOOLTIP} element={<Tooltip />} />
                <Route
                    path={ADMINISTRATOR_TOOLTIP_DETAIL}
                    element={<TooltipDetail />}
                />
                <Route
                    path={ADMINISTRATOR_TOOLTIP_CREATE}
                    element={<TooltipCreate />}
                />
                <Route path={USER_SETTING} element={<UserSetting />} />
                <Route
                    path={ADMINISTRATOR_DATASCHEDULER}
                    element={<DataScheduler />}
                />
                <Route
                    path={ADMINISTRATOR_DATASCHEDULER_NEW}
                    element={<DataSchedulerCreate />}
                />
                <Route
                    path={ADMINISTRATOR_DATASCHEDULER_DETAIL}
                    element={<DataSchedulerEdit />}
                />

                <Route path='*' element={<Navigate to={HOME} />} />
                <Route path={DIAGNOSTIC} element={<Diagnostic />} />
                <Route
                    path={DIAGNOSTIC_DETAIL}
                    element={<DiagnosticDetail />}
                />
                <Route
                    path={ADMINISTRATOR_TEST_DATA_DIAGNOSE}
                    element={<TestDataCreateDiagnose />}
                />
                <Route
                    path={ADMINISTRATOR_TEST_DATA_DISPATCH_SHIPMENT_REPORT}
                    element={<TestDataIssues />}
                />
            </Routes>
        </Layout>
    )
}

export default Navigator
