import { useMutation, useQueryClient } from 'react-query'
import testDataApi from '~/api/testDataApi'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setSnackbar } from '~/store/snackbar/slice'
import {
    ADMINISTRATOR_TEST_DATA_DIAGNOSE,
    ISSUE_ISSUE,
} from '~/constants/Routes'

export const useCreatingDiagnoseTesting = () => {
    const queryClient = useQueryClient()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return useMutation(
        ['creating_diagnose_testing'],
        async (payload) => {
            return await testDataApi.diagnosePrefilled(payload)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('issues')
                queryClient.invalidateQueries('tickets')
                dispatch(
                    setSnackbar({
                        message: 'Prefilled diagnose for testing successfully!',
                        type: 'success',
                    }),
                )
                navigate(ISSUE_ISSUE)
            },
            onError: (error) => {
                let message = 'Create failed!'
                if (error.response.data.message) {
                    message = error.response.data.message
                } else if (error.message) {
                    message = error.message
                }
                dispatch(
                    setSnackbar({
                        message,
                        type: 'error',
                    }),
                )
                navigate(ADMINISTRATOR_TEST_DATA_DIAGNOSE)
            },
        },
    )
}
