export const ComponentDetailHeadCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
    },
    {
        id: 'count',
        numeric: false,
        disablePadding: false,
        label: 'count',
    },
    {
        id: 'item_description',
        numeric: false,
        disablePadding: false,
        label: 'itemDescription',
    },
    {
        id: 'oldest',
        numeric: false,
        disablePadding: false,
        label: 'oldest',
    },
    {
        id: 'newest',
        numeric: false,
        disablePadding: false,
        label: 'newest',
    },
    {
        id: 'in_warranty',
        numeric: false,
        disablePadding: false,
        label: 'inWarranty',
    },
    {
        id: 'out_of_warranty',
        numeric: false,
        disablePadding: false,
        label: 'outOfWarranty',
    },
    {
        id: 'expire_in_year_warranty',
        numeric: false,
        disablePadding: false,
        label: 'expireInYearWarranty',
    },
    {
        id: 'active',
        numeric: false,
        disablePadding: false,
        label: 'active',
    },
    {
        id: 'stock',
        numeric: false,
        disablePadding: false,
        label: 'stock',
    },
    {
        id: 'other',
        numeric: false,
        disablePadding: false,
        label: 'other',
    },
]

export const ComponentHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
    },
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'Name',
        orderable: true,
    },
    {
        id: 'part_number',
        numeric: true,
        disablePadding: false,
        label: 'PartNumber',
        orderable: true,
    },
    {
        id: 'item_description',
        numeric: true,
        disablePadding: false,
        label: 'Description',
        orderable: true,
    },
    {
        id: 'code',
        numeric: true,
        disablePadding: false,
        label: 'Code',
    },
    {
        id: 'part_code_name',
        numeric: true,
        disablePadding: false,
        label: 'PartCode',
    },
    {
        id: 'type',
        numeric: true,
        disablePadding: false,
        label: 'Type',
    },
    {
        id: 'removeComponent',
        numeric: false,
        disablePadding: false,
        label: 'removeComponent',
    },
]

export const ComponentUnlinkedHeadCells = [
    {
        id: 'part_number',
        numeric: true,
        disablePadding: false,
        label: 'PartNumber',
        orderable: false,
    },
    {
        id: 'action',
        numeric: true,
        disablePadding: false,
        label: 'action',
        orderable: false,
    },
]
