import { useNavigate, useParams } from 'react-router-dom'
import { useTagRuleDetailQuery } from '../query'
import { Form } from 'react-final-form'
import BreadCrumb from '~/components/BreadCrumb'
import useFormValidationCallback from '~/hooks/useFormValidationCallback'
import { object, string, number } from 'yup'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_NAMING_TAG,
    ADMINISTRATOR_NAMING_TAG_RULE_DETAIL,
} from '~/constants/Routes'
import FormToolbar from '~/components/FinalForm/FormToolbar'
import { LoadingButton } from '~/components/Button'
import { useTranslation } from 'react-i18next'
import {
    useApplyTagRuleMutation,
    useDeleteTagRuleMutation,
    useRevokeTagRuleMutation,
    useSaveTagRuleMutation,
} from '../mutate'
import { useMemo } from 'react'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import ComponentTooltip from '~/components/ComponentTooltip'
import PageName from '~/components/PageName'
import BodyForm from './BodyForm'

const NamingTagRuleDetail = () => {
    const localization = `pages.administrator.namingTag`
    const { t } = useTranslation()
    const { id } = useParams()
    const { data, isLoading } = useTagRuleDetailQuery(id)
    const { mutate: applyRuleMutate, isLoading: loadingApplyRule } =
        useApplyTagRuleMutation()
    const { mutate: revokeRuleMutate, isLoading: loadingRevokeRule } =
        useRevokeTagRuleMutation()
    const { mutate: saveRuleMutate, isLoading: loadingSaveRule } =
        useSaveTagRuleMutation()
    const { mutate: deleteRuleMutate, isLoading: loadingDeleteRule } =
        useDeleteTagRuleMutation()

    const { openSnackbar } = useDialogContext()
    const navigate = useNavigate()
    const schema = object().shape({
        device_name: string().label(`Device Name`).required(),
        vendor_id: number().label(`Vendor`).required(),
        component_id: number().label(`Component`).required(),
        device_tag_id: number().label(`Device Tag`).required(),
    })
    const validate = useFormValidationCallback(schema)
    const loading = useMemo(
        () =>
            loadingApplyRule ||
            loadingDeleteRule ||
            loadingRevokeRule ||
            loadingSaveRule,
        [
            loadingApplyRule,
            loadingDeleteRule,
            loadingRevokeRule,
            loadingSaveRule,
        ],
    )
    const breadCrumbConfig = useMemo(
        () => [
            { title: `Administrator`, route: ADMINISTRATOR },
            {
                title: `NamingTags`,
                route: ADMINISTRATOR_NAMING_TAG,
            },
            {
                title: `Naming tag rule ${data?.id}`,
                page: 'detail',
            },
        ],
        [data],
    )

    const applyHandler = (isFormDirty) => {
        if (isFormDirty) {
            openSnackbar({
                message: t(`${localization}.saveBeforeApplyWarning`),
                type: 'info',
            })
            return
        }
        applyRuleMutate(id, {
            onSuccess: (data) => {
                openSnackbar({ message: data.message, type: data.code })
            },
            onError: (e) => {
                openSnackbar({
                    message: e?.response?.data.message,
                    type: 'error',
                })
            },
        })
    }
    const revokeHandler = () => {
        revokeRuleMutate(id, {
            onSuccess: (data) => {
                openSnackbar({ message: data.message, type: data.code })
            },
            onError: (e) => {
                openSnackbar({
                    message: e?.response?.data.message,
                    type: 'error',
                })
            },
        })
    }
    const saveHandler = (values) => {
        saveRuleMutate(
            { id, ...values },
            {
                onSuccess: (data) => {
                    openSnackbar({ message: data.message, type: data.code })
                },
                onError: (e) => {
                    openSnackbar({
                        message: e?.response?.data.message,
                        type: 'error',
                        duration: 10000,
                    })
                },
            },
        )
    }
    const deleteHandler = () => {
        deleteRuleMutate(id, {
            onSuccess: (data) => {
                navigate(ADMINISTRATOR_NAMING_TAG)
                openSnackbar({ message: data.message, type: data.code })
            },
            onError: (e) => {
                openSnackbar({
                    message: e?.response?.data.message,
                    type: 'error',
                    duration: 10000,
                })
            },
        })
    }

    if (isLoading) {
        return <></>
    }
    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <ComponentTooltip
                componentId={`page_title_naming_rule`}
                pagePath={ADMINISTRATOR_NAMING_TAG_RULE_DETAIL}
            >
                <div>
                    <PageName name='Edit Tag Rules' />
                </div>
            </ComponentTooltip>
            <Form
                validate={validate}
                initialValues={data}
                onSubmit={saveHandler}
                render={({ handleSubmit, values, dirty }) => (
                    <form onSubmit={handleSubmit}>
                        <FormToolbar>
                            <LoadingButton
                                disabled={!dirty}
                                label={t(`${localization}.save`)}
                                type={`submit`}
                                loading={loading}
                            />
                            <LoadingButton
                                label={t(`${localization}.apply`)}
                                onClick={() => applyHandler(dirty)}
                                loading={loading}
                            />
                            <LoadingButton
                                label={t(`${localization}.revoke`)}
                                onClick={revokeHandler}
                                loading={loading}
                            />
                            <LoadingButton
                                label={t(`${localization}.delete`)}
                                onClick={deleteHandler}
                                loading={loading}
                            />
                        </FormToolbar>
                        <BodyForm {...{ localization, data, values }} />
                    </form>
                )}
            />
        </>
    )
}

export default NamingTagRuleDetail
