import { TableBody, TableCell, TableRow } from '@mui/material'
import TableLoading from '~/components/Table/TableLoading'
import { headCells } from './constants'
import PropTypes from 'prop-types'
import PartNumberDisplayer from '~/pages/BOM/components/PartNumberDisplayer'
import SkuDisplayer from '~/pages/BOM/components/SkuDisplayer'
import DataNotFound from '~/components/DataNotFound'
import StockLabelDisplayer from '../components/StockLabelDisplayer'
import { ISSUE_DISPATCH_DETAIL } from '~/constants/Routes'
import { route } from '~/utils/helpers'
import { Link } from 'react-router-dom'

const BomDetailBodyTable = ({ isLoading, boms, bomState }) => {
    if (isLoading) {
        return <TableLoading colSpan={headCells.length} />
    }

    return (
        <TableBody>
            {boms && boms.length > 0 ? (
                boms?.map((row, index) => (
                    <TableRow
                        key={index}
                        sx={{
                            '&:last-child td, &:last-child th': {
                                border: 0,
                            },
                        }}
                    >
                        <TableCell>{row.part_code || 'N/A'}</TableCell>
                        <TableCell>{row.qty}</TableCell>
                        <TableCell
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <StockLabelDisplayer
                                bomId={row.id}
                                stockLabel={row.stock_label}
                                bomState={bomState}
                            />
                        </TableCell>
                        <TableCell>
                            <PartNumberDisplayer
                                bomId={row.id}
                                partNumber={row.part_number}
                                bomState={bomState}
                            />
                        </TableCell>
                        <TableCell>
                            <SkuDisplayer
                                sku={row.sku}
                                bomId={row.id}
                                bomState={bomState}
                            />
                        </TableCell>
                        <TableCell>{row.price}</TableCell>
                        <TableCell>
                            {row.dispatch_code?.length > 0 ? (
                                <Link
                                    to={route(ISSUE_DISPATCH_DETAIL, {
                                        id: row.dispatch_id,
                                    })}
                                    style={{ textDecoration: 'none' }}
                                >
                                    {row.dispatch_code}
                                </Link>
                            ) : (
                                <>N/A</>
                            )}
                        </TableCell>
                        <TableCell>{row.dps_number || 'N/A'}</TableCell>
                        <TableCell>{row.stock_level}</TableCell>
                        <TableCell>{row.external_order_id || 'N/A'}</TableCell>
                    </TableRow>
                ))
            ) : (
                <DataNotFound colSpan={headCells.length} />
            )}
        </TableBody>
    )
}

BomDetailBodyTable.propTypes = {
    isLoading: PropTypes.bool,
    boms: PropTypes.array,
    bomState: PropTypes.string,
}

export default BomDetailBodyTable
