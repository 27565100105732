import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import PageName from '~/components/PageName'
import { PROCUREMENT, PROCUREMENT_INVOICE } from '~/constants/Routes'
import InvoiceTable from './InvoiceTable'

const Invoice = () => {
    const BreadComponents = [
        { route: PROCUREMENT, title: 'Procurement' },
        {
            route: PROCUREMENT_INVOICE,
            title: 'Invoices',
        },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={PROCUREMENT_INVOICE}
            >
                <div>
                    <PageName name={'Invoices'} />
                </div>
            </ComponentTooltip>
            <InvoiceTable />
        </>
    )
}

export default Invoice
