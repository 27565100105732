import DownloadIcon from '@mui/icons-material/Download'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import UploadIcon from '@mui/icons-material/Upload'
import { MenuItem } from '@mui/material'
import ActionMenu from '~/components/ActionMenu'
import ListToolbarWrapper from '~/components/ListToolbarWrapper'
import UploadPopup from '~/components/UploadPopup'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useExportPartCodeMutation, useImportPartCodeMutation } from './mutate'
import PropTypes from 'prop-types'

const PartCodeListToolbar = ({ payload }) => {
    const [openUpload, setOpenUpload] = useState(false)
    const { t } = useTranslation()

    const { mutate: exportPartCode, isLoading: loadingExportPartCode } =
        useExportPartCodeMutation()
    const { mutate: importPartCode, isLoading: loadingImportPartCode } =
        useImportPartCodeMutation()

    return (
        <ListToolbarWrapper>
            <ActionMenu {...{ openUpload }}>
                <MenuItem
                    onClick={() => exportPartCode(payload)}
                    disabled={loadingExportPartCode}
                >
                    {loadingExportPartCode ? (
                        <HourglassTopIcon />
                    ) : (
                        <DownloadIcon />
                    )}
                    {t('button.export')}
                </MenuItem>
                <MenuItem onClick={() => setOpenUpload(true)}>
                    <UploadIcon />
                    {t('button.import')}
                </MenuItem>
            </ActionMenu>

            <UploadPopup
                openUpload={openUpload}
                setOpenUpload={setOpenUpload}
                actionImport={importPartCode}
                isLoading={loadingImportPartCode}
            />
        </ListToolbarWrapper>
    )
}

PartCodeListToolbar.propTypes = { payload: PropTypes.object }

export default PartCodeListToolbar
