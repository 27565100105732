import tooltipApi from '~/api/tooltipApi'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

export const useDeleteMutation = () => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['delete_component_tooltips'],
        async (id) => {
            const {
                data: { data },
            } = await tooltipApi.deleteTooltip({ id })
            return data
        },
        {
            onSettled: () => clientQuery.invalidateQueries('tooltip'),
        },
    )
}

export const useSaveMutation = () => {
    const clientQuery = useQueryClient()
    const { id } = useParams()
    return useMutation(
        ['save_component_tooltips'],
        async (values) => {
            const {
                data: { data },
            } = await tooltipApi.saveTooltip({ id, payload: values })
            return data
        },
        {
            onSettled: async () => clientQuery.invalidateQueries('tooltip'),
        },
    )
}
export const useCreateMutation = () => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['create_component_tooltips'],
        async (values) => {
            const {
                data: { data },
            } = await tooltipApi.createTooltip({ payload: values })
            return data
        },
        {
            onSettled: async () => clientQuery.invalidateQueries('tooltip'),
        },
    )
}
