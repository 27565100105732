import { createSlice } from '@reduxjs/toolkit'

export const rackTicketStateSlice = createSlice({
    name: 'rackTicketState',
    initialState: {
        rackTicketState: null,
    },
    reducers: {
        setRackTicketState: (state, action) => {
            state.rackTicketState = action.payload
        },
    },
})

export const { setRackTicketState } = rackTicketStateSlice.actions

export default rackTicketStateSlice
