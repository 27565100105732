import { useMutation, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import rackScanApi from '~/api/rackScanApi'
import { selectUser } from '~/store/auth/selector'
import { ISSUE_RACKSCAN_SCAN_DETAIL } from '~/constants/Routes'
import { useSupportObject } from '~/hooks/useSupportObject'
import { useParams } from 'react-router-dom'
import { setSnackbar } from '~/store/snackbar/slice'

export const useCreateScanMutation = ({ showSnackbar }) => {
    const { navigate, dispatch } = useSupportObject()
    const implementUser = useSelector(selectUser)
    return useMutation(
        ['scan_create'],
        async (payload) => {
            payload = { ...payload, created_by: implementUser.id }
            return await rackScanApi.createRackScan(payload)
        },
        {
            onSuccess: (data) => {
                dispatch(
                    setSnackbar({
                        message: data.message
                            ? data.message
                            : 'Create successful!',
                        type: 'success',
                    }),
                )
                navigate(
                    ISSUE_RACKSCAN_SCAN_DETAIL.replace(
                        ':id',
                        data.data.data.id,
                    ),
                )

                return data.data?.data
            },
            onError: (error) =>
                showSnackbar(
                    error.message ? error.message : 'Save failed!',
                    'error',
                ),
            retry: false,
        },
    )
}

export const useCreateScanLineMutation = () => {
    const { id } = useParams()
    return useMutation(
        ['scan_create'],
        async (payload) => {
            return await rackScanApi.createRackScanLine(id, payload)
        },
        {
            onSuccess: (data) => {},
            retry: false,
        },
    )
}

export const useDeleteLineMutation = () => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['delete_scan_line'],
        async (id) => {
            return await rackScanApi.deleteRackScanLine(id)
        },
        {
            onSettled: async () =>
                clientQuery.invalidateQueries('rack_scan_line'),
        },
    )
}
