import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '~/store/auth/selector'
import { useUpdateWorkbenchMutation } from './mutation'
import { useWorkbenchQuery } from './query'
import CustomSelection from '~/components/CustomSelection'

export const useWorkbenchSelection = () => {
    const user = useSelector(selectUser)
    const { data: workbenchs } = useWorkbenchQuery()
    const { mutate: updateWorkbench } = useUpdateWorkbenchMutation()

    const [selectedWorkbench, setSelectedWorkbench] = useState(
        user?.workbench?.id ?? '',
    )

    const handleChangeWorkbench = (event) => {
        setSelectedWorkbench(event.target.value)
        updateWorkbench({ workbench_id: event.target.value })
    }

    const workbenchSelection = (
        <CustomSelection
            selected={selectedWorkbench}
            onChange={handleChangeWorkbench}
            options={workbenchs?.data}
        />
    )

    return { workbenchSelection, selectedWorkbench }
}

export default useWorkbenchSelection
