export const RackScanLineHeadCells = [
    {
        id: 'id',
        label: 'id',
        orderable: true,
    },
    {
        id: 'location',
        label: 'location',
        orderable: true,
    },
    {
        id: 'ticket_id',
        label: 'ticket',
        orderable: true,
    },
]
