import { FLEET_OVERVIEW } from '../../../../constants/Routes'

export const tableCells = [
    {
        id: 'code',
        numeric: false,
        disablePadding: false,
        label: 'code',
        orderable: true,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        orderable: true,
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'description',
        orderable: true,
    },
    {
        id: 'devices',
        numeric: false,
        disablePadding: false,
        label: 'devices',
        orderable: false,
    },
]

export const breadCrumbs = [
    { route: FLEET_OVERVIEW, title: 'Fleet' },
    {
        route: '#',
        title: 'Models',
    },
]

export const LOCALIZATION = ''
