import axiosClient from '~/api/base'

class RackScanApi {
    getRackScans = (params) => {
        return axiosClient.get('api/issue/rack-scans/scans', { params })
    }

    createRackScan = (payload) => {
        return axiosClient.post('api/issue/rack-scans/scans', payload)
    }

    getScanDetail = (id) => axiosClient.get(`api/issue/rack-scans/scans/${id}`)

    getRackScanLine = (params, id) => {
        return axiosClient.get(`api/issue/rack-scans/scans/${id}/lines`, {
            params,
        })
    }

    deleteRackScanLine = (id) => {
        return axiosClient.delete(`api/issue/rack-scans/scan-line/${id}`)
    }
}
export default new RackScanApi()
