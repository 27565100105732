import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import { TableBody, TableCell, TableRow } from '@mui/material'
import PropTypes from 'prop-types'
import LoadingCircle from '~/components/LoadingCircle'
import { ISSUE_ISSUE_DETAIL } from '~/constants/Routes'
import { route } from '~/utils/helpers'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const DeviceInformation = ({ deviceInfors, isLoading }) => {
    const localization = 'pages.bom.'
    const { t } = useTranslation()
    if (isLoading) {
        return <LoadingCircle />
    }
    return (
        <TableContainer>
            <Table>
                <TableBody>
                    {deviceInfors.map((data, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell
                                    sx={{
                                        width: '250px',
                                        borderBottom: 'none',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {data.key}:
                                </TableCell>
                                <TableCell
                                    sx={{
                                        borderBottom: 'none',
                                    }}
                                >
                                    {data.key ===
                                    t(`${localization}issue_id`) ? (
                                        <Link
                                            to={route(ISSUE_ISSUE_DETAIL, {
                                                issueId: data.value,
                                            })}
                                            style={{ textDecoration: 'none' }}
                                        >
                                            {data.value}
                                        </Link>
                                    ) : (
                                        <> {data.value}</>
                                    )}
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

DeviceInformation.propTypes = {
    isLoading: PropTypes.bool,
    deviceInfors: PropTypes.array,
}

export default DeviceInformation
