import { Grid, Typography } from '@mui/material'
import CheckListPartCodeSection from '~/components/partCodes/CheckListPartCodeSection'
import { StyledPaper } from '~/components/StyledPaper'
import { useCausesFilterPartCodeQuery } from '~/pages/PartCode/query'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectDispatchSerial } from '~/store/dispatch/selector'
import { selectSelectedPartCodeTypes } from '~/store/partcode/selector'
import {
    pushSelectedPartCodeTypes,
    unsetSelectedPartCodeTypes,
} from '~/store/partcode/slice'
import { LOCALIZATION } from '../constants'
import { usePartCodeTypeQuery } from '../query'
import PartCodeList from './PartCode/PartCodeList'
import PartCodeTable from './PartCode/PartCodeTable'
import Layout from './Section/Layout'
import PropTypes from 'prop-types'

const PartCodeSection = ({
    show,
    clickedCause,
    initComp,
    setInitComp,
    serial = false,
    setRemovedParts,
    innerRef,
}) => {
    const { t } = useTranslation()
    const [typeNameFilter, setTypeNameFilter] = useState('')
    const dispatchSerial = useSelector(selectDispatchSerial)
    const dispatch = useDispatch()
    const selectedPartCodeTypes = useSelector(selectSelectedPartCodeTypes)

    const { data: partCodeType, isLoading: isLoadingPartCodeTypes } =
        usePartCodeTypeQuery({
            type: typeNameFilter,
            serial: serial ?? dispatchSerial,
        })

    useCausesFilterPartCodeQuery({
        clickedCause: clickedCause.causeValue === true ? clickedCause : {},
    })

    const renderedPartCodeTypes = useMemo(() => {
        if (isLoadingPartCodeTypes || !partCodeType?.types) return []
        return partCodeType.types.map((type) => {
            return { name: type.type, label: type.type }
        })
    }, [partCodeType, isLoadingPartCodeTypes])

    const handleToggle = (e, code) => {
        if (e.target.checked) {
            dispatch(pushSelectedPartCodeTypes(code.name))
            return
        }
        dispatch(unsetSelectedPartCodeTypes(code.name))
    }

    if (!show) return <></>
    return (
        <Layout>
            <Typography variant='h6' gutterBottom ref={innerRef}>
                {t(`${LOCALIZATION}.partCodes`)}
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PartCodeTable
                        {...{
                            initComp,
                            setInitComp,
                            setRemovedParts,
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CheckListPartCodeSection
                        {...{
                            isLoadingPartCodeTypes,
                            renderedPartCodeTypes,
                            selectedPartCodeTypes,
                            handleToggle,
                            setTypeNameFilter,
                        }}
                    />
                </Grid>
                <Grid item xs={8}>
                    <StyledPaper>
                        <PartCodeList serial={serial} />
                    </StyledPaper>
                </Grid>
            </Grid>
        </Layout>
    )
}

PartCodeSection.propTypes = {
    show: PropTypes.bool,
    clickedCause: PropTypes.object,
    serial: PropTypes.bool,
    initComp: PropTypes.bool,
    setInitComp: PropTypes.func,
    innerRef: PropTypes.any,
    setRemovedParts: PropTypes.func,
}

export default PartCodeSection
