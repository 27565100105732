import { useMutation, useQuery } from 'react-query'
import modelApi from '~/api/modelApi'

export const useModelDetailQuery = (id, setName) =>
    useQuery(
        ['fleet_model_detail'],
        async () => {
            const res = await modelApi.getModelDetails(id)

            return { data: res.data.data }
        },
        {
            onSuccess: setName,
        },
    )

export const useUpdateModelNameMutation = (id) =>
    useMutation(async (newName) => {
        const res = await modelApi.postNewModelName(id, newName)

        return { data: res.data }
    })

export const useAttachPartCodeIntoModelMutation = () => {
    return useMutation(async ({ modelId, code }) => {
        return await modelApi.attachPartCodeIntoModel(modelId, code)
    })
}

export const useModelCausesQuery = ({ id, ...params }) => {
    return useQuery(['fleet_model_causes', params], async () => {
        const { data } = await modelApi.getModelCauses(id, params)

        return { data: data.data, total: data.meta?.total }
    })
}

export const useModelPartCodesQuery = ({ id, ...params }) =>
    useQuery(['fleet_model_part_codes', params], async () => {
        const { data } = await modelApi.getModelPartCodes(id, params)

        return { data: data.data, total: data.meta?.total }
    })
