import { PropTypes } from 'prop-types'
import { useMemo } from 'react'
import InformationData from './Component/InformationData'
import { formatDateTime } from '~/utils/helpers'
import { useSelector } from 'react-redux'
import { selectFormatDateState } from '~/store/formatdate/selector'
import { selectUser } from '~/store/auth/selector'

const DeviceInformation = ({ device }) => {
    const format = useSelector(selectFormatDateState)
    const user = useSelector(selectUser)

    const procurementSerial = device?.procurement_serial
    const procurementInvoice = procurementSerial?.procurement_invoice
    const procurementDispatch = procurementSerial?.procurement_dispatch

    const supplierData = useMemo(() => {
        const supplierNumber = procurementSerial?.supplier_sku
        const supplierName = procurementInvoice?.supplier?.name

        if (supplierNumber && supplierName) {
            return supplierName + ' | ' + supplierNumber
        }
        return 'Unknown'
    }, [procurementInvoice?.supplier?.name, procurementSerial?.supplier_sku])

    const invoiceData = useMemo(() => {
        const invoiceNumber = procurementInvoice?.invoice_number
        const invoiceDate = formatDateTime(
            procurementInvoice?.invoice_date,
            format,
            user?.timezone,
            true,
        )

        if (invoiceNumber) {
            return invoiceNumber + ' | ' + invoiceDate
        }
        return 'Unknown'
    }, [
        format,
        procurementInvoice?.invoice_date,
        procurementInvoice?.invoice_number,
        user?.timezone,
    ])

    const shipData = useMemo(() => {
        const dispatchNumber = procurementDispatch?.dispatch_number
        const dispatchDate = formatDateTime(
            procurementDispatch?.dispatch_date,
            format,
            user?.timezone,
            true,
        )

        if (dispatchNumber) {
            return dispatchNumber + ' | ' + dispatchDate
        }
        return 'Unknown'
    }, [
        format,
        procurementDispatch?.dispatch_date,
        procurementDispatch?.dispatch_number,
        user?.timezone,
    ])

    const SerialInformation = useMemo(() => {
        return [
            {
                label: 'shipDate',
                value: device?.shipdate,
                dateTime: true,
                notshowHour: true,
            },
            {
                label: 'countryCode',
                value: device?.country_code,
            },
            {
                label: 'productCode',
                value: device?.product_code,
            },
            {
                label: 'name',
                value: device?.name,
            },
            {
                label: 'Family',
                value: device?.family,
            },
            {
                label: 'Category',
                value: device?.category,
            },
            {
                label: 'notebook',
                value: device?.easy4u_notebook_id,
            },
            {
                label: 'orderbuil',
                value: device?.order_buid,
            },
            {
                label: 'localChanel',
                value: device?.local_channel,
            },
            {
                label: 'productSKU',
                value: device?.product_sku,
            },
            {
                label: 'createdAt',
                value: device?.created_at,
                dateTime: true,
            },
            {
                label: 'Supplier',
                value: supplierData,
            },
            {
                label: 'Invoice',
                value: invoiceData,
            },
            {
                label: 'localShipping',
                value: shipData,
            },
        ]
    }, [
        device?.category,
        device?.country_code,
        device?.created_at,
        device?.easy4u_notebook_id,
        device?.family,
        device?.local_channel,
        device?.name,
        device?.order_buid,
        device?.product_code,
        device?.product_sku,
        device?.shipdate,
        invoiceData,
        shipData,
        supplierData,
    ])

    return <InformationData content={SerialInformation} />
}

DeviceInformation.propTypes = {
    device: PropTypes.object,
}

export default DeviceInformation
