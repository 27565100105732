import TableFilters from '~/components/TableFilters'
import { useServiceCenterListQuery } from '~/pages/ServiceCenter/query'
import { useTeamListQuery } from '~/pages/Team/query'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const RackScanTableFilters = ({
    search,
    searchHandler,
    team,
    serviceCenter,
    setTeam,
    setServiceCenter,
    onEnterSearch,
}) => {
    const { data: serviceCenterData, isSuccess: isSuccessServiceCenter } =
        useServiceCenterListQuery({
            menuOption: true,
        })
    const { data: teamData, isSuccess: isSuccessTeam } = useTeamListQuery({
        menuOption: true,
    })

    const serviceCenters = useMemo(() => {
        if (isSuccessServiceCenter) {
            const result = [...serviceCenterData?.data]
            result.unshift({ id: '', name: 'ALL' })
            return result
        }
        return []
    }, [isSuccessServiceCenter, serviceCenterData])

    const teams = useMemo(() => {
        if (isSuccessTeam) {
            const result = [...teamData?.data]
            result.unshift({ id: '', name: 'ALL' })
            return result
        }
        return []
    }, [isSuccessTeam, teamData])

    const selectGroup = useMemo(() => {
        return [
            {
                label: 'Services Center',
                handleFunc: setServiceCenter,
                dataSet: serviceCenters,
                value: serviceCenter,
            },
            {
                label: 'Team',
                handleFunc: setTeam,
                dataSet: teams,
                value: team,
            },
        ]
    }, [serviceCenter, serviceCenters, setServiceCenter, setTeam, team, teams])
    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='rackScans'
            page='rackScans'
        />
    )
}
RackScanTableFilters.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    team: PropTypes.string,
    serviceCenter: PropTypes.string,
    setTeam: PropTypes.func,
    setServiceCenter: PropTypes.func,
    onEnterSearch: PropTypes.func,
}
export default RackScanTableFilters
