import { TableBody, TableRow } from '@mui/material'
import PropTypes from 'prop-types'
import DataNotFound from '~/components/DataNotFound'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'
import ReferencedEntities from '~/components/ReferencedEntities'
import TableLoading from '~/components/Table/TableLoading'
import { TableCell } from '~/components/TableCell'
import { headCells } from './constants'

const IssueTableBody = ({ isLoading, issues }) => {
    if (isLoading) {
        return <TableLoading colSpan={headCells.length} />
    }

    if (issues.length < 1) {
        return (
            <TableBody>
                <DataNotFound colSpan={headCells.length} />
            </TableBody>
        )
    }
    return (
        <>
            <TableBody>
                {issues?.map((row, index) => (
                    <TableRow
                        key={index}
                        sx={{
                            '&:last-child td, &:last-child th': {
                                border: 0,
                            },
                        }}
                    >
                        <ReferencedEntities
                            isTableCell={true}
                            dataType={'issue-id'}
                            dataIdentifier={row.id}
                            dataDisplay={row.id}
                        />
                        <ReferencedEntities
                            isTableCell={true}
                            dataType={'ticket-id'}
                            dataIdentifier={row.ticket_id}
                            dataDisplay={row.ticket_id}
                        />
                        <TableCell cellValue={row.esd_location || '---'} />
                        <ReferencedEntities
                            isTableCell={true}
                            dataType={'device-serial'}
                            dataIdentifier={row.name}
                            dataDisplay={row.name}
                        />
                        <TableCell cellValue={row.brand || '---'} />
                        <TableCell cellValue={row.status || '---'} />
                        <TableCell cellValue={row.state || '---'} />
                        <ReferencedEntities
                            isTableCell={true}
                            dataType={'issue-id'}
                            dataIdentifier={row.previous_id}
                            dataDisplay={row.previous_id}
                        />
                        <TableCell
                            cellValue={
                                row.created_at ? (
                                    <DateTimeLocale datetime={row.created_at} />
                                ) : (
                                    '---'
                                )
                            }
                        />
                        <TableCell
                            cellValue={
                                row.updated_at ? (
                                    <DateTimeLocale datetime={row.updated_at} />
                                ) : (
                                    '---'
                                )
                            }
                        />
                    </TableRow>
                ))}
            </TableBody>
        </>
    )
}

IssueTableBody.propTypes = {
    isLoading: PropTypes.bool,
    issues: PropTypes.array,
}

export default IssueTableBody
