import MuiLoadingButton from '@mui/lab/LoadingButton'
import PropTypes from 'prop-types'

export const LoadingButton = ({ label, type, bgColor, disabled, ...props }) => {
    return (
        <MuiLoadingButton
            variant='contained'
            size='small'
            type={type ?? 'button'}
            {...props}
            sx={{
                textTransform: 'none',
                backgroundColor: `${bgColor ?? '#76B72A'}`,
                '&:hover': {
                    backgroundColor: '#102F44',
                    color: 'white',
                },
            }}
            disabled={disabled}
        >
            {label}
        </MuiLoadingButton>
    )
}

LoadingButton.propTypes = {
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    bgColor: PropTypes.string,
}

LoadingButton.defaultProps = {
    disabled: false,
    loading: false,
}
