export const DeviceEntitlementHeadCells = [
    {
        id: 'id',
        label: 'id',
        orderable: true,
    },
    {
        id: 'sla_code',
        label: 'code',
        orderable: true,
    },
    {
        id: 'name',
        label: 'name',
        orderable: true,
    },
    {
        id: 'sla_group',
        label: 'group',
        orderable: true,
    },
    {
        id: 'start_date',
        label: 'start',
        orderable: false,
        dataType: 'datetime',
        notShowHour: true,
    },
    {
        id: 'end_date',
        label: 'end',
        orderable: false,
        dataType: 'datetime',
        notShowHour: true,
    },
]
