import propTypes from 'prop-types'
import { useMemo } from 'react'
import TableFilters from '~/components/TableFilters'
import { useGetTicketStateQuery, useGetTicketStatusQuery } from '../query'

const TicketTableFilter = ({
    search,
    searchHandler,
    onEnterSearch,
    status,
    setStatus,
    state,
    setState,
}) => {
    // HANDLE STATE
    const { data: stateData, isSuccess: isStateDataLoading } =
        useGetTicketStateQuery()
    const states = useMemo(() => {
        if (isStateDataLoading) {
            const result = stateData?.states?.map((item) => ({
                id: item,
                name: item,
            }))
            result.unshift({ id: '', name: 'All' })
            return result
        }
        return []
    }, [isStateDataLoading, stateData?.states])

    // HANDLE STATUS
    const { data: statusData, isSuccess: isStatusLoadingSuccess } =
        useGetTicketStatusQuery()
    const ticketStatus = useMemo(() => {
        if (isStatusLoadingSuccess) {
            const result = statusData?.data?.map((item) => ({
                id: item.ticket_status,
                name: item.ticket_status,
            }))
            result.unshift({ id: '', name: 'All' })
            return result
        }
        return []
    }, [isStatusLoadingSuccess, statusData?.data])

    const selectGroup = useMemo(() => {
        return [
            {
                label: 'State',
                handleFunc: setState,
                dataSet: states,
                value: state,
            },
            {
                label: 'Status',
                handleFunc: setStatus,
                dataSet: ticketStatus,
                value: status,
            },
        ]
    }, [setState, setStatus, state, states, status, ticketStatus])

    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='tickets'
            page='tickets'
        />
    )
}

TicketTableFilter.propTypes = {
    search: propTypes.string,
    searchHandler: propTypes.func,
    onEnterSearch: propTypes.func,
    status: propTypes.string,
    setStatus: propTypes.func,
    state: propTypes.string,
    setState: propTypes.func,
}

export default TicketTableFilter
