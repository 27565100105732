import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { useNavigate, useParams } from 'react-router-dom'
import {
    ADMINISTRATOR_DEFINITION_WORKBENCH,
    ADMINISTRATOR_DEFINITION_WORKBENCH_DETAIL,
} from '~/constants/Routes'
import {
    useCreateWorkbenchMutation,
    useDeleteWorkbenchMutation,
    useUpdateWorkbenchMutation,
} from '../mutation'

const useActions = () => {
    const { openSnackbar } = useDialogContext()
    const { id } = useParams()
    const navigate = useNavigate()
    const { mutate: createMutate, isLoading } = useCreateWorkbenchMutation()
    const { mutate: saveMutate, isLoading: isLoadingUpdate } =
        useUpdateWorkbenchMutation()
    const { mutate: deleteMutate, isLoading: isLoadingDelete } =
        useDeleteWorkbenchMutation()

    const onErrorCallback = (e) => {
        openSnackbar({
            message: e?.response?.data.message,
            type: 'error',
            duration: 10000,
        })
    }
    const createHandler = (values) => {
        createMutate(values, {
            onSuccess: (data) => {
                openSnackbar({
                    message: `Save successful.`,
                    type: `success`,
                })
                navigate(
                    ADMINISTRATOR_DEFINITION_WORKBENCH_DETAIL.replace(
                        ':id',
                        data.id,
                    ),
                )
            },
            onError: onErrorCallback,
        })
    }

    const saveHandler = (values) => {
        saveMutate(values, {
            onSuccess: () => {
                openSnackbar({ message: `Save successful.`, type: `success` })
            },
            onError: onErrorCallback,
        })
    }

    const deleteHandler = () => {
        deleteMutate(id, {
            onSuccess: (data) => {
                navigate(ADMINISTRATOR_DEFINITION_WORKBENCH)
                openSnackbar({ message: data.message, type: data.code })
            },
            onError: onErrorCallback,
        })
    }

    return {
        createHandler,
        saveHandler,
        deleteHandler,
        isLoading: isLoading || isLoadingUpdate || isLoadingDelete,
    }
}

export default useActions
