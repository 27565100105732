import TableFilters from '~/components/TableFilters'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useOrderStatusesQuery, useSuppliersQuery } from '../query'
import { capitalize } from '~/utils/helpers'

const OrderTableFilters = ({
    search,
    searchHandler,
    supplier,
    setSupplier,
    status,
    setStatus,
    onEnterSearch,
}) => {
    const { data: supplierData, isSuccess: isSuccessSuppliers } =
        useSuppliersQuery()

    const { data: orderStatuses, isSuccess: isSuccessOrderStatus } =
        useOrderStatusesQuery()

    const supplierList = useMemo(() => {
        if (isSuccessSuppliers) {
            const result = [...supplierData?.data]
            result.unshift({ id: '', name: 'All' })
            return result
        }
        return []
    }, [isSuccessSuppliers, supplierData?.data])

    const statusList = useMemo(() => {
        if (isSuccessOrderStatus) {
            const result = orderStatuses?.data.map((x) => {
                return {
                    id: x.status,
                    name: capitalize(x.status),
                }
            })
            result.unshift({ id: '', name: 'All' })
            return result
        }
        return []
    }, [isSuccessOrderStatus, orderStatuses?.data])

    const selectGroup = useMemo(() => {
        return [
            {
                label: 'Supplier',
                handleFunc: setSupplier,
                dataSet: supplierList,
                value: supplier,
            },
            {
                label: 'Status',
                handleFunc: setStatus,
                dataSet: statusList,
                value: status,
            },
        ]
    }, [setStatus, setSupplier, status, statusList, supplier, supplierList])
    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='orderConfirmations'
            page='orderConfirmations'
        />
    )
}
OrderTableFilters.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    supplier: PropTypes.string,
    setSupplier: PropTypes.func,
    status: PropTypes.string,
    setStatus: PropTypes.func,
    onEnterSearch: PropTypes.func,
}
export default OrderTableFilters
