import addressApi from '~/api/addressApi'
import branchApi from '~/api/branchApi'
import countryApi from '~/api/countryApi'
import serviceCenterApi from '~/api/serviceCenterApi'
import timezoneApi from '~/api/timezoneApi'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { removeEmptyValue } from '~/utils/helpers'

export const useServiceCenterListQuery = (params) => {
    return useQuery(
        ['service_center', params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await serviceCenterApi.getServiceCenters(p)
            return { data: data.data, total: data.meta?.total }
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useServiceCenterListOptionQuery = (setServiceCenter, params) => {
    return useQuery(
        ['service_center_option'],
        async () => {
            const { data } = await serviceCenterApi.getServiceCenters(params)
            return { data: data.data, total: data.meta?.total }
        },
        {
            onSuccess: (data) => {
                setServiceCenter(data)
            },
            onError: () => {},
            retry: false,
        },
    )
}

export const useGetServiceCenterDetailQuery = (id) => {
    return useQuery(
        ['service_center_detail', id],
        async () => {
            const { data } = await serviceCenterApi.getServiceCenterDetail(id)
            return { data: data.data }
        },
        {
            onSuccess: (data) => {},
        },
    )
}

export const useGetAllRegionsQuery = () => {
    return useQuery(
        ['region_all'],
        async () => {
            const { data } = await serviceCenterApi.getRegionAll()
            return { data: data.data }
        },
        {
            onSuccess: (data) => {},
        },
    )
}

export const useTeamServiceCenterQuery = (params) => {
    const { id } = useParams()
    return useQuery(
        ['service_center_team', params, id],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await serviceCenterApi.getTeamServiceCenter(p, id)
            return { data: data.data, total: data.meta?.total }
        },
        {
            onSuccess: (data) => {},
        },
    )
}

export const useAddressesListQuery = (params) => {
    return useQuery(
        ['address', params],
        async () => {
            const { data } = await addressApi.getAddresses(params)
            return data.data
        },
        {
            onSuccess: (data) => {},
        },
    )
}

export const useBranchesListQuery = (params) => {
    return useQuery(
        ['dell_branches', params],
        async () => {
            const { data } = await branchApi.getBranches(params)
            return data.data
        },
        {
            onSuccess: (data) => {},
        },
    )
}

export const useCountriesListQuery = (params) => {
    return useQuery(
        ['iso_countries', params],
        async () => {
            const { data } = await countryApi.getCountries(params)
            return data.data
        },
        {
            onSuccess: (data) => {},
        },
    )
}

export const useTimezonesListQuery = (params) => {
    return useQuery(
        ['timezones', params],
        async () => {
            const { data } = await timezoneApi.getTimezones(params)
            return data.data
        },
        {
            onSuccess: (data) => {},
        },
    )
}
