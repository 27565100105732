import {
    Divider,
    Table as MuiTable,
    Paper,
    TableContainer,
} from '@mui/material'
import { DispatchHeadCells } from '~/components/HeaderTable/DispatchHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableHeader from '~/components/TableHeader'
import Toolbar from '~/components/Toolbar'
import useTable from '~/hooks/useTable'
import { DispatchesTableBody } from '~/pages/Dispatch/DispatchesTable'
import { useParams } from 'react-router-dom'
import { useDeviceDispatchesQuery } from './query'

const localization = 'pages.dispatches.dispatch.overview.'

const DeviceDispatchList = () => {
    const { id } = useParams()

    const {
        data,
        isLoading,
        results,
        page,
        order,
        orderBy,
        search,
        searchHandler,
        sortHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useDeviceDispatchesQuery,
        initialStates: {
            orderBy: '',
        },
        queryAttributes: {
            id,
        },
    })

    return (
        <Paper>
            <Toolbar
                filterSearch={search}
                onFilterSearch={searchHandler}
                onEnterSearch={onEnterSearch}
            />
            <Divider />
            <TableContainer
                sx={{ minWidth: 750 }}
                aria-labelledby='tableTitle'
                size='medium'
            >
                <MuiTable>
                    <TableHeader
                        headCells={DispatchHeadCells}
                        localization={localization}
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />

                    <DispatchesTableBody
                        isLoading={isLoading}
                        dispatches={results}
                    />
                </MuiTable>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}
export default DeviceDispatchList
