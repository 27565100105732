import axiosClient from '~/api/base'

class ServiceCenterApi {
    getServiceCenters = (params) => {
        return axiosClient.get('api/definition/service-centers', { params })
    }

    getAllServiceCenters = (params) =>
        axiosClient.get('api/definition/service-centers/all', {
            params,
        })

    getServiceCenterDetail = (id) =>
        axiosClient.get(`api/definition/service-centers/${id}`)

    createServiceCenter = (payload) => {
        return axiosClient.post('api/definition/service-centers', payload)
    }

    updateServiceCenter = (id, payload) => {
        return axiosClient.put(`api/definition/service-centers/${id}`, payload)
    }

    updateWmsEnable = (id, payload) => {
        return axiosClient.post(
            `api/definition/service-centers/${id}/update-wms-enable`,
            payload,
        )
    }

    getTeamServiceCenter = (params, id) => {
        return axiosClient.get(`api/definition/service-centers/${id}/teams`, {
            params,
        })
    }

    updateSeparateTriage = (id, payload) => {
        return axiosClient.put(
            `api/definition/service-centers/${id}/update-separate-triage`,
            payload,
        )
    }
}
export default new ServiceCenterApi()
