import { useTranslation } from 'react-i18next'
import Table from './Table'
import BreadCrumb from '~/components/BreadCrumb'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_DEFINITION,
    ADMINISTRATOR_DEFINITION_TEAM,
    ADMINISTRATOR_DEFINITION_TEAM_NEW,
} from '~/constants/Routes'
import PageName from '~/components/PageName'
import ListToolbar from '~/components/ListToolbar'
import { useMemo } from 'react'
import AddIcon from '@mui/icons-material/Add'
import ComponentTooltip from '~/components/ComponentTooltip'
import usePermissions from '~/hooks/usePermission'

const Team = () => {
    const { t } = useTranslation()
    const { permissions } = usePermissions()
    const BreadComponents = [
        { route: ADMINISTRATOR, title: t(`breadCrumb.Administrator`) },
        {
            route: ADMINISTRATOR_DEFINITION,
            title: 'Definitions',
        },
        {
            route: ADMINISTRATOR_DEFINITION_TEAM,
            title: 'Teams',
        },
    ]

    const toolBarButtons = useMemo(() => {
        return [
            {
                button: 'create',
                navigateLink: ADMINISTRATOR_DEFINITION_TEAM_NEW,
                icon: <AddIcon />,
                isPermission: permissions.isSuperAdmin,
            },
        ]
    }, [permissions.isSuperAdmin])

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_DEFINITION_TEAM}
            >
                <div>
                    <PageName name={t(`breadCrumb.Teams`)} />
                </div>
            </ComponentTooltip>
            <ListToolbar toolBarButton={toolBarButtons} />
            <Table />
        </>
    )
}

export default Team
