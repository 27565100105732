import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import { alpha, styled } from '@mui/material/styles'
import propTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light'
                ? 'rgb(55, 65, 81)'
                : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}))

const ActionMenu = ({ children, openUpload = false }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const { t } = useTranslation()
    const [openMenu, setOpenMenu] = useState(false)

    useEffect(() => {
        if (openUpload) {
            setOpenMenu(false)
        }
    }, [openUpload])

    return (
        <>
            <Button
                variant='contained'
                onClick={(event) => {
                    setAnchorEl(event.currentTarget)
                    setOpenMenu(true)
                }}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                    textTransform: 'none',
                    backgroundColor: '#76B72A',
                    '&:hover': {
                        backgroundColor: '#102F44',
                        color: 'white',
                    },
                }}
            >
                {t('button.action')}
            </Button>
            <StyledMenu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={() => {
                    setAnchorEl(null)
                    setOpenMenu(false)
                }}
            >
                {children}
            </StyledMenu>
        </>
    )
}

ActionMenu.propTypes = {
    children: propTypes.node,
    openUpload: propTypes.bool,
}

export default ActionMenu
