import { useLocation } from 'react-router-dom'
import useComponentTooltipDataQuery from '~/hooks/useComponentTooltipDataQuery'

const useComponentTooltip = () => {
    const location = useLocation()
    const current_url = location.pathname
    const secondSlashIdx = current_url.indexOf('/', 1)
    const firstPartUrl = current_url.substring(0, secondSlashIdx)

    const { data: componentTooltipData } = useComponentTooltipDataQuery({
        page_url: firstPartUrl,
    })

    return { componentTooltipData, current_url }
}

export default useComponentTooltip
