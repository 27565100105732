import Proptypes from 'prop-types'
import { Grid, TextField } from '@mui/material'
import { LoadingButton } from '../Button'
import Stack from '@mui/material/Stack'

const inputLabelProps = {
    shrink: true,
}

const FormFieldWithAction = ({
    innerRef,
    input,
    meta,
    label,
    actionTitle,
    handler,
    actionIcon,
    isButtonRight,
    isLoading,
    isDisabled,
    ...props
}) => {
    const hasErrors = meta.touched && meta.invalid
    const helperText = hasErrors && (meta.error || meta.submitError)
    const button = (
        <LoadingButton
            startIcon={actionIcon}
            label={actionTitle}
            onClick={handler}
            loading={isLoading}
            disabled={isDisabled}
        />
    )

    const textField = (
        <TextField
            InputProps={{ ...props }}
            {...input}
            fullWidth
            ref={innerRef}
            error={hasErrors}
            helperText={helperText}
            InputLabelProps={inputLabelProps}
            variant='outlined'
            sx={{ width: '100%' }}
        />
    )

    return (
        <Grid item xs={12}>
            {label}
            <Stack direction='row' spacing={2}>
                {isButtonRight ? [textField, button] : [button, textField]}
            </Stack>
        </Grid>
    )
}

FormFieldWithAction.defaultProps = {
    innerRef: null,
    input: {},
    meta: {},
    title: '',
    isButtonRight: true,
}

FormFieldWithAction.propTypes = {
    innerRef: Proptypes.oneOfType([
        Proptypes.func,
        Proptypes.shape({ current: Proptypes.any }),
    ]),
    input: Proptypes.objectOf(Proptypes.any),
    meta: Proptypes.objectOf(Proptypes.any),
    title: Proptypes.string,
    label: Proptypes.string,
    actionTitle: Proptypes.string,
    handler: Proptypes.func,
    actionIcon: Proptypes.object,
    isButtonRight: Proptypes.bool,
    isLoading: Proptypes.bool,
    isDisabled: Proptypes.bool,
}

export default FormFieldWithAction
