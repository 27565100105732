import { Button, Divider, Menu, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import EditIcon from '@mui/icons-material/Edit'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { alpha, styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light'
                ? 'rgb(55, 65, 81)'
                : theme.palette.grey[300],
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}))

const ProjectAddonActionButton = ({
    row,
    onDeleteAddonClick,
    onEditAddonClick,
}) => {
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = useState()
    const open = Boolean(anchorEl)
    const actionClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <Button
                id='action-button-dropdown'
                aria-controls={open ? 'customized-action' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                variant='contained'
                disableElevation
                onClick={actionClick}
                endIcon={<KeyboardArrowDownIcon />}
                style={{ backgroundColor: '#76b72a' }}
            >
                {t('button.action')}
            </Button>
            <StyledMenu
                id='customized-action'
                MenuListProps={{
                    'aria-labelledby': 'action-button-dropdown',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={(e) => {
                        onDeleteAddonClick(e, row)
                        setAnchorEl(null)
                    }}
                    disabled={false}
                    disableRipple
                >
                    <DeleteForeverIcon />
                    {t('button.delete')}
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                    onClick={(e) => {
                        onEditAddonClick(e, row)
                        setAnchorEl(null)
                    }}
                    disabled={false}
                    disableRipple
                >
                    <EditIcon />
                    {t('button.edit')}
                </MenuItem>
            </StyledMenu>
        </>
    )
}

ProjectAddonActionButton.propTypes = {
    row: PropTypes.object.isRequired,
    onEditAddonClick: PropTypes.func.isRequired,
    onDeleteAddonClick: PropTypes.func.isRequired,
}

export default ProjectAddonActionButton
