import CheckboxesField from '~/components/FormInput/CheckboxesField'
import { FormControl, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Field } from 'react-final-form'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import { useCallback, useMemo } from 'react'
import InputField from '~/components/FinalForm/InputField'

import { ADMINISTRATOR_ROLE_CREATE } from '~/constants/Routes'
import SelectField from '~/components/FinalForm/SelectField'
import PropTypes from 'prop-types'

const pagePath = ADMINISTRATOR_ROLE_CREATE
function Body({ localization, updateGuardName, guards, form }) {
    const { t } = useTranslation()
    const guardNameList = useMemo(() => {
        if (guards) {
            return guards.map((guard) => ({
                name: guard,
                value: guard,
            }))
        }
    }, [guards])

    const updateGuard = useCallback(
        (guardName) => {
            form.resetFieldState('name')
            updateGuardName(guardName)
        },
        [form, updateGuardName],
    )

    return (
        <FormBodyWrapper>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl sx={{ width: '100%' }}>
                        <Field
                            name='guard_name'
                            component={SelectField}
                            items={guardNameList}
                            localization={localization}
                            componentId={`role_form_guard_name`}
                            pagePath={pagePath}
                            nameSelect='guard_name'
                            isRequired={true}
                            onUpdate={updateGuard}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <InputField
                        localization={localization}
                        name='name'
                        componentId={`role_form_name`}
                        pagePath={pagePath}
                        isRequired={true}
                        placeholder={t(`${localization}.name`)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CheckboxesField
                        localization={localization}
                        name={`permissions`}
                    />
                </Grid>
            </Grid>
        </FormBodyWrapper>
    )
}

Body.propTypes = {
    localization: PropTypes.string,
    updateGuardName: PropTypes.string,
    guards: PropTypes.array,
    form: PropTypes.object,
}

export default Body
