import PropTypes from 'prop-types'
import {
    Box,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { useTranslation } from 'react-i18next'

const TableHeader = ({
    headCells,
    onRequestSort,
    order,
    orderBy,
    localization = '',
}) => {
    const { t } = useTranslation()
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => {
                    if (headCell.hidden) {
                        return <TableCell key={headCell.id} />
                    }
                    return (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align ?? 'left'}
                            padding={
                                headCell.disablePadding ? 'none' : 'normal'
                            }
                            sortDirection={
                                orderBy === headCell.id ? order : false
                            }
                        >
                            {headCell.orderable ? (
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={
                                        orderBy === headCell.id ? order : 'asc'
                                    }
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {t(`${localization}${headCell.label}`)}
                                    {orderBy === headCell.id ? (
                                        <Box
                                            component='span'
                                            sx={visuallyHidden}
                                        >
                                            {order === 'desc'
                                                ? 'sorted descending'
                                                : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            ) : (
                                <TableSortLabel hideSortIcon={true}>
                                    {t(`${localization}${headCell.label}`)}
                                </TableSortLabel>
                            )}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

TableHeader.propTypes = {
    headCells: PropTypes.array.isRequired,
    onRequestSort: PropTypes.func,
    order: PropTypes.oneOf(['asc', 'desc']),
    orderBy: PropTypes.string,
    localization: PropTypes.string,
}

export default TableHeader
