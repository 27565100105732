import Proptypes from 'prop-types'
import { InputAdornment, TextField } from '@mui/material'
import { AccountCircle } from '@mui/icons-material'

const inputProps = {
    startAdornment: (
        <InputAdornment position='start'>
            <AccountCircle />
        </InputAdornment>
    ),
}

const inputLabelProps = {
    shrink: true,
}

const UserFormInput = ({ innerRef, input, meta, title, ...props }) => {
    const hasErrors = meta.touched && meta.invalid
    const helperText = hasErrors && (meta.error || meta.submitError)

    return (
        <>
            <TextField
                autoFocus
                {...input}
                {...props}
                fullWidth
                ref={innerRef}
                error={hasErrors}
                helperText={helperText}
                InputLabelProps={inputLabelProps}
                InputProps={inputProps}
                variant='outlined'
                sx={{ width: '100%' }}
            />
        </>
    )
}

UserFormInput.defaultProps = {
    innerRef: null,
    input: {},
    meta: {},
    title: '',
}

UserFormInput.propTypes = {
    innerRef: Proptypes.oneOfType([
        Proptypes.func,
        Proptypes.shape({ current: Proptypes.any }),
    ]),
    input: Proptypes.objectOf(Proptypes.any),
    meta: Proptypes.objectOf(Proptypes.any),
    title: Proptypes.string,
}

export default UserFormInput
