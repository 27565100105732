import { Grid } from '@mui/material'
import AccordionDevice from '~/components/AccordionDevice'
import { useState } from 'react'
import PurchaseOrderLineToolbar from './PurchaseOrderLineToolbar'
import OrderSession from './OrderSession'
import OrderLineSession from './OrderLineSession'
import OrderEntitlementSession from './OrderEntitlementSession'
import OrderSpecificationSession from './OrderSpecificationSession'
import PropTypes from 'prop-types'

const localization = 'pages.procurement.purchaseOrder.orderLines'

const accordionsList = [
    {
        index: 1,
        title: 'PurchaseOrder',
        content: <OrderSession />,
    },
    {
        index: 2,
        title: 'PurchaseOrderLine',
        content: <OrderLineSession />,
    },
    {
        index: 3,
        title: 'PurchaseOrderEntitlement',
        content: <OrderEntitlementSession />,
    },
    {
        index: 4,
        title: 'PurchaseOrderSpecification',
        content: <OrderSpecificationSession />,
    },
]

const accordionArray = accordionsList.map((item) => item.index)

const PurchaseOrderLineDetail = ({ orderLineContent }) => {
    const [accordions, setAccordions] = useState(accordionArray)

    const handleChange = (panel) => (event) => {
        if (accordions.includes(panel)) {
            const filteredItems = accordions.filter((item) => item !== panel)
            setAccordions(filteredItems)
        }
        if (!accordions.includes(panel)) {
            accordions.push(panel)
            setAccordions([...accordions])
        }
    }

    const showAll = () => {
        setAccordions(accordionArray)
    }

    const hideAll = () => {
        setAccordions([])
    }

    return (
        <>
            <PurchaseOrderLineToolbar showAll={showAll} hideAll={hideAll} />
            {orderLineContent && (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {accordionsList.map((item) => (
                            <AccordionDevice
                                key={item.index}
                                localization={localization}
                                item={item}
                                accordions={accordions}
                                handleChange={handleChange}
                            />
                        ))}
                    </Grid>
                </Grid>
            )}
        </>
    )
}

PurchaseOrderLineDetail.propTypes = { orderLineContent: PropTypes.any }

export default PurchaseOrderLineDetail
