export const ProductionProjectHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
    },
    {
        id: 'project_nr',
        numeric: false,
        disablePadding: false,
        label: 'project_nr',
        orderable: false,
    },
    {
        id: 'entity_tid',
        numeric: false,
        disablePadding: false,
        label: 'entity_tid',
        orderable: false,
    },
    {
        id: 'service_center_id',
        numeric: false,
        disablePadding: false,
        label: 'service_center_id',
        orderable: false,
    },
    {
        id: 'external_id',
        numeric: false,
        disablePadding: false,
        label: 'external_id',
        orderable: false,
    },
    {
        id: 'production_image_id',
        numeric: false,
        disablePadding: false,
        label: 'production_image_id',
        orderable: false,
    },
    {
        id: 'active',
        numeric: false,
        disablePadding: false,
        label: 'active',
        orderable: false,
    },
    {
        id: 'intune_group_tag',
        numeric: false,
        disablePadding: false,
        label: 'intune_group_tag',
        orderable: false,
    },
    {
        id: 'lastseen',
        numeric: false,
        disablePadding: false,
        label: 'lastseen',
        orderable: false,
        dataType: 'datetime',
    },
]

export const ProductionProjectDetailHeadCells = [
    {
        id: 'language',
        numeric: false,
        disablePadding: false,
        label: 'language',
        orderable: false,
    },
    {
        id: 'target_group',
        numeric: false,
        disablePadding: false,
        label: 'target_group',
        orderable: false,
    },
    {
        id: 'delivery_date',
        numeric: false,
        disablePadding: false,
        label: 'delivery_date',
        orderable: false,
        dataType: 'datetime',
    },
    {
        id: 'state',
        numeric: false,
        disablePadding: false,
        label: 'state',
        orderable: false,
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'created',
        orderable: false,
        dataType: 'datetime',
    },
]

export const ProductionProjectAddonHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: false,
    },
    {
        id: 'addon_id',
        numeric: false,
        disablePadding: false,
        label: 'addon_id',
        orderable: false,
    },
    {
        id: 'params',
        numeric: false,
        disablePadding: false,
        label: 'params',
        orderable: false,
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'created',
        orderable: false,
        dataType: 'datetime',
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: 'action',
        orderable: false,
    },
]
