import { Form } from 'react-final-form'
import { useParams } from 'react-router-dom'
import { useTooltipDetailQuery } from './query'
import BreadCrumb from '~/components/BreadCrumb'
import FormToolbar from '~/components/FinalForm/FormToolbar'
import { LoadingButton } from '~/components/Button'
import { useTranslation } from 'react-i18next'
import useFormValidate from './hooks/useFormValidate'
import useBreadCrumbConfig from './hooks/useBreadCrumbConfig'
import useActions from './hooks/useActions'
import ComponentTooltip from '~/components/ComponentTooltip'

import { ADMINISTRATOR_TOOLTIP_DETAIL } from '~/constants/Routes'
import PageName from '~/components/PageName'
import Loading from '~/components/Loading'
import BodyForm from './components/BodyForm'
import ToolBarButton from '~/components/ToolBarButton'

const TooltipDetail = () => {
    const localization = `pages.administrator.tooltip`

    const { t } = useTranslation()
    const { id } = useParams()
    const { data, isLoading } = useTooltipDetailQuery({ id })

    const validate = useFormValidate()
    const {
        saveHandler,
        deleteHandler,
        isLoading: isLoadingAction,
    } = useActions()

    const breadCrumbConfig = useBreadCrumbConfig(`Tooltip ${data?.id}`)
    if (isLoading) {
        return <Loading />
    }
    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_TOOLTIP_DETAIL}
            >
                <div>
                    <PageName name='Edit Tooltips' />
                </div>
            </ComponentTooltip>
            <Form
                validate={validate}
                initialValues={data}
                onSubmit={saveHandler}
                render={({ handleSubmit, values, dirty }) => (
                    <form onSubmit={handleSubmit}>
                        <FormToolbar>
                            <ToolBarButton
                                color='error'
                                handleAction={deleteHandler}
                                name={t(`button.delete`)}
                            />
                            <LoadingButton
                                disabled={!dirty}
                                label={t(`${localization}.save`)}
                                type={`submit`}
                                loading={isLoadingAction}
                            />
                        </FormToolbar>
                        <BodyForm {...{ localization, values }} />
                    </form>
                )}
            />
        </>
    )
}

export default TooltipDetail
