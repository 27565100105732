import axiosClient from '~/api/base'

class PurchaseOrderApi {
    getPurchaseOrders = (params) => {
        return axiosClient.get('api/procurement/purchase-order', {
            params,
        })
    }

    getPurchaseOrderDetail = (id, params) =>
        axiosClient.get(`api/procurement/purchase-order/${id}`, { params })

    getPurchaseOrderLineDetail = (id) =>
        axiosClient.get(
            `api/procurement/purchase-order/purchase-order-line/${id}`,
        )
}

export default new PurchaseOrderApi()
