import PropTypes from 'prop-types'
import { Box, TableCell, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const DataNotFound = ({ colSpan, message }) => {
    const { t } = useTranslation()
    return (
        <TableRow>
            <TableCell align='center' colSpan={colSpan} sx={{ py: 3 }}>
                <Box>
                    <Typography gutterBottom align='center' variant='subtitle1'>
                        {message || t(`message.Notfound`)}
                    </Typography>
                </Box>
            </TableCell>
        </TableRow>
    )
}

DataNotFound.propTypes = {
    colSpan: PropTypes.number,
    message: PropTypes.string,
}

export default DataNotFound
