import PropTypes from 'prop-types'
import { Box, Stack, Toolbar } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import AccordionsButton from './AccordianButton'
import ToolBarButton from './ToolBarButton'

const ToolbarDetailPage = ({
    showAll = () => {},
    hideAll = () => {},
    Buttons = [],
}) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    return (
        <Toolbar disableGutters={false}>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Stack direction='row-reverse' spacing={2}>
                {Buttons.map((btn, index) => (
                    <ToolBarButton
                        key={index}
                        color={btn.color}
                        icon={btn.icon}
                        handleAction={() => navigate(`${btn.navigateLink}`)}
                        name={t(`button.${btn.button}`)}
                    />
                ))}

                <AccordionsButton onClick={hideAll}>Hide All</AccordionsButton>
                <AccordionsButton onClick={showAll}>Show All</AccordionsButton>
            </Stack>
        </Toolbar>
    )
}
ToolbarDetailPage.propTypes = {
    showAll: PropTypes.func,
    hideAll: PropTypes.func,
    Buttons: PropTypes.array.isRequired,
}
export default ToolbarDetailPage
