import { Grid } from '@mui/material'
import BreadCrumb from '~/components/BreadCrumb'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import FieldWrapper from '~/components/FormInput/FieldWrapper'
import FormInput from '~/components/FormInput/FormInput'
import SingleRadioField from '~/components/FormInput/SingleRadioField'
import PageName from '~/components/PageName'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

export const Layout = ({ children, breadcrumbs, pageName }) => {
    return (
        <>
            <BreadCrumb {...{ breadcrumbs }} />
            <PageName name={pageName} />
            {children}
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.any,
    breadcrumbs: PropTypes.array,
    pageName: PropTypes.string,
}

export const IssueCreateUpdateFormBody = ({ initialValues }) => {
    const { t } = useTranslation()
    const localization = 'pages.issues.create'
    const options = [
        { value: 1, label: 'True' },
        { value: 0, label: 'False' },
    ]
    return (
        <FormBodyWrapper>
            <Grid container spacing={2}>
                <FieldWrapper
                    isRequired={1}
                    title={t(`${localization}.ticket_id`)}
                >
                    <Field
                        name='ticket_id'
                        title={t(`${localization}.ticket_id`)}
                        component={FormInput}
                        parse={(x) => x || null}
                    />
                </FieldWrapper>
                <FieldWrapper title={t(`${localization}.esd_location`)}>
                    <Field
                        name='esd_location'
                        title={t(`${localization}.esd_location`)}
                        component={FormInput}
                        parse={(x) => x || null}
                    />
                </FieldWrapper>
                <FieldWrapper
                    isRequired={1}
                    title={t(`${localization}.serial`)}
                >
                    <Field
                        name='serial'
                        title={t(`${localization}.serial`)}
                        component={FormInput}
                        parse={(x) => x || null}
                    />
                </FieldWrapper>
                {[
                    'description',
                    'service_id',
                    'service_center_tid',
                    'brand',
                    'school_id',
                    'device_user_id',
                    'contract_id',
                ].map((fieldName, key) => (
                    <FieldWrapper
                        key={key}
                        title={t(`${localization}.${fieldName}`)}
                    >
                        <Field
                            name={fieldName}
                            title={t(`${localization}.${fieldName}`)}
                            component={FormInput}
                            parse={(x) => x || null}
                        />
                    </FieldWrapper>
                ))}
                <FieldWrapper title={t(`${localization}.damaged`)}>
                    <SingleRadioField
                        defaultValue={initialValues?.damaged}
                        options={options}
                        name={`damaged`}
                        style={{
                            paddingLeft: '16px',
                        }}
                    />
                </FieldWrapper>
                <FieldWrapper title={t(`${localization}.damage_description`)}>
                    <Field
                        name='damage_description'
                        title={t(`${localization}.damage_description`)}
                        component={FormInput}
                        parse={(x) => x || null}
                    />
                </FieldWrapper>
                <FieldWrapper title={t(`${localization}.powers_on`)}>
                    <SingleRadioField
                        defaultValue={initialValues?.powers_on}
                        options={options}
                        name={`powers_on`}
                        style={{
                            paddingLeft: '16px',
                        }}
                    />
                </FieldWrapper>
                <FieldWrapper title={t(`${localization}.liquid_damage`)}>
                    <SingleRadioField
                        defaultValue={initialValues?.liquid_damage}
                        options={options}
                        name={`liquid_damage`}
                        style={{
                            paddingLeft: '16px',
                        }}
                    />
                </FieldWrapper>
                {[
                    'cause_tid',
                    'accessories',
                    'loaner_id',
                    'webshop_id',
                    'image_version_tid',
                    'language',
                    'entity_tid',
                    'previous_id',
                ].map((fieldName, key) => (
                    <FieldWrapper
                        key={key}
                        title={t(`${localization}.${fieldName}`)}
                    >
                        <Field
                            name={fieldName}
                            title={t(`${localization}.${fieldName}`)}
                            component={FormInput}
                            parse={(x) => x || null}
                        />
                    </FieldWrapper>
                ))}
            </Grid>
        </FormBodyWrapper>
    )
}

IssueCreateUpdateFormBody.propTypes = { initialValues: PropTypes.object }
