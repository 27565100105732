export const WorkbenchHeadCells = [
    {
        id: 'id',
        label: 'id',
        orderable: true,
    },
    {
        id: 'name',
        label: 'name',
        orderable: true,
    },
    {
        id: 'location',
        label: 'location',
        orderable: true,
    },
    {
        id: 'service_center',
        label: 'serviceCenter',
        orderable: false,
    },

    {
        id: 'created_at',
        label: 'createdAt',
    },
    {
        id: 'updated_at',
        label: 'updatedAt',
    },
]
