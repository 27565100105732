import { axiosClient, clientDownload } from '~/api/base'

class ComponentApi {
    getComponents = (params) => {
        return axiosClient.get('api/components', {
            params,
        })
    }

    getComponentsByVendor = (vendorId) => {
        return axiosClient.get(`api/components/vendor/${vendorId}`)
    }

    getComponentsExport = (payload) =>
        clientDownload.post('api/components/export-component', payload)

    getComponentDetail = (id) => {
        return axiosClient.get(`api/components/${id}`)
    }

    getComponentDevices = (id, params) => {
        return axiosClient.get(`api/components/${id}/devices`, { params })
    }

    setComponentsPartNumber = (payload) => {
        return axiosClient.put('api/components/part-number', payload)
    }

    setComponentsPartCode = (id, payload) => {
        return axiosClient.put(`api/components/${id}/part-code`, payload)
    }
}

export default new ComponentApi()
