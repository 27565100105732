export const headCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: false,
        label: 'ticket_id',
        orderable: true,
    },
    {
        id: 'esd_location',
        numeric: true,
        disablePadding: false,
        label: 'esd_location',
        orderable: true,
    },
    {
        id: 'serial',
        numeric: true,
        disablePadding: false,
        label: 'name',
        orderable: true,
    },
    {
        id: 'brand',
        numeric: true,
        disablePadding: false,
        label: 'brand',
        orderable: true,
    },
    {
        id: 'wo_status',
        numeric: true,
        disablePadding: false,
        label: 'status',
        orderable: true,
    },
    {
        id: 'state',
        numeric: true,
        disablePadding: false,
        label: 'state',
        orderable: true,
    },
    {
        id: 'language',
        numeric: true,
        disablePadding: false,
        label: 'language',
        orderable: true,
    },
    {
        id: 'previous_id',
        numeric: true,
        disablePadding: false,
        label: 'previous_id',
        orderable: true,
    },
    {
        id: 'created_at',
        numeric: true,
        disablePadding: false,
        label: 'created_at',
        orderable: true,
    },
]
