import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import { PRODUCTION_SYSTEMIMAGE } from '~/constants/Routes'
import ImagesTable from './ImagesTable'

const ImagesOverview = () => {
    const breadCrumbConfig = [
        { route: PRODUCTION_SYSTEMIMAGE, title: 'Production' },
        { route: '#', title: 'Images' },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <PageName name={'Images'} />
            <ImagesTable />
        </>
    )
}

export default ImagesOverview
