import { Grid, Typography } from '@mui/material'
import StyledModal from '~/components/StyledModal'
import InputFile from '~/pages/Device/Serial/SerialDetail/Component/InputFile'
import SaveButtonPopup from '~/pages/Device/Serial/SerialDetail/Component/SaveButtonPopup'
import UploadModalTitle from '~/pages/Device/Serial/SerialDetail/Component/UploadModalTitle'
import { Form } from 'react-final-form'
import { useUploadPicturesForQcMutation } from '../mutation'
import { useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import PropTypes from 'prop-types'

const UploadQcPicturePopup = ({
    open,
    setOpenUploadPicture,
    setOverrulePictures,
    isOverrule = false,
}) => {
    const { issueId } = useParams()

    const queryClient = useQueryClient()

    const { mutate: uploadPicture, isLoading } =
        useUploadPicturesForQcMutation()

    const handleSubmit = (values) => {
        if (isOverrule) {
            setOverrulePictures((prev) => [
                ...prev,
                {
                    src: URL.createObjectURL(values.pictures.item(0)),
                    image: values.pictures.item(0),
                    id: URL.createObjectURL(values.pictures.item(0)),
                    checked: false,
                    notConvert: true,
                },
            ])
        } else {
            uploadPicture(
                { issueId, pictures: [values.pictures.item(0)], upload: true },
                {
                    onSettled: () => {
                        queryClient.invalidateQueries('issue_qc_images')
                        setOpenUploadPicture(false)
                    },
                },
            )
        }
    }

    const handleClose = () => {
        setOpenUploadPicture(false)
    }

    return (
        <StyledModal open={open} handleClose={() => handleClose()}>
            <Grid container spacing={0.5}>
                <UploadModalTitle
                    componentId={'upload_picture_popup_title'}
                    title={'Upload Picture'}
                />
                <Grid item xs={12} sm={12}>
                    <Form
                        onSubmit={handleSubmit}
                        render={({ handleSubmit, form }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid item xs={12}>
                                    <Typography>File</Typography>
                                    <InputFile
                                        form={form}
                                        nameField='pictures'
                                    />
                                </Grid>
                                <SaveButtonPopup {...{ isLoading }} />
                            </form>
                        )}
                    />
                </Grid>
            </Grid>
        </StyledModal>
    )
}

UploadQcPicturePopup.propTypes = {
    open: PropTypes.bool,
    setOpenUploadPicture: PropTypes.func,
    isOverrule: PropTypes.bool,
    setOverrulePictures: PropTypes.func,
}

export default UploadQcPicturePopup
