import { Typography } from '@mui/material'
import PropTypes from 'prop-types'

const SectionName = ({ name, paddingBottom }) => {
    return (
        <Typography variant='h6' textAlign='left' paddingBottom={paddingBottom}>
            {name}
        </Typography>
    )
}
SectionName.propTypes = {
    name: PropTypes.string,
    paddingBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
export default SectionName
