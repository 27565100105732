import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import PageName from '~/components/PageName'
import { FLEET_OVERVIEW, FLEET_SERIAL } from '~/constants/Routes'
import { useSnackbar } from '~/hooks/useSnackbar'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SelectErrorMessage } from '~/store/device/selector'
import { setMessageDeviceError } from '~/store/device/slice'
import SerialTable from './SerialTable'

const SerialOverview = () => {
    const { showSnackbar, snackbar } = useSnackbar()

    const dispatch = useDispatch()
    const getErrorMessageDevice = useSelector(SelectErrorMessage)

    useEffect(() => {
        if (getErrorMessageDevice) {
            showSnackbar(getErrorMessageDevice, 'error')
            dispatch(setMessageDeviceError(null))
        }
    }, [dispatch, getErrorMessageDevice, showSnackbar])

    const BreadComponents = [
        { route: FLEET_OVERVIEW, title: 'Fleet' },
        {
            route: FLEET_SERIAL,
            title: 'Serial',
        },
    ]

    return (
        <>
            {snackbar}
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={FLEET_SERIAL}
            >
                <div>
                    <PageName name={'Serials'} />
                </div>
            </ComponentTooltip>

            <SerialTable />
        </>
    )
}

export default SerialOverview
