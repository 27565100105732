import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectUserDetailInformation } from '~/store/user/selector'
import UserTeams from './UserTeams/UserTeams'
import LoadingButton from '@mui/lab/LoadingButton'
import { useMemo, useState } from 'react'
import AddTeamPopup from '~/pages/UserDetail/components/UserTeams/AddTeamPopup'
import { useTeamsUserNotInQuery } from '~/pages/UserDetail/query'

const localization = 'pages.user.userDetail'

const UserAttributes = () => {
    const { t } = useTranslation()
    const selectUser = useSelector(selectUserDetailInformation)
    const [openAddTeamPopup, setOpenAddTeamPopup] = useState(false)
    const { data: teams } = useTeamsUserNotInQuery(selectUser?.id)
    const teamJoinable = useMemo(() => {
        return teams?.data?.length > 0
    }, [teams])

    return (
        <Grid item xs={12} md={6} lg={8}>
            <Stack direction='column' spacing={3}>
                <Card>
                    <CardContent>
                        <Box
                            sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                            }}
                        >
                            <Box sx={{ my: 3, mx: 2 }}>
                                <Grid container alignItems='center'>
                                    <Stack direction='column'>
                                        <Grid item xs>
                                            <Typography
                                                gutterBottom
                                                variant='h4'
                                                component='div'
                                            >
                                                {t(`${localization}.userData`)}
                                            </Typography>
                                        </Grid>
                                        <Stack direction='row' spacing={10}>
                                            <Grid item>
                                                <Typography
                                                    gutterBottom
                                                    variant='h6'
                                                    component='div'
                                                >
                                                    {t(
                                                        `${localization}.emailAddress`,
                                                    )}
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant='h8'
                                                    component='div'
                                                >
                                                    {selectUser?.email}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    gutterBottom
                                                    variant='h6'
                                                    component='div'
                                                >
                                                    {t(`${localization}.phone`)}
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant='h8'
                                                    component='div'
                                                >
                                                    {selectUser?.phone}
                                                </Typography>
                                            </Grid>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Box
                            sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                            }}
                        >
                            <Box sx={{ m: 2 }}>
                                <Stack direction='column'>
                                    <Grid item xs>
                                        <Typography
                                            gutterBottom
                                            variant='h4'
                                            component='div'
                                        >
                                            {t(`${localization}.security`)}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            gutterBottom
                                            variant='h8'
                                            component='div'
                                        >
                                            {t(
                                                `${localization}.passwordSetting`,
                                            )}
                                        </Typography>
                                    </Grid>
                                </Stack>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Box
                            sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                            }}
                        >
                            <Box sx={{ m: 2 }}>
                                <Stack direction='column'>
                                    <Grid item xs>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                marginInline: 0,
                                            }}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant='h4'
                                                component='div'
                                            >
                                                {t(`${localization}.teams`)}
                                            </Typography>
                                            {teamJoinable && (
                                                <LoadingButton
                                                    variant='contained'
                                                    size='large'
                                                    sx={{
                                                        textTransform: 'none',
                                                        backgroundColor:
                                                            '#76B72A',
                                                        height: '100%',
                                                    }}
                                                    type={`button`}
                                                    onClick={() => {
                                                        setOpenAddTeamPopup(
                                                            true,
                                                        )
                                                    }}
                                                >
                                                    {t(
                                                        `${localization}.joinTeam`,
                                                    )}
                                                </LoadingButton>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            gutterBottom
                                            variant='h8'
                                            component='div'
                                        >
                                            <UserTeams
                                                userId={selectUser?.id}
                                            />
                                        </Typography>
                                    </Grid>
                                </Stack>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Stack>
            {openAddTeamPopup && (
                <AddTeamPopup
                    userId={selectUser?.id}
                    open={openAddTeamPopup}
                    onClose={() => {
                        setOpenAddTeamPopup(false)
                    }}
                />
            )}
        </Grid>
    )
}

export default UserAttributes
