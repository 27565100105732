import styled from '@emotion/styled'
import { Field, Form } from 'react-final-form'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import SubmitButton from '~/components/SubmitButton'
import {
    Box,
    Grid,
    Card,
    CardContent,
    Stack,
    Typography,
    Button,
} from '@mui/material'
import { FLEET_IMPORT_DEVICES } from '~/constants/Routes'
import ComponentTooltip from '~/components/ComponentTooltip'
import { useImportLenovoDispatchesMutation } from '../Dispatch/mutate'
import ProgressResultTable from './ProgressResultTable'
import { UploadFile } from '@mui/icons-material'

const LOCALIZATION = 'pages.dispatches.dispatch.import.'

const ErrorBox = styled(Box)({
    height: '7rem',
    overflow: 'auto',
})

const ErrorTypography = styled(Typography)({
    color: '#d32f2f',
    fontFamily: "'Lato',sans-serif",
    fontSize: '0.75rem',
    fontWeightL: 400,
    lineHeight: 1.66,
})

const ImportDispatch = () => {
    const { t } = useTranslation()
    const [selectedFile, setSelectedFile] = useState()
    const [fileName, setFileName] = useState('')
    const [total, setTotal] = useState(0)
    const [failed, setFailed] = useState(0)
    const [failures, setFailures] = useState([])
    const [error, setError] = useState([])
    const [dataResult, setDataResult] = useState(null)
    const [openProgress, setOpenProgress] = useState(false)
    const { openSnackbar } = useDialogContext()
    const { mutate: actionImport, isLoading } =
        useImportLenovoDispatchesMutation()

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0])
        setError([])
        setFailures([])
    }

    const onSubmit = useCallback(() => {
        actionImport(selectedFile, {
            onSuccess: (data) => {
                const result = data?.data?.data
                setFileName('')
                setDataResult(result?.data)
                setTotal(result?.total)
                setFailed(result?.failed)
                setOpenProgress(true)
                openSnackbar({
                    message:
                        result?.data?.length > 0
                            ? t('message.importCompeltedWithFailed')
                            : t('message.importSuccess'),
                    type: result?.data?.length > 0 ? 'warning' : 'success',
                })
            },
            onError: (e) => {
                setFailures(e.response.data.data)
                setError(e.response.data.message)
                openSnackbar({
                    message: t(`message.importFailed`),
                    type: 'error',
                })
            },
            onSettled: () => {
                setSelectedFile(false)
            },
        })
    }, [actionImport, selectedFile, openSnackbar, t])

    return (
        <>
            <Form
                onSubmit={onSubmit}
                render={() => (
                    <Grid item xs={12} md={6}>
                        <ComponentTooltip
                            componentId={`import_lenovo_dispatches`}
                            pagePath={FLEET_IMPORT_DEVICES}
                        >
                            <div>
                                <Typography
                                    variant='h6'
                                    sx={{ fontWeight: '400' }}
                                >
                                    {t(`${LOCALIZATION}importLenovo`)}
                                </Typography>
                            </div>
                        </ComponentTooltip>
                        <Card>
                            <CardContent>
                                <Stack direction={'row'} spacing={3}>
                                    <Grid container>
                                        <Grid
                                            item
                                            container
                                            direction='column'
                                            alignItems='center'
                                            sx={{
                                                border: '1px solid gray',
                                                borderRadius: '2px',
                                                padding: 2,
                                                borderStyle: 'dotted',
                                            }}
                                        >
                                            <UploadFile />
                                            <Typography>
                                                (
                                                {t(
                                                    'message.pleaseSelectExcelType',
                                                )}
                                                )
                                            </Typography>
                                            <Typography
                                                sx={{ padding: '10px 0' }}
                                            >
                                                {fileName && `(${fileName})`}
                                            </Typography>
                                            <Field>
                                                {(props) => {
                                                    const handleFileChange = (
                                                        event,
                                                    ) => {
                                                        setFileName(
                                                            event.target
                                                                .files[0]
                                                                ?.name || '',
                                                        )
                                                        changeHandler(event)
                                                    }

                                                    return (
                                                        <Box>
                                                            <input
                                                                id='file-import'
                                                                name='file'
                                                                type='file'
                                                                style={{
                                                                    display:
                                                                        'none',
                                                                }}
                                                                onChange={
                                                                    handleFileChange
                                                                }
                                                                accept='.xlsx'
                                                            />
                                                            <label htmlFor='file-import'>
                                                                <Button
                                                                    variant='contained'
                                                                    size='small'
                                                                    component='span'
                                                                    sx={{
                                                                        textTransform:
                                                                            'none',
                                                                        backgroundColor:
                                                                            '#76B72A',
                                                                        '&:hover':
                                                                            {
                                                                                backgroundColor:
                                                                                    '#102F44',
                                                                                color: 'white',
                                                                            },
                                                                    }}
                                                                >
                                                                    Browse...
                                                                </Button>
                                                            </label>
                                                        </Box>
                                                    )
                                                }}
                                            </Field>
                                        </Grid>
                                    </Grid>
                                </Stack>
                                <Stack
                                    direction='row-reverse'
                                    sx={{ marginTop: 1 }}
                                >
                                    <SubmitButton
                                        isLoading={isLoading}
                                        handleSubmit={onSubmit}
                                        name={`${t('button.import')}`}
                                    />
                                </Stack>
                                {((failures && failures.length > 0) ||
                                    (error && error.length > 0)) && (
                                    <Stack direction='row' spacing={3}>
                                        <Grid item xs={12}>
                                            {failures &&
                                                failures.length > 0 && (
                                                    <ErrorBox>
                                                        {failures.map(
                                                            (index) => (
                                                                <ErrorTypography
                                                                    key={
                                                                        index.row
                                                                    }
                                                                >
                                                                    (Row:{' '}
                                                                    {index.row}){' '}
                                                                    {index.contents.map(
                                                                        (
                                                                            content,
                                                                        ) =>
                                                                            content,
                                                                    )}
                                                                </ErrorTypography>
                                                            ),
                                                        )}
                                                    </ErrorBox>
                                                )}
                                            {error && (
                                                <ErrorBox>
                                                    <ErrorTypography>
                                                        {error}
                                                    </ErrorTypography>
                                                </ErrorBox>
                                            )}
                                        </Grid>
                                    </Stack>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            />

            <ProgressResultTable
                open={openProgress}
                onClose={() => setOpenProgress(false)}
                data={dataResult ?? []}
                total={total}
                failed={failed}
            />
        </>
    )
}

export default ImportDispatch
