import {
    Paper,
    TableContainer,
    TableBody as MuiTableBody,
    Table as MuiTable,
} from '@mui/material'
import FilterSearch from '~/components/Search/FilterSearch'
import PaginationTable from '~/components/PaginationTable'
import DataNotFound from '~/components/DataNotFound'
import TableHeader from '~/components/TableHeader'
import Row from '~/components/TableRow'
import useTable from '~/hooks/useTable'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeleteLineMutation } from '../mutate'
import { useLineRackScanQuery } from '../query'
import { RackScanLineHeadCells } from '~/components/HeaderTable/RackScanLineHeadCells'
import TableLoading from '~/components/Table/TableLoading'
import PropTypes from 'prop-types'

const TableBody = ({ isLoading, objects, headCells }) => {
    if (isLoading) {
        return <TableLoading colSpan={headCells.length} />
    }

    return (
        <MuiTableBody>
            {objects.length > 0 ? (
                objects.map((row, index) => {
                    return (
                        <Row row={row} columnsConfig={headCells} key={index} />
                    )
                })
            ) : (
                <DataNotFound colSpan={headCells.length} />
            )}
        </MuiTableBody>
    )
}

TableBody.propTypes = {
    isLoading: PropTypes.bool,
    objects: PropTypes.array,
    headCells: PropTypes.array,
}

const RackScanLineSession = () => {
    const {
        order,
        page,
        orderBy,
        data,
        isLoading,
        results,
        sortHandler,
        searchHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({ query: useLineRackScanQuery })

    const { t } = useTranslation()

    const { mutate: deleteLine } = useDeleteLineMutation()

    const headCells = useMemo(() => {
        return [
            ...RackScanLineHeadCells,
            {
                id: 'action',
                label: 'action',
                orderable: true,
                clickable: true,
                handleAction: (row, setLoading) => {
                    setLoading(true)
                    deleteLine(row, {
                        onSuccess: (data) => {
                            setLoading(false)
                        },
                    })
                },
            },
        ]
    }, [deleteLine])

    return (
        <Paper>
            <FilterSearch
                placeholder={`${t(`general.searchFor`)} ${t(
                    `general.scanLines`,
                )}`}
                onSearchChange={searchHandler}
                onEnterSearch={onEnterSearch}
            />
            <TableContainer
                sx={{ minWidth: 750 }}
                aria-labelledby='tableTitle'
                size='medium'
            >
                <MuiTable>
                    <TableHeader
                        headCells={headCells}
                        localization={'pages.issues.rackScans.scan.detail.'}
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />
                    <TableBody
                        isLoading={isLoading}
                        objects={results}
                        headCells={headCells}
                    />
                </MuiTable>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}

export default RackScanLineSession
