import { Form } from 'react-final-form'
import BreadCrumb from '~/components/BreadCrumb'
import FormToolbar from '~/components/FinalForm/FormToolbar'
import { LoadingButton } from '~/components/Button'
import { useTranslation } from 'react-i18next'
import useFormValidate from './hooks/useFormValidate'
import useBreadCrumbConfig from './hooks/useBreadCrumbConfig'
import useActions from './hooks/useActions'
import { useLocation } from 'react-router-dom'
import CancelButton from '~/components/CancelButton'
import BodyForm from './components/BodyForm'
import ComponentTooltip from '~/components/ComponentTooltip'

import { ADMINISTRATOR_TOOLTIP_CREATE } from '~/constants/Routes'
import PageName from '~/components/PageName'

const TooltipCreate = () => {
    const localization = `pages.administrator.tooltip`

    const { t } = useTranslation()
    const { state } = useLocation()

    const selectedPageUrl = state?.pageUrl
    const selectedComponentId = state?.componentId

    const validate = useFormValidate()
    const { createHandler, isLoading } = useActions()

    const breadCrumbConfig = useBreadCrumbConfig(`Create Component tooltip`)

    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_TOOLTIP_CREATE}
            >
                <div>
                    <PageName name='Create Tooltips' />
                </div>
            </ComponentTooltip>
            <Form
                validate={validate}
                initialValues={{
                    page_url: selectedPageUrl,
                    component_id: selectedComponentId,
                }}
                onSubmit={createHandler}
                render={({ handleSubmit, values, dirty }) => (
                    <form onSubmit={handleSubmit}>
                        <FormToolbar>
                            <CancelButton />
                            <LoadingButton
                                disabled={!dirty}
                                label={t(`${localization}.save`)}
                                type={`submit`}
                                loading={isLoading}
                            />
                        </FormToolbar>
                        <BodyForm {...{ localization, values }} />
                    </form>
                )}
            />
        </>
    )
}

export default TooltipCreate
