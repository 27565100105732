import CameraAltIcon from '@mui/icons-material/CameraAlt'
import Settings from '@mui/icons-material/Settings'
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined'
import {
    Box,
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from '@mui/material'
import Badge from '@mui/material/Badge'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import CustomAvatar from '~/components/CustomAvatar'
import { USER_SETTING } from '~/constants/Routes'
import useCameraSelection from '~/hooks/useCameraSelection'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { KEY_AUTH_TOKEN } from '../../constants/constants'
import { selectUser } from '../../store/auth/selector'
import { logout } from '../../store/auth/slice'
import { EventSeat, Groups } from '@mui/icons-material'
import useWorkbenchSelection from '~/components/Workbench/useWorkbenchSelection'
import useTeamSelection from '~/components/Team/useTeamSelection'

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            content: '""',
        },
    },
}))

const ColorButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'white',
    width: '95%',
    color: `${theme.palette.blue[100]}`,
    border: '1px solid #ccc',
    margin: '0 5px 0 5px',
    '&:hover': {
        backgroundColor: `${theme.palette.blue[100]}`,
        color: 'white',
    },
}))

const MenuItemUser = styled(MenuItem)(({ theme }) => ({
    '&:hover': {
        color: `${theme.palette.green[100]} !important`,
        backgroundColor: 'white',
    },
}))

const AvatarUser = () => {
    const { t } = useTranslation()
    const user = useSelector(selectUser)
    const { cameraSelection } = useCameraSelection()
    const { workbenchSelection } = useWorkbenchSelection()
    const { teamSelection } = useTeamSelection()
    const [anchorElUser, setAnchorElUser] = useState(null)
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleSetting = useCallback(
        (id) => {
            navigate(USER_SETTING.replace(':id', id))
            setAnchorElUser(null)
        },
        [navigate],
    )

    const doLogout = useCallback(() => {
        localStorage.removeItem(KEY_AUTH_TOKEN)
        dispatch(logout())
    }, [dispatch])

    return (
        <Box>
            <Tooltip
                title={t('profileSetting.openSetting')}
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Stack direction='row' spacing={2}>
                        <StyledBadge overlap='circular' variant='dot'>
                            <CustomAvatar url={user?.media?.preview_url} />
                        </StyledBadge>
                    </Stack>
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <Stack
                    direction='column'
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <CustomAvatar url={user?.media?.preview_url} />
                    <Typography
                        variant='h6'
                        sx={{ fontWeight: 'bold' }}
                        gutterBottom
                    >
                        {user ? user.name : 'Name'}
                    </Typography>
                    <Typography variant='h7' gutterBottom>
                        {user ? user.email : 'Email'}
                    </Typography>
                </Stack>
                <Divider />
                <MenuItemUser
                    style={{ marginTop: '5px' }}
                    onClick={() => handleSetting(user.id)}
                >
                    <Grid container alignItems='center'>
                        <Grid item xs={12} sm={2}>
                            <Settings
                                fontSize='small'
                                style={{ marginTop: '5px' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Box style={{ marginLeft: '6px' }}>
                                {t('profileSetting.settingManagement')}
                            </Box>
                        </Grid>
                    </Grid>
                </MenuItemUser>

                <Divider />
                <MenuItemUser style={{ marginTop: '5px' }}>
                    <Grid container alignItems='center'>
                        <Grid item xs={12} sm={2}>
                            <Groups
                                fontSize='small'
                                style={{ marginRight: '20px' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            {teamSelection}
                        </Grid>
                    </Grid>
                </MenuItemUser>

                <Divider />
                <MenuItemUser style={{ marginTop: '5px' }}>
                    <Grid container alignItems='center'>
                        <Grid item xs={12} sm={2}>
                            <EventSeat
                                fontSize='small'
                                style={{ marginRight: '20px' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            {workbenchSelection}
                        </Grid>
                    </Grid>
                </MenuItemUser>

                <Divider />
                <MenuItemUser style={{ marginTop: '5px' }}>
                    <Grid container alignItems='center'>
                        <Grid item xs={12} sm={2}>
                            <CameraAltIcon
                                fontSize='small'
                                style={{ marginRight: '20px' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            {cameraSelection}
                        </Grid>
                    </Grid>
                </MenuItemUser>
                <Divider />
                <MenuItemUser>
                    <Grid container alignItems='center'>
                        <Grid item xs={12} sm={2}>
                            <ToggleOffOutlinedIcon
                                style={{ marginTop: '5px' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Box style={{ marginLeft: '6px' }}>
                                {t('profileSetting.toggleDarkmode')}
                            </Box>
                        </Grid>
                    </Grid>
                </MenuItemUser>
                <Divider />
                <ColorButton
                    style={{ textTransform: 'none' }}
                    variant='contained'
                    onClick={doLogout}
                >
                    {t('profileSetting.signOut')}
                </ColorButton>
            </Menu>
        </Box>
    )
}

export default AvatarUser
