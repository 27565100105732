import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import { FLEET_OVERVIEW, FLEET_IMPORT_DEVICES } from '~/constants/Routes'
import ImportSerialQueue from './ImportSerialQueue'
import ImportDellCoep from './ImportDellCoep'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import ComponentTooltip from '~/components/ComponentTooltip'
import ListToolbar from '~/components/ListToolbar'

const ImportDevicePage = () => {
    const { t } = useTranslation()

    const BreadComponents = [
        { route: FLEET_OVERVIEW, title: 'Fleet' },
        {
            route: FLEET_IMPORT_DEVICES,
            title: 'ImportDevices',
        },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={FLEET_IMPORT_DEVICES}
            >
                <div>
                    <PageName
                        name={t(`pages.device.importDevices.pageTitle`)}
                    />
                </div>
            </ComponentTooltip>
            <ListToolbar toolBarButton={[]} />
            <Grid container spacing={3}>
                <ImportSerialQueue />
                <ImportDellCoep />
            </Grid>
        </>
    )
}
export default ImportDevicePage
