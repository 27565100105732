import workbenchApi from '~/api/workbenchApi'
import { useQuery } from 'react-query'

export const useWorkbenchQuery = () => {
    return useQuery(
        ['workbench-without-paginate'],
        async () => {
            const { data } = await workbenchApi.getWorkbenchesWithoutPaginate()
            return { data: data.data }
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}
