import styled from '@emotion/styled'
import {
    Button,
    Card,
    CardContent,
    Divider,
    Paper,
    Stack,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material'
import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import CustomCardHeader from '~/components/CustomCardHeader'
import PageName from '~/components/PageName'
import SubmitButton from '~/components/SubmitButton'
import CustomTable from '~/components/Table'
import {
    FLEET_MODEL,
    FLEET_MODEL_DETAIL,
    FLEET_OVERVIEW,
} from '~/constants/Routes'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CauseTable from './Component/CauseTable/CauseTable'
import {
    useModelDetailQuery,
    useModelPartCodesQuery,
    useUpdateModelNameMutation,
} from './query'
import { LoadingButton } from '~/components/Button'
import PartCodeSelectionPopup from '~/pages/Model/Details/Component/PartCodeSelectionPopup'
import { useQueryClient } from 'react-query'

const breadCrumbs = (title) => [
    { route: FLEET_OVERVIEW, title: 'Fleet' },
    {
        route: FLEET_MODEL,
        title: 'Models',
    },
    {
        route: '#',
        title,
    },
]

const partCodeTableHeadCells = [
    {
        id: 'code',
        key: 'code',
        numeric: false,
        disablePadding: false,
        label: 'code',
        orderable: true,
    },
    {
        id: 'name',
        key: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        orderable: true,
    },
    {
        id: 'type',
        key: 'type',
        numeric: false,
        disablePadding: false,
        label: 'type',
        orderable: true,
    },
    {
        id: 'serializable',
        key: 'serializable',
        numeric: false,
        disablePadding: false,
        label: 'serializable',
        orderable: true,
    },
]

const ResetButton = styled(Button)({
    textTransform: 'none',
})

const FleetModelDetails = () => {
    const { id } = useParams()

    const [modelName, setModelName] = useState('')
    const [isDisabled, setIsDisabled] = useState(true)
    const [openPopup, setOpenPopup] = useState(false)
    const queryClient = useQueryClient()

    const setName = (data) => {
        setModelName(data.data.name)
    }

    const { data, isLoading } = useModelDetailQuery(id, setName)

    useEffect(() => {
        if (!isLoading) {
            setIsDisabled(data?.data.name === modelName)
        }
    }, [data, isLoading, modelName])

    const { mutate: postNewModelName, isLoading: isChangingName } =
        useUpdateModelNameMutation(id)

    const handleInputChange = (event) => {
        setModelName(event.target.value)
    }

    const resetNameInput = () => {
        setModelName(data.data.name)
    }

    const handleNameInput = (event) => {
        event.preventDefault()

        postNewModelName(modelName)
    }

    const closePopup = () => {
        queryClient.invalidateQueries('fleet_model_part_codes')
        setOpenPopup(false)
    }

    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbs(data?.data.code)} />
            <Stack gap={4}>
                <ComponentTooltip
                    componentId={`page_title`}
                    pagePath={FLEET_MODEL_DETAIL}
                >
                    <div>
                        <PageName
                            name={`${data?.data.code} ${data?.data.description}`}
                        />
                    </div>
                </ComponentTooltip>

                <Paper
                    sx={{
                        width: '100%',
                        mb: 2,
                        p: 2,
                    }}
                >
                    <Toolbar disableGutters={false}>
                        <Stack
                            direction='row-reverse'
                            spacing={2}
                            sx={{
                                width: '100%',
                            }}
                        >
                            <ResetButton
                                variant='contained'
                                size='small'
                                disabled={isDisabled}
                                onClick={resetNameInput}
                            >
                                Reset
                            </ResetButton>
                            <SubmitButton
                                handleSubmit={handleNameInput}
                                disabled={isDisabled}
                                loading={isChangingName}
                            >
                                Update
                            </SubmitButton>
                        </Stack>
                    </Toolbar>

                    <Stack>
                        <ComponentTooltip
                            componentId={`edit_model_name`}
                            pagePath={FLEET_MODEL_DETAIL}
                        >
                            <Typography>Model name:</Typography>
                        </ComponentTooltip>
                        <TextField
                            id='fleet-model-name'
                            variant='outlined'
                            value={modelName}
                            onChange={handleInputChange}
                        />
                    </Stack>
                </Paper>
                <Card>
                    <CustomCardHeader title='Causes' />
                    <Divider />
                    <CardContent>
                        <CauseTable id={id} />
                    </CardContent>
                </Card>
                <Card>
                    <CustomCardHeader
                        title='Parts code'
                        action={
                            <LoadingButton
                                label='Add part code'
                                onClick={() => setOpenPopup(true)}
                                loading={isLoading}
                                bgColor='#102F44'
                            />
                        }
                    />

                    <Divider />
                    <CardContent>
                        <CustomTable
                            headCells={partCodeTableHeadCells}
                            customQuery={useModelPartCodesQuery}
                            id={id}
                        />
                    </CardContent>
                </Card>
            </Stack>
            {openPopup && (
                <PartCodeSelectionPopup
                    modelId={id}
                    open={openPopup}
                    onClose={closePopup}
                />
            )}
        </>
    )
}

export default FleetModelDetails
