import { deviceApi } from '~/api'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { useSupportObject } from '~/hooks/useSupportObject'
import { useMutation } from 'react-query'
import { base64ImageToBlob } from '~/utils/helpers'

export const useUploadPictureMutation = () => {
    const { id, clientQuery } = useSupportObject()
    return useMutation(
        ['upload_pictures', id],
        async (payload) => {
            const formData = new FormData()
            Array.from(payload.pictures).forEach((file, i) => {
                formData.append(`pictures[]`, file, file.name)
            })
            const { data } = await deviceApi.uploadPictures(formData, id)
            return data
        },
        {
            onSuccess: (data) => {
                clientQuery.invalidateQueries(['device', id])
            },
            retry: false,
        },
    )
}

export const useUploadCapturePictureMutation = () => {
    const { id, clientQuery } = useSupportObject()
    return useMutation(
        ['upload_capture_pictures', id],
        async (payload) => {
            const formData = new FormData()
            let i = 0
            for (const item of payload) {
                const imgBlob = await base64ImageToBlob(item)
                formData.append(`pictures[${i}]`, imgBlob)
                ++i
            }

            const { data } = await deviceApi.uploadPictures(formData, id)
            return data
        },
        {
            onSuccess: (data) => {
                clientQuery.invalidateQueries(['device', id])
            },
            retry: false,
        },
    )
}

export const useDeleteAttachmentMutation = () => {
    const { id: deviceId, clientQuery } = useSupportObject()
    return useMutation(
        ['delete_device_attachment'],
        async (id) => {
            const { data } = await deviceApi.deleteDeviceAttachment(id)
            return data
        },
        {
            onSuccess: (data) => {
                clientQuery.invalidateQueries(['device', deviceId])
            },
            onError: () => {},
            retry: false,
        },
    )
}

export const useUploadDocumentMutation = ({ setOpenUploadDocument }) => {
    const { id, clientQuery } = useSupportObject()
    return useMutation(
        ['upload_documents', id],
        async (payload) => {
            const { data } = await deviceApi.uploadDocuments(payload, id)
            return data
        },
        {
            onSuccess: (data) => {
                clientQuery.invalidateQueries(['device', id])
                setOpenUploadDocument(false)
            },

            retry: false,
        },
    )
}

export const useDownloadDocumentMutation = () => {
    const { id: deviceId, clientQuery } = useSupportObject()
    return useMutation(
        ['download_device_document'],
        async (payload) => {
            const id = payload?.id
            const { data } = await deviceApi.downloadDeviceDocument(id)
            return data
        },
        {
            onSuccess: (data, payload) => {
                clientQuery.invalidateQueries(['device', deviceId])
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', payload.fileName)
                document.body.appendChild(link)
                link.click()
            },
            onError: () => {},
            retry: false,
        },
    )
}

export const useUpdateDeviceStateMutation = () => {
    const { id, clientQuery } = useSupportObject()
    const { openSnackbar } = useDialogContext()

    return useMutation(
        ['update_device_state'],
        async (payload) => {
            const { data } = await deviceApi.updateDeviceState(id, payload)
            return data
        },
        {
            onSuccess: (data) => {
                clientQuery.invalidateQueries(['device', id])
                openSnackbar({
                    message: data.message ? data.message : 'Create successful!',
                    type: 'success',
                })
            },
            onError: (error) => {
                let message = 'failed to save!'
                if (error.response.data.message) {
                    message = error.response.data.message
                } else if (error.message) {
                    message = error.message
                }
                openSnackbar({
                    message,
                    type: 'error',
                    duration: 10000,
                })
            },
            retry: false,
        },
    )
}
