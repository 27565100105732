import { useServiceCenterListQuery } from '~/pages/ServiceCenter/query'
import { useMemo } from 'react'

const useWorkbenchFormData = () => {
    const localization = useMemo(() => 'pages.administrator.workbench', [])
    const { data: serviceCenters } = useServiceCenterListQuery({
        menuOption: true,
    })

    const serviceCenterList = useMemo(() => {
        if (serviceCenters) {
            const result = serviceCenters.data.map((serviceCenter) => ({
                name: serviceCenter.name,
                value: serviceCenter.id,
            }))

            return result
        }
        return []
    }, [serviceCenters])

    const InputFieldGroup = useMemo(() => {
        return [
            {
                name: 'name',
                componentId: 'workbench_form_name',
                isRequired: true,
            },
            {
                name: 'location',
                componentId: 'workbench_form_location',
                isRequired: true,
            },
        ]
    }, [])

    const selectGroup = [
        {
            title: 'serviceCenter',
            name: 'service_center_id',
            listResource: serviceCenterList,
            componentId: 'workbench_form_service_center',
            isRequired: true,
        },
    ]
    return { InputFieldGroup, selectGroup, localization }
}

export default useWorkbenchFormData
