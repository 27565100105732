export const CauseHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
    },
    {
        id: 'tid',
        numeric: false,
        disablePadding: false,
        label: 'tid',
        orderable: true,
    },
    {
        id: 'model',
        numeric: false,
        disablePadding: false,
        label: 'model',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        orderable: false,
    },
    {
        id: 'enabled',
        numeric: false,
        disablePadding: false,
        label: 'enabled',
        orderable: false,
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'createdAt',
        orderable: false,
        dataType: 'datetime',
    },
    {
        id: 'updated_at',
        numeric: false,
        disablePadding: false,
        label: 'updatedAt',
        orderable: false,
        dataType: 'datetime',
    },
]
