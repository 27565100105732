import { RemoveCircle } from '@mui/icons-material'
import { Link } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { PART_COMPONENT_DETAIL } from '~/constants/Routes'
import PartCodeDisplayer from '~/pages/ComponentPage/components/PartCodeDisplayer'
import { Fragment, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

const ComponentRow = ({ row, confirmUnlink }) => {
    const navigate = useNavigate()
    const handleClickDirection = useCallback(
        (row) => {
            navigate(PART_COMPONENT_DETAIL.replace(':id', row.id))
        },
        [navigate],
    )
    return (
        <Fragment>
            <TableRow hover tabIndex={-1} key={row.id}>
                <TableCell component='th' scope='row'>
                    <Link
                        component='button'
                        variant='inherit'
                        to='#'
                        underline='hover'
                        onClick={() => handleClickDirection(row)}
                    >
                        <Typography variant='body2'>{row.id}</Typography>
                    </Link>
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.part_number}</TableCell>
                <TableCell>{row.item_description}</TableCell>
                <TableCell>
                    <PartCodeDisplayer
                        partCode={row.part_code?.code}
                        componentId={row.id}
                    />
                </TableCell>
                <TableCell>{row.part_code?.name}</TableCell>
                <TableCell>{row.part_code?.type}</TableCell>
                <TableCell>
                    <IconButton
                        aria-label='row'
                        size='small'
                        sx={{ marginLeft: '30px' }}
                    >
                        <RemoveCircle
                            color='error'
                            onClick={(e) => {
                                confirmUnlink(row.id)
                            }}
                        />
                    </IconButton>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

ComponentRow.propTypes = {
    row: PropTypes.object,
    confirmUnlink: PropTypes.func,
}

export default ComponentRow
