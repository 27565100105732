import { createSlice } from '@reduxjs/toolkit'

const issueDiagnoseSlice = createSlice({
    name: 'issueDiagnostic',
    initialState: {
        issueDiagnostic: null,
        isOpen: false,
        diagnosticSerial: null,
    },
    reducers: {
        setIssueDiagnostic: (state, action) => {
            state.issueDiagnostic = action.payload
        },
        setIsOpen: (state, action) => {
            state.isOpen = action.payload
        },
        setDiagnosticSerial: (state, action) => {
            state.diagnosticSerial = action.payload
        },
    },
})

export const { setIssueDiagnostic, setIsOpen, setDiagnosticSerial } =
    issueDiagnoseSlice.actions

export default issueDiagnoseSlice
