import issueApi from '~/api/issueApi'
import ticketApi from '~/api/ticketApi'
import useQuery from '~/hooks/useQuery'
import { removeEmptyValue } from '~/utils/helpers'

export const useGetAllTicketQuery = (params) => {
    return useQuery(['tickets', params], async () => {
        const { data } = await ticketApi.getAllTickets(removeEmptyValue(params))

        return { data: data.data, total: data.total }
    })
}

export const useTicketQuery = (id) => {
    return useQuery(['ticket', id], async () => {
        const { data } = await ticketApi.getTicketById(id)
        return data?.data
    })
}

export const useFormFieldsOptionsQuery = () => {
    return useQuery(['issue', 'get_form_fields_options'], async () => {
        const { data } = await issueApi.getFormFieldsOptions()
        return data
    })
}

export const usePictureDispatchQuery = (id) => {
    return useQuery(
        ['dispatch_picture_query', id],
        async () => {
            const { data } = await ticketApi.getAllDispatchPictureById(id)
            return data
        },
        {
            enabled: !!id,
            retry: false,
        },
    )
}

export const useGetTicketStateQuery = () => {
    return useQuery(['ticket_state'], async () => {
        const { data } = await ticketApi.getTicketStates()
        return data
    })
}

export const useGetTicketStatusQuery = () => {
    return useQuery(['ticket_status'], async () => {
        const { data } = await ticketApi.getTicketStatus()
        return data
    })
}
