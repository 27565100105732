import { Alert, Snackbar } from '@mui/material'
import { t } from 'i18next'
import { useCallback, useState } from 'react'

export const useSnackbar = () => {
    const [message, setMessage] = useState('')
    const [type, setType] = useState('')
    const [open, setOpen] = useState(false)
    const [autoHideDuration, setAutoHideDuration] = useState(0)
    const [alertSx, setAlertSx] = useState(null)
    const showSnackbar = useCallback(
        (message, type = 'error', autoHideDuration = 3000, alertSx = null) => {
            setMessage(message)
            setType(type)
            setOpen(true)
            setAutoHideDuration(autoHideDuration)
            setAlertSx(alertSx)
        },
        [],
    )

    const snackbar = (
        <Snackbar
            open={open}
            onClose={() => setOpen(false)}
            autoHideDuration={autoHideDuration}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'top',
            }}
            sx={{ marginTop: '70px' }}
        >
            <Alert severity={type} sx={alertSx}>
                {t(message)}
            </Alert>
        </Snackbar>
    )

    return { showSnackbar, snackbar }
}
