import { useUpdateBomLabel } from '~/pages/BOM/mutate'
import { useQueryClient } from 'react-query'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { useCallback, useMemo } from 'react'
import StyledModal from '~/components/StyledModal'
import { Field, Form } from 'react-final-form'
import { FormControl, Grid, Stack, Typography } from '@mui/material'
import { LoadingButton } from '~/components/Button'
import PropTypes from 'prop-types'
import AutoCompleteField from '~/components/FinalForm/AutoCompleteField'
import { STOCK_LABELS } from '~/constants/constants'
import { ISSUE_BOM_DETAIL } from '~/constants/Routes'
import SubmitButton from '~/components/SubmitButton'

const pagePath = ISSUE_BOM_DETAIL
const localization = 'pages.bom'
const EditStockLabelPopup = ({
    onClose = () => {},
    closeAll = () => {},
    stockLabel = null,
    sku = null,
    message,
    bomId,
}) => {
    const { mutate, isLoading } = useUpdateBomLabel()
    const queryClient = useQueryClient()
    const { openSnackbar } = useDialogContext()

    const stockLabelList = STOCK_LABELS.map((sl) => ({
        name: sl,
        value: sl,
    }))

    const updateBOM = useCallback(
        (values) => {
            mutate(
                {
                    id: bomId,
                    payload: {
                        stock_label: values.stockLabel,
                    },
                },

                {
                    onSuccess: () => {
                        onClose()
                        closeAll()
                        queryClient.invalidateQueries('bom_detail')
                        openSnackbar({
                            message: 'Update success!',
                            type: 'success',
                        })
                    },
                    onError: () => {
                        onClose()
                        openSnackbar({
                            message: 'Update failed!',
                            type: 'error',
                        })
                    },
                },
            )
        },
        [mutate, bomId, onClose, closeAll, queryClient, openSnackbar],
    )

    const initialValues = useMemo(() => {
        return {
            stockLabel,
        }
    }, [stockLabel])

    return (
        <Form
            onSubmit={updateBOM}
            initialValues={initialValues}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <StyledModal
                        open={!!stockLabel || !!sku}
                        handleClose={onClose}
                        stackProps={{
                            width: 500,
                            minHeight: 300,
                            gap: 4,
                        }}
                    >
                        <Typography variant='h6'>{message}</Typography>
                        <Grid
                            container
                            direction='column'
                            justifyContent='center'
                            spacing={1}
                        >
                            <Grid item>
                                <FormControl sx={{ width: '100%' }}>
                                    <Field
                                        name='stockLabel'
                                        component={AutoCompleteField}
                                        items={stockLabelList}
                                        localization={localization}
                                        componentId='setting_form_stock_label'
                                        pagePath={pagePath}
                                        nameSelect='stock_label'
                                        isRequired={true}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Stack direction='row' gap={2}>
                            <LoadingButton
                                label='Cancel'
                                onClick={onClose}
                                loading={isLoading}
                            />
                            <SubmitButton
                                isLoading={isLoading}
                                handleSubmit={handleSubmit}
                                name='Save'
                            />
                        </Stack>
                    </StyledModal>
                </form>
            )}
        />
    )
}

EditStockLabelPopup.propTypes = {
    onClose: PropTypes.func,
    closeAll: PropTypes.func,
    stockLabel: PropTypes.string,
    sku: PropTypes.string,
    message: PropTypes.string.isRequired,
    bomId: PropTypes.number,
    bomState: PropTypes.string,
}

export default EditStockLabelPopup
