export const PurchaseOrderHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'purchaseOrder',
        orderable: true,
    },
    {
        id: 'supplier',
        numeric: false,
        disablePadding: false,
        label: 'supplier',
        orderable: true,
    },
    {
        id: 'entity_id',
        numeric: false,
        disablePadding: false,
        label: 'entity',
        orderable: true,
    },
    {
        id: 'contact_name',
        numeric: false,
        disablePadding: false,
        label: 'contactName',
        orderable: true,
    },
    {
        id: 'order_date',
        numeric: false,
        disablePadding: false,
        label: 'orderDate',
        orderable: true,
    },
    {
        id: 'expected_delivery_date',
        numeric: false,
        disablePadding: false,
        label: 'expectedDeliveryDate',
        orderable: true,
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: 'action',
        orderable: false,
    },
]
