import {
    Autocomplete,
    CircularProgress,
    Divider,
    Grid,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { useState } from 'react'
import { useAddMemberForTeamMutation } from '../mutation'
import { useGetUsersNotExistInTeamQuery } from '../query'
import { useParams } from 'react-router-dom'
import ErrorMessageInput from '~/components/ErrorMessageInput'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import ComponentTooltip from '~/components/ComponentTooltip'
import { ADMINISTRATOR_DEFINITION_TEAM_DETAIL } from '~/constants/Routes'
import StyledModal from '~/components/StyledModal'
import PropTypes from 'prop-types'

const AddMember = ({ openAddMember, setOpenAddMember }) => {
    const [idUser, setIdUser] = useState(null)
    const { t } = useTranslation()

    const { id } = useParams()

    const { data: users, isLoading } = useGetUsersNotExistInTeamQuery(id)

    const {
        mutate: addMember,
        isLoading: loadingAdd,
        isError,
    } = useAddMemberForTeamMutation({
        setOpenAddMember,
        setIdUser,
    })

    const handleAddMember = () => {
        addMember({
            users: [idUser],
        })
    }

    const handleClose = () => {
        setOpenAddMember(false)
    }

    return (
        <StyledModal open={openAddMember} handleClose={() => handleClose()}>
            <Grid container spacing={0.5}>
                <Grid item xs={12} sm={12}>
                    <ComponentTooltip
                        componentId={`add_member_form_title`}
                        pagePath={ADMINISTRATOR_DEFINITION_TEAM_DETAIL}
                        align='center'
                    >
                        <Typography
                            variant='h6'
                            color='gray'
                            textAlign='center'
                        >
                            Add member
                        </Typography>
                    </ComponentTooltip>

                    <Divider sx={{ mb: 2 }} />
                </Grid>

                {isError && (
                    <ErrorMessageInput>
                        {t('message.somethingWrong')}
                    </ErrorMessageInput>
                )}
                <Grid item xs={12} sm={12}>
                    <ComponentTooltip
                        componentId={`add_member_form_user`}
                        pagePath={ADMINISTRATOR_DEFINITION_TEAM_DETAIL}
                    >
                        <Typography>Users</Typography>
                    </ComponentTooltip>
                    <Autocomplete
                        options={users}
                        getOptionLabel={(option) =>
                            `${option.name} (${option.email})`
                        }
                        onChange={(event, newValue) => {
                            setIdUser(newValue?.id)
                        }}
                        ListboxProps={{ style: { maxHeight: 150 } }}
                        loading={isLoading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder='Choose members'
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {isLoading ? (
                                                <CircularProgress
                                                    color='inherit'
                                                    size={20}
                                                />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>
            </Grid>

            <Grid
                container
                spacing={0.5}
                direction='row'
                justifyContent='flex-end'
                alignItems='flex-end'
                mb={0}
            >
                <Grid item xs={12}>
                    <Stack direction='column' spacing={2}>
                        <LoadingButton
                            onClick={() => {
                                handleAddMember()
                            }}
                            disabled={!idUser}
                            loading={loadingAdd}
                            variant='contained'
                            size='large'
                            sx={{
                                textTransform: 'none',
                                backgroundColor: '#76B72A',
                            }}
                        >
                            Save
                        </LoadingButton>
                    </Stack>
                </Grid>
            </Grid>
        </StyledModal>
    )
}

AddMember.propTypes = {
    openAddMember: PropTypes.bool,
    setOpenAddMember: PropTypes.func,
}

export default AddMember
