import BreadCrumb from '../BreadCrumb'
import PageName from '../PageName'
import PropTypes from 'prop-types'

const PageLayout = ({ children, breadcrumbs, pageName }) => {
    return (
        <>
            <BreadCrumb {...{ breadcrumbs }} />
            <PageName name={pageName} />
            {children}
        </>
    )
}

PageLayout.propTypes = {
    children: PropTypes.any,
    breadcrumbs: PropTypes.arrayOf(PropTypes.object),
    pageName: PropTypes.any,
}

export default PageLayout
