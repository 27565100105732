import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import DataNotFound from '~/components/DataNotFound'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import useTable from '~/hooks/useTable'
import { route } from '~/utils/helpers'
import SectionTitle from './components/SectionTitle'
import StyledTableCell from './components/StyledTableCell'
import StyledTableRow from './components/StyledTableRow'
import useStyles from './hooks/useStyles'
import { useIssueLogsQuery } from './query'
import { ISSUE_DISPATCH_DETAIL } from '~/constants/Routes'
import PropTypes from 'prop-types'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const DescriptionRow = ({ inputDescription }) => {
    // Check if inputDescription is null or empty
    if (!inputDescription) {
        return <></>
    }

    const regexDispatch = /^((Create )?)(dispatch|Dispatch)\s(\d+)$/
    const match = inputDescription.match(regexDispatch)

    if (match) {
        const parts = inputDescription.split(' ')
        const id = parts[parts.length - 1]
        return (
            <>
                {'Create dispatch '}
                <Link
                    to={route(ISSUE_DISPATCH_DETAIL, { id })}
                    style={{ textDecoration: 'none' }}
                >
                    {id}
                </Link>
            </>
        )
    }
    return <>{inputDescription}</>
}

DescriptionRow.propTypes = { inputDescription: PropTypes.string.isRequired }

const IssueLog = () => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'pages.diagnostic',
    })

    return (
        <Grid container>
            <SectionTitle
                title={t('issueLog')}
                xs={12}
                md={12}
                lg={12}
                align='center'
            />
            <IssueLogTable />
        </Grid>
    )
}

const IssueLogTable = () => {
    const { classes } = useStyles()
    const { t } = useTranslation('translation', {
        keyPrefix: 'pages.diagnostic',
    })
    const { issueId } = useParams()
    const { results, data, isLoading, page, changePageHandler } = useTable({
        query: useIssueLogsQuery,
        queryAttributes: {
            id: issueId,
        },
    })

    const headerTable = [
        'startTime',
        'endTime',
        'status',
        'description',
        'name',
        'serviceCenter',
    ]

    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        {headerTable.map((item) => (
                            <StyledTableCell align='center' key={item}>
                                {t(item)}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading ? (
                        <TableLoading colSpan={headerTable.length} />
                    ) : results?.length > 0 ? (
                        results.map((item) => {
                            const {
                                id,
                                started_at,
                                finished_at,
                                old_state,
                                new_state,
                                event,
                                description,
                                user,
                                service_center,
                            } = item
                            return (
                                <StyledTableRow key={id}>
                                    <TableCell className={classes.tableCell}>
                                        <DateTimeLocale datetime={started_at} />
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <DateTimeLocale
                                            datetime={finished_at}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {old_state} - {new_state} - {event}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <DescriptionRow
                                            inputDescription={description}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {user?.name || 'N/A'}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {service_center?.name || 'N/A'}
                                    </TableCell>
                                </StyledTableRow>
                            )
                        })
                    ) : (
                        <DataNotFound colSpan={headerTable.length} />
                    )}
                </TableBody>
            </Table>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </TableContainer>
    )
}
export default IssueLog
