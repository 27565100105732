import { Box, Grid } from '@mui/material'
import data from '~/constants/countryCode.json'
import PropTypes from 'prop-types'

const DispatchShippingInfo = ({ t = (string) => string, dispatch }) => {
    const country = data.find(
        (x) => x.code === dispatch.branch?.address?.country_iso_code,
    )?.name

    return (
        <Box sx={{ px: 2, py: 1 }}>
            <Grid container spacing={3}>
                <Grid item xs={5}>
                    {t(`AddressName`)}
                </Grid>
                <Grid item xs={7}>
                    {dispatch?.branch?.name}
                </Grid>
                <Grid item xs={5}>
                    {t(`Address`)}
                </Grid>
                <Grid item xs={7}>
                    {dispatch?.branch?.address?.name &&
                        dispatch?.branch?.address?.name &&
                        `${dispatch?.branch?.address?.name} | ${dispatch?.name}`}
                </Grid>
                <Grid item xs={5}>
                    {t(`City`)}
                </Grid>
                <Grid item xs={7}>
                    {dispatch?.branch?.address?.city}
                </Grid>
                <Grid item xs={5}>
                    {t(`StateProvince`)}
                </Grid>
                <Grid item xs={7}>
                    {dispatch?.branch?.address?.state}
                </Grid>
                <Grid item xs={5}>
                    {t(`ZipPostalCode`)}
                </Grid>
                <Grid item xs={7}>
                    {dispatch?.branch?.address?.zipcode}
                </Grid>
                <Grid item xs={5}>
                    {t(`Location`)}
                </Grid>
                <Grid item xs={7}>
                    {country} &nbsp;{' '}
                    {dispatch?.branch?.address?.country_iso_code}
                </Grid>
                <Grid item xs={5}>
                    {t(`TimeZone`)}
                </Grid>
                <Grid item xs={7}>
                    {dispatch?.branch?.address?.timezone}
                </Grid>
            </Grid>
        </Box>
    )
}

DispatchShippingInfo.propTypes = {
    t: PropTypes.func,
    dispatch: PropTypes.object,
}

export default DispatchShippingInfo
