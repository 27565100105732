import { Divider, Paper } from '@mui/material'
import PaginationTable from '~/components/PaginationTable'
import TableContainer from '~/components/Table/TableContainer'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import { LOCALIZATION } from '../constants'
import { tableCells } from '../constants/index'
import { useModelQuery } from '../query'
import TableBody from './TableBody'
import Toolbar from './Toolbar'

const Table = () => {
    const {
        results: items,
        data,
        isLoading,
        page,
        order,
        orderBy,
        search,
        sortHandler,
        searchHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useModelQuery,
    })

    return (
        <Paper sx={{ width: '100%', mb: 2 }}>
            <Toolbar
                filterSearch={search}
                onFilterSearch={searchHandler}
                onEnterSearch={onEnterSearch}
            />
            <Divider />
            <TableContainer>
                <TableHeader
                    headCells={tableCells}
                    localization={LOCALIZATION}
                    onRequestSort={sortHandler}
                    order={order}
                    orderBy={orderBy}
                />
                <TableBody isLoading={isLoading} items={items} />
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}

export default Table
