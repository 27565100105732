import {
    Divider,
    Table as MuiTable,
    TableBody as MuiTableBody,
    Paper,
    TableContainer,
} from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import { RegionHeadCells } from '~/components/HeaderTable/RegionHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import Row from '~/components/TableRow'
import useTable from '~/hooks/useTable'
import { useRegionsListQuery } from '../query'
import RegionTableFilters from './RegionTableFilters'
import PropTypes from 'prop-types'

const TableBody = ({ isLoading, objects }) => {
    if (isLoading) {
        return <TableLoading colSpan={RegionHeadCells.length} />
    }

    return (
        <MuiTableBody>
            {objects.length > 0 ? (
                objects.map((row, index) => {
                    return (
                        <Row
                            row={row}
                            columnsConfig={RegionHeadCells}
                            key={index}
                        />
                    )
                })
            ) : (
                <DataNotFound colSpan={RegionHeadCells.length} />
            )}
        </MuiTableBody>
    )
}

TableBody.propTypes = { isLoading: PropTypes.bool, objects: PropTypes.array }

const Table = () => {
    const {
        results,
        data,
        isLoading,
        page,
        search,
        order,
        orderBy,
        changePageHandler,
        searchHandler,
        sortHandler,
        onEnterSearch,
    } = useTable({
        query: useRegionsListQuery,
    })

    return (
        <Paper>
            <RegionTableFilters
                search={search}
                searchHandler={searchHandler}
                onEnterSearch={onEnterSearch}
            />
            <Divider />
            <TableContainer
                sx={{ minWidth: 750 }}
                aria-labelledby='tableTitle'
                size='medium'
            >
                <MuiTable>
                    <TableHeader
                        headCells={RegionHeadCells}
                        localization={
                            'pages.administrator.definition.regions.overview.'
                        }
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />
                    <TableBody isLoading={isLoading} objects={results} />
                </MuiTable>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}

export default Table
