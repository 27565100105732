import Axios from 'axios'
import { KEY_AUTH_TOKEN } from '~/constants/constants'
import { getImpersonateToken, isProductionApp } from '~/utils/helpers'

const REQUEST_TIMEOUT = 180000
const REQUEST_FORM_TIMEOUT = 30000

export const axiosClient = Axios.create({
    baseURL: import.meta.env.VITE_BASE_API_URL,
    headers: {
        Accept: 'application/json',
        ContentType: 'application/json',
    },
    timeout: REQUEST_TIMEOUT,
})

export const clientDownload = Axios.create({
    baseURL: import.meta.env.VITE_BASE_API_URL,
    headers: {
        Accept: 'application/json',
    },
    timeout: REQUEST_TIMEOUT,
    responseType: 'blob',
})

export const axiosFormClient = Axios.create({
    baseURL: import.meta.env.VITE_BASE_API_URL,
    headers: {
        Accept: 'application/json',
        ContentType: 'multipart/form-data',
    },
    mimeType: 'multipart/form-data',
    processData: false,
    contentType: false,
    timeout: REQUEST_FORM_TIMEOUT,
})

axiosClient.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        // if (error.response.status === 401) {
        //   if (window.location.pathname !== SIGNIN) {
        //     localStorage.removeItem(STORAGE_TOKEN_KEY);
        //     window.location.replace(SIGNIN);
        //   }
        // }
        return Promise.reject(error)
    },
)

axiosClient.interceptors.request.use((config) => {
    // If the request is a GET request and the 'locale' param is missing, add it
    if (config.method === 'get' && !config.params?.locale) {
        config.params = {
            ...config.params,
            locale: localStorage.getItem('LOCALE') || 'nl',
        }
    }
    return config
})

export const setApiClientAuthToken = (t) => {
    axiosClient.defaults.headers.common.Authorization = `Bearer ${t}`
    axiosClient.defaults.headers.common['X-CSRF-TOKEN'] = t
    clientDownload.defaults.headers.common.Authorization = `Bearer ${t}`
    clientDownload.defaults.headers.common['X-CSRF-TOKEN'] = t
    axiosFormClient.defaults.headers.common.Authorization = `Bearer ${t}`
    axiosFormClient.defaults.headers.common['X-CSRF-TOKEN'] = t
}

const getAuthToken = () => {
    if (isProductionApp) return localStorage.getItem(KEY_AUTH_TOKEN)

    return getImpersonateToken() || localStorage.getItem(KEY_AUTH_TOKEN)
}

setApiClientAuthToken(getAuthToken())

export default axiosClient
