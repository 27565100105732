import TableFilters from '~/components/TableFilters'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useVendorsQuery } from '~/pages/Device/Serial/Overview/query'

const VendorTableFilters = ({
    search,
    searchHandler,
    vendor,
    setVendor,
    onEnterSearch,
}) => {
    const { data: vendorData, isSuccess: isSuccessVendor } = useVendorsQuery()

    const vendors = useMemo(() => {
        if (isSuccessVendor) {
            const result = [...vendorData?.data]
            result.unshift({ id: '', name: 'ALL' })
            return result
        }
        return []
    }, [isSuccessVendor, vendorData?.data])

    const selectGroup = useMemo(() => {
        return [
            {
                label: 'Vendors',
                handleFunc: setVendor,
                dataSet: vendors,
                value: vendor,
            },
        ]
    }, [setVendor, vendor, vendors])

    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='vendors'
            page='vendors'
        />
    )
}
VendorTableFilters.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    vendor: PropTypes.string,
    setVendor: PropTypes.func,
    onEnterSearch: PropTypes.func,
}
export default VendorTableFilters
