import { useSelector } from 'react-redux'
import { selectFormatDateState } from '~/store/formatdate/selector'
import { formatDateTime } from '~/utils/helpers'
import PropTypes from 'prop-types'
import { selectUser } from '~/store/auth/selector'

const DateTimeLocale = ({ datetime, formatHour = false }) => {
    const format = useSelector(selectFormatDateState)
    const user = useSelector(selectUser)

    return formatDateTime(datetime, format, user?.timezone, formatHour)
}
DateTimeLocale.propTypes = { datetime: PropTypes.string }

export default DateTimeLocale
