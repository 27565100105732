import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material'
import { useState } from 'react'
import { useRemoveTeamMemberMutation } from '../mutation'
import AddMember from './AddMember'
import MemberCard from './components/MemberCard'
import PropTypes from 'prop-types'

const CustomMenu = ({ handleRemoveMember, isLoading }) => {
    const [anchorElUser, setAnchorElUser] = useState(null)
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }
    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    return (
        <>
            <CardHeader
                action={
                    <IconButton
                        aria-label='settings'
                        onClick={handleOpenUserMenu}
                    >
                        <MoreVertIcon />
                    </IconButton>
                }
            />
            <Menu
                sx={{ mt: '22px' }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem onClick={handleRemoveMember}>
                    <ListItemIcon>
                        {isLoading ? (
                            <CircularProgress size={15} />
                        ) : (
                            <DeleteOutlineIcon fontSize='small' />
                        )}
                    </ListItemIcon>
                    <ListItemText>Remove</ListItemText>
                </MenuItem>
            </Menu>
        </>
    )
}

CustomMenu.propTypes = {
    handleRemoveMember: PropTypes.func,
    isLoading: PropTypes.bool,
}

const Member = ({ members }) => {
    const [openAddMember, setOpenAddMember] = useState(false)
    const { mutate: removeMember, isLoading } = useRemoveTeamMemberMutation()

    const handleRemoveMember = (userId) => {
        removeMember({
            memberId: userId,
        })
    }

    return (
        <>
            <Button
                sx={{ textTransform: 'none', fontWeight: 'normal' }}
                onClick={() => {
                    setOpenAddMember(true)
                }}
            >
                Add member
            </Button>
            {openAddMember && (
                <AddMember
                    openAddMember={openAddMember}
                    setOpenAddMember={setOpenAddMember}
                />
            )}

            <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
            >
                {members.map((member) => (
                    <Grid item xs={2} sm={2} md={2} key={member.id}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CustomMenu
                                handleRemoveMember={() =>
                                    handleRemoveMember(member.id)
                                }
                                isLoading={isLoading}
                            />

                            <CardActionArea>
                                <MemberCard {...{ member }} />

                                <CardContent>
                                    <Grid container wrap='nowrap' spacing={2}>
                                        <Grid item xs zeroMinWidth>
                                            <Typography
                                                noWrap
                                                gutterBottom
                                                variant='h5'
                                                component='div'
                                            >
                                                {member.name}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container wrap='nowrap' spacing={2}>
                                        <Grid item xs zeroMinWidth>
                                            <Typography
                                                noWrap
                                                variant='body2'
                                                color='text.secondary'
                                            >
                                                {member.email}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

Member.propTypes = { members: PropTypes.array }

export default Member
