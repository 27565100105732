import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import PageName from '~/components/PageName'
import { FLEET_OVERVIEW, FLEET_OVERVIEW_VENDOR } from '~/constants/Routes'
import VendorTable from './VendorTable'

const VendorPage = () => {
    const BreadComponents = [
        { route: FLEET_OVERVIEW, title: 'Fleet' },
        {
            route: FLEET_OVERVIEW,
            title: 'Overview',
        },
        {
            route: FLEET_OVERVIEW_VENDOR,
            title: 'Vendor',
        },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={FLEET_OVERVIEW_VENDOR}
            >
                <div>
                    <PageName name={'Vendors'} />
                </div>
            </ComponentTooltip>
            <VendorTable />
        </>
    )
}

export default VendorPage
