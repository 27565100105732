import { Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import { PRODUCTION_ORDERS, PRODUCTION_SYSTEMIMAGE } from '~/constants/Routes'
import { useSnackbar } from '~/hooks/useSnackbar'
import { useServerQuery } from '../query'
import UpdateServerInfo from './UpdateServerInfo'

const ServersDetail = () => {
    const { id } = useParams()
    const { showSnackbar, snackbar } = useSnackbar()

    const { data, isSuccess } = useServerQuery({ id, showSnackbar })

    const breadCrumbConfig = [
        { route: PRODUCTION_SYSTEMIMAGE, title: 'Production' },
        { route: PRODUCTION_ORDERS, title: 'Servers' },
        { route: '#', title: 'detail' },
    ]
    return (
        <>
            {snackbar}
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <PageName
                name={
                    data?.data?.hostname +
                    ' - ' +
                    data?.data?.name +
                    ' - ' +
                    data?.data?.last_ip +
                    ' - ' +
                    data?.data?.organisation?.name +
                    ' (' +
                    data?.data?.organisation?.external_id +
                    ')'
                }
            />
            <Grid
                container
                spacing={3}
                sx={{
                    marginBottom: '20px',
                }}
            >
                <Grid item xs={12} md={6}>
                    {isSuccess && <UpdateServerInfo data={data?.data} />}
                </Grid>
            </Grid>
        </>
    )
}

export default ServersDetail
