import { useTranslation } from 'react-i18next'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_DEFINITION,
    ADMINISTRATOR_DEFINITION_REGIONS,
    ADMINISTRATOR_DEFINITION_REGIONS_EDIT,
} from '~/constants/Routes'
import { useParams } from 'react-router-dom'
import Loading from '~/components/Loading'
import { useMemo } from 'react'
import { Form } from 'react-final-form'
import Body from './Body'
import { object, string } from 'yup'
import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import useFormValidationCallback from '~/hooks/useFormValidationCallback'
import { useGetRegionDetailQuery } from '../query'
import { useUpdateRegionMutation } from '../mutation'
import { useSnackbar } from '~/hooks/useSnackbar'
import ToolbarForm from '~/components/ToolbarForm'
import ComponentTooltip from '~/components/ComponentTooltip'

const localization = 'pages.administrator.definition.regions.edit'
const RegionEdit = () => {
    const { showSnackbar, snackbar } = useSnackbar()
    const { mutate: updateRegion, isLoading: loadingUpdate } =
        useUpdateRegionMutation({ showSnackbar })
    const { id } = useParams()
    const { t } = useTranslation()
    const schema = object().shape({
        name: string().required(),
        description: string().nullable(),
    })

    const validate = useFormValidationCallback(schema)
    const { data, isLoading } = useGetRegionDetailQuery(id)

    const queryResponse = useMemo(() => {
        return data?.data || []
    }, [data])

    if (isLoading) {
        return <Loading />
    }

    const BreadComponents = [
        { route: ADMINISTRATOR, title: t(`breadCrumb.Administrator`) },
        {
            route: ADMINISTRATOR_DEFINITION,
            title: 'Definitions',
        },
        {
            route: ADMINISTRATOR_DEFINITION_REGIONS,
            title: 'Regions',
        },
        {
            page: 'detail',
            title: id,
        },
    ]
    return (
        <>
            {snackbar}
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_DEFINITION_REGIONS_EDIT}
            >
                <div>
                    <PageName name={`Edit Region`} />
                </div>
            </ComponentTooltip>
            <Form
                validate={validate}
                onSubmit={updateRegion}
                initialValues={queryResponse}
                render={({ handleSubmit, form, values }) => (
                    <form onSubmit={handleSubmit}>
                        <ToolbarForm
                            localization={localization}
                            isLoading={loadingUpdate}
                        />
                        <Body
                            data={queryResponse}
                            localization={localization}
                        />
                    </form>
                )}
            />
        </>
    )
}
export default RegionEdit
