import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_TEST_DATA_DISPATCH_SHIPMENT_REPORT,
} from '~/constants/Routes'
import { useEffect, useRef, useState } from 'react'
import useTable from '~/hooks/useTable'
import { Divider, Paper, Table, TableContainer } from '@mui/material'
import IssueTableFilter from '~/pages/Issue/Overview/IssueTableFilter'
import TableHeader from '~/components/TableHeader'
import { headCells } from './constants'
import IssueTableBody from './IssueTableBody'
import PaginationTable from '~/components/PaginationTable'
import { useTestDataIssuesQuery } from '~/pages/TestData/DispatchShipmentReport/query'

const breadCrumbConfig = [
    { title: `Administrator`, route: ADMINISTRATOR },
    {
        title: `Test_Data_Dispatch_Shipment_Report`,
        route: ADMINISTRATOR_TEST_DATA_DISPATCH_SHIPMENT_REPORT,
    },
]

const TestDataIssues = () => {
    const localization = `pages.administrator.testData.overview.`
    const [state, setState] = useState('')
    const [status, setStatus] = useState('')
    const {
        data,
        results: issues,
        isLoading,
        page,
        setPage,
        order,
        orderBy,
        search,
        searchHandler,
        sortHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useTestDataIssuesQuery,
        initialStates: {
            orderBy: '',
            page: 1,
        },
        queryAttributes: {
            status,
            state,
        },
    })

    const refValue = useRef({
        search: '',
        status: '',
        state: '',
    })

    useEffect(() => {
        if (
            status !== refValue.current.status ||
            state !== refValue.current.state
        ) {
            setPage(1)
            refValue.current.status = status
            refValue.current.state = state
        }
    }, [status, state, setPage])
    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <PageName name={'Issues'} />
            <Paper>
                <IssueTableFilter
                    search={search}
                    searchHandler={searchHandler}
                    onEnterSearch={onEnterSearch}
                    status={status}
                    setStatus={setStatus}
                    state={state}
                    setState={setState}
                    showRangePicker={false}
                    showServiceCenter={false}
                />
                <Divider />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHeader
                            {...{
                                order,
                                orderBy,
                                localization,
                                headCells,
                                onRequestSort: sortHandler,
                            }}
                        />
                        <IssueTableBody {...{ isLoading, issues }} />
                    </Table>
                </TableContainer>
                <PaginationTable
                    {...{
                        data,
                        page,
                        handleChangePage: changePageHandler,
                        size: 'small',
                    }}
                />
            </Paper>
        </>
    )
}
export default TestDataIssues
