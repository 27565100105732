import { useMutation, useQueryClient } from 'react-query'
import serversApi from '~/api/production/serversApi'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useUpdateServerInfoMutation = ({ showSnackbar }) => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['update_server_info'],
        async ({ id, payload }) => {
            const { data } = await serversApi.updateServerInfo({ id, payload })
            return data.data
        },
        {
            onSuccess: () => {
                showSnackbar(
                    'Successfully update server information',
                    'success',
                )
                clientQuery.invalidateQueries('production-servers')
                clientQuery.invalidateQueries('production-server')
            },
            onError: (error) => {
                showSnackbar(parseApiErrorMessage(error), 'error')
            },
            retry: false,
        },
    )
}
