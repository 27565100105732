import dispatchShipmentReportApi from '~/api/dispatchShipmentReportApi'
import productApi from '~/api/productApi'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { useQuery } from 'react-query'
import { removeEmptyValue } from '~/utils/helpers'
import { partcodeApi } from '~/api'

export const useDispatchShipmentReportQuery = (params) => {
    return useQuery(
        ['dispatch_Shipment_Report', params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } =
                await dispatchShipmentReportApi.getDispatchShipmentReport(p)
            return { data: data.data, total: data.meta.total }
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const usePartDispatchShipmentReportQuery = (dispatchId) => {
    return useQuery(
        ['dispatch_Shipment_Report_Parts', dispatchId],
        async () => {
            const { data } =
                await dispatchShipmentReportApi.getPartsDispatchShipmentReport(
                    dispatchId,
                )
            return data.data
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useCheckProductSkuQuery = (
    sku,
    setSku,
    partNumber,
    currentIndex,
    mappingPair,
    setMappingPair,
    setSkuError,
) => {
    const { openSnackbar } = useDialogContext()
    return useQuery(
        ['check_product_sku'],
        async () => {
            const { data } = await productApi.checkProductSku(sku)
            return data
        },
        {
            onSuccess: (data) => {
                const arrIncErr = mappingPair.map((item, index) => {
                    if (
                        item.part_number === partNumber &&
                        index === currentIndex
                    ) {
                        return {
                            ...item,
                            error: false,
                        }
                    }

                    return item
                })
                setMappingPair(arrIncErr)
                setSkuError(false)
            },
            onError: (data) => {
                const message = data?.response?.data?.message
                    ? data?.response?.data?.message
                    : 'The SKU not found!'
                openSnackbar({
                    message,
                    type: 'error',
                })

                const arrIncErr = mappingPair.map((item, index) => {
                    if (
                        item.part_number === partNumber &&
                        index === currentIndex
                    ) {
                        return {
                            ...item,
                            error: true,
                        }
                    }

                    return item
                })
                setMappingPair(arrIncErr)
                setSkuError(true)
            },
            retry: false,
            enabled: !!sku,
            onSettled: () => setSku(''),
        },
    )
}

export const useGetPartcodeWithVoteQuery = (params) => {
    return useQuery(
        ['list_partcode_with_votes', params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await partcodeApi.getPartCodeWithVotes(p)
            return { data: data.data, total: data.meta.total }
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}
