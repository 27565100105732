import { Box } from '@mui/material'
import underConstruction from '~/assets/images/underconstruction.webp'

const Placeholder = () => {
    return (
        <Box>
            <img
                src={underConstruction}
                alt=''
                style={{ width: '80%', margin: '0 auto', display: 'flex' }}
            ></img>
        </Box>
    )
}

export default Placeholder
