export const selectPartCodeState = (state) => state.partcodeState.partcodeState

export const selectSelectedPartCodes = (state) => {
    return state.partcodeState.selectedPartCodes
}

export const selectCurrentCausePartCodes = (state) => {
    return state.partcodeState.currentCausePartCode
}

export const selectSelectedPartCodeTypes = (state) => {
    return state.partcodeState.selectedPartCodeTypes
}

export const selectShowedPartCode = (state) => {
    return state.partcodeState.showedPartCode
}

export const selectShowedPartCodeForDiagnostic = (state) => {
    return state.partcodeState.showedPartCodeForDiagnostic
}

export const selectSelectedPartCodeTypesForDiagnostic = (state) => {
    return state.partcodeState.selectedPartCodeTypesForDiagnostic
}

export const selectComponentPartCodeSelected = (state) => {
    return state.partcodeState.componentPartCodeSelected
}
