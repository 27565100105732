import { partcodeApi } from '~/api'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectShowedPartCode,
    selectShowedPartCodeForDiagnostic,
} from '~/store/partcode/selector'
import {
    pushShowPartCodes,
    pushShowPartCodesForDiagnostic,
} from '~/store/partcode/slice'
import { parseApiErrorMessage, removeEmptyValue } from '~/utils/helpers'

export const usePartCodeQuery = (params) => {
    return useQuery(
        ['partcodes', params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await partcodeApi.getPartCodeList(p)
            return { data: data.data, total: data.meta?.total }
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useCausesFilterPartCodeQuery = ({ clickedCause }) => {
    const showingPartCode = useSelector(selectShowedPartCode)
    const dispatch = useDispatch()

    return useQuery(
        ['causes_partcodes', clickedCause],
        async () => {
            const { data } = await partcodeApi.getCausePartCode({
                cause: clickedCause.causeId,
            })
            return data.data
        },
        {
            onSuccess: (data) => {
                const newCausePartCode = data.map((partCode) => {
                    return {
                        ...partCode,
                        qty: 1,
                        ppid: null,
                        causes: [clickedCause.causeTag],
                    }
                })
                const partCodeList = [...showingPartCode]

                if (partCodeList.length === 0) {
                    dispatch(pushShowPartCodes(newCausePartCode))
                } else {
                    const mergePartCodeList = JSON.parse(
                        JSON.stringify(showingPartCode),
                    )

                    newCausePartCode.forEach((item) => {
                        const foundIndex = mergePartCodeList.findIndex(
                            (zItem) => zItem.code === item.code,
                        )
                        if (foundIndex >= 0) {
                            if (!mergePartCodeList[foundIndex].causes) {
                                mergePartCodeList[foundIndex].causes = []
                            }
                            mergePartCodeList[foundIndex].causes.push(
                                item.causes[0],
                            )
                        } else {
                            mergePartCodeList.push(item)
                        }
                    })
                    dispatch(pushShowPartCodes(mergePartCodeList))
                }
            },
            onError: () => {},
            retry: false,
            enabled: !!Object.keys(clickedCause).length,
        },
    )
}

export const usePartCodeTypeQuery = () => {
    return useQuery(
        ['partCodeType'],
        async () => {
            const { data } = await partcodeApi.getTypes()
            return data
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useAllPartCodesQuery = ({ openSnackbar }) => {
    return useQuery(
        ['all_part_codes'],
        async () => {
            const { data } = await partcodeApi.getAllPartCodes()
            return data.data
        },
        {
            onSuccess: () => {},
            onError: (error) => {
                openSnackbar({
                    message: parseApiErrorMessage(error),
                    type: 'error',
                })
            },
            retry: false,
        },
    )
}

export const useCausesFilterPartCodeForDiagnosticQuery = ({ clickedCause }) => {
    const showingPartCode = useSelector(selectShowedPartCodeForDiagnostic)
    const dispatch = useDispatch()

    return useQuery(
        ['causes_partcodes', clickedCause],
        async () => {
            const { data } = await partcodeApi.getCausePartCode({
                cause: clickedCause.causeId,
            })
            return data.data
        },
        {
            onSuccess: (data) => {
                const newCausePartCode = data.map((partCode) => {
                    return {
                        ...partCode,
                        broken: null,
                        causes: [clickedCause.causeTag],
                    }
                })
                const partCodeList = [...showingPartCode]

                if (partCodeList.length === 0) {
                    dispatch(pushShowPartCodesForDiagnostic(newCausePartCode))
                } else {
                    const mergePartCodeList = JSON.parse(
                        JSON.stringify(showingPartCode),
                    )

                    newCausePartCode.forEach((item) => {
                        const foundIndex = mergePartCodeList.findIndex(
                            (zItem) => zItem.code === item.code,
                        )
                        if (foundIndex >= 0) {
                            mergePartCodeList[foundIndex].causes.push(
                                item.causes[0],
                            )
                        } else {
                            mergePartCodeList.push(item)
                        }
                    })
                    dispatch(pushShowPartCodesForDiagnostic(mergePartCodeList))
                }
            },
            onError: () => {},
            retry: false,
            enabled: !!Object.keys(clickedCause).length,
        },
    )
}
