import { Typography } from '@mui/material'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import useServiceCenterFormData from '../hooks/useServiceCenterFormData'
import ServiceCenterForm from '../ServiceCenterForm'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import usePermissions from '~/hooks/usePermission'

function Body({ data, localization }) {
    const { permissions } = usePermissions()

    const hasEditServiceCenterPermission =
        permissions.administrator.serviceCenter.canEditServiceCenter

    const { InputFieldGroup, selectGroup, branchesList } =
        useServiceCenterFormData()
    const { t } = useTranslation()
    return (
        <FormBodyWrapper>
            {data && hasEditServiceCenterPermission ? (
                <ServiceCenterForm
                    {...{
                        localization,
                        InputFieldGroup,
                        selectGroup,
                        branchesList,
                    }}
                />
            ) : (
                <Typography>{t(`message.data_not_found`)}</Typography>
            )}
        </FormBodyWrapper>
    )
}

Body.propTypes = { data: PropTypes.any, localization: PropTypes.string }

export default Body
