import rackScanApi from '~/api/rackScanApi'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { removeEmptyValue } from '~/utils/helpers'

export const useRackScanListQuery = (params) => {
    return useQuery(
        ['rack_scans', params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await rackScanApi.getRackScans(p)
            return { data: data.data, total: data.meta?.total }
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useScanDetailQuery = (id) => {
    return useQuery(
        ['scan_detail', id],
        async () => {
            const { data } = await rackScanApi.getScanDetail(id)
            return { data: data.data }
        },
        {
            onSuccess: (data) => {},
        },
    )
}

export const useLineRackScanQuery = (params) => {
    const { id } = useParams()
    return useQuery(
        ['rack_scan_line', params, id],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await rackScanApi.getRackScanLine(p, id)
            return { data: data.data, total: data.meta?.total }
        },
        {
            onSuccess: (data) => {},
        },
    )
}
