import { deviceApi } from '~/api'
import { FLEET_SERIAL, FLEET_SERIAL_DETAIL } from '~/constants/Routes'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    setDevice,
    setMessageDeviceError,
    setMessageSearchSerial,
} from '~/store/device/slice'

export const useDeviceDetailQuery = (id) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()
    return useQuery(
        ['device', id],
        async () => {
            const { data } = await deviceApi.getDeviceDetail(id)
            return data.data
        },
        {
            onSuccess: (data) => {
                dispatch(setDevice(data))
            },
            onError: () => {
                dispatch(setMessageDeviceError(t('message.somethingWrong')))
                navigate(FLEET_SERIAL)
            },
            enabled: !!id,
        },
    )
}
export const useDeviceDetailSearchQuery = (
    serial,
    setSerial = () => {},
    setOpenCamera = () => {},
) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return useQuery(
        ['serial_search', { serial }],
        async () => {
            const { data } = await deviceApi.getDeviceDetailSearch(serial)
            if (data.meta?.total) {
                return { data: data.data, total: data.meta.total }
            } else {
                return data.data
            }
        },
        {
            onSuccess: (data) => {
                if (data.total) {
                    localStorage.setItem('SEARCH_SERIAL', serial)
                    navigate(FLEET_SERIAL)
                } else {
                    dispatch(setDevice(data))
                    navigate(FLEET_SERIAL_DETAIL.replace(':id', data.id))
                }
                setOpenCamera(false)
            },
            onError: (e) => {
                dispatch(
                    setMessageSearchSerial({
                        status: e.response.status,
                        serial,
                    }),
                )
            },
            onSettled: () => setSerial(null),
            enabled: !!serial,
            retry: false,
        },
    )
}

export const useDeviceEntitlementsQuery = ({ id, ...params }) => {
    const p = { ...params, device_id: id }
    return useQuery(['device_entitlements', id, params], async () => {
        const { data } = await deviceApi.getDeviceEntitlements(p)
        return { data: data.data, total: data.meta?.total }
    })
}

export const useDeviceComponentsQuery = ({ id, ...params }) => {
    const p = { ...params, device_id: id }
    return useQuery(['device_components', id, params], async () => {
        const { data } = await deviceApi.getDeviceComponents(p)
        return { data: data.data, total: data.meta.total }
    })
}

export const useDeviceReplaceablePartQuery = ({ id, ...params }) => {
    return useQuery(['device_replaceablePart', params], async () => {
        const { data } = await deviceApi.getDeviceReplaceablePart(params, id)
        return { data: data.data, total: data.meta.total }
    })
}

export const useDeviceIntuneQuery = ({ id, ...params }) => {
    return useQuery(['device_intune', params], async () => {
        const { data } = await deviceApi.getDeviceIntune(params, id)
        return { data: data.data, total: data.meta.total }
    })
}

export const useIssuesQuery = ({ id, ...params }) => {
    const p = { ...params, device_id: id }
    return useQuery(['device_issues', params], async () => {
        const { data } = await deviceApi.getDeviceIssues(p)
        return { data: data.data, total: data.meta.total }
    })
}

export const useDeviceDispatchesQuery = ({ id, ...params }) => {
    const p = { ...params, id }
    return useQuery(['device_dispatches', params], async () => {
        const { data } = await deviceApi.getDeviceDispatches(p)
        return { data: data.data, total: data.meta.total }
    })
}

export const useDeviceImageQuery = (id) => {
    return useQuery(['device_image_query'], async () => {
        const { data } = await deviceApi.getDeviceImages(id)
        return data.data
    })
}
