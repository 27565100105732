import { ADMINISTRATOR, ADMINISTRATOR_TOOLTIP } from '../../../constants/Routes'

const useBreadCrumbConfig = (pageName) => {
    return [
        { title: `Administrator`, route: ADMINISTRATOR },
        {
            title: `Tooltips`,
            route: ADMINISTRATOR_TOOLTIP,
        },
        {
            title: pageName,
            page: 'detail',
        },
    ]
}

export default useBreadCrumbConfig
