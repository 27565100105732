import {
    Card,
    CardContent,
    Divider,
    Grid,
    Stack,
    Typography,
} from '@mui/material'
import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import Loading from '~/components/Loading'
import PageName from '~/components/PageName'
import { PART, PART_PRODUCT } from '~/constants/Routes'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import AddComponent from '../Component/AddComponent'
import ProductComponent from '../Component/ProductComponent'
import { useProductDetailQuery } from './query'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const ProductDetail = () => {
    const { id } = useParams()
    const { data, isLoading: isQueryLoading } = useProductDetailQuery(id)
    const BreadComponents = [
        { route: PART, title: 'Parts' },
        {
            route: PART_PRODUCT,
            title: 'Products',
        },
    ]

    const product = useMemo(() => data ?? [], [data])
    const localization = 'pages.administrator.product'
    const { t } = useTranslation('translation', { keyPrefix: localization })

    if (isQueryLoading) {
        return (
            <>
                <BreadCrumb breadcrumbs={BreadComponents} />
                <ComponentTooltip
                    componentId={`page_title`}
                    pagePath={PART_PRODUCT}
                >
                    <div>
                        <PageName name='Products' />
                    </div>
                </ComponentTooltip>
                <Loading />
            </>
        )
    }

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={PART_PRODUCT}
            >
                <div>
                    <PageName name='Product Detail' />
                </div>
            </ComponentTooltip>

            <Grid container spacing={2}>
                <Stack
                    direction='column'
                    spacing={3}
                    sx={{ width: '100%', mx: 2, mt: 6 }}
                >
                    <Card>
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                {['id', 'sku', 'name', 'code'].map(
                                    (fieldName, index) => (
                                        <Grid item xs={6} md={4} key={index}>
                                            <Typography
                                                variant='subtitle2'
                                                component='div'
                                            >
                                                {t(fieldName)}
                                            </Typography>
                                            <Typography
                                                variant='primary'
                                                component='div'
                                            >
                                                {product?.data?.[fieldName] ??
                                                    'NULL'}
                                            </Typography>
                                        </Grid>
                                    ),
                                )}

                                <Grid item xs={6} md={4}>
                                    <Typography
                                        variant='subtitle2'
                                        component='div'
                                    >
                                        {t('brand_id')}
                                    </Typography>
                                    <Typography
                                        variant='primary'
                                        component='div'
                                    >
                                        {product?.data?.brands?.name ?? 'NULL'}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6} md={4}>
                                    <Typography
                                        variant='subtitle2'
                                        component='div'
                                    >
                                        {t('created_by')}
                                    </Typography>
                                    <Typography
                                        variant='primary'
                                        component='div'
                                    >
                                        {product?.data?.created_by?.name ??
                                            'NULL'}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6} md={4}>
                                    <Typography
                                        variant='subtitle2'
                                        component='div'
                                    >
                                        {t('updated_by')}
                                    </Typography>
                                    <Typography
                                        variant='primary'
                                        component='div'
                                    >
                                        {product?.data?.updated_by?.name ??
                                            'NULL'}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6} md={4}>
                                    <Typography
                                        variant='subtitle2'
                                        component='div'
                                    >
                                        {t('created_at')}
                                    </Typography>
                                    <Typography
                                        variant='primary'
                                        component='div'
                                    >
                                        <DateTimeLocale
                                            datetime={product?.data?.created_at}
                                        />
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <Typography
                                        variant='subtitle2'
                                        component='div'
                                    >
                                        {t('description')}
                                    </Typography>
                                    <Typography
                                        variant='primary'
                                        component='div'
                                    >
                                        {product?.data?.description ?? 'NULL'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Stack>
            </Grid>

            <Grid container spacing={2}>
                <Stack
                    direction='column'
                    spacing={3}
                    sx={{ width: '100%', mx: 2, mt: 6 }}
                >
                    <AddComponent />

                    <Card>
                        <ProductComponent />
                    </Card>
                </Stack>
            </Grid>
        </>
    )
}

export default ProductDetail
