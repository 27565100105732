import axiosClient from '~/api/base'

class ImagesApi {
    getProductionImages = (params) => {
        return axiosClient.get('api/production/images', { params })
    }

    getProductionImage = (id) => {
        return axiosClient.get(`api/production/images/${id}`)
    }

    getProductionImageVersions = (id) => {
        return axiosClient.get(`api/production/image-versions/${id}`)
    }

    addProductionImage = (payload) => {
        return axiosClient.post('api/production/add-production-image', payload)
    }

    updateProductionImage = ({ id, payload }) => {
        return axiosClient.post(
            `api/production/update-image-info/${id}`,
            payload,
        )
    }
}

export default new ImagesApi()
