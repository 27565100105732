import { Box } from '@mui/material'
import Stack from '@mui/material/Stack'
import { useSnackbar } from '~/hooks/useSnackbar'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { selectSsoRedirectUrl } from '../../store/auth/selector'
import LoginHeader from './Header'
import ImageContent from './ImageContent'

const Login = () => {
    const { t } = useTranslation()
    const { showSnackbar, snackbar } = useSnackbar()

    const ssoRedirectUrl = useSelector(selectSsoRedirectUrl)

    const search = useLocation()
    const error = useMemo(() => {
        return new URLSearchParams(search.search).get('error')
    }, [search])

    const status = useMemo(() => {
        return new URLSearchParams(search.search).get('status')
    }, [search])

    if (ssoRedirectUrl) {
        window.location.href = ssoRedirectUrl
    }

    useEffect(() => {
        if (error) {
            showSnackbar(t('message.authenticationFail'), 'error')
        }
        if (status) {
            showSnackbar(t(`message.${status}`), 'warning')
        }
    }, [error, showSnackbar, status, t])

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Stack>
                <LoginHeader />
                <ImageContent />
                {snackbar}
            </Stack>
        </Box>
    )
}

export default Login
