import { TableRow } from '@mui/material'
import PropTypes from 'prop-types'
import {
    StyledCell,
    TableActionCell,
    TableCell,
    TableClickableCell,
    TableImageCell,
} from '~/components/TableCell'
import DateTimeLocale from './DateTimeLocale/DateTimeLocale'

const Row = ({ row, columnsConfig, color }) => {
    return (
        <TableRow hover tabIndex={-1} key={row.id}>
            {columnsConfig.map((item, index) => {
                let cellValue
                cellValue = row[item.id]
                if (item.customVal) {
                    cellValue = item.customVal(row)
                }

                if (item.dataType === 'datetime') {
                    cellValue = <DateTimeLocale datetime={cellValue} />

                    if (item.customDate) {
                        cellValue = item.customDate(row)
                    }
                }
                if (item.dataType === 'boolean') {
                    cellValue = cellValue ? 'True' : 'False'
                }

                if (item.clickable && item.link) {
                    return (
                        <TableClickableCell
                            cellValue={cellValue}
                            link={item.link.replace(':id', row.id)}
                            key={index}
                        />
                    )
                }

                if (item.clickable && item.id === 'action') {
                    return <TableActionCell item={item} row={row} key={index} />
                }

                if (item.dataType === 'image_name' && item.customVal) {
                    return <TableImageCell item={item} row={row} key={index} />
                }

                if (item.styledCell) {
                    return <StyledCell item={item} row={row} key={index} />
                }

                return (
                    <TableCell
                        columnsConfig={item}
                        cellValue={cellValue}
                        color={color}
                        key={index}
                    />
                )
            })}
        </TableRow>
    )
}

Row.propTypes = {
    row: PropTypes.object.isRequired,
    columnsConfig: PropTypes.array.isRequired,
    color: PropTypes.string,
}

export default Row
