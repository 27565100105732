import Proptypes from 'prop-types'
import { TextField } from '@mui/material'

const FormInputTextArea = ({
    innerRef,
    input,
    meta,
    variant,
    title,
    ...props
}) => {
    const hasErrors = meta.touched && meta.invalid
    const helperText = hasErrors && (meta.error || meta.submitError)

    return (
        <TextField
            {...input}
            {...props}
            multiline
            minRows={5}
            maxRows={10}
            ref={innerRef}
            error={hasErrors}
            helperText={helperText}
            variant={variant ?? 'outlined'}
            style={{ width: '100%' }}
        />
    )
}

FormInputTextArea.defaultProps = {
    innerRef: null,
    input: {},
    meta: {},
    title: '',
}

FormInputTextArea.propTypes = {
    innerRef: Proptypes.oneOfType([
        Proptypes.func,
        Proptypes.shape({ current: Proptypes.any }),
    ]),
    input: Proptypes.objectOf(Proptypes.any),
    meta: Proptypes.objectOf(Proptypes.any),
    title: Proptypes.string,
    variant: Proptypes.string,
}

export default FormInputTextArea
