import { useMutation, useQueryClient } from 'react-query'
import { setSnackbar } from '~/store/snackbar/slice'
import { useSupportObject } from '~/hooks/useSupportObject'
import dataSchedulerApi from '~/api/dataSchedulerApi'
import { ADMINISTRATOR_DATASCHEDULER_DETAIL } from '~/constants/Routes'
import { useDispatch } from 'react-redux'
import { setSchedulerData } from '~/store/scheduler/slice'

export const useCreateDataSchedulerMutation = () => {
    const { dispatch, navigate } = useSupportObject()
    return useMutation(
        ['data_schedule_create'],
        async (payload) => {
            const { data } = await dataSchedulerApi.createDataScheduler(payload)
            return data.data
        },
        {
            onSuccess: (data) => {
                dispatch(
                    setSnackbar({
                        message: data.message
                            ? data.message
                            : 'Create successful!',
                        type: 'success',
                    }),
                )

                navigate(
                    ADMINISTRATOR_DATASCHEDULER_DETAIL.replace(':id', data.id),
                )
                return data
            },
            onError: (error) => {
                const message = error.response.data.message
                    ? error.response.data.message
                    : error.message
                      ? error.message
                      : 'Create failed!'
                dispatch(
                    setSnackbar({
                        message,
                        type: 'error',
                    }),
                )
            },
            retry: false,
        },
    )
}

export const useUpdateDataSchedulerMutation = () => {
    const { id, dispatch, navigate } = useSupportObject()
    return useMutation(
        ['data_schedule_update', id],
        async (payload) => {
            const { data } = await dataSchedulerApi.updateDataScheduler(
                id,
                payload,
            )
            return data.data
        },
        {
            onSuccess: (data) => {
                dispatch(setSchedulerData(data))
                dispatch(
                    setSnackbar({
                        message: data.message
                            ? data.message
                            : 'Update successful!',
                        type: 'success',
                    }),
                )

                navigate(
                    ADMINISTRATOR_DATASCHEDULER_DETAIL.replace(':id', data.id),
                )
                return data
            },
            onError: (error) => {
                const message = error.response.data.message
                    ? error.response.data.message
                    : error.message
                      ? error.message
                      : 'Update failed!'
                dispatch(
                    setSnackbar({
                        message,
                        type: 'error',
                    }),
                )
            },
            retry: false,
        },
    )
}

export const useUpdateExportScheduleStatusMutation = () => {
    const queryClient = useQueryClient()
    return useMutation(
        ['update_status'],
        async ({ id, enabled }) => {
            const { data } = await dataSchedulerApi.updateStatusScheduler(id, {
                enabled,
            })
            return data
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('export_scheduler_detail')
            },
            onError: () => {},
            retry: false,
        },
    )
}

export const useDownloadFileMutation = () => {
    const dispatch = useDispatch()
    return useMutation(
        ['download_file'],
        async ({ path_token }) => {
            const { data } = await dataSchedulerApi.getDownLoadFile({
                path_token,
            })
            return data
        },
        {
            onSuccess: (data) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `export_scheduler.xlsx`)
                document.body.appendChild(link)
                link.click()
            },
            onError: (error) => {
                const message = error.response.data.message
                    ? error.response.data.message
                    : error.message
                      ? error.message
                      : 'Download failed!'
                dispatch(
                    setSnackbar({
                        message,
                        type: 'error',
                    }),
                )
            },
            retry: false,
        },
    )
}

export const useCheckStatementMutation = ({ setResultCheckStatement }) => {
    return useMutation(
        ['check_statement'],
        async (payload) => {
            const { data } = await dataSchedulerApi.checkStatement(payload)
            return data
        },
        {
            onSuccess: (data) => {
                setResultCheckStatement(data.status)
            },
            onError: (error) => {
                const message = error.response.data.message
                    ? error.response.data.message
                    : error.message
                      ? error.message
                      : 'incorrect'
                setResultCheckStatement(message)
            },
            retry: false,
        },
    )
}
