import styled from '@emotion/styled'
import SectionName from '~/components/SectionName'
import SubmitButton from '~/components/SubmitButton'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { useCallback, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import {
    useExportDellCoepSampleMutation,
    useImportDellCoepMutation,
} from './mutate'

import {
    Box,
    Card,
    CardContent,
    Grid,
    Link,
    Stack,
    Typography,
} from '@mui/material'
import ComponentTooltip from '~/components/ComponentTooltip'
import { FLEET_IMPORT_DEVICES } from '~/constants/Routes'

const ErrorBox = styled(Box)({
    height: '7rem',
    overflow: 'auto',
})

const ErrorTypography = styled(Typography)({
    color: '#d32f2f',
    fontFamily: "'Lato',sans-serif",
    fontSize: '0.75rem',
    fontWeightL: 400,
    lineHeight: 1.66,
})

const ImportDellCoep = () => {
    const { t } = useTranslation()
    const [selectedFile, setSelectedFile] = useState()
    const [failures, setFailures] = useState([])
    const [error, setError] = useState([])
    const { openSnackbar } = useDialogContext()
    const { mutate: actionImport, isLoading } = useImportDellCoepMutation()
    const { mutate: actionExport } = useExportDellCoepSampleMutation()

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0])
        setError([])
        setFailures([])
    }

    const onSubmit = useCallback(() => {
        actionImport(selectedFile, {
            onSuccess: (data) => {
                if (data.data.status === 'success') {
                    setError([])
                    setFailures([])
                    openSnackbar({
                        message: t(`message.importSuccess`),
                        type: 'success',
                    })
                }
            },
            onError: (e) => {
                setFailures(e.response.data.data)
                setError(e.response.data.message)
                openSnackbar({
                    message: t(`message.importFailed`),
                    type: 'error',
                })
            },
        })
    }, [actionImport, selectedFile, openSnackbar, t])

    return (
        <>
            <Form
                onSubmit={onSubmit}
                render={() => (
                    <Grid item xs={12} md={6}>
                        <ComponentTooltip
                            componentId={`import_device_session_COEP`}
                            pagePath={FLEET_IMPORT_DEVICES}
                        >
                            <div>
                                <SectionName
                                    name={t(
                                        `pages.device.importDevices.importDellCoep`,
                                    )}
                                />
                            </div>
                        </ComponentTooltip>
                        <Card style={{ height: '100%' }}>
                            <CardContent>
                                <Stack direction='row' spacing={3}>
                                    <Grid item xs={12} md={8}>
                                        <Box>{t(`general.uploadExcel`)}</Box>
                                        <Field>
                                            {() => (
                                                <input
                                                    id='file-import'
                                                    name='file'
                                                    type='file'
                                                    onChange={changeHandler}
                                                    accept='.xlsx'
                                                />
                                            )}
                                        </Field>
                                        <Link
                                            onClick={actionExport}
                                            target='_blank'
                                            style={{ display: 'block' }}
                                            download
                                        >
                                            {t(`general.downloadExampleFile`)}
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SubmitButton
                                            isLoading={isLoading}
                                            handleSubmit={onSubmit}
                                            name={`${t('button.import')}`}
                                        />
                                    </Grid>
                                </Stack>
                                <Stack direction='row' spacing={3}>
                                    <Grid item xs={12}>
                                        {failures && failures.length > 0 && (
                                            <ErrorBox>
                                                {failures.map((index) => (
                                                    <ErrorTypography
                                                        key={index.row}
                                                    >
                                                        (Row: {index.row}){' '}
                                                        {index.contents.map(
                                                            (content) =>
                                                                content,
                                                        )}
                                                    </ErrorTypography>
                                                ))}
                                            </ErrorBox>
                                        )}
                                        {error && (
                                            <ErrorBox>
                                                <ErrorTypography>
                                                    {error}
                                                </ErrorTypography>
                                            </ErrorBox>
                                        )}
                                    </Grid>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            />
        </>
    )
}

export default ImportDellCoep
