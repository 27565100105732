import { Table, TableCell, TableHead, TableRow } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableLoading from '~/components/Table/TableLoading'
import PartCodeListComponent from '~/components/partCodes/PartCodeListComponent'
import useTable from '~/hooks/useTable'
import { usePartCodeQuery } from '~/pages/Dispatch/AddDispatch/query'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectDiagnosticSerial } from '~/store/diagnose/selector'
import {
    selectSelectedPartCodeTypesForDiagnostic,
    selectSelectedPartCodes,
} from '~/store/partcode/selector'
import PartCodeListSelectorComponent from './PartCodeListSelectorComponent'

const PartCodeList = () => {
    const selectedPartCodeTypes = useSelector(
        selectSelectedPartCodeTypesForDiagnostic,
    )
    const [filteredCode, setFilteredCode] = useState('')
    const diagnosticSerial = useSelector(selectDiagnosticSerial)
    const addedPartCode = useSelector(selectSelectedPartCodes)

    const refValue = useRef({
        code: '',
        types: '',
    })

    const { data, setPage, isLoading, page, changePageHandler } = useTable({
        query: usePartCodeQuery,
        initialStates: {
            page: 1,
            order: null,
            orderBy: null,
            search: null,
        },
        queryAttributes: {
            code: filteredCode,
            types: selectedPartCodeTypes,
            serial: diagnosticSerial,
        },
    })

    const renderedPartCodes = useMemo(() => {
        if (isLoading || !data?.data) return []
        return data.data.map((partCode) => {
            const addedPart = addedPartCode.find(
                (el) => el.code === partCode.code,
            )
            let added = false
            if (addedPart !== undefined) {
                added = true
            }
            return {
                code: partCode.code,
                name: partCode.name,
                added,
                broken: null,
                type: partCode.type,
                serializable: partCode.serializable,
                causes: [{ id: '#', tag: '#' }],
            }
        })
    }, [data, isLoading, addedPartCode])

    useEffect(() => {
        if (
            filteredCode !== refValue.current.filteredCode ||
            selectedPartCodeTypes !== refValue.current.selectedPartCodeTypes
        ) {
            setPage(1)
            refValue.current.filteredCode = filteredCode
            refValue.current.selectedPartCodeTypes = selectedPartCodeTypes
        }
    }, [filteredCode, selectedPartCodeTypes, setPage])

    return (
        <>
            <PartCodeListComponent
                {...{
                    filteredCode,
                    setFilteredCode,
                    data,
                    page,
                    changePage: changePageHandler,
                }}
            >
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Part code</TableCell>
                            <TableCell>Part name</TableCell>
                            <TableCell align='center'>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    {isLoading ? (
                        <TableLoading colSpan={3} />
                    ) : (
                        <TableBody>
                            {renderedPartCodes.map((partCode) => (
                                <PartCodeListSelectorComponent
                                    key={partCode.code}
                                    partCode={partCode}
                                />
                            ))}
                        </TableBody>
                    )}
                </Table>
            </PartCodeListComponent>
        </>
    )
}

export default PartCodeList
