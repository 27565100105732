import { Box, Grid } from '@mui/material'
import PropTypes from 'prop-types'

const DispatchContactInfo = ({ t = (string) => string, dispatch }) => {
    return (
        <Box sx={{ px: 2, py: 1 }}>
            <Grid container spacing={3}>
                <Grid item xs={5}>
                    {t(`PrimaryContactName`)}
                </Grid>
                <Grid item xs={7}>
                    {dispatch?.contact?.name}
                </Grid>
                <Grid item xs={5}>
                    {t(`PrimaryContactPhone`)}
                </Grid>
                <Grid item xs={7}>
                    {dispatch?.contact?.phone}
                </Grid>
                <Grid item xs={5}>
                    {t(`PrimaryContactPhoneExt`)}
                </Grid>
                <Grid item xs={7}>
                    N/A
                </Grid>
                <Grid item xs={5}>
                    {t(`AdditionalEmail`)}
                </Grid>
                <Grid item xs={7}>
                    {dispatch?.contact?.email}
                </Grid>

                <Grid item xs={5}>
                    {t(`AltContactName`)}
                </Grid>
                <Grid item xs={7}>
                    N/A
                </Grid>
                <Grid item xs={5}>
                    {t(`AltContactEmail`)}
                </Grid>
                <Grid item xs={7}>
                    N/A
                </Grid>
                <Grid item xs={5}>
                    {t(`AltContactPhone`)}
                </Grid>
                <Grid item xs={7}>
                    N/A
                </Grid>
            </Grid>
        </Box>
    )
}

DispatchContactInfo.propTypes = {
    t: PropTypes.func,
    dispatch: PropTypes.object,
}

export default DispatchContactInfo
