import axiosClient from '~/api/base'

class ModelApi {
    getModels = (params) =>
        axiosClient.get('api/models', {
            params,
        })

    getModelDetails = (id) => axiosClient.get(`api/models/${id}`)

    postNewModelName = (id, name) =>
        axiosClient.post(`api/models/${id}/update-model-name`, { name })

    getModelCauses = (id, params) =>
        axiosClient.get(`api/model/${id}/causes`, { params })

    getModelPartCodes = (id, params) =>
        axiosClient.get(`api/models/${id}/part-codes`, { params })

    attachPartCodeIntoModel = (id, code) =>
        axiosClient.put(`api/models/${id}/attach-part-code`, { code })
}
export default new ModelApi()
