import {
    ADMINISTRATOR,
    ADMINISTRATOR_ROLE,
    ADMINISTRATOR_ROLE_CREATE,
} from '~/constants/Routes'
import { useCreateMutation } from './mutate'
import { useGetAllGuardsQuery, useGetAllPermissionsQuery } from './query'
import { Form } from 'react-final-form'
import useFormValidationCallback from '../../../hooks/useFormValidationCallback'
import { object, string } from 'yup'
import { useEffect, useMemo, useState } from 'react'
import arrayMutators from 'final-form-arrays'
import Body from './Body'
import BreadCrumb from '~/components/BreadCrumb'
import Toolbar from './Toolbar'
import ComponentTooltip from '~/components/ComponentTooltip'
import PageName from '~/components/PageName'

const breadCrumbConfig = [
    { title: `Administrator`, route: ADMINISTRATOR },
    {
        title: `Roles`,
        route: ADMINISTRATOR_ROLE,
    },
    {
        title: `Role Create`,
        page: 'detail',
    },
]

const RoleCreate = () => {
    const localization = 'pages.administrator.roleDetail'
    const { mutate: createRole } = useCreateMutation({})
    const [guardName, setGuardName] = useState()
    const { data: permissions } = useGetAllPermissionsQuery(guardName)

    const schema = object().shape({
        name: string().required(),
    })
    const validate = useFormValidationCallback(schema)

    const { data: guards } = useGetAllGuardsQuery()
    useEffect(() => {
        if (guards) {
            setGuardName(guards[0])
        }
    }, [guards])
    const initialValues = useMemo(() => {
        if (!permissions) return {}
        return {
            guard_name: guardName,
            permissions: permissions.map((permission) => {
                return { name: permission.name, value: false }
            }),
        }
    }, [guardName, permissions])

    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_ROLE_CREATE}
            >
                <div>
                    <PageName name='Create Role' />
                </div>
            </ComponentTooltip>
            <Form
                validate={validate}
                onSubmit={createRole}
                mutators={{
                    ...arrayMutators,
                }}
                keepDirtyOnReinitialize
                initialValues={initialValues}
                render={({ handleSubmit, values, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Toolbar localization={localization} />
                        <Body
                            localization={localization}
                            updateGuardName={setGuardName}
                            guards={guards}
                            form={form}
                        />
                    </form>
                )}
            />
        </>
    )
}
export default RoleCreate
