import causeApi from '~/api/causeApi'
import { useSupportObject } from '~/hooks/useSupportObject'
import { useMutation, useQueryClient } from 'react-query'
import { useDialogContext } from '~/components/providers/StyledDialogContext'

export const useCreateCauseMutation = () => {
    return useMutation(
        ['createCause'],
        async (payload) => {
            const { data } = await causeApi.createCause(payload)
            return data
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useEditCauseMutation = () => {
    return useMutation(['updateCause'], async ({ id, payload }) => {
        const { data } = await causeApi.updateCause(id, { payload })
        return data
    })
    // Success error case handle in EditCause.jsx
}

export const useAttachPartCodeToCause = () => {
    const { id, clientQuery } = useSupportObject()
    const { openSnackbar } = useDialogContext()
    return useMutation(
        ['attach_part_code_to_cause'],
        async (payload) => {
            const { data } = await causeApi.attachPartCodeToCause(id, payload)
            return data
        },
        {
            onSettled: async () => {
                clientQuery.invalidateQueries('cause_part_codes')
                clientQuery.invalidateQueries('model_part_codes')
            },
            onError: (e) => {
                openSnackbar({
                    message: e?.response?.data?.message || 'Update failed!',
                    type: 'error',
                })
            },
        },
    )
}

export const useDetachPartCodeFromCause = () => {
    const { id, clientQuery } = useSupportObject()

    return useMutation(
        ['detach_part_code_from_cause'],
        async (payload) => {
            const { data } = await causeApi.detachPartCodeFromCause(id, payload)
            return data
        },

        {
            onSuccess: (data) => {},
            onSettled: async () => {
                clientQuery.invalidateQueries('cause_part_codes')
                clientQuery.invalidateQueries('model_part_codes')
            },
        },
    )
}

export const useChangeDisableStateCauseMutation = () => {
    const clientQuery = useQueryClient()
    const { openSnackbar } = useDialogContext()
    return useMutation(
        'change_disabled_state_cause',
        async ({ id, isDisabled }) => {
            const apiCall = isDisabled
                ? causeApi.enableCause
                : causeApi.disableCause
            const { data } = await apiCall(id)

            return data
        },
        {
            onSuccess: (data) => {},
            onError: () => {
                openSnackbar({
                    message: 'Update failed!',
                    type: 'error',
                })
            },
            onSettled: async () => {
                clientQuery.invalidateQueries('cause_detail')
                clientQuery.invalidateQueries('diagnostic_root_causes')
                clientQuery.invalidateQueries('device_causes')
            },
        },
    )
}
