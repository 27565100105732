import styled from '@emotion/styled'
import {
    Box,
    Paper,
    Radio,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import { useEffect, useMemo, useRef } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import FormAutoSave from '~/components/FinalForm/FormAutoSave'
import { useIssueQuery } from '../../query'
import { useUpdateAnswersForQcMutation } from '../mutation'
import { qcFormCriteria } from './qcFormCriteria'
import PropTypes from 'prop-types'

const StyledTableCell = styled(TableCell)(() => ({
    borderRight: '0.5px solid lightgray',
    fontSize: 14,
    padding: 'none',
    '&:last-child': {
        borderRight: 'none',
    },
}))

const StyledRadio = ({ innerRef, inputOnChange, input, ...props }) => {
    const inputProps = {
        ...input,
        onChange: (e) => {
            input.onChange(e)

            inputOnChange && inputOnChange(e)
        },
    }
    return <Radio {...inputProps} {...props} />
}
StyledRadio.propTypes = {
    innerRef: PropTypes.any,
    inputOnChange: PropTypes.any,
    input: PropTypes.any,
}

const QCTable = ({ disabled, leftSide }) => {
    const rowRefs = useRef([])
    const tableContainerRef = useRef(null)
    const { t } = useTranslation('translation', {
        keyPrefix: 'pages.issues.qualityCheck.qcForm',
    })

    const formItems = qcFormCriteria.filter((qc) => qc.leftSide === leftSide)

    const scrollToNextRowIfNeeded = (currentRow) => {
        const currentIndex = rowRefs.current.findIndex(
            (ref) => ref === currentRow,
        )

        if (currentIndex < rowRefs.current.length - 1) {
            const nextRow = rowRefs.current[currentIndex + 1]
            const rect = nextRow.getBoundingClientRect()
            const isOutOfViewport =
                rect.bottom > window.innerHeight || rect.top < 0

            if (isOutOfViewport) {
                nextRow.scrollIntoView({ behavior: 'smooth', block: 'end' })
            }
        }
    }

    const isShownNAColumn = qcFormCriteria
        .filter((criteria) => criteria.leftSide === leftSide)
        .some((criteria) => criteria.NA === true)

    return (
        <TableContainer
            sx={{
                border: '0.5px solid lightgray',
                borderRadius: '6px',
            }}
            ref={tableContainerRef}
        >
            <Table>
                <TableHead>
                    <TableRow
                        sx={{
                            backgroundColor: '#f2f2f2',
                        }}
                    >
                        <StyledTableCell>
                            {t('tableHeaders.section')}
                        </StyledTableCell>
                        <StyledTableCell>
                            {t('tableHeaders.agree')}
                        </StyledTableCell>
                        <StyledTableCell>
                            {t('tableHeaders.disagree')}
                        </StyledTableCell>
                        {isShownNAColumn && (
                            <StyledTableCell>
                                {t('tableHeaders.na')}
                            </StyledTableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {formItems.map((criteria, index) => (
                        <TableRow
                            key={index}
                            ref={(ref) => (rowRefs.current[index] = ref)}
                        >
                            <StyledTableCell>
                                {t(criteria.name)}
                                {criteria.NA && t('ifAvailable')}
                            </StyledTableCell>
                            <StyledTableCell>
                                <Field
                                    name={criteria.name}
                                    component={StyledRadio}
                                    type='radio'
                                    value='true'
                                    disabled={disabled}
                                    inputOnChange={() => {
                                        scrollToNextRowIfNeeded(
                                            rowRefs.current[index],
                                        )
                                    }}
                                ></Field>
                            </StyledTableCell>
                            <StyledTableCell>
                                <Field
                                    name={criteria.name}
                                    component={StyledRadio}
                                    type='radio'
                                    value='false'
                                    disabled={disabled}
                                    inputOnChange={() => {
                                        scrollToNextRowIfNeeded(
                                            rowRefs.current[index],
                                        )
                                    }}
                                ></Field>
                            </StyledTableCell>
                            {isShownNAColumn && (
                                <StyledTableCell>
                                    {criteria.NA && (
                                        <Field
                                            name={criteria.name}
                                            component={StyledRadio}
                                            type='radio'
                                            value='null'
                                            disabled={disabled}
                                            inputOnChange={() => {
                                                scrollToNextRowIfNeeded(
                                                    rowRefs.current[index],
                                                )
                                            }}
                                        ></Field>
                                    )}
                                </StyledTableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

QCTable.propTypes = {
    disabled: PropTypes.bool,
    leftSide: PropTypes.bool,
}

const QCForm = ({
    data,
    isFetchedData,
    setDisableSaveButton,
    disabled,
    setQCFormOverruleData,
    isOverrule,
    setIsUpdatingAnswers,
}) => {
    const { issueId: id } = useParams()
    const { data: dataIssue } = useIssueQuery(id)

    const { mutateAsync: updateAnswers, isLoading } =
        useUpdateAnswersForQcMutation(dataIssue)

    useEffect(() => {
        setIsUpdatingAnswers(isLoading)
    }, [isLoading, setIsUpdatingAnswers])

    const onSubmit = async (values) => {
        const jsonValue = JSON.stringify(values ?? '{}')
        if (isOverrule) {
            setQCFormOverruleData(values)
        } else {
            await updateAnswers(
                { issueId: id, answers: jsonValue },
                {
                    onSuccess: () => {
                        const answers = values ?? {}
                        let answered = 0
                        qcFormCriteria.forEach((criteria) => {
                            if (typeof answers[criteria.name] !== 'undefined') {
                                answered++
                            }
                        })

                        setDisableSaveButton(answered < qcFormCriteria.length)
                    },
                },
            )
        }
    }

    const initData = useMemo(() => {
        if (isFetchedData) {
            return data
        }
        return null
    }, [data, isFetchedData])

    return (
        <Form
            initialValues={initData}
            onSubmit={onSubmit}
            render={({ handleSubmit }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <FormAutoSave debounce={1000} save={onSubmit} />
                        <Stack gap={2}>
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr',
                                    gap: '16px',
                                }}
                            >
                                <Paper sx={{ px: 2, py: 2 }}>
                                    <QCTable
                                        disabled={disabled}
                                        leftSide={true}
                                    />
                                </Paper>
                                <Paper sx={{ px: 2, py: 2 }}>
                                    <QCTable
                                        disabled={disabled}
                                        leftSide={false}
                                    />
                                </Paper>
                            </Box>
                        </Stack>
                    </form>
                )
            }}
        ></Form>
    )
}
QCForm.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    isFetchedData: PropTypes.bool,
    setDisableSaveButton: PropTypes.func,
    disabled: PropTypes.bool,
    setQCFormOverruleData: PropTypes.func,
    isOverrule: PropTypes.bool,
    setIsUpdatingAnswers: PropTypes.func,
}
export default QCForm
