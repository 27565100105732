import { Grid } from '@mui/material'
import InputField from '~/components/FinalForm/InputField'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import { ADMINISTRATOR_TOOLTIP_CREATE } from '~/constants/Routes'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Tabs from './Tabs'
import PropTypes from 'prop-types'
const pagePath = ADMINISTRATOR_TOOLTIP_CREATE

const BodyForm = ({ localization, values }) => {
    const { t } = useTranslation()
    const InputFieldGroup = useMemo(() => {
        return [
            {
                name: 'page_url',
                componentId: 'tooltip_form_page_url',
                isRequired: true,
                label: 'pageUrl',
            },
            {
                name: 'component_id',
                componentId: 'tooltip_form_component_id',
                isRequired: true,
                label: 'componentId',
            },
        ]
    }, [])
    return (
        <FormBodyWrapper>
            {InputFieldGroup.map((item, idx) => (
                <Grid item xs={12} key={idx}>
                    <InputField
                        localization={localization}
                        name={item.name}
                        componentId={`${item.componentId}`}
                        pagePath={pagePath}
                        isRequired={item.isRequired}
                        placeholder={t(`${localization}.${item.label}`)}
                        label={item.label}
                    />
                </Grid>
            ))}

            <Tabs {...{ values, pagePath }} />
        </FormBodyWrapper>
    )
}

BodyForm.propTypes = {
    localization: PropTypes.string,
    values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default BodyForm
