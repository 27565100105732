import {
    Checkbox,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material'
import PropTypes from 'prop-types'

const CheckListPartCodeType = ({
    renderedPartCodeTypes,
    selectedPartCodeTypes,
    handleToggle,
    isLoading,
}) => {
    if (isLoading) return <></>
    return (
        <List
            sx={{
                position: 'relative',
                overflow: 'auto',
                maxHeight: '770px',
                '& ul': { padding: 0 },
            }}
        >
            {renderedPartCodeTypes.map((code, index) => {
                return (
                    <ListItem key={index} disablePadding>
                        <ListItemIcon>
                            <Checkbox
                                edge='start'
                                checked={
                                    selectedPartCodeTypes.indexOf(code.name) !==
                                    -1
                                }
                                tabIndex={-1}
                                disableRipple
                                onChange={(e) => handleToggle(e, code)}
                            />
                        </ListItemIcon>
                        <ListItemText primary={code.label} />
                    </ListItem>
                )
            })}
        </List>
    )
}

CheckListPartCodeType.propTypes = {
    renderedPartCodeTypes: PropTypes.arrayOf(PropTypes.object),
    selectedPartCodeTypes: PropTypes.arrayOf(PropTypes.string),
    handleToggle: PropTypes.func,
    isLoading: PropTypes.bool,
}

export default CheckListPartCodeType
