import { ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_DETAIL } from '~/constants/Routes'

export const ServiceAccountColumnConfigs = [
    {
        id: 'id',
        key: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
    },
    {
        id: 'name',
        key: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        orderable: false,
        clickable: true,
        link: ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_DETAIL,
    },
    {
        id: 'description',
        key: 'description',
        numeric: false,
        disablePadding: false,
        label: 'description',
        orderable: false,
    },
    {
        id: 'contact',
        key: 'contact',
        numeric: false,
        disablePadding: false,
        label: 'contact',
        orderable: false,
    },
    {
        id: 'contact_email',
        key: 'contact_email',
        numeric: false,
        disablePadding: false,
        label: 'contact_email',
        orderable: false,
    },
    {
        id: 'revoked',
        key: 'revoked',
        numeric: false,
        disablePadding: false,
        label: 'revoked',
        orderable: false,
        dataType: 'boolean',
    },
    {
        id: 'created_at',
        key: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'created_at',
        orderable: false,
        dataType: 'datetime',
    },
    {
        id: 'updated_at',
        key: 'updated_at',
        numeric: false,
        disablePadding: false,
        label: 'updated_at',
        orderable: false,
        dataType: 'datetime',
    },
]
