import dispatchApi from '~/api/dispatchApi'
import { useQuery } from 'react-query'

export const useDispatchHistoryQuery = (params) => {
    return useQuery(
        ['dispatch_history', params],
        async () => {
            const { data } = await dispatchApi.getDispatchHistory(params)
            return { data: data.data, total: data.meta.total }
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}
