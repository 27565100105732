import BreadCrumb from '~/components/BreadCrumb'
import ListToolbar from '~/components/ListToolbar'
import PageName from '~/components/PageName'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_DEFINITION,
    ADMINISTRATOR_DEFINITION_CAUSE,
    ADMINISTRATOR_DEFINITION_CAUSE_NEW,
} from '~/constants/Routes'
import CausesTable from './CausesTable'
import AddIcon from '@mui/icons-material/Add'
import { useMemo } from 'react'
import ComponentTooltip from '~/components/ComponentTooltip'
import usePermissions from '~/hooks/usePermission'

const Cause = () => {
    const { permissions } = usePermissions()

    const BreadComponents = useMemo(() => {
        return [
            { route: ADMINISTRATOR, title: 'Administrator' },
            {
                route: ADMINISTRATOR_DEFINITION,
                title: 'Definitions',
            },
            {
                route: ADMINISTRATOR_DEFINITION_CAUSE,
                title: 'Causes',
            },
        ]
    }, [])

    const toolBarButtons = useMemo(() => {
        return [
            {
                button: 'create',
                navigateLink: ADMINISTRATOR_DEFINITION_CAUSE_NEW,
                icon: <AddIcon />,
                isPermission:
                    permissions.administrator.definitions.canCreateCauses,
            },
        ]
    }, [permissions.administrator.definitions.canCreateCauses])

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_DEFINITION_CAUSE}
            >
                <div>
                    <PageName name='Causes' />
                </div>
            </ComponentTooltip>
            <ListToolbar toolBarButton={toolBarButtons} />
            <CausesTable />
        </>
    )
}
export default Cause
