import AddIcon from '@mui/icons-material/Add'
import {
    Divider,
    TableBody as MuiTableBody,
    TableRow as MuiTableRow,
    Paper,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ComponentTooltip from '~/components/ComponentTooltip'
import DataNotFound from '~/components/DataNotFound'
import { NameTagRuleColumnConfig } from '~/components/HeaderTable/NameTagRuleColumnConfig'
import Loading from '~/components/Loading'
import PageName from '~/components/PageName'
import PaginationTable from '~/components/PaginationTable'
import ReferencedEntities from '~/components/ReferencedEntities'
import SearchToolBar from '~/components/SearchToolBar'
import TableContainer from '~/components/Table/TableContainer'
import TableLoading from '~/components/Table/TableLoading'
import TableToolbarContainer from '~/components/Table/TableToolbarContainer'
import { TableCell } from '~/components/TableCell'
import TableHeader from '~/components/TableHeader'
import {
    ADMINISTRATOR_NAMING_TAG,
    ADMINISTRATOR_NAMING_TAG_RULE_CREATE,
} from '~/constants/Routes'
import useTable from '~/hooks/useTable'
import ToolBarButton from '../../../components/ToolBarButton'
import { useTagRuleQuery } from '../query'

const RuleTableBody = ({ isLoading, rules }) => {
    if (isLoading) {
        return <TableLoading colSpan={NameTagRuleColumnConfig.length} />
    }
    return (
        <MuiTableBody>
            {rules.length > 0 ? (
                rules.map((row) => {
                    const itemNumbers = row?.component?.item_numbers || []
                    const itemNumber =
                        itemNumbers.length > 0 ? itemNumbers[0].item_number : ''
                    return (
                        <MuiTableRow hover tabIndex={-1} key={row.id}>
                            <TableCell cellValue={row?.id} />
                            <ReferencedEntities
                                isTableCell={true}
                                dataType={'naming-tags-rule-id'}
                                dataIdentifier={row.id}
                                dataDisplay={row?.device_name}
                            />
                            <TableCell
                                cellValue={`${row?.component.part_number} -- ${itemNumber}`}
                            />
                            <TableCell cellValue={row?.device_tag.name} />
                        </MuiTableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={NameTagRuleColumnConfig.length} />
            )}
        </MuiTableBody>
    )
}

RuleTableBody.propTypes = {
    isLoading: PropTypes.bool,
    rules: PropTypes.arrayOf(PropTypes.object),
}

const Table = () => {
    const { t } = useTranslation()
    const localization = 'pages.administrator.namingTag.'
    const navigate = useNavigate()
    const {
        results,
        data,
        isLoading,
        page,
        search,
        order,
        orderBy,
        searchHandler,
        sortHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useTagRuleQuery,
    })

    if (isLoading) {
        return <Loading />
    }
    return (
        <Paper>
            <ComponentTooltip
                componentId={`page_title_naming_rule`}
                pagePath={ADMINISTRATOR_NAMING_TAG}
            >
                <div>
                    <PageName name='Naming Rules' />
                </div>
            </ComponentTooltip>
            <TableToolbarContainer>
                <ToolBarButton
                    icon={<AddIcon />}
                    name={t(`${localization}add`)}
                    handleAction={() => {
                        navigate(ADMINISTRATOR_NAMING_TAG_RULE_CREATE)
                    }}
                />
            </TableToolbarContainer>
            <TableToolbarContainer>
                <SearchToolBar
                    onEnterSearch={onEnterSearch}
                    filterSearch={search}
                    onFilterSearch={searchHandler}
                    placeholder={`tagRules`}
                    sx={{
                        width: '20%',
                    }}
                />
            </TableToolbarContainer>
            <Divider />
            <TableContainer>
                <TableHeader
                    headCells={NameTagRuleColumnConfig}
                    localization={localization}
                    onRequestSort={sortHandler}
                    order={order}
                    orderBy={orderBy}
                />
                <RuleTableBody isLoading={isLoading} rules={results} />
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}

export default Table
