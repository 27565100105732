import { Grid } from '@mui/material'
import AccordionDevice from '~/components/AccordionDevice'
import ToolbarDetailPage from '~/components/ToolbarDetailPage'
import { ISSUE_RACKSCAN_SCAN } from '~/constants/Routes'
import { useState } from 'react'
import RackScanLineSession from './RackScanLineSession'
import RackScanSession from './RackScanSession'
import PropTypes from 'prop-types'
const localization = 'pages.issues.rackScans.scan.detail'

const RackScanContent = ({ rackScan }) => {
    const accordionsList = [
        {
            index: 1,
            title: 'rackScan',
            content: <RackScanSession data={rackScan} />,
        },
        {
            index: 2,
            title: 'rackScanLine',
            content: <RackScanLineSession />,
        },
    ]

    const accordionArray = accordionsList.map((item) => item.index)
    const [accordions, setAccordions] = useState(accordionArray)

    const handleChange = (panel) => (event) => {
        if (accordions.includes(panel)) {
            const filteredItems = accordions.filter((item) => item !== panel)
            setAccordions(filteredItems)
        }
        if (!accordions.includes(panel)) {
            accordions.push(panel)
            setAccordions([...accordions])
        }
    }

    const showAll = () => {
        setAccordions(accordionArray)
    }

    const hideAll = () => {
        setAccordions([])
    }

    const Buttons = [
        {
            navigateLink: ISSUE_RACKSCAN_SCAN,
            button: 'close',
            color: 'close',
        },
    ]

    return (
        <>
            <ToolbarDetailPage
                showAll={showAll}
                hideAll={hideAll}
                Buttons={Buttons}
            />
            {rackScan && (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {accordionsList.map((item) => (
                            <AccordionDevice
                                localization={localization}
                                item={item}
                                accordions={accordions}
                                handleChange={handleChange}
                                key={item.index}
                            />
                        ))}
                    </Grid>
                </Grid>
            )}
        </>
    )
}

RackScanContent.propTypes = { rackScan: PropTypes.object }

export default RackScanContent
