import { ChangeCircle } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '~/components/Button'
import { useMemo, useState } from 'react'
import { toggleCanProceed } from '~/store/issue/slice'
import { useLoginUserSwitchActiveTeamMutation } from '~/pages/UserDetail/mutate'
import { useGetTeamsByServiceCenter } from '~/pages/UserDetail/query'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import StyledModal from '~/components/StyledModal'

export const SwitchTeamButton = ({ serviceCenter, user }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [open, setOpen] = useState(false)
    const { mutate: activateTeam, isLoading: isActivating } =
        useLoginUserSwitchActiveTeamMutation()

    const { data: teams, isLoading: isGetting } = useGetTeamsByServiceCenter(
        serviceCenter?.id,
        serviceCenter?.name,
        user?.current_team?.service_center?.id,
    )
    const hasTeamToSwitch = useMemo(() => teams?.length > 0, [teams])

    const switchTeam = (team_id) => {
        activateTeam(
            { team_id },
            {
                onSettled: () => {
                    setOpen(false)
                },
            },
        )
    }

    if (isGetting) {
        return <LoadingButton label={t('button.switchTeam')} loading={true} />
    }

    if (!hasTeamToSwitch) {
        dispatch(toggleCanProceed(false))
        return <></>
    }

    return (
        <>
            <LoadingButton
                label={
                    teams.length === 1
                        ? t('button.switchNameTeam', { name: teams[0].name })
                        : t('button.switchTeam')
                }
                startIcon={<ChangeCircle />}
                loading={isActivating}
                onClick={() => {
                    teams.length === 1 ? switchTeam(teams[0].id) : setOpen(true)
                }}
            />
            <StyledModal
                onBackdropClick={() => {}}
                open={open}
                handleClose={() => setOpen(false)}
                stackProps={{
                    gap: 2,
                    sx: {
                        minWidth: 600,
                        maxHeight: '95vh',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '10px',
                            backgroundColor: 'rgba(0, 0, 0, .5)',
                        },
                        '&::-webkit-scrollbar': {
                            width: '10px',
                        },
                    },
                }}
            >
                <Typography variant='h6' component='h2'>
                    {t('pages.user.userDetail.switchTeam')}
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('table.name')}</TableCell>
                            <TableCell align='center'>
                                {t('table.action')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {teams.map((team) => (
                            <TableRow key={team.id}>
                                <TableCell>{team.name}</TableCell>
                                <TableCell align='center'>
                                    <LoadingButton
                                        loading={isActivating}
                                        onClick={() => switchTeam(team.id)}
                                        label={t('button.select')}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </StyledModal>
        </>
    )
}

SwitchTeamButton.propTypes = {
    serviceCenter: PropTypes.object,
    user: PropTypes.object,
}
