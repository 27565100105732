import { FormControl, Grid, Typography } from '@mui/material'
import InputField from '~/components/FinalForm/InputField'
import SelectField from '~/components/FinalForm/SelectField'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import { ADMINISTRATOR_DEFINITION_REGIONS_EDIT } from '~/constants/Routes'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import useRegionForm from '../hooks/useRegionForm'
import PropTypes from 'prop-types'

const pagePath = ADMINISTRATOR_DEFINITION_REGIONS_EDIT
const Body = ({ data, localization }) => {
    const { t } = useTranslation()
    const { InputFieldGroup, selectGroup } = useRegionForm()

    return (
        <FormBodyWrapper>
            <Grid container spacing={2}>
                {data ? (
                    <>
                        {InputFieldGroup.map((item, idx) => (
                            <Grid item xs={12} key={idx}>
                                <InputField
                                    localization={localization}
                                    name={item.name}
                                    componentId={item.componentId}
                                    pagePath={pagePath}
                                    isRequired={item.isRequired}
                                    placeholder={t(
                                        `${localization}.${item.name}`,
                                    )}
                                />
                            </Grid>
                        ))}
                        {selectGroup.map((item, index) => {
                            return (
                                <Grid item xs={12} key={index}>
                                    <Grid item xs={12}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Field
                                                name={item.name}
                                                component={SelectField}
                                                items={item.listResource}
                                                localization={localization}
                                                componentId={item.componentId}
                                                pagePath={pagePath}
                                                nameSelect={item.title}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </>
                ) : (
                    <Typography>{t(`message.data_not_found`)}</Typography>
                )}
            </Grid>
        </FormBodyWrapper>
    )
}

Body.propTypes = { data: PropTypes.any, localization: PropTypes.string }

export default Body
