import { Grid } from '@mui/material'
import ExternalLink from '~/components/ExternalLink'
import { EASY4U_CUSTOMER_ORDER_NUMBER } from '~/constants/externalLink'
import { useSelector } from 'react-redux'
import { selectPurchaseOrderLine } from '~/store/purchaseOrder/selector'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const OrderSession = () => {
    const purchaseOrderLine = useSelector(selectPurchaseOrderLine)
    return (
        <Grid container spacing={3}>
            <Grid item xs={4}>
                Order number
            </Grid>
            <Grid item xs={8}>
                <ExternalLink
                    extLink={EASY4U_CUSTOMER_ORDER_NUMBER}
                    param={purchaseOrderLine?.purchase_order?.id}
                />
            </Grid>
            <Grid item xs={4}>
                Supplier
            </Grid>
            <Grid item xs={8}>
                {purchaseOrderLine?.purchase_order?.supplier}
            </Grid>
            <Grid item xs={4}>
                Entity Id
            </Grid>
            <Grid item xs={8}>
                {purchaseOrderLine?.purchase_order?.entity_id}
            </Grid>
            <Grid item xs={4}>
                Contact name
            </Grid>
            <Grid item xs={8}>
                {purchaseOrderLine?.purchase_order?.contact_name}
            </Grid>
            <Grid item xs={4}>
                Order date
            </Grid>
            <Grid item xs={8}>
                <DateTimeLocale
                    datetime={purchaseOrderLine?.purchase_order?.order_date}
                />
            </Grid>
            <Grid item xs={4}>
                Expected delivery date
            </Grid>
            <Grid item xs={8}>
                <DateTimeLocale
                    datetime={
                        purchaseOrderLine?.purchase_order
                            ?.expected_delivery_date
                    }
                />
            </Grid>
        </Grid>
    )
}

export default OrderSession
