import {
    ADMINISTRATOR_DEFINITION_SERVICECENTER_EDIT,
    ADMINISTRATOR_DEFINITION_SERVICECENTER_DETAIL,
} from '~/constants/Routes'

export const ServiceCenterHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
        clickable: true,
        link: ADMINISTRATOR_DEFINITION_SERVICECENTER_EDIT,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        orderable: true,
    },
    {
        id: 'contact_person_id',
        numeric: false,
        disablePadding: false,
        label: 'contactPerson',
        orderable: false,
        relationship: 'name',
        customVal: (row) => {
            return row.contact_person?.name
        },
    },
    {
        id: 'address_id',
        numeric: false,
        disablePadding: false,
        label: 'address',
        orderable: false,
        relationship: 'name',
        customVal: (row) => {
            return row.address?.name
        },
    },
]

export const ServiceCenterDetailHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
        clickable: true,
        link: ADMINISTRATOR_DEFINITION_SERVICECENTER_DETAIL,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        orderable: true,
    },
    {
        id: 'contact_person_id',
        numeric: false,
        disablePadding: false,
        label: 'contactPerson',
        orderable: false,
        relationship: 'name',
        customVal: (row) => {
            return row.contact_person?.name
        },
    },
    {
        id: 'region_id',
        numeric: false,
        disablePadding: false,
        label: 'region',
        orderable: false,
        relationship: 'name',
        customVal: (row) => {
            return row.region?.name
        },
    },
    {
        id: 'address_id',
        numeric: false,
        disablePadding: false,
        label: 'address',
        orderable: false,
        relationship: 'name',
        customVal: (row) => {
            return row.address?.name
        },
    },

    {
        id: 'separate_triage',
        numeric: false,
        disablePadding: false,
        label: 'separateTriage',
        orderable: false,
        relationship: 'name',
        customVal: (row) => {
            return row.separate_triage ? 'Yes' : 'No'
        },
    },
]
