import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { IconButton } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import SkuSelectionPopup from '~/pages/BOM/components/SkuSelectionPopup'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import { PARTS_NOT_READY } from '../Detail/constants'

const SkuDisplayer = ({ bomId, sku, bomState }) => {
    const [open, setOpen] = useState(false)
    const isPartNotReady = bomState === PARTS_NOT_READY
    const handleOpenModal = useCallback(() => {
        if (!sku || isPartNotReady) {
            setOpen(true)
        }
    }, [isPartNotReady, sku])
    return (
        <>
            {sku}
            <IconButton size='small' onClick={() => handleOpenModal()}>
                {!sku ? (
                    <AddCircleIcon color='primary' />
                ) : (
                    isPartNotReady && <BorderColorIcon color='primary' />
                )}
            </IconButton>

            {open && (
                <SkuSelectionPopup
                    bomId={bomId}
                    open={open}
                    onClose={() => setOpen(false)}
                    sku={sku}
                />
            )}
        </>
    )
}

SkuDisplayer.propTypes = {
    bomId: PropTypes.number,
    sku: PropTypes.string,
    bomState: PropTypes.string,
}

export default SkuDisplayer
