import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import { Grid } from '@mui/material'

import SubmitButton from '~/components/SubmitButton'
import StyledModal from '~/components/StyledModal'
import ImageList from '~/components/Image/ImageList'
import Webcam from '~/pages/Diagnostic/components/Webcam'
import { CloseButton } from './CancelButton'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
    },
}))
const PictureCapturePopup = ({
    open,
    isLoading,
    selectedFiles,
    setSelectedFiles,
    onSubmit,
    onClose,
    imageLimit,
    btnLabel = 'button.save',
    showCheckbox = false,
    showRemoveCurrentImage = true,
    currentImageList = [],
    handleRemoveCurrent = () => {},
    handleChecked = () => {},
}) => {
    const style = useStyles()
    const { t } = useTranslation()
    const [videoWidth, setVideoWidth] = useState(window.innerHeight * 1.25)
    const handleRemoveImage = (item) => {
        if (item.is_current !== true) {
            setSelectedFiles((current) => {
                return current.filter((_, index) => index !== item.id)
            })
        } else {
            handleRemoveCurrent(item)
        }
    }

    const resizeVideo = () => {
        setVideoWidth(window.innerHeight * 1.25)
    }

    const imageLists = useMemo(() => {
        const list = selectedFiles
            ? selectedFiles.map((file, key) => ({
                  src: file.image,
                  id: key,
                  checked: file.checked,
                  removeable: file.removeable !== false,
              }))
            : []
        return [...currentImageList, ...list]
    }, [currentImageList, selectedFiles])

    useEffect(() => {
        window.addEventListener('resize', resizeVideo)
        return () => {
            window.removeEventListener('resize', resizeVideo)
        }
    }, [])

    return (
        <StyledModal
            {...{ open, handleClose: onClose }}
            stackProps={{
                width: 'auto',
                padding: '2px 10px',
                sx: {
                    borderRadius: 0,
                },
            }}
        >
            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid
                            container
                            direction='column'
                            className={style.container}
                        >
                            <Grid item>
                                <Webcam
                                    {...{
                                        selectedFiles,
                                        setSelectedFiles,
                                        imageLimit,
                                        videoWidth,
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <ImageList
                                    {...{
                                        handleRemoveImage,
                                    }}
                                    images={imageLists}
                                    imageListProps={{
                                        cols: 10,
                                        sx: {
                                            width: videoWidth,
                                        },
                                    }}
                                    showCheckbox={showCheckbox}
                                    handleChecked={handleChecked}
                                    showRemoveCurrentImage={
                                        showRemoveCurrentImage
                                    }
                                />
                            </Grid>
                            <Grid padding={1} item>
                                <Grid
                                    container
                                    alignItems='center'
                                    direction='row'
                                    spacing={2}
                                    justifyContent='center'
                                >
                                    <Grid item>
                                        <CloseButton onClick={onClose} />
                                    </Grid>
                                    <Grid item>
                                        <SubmitButton
                                            {...{
                                                isLoading,
                                            }}
                                            name={`${t(btnLabel)}`}
                                            disabled={selectedFiles.length <= 0}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )}
            />
        </StyledModal>
    )
}

PictureCapturePopup.propTypes = {
    open: PropTypes.bool,
    isLoading: PropTypes.bool,
    selectedFiles: PropTypes.array,
    setSelectedFiles: PropTypes.func,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    imageLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    btnLabel: PropTypes.string,
    showCheckbox: PropTypes.bool,
    showRemoveCurrentImage: PropTypes.bool,
    currentImageList: PropTypes.array,
    handleRemoveCurrent: PropTypes.func,
    handleChecked: PropTypes.func,
}
export default PictureCapturePopup
