import DeleteIcon from '@mui/icons-material/Delete'
import {
    Table as MuiTable,
    TableBody as MuiTableBody,
    Paper,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ProductionOderLineHeadCells } from '~/components/HeaderTable/ProductionOderLineHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import DeleteOrderLine from '../DeleteOrderLine'
import { useOrderLineQuery } from '../query'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const TableBody = ({ isLoading, object }) => {
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
    const [serialDelete, setSerialDelete] = useState('')
    const deleteOrderLine = (serial) => {
        setOpenDeleteConfirm(true)
        setSerialDelete(serial)
    }
    const handleClose = () => {
        setOpenDeleteConfirm(false)
    }

    if (isLoading) {
        return <TableLoading colSpan={ProductionOderLineHeadCells.length} />
    }

    return (
        <MuiTableBody>
            {object.length > 0 &&
                object.map((row, index) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row?.id}>
                            <TableCell>{row?.serial}</TableCell>
                            <TableCell>{row?.state}</TableCell>
                            <TableCell>{'-'}</TableCell>
                            <TableCell>{'-'}</TableCell>
                            <TableCell>{'-'}</TableCell>
                            <TableCell>
                                <DateTimeLocale datetime={row?.created_at} />
                            </TableCell>
                            <TableCell>
                                <DeleteIcon
                                    sx={{
                                        marginLeft: '10px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => deleteOrderLine(row.serial)}
                                />
                                {openDeleteConfirm && (
                                    <DeleteOrderLine
                                        openDeleteConfirm={openDeleteConfirm}
                                        handleClose={handleClose}
                                        serialDelete={serialDelete}
                                        orderId={row.production_order_id}
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                    )
                })}
        </MuiTableBody>
    )
}

TableBody.propTypes = {
    isLoading: PropTypes.bool,
    object: PropTypes.arrayOf(PropTypes.object),
}

const OrderLineTable = () => {
    const { production_order_nr } = useParams()
    const {
        results,
        data,
        isLoading,
        page,
        order,
        orderBy,
        changePageHandler,
        sortHandler,
    } = useTable({
        query: useOrderLineQuery,
        queryAttributes: {
            production_order_nr,
        },
    })
    return (
        <Paper>
            <TableContainer
                sx={{ width: '100%' }}
                aria-labelledby='tableTitle'
                size='medium'
            >
                <MuiTable>
                    <TableHeader
                        headCells={ProductionOderLineHeadCells}
                        localization={'pages.production.orderLine.'}
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />
                    <TableBody isLoading={isLoading} object={results} />
                </MuiTable>
                <PaginationTable
                    data={data}
                    page={page}
                    handleChangePage={changePageHandler}
                    size='medium'
                />
            </TableContainer>
        </Paper>
    )
}

export default OrderLineTable
