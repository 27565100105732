import { Grid } from '@mui/material'
import AccordionDevice from '~/components/AccordionDevice'
import ToolbarDetailPage from '~/components/ToolbarDetailPage'
import {
    ADMINISTRATOR_DEFINITION_REGIONS,
    ADMINISTRATOR_DEFINITION_REGIONS_EDIT,
} from '~/constants/Routes'
import { useState } from 'react'
import RegionSession from './RegionSession'
import ServiceCenterSession from './ServiceCenterSession'
import PropTypes from 'prop-types'
const localization = 'pages.administrator.definition.regions.overview'

const RegionContent = ({ region }) => {
    const accordionsList = [
        {
            index: 1,
            title: 'region',
            content: <RegionSession data={region} />,
        },
        {
            index: 2,
            title: 'serviceCenter',
            content: <ServiceCenterSession />,
        },
    ]

    const accordionArray = accordionsList.map((item) => item.index)
    const [accordions, setAccordions] = useState(accordionArray)

    const handleChange = (panel) => (event) => {
        if (accordions.includes(panel)) {
            const filteredItems = accordions.filter((item) => item !== panel)
            setAccordions(filteredItems)
        }
        if (!accordions.includes(panel)) {
            accordions.push(panel)
            setAccordions([...accordions])
        }
    }

    const showAll = () => {
        setAccordions(accordionArray)
    }

    const hideAll = () => {
        setAccordions([])
    }

    const Buttons = [
        {
            navigateLink: ADMINISTRATOR_DEFINITION_REGIONS,
            button: 'close',
            color: 'close',
        },
        {
            navigateLink: ADMINISTRATOR_DEFINITION_REGIONS_EDIT.replace(
                ':id',
                region?.id,
            ),
            button: 'edit',
        },
    ]

    return (
        <>
            <ToolbarDetailPage
                showAll={showAll}
                hideAll={hideAll}
                Buttons={Buttons}
            />
            {region && (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {accordionsList.map((item) => (
                            <AccordionDevice
                                key={item.index}
                                localization={localization}
                                item={item}
                                accordions={accordions}
                                handleChange={handleChange}
                            />
                        ))}
                    </Grid>
                </Grid>
            )}
        </>
    )
}

RegionContent.propTypes = { region: PropTypes.any }

export default RegionContent
