import { createSlice } from '@reduxjs/toolkit'

export const userStateSlice = createSlice({
    name: 'userState',
    initialState: {
        userState: null,
        userDelete: null,
        userDetailInformation: null,
    },
    reducers: {
        setUserState: (state, action) => {
            state.userState = action.payload
        },
        setMessageDeleteUser: (state, action) => {
            state.userDelete = action.payload
        },
        setUserDetailInformation: (state, action) => {
            state.userDetailInformation = action.payload
        },
    },
})

export const { setUserState, setMessageDeleteUser, setUserDetailInformation } =
    userStateSlice.actions

export default userStateSlice
