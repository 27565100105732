import { Grid } from '@mui/material'
import DeviceDocuments from './DeviceDocuments'
import DevicePictures from './DevicePictures'

const DeviceAttachments = () => {
    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <DeviceDocuments />
            </Grid>
            <Grid item xs={12}>
                <DevicePictures />
            </Grid>
        </Grid>
    )
}

export default DeviceAttachments
