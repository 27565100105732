export const RoleHeadCells = [
    { id: 'drop_down_btn', hidden: true },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        orderable: true,
    },
    {
        id: 'guard_name',
        numeric: false,
        disablePadding: false,
        label: 'guard_name',
        orderable: true,
    },
    {
        id: 'permissions',
        numeric: false,
        disablePadding: false,
        label: 'permissions',
        orderable: false,
    },
]
