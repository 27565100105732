import { partcodeApi } from '~/api'
import causeApi from '~/api/causeApi'
import modelApi from '~/api/modelApi'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useDialogContext } from '~/components/providers/StyledDialogContext'

export const useCausesQuery = (params) => {
    const locale = localStorage.getItem('LOCALE')
    params = { ...params, locale }
    return useQuery(
        ['causes', params],
        async () => {
            const { data } = await causeApi.getCauses(params)
            return { data: data.data, total: data.meta.total }
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useCauseDetailQuery = (id) => {
    return useQuery(
        ['cause_detail', id],
        async () => {
            const { data } = await causeApi.getCauseDetail(id)
            return data.data
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useDeviceModelSelectionQuery = (params) => {
    return useQuery(
        ['device_model'],
        async () => {
            const { data } = await modelApi.getModels(params)
            return data?.data
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useModelPartCodeTypeQuery = (params) => {
    return useQuery(['model_part_code_types', params], async () => {
        const { data } =
            typeof params.model !== 'undefined'
                ? await partcodeApi.getModelPartCodeType(params)
                : await partcodeApi.getAllPartCodeType(params)
        return data
    })
}

export const useModelPartCodeQuery = (params) => {
    const { id } = useParams()
    const { openSnackbar } = useDialogContext()

    return useQuery(
        ['model_part_codes', params],
        async () => {
            params = { ...params, cause: id }
            const { data } =
                typeof params.model !== 'undefined'
                    ? await partcodeApi.getModelPartCode(params)
                    : await partcodeApi.getAllPartCodes({
                          ...params,
                          paginated: 1,
                      })
            return { data: data?.data, total: data?.meta?.total }
        },
        {
            onError: (e) => {
                openSnackbar({
                    message: e?.response?.data?.message,
                    type: 'error',
                })
            },
            retry: false,
        },
    )
}

export const useCausePartCodeQuery = (params) => {
    return useQuery(['cause_part_codes', params], async () => {
        const { data } = await partcodeApi.getCausePartCode(params)
        return { data: data?.data, total: data?.meta?.total }
    })
}

export const useCauseModelsQuery = () => {
    return useQuery(['cause_models'], async () => {
        const { data } = await causeApi.getModels()
        return { data: data?.data }
    })
}
