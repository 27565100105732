import styled from '@emotion/styled'
import { TableRow } from '@mui/material'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
}))

export default StyledTableRow
