import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import ComponentTooltip from '~/components/ComponentTooltip'
import Loading from '~/components/Loading'
import PageName from '~/components/PageName'
import PageLayout from '~/components/layout/PageLayout'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_DEFINITION,
    ADMINISTRATOR_DEFINITION_CAUSE,
    ADMINISTRATOR_DEFINITION_CAUSE_EDIT,
    ADMINISTRATOR_DEFINITION_CAUSE_NEW,
} from '~/constants/Routes'
import { useCauseDetailQuery } from '../query'
import CausePartCodeSection from './CausePartCodeSection'
import EditCauseForm from './EditCause'
import { useTranslation } from 'react-i18next'
import { useDialogContext } from '~/components/providers/StyledDialogContext'

const CauseEdit = () => {
    const { openSnackbar } = useDialogContext()
    const { t } = useTranslation()
    const { id } = useParams()
    const { data: causeContent, isSuccess } = useCauseDetailQuery(id)
    const [selectedModelIds, setSelectedModelIds] = useState([])

    useEffect(() => {
        if (isSuccess && causeContent?.duplicate_models_by_tid?.length > 0) {
            const tag = causeContent.tid
            const msg = causeContent.duplicate_models_by_tid.map((item) => {
                const { models, cause_id } = item
                return t('pages.causes.duplicatedModels', {
                    models,
                    cause_id,
                    tag,
                })
            })
            if (msg.length > 0) {
                openSnackbar({
                    message: msg.join('\n'),
                    type: 'error',
                    duration: 8000,
                    alertSx: { whiteSpace: 'pre' },
                })
            }
        }
    }, [causeContent, isSuccess, openSnackbar, t])

    const BreadComponents = useMemo(
        () => [
            { route: ADMINISTRATOR, title: 'Administrator' },
            {
                route: ADMINISTRATOR_DEFINITION,
                title: 'Definitions',
            },
            {
                route: ADMINISTRATOR_DEFINITION_CAUSE,
                title: 'Causes',
            },
            {
                page: 'detail',
                title: id,
            },
        ],
        [id],
    )

    if (!causeContent) {
        return <Loading></Loading>
    }

    return (
        <PageLayout
            breadcrumbs={BreadComponents}
            pageName={
                <ComponentTooltip
                    componentId={`page_title`}
                    pagePath={ADMINISTRATOR_DEFINITION_CAUSE_EDIT}
                >
                    <div>
                        <PageName name='Edit Causes' />
                    </div>
                </ComponentTooltip>
            }
        >
            <EditCauseForm
                causeContent={causeContent}
                pagePath={ADMINISTRATOR_DEFINITION_CAUSE_NEW}
                setSelectedModelIds={setSelectedModelIds}
            />
            <CausePartCodeSection
                {...{ causeContent }}
                disabled={causeContent.disabled}
                selectedModelIds={selectedModelIds}
            />
        </PageLayout>
    )
}
export default CauseEdit
