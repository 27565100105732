import { TableBody as MuiTableBody, TableCell, TableRow } from '@mui/material'
import PropTypes from 'prop-types'
import DataNotFound from '~/components/DataNotFound'
import { CauseHeadCells } from '~/components/HeaderTable/CauseHeadCells'
import ReferencedEntities from '~/components/ReferencedEntities'
import TableLoading from '~/components/Table/TableLoading'

const TableBody = ({ isLoading, items }) => {
    if (isLoading) {
        return <TableLoading colSpan={CauseHeadCells.length} />
    }

    return (
        <MuiTableBody>
            {items.length > 0 ? (
                items.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.code}>
                            <ReferencedEntities
                                isTableCell={true}
                                dataType={'models-id'}
                                dataIdentifier={row.id}
                                dataDisplay={row.code}
                            />
                            <TableCell align='left'>{row.name}</TableCell>
                            <TableCell align='left'>
                                {row.description}
                            </TableCell>
                            <TableCell align='left'>
                                {row.devices_count}
                            </TableCell>
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={CauseHeadCells.length} />
            )}
        </MuiTableBody>
    )
}

TableBody.propTypes = {
    isLoading: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
}

export default TableBody
