import { approveApi } from '~/api'
import { useMutation } from 'react-query'

export const useApprovalTokenMutation = () => {
    return useMutation(
        'approval_token',
        (approvalToken) => approveApi.approveUser(approvalToken),
        {},
    )
}
