import { FormControlLabel, Radio } from '@mui/material'
import PropTypes from 'prop-types'

const RadioInput = ({ input, onUpdate, label, ...props }) => {
    const change = (event) => {
        if (onUpdate) {
            onUpdate(event.target.value)
        }
        input.onChange(event.target.value)
    }

    return (
        <FormControlLabel
            value={input.value}
            control={<Radio />}
            label={label}
            onChange={change}
            {...props}
        />
    )
}

RadioInput.propTypes = {
    input: PropTypes.object,
    onUpdate: PropTypes.func,
    label: PropTypes.string,
}

export default RadioInput
