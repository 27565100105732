import { useCallback } from 'react'

const useFormValidationCallback = (schema) => {
    return useCallback(
        async (values) => {
            try {
                await schema.validate(values, { abortEarly: false })
            } catch (yupError) {
                const errors = {}

                if (yupError.inner) {
                    if (yupError.inner.length === 0) {
                        errors[yupError.path] = yupError.message
                    } else {
                        yupError.inner.forEach((err) => {
                            if (!errors[err.path]) {
                                errors[err.path] = err.message
                            }
                        })
                    }
                }

                return errors
            }

            return {}
        },
        [schema],
    )
}

export default useFormValidationCallback
