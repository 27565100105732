import PropTypes from 'prop-types'
import { Grid, Typography, Box } from '@mui/material'
import { Field } from 'react-final-form'
import FormInput from '../FormInput/FormInput'
import { useTranslation } from 'react-i18next'

const FormField = ({
    localization,
    name,
    label,
    component,
    disabled,
    isRequired,
    autoFocus,
    NoTitle,
}) => {
    const { t } = useTranslation()

    const title = (
        <Typography>
            {label
                ? t(`${localization}.` + label)
                : t(`${localization}.` + name)}
            {isRequired && (
                <Box
                    component={'span'}
                    sx={{
                        color: (theme) => theme.palette.error.light,
                    }}
                >
                    {' *'}
                </Box>
            )}
        </Typography>
    )

    return (
        <Grid item xs={12}>
            <>{!NoTitle ? title : NoTitle}</>
            <Field
                name={name}
                component={component}
                disabled={disabled}
                autoFocus={autoFocus}
            />
        </Grid>
    )
}

FormField.defaultProps = {
    component: FormInput,
}

FormField.propTypes = {
    localization: PropTypes.string.isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    component: PropTypes.any,
    disabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    autoFocus: PropTypes.bool,
    NoTitle: PropTypes.any,
}

export default FormField
