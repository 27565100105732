export const DeliveryHeadCells = [
    {
        id: 'suppliers.name',
        numeric: false,
        disablePadding: false,
        label: 'supplier',
        orderable: true,
    },
    {
        id: 'route',
        numeric: false,
        disablePadding: false,
        label: 'route',
        orderable: true,
    },
    {
        id: 'dispatch_number',
        numeric: false,
        disablePadding: false,
        label: 'dispatchNumber',
        orderable: true,
    },
    {
        id: 'dispatch_date',
        numeric: false,
        disablePadding: false,
        label: 'dispatchDate',
        orderable: true,
    },
    {
        id: 'to_name_1',
        numeric: false,
        disablePadding: false,
        label: 'toName',
        orderable: true,
    },
    {
        id: 'to_city',
        numeric: false,
        disablePadding: false,
        label: 'toCity',
        orderable: true,
    },
    {
        id: 'to_country',
        numeric: true,
        disablePadding: false,
        label: 'toCountry',
        orderable: true,
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'receivedAPI',
        orderable: true,
    },
]

export const DeliveryDetailHeadCells = [
    {
        id: 'total_number_of_units',
        numeric: false,
        disablePadding: false,
        label: 'orderedQty',
        orderable: true,
    },
    {
        id: 'trc_sku',
        numeric: false,
        disablePadding: false,
        label: 'sku',
        orderable: true,
    },
    {
        id: 'supplier_sku',
        numeric: false,
        disablePadding: false,
        label: 'supplierSku',
        orderable: true,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'description',
        orderable: true,
    },
    {
        id: 'vendor_sku',
        numeric: false,
        disablePadding: false,
        label: 'vendorSku',
        orderable: true,
    },
    {
        id: 'qty',
        numeric: false,
        disablePadding: false,
        label: 'qty',
        orderable: true,
    },
]
