import { deviceApi } from '~/api'
import { useMutation } from 'react-query'

export const useExportFleetMutation = () => {
    return useMutation(
        ['fleet_export'],
        async (payload) => {
            const { data } = await deviceApi.getFleetsExport(payload)
            return data
        },
        {
            onSuccess: (data) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `fleet.csv`)
                document.body.appendChild(link)
                link.click()
            },
            onError: () => {},
            retry: false,
        },
    )
}
