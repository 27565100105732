import { MULTI_LANGUAGES } from '~/constants/constants'
import { getValidator } from '~/utils/helpers'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import { useState } from 'react'
import InputField from '~/components/FinalForm/InputField'
import PropTypes from 'prop-types'

const Tabs = ({ values, pagePath }) => {
    const [tabValue, setTabValue] = useState('en')
    const localization = `pages.administrator.tooltip`

    return (
        <TabContext value={tabValue}>
            <Box
                sx={{
                    borderColor: 'divider',
                }}
            >
                <TabList
                    onChange={(e, newValue) => setTabValue(newValue)}
                    textColor='primary'
                    indicatorColor='primary'
                >
                    {MULTI_LANGUAGES.map((i) => {
                        return (
                            <Tab
                                value={i.locale}
                                label={i.language}
                                key={i.locale}
                            />
                        )
                    })}
                </TabList>
            </Box>
            {MULTI_LANGUAGES.map((i) => {
                return (
                    <TabPanel
                        value={i.locale}
                        sx={{
                            p: 0,
                        }}
                        key={i.locale}
                    >
                        <InputField
                            localization={localization}
                            label='tooltip'
                            name={`tooltip[${i.locale}]`}
                            componentId={`tooltip_form_tooltip_content`}
                            pagePath={pagePath}
                            isRequired={true}
                            placeholder={`Tooltip in ${i.language}`}
                            validate={getValidator(!values.tooltip, 'tooltip')}
                        />
                    </TabPanel>
                )
            })}
        </TabContext>
    )
}

Tabs.propTypes = { values: PropTypes.object, pagePath: PropTypes.string }

export default Tabs
