import styled from '@emotion/styled'
import { Alert, Box, Grid, LinearProgress } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ErrorMessageInput from '~/components/ErrorMessageInput'
import Search from '~/components/Search/Search'
import { StyledPaper } from '~/components/StyledPaper'
import { ISSUE_STATES } from '~/constants/constants'
import DeviceHistory from '~/pages/Diagnostic/DeviceHistory'
import IssueLog from '~/pages/Diagnostic/IssueLog'
import IssueRepairPage from '.'
import { useIssueRepairScanSerialMutation } from '../mutation'
import { useIssueQuery } from '../query'

const Section = styled(Grid, {
    shouldForwardProp: (prop) => prop !== 'show',
})(({ show }) => {
    return {
        ...(!show && {
            display: 'none',
        }),
    }
})

const RepairComponent = () => {
    const queryClient = useQueryClient()
    const { issueId } = useParams()
    const navigate = useNavigate()
    const location = useLocation()

    const [error, setError] = useState(false)
    const [openScan, setOpenScan] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [openScanReceivedPart, setOpenScanReceivedPart] = useState(false)

    const { data: issueData, isSuccess } = useIssueQuery(issueId)

    useEffect(() => {
        if (!openScanReceivedPart) {
            setOpenScan(true)
        }
    }, [openScanReceivedPart])

    const { mutate: scanSerialMutate, isLoading: isScanning } =
        useIssueRepairScanSerialMutation()

    const scanSerial = useCallback(
        (serial) => {
            scanSerialMutate(
                { issueId, serial },
                {
                    onSuccess: (data) => {
                        const searchParams = new URLSearchParams(
                            location.search,
                        )
                        searchParams.set('repair', data?.data?.repair?.id)
                        const newSearchString = searchParams.toString()
                        navigate({
                            pathname: window.location.pathname,
                            search: newSearchString,
                        })

                        setError(false)
                        setOpenScan(false)
                        setOpenScanReceivedPart(true)
                    },
                    onError: (e) => {
                        setError(e.response?.data?.message)
                    },
                },
            )
        },
        [issueId, location.search, navigate, scanSerialMutate],
    )

    useEffect(() => {
        if (isSuccess) {
            const stateIndex = ISSUE_STATES.findIndex(
                (state) => state === issueData?.data?.state,
            )
            if (
                stateIndex >=
                ISSUE_STATES.findIndex((state) => state === 'REPAIRED')
            ) {
                setOpenScan(false)
            }

            const searchParams = new URLSearchParams(location.search)
            if (searchParams.has('serial')) {
                scanSerial(searchParams.get('serial'))
            }

            setIsLoading(false)
        }
    }, [isSuccess, issueData?.data?.state, location.search, scanSerial])

    const handleEnterSearch = (event) => {
        if (event.key === 'Enter') {
            queryClient.removeQueries('part_issue')
            scanSerial(event.target.value)
        }
    }

    if (
        !issueData?.data?.repair &&
        ISSUE_STATES.findIndex((state) => state === issueData?.data?.state) >=
            ISSUE_STATES.findIndex((state) => state === 'WAIT_FOR_IMAGE')
    ) {
        return <Alert severity='info'>No parts needed to repair!</Alert>
    }

    if (isLoading) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        )
    }

    return (
        <>
            {openScan ? (
                <Box>
                    <Search
                        label={'Scan Serial'}
                        autoFocus
                        onKeyPress={handleEnterSearch}
                    />
                    {isScanning && (
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    )}
                    {error && <ErrorMessageInput>{error}</ErrorMessageInput>}
                </Box>
            ) : (
                <>
                    <IssueRepairPage
                        openScanReceivedPart={openScanReceivedPart}
                        setOpenScanReceivedPart={setOpenScanReceivedPart}
                    />

                    <Section item xs={12} show={true} sx={{ margin: '10px 0' }}>
                        <StyledPaper variant='outlined'>
                            <IssueLog />
                        </StyledPaper>
                    </Section>

                    <Section item xs={12} show={true}>
                        <StyledPaper variant='outlined'>
                            <DeviceHistory />
                        </StyledPaper>
                    </Section>
                </>
            )}
        </>
    )
}

export default RepairComponent
