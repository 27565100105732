import axiosClient from './base'

class ApproveApi {
    approveUser = (token) => axiosClient.put(`api/approved/${token}`)

    // updateStatusUser = (id, payload) =>
    //     axiosClient.put(`api/user/${id}/update_status`, payload);
}

export default new ApproveApi()
