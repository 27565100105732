import dispatchApi from '~/api/dispatchApi'
import { useQuery } from 'react-query'

export const useDispatchDetailQuery = (id) => {
    return useQuery(
        ['dispatch_detail', id],
        async () => {
            const { data } = await dispatchApi.getDispatchDetail(id)
            return data.data
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}
