import { useRef, useState, useCallback, useEffect, useMemo } from 'react'
import StyledModal from '~/components/StyledModal'
import { Stack, Typography } from '@mui/material'
import { LoadingButton } from '~/components/Button'
import { useTranslation } from 'react-i18next'

const useVersionChecker = () => {
    const { t } = useTranslation()
    const intervalRef = useRef(null)
    const versionMismatchDetected = useRef(false)
    const [modalOpen, setModalOpen] = useState(false)

    const handleConfirm = () => {
        window.location.reload()
    }

    const handleCancel = (e, reason) => {
        setModalOpen(false)
        intervalRef.current = setTimeout(() => setModalOpen(true), 60000 * 15) // Snooze the popup 15 minutes
    }

    const checkVersion = useCallback(async () => {
        if (versionMismatchDetected.current) {
            return
        }

        try {
            const timestamp = new Date().getTime()
            const response = await fetch(`/build/version.json?t=${timestamp}`)

            if (!response.ok) {
                return
            }
            const data = await response.json()
            const frontend_version = import.meta.env.VITE_COMMIT_HASH
            if (frontend_version && data.version !== frontend_version) {
                versionMismatchDetected.current = true
                clearInterval(intervalRef.current)
                setModalOpen(true)
            }
        } catch (error) {
            console.error('Error checking version:', error)
        }
    }, [])

    useEffect(() => {
        checkVersion()
        intervalRef.current = setInterval(checkVersion, 60000)

        return () => {
            clearInterval(intervalRef.current)
        }
    }, [checkVersion])

    const versionCheckerModal = useMemo(() => {
        return (
            <StyledModal
                onBackdropClick={() => {}}
                open={modalOpen}
                handleClose={handleCancel}
                disableBackdropClick
                stackProps={{
                    sx: {
                        outline: 'none',
                    },
                }}
            >
                <Typography variant='h6' component='h2'>
                    {t('message.newVersionAvailable')}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    {t('message.newVersionBody')}
                </Typography>
                <Stack mt={5} direction='row-reverse' spacing={2}>
                    <LoadingButton
                        label={t('button.confirm')}
                        onClick={handleConfirm}
                    />
                    <LoadingButton
                        bgColor={'gray'}
                        label={t('button.snooze')}
                        onClick={handleCancel}
                    />
                </Stack>
            </StyledModal>
        )
    }, [modalOpen, t])

    return {
        versionCheckerModal,
    }
}

export default useVersionChecker
