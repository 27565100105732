import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
    Box,
    FormControl,
    Grid,
    Paper,
    Stack,
    Tab,
    Toolbar,
    Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { LoadingButton } from '~/components/Button'
import CancelButton from '~/components/CancelButton'
import FormTextArea from '~/components/FinalForm/FormTextArea'
import InputField from '~/components/FinalForm/InputField'
import MultiSelectAutoCompleteField from '~/components/FinalForm/MultiSelectAutoCompleteField'
import ToggleField from '~/components/FinalForm/ToggleField'
import SubmitButton from '~/components/SubmitButton'
import { MULTI_LANGUAGES } from '~/constants/constants'
import { selectUserPermissions } from '~/store/auth/selector'
import { getValidator } from '~/utils/helpers'
import useDeviceModel from './hooks/useDeviceModel'
import { useChangeDisableStateCauseMutation } from './mutation'

const localization = 'pages.administrator.definition.causes.overview'

const CauseForm = ({
    validate,
    onSubmit,
    isLoading,
    name,
    initialValues,
    pagePath,
    isCauseDisabled,
    causeId,
    setSelectedModelIds,
    isUpdatePage = false,
}) => {
    const locale = localStorage.getItem('LOCALE')
    const [value, setValue] = useState(locale)
    const { deviceModelData } = useDeviceModel()
    const perms = useSelector(selectUserPermissions)

    const canEnableOrDisable = useMemo(
        () =>
            perms.some(
                (perm) =>
                    perm === 'super_admin' ||
                    perm === 'administrator.definitions.causes.disable',
            ) && isUpdatePage,
        [perms, isUpdatePage],
    )
    const models = useMemo(() => {
        const models = {}
        deviceModelData.forEach((model) => {
            models[model.value] = model.name
        })
        return models
    }, [deviceModelData])
    const { mutate: changeState, isLoading: isChanging } =
        useChangeDisableStateCauseMutation()

    const selectModels = (value) => {
        const idxZero = value.findIndex((e) => e === 0)
        if (idxZero === 0 && value.length > 1) {
            value.shift()
        } else if (idxZero !== -1) {
            value = [0]
        }
        if (value.length === 1 && value[0] === 0) {
            setSelectedModelIds([])
        } else {
            setSelectedModelIds(value)
        }
        return value
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    return (
        <Form
            initialValues={initialValues}
            validate={validate}
            onSubmit={onSubmit}
            render={({ handleSubmit, values, dirty }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Toolbar disableGutters={false}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            >
                                <Box>
                                    {isUpdatePage ? (
                                        <Typography variant='h6'>
                                            This cause is{' '}
                                            {isCauseDisabled ? (
                                                <span style={{ color: 'red' }}>
                                                    DISABLED
                                                </span>
                                            ) : (
                                                <span
                                                    style={{ color: 'green' }}
                                                >
                                                    ENABLED
                                                </span>
                                            )}
                                        </Typography>
                                    ) : (
                                        <></>
                                    )}
                                </Box>
                                <Stack direction='row-reverse' spacing={2}>
                                    <SubmitButton
                                        disabled={
                                            !dirty &&
                                            typeof initialValues === 'undefined'
                                        }
                                        isLoading={isLoading || isChanging}
                                        handleSubmit={handleSubmit}
                                        name={name}
                                    />
                                    {canEnableOrDisable ? (
                                        <LoadingButton
                                            label={
                                                isCauseDisabled
                                                    ? 'Enable'
                                                    : 'Disable'
                                            }
                                            loading={isChanging}
                                            onClick={() =>
                                                changeState({
                                                    id: causeId,
                                                    isDisabled: isCauseDisabled,
                                                })
                                            }
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    <CancelButton />
                                </Stack>
                            </Box>
                        </Toolbar>
                        <Paper
                            sx={{
                                width: '100%',
                                mb: 2,
                                display: 'flex',
                                alignItems: 'left',
                                justifyContent: 'left',
                            }}
                        >
                            <Box
                                bgcolor={'background.default'}
                                color={'text.primary'}
                                p={3}
                                borderRadius={5}
                                width={'100%'}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Stack direction='column' spacing={2}>
                                            <InputField
                                                localization={localization}
                                                name='tid'
                                                componentId={`cause_form_tid`}
                                                pagePath={pagePath}
                                                isRequired
                                                disabled={isCauseDisabled}
                                            />
                                            <FormTextArea
                                                localization={localization}
                                                name={`notes`}
                                                label={`notes`}
                                                isRequired={false}
                                                multiple
                                                minRow={3}
                                                componentId={`cause_form_notes`}
                                                pagePath={pagePath}
                                                disabled={isCauseDisabled}
                                            />

                                            <FormControl variant='outlined'>
                                                <TabContext value={value}>
                                                    <Box
                                                        sx={{
                                                            borderColor:
                                                                'divider',
                                                        }}
                                                    >
                                                        <TabList
                                                            onChange={
                                                                handleChange
                                                            }
                                                            textColor='primary'
                                                            indicatorColor='primary'
                                                        >
                                                            {MULTI_LANGUAGES.map(
                                                                (i) => {
                                                                    return (
                                                                        <Tab
                                                                            key={
                                                                                i.locale
                                                                            }
                                                                            value={
                                                                                i.locale
                                                                            }
                                                                            label={
                                                                                i.language
                                                                            }
                                                                        />
                                                                    )
                                                                },
                                                            )}
                                                        </TabList>
                                                    </Box>
                                                    {MULTI_LANGUAGES.map(
                                                        (i) => {
                                                            return (
                                                                <TabPanel
                                                                    key={
                                                                        i.locale
                                                                    }
                                                                    value={
                                                                        i.locale
                                                                    }
                                                                    sx={{
                                                                        p: 0,
                                                                    }}
                                                                >
                                                                    <InputField
                                                                        localization={
                                                                            localization
                                                                        }
                                                                        label='name'
                                                                        name={`name[${i.locale}]`}
                                                                        componentId={`cause_form_name`}
                                                                        pagePath={
                                                                            pagePath
                                                                        }
                                                                        isRequired={
                                                                            true
                                                                        }
                                                                        placeholder={`Name in ${i.language}`}
                                                                        validate={getValidator(
                                                                            !values.name,
                                                                            'name',
                                                                        )}
                                                                        disabled={
                                                                            isCauseDisabled
                                                                        }
                                                                    />
                                                                </TabPanel>
                                                            )
                                                        },
                                                    )}
                                                </TabContext>
                                            </FormControl>
                                            <Stack
                                                direction='row'
                                                gap={2}
                                                alignItems='center'
                                            >
                                                <Typography>
                                                    For all model?
                                                </Typography>
                                                <Field
                                                    id='for_all_models'
                                                    name='for_all_models'
                                                    component={ToggleField}
                                                    disabled={isCauseDisabled}
                                                ></Field>
                                            </Stack>
                                            <Field
                                                name='model_ids'
                                                component={
                                                    MultiSelectAutoCompleteField
                                                }
                                                items={deviceModelData}
                                                localization={localization}
                                                componentId='cause_form_model'
                                                pagePath={pagePath}
                                                nameSelect='model'
                                                isRequired={false}
                                                dictItems={models}
                                                onUpdate={selectModels}
                                                disabled={
                                                    values.for_all_models ||
                                                    isCauseDisabled
                                                }
                                                limitTags={
                                                    values.for_all_models
                                                        ? 0
                                                        : -1
                                                }
                                                getLimitTagsText={() =>
                                                    'All Models'
                                                }
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </form>
                )
            }}
        />
    )
}

CauseForm.propTypes = {
    validate: PropTypes.any,
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    name: PropTypes.string,
    initialValues: PropTypes.any,
    pagePath: PropTypes.string,
    editProcess: PropTypes.bool,
    isCauseDisabled: PropTypes.bool,
    causeId: PropTypes.any,
    isUpdatePage: PropTypes.bool,
    setSelectedModelIds: PropTypes.func,
}

CauseForm.defaultProps = {
    setSelectedModelIds: () => {},
}

export default CauseForm
