import axiosClient from '~/api/base'

class AddonsApi {
    getAddons = (params) => {
        return axiosClient.get('api/production/addons', { params })
    }

    getAddon = (id) => {
        return axiosClient.get(`api/production/addons/${id}`)
    }

    getUsers = (params) => {
        return axiosClient.get('api/production/addons-users', { params })
    }

    importAddon = (payload) => {
        return axiosClient.post('api/production/add-addons', {
            name: payload.name,
            version: payload.version,
            filename: payload.filename,
        })
    }

    editAddon = (id, payload) => {
        return axiosClient.post(`api/production/edit-addon/${id}`, {
            name: payload.name,
            version: payload.version,
        })
    }

    editParam = (id, old_key, key, params) => {
        return axiosClient.post(`api/production/edit-param/${id}`, {
            old_key,
            key,
            params,
        })
    }

    addNewParam = (id, key, payload) => {
        return axiosClient.post(`api/production/add-new-params/${id}`, {
            key,
            params: payload,
        })
    }
}

export default new AddonsApi()
