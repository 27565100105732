import { createSlice } from '@reduxjs/toolkit'

export const partcodeSlice = createSlice({
    name: 'component',
    initialState: {
        partcodeState: null,
        currentCausePartCode: [],
        selectedPartCodes: [],
        selectedPartCodeTypes: [],
        showedPartCode: [],
        /// //////////
        selectedPartCodesForDiagnostic: [],
        showedPartCodeForDiagnostic: [],
        selectedPartCodeTypesForDiagnostic: [],
        componentPartCodeSelected: {},
    },
    reducers: {
        setPartCodeState: (state, action) => {
            state.partcodeState = action.payload
        },
        setCurrentCausePartCode: (state, action) => {
            state.currentCausePartCode = action.payload
        },
        pushSelectedPartCodes: (state, action) => {
            const idx = state.selectedPartCodes.findIndex(
                ({ code }) => code === action.payload.code,
            )
            if (idx !== -1) {
                state.selectedPartCodes[idx] = { ...action.payload }
            } else {
                state.selectedPartCodes.push(action.payload)
            }
        },
        unsetSelectedPartCodes: (state, action) => {
            state.selectedPartCodes = state.selectedPartCodes.filter((type) => {
                return type.code !== action.payload
            })
        },
        pushSelectedPartCodeTypes: (state, action) => {
            if (state.selectedPartCodeTypes.indexOf(action.payload) === -1) {
                state.selectedPartCodeTypes.push(action.payload)
            }
        },
        unsetSelectedPartCodeTypes: (state, action) => {
            state.selectedPartCodeTypes = state.selectedPartCodeTypes.filter(
                (type) => {
                    return type !== action.payload
                },
            )
        },
        pushShowPartCodes: (state, action) => {
            state.showedPartCode = action.payload
        },

        clearSelectedPartCodes: (state) => {
            state.selectedPartCodes = []
        },

        clearSelectedPartCodeTypes: (state) => {
            state.selectedPartCodeTypes = []
        },

        clearShowedPartCode: (state) => {
            state.showedPartCode = []
        },

        pushShowPartCodesForDiagnostic: (state, action) => {
            state.showedPartCodeForDiagnostic = action.payload
        },

        pushSelectedPartCodeTypesForDiagnostic: (state, action) => {
            if (
                state.selectedPartCodeTypesForDiagnostic.indexOf(
                    action.payload,
                ) === -1
            ) {
                state.selectedPartCodeTypesForDiagnostic.push(action.payload)
            }
        },

        unsetSelectedPartCodeTypesForDiagnostic: (state, action) => {
            state.selectedPartCodeTypesForDiagnostic =
                state.selectedPartCodeTypesForDiagnostic.filter((type) => {
                    return type !== action.payload
                })
        },
        unsetSelectedPartCodesForDispatch: (state, action) => {
            state.showedPartCodeForDiagnostic =
                state.showedPartCodeForDiagnostic.filter((type) => {
                    return type.code !== action.payload
                })
        },
        clearSelectedPartCodeTypesForDiagnostic: (state) => {
            state.selectedPartCodeTypesForDiagnostic = []
        },

        clearShowedPartCodeForDiagnostic: (state) => {
            state.showedPartCodeForDiagnostic = []
        },

        partCodeSelected: (state, action) => {
            state.componentPartCodeSelected[action.payload.part_number] =
                action.payload.code
        },

        clearPartCodeSelected: (state) => {
            state.componentPartCodeSelected = {}
        },
    },
})

export const {
    setPartCodeState,
    setCurrentCausePartCode,
    pushSelectedPartCodes,
    unsetSelectedPartCodes,
    pushSelectedPartCodeTypes,
    unsetSelectedPartCodeTypes,
    pushShowPartCodes,
    clearSelectedPartCodes,
    clearSelectedPartCodeTypes,
    clearShowedPartCode,
    pushShowPartCodesForDiagnostic,
    pushSelectedPartCodeTypesForDiagnostic,
    unsetSelectedPartCodeTypesForDiagnostic,
    clearSelectedPartCodeTypesForDiagnostic,
    clearShowedPartCodeForDiagnostic,
    partCodeSelected,
    clearPartCodeSelected,
} = partcodeSlice.actions

export default partcodeSlice
