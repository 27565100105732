import { InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import PropTypes from 'prop-types'

const Search = ({ placeholder, ...props }) => {
    return (
        <TextField
            variant='outlined'
            placeholder={placeholder}
            InputProps={{
                'aria-label': { placeholder },
                startAdornment: (
                    <InputAdornment position='start'>
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            {...props}
        />
    )
}

Search.propTypes = {
    placeholder: PropTypes.string,
    props: PropTypes.any,
}

export default Search
