import serviceAccountApi from '~/api/serviceAccountApi'
import { useQuery } from 'react-query'
import roleApi from '~/api/acl/roleApi'

export const useGetDetailQuery = (params) => {
    return useQuery(
        ['service_account_detail', params],
        async () => {
            const { data } = await serviceAccountApi.getDetail(params.id)
            return { data: data.data }
        },
        {
            onSuccess: (data) => {
                if (
                    !params.tokenValue &&
                    data.data.is_token_existed &&
                    !data.data.revoked
                ) {
                    params.setTokenValue('*******')
                }
                return data
            },
        },
    )
}

export const useGetAllRolesQuery = (guardName) => {
    return useQuery(
        ['service_account_detail_roles', guardName],
        async () => {
            const { data } = await roleApi.getRolesByGuard(guardName)
            return data.data
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}
