import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import ListToolbarWrapper from '~/components/ListToolbarWrapper'
import PageName from '~/components/PageName'
import {
    ISSUE_DISPATCH,
    ISSUE_DISPATCH_COMPONENT,
    ISSUE_ISSUE,
} from '~/constants/Routes'
import DispatchComponentsTable from './DispatchComponentsTable'

const BreadComponents = [
    { route: ISSUE_ISSUE, title: 'Issues' },
    {
        route: ISSUE_DISPATCH,
        title: 'Dispatches',
    },
    {
        route: '#',
        title: 'Components',
    },
]
const DispatchComponent = () => {
    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ISSUE_DISPATCH_COMPONENT}
            >
                <div>
                    <PageName name={'Components'} />
                </div>
            </ComponentTooltip>
            <ListToolbarWrapper />
            <DispatchComponentsTable />
        </>
    )
}
export default DispatchComponent
