import {
    ADMINISTRATOR,
    ADMINISTRATOR_SYSTEM,
    ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT,
    ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_CREATE,
} from '~/constants/Routes'
import { useUpdateMutation } from './mutate'
import { Form } from 'react-final-form'
import Body from './Body'
import Toolbar from './Toolbar'
import BreadCrumb from '~/components/BreadCrumb'
import { object, string } from 'yup'
import { multipleIp, phone, regularEmail } from '~/constants/yupSchemas'
import useFormValidationCallback from '../../../hooks/useFormValidationCallback'
import { useTranslation } from 'react-i18next'
import PageName from '../../../components/PageName'
import { useGetAllRolesQuery } from '../ServiceAccountDetail/query'
import { SERVICE_ACCOUNT_GUARD_NAME } from '~/constants/constants'
import { useMemo } from 'react'
import ComponentTooltip from '~/components/ComponentTooltip'
import PropTypes from 'prop-types'

const ServiceAccountCreate = () => {
    const localization = 'pages.administrator.serviceAccountDetail'
    const { mutate: updateClient, isLoading } = useUpdateMutation({})
    const { t } = useTranslation()
    const schema = object().shape({
        name: string().required(),
        contact_email: regularEmail(t),
        contact: phone(t),
        whitelist: multipleIp(t),
    })
    const validate = useFormValidationCallback(schema)
    const breadCrumbConfig = [
        { title: `Administrator`, route: ADMINISTRATOR },
        { title: `System`, route: ADMINISTRATOR_SYSTEM },
        {
            title: `ServiceAccounts`,
            route: ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT,
        },
        { title: `Create`, route: '' },
    ]
    const { data: roles } = useGetAllRolesQuery(SERVICE_ACCOUNT_GUARD_NAME)
    const rolesList = useMemo(() => {
        if (roles) {
            return roles.map((role) => ({
                name: role.name,
                value: role.id,
            }))
        }
        return []
    }, [roles])
    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <ComponentTooltip
                componentId={`service_account_create_title`}
                pagePath={ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_CREATE}
            >
                <div>
                    <PageName name={'Create Service Accounts'} />
                </div>
            </ComponentTooltip>
            <Form
                validate={validate}
                onSubmit={updateClient}
                initialValues={{ role: rolesList[0]?.value }}
                render={(props) => (
                    <form onSubmit={props.handleSubmit}>
                        <Toolbar
                            localization={localization}
                            isLoading={isLoading}
                        />
                        <Body
                            localization={localization}
                            rolesList={rolesList}
                        />
                    </form>
                )}
            />
        </>
    )
}

ServiceAccountCreate.propTypes = { handleSubmit: PropTypes.func }

export default ServiceAccountCreate
