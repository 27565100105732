import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import DataNotFound from '~/components/DataNotFound'
import TableLoading from '~/components/Table/TableLoading'
import SectionTitle from './components/SectionTitle'
import StyledTableCell from './components/StyledTableCell'
import StyledTableRow from './components/StyledTableRow'
import { LOCALIZATION } from './constants'
import useStyles from './hooks/useStyles'
import { useDeviceHistoriesViaSerialQuery } from './query'
import useTable from '~/hooks/useTable'
import PaginationTable from '~/components/PaginationTable'
import DeviceHistoryIssue from '~/pages/Diagnostic/components/DeviceHistoryIssue'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const DeviceHistory = () => {
    const { t } = useTranslation()
    return (
        <Grid container>
            <SectionTitle
                title={t(LOCALIZATION + `deviceHistory`)}
                xs={12}
                md={12}
                lg={12}
                align='center'
            />
            <DeviceHistoryTable />
        </Grid>
    )
}

const DeviceHistoryTable = () => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    const { issueId } = useParams()
    const { results, data, isLoading, page, changePageHandler } = useTable({
        query: useDeviceHistoriesViaSerialQuery,
        queryAttributes: {
            id: issueId,
        },
    })

    const headerTable = useMemo(
        () => ['date', 'issueNo', 'problem', 'name'],
        [],
    )

    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
                <TableHead>
                    <TableRow>
                        {headerTable.map((item) => (
                            <StyledTableCell align='center' key={item}>
                                {t(LOCALIZATION + item)}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading ? (
                        <TableLoading />
                    ) : results?.length > 0 ? (
                        results.map((item) => (
                            <StyledTableRow key={item.id}>
                                <TableCell className={classes.tableCell}>
                                    <DateTimeLocale
                                        datetime={item.created_at}
                                    />
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <DeviceHistoryIssue {...{ item }} />
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {item.description}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {item.user?.name || 'N/A'}
                                </TableCell>
                            </StyledTableRow>
                        ))
                    ) : (
                        <DataNotFound colSpan={headerTable.length} />
                    )}
                </TableBody>
            </Table>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </TableContainer>
    )
}

export default DeviceHistory
