import { Box, Button, CircularProgress, Stack, Tooltip } from '@mui/material'
import LabelStatus from '~/components/LabelStatus'
import { useMemo } from 'react'
import { useUpdateStateTicketMutation } from '../mutation'
import PropTypes from 'prop-types'

const LabelStateTicket = ({ row }) => {
    const { mutate: updateStateTicket, isLoading: LoadingHandleChangeState } =
        useUpdateStateTicketMutation()

    const handleChangeTicketState = ({ id, payload }) => {
        updateStateTicket({
            id,
            payload,
        })
    }

    const color = useMemo(() => {
        switch (row.state) {
            case 'OPEN':
                return 'success'
            case 'PENDING':
                return 'warning'
            case 'CLOSED':
                return 'secondary'
            case 'ERROR':
                return 'error'
            default:
                return 'warning'
        }
    }, [row.state])

    const showState = useMemo(() => {
        if (row.state_message) {
            return (
                <Tooltip title={row.state_message} placement='top'>
                    <Button color={color} size='small'>
                        {row.state.replace('_', ' ')}
                    </Button>
                </Tooltip>
            )
        }
        return (
            <Button color={color} size='small'>
                {row.state.replace(/_/g, ' ')}
            </Button>
        )
    }, [color, row.state, row.state_message])

    const isActionRequired = row.state === 'ACTION_REQUIRED'
    return (
        <Stack direction='row'>
            <LabelStatus variant='ghost' color={color}>
                {isActionRequired ? (
                    <Tooltip
                        title={
                            <span style={{ whiteSpace: 'pre-wrap' }}>
                                {row.actionRequiredMessage
                                    .split('\\n')
                                    .join('\n')}
                            </span>
                        }
                        placement='top'
                    >
                        {row.state.replace(/_/g, ' ')}
                    </Tooltip>
                ) : (
                    showState
                )}
            </LabelStatus>
            {isActionRequired &&
                (LoadingHandleChangeState ? (
                    <CircularProgress size={25} />
                ) : (
                    <Tooltip title='Change to CLOSED' placement='top'>
                        <Box
                            component='span'
                            className='material-symbols-rounded'
                            onClick={() =>
                                handleChangeTicketState({
                                    id: row.id,
                                    payload: row.state,
                                })
                            }
                            sx={{ cursor: 'pointer' }}
                        >
                            autorenew
                        </Box>
                    </Tooltip>
                ))}
        </Stack>
    )
}

LabelStateTicket.propTypes = { row: PropTypes.object }

export default LabelStateTicket
