import { useMutation, useQueryClient } from 'react-query'
import regionApi from '~/api/regionApi'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setSnackbar } from '~/store/snackbar/slice'
import { ADMINISTRATOR_DEFINITION_REGIONS_DETAIL } from '~/constants/Routes'

const useHooks = () => {
    const { id } = useParams()
    const clientQuery = useQueryClient()
    const navigate = useNavigate()
    return {
        id,
        clientQuery,
        navigate,
    }
}

export const useCreateRegionMutation = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return useMutation(
        ['region_create'],
        async (payload) => {
            return await regionApi.createRegion(payload)
        },
        {
            onSuccess: (data) => {
                dispatch(
                    setSnackbar({
                        message: data.message
                            ? data.message
                            : 'Create successful!',
                        type: 'success',
                    }),
                )

                navigate(
                    ADMINISTRATOR_DEFINITION_REGIONS_DETAIL.replace(
                        ':id',
                        data.data.data.id,
                    ),
                )
                return data.data?.data
            },
            onError: (error) => {
                const message = error.response.data.message
                    ? error.response.data.message
                    : error.message
                      ? error.message
                      : 'Create failed!'
                dispatch(
                    setSnackbar({
                        message,
                        type: 'error',
                    }),
                )
            },
            retry: false,
        },
    )
}
export const useUpdateRegionMutation = ({ showSnackbar }) => {
    const { id, navigate } = useHooks()
    const dispatch = useDispatch()

    return useMutation(
        ['region_update'],
        async (payload) => {
            return await regionApi.updateRegion(id, payload)
        },
        {
            onSuccess: (data) => {
                dispatch(
                    setSnackbar({
                        message: data.message
                            ? data.message
                            : 'Update successful!',
                        type: 'success',
                    }),
                )
                navigate(
                    ADMINISTRATOR_DEFINITION_REGIONS_DETAIL.replace(':id', id),
                )
                return data
            },
            onError: (error) =>
                showSnackbar(
                    error.message ? error.message : 'Save failed!',
                    'error',
                ),
            retry: false,
        },
    )
}
