import PropTypes from 'prop-types'
import { Paper, Table, TableBody, TableContainer } from '@mui/material'
import TableHeader from './TableHeader'

const PartCodeTableComponent = ({
    children,
    partCodeTableHeader,
    localization,
}) => {
    return (
        <Paper>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                    <TableHeader
                        headCells={partCodeTableHeader}
                        localization={localization}
                    />
                    <TableBody>{children}</TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

PartCodeTableComponent.propTypes = {
    children: PropTypes.node,
    partCodeTableHeader: PropTypes.array,
    localization: PropTypes.string,
}

export default PartCodeTableComponent
