import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import { DispatchHistoryHeadCells } from '~/components/HeaderTable/DispatchHistoryHeadCells'
import PaginationTable from '~/components/PaginationTable'
import FilterSearch from '~/components/Search/FilterSearch'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import { useDispatchHistoryQuery } from './query'
import PropTypes from 'prop-types'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const localization = 'pages.dispatches.dispatch.detail.'
const DispatchHistoryTableBody = ({ isLoading, dispatches }) => {
    if (isLoading) {
        return <TableLoading colSpan={DispatchHistoryHeadCells.length} />
    }
    return (
        <TableBody>
            {dispatches.length >= 1 ? (
                dispatches?.map((row) => (
                    <TableRow
                        key={row.id}
                        sx={{
                            '&:last-child td, &:last-child th': {
                                border: 0,
                            },
                        }}
                    >
                        <TableCell>
                            <DateTimeLocale datetime={row.created_at} />
                        </TableCell>
                        <TableCell>{row.status}</TableCell>
                        <TableCell>{row.waybill}</TableCell>
                        <TableCell>{row.dps_number}</TableCell>
                        <TableCell>{row.updated_by?.name}</TableCell>
                    </TableRow>
                ))
            ) : (
                <DataNotFound colSpan={DispatchHistoryHeadCells.length} />
            )}
        </TableBody>
    )
}

DispatchHistoryTableBody.propTypes = {
    isLoading: PropTypes.bool,
    dispatches: PropTypes.array,
}

const DispatchHistoryTable = ({ dispatchContent }) => {
    const id = dispatchContent?.id

    const {
        results,
        data,
        isLoading,
        page,
        order,
        orderBy,
        sortHandler,
        searchHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useDispatchHistoryQuery,
        initialStates: {
            orderBy: '',
        },
        queryAttributes: {
            id,
        },
    })

    return (
        <>
            <FilterSearch
                placeholder='Search'
                onSearchChange={searchHandler}
                onEnterSearch={onEnterSearch}
            />
            <Paper>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                        <TableHeader
                            headCells={DispatchHistoryHeadCells}
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                            localization={localization}
                        />
                        <DispatchHistoryTableBody
                            isLoading={isLoading}
                            dispatches={results}
                        />
                    </Table>
                </TableContainer>
                <PaginationTable
                    data={data}
                    page={page}
                    size='small'
                    handleChangePage={changePageHandler}
                />
            </Paper>
        </>
    )
}

DispatchHistoryTable.propTypes = { dispatchContent: PropTypes.object }

export default DispatchHistoryTable
