import { Box, Stack, Toolbar as MuiToolbar } from '@mui/material'
import Proptypes from 'prop-types'

const FormToolbar = ({ children }) => {
    return (
        <>
            <MuiToolbar disableGutters={false}>
                <Box sx={{ flexGrow: 1 }}></Box>

                <Stack
                    direction='row'
                    justifyContent='right'
                    alignItems='right'
                    minWidth='46px'
                    spacing={2}
                >
                    {children}
                </Stack>
            </MuiToolbar>
        </>
    )
}

FormToolbar.propTypes = {
    children: Proptypes.any,
}

export default FormToolbar
