import PropTypes from 'prop-types'
import { useMemo } from 'react'
import TableFilters from '~/components/TableFilters'
import { useServiceCenterQuery } from './AddDispatch/query'
import {
    useBranchQuery,
    useDispatchStateQuery,
    useDispatchStatusQuery,
    useDispatchUserQuery,
} from './query'

const DispatchesTableFilters = ({
    search,
    searchHandler,
    branch,
    setBranch,
    status,
    setStatus,
    user,
    setUser,
    state,
    setState,
    serviceCenter,
    setServiceCenter,
    onEnterSearch,
    setCreatedManually,
    createdManually,
}) => {
    const { data: branchData, isSuccess: isSuccessBranch } = useBranchQuery({
        menuOption: true,
    })
    const { data: statusData, isSuccess: isSuccessStatus } =
        useDispatchStatusQuery()
    const { data: userData, isSuccess: isSuccessUser } = useDispatchUserQuery()
    const { data: serviceCenterData, isSuccess: isSuccessSC } =
        useServiceCenterQuery()

    const { data: stateData, isSuccess: isSuccessState } =
        useDispatchStateQuery()

    const branches = useMemo(() => {
        if (isSuccessBranch) {
            const result = branchData?.data?.map((item) => ({
                id: item.id,
                name: item.name,
            }))
            result.unshift({ id: '', name: 'All' })
            return result
        }
        return []
    }, [branchData?.data, isSuccessBranch])

    const statuses = useMemo(() => {
        if (isSuccessStatus) {
            const result = statusData?.data.map((x) => {
                return {
                    id: x.status,
                    name: x.status,
                }
            })
            result.unshift({ id: '', name: 'All' })
            return result
        }
        return []
    }, [isSuccessStatus, statusData?.data])

    const users = useMemo(() => {
        if (isSuccessUser) {
            const result = userData?.data?.map((item) => ({
                id: item.id,
                name: item.name,
            }))
            result.unshift({ id: '', name: 'All' })
            return result
        }
        return []
    }, [userData?.data, isSuccessUser])

    const serviceCenters = useMemo(() => {
        if (isSuccessSC) {
            const result = serviceCenterData?.map((item) => ({
                id: item.id,
                name: item.name,
            }))
            result.unshift({ id: '', name: 'All' })
            return result
        }
        return []
    }, [serviceCenterData, isSuccessSC])

    const states = useMemo(() => {
        if (isSuccessState) {
            const result = stateData?.data?.map((s) => ({
                id: s.state,
                name: s.state,
            }))
            result.unshift({ id: '', name: 'All' })
            return result
        }
        return []
    }, [isSuccessState, stateData?.data])

    const selectGroup = useMemo(() => {
        const dataCreatedManually = [
            { id: 'All', name: 'All' },
            { id: '1', name: 'Yes' },
            { id: '0', name: 'No' },
        ]
        return [
            {
                label: 'Status',
                handleFunc: setStatus,
                dataSet: statuses,
                value: status,
            },
            {
                label: 'Branch',
                handleFunc: setBranch,
                dataSet: branches,
                value: branch,
            },
            {
                label: 'User',
                handleFunc: setUser,
                dataSet: users,
                value: user,
            },
            {
                label: 'Service Center',
                handleFunc: setServiceCenter,
                dataSet: serviceCenters,
                value: serviceCenter,
            },
            {
                label: 'State',
                handleFunc: setState,
                dataSet: states,
                value: state,
            },
            {
                label: 'Created Manually',
                handleFunc: setCreatedManually,
                dataSet: dataCreatedManually,
                value: createdManually,
            },
        ]
    }, [
        setStatus,
        statuses,
        status,
        setBranch,
        branches,
        branch,
        setUser,
        users,
        user,
        setServiceCenter,
        serviceCenters,
        serviceCenter,
        setState,
        states,
        state,
        setCreatedManually,
        createdManually,
    ])
    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='dispatches'
            page='dispatchess'
        />
    )
}

DispatchesTableFilters.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    branch: PropTypes.string,
    setBranch: PropTypes.func,
    status: PropTypes.string,
    setStatus: PropTypes.func,
    user: PropTypes.string,
    setUser: PropTypes.func,
    state: PropTypes.string,
    setState: PropTypes.func,
    serviceCenter: PropTypes.string,
    setServiceCenter: PropTypes.func,
    onEnterSearch: PropTypes.func,
    setCreatedManually: PropTypes.func,
    createdManually: PropTypes.string,
}

export default DispatchesTableFilters
