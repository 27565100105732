import axiosClient from '~/api/base'
import { DEFAULT_LANGUAGE } from '~/constants/constants'

class CauseApi {
    getCauses = (params) => {
        return axiosClient.get('api/definition/causes', {
            params,
        })
    }

    createCause = (payload) => {
        const p = localStorage.getItem('LOCALE') ?? DEFAULT_LANGUAGE
        return axiosClient.post(`api/definition/causes?locale=${p}`, payload)
    }

    getCauseDetail = (id) => axiosClient.get(`api/definition/causes/${id}`)

    updateCause = (id, { payload }) => {
        const locale = localStorage.getItem('LOCALE')

        return axiosClient.put(
            `api/definition/causes/${id}?locale=${locale}`,
            payload,
        )
    }

    attachPartCodeToCause = (id, payload) => {
        return axiosClient.post(`api/causes/${id}/attach-part-code`, payload)
    }

    detachPartCodeFromCause = (id, payload) => {
        return axiosClient.post(`api/causes/${id}/detach-part-code`, payload)
    }

    getDeviceCause = (params) => {
        const locale = params.locale || 'en'
        return axiosClient.get(`api/device-serial/${params.serial}/causes`, {
            params: { locale },
        })
    }

    disableCause = (id) => {
        return axiosClient.post(`api/definition/causes/${id}/disable`)
    }

    enableCause = (id) => {
        return axiosClient.delete(`api/definition/causes/${id}/disable`)
    }

    getModels = () => axiosClient.get('/api/cause/models')
}
export default new CauseApi()
