import axiosClient, { axiosFormClient, clientDownload } from '~/api/base'

class PartcodeApi {
    getPartCodeList = (params) => {
        return axiosClient.get('api/partcodes', {
            params,
        })
    }

    getAllPartCodes = (params) =>
        axiosClient.get('api/partcodes/all', { params })

    getAllPartCodeType = (params) =>
        axiosClient.get('api/partcodes/all-types', { params })

    getModelPartCodeType = (params) => {
        if (!params.model) return
        const modelId = params.model
        return axiosClient.get(`api/models/${modelId}/part-codes-types`, {
            params,
        })
    }

    getModelPartCode = (params) => {
        if (!params.model) return
        const modelId = params.model
        return axiosClient.get(`api/models/${modelId}/part-codes`, {
            params,
        })
    }

    getCausePartCode = (params) => {
        if (!params.cause) return
        const causeId = params.cause
        return axiosClient.get(
            `api/causes/${causeId}/part-codes`,
            params.page
                ? {
                      params,
                  }
                : null,
        )
    }

    getTypes = (params) => {
        return axiosClient.get('api/partcodes/type', {
            params,
        })
    }

    getPartCodesExport = (payload) =>
        clientDownload.post('api/partcodes/export-partcode', payload)

    getPartCodesImport = (payload) =>
        axiosFormClient.post('api/partcodes/import-partcode', payload)

    getSerialPartCodeType = (params) =>
        axiosClient.get(`api/serials/${params.serial}/part-codes-types`, {
            params,
        })

    getSerialPartCode = (params) =>
        axiosClient.get(`api/serials/${params.serial}/part-codes`, { params })

    getPartCodeWithVotes = (params) => {
        return axiosClient.get(`api/partcodes/get-partcode-with-votes`, {
            params,
        })
    }
}

export default new PartcodeApi()
