import { Grid, TextareaAutosize } from '@mui/material'
import SectionTitle from './components/SectionTitle'
import { useTranslation } from 'react-i18next'
import { LOCALIZATION } from './constants'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

const TechnicianComment = ({ finishDiagnostic }) => {
    const { t } = useTranslation()
    return (
        <Grid container>
            <SectionTitle
                title={t(LOCALIZATION + `technicianComments`)}
                xs={12}
                md={12}
                lg={12}
                align='left'
                ml={2}
            />
            <Grid item xs={12} md={12} lg={12} align='left' m={2}>
                <Field
                    name='comments'
                    component={Textarea}
                    disabled={finishDiagnostic}
                />
            </Grid>
        </Grid>
    )
}

TechnicianComment.propTypes = { finishDiagnostic: PropTypes.bool }

const Textarea = ({ input, ...props }) => {
    return (
        <TextareaAutosize
            {...input}
            {...props}
            minRows={5}
            maxRows={5}
            style={{ width: '100%' }}
        />
    )
}

Textarea.propTypes = { input: PropTypes.any }

export default TechnicianComment
