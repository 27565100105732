import { userApi } from '~/api'
import localeApi from '~/api/localeApi'
import { useQuery } from 'react-query'

export const useUserDetailQuery = (id) => {
    return useQuery(
        ['users', id],
        async () => {
            const { data } = await userApi.getUserProfile(id)
            return data.data
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useActiveLocaleListQuery = (params) => {
    return useQuery(
        ['locales', params],
        async () => {
            const { data } = await localeApi.getLocales(params)
            return data.data
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}
