import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Collapse,
    IconButton,
} from '@mui/material'
import CheckListPartCodeSection from '~/components/partCodes/CheckListPartCodeSection'
import { StyledPaper } from '~/components/StyledPaper'
import { usePartCodeTypeQuery } from '~/pages/Dispatch/AddDispatch/query'
import { useCausesFilterPartCodeForDiagnosticQuery } from '~/pages/PartCode/query'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectDiagnosticSerial } from '~/store/diagnose/selector'
import {
    selectSelectedPartCodeTypesForDiagnostic,
    selectShowedPartCodeForDiagnostic,
} from '~/store/partcode/selector'
import {
    pushSelectedPartCodeTypesForDiagnostic,
    unsetSelectedPartCodeTypesForDiagnostic,
} from '~/store/partcode/slice'
import SectionTitle from './components/SectionTitle'
import PartCodeList from './Detail/PartCode/PartCodeList'
import ShowingPartCodeTable from './Detail/PartCode/ShowingPartCodeTable'
import { LOCALIZATION } from './constants'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import propTypes from 'prop-types'

const PartToChange = ({
    clickedCause,
    showMaliciousCondition = false,
    showAccidentalCondition = true,
    finishDiagnostic,
    isConfirmForm = false,
    form,
    hasAddAdditionalPartsRight = false,
    errorPartCodes = [],
    ppidRegex = null,
}) => {
    const [open, setOpen] = useState(false)
    const { t } = useTranslation()
    const [typeNameFilter, setTypeNameFilter] = useState('')
    const diagnosticsSerial = useSelector(selectDiagnosticSerial)
    const dispatch = useDispatch()
    const selectedPartCodeTypes = useSelector(
        selectSelectedPartCodeTypesForDiagnostic,
    )

    const { data: partCodeType, isLoading: isLoadingPartCodeTypes } =
        usePartCodeTypeQuery({
            type: typeNameFilter,
            serial: diagnosticsSerial,
        })

    useCausesFilterPartCodeForDiagnosticQuery({
        clickedCause: clickedCause.causeValue === true ? clickedCause : {},
    })

    const renderedPartCodeTypes = useMemo(() => {
        if (
            partCodeType?.types?.length === 0 ||
            isLoadingPartCodeTypes ||
            !!partCodeType?.message
        )
            return []
        return partCodeType?.types.map((type) => {
            return { name: type.type, label: type.type }
        })
    }, [isLoadingPartCodeTypes, partCodeType])

    const handleToggle = (e, code) => {
        if (e.target.checked) {
            dispatch(pushSelectedPartCodeTypesForDiagnostic(code.name))
            return
        }
        dispatch(unsetSelectedPartCodeTypesForDiagnostic(code.name))
    }

    const showingPartCodes = useSelector(selectShowedPartCodeForDiagnostic)

    useEffect(() => {
        if (!isConfirmForm) {
            form?.change('parts', showingPartCodes)
        }
    }, [showingPartCodes, form, isConfirmForm])

    if (!diagnosticsSerial) return <></>
    return (
        <Grid container spacing={2}>
            <SectionTitle
                title={t(LOCALIZATION + `partToChange`)}
                extra={
                    !finishDiagnostic && hasAddAdditionalPartsRight ? (
                        <IconButton
                            aria-label='expand row'
                            size='small'
                            onClick={() => setOpen(!open)}
                        >
                            {open ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    ) : (
                        ''
                    )
                }
                xs={12}
                md={12}
                lg={12}
                align='center'
                ml={2}
            />
            {finishDiagnostic ? (
                <Grid item xs={12}>
                    <ShowingPartCodeTable
                        {...{
                            showMaliciousCondition,
                            showAccidentalCondition,
                            finishDiagnostic,
                            hasAddAdditionalPartsRight,
                            errorPartCodes,
                            ppidRegex,
                        }}
                    />
                </Grid>
            ) : (
                <>
                    <Grid item xs={12}>
                        <ShowingPartCodeTable
                            {...{
                                showMaliciousCondition,
                                showAccidentalCondition,
                                hasAddAdditionalPartsRight,
                                errorPartCodes,
                                ppidRegex,
                            }}
                        />
                    </Grid>
                    {hasAddAdditionalPartsRight && (
                        <Grid item xs={12}>
                            <Collapse in={open} timeout='auto' unmountOnExit>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    sx={{
                                                        width: '33%',
                                                        verticalAlign: 'top',
                                                    }}
                                                    scope='row'
                                                >
                                                    <CheckListPartCodeSection
                                                        {...{
                                                            isLoadingPartCodeTypes,
                                                            renderedPartCodeTypes,
                                                            selectedPartCodeTypes,
                                                            handleToggle,
                                                            setTypeNameFilter,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    <StyledPaper>
                                                        <PartCodeList />
                                                    </StyledPaper>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Collapse>
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    )
}

PartToChange.propTypes = {
    showMaliciousCondition: propTypes.bool,
    showAccidentalCondition: propTypes.bool,
    clickedCause: propTypes.oneOfType([propTypes.array, propTypes.object]),
    finishDiagnostic: propTypes.bool,
    isConfirmForm: propTypes.bool,
    form: propTypes.object,
    hasAddAdditionalPartsRight: propTypes.bool,
    errorPartCodes: propTypes.array,
    ppidRegex: propTypes.string,
}

PartToChange.defaultProps = {
    isConfirmForm: false,
    hasAddAdditionalPartsRight: false,
    showMaliciousCondition: false,
    showAccidentalCondition: true,
    errorPartCodes: [],
    ppidRegex: null,
}

export default PartToChange
