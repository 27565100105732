import { Box, Stack, Toolbar as MuiToolbar } from '@mui/material'
import { LoadingButton } from '~/components/Button'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const Toolbar = ({ localization, isLoading }) => {
    const { t } = useTranslation()
    return (
        <>
            <MuiToolbar disableGutters={false}>
                <Box sx={{ flexGrow: 1 }}></Box>

                <Stack
                    direction='row'
                    justifyContent='right'
                    alignItems='right'
                    minWidth='46px'
                    spacing={2}
                >
                    <LoadingButton
                        label={t(`${localization}.save`)}
                        type={`submit`}
                        loading={isLoading}
                    />
                </Stack>
            </MuiToolbar>
        </>
    )
}

Toolbar.propTypes = {
    localization: PropTypes.string,
    isLoading: PropTypes.bool,
}

export default Toolbar
