import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

export const useSupportObject = () => {
    const { id } = useParams()
    const clientQuery = useQueryClient()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    return {
        id,
        clientQuery,
        navigate,
        dispatch,
    }
}
