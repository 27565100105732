import { useState } from 'react'
import Proptypes from 'prop-types'
import { InputAdornment, TextField } from '@mui/material'
import SensorsIcon from '@mui/icons-material/Sensors'

const inputProps = {
    startAdornment: (
        <InputAdornment position='start'>
            <SensorsIcon />
        </InputAdornment>
    ),
}

const inputLabelProps = {
    shrink: true,
}

const AddSerialInput = ({ innerRef, input, meta, title, ...props }) => {
    const hasErrors = meta.touched && meta.invalid
    const helperText = hasErrors && (meta.error || meta.submitError)

    const [value, setValue] = useState('')

    const handleChangeCase = (e) => {
        const value = e.target.value
        setValue(value.toUpperCase())
        input.onChange(value.toUpperCase())
    }

    return (
        <>
            <TextField
                {...props}
                autoFocus
                fullWidth
                ref={innerRef}
                error={hasErrors}
                helperText={helperText}
                InputLabelProps={inputLabelProps}
                InputProps={inputProps}
                variant='outlined'
                sx={{ width: '100%' }}
                onChange={handleChangeCase}
                value={value}
            />
        </>
    )
}

AddSerialInput.defaultProps = {
    innerRef: null,
    input: {},
    meta: {},
    title: '',
}

AddSerialInput.propTypes = {
    innerRef: Proptypes.oneOfType([
        Proptypes.func,
        Proptypes.shape({ current: Proptypes.any }),
    ]),
    input: Proptypes.objectOf(Proptypes.any),
    meta: Proptypes.objectOf(Proptypes.any),
    title: Proptypes.string,
}

export default AddSerialInput
