import deliveryApi from '~/api/deliveryApi'
import { useQuery } from 'react-query'
import { removeEmptyValue } from '~/utils/helpers'

export const useDeliveriesQuery = (params) => {
    const p = removeEmptyValue(params)
    return useQuery(
        ['Deliveries', params],
        async () => {
            const { data } = await deliveryApi.getDeliveries(p)
            return { data: data.data, total: data.meta.total }
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useDeliveryDetailQuery = ({ id, ...params }) => {
    return useQuery(
        ['Delivery_detail', id, params],
        async () => {
            const { data } = await deliveryApi.getDeliveryDetail(id, params)
            return { data: data.data, total: data.meta.total }
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}
