import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LargeBackgound from '../../assets/images/large_background/easy4device_using_laptop.jpg'
import SmallBackgound from '../../assets/images/small_background/easy4device_using_laptop.jpg'
import { Container } from '@mui/material'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

const StylePaper = styled(Paper)(({ theme }) => ({
    position: 'relative',
    backgroundColor: 'grey.800',
    width: `100%`,
    color: '#fff',
    height: '50vh',
    minHeight: '700px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${LargeBackgound})`,
    [theme.breakpoints.down('md')]: {
        backgroundImage: `url(${SmallBackgound})`,
    },
}))

function ImageContent() {
    const { t } = useTranslation()

    return (
        <StylePaper
            sx={{
                mb: 4,
            }}
        >
            {/* Increase the priority of the hero background image */}
            {
                <img
                    style={{ display: 'none' }}
                    src={LargeBackgound}
                    alt='test'
                />
            }
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    backgroundColor: 'rgba(0,0,0,.3)',
                }}
            />
            <Container maxWidth='lg'>
                <Grid container>
                    <Grid item md={6}>
                        <Box
                            sx={{
                                position: 'relative',
                                p: { xs: 3, md: 12 },
                                pr: { md: 0 },
                            }}
                        >
                            <Typography
                                component='h1'
                                variant='h4'
                                color='inherit'
                                gutterBottom
                            >
                                {t('login.welcomeTitle')}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </StylePaper>
    )
}

export default ImageContent
