import { Box } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BreadCrumb from '~/components/BreadCrumb'
import { LoadingButton } from '~/components/Button'
import PageName from '~/components/PageName'
import { PRODUCTION_SYSTEMIMAGE } from '~/constants/Routes'
import ScanOrderNumber from '../Scan/ScanOrderNumber'
import OrdersTable from './OrdersTable'

const OrdersOverview = () => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const breadCrumbConfig = [
        { route: PRODUCTION_SYSTEMIMAGE, title: 'Production' },
        { route: '#', title: 'Orders' },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <PageName name={'Orders'} />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'right',
                    marginBottom: '10px',
                }}
            >
                <LoadingButton
                    label={t('pages.production.orders.scanOderNumber')}
                    onClick={() => {
                        setOpen(true)
                    }}
                />
            </Box>
            <OrdersTable />
            {open && <ScanOrderNumber setOpen={setOpen} />}
        </>
    )
}

export default OrdersOverview
