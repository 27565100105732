import { deviceApi } from '~/api'
import { useMutation } from 'react-query'

export const useImportSerialQueueMutation = () => {
    return useMutation(
        ['import_serial'],
        async (file) => {
            const data = new FormData()
            data.append('file', file)
            const results = await deviceApi.getSerialsImport(data)
            return results
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useImportDellCoepMutation = () => {
    return useMutation(
        ['import_dell_coep'],
        async (file) => {
            const data = new FormData()
            data.append('file', file)
            const results = await deviceApi.getDellCOEPImport(data)
            return results
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useExportDellCoepSampleMutation = () => {
    return useMutation(
        ['sampleDellCoep_export'],
        async () => {
            const { data } = await deviceApi.getDellCoepSample()
            return data
        },
        {
            onSuccess: (data) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute(
                    'download',
                    `Servicetag_replaceThisPartWithOrdernr.xlsx`,
                )
                document.body.appendChild(link)
                link.click()
            },
            onError: () => {},
            retry: false,
        },
    )
}
