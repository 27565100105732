import BreadCrumb from '~/components/BreadCrumb'
import { ISSUE_DISPATCH, ISSUE_ISSUE } from '~/constants/Routes'
import { useParams } from 'react-router-dom'
import DispatchDetail from './DispatchDetail'
import { useDispatchDetailQuery } from './query'

const DispatchDetailPage = () => {
    const { id } = useParams()
    const { data: dispatchContent } = useDispatchDetailQuery(id)

    const BreadComponents = [
        { route: ISSUE_ISSUE, title: 'Issues' },
        { route: ISSUE_DISPATCH, title: 'Dispatches' },
        {
            page: 'detail',
            title: dispatchContent?.name,
        },
    ]
    if (!dispatchContent) {
        return null
    }

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <DispatchDetail dispatchContent={dispatchContent} />
        </>
    )
}

export default DispatchDetailPage
