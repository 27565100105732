import propTypes from 'prop-types'
import { useMemo } from 'react'
import TableFilters from '~/components/TableFilters'

const ProjectTableSearchAndFilter = ({
    search,
    searchHandler,
    onEnterSearch,
    setServiceCenter,
    serviceCenter,
    setProductionImage,
    productionImage,
    setOrganisation,
    organisation,
    dataForFilter,
}) => {
    const serviceCenterData = useMemo(() => {
        if (dataForFilter && dataForFilter.serviceCenters) {
            const result = dataForFilter.serviceCenters?.map((item) => ({
                id: item?.name,
                name: item?.name,
            }))
            result.unshift({ id: '', name: 'All' })
            return result
        }
        return []
    }, [dataForFilter])

    const orgsData = useMemo(() => {
        if (dataForFilter && dataForFilter.orgs) {
            const result = dataForFilter.orgs?.map((item) => ({
                id: item?.name,
                name: item?.name,
            }))
            result.unshift({ id: '', name: 'All' })
            return result
        }
        return []
    }, [dataForFilter])

    const productionImageData = useMemo(() => {
        if (dataForFilter && dataForFilter.images) {
            const result = dataForFilter.images?.map((item) => ({
                id: item?.name,
                name: item?.name,
            }))
            result.unshift({ id: '', name: 'All' })
            return result
        }
        return []
    }, [dataForFilter])

    const selectGroup = useMemo(() => {
        return [
            {
                label: 'Service Center',
                handleFunc: setServiceCenter,
                dataSet: serviceCenterData,
                value: serviceCenter,
            },
            {
                label: 'Organisation',
                handleFunc: setOrganisation,
                dataSet: orgsData,
                value: organisation,
            },
            {
                label: 'Production Image',
                handleFunc: setProductionImage,
                dataSet: productionImageData,
                value: productionImage,
            },
        ]
    }, [
        organisation,
        orgsData,
        productionImage,
        productionImageData,
        serviceCenter,
        serviceCenterData,
        setOrganisation,
        setProductionImage,
        setServiceCenter,
    ])

    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='projects'
            page='projects'
        />
    )
}

ProjectTableSearchAndFilter.propTypes = {
    search: propTypes.string,
    searchHandler: propTypes.func,
    onEnterSearch: propTypes.func,
    setServiceCenter: propTypes.func,
    serviceCenter: propTypes.string,
    setProductionImage: propTypes.func,
    productionImage: propTypes.string,
    setOrganisation: propTypes.func,
    organisation: propTypes.string,
    dataForFilter: propTypes.object,
}

export default ProjectTableSearchAndFilter
