import productApi from '~/api/productApi'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { removeEmptyValue } from '~/utils/helpers'

export const useProductDetailQuery = (params) => {
    const { id } = useParams()
    return useQuery(
        ['product', id, params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await productApi.getProductDetail(id, p)
            return { data: data.data }
        },
        {},
    )
}
