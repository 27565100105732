import PropTypes from 'prop-types'

import { Divider, Paper } from '@mui/material'
import PaginationTable from '~/components/PaginationTable'
import TableContainer from '~/components/Table/TableContainer'
import TableHeader from '~/components/TableHeader'
import Toolbar from '~/components/Toolbar'
import useTable from '~/hooks/useTable'
import TableBody from './TableBody'

const CustomTable = ({
    id = null,
    LOCALIZATION,
    customQuery,
    headCells,
    navigateAt,
    navigateTo,
    ...props
}) => {
    const {
        results,
        data,
        isLoading,
        search,
        order,
        orderBy,
        page,
        sortHandler,
        searchHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: customQuery,
        queryAttributes: {
            id,
        },
    })

    return (
        <Paper sx={{ width: '100%', mb: 2 }}>
            <Toolbar
                filterSearch={search}
                onFilterSearch={searchHandler}
                onEnterSearch={onEnterSearch}
            />
            <Divider />
            <TableContainer>
                <TableHeader
                    headCells={headCells}
                    localization={LOCALIZATION}
                    onRequestSort={sortHandler}
                    order={order}
                    orderBy={orderBy}
                />
                <TableBody
                    isLoading={isLoading}
                    items={results}
                    headCells={headCells}
                    navigateAt={navigateAt}
                    navigateTo={navigateTo}
                    {...{ props }}
                />
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}

CustomTable.propTypes = {
    id: PropTypes.string,
    LOCALIZATION: PropTypes.string,
    customQuery: PropTypes.func,
    headCells: PropTypes.array,
    navigateAt: PropTypes.arrayOf(PropTypes.string),
    navigateTo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

CustomTable.defaultProps = {
    LOCALIZATION: '',
    customQuery: () => {},
    headCells: [],
}

export default CustomTable
