import { useCallback, useState } from 'react'

const useSnackBar = () => {
    const [snackbarConfig, setSnackbarConfig] = useState(null)

    const openSnackbar = useCallback(
        ({ message, type = 'success', duration = 3000, alertSx = null }) => {
            setSnackbarConfig({ message, type, duration, alertSx })
        },
        [],
    )

    const hideSnackbar = useCallback(() => {
        setSnackbarConfig(null)
    }, [])

    return {
        snackbarOpen: !!snackbarConfig,
        snackbarConfig,
        openSnackbar,
        hideSnackbar,
    }
}

export default useSnackBar
