import {
    Table as MuiTable,
    TableBody as MuiTableBody,
    Paper,
    TableContainer,
} from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import { RackScanHeadCells } from '~/components/HeaderTable/RackScanHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import Row from '~/components/TableRow'
import useTable from '~/hooks/useTable'
import { useEffect, useRef, useState } from 'react'
import { useRackScanListQuery } from '../query'
import RackScanTableFilters from './RackScanTableFilters'
import PropTypes from 'prop-types'

const TableBody = ({ isLoading, objects }) => {
    if (isLoading) {
        return <TableLoading colSpan={RackScanHeadCells.length} />
    }

    return (
        <MuiTableBody>
            {objects.length > 0 ? (
                objects.map((row, index) => {
                    return (
                        <Row
                            row={row}
                            columnsConfig={RackScanHeadCells}
                            key={index}
                        />
                    )
                })
            ) : (
                <DataNotFound colSpan={RackScanHeadCells.length} />
            )}
        </MuiTableBody>
    )
}

TableBody.propTypes = { isLoading: PropTypes.bool, objects: PropTypes.array }

const Table = () => {
    const [serviceCenter, setServiceCenter] = useState('')
    const [team, setTeam] = useState('')

    const {
        results,
        data,
        isLoading,
        page,
        search,
        order,
        orderBy,
        changePageHandler,
        searchHandler,
        sortHandler,
        setPage,
        onEnterSearch,
    } = useTable({
        query: useRackScanListQuery,
        queryAttributes: {
            team,
            serviceCenter,
        },
    })

    const refValue = useRef({
        team: '',
        serviceCenter: '',
    })

    useEffect(() => {
        if (
            team !== refValue.current.team ||
            serviceCenter !== refValue.current.serviceCenter
        ) {
            setPage(1)
            refValue.current.team = team
            refValue.current.serviceCenter = serviceCenter
        }
    }, [setPage, serviceCenter, team])

    return (
        <Paper>
            <RackScanTableFilters
                search={search}
                serviceCenter={serviceCenter}
                setServiceCenter={setServiceCenter}
                setTeam={setTeam}
                team={team}
                searchHandler={searchHandler}
                onEnterSearch={onEnterSearch}
            />
            <TableContainer
                sx={{ minWidth: 750 }}
                aria-labelledby='tableTitle'
                size='medium'
            >
                <MuiTable>
                    <TableHeader
                        headCells={RackScanHeadCells}
                        localization={'pages.issues.rackScans.scan.overview.'}
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />
                    <TableBody isLoading={isLoading} objects={results} />
                </MuiTable>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}

export default Table
