import axiosClient from '~/api/base'

class ServersApi {
    getServers = (params) => {
        return axiosClient.get('api/production/servers', { params })
    }

    getServer = (id) => {
        return axiosClient.get(`api/production/servers/${id}`)
    }

    getServerOrgs = (params) => {
        return axiosClient.get('api/production/servers-organisations', {
            params,
        })
    }

    updateServerInfo = ({ id, payload }) => {
        return axiosClient.post(`api/production/update-server-info/${id}`, {
            name: payload.name,
            organisation_id: payload.organisation_id,
        })
    }
}

export default new ServersApi()
