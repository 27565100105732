import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import { PRODUCTION_SYSTEMIMAGE } from '~/constants/Routes'
import ServersTable from './ServersTable'

const ServersOverview = () => {
    const breadCrumbConfig = [
        { route: PRODUCTION_SYSTEMIMAGE, title: 'Production' },
        { route: '#', title: 'Servers' },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <PageName name={'Servers'} />
            <ServersTable />
        </>
    )
}

export default ServersOverview
