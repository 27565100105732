import { Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { LoadingButton } from '~/components/Button'
import StyledModal from '~/components/StyledModal'
import { useCloseDispatchMutation } from '../../mutate'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const UpdateStateButton = () => {
    const { id } = useParams()
    const { t } = useTranslation()
    const localization = 'pages.dispatches.dispatch.detail'
    const [openChangeStatePopup, setOpenChangeStatePopup] = useState(false)
    const { mutate, isLoading } = useCloseDispatchMutation()

    const onCloseChangeStatePopup = () => {
        setOpenChangeStatePopup(false)
    }

    const onOpenChangeStatePopup = () => {
        setOpenChangeStatePopup(true)
    }

    const closeDispatch = () => {
        mutate({ id })
        setOpenChangeStatePopup(false)
    }

    return (
        <>
            <Stack
                direction='row'
                justifyContent={'flex-end'}
                sx={{ marginBottom: '10px' }}
            >
                <LoadingButton
                    label={t(`${localization}.closeThisDispatch`)}
                    onClick={onOpenChangeStatePopup}
                />
            </Stack>
            <StyledModal
                open={openChangeStatePopup}
                handleClose={onCloseChangeStatePopup}
                stackProps={{
                    sx: { minWidth: 550 },
                }}
            >
                <Typography>
                    {t(`${localization}.confirmCloseDispatch`)}
                </Typography>
                <Stack direction='row' gap={2} sx={{ marginTop: '20px' }}>
                    <LoadingButton
                        label={'Yes'}
                        onClick={closeDispatch}
                        loading={isLoading}
                    />
                    <LoadingButton
                        label={'No'}
                        onClick={onCloseChangeStatePopup}
                        loading={isLoading}
                    />
                </Stack>
            </StyledModal>
        </>
    )
}

UpdateStateButton.propTypes = {
    id: PropTypes.number,
}

export default UpdateStateButton
