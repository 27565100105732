import workbenchApi from '~/api/workbenchApi'
import { useQuery } from 'react-query'
import { removeEmptyValue } from '~/utils/helpers'

export const useWorkbenchListQuery = (params) => {
    return useQuery(
        ['workbenches', params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await workbenchApi.getWorkbenches(p)
            return { data: data.data, total: data.meta?.total }
        },
        {
            retry: false,
        },
    )
}

export const useGetWorkbenchDetailQuery = (id) => {
    return useQuery(['workbench_detail', id], async () => {
        const { data } = await workbenchApi.getWorkbenchDetail(id)
        return data.data
    })
}
