import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import PageName from '~/components/PageName'
import { FLEET_OVERVIEW } from '~/constants/Routes'
import FleetTable from './FleetTable'

const FleetOverview = () => {
    const BreadComponents = [
        { route: FLEET_OVERVIEW, title: 'Fleet' },
        {
            route: FLEET_OVERVIEW,
            title: 'Overview',
        },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={FLEET_OVERVIEW}
            >
                <div>
                    <PageName name={'Fleet'} />
                </div>
            </ComponentTooltip>
            <FleetTable />
        </>
    )
}

export default FleetOverview
