import serviceAccountApi from '~/api/serviceAccountApi'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

const useHooks = () => {
    const { id } = useParams()
    const clientQuery = useQueryClient()
    return {
        id,
        clientQuery,
    }
}

export const useUpdateMutation = ({ showSnackbar }) => {
    const { id, clientQuery } = useHooks()

    return useMutation(
        ['service_account_update'],
        async (values) => {
            return await serviceAccountApi.update(id, values)
        },
        {
            onSuccess: (data) => {
                showSnackbar(
                    data.message ? data.message : 'Save successful!',
                    'success',
                )
                return data
            },
            onError: (error) =>
                showSnackbar(
                    error.message ? error.message : 'Save failed!',
                    'error',
                ),
            onSettled: () =>
                clientQuery.invalidateQueries('service_account_detail'),
            retry: false,
        },
    )
}

export const useRevokeServiceAccountMutation = ({
    showSnackbar,
    setTokenValue,
}) => {
    const { id, clientQuery } = useHooks()

    return useMutation(
        ['service_account_revoke'],
        async () => {
            const { data } = await serviceAccountApi.revoke(id)
            setTokenValue('')
            return { data: data.data }
        },
        {
            onSuccess: (data) => {
                showSnackbar(
                    data.message ? data.message : `Revoke successful!`,
                    'success',
                )

                clientQuery.invalidateQueries('service_account_detail')
                return data
            },
            onError: (error) => {
                showSnackbar(
                    error.message ? error.message : 'Revoke failed!',
                    'error',
                )
            },
            onSettled: () =>
                clientQuery.invalidateQueries('service_account_detail'),
            retry: false,
        },
    )
}

export const useGenerateTokenMutate = ({ showSnackbar, setTokenValue }) => {
    const { id, clientQuery } = useHooks()

    return useMutation(
        ['service_account_generate_token'],
        async () => {
            const { data } = await serviceAccountApi.generateToken(id)
            setTokenValue(data.token)
            return data.token
        },
        {
            onSuccess: (data) => {
                navigator.clipboard.writeText(data)
                showSnackbar(`Token copied to clipboard!`, 'success')
                clientQuery.invalidateQueries('service_account_detail')
                return data
            },
            onError: () => {
                showSnackbar('Token generate failed!', 'error')
            },
            retry: false,
        },
    )
}
