import { createSlice } from '@reduxjs/toolkit'

export const purchaseOrderSlice = createSlice({
    name: 'purchaseOrder',
    initialState: {
        purchaseOrderLine: null,
    },
    reducers: {
        setPurchaseOrderLineDetail: (state, action) => {
            state.purchaseOrderLine = action.payload
        },
    },
})

export const { setPurchaseOrderLineDetail } = purchaseOrderSlice.actions

export default purchaseOrderSlice
