import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
    Collapse,
    IconButton,
    Link,
    Table as MuiTable,
    TableBody as MuiTableBody,
    Paper,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import { RoleHeadCells } from '~/components/HeaderTable/RoleHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { ADMINISTRATOR_ROLE } from '~/constants/Routes'
import useTable from '~/hooks/useTable'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRolesQuery } from './query'
import PropTypes from 'prop-types'

const Row = (row) => {
    const navigate = useNavigate()
    const [isCollapsed, setIsCollapsed] = useState(true)
    return (
        <TableRow hover tabIndex={-1} key={row.row.id}>
            <TableCell>
                <IconButton
                    aria-label='expand row'
                    size='small'
                    onClick={() => setIsCollapsed(!isCollapsed)}
                >
                    {isCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                </IconButton>
            </TableCell>
            <TableCell component='th' scope='row'>
                <Link
                    component='button'
                    variant='inherit'
                    to='#'
                    underline='hover'
                    onClick={() =>
                        navigate(`${ADMINISTRATOR_ROLE}/${row.row.id}`)
                    }
                >
                    <Typography variant='body2' align='center'>
                        {row.row.name}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell component='th' scope='row'>
                <Typography variant='body2'>{row.row.guard_name}</Typography>
            </TableCell>
            <TableCell align='left'>
                <Collapse in={isCollapsed} timeout={'auto'} unmountOnExit>
                    {row.row.permissions ? (
                        row.row.permissions.map((item) => {
                            return (
                                <Typography variant='body2' key={item.name}>
                                    {item.name}
                                </Typography>
                            )
                        })
                    ) : (
                        <Typography variant='body2'>{''}</Typography>
                    )}
                </Collapse>
            </TableCell>
        </TableRow>
    )
}

const TableBody = ({ isLoading, objects }) => {
    if (isLoading) {
        return <TableLoading colSpan={RoleHeadCells.length} />
    }

    return (
        <MuiTableBody>
            {objects.length > 0 ? (
                objects.map((row) => {
                    return <Row row={row} key={row.id} />
                })
            ) : (
                <DataNotFound colSpan={RoleHeadCells.length} />
            )}
        </MuiTableBody>
    )
}

TableBody.propTypes = {
    isLoading: PropTypes.bool,
    objects: PropTypes.array,
}

const Table = () => {
    const {
        results,
        data,
        isLoading,
        order,
        page,
        orderBy,
        sortHandler,
        changePageHandler,
    } = useTable({
        query: useRolesQuery,
        initialStates: {
            order: 'asc',
            page: 1,
            orderBy: 'name',
        },
    })

    return (
        <Paper>
            <TableContainer
                sx={{ minWidth: 750 }}
                aria-labelledby='tableTitle'
                size='medium'
            >
                <MuiTable>
                    <TableHeader
                        headCells={RoleHeadCells}
                        localization={'pages.administrator.roles.'}
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />
                    <TableBody isLoading={isLoading} objects={results} />
                </MuiTable>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}

export default Table
