import { Fragment } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Grid, Typography } from '@mui/material'

const patternValueList = [
    'min (0 - 59)',
    'hour (0 - 23)',
    'day of month (1 - 31)',
    'month (1 - 12)',
    'day of week (0 - 6)',
]

const patternSpecialValueList = [
    { character: '*', mean: 'any value' },
    { character: ',', mean: 'value list separator' },
    { character: '-', mean: 'range of values' },
    { character: '/', mean: 'step values' },
]

const CronTabDescription = () => {
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            {Array(5)
                                .fill('*')
                                .map((item, index) => (
                                    <TableCell align='center' key={index}>
                                        <Typography variant='h3'>
                                            {item}
                                        </Typography>
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {patternValueList.map((item, index) => (
                                <TableCell align='center' key={index}>
                                    {item}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container spacing={2}>
                {patternSpecialValueList.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            <Grid item xs={1}>
                                <Typography variant='h4'>
                                    {item.character}
                                </Typography>
                            </Grid>
                            <Grid item xs={11}>
                                <Typography>{item.mean}</Typography>
                            </Grid>
                        </Fragment>
                    )
                })}
            </Grid>
        </>
    )
}

export default CronTabDescription
