export const ComponentTooltipColumnConfig = [
    {
        id: 'drop_down_btn',
        hidden: true,
    },
    {
        id: 'id',
        label: 'id',
        orderable: true,
    },
    {
        id: 'page_url',
        label: 'pageUrl',
        orderable: true,
    },
    {
        id: 'component_id',
        label: 'componentId',
        orderable: true,
    },
    {
        id: 'tooltip',
        label: 'tooltip',
        orderable: true,
    },
]
