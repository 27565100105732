import { axiosClient } from '~/api/base'

class ServiceAccountApi {
    getLists = (params) => axiosClient.get('api/service_accounts', { params })
    getDetail = (id) => axiosClient.get(`api/service_accounts/${id}`)

    update = (id, params) =>
        axiosClient.put(`api/service_accounts/${id}`, { ...params })

    create = (params) => axiosClient.post('api/service_accounts', { ...params })

    revoke = (id) => axiosClient.put(`api/service_accounts/${id}/revoke`)

    generateToken = (id, params) =>
        axiosClient.put(`api/service_accounts/${id}/generate_token`, {
            ...params,
        })
}

export default new ServiceAccountApi()
