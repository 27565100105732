import { Grid, Typography } from '@mui/material'
import ComponentTooltip from '~/components/ComponentTooltip'
import { FLEET_SERIAL_DETAIL } from '~/constants/Routes'
import { Form } from 'react-final-form'
import { useUploadPictureMutation } from '../mutation'
import StyledModal from '~/components/StyledModal'
import InputFile from './InputFile'
import SaveButtonPopup from './SaveButtonPopup'
import UploadModalTitle from './UploadModalTitle'
import { useCallback } from 'react'
import PropTypes from 'prop-types'

const UploadPicture = ({ openUploadPicture, setOpenUploadPicture }) => {
    const { mutate: uploadPicture, isLoading } = useUploadPictureMutation()

    const handleClose = () => {
        setOpenUploadPicture(false)
    }

    const onSubmit = useCallback(
        (payload) =>
            uploadPicture(payload, {
                onSuccess: (data) => {
                    setOpenUploadPicture(false)
                },
            }),
        [setOpenUploadPicture, uploadPicture],
    )

    return (
        <StyledModal open={openUploadPicture} handleClose={() => handleClose()}>
            <Grid container spacing={0.5}>
                <UploadModalTitle
                    componentId={'upload_picture_popup_title'}
                    title={'Upload Picture'}
                />
                <Grid item xs={12} sm={12}>
                    <Form
                        onSubmit={onSubmit}
                        render={({ handleSubmit, values, form }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid item xs={12}>
                                    <ComponentTooltip
                                        componentId={`upload_picture_popup_file`}
                                        pagePath={FLEET_SERIAL_DETAIL}
                                    >
                                        <Typography>File</Typography>
                                    </ComponentTooltip>
                                    <InputFile
                                        form={form}
                                        nameField='pictures'
                                    />
                                </Grid>
                                <SaveButtonPopup {...{ isLoading }} />
                            </form>
                        )}
                    />
                </Grid>
            </Grid>
        </StyledModal>
    )
}

UploadPicture.propTypes = {
    openUploadPicture: PropTypes.bool,
    setOpenUploadPicture: PropTypes.func,
}

export default UploadPicture
