import PropTypes from 'prop-types'
import { Snackbar, Alert } from '@mui/material'

const StyledSnackbar = ({
    open,
    onClose,
    message,
    type,
    duration,
    alertSx = null,
}) => {
    if (!open) {
        return null
    }
    return (
        <Snackbar
            open={open}
            autoHideDuration={duration}
            onClose={onClose}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'top',
            }}
            sx={{ marginTop: '70px' }}
        >
            <Alert severity={type} sx={alertSx}>
                {message}
            </Alert>
        </Snackbar>
    )
}

StyledSnackbar.propTypes = {
    message: PropTypes.string,
    type: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    duration: PropTypes.number,
    alertSx: PropTypes.object,
}

StyledSnackbar.defaultProps = {
    message: '',
    type: 'info',
    duration: 3000,
    alertSx: null,
}

export default StyledSnackbar
