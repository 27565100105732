import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import ListToolbarWrapper from '~/components/ListToolbarWrapper'
import PageName from '~/components/PageName'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_USER,
    ADMINISTRATOR_USER_DISABLED,
} from '~/constants/Routes'
import DisabledUsersTable from './DisabledUsersTable'

const DisabledUser = () => {
    const BreadComponents = [
        { route: ADMINISTRATOR, title: 'Administrator' },
        {
            route: ADMINISTRATOR_USER,
            title: 'Users',
        },
        {
            route: ADMINISTRATOR_USER_DISABLED,
            title: 'DisabledUser',
        },
    ]
    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_USER_DISABLED}
            >
                <div>
                    <PageName name={'Disabled users'} />
                </div>
            </ComponentTooltip>
            <ListToolbarWrapper />
            <DisabledUsersTable />
        </>
    )
}
export default DisabledUser
