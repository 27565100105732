import DownloadIcon from '@mui/icons-material/Download'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import UploadIcon from '@mui/icons-material/Upload'
import { MenuItem } from '@mui/material'
import ActionMenu from '~/components/ActionMenu'
import ListToolbarWrapper from '~/components/ListToolbarWrapper'
import UploadPopup from '~/components/UploadPopup'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useExportVendorMutation, useImportVendorMutation } from './mutate'
import PropTypes from 'prop-types'

const VendorListToolbar = ({ payload }) => {
    const [openUpload, setOpenUpload] = useState(false)
    const { t } = useTranslation()

    const { mutate: exportVendor, isLoading } = useExportVendorMutation()
    const { mutate: importVendor } = useImportVendorMutation()

    return (
        <ListToolbarWrapper>
            <ActionMenu {...{ openUpload }}>
                <MenuItem
                    onClick={() => exportVendor(payload)}
                    disabled={isLoading}
                >
                    {isLoading ? <HourglassTopIcon /> : <DownloadIcon />}
                    {t('button.export')}
                </MenuItem>

                <MenuItem onClick={() => setOpenUpload(true)}>
                    <UploadIcon />
                    {t('button.import')}
                </MenuItem>
            </ActionMenu>

            <UploadPopup
                openUpload={openUpload}
                setOpenUpload={setOpenUpload}
                actionImport={importVendor}
                isLoading={isLoading}
            />
        </ListToolbarWrapper>
    )
}

VendorListToolbar.propTypes = { payload: PropTypes.object }

export default VendorListToolbar
