import { Grid, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
    useGetDiagnosisFromIssueQuery,
    usePictureQuery,
    useTicketDetailsQuery,
} from '~/pages/Diagnostic/query'
import TicketDetails from '~/pages/Diagnostic/TicketDetails'
import PageName from '~/components/PageName'
import { StyledPaper } from '~/components/StyledPaper'
import styled from '@emotion/styled'
import PicturesSection from '~/pages/Diagnostic/PicturesSection'
import { LoadingButton } from '~/components/Button'
import { useIssueQuery } from '~/pages/Issue/query'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ISSUE_STATES } from '~/constants/constants'
import SectionTitle from '~/pages/Diagnostic/components/SectionTitle'
import StyledModal from '~/components/StyledModal'
import { DASHBOARD, ISSUE_ISSUE_DETAIL } from '~/constants/Routes'
import { useQueryClient } from 'react-query'
import {
    useTransitionToImagingMutation,
    useTriageDoneIssueMutation,
} from '~/pages/Issue/mutation'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import IssueLog from '~/pages/Diagnostic/IssueLog'
import DeviceHistory from '~/pages/Diagnostic/DeviceHistory'
import { Form } from 'react-final-form'
import FormAutoSave from '~/components/FinalForm/FormAutoSave'
import TriageCheckboxesSection from '~/pages/Issue/Triage/TriageCheckboxesSection'
import {
    useCreateDiagnosticMutation,
    useUpdateDiagnosticTriage,
} from '~/pages/Diagnostic/mutation'
import { convertBooleanValue } from '~/utils/helpers'
import {
    COSMETIC_DAMAGE,
    POWERS_ON,
    LIQUID_DAMAGE,
    SOFTWARE_ISSUE,
} from '~/pages/Diagnostic/constants'

const Triage = () => {
    const localization = 'pages.issues.triage'
    const { issueId } = useParams()
    const location = useLocation()
    const [showPopup, setShowPopup] = useState(false)
    const [showPopupImagingStep, setShowPopupImagingStep] = useState(false)

    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { openSnackbar } = useDialogContext()
    const { t } = useTranslation()

    const { data: ticketData } = useTicketDetailsQuery(issueId)
    const { data: issueDetail } = useIssueQuery(issueId)
    const { data: issuePics } = usePictureQuery(issueId)
    const { data: diagnosis, isError } = useGetDiagnosisFromIssueQuery(issueId)

    const issueData = useMemo(() => issueDetail?.data, [issueDetail])

    const isFinished = useMemo(
        () => !!issueData?.diagnostic?.finished_at,
        [issueData],
    )

    const isInPictureStep = useMemo(
        () => issueData?.diagnostic?.current_step === 1,
        [issueData],
    )

    const isStartingTriage = useMemo(() => {
        const stateIndex = ISSUE_STATES.findIndex(
            (state) => state === issueData?.state,
        )

        const triageDoneIndex = ISSUE_STATES.findIndex(
            (state) => state === 'TRIAGE_DONE',
        )

        return stateIndex < triageDoneIndex
    }, [issueData])

    const { mutate: makeIssueDoneTriage, isLoading: isSaving } =
        useTriageDoneIssueMutation()

    const { mutate: createDiagnostic } = useCreateDiagnosticMutation()

    const { mutate: transitionToImaging } =
        useTransitionToImagingMutation(issueId)

    const hasPics = useMemo(() => {
        return issuePics?.data?.length > 0
    }, [issuePics])

    useEffect(() => {
        if (isError) {
            createDiagnostic(undefined, {
                onSuccess: () => {
                    queryClient.invalidateQueries([
                        'diagnosis_from_issue',
                        issueId,
                    ])
                },
            })
        }
    }, [createDiagnostic, isError, issueId, queryClient])

    const initialValues = useMemo(() => {
        if (!isError && diagnosis) {
            return diagnosis
        }

        return {}
    }, [diagnosis, isError])

    const triageAndClosePopup = () => {
        makeIssueDoneTriage(
            { issueId },
            {
                onError: () => {
                    openSnackbar({
                        message: t(
                            'pages.issues.overview.error_when_proceeding',
                        ),
                        type: 'error',
                        duration: 10000,
                    })
                },
                onSuccess: () => {
                    setShowPopup(false)
                    queryClient.invalidateQueries(['issue', issueId])

                    queryClient.invalidateQueries(['issue_diagnostic'])

                    queryClient.invalidateQueries(['issue_logs'])

                    queryClient.invalidateQueries(['device_histories'])
                    if (issueData?.service_center?.separate_triage) {
                        navigate({ pathname: DASHBOARD })
                    } else {
                        const searchParams = new URLSearchParams(
                            location.search,
                        )
                        searchParams.set('tag', '2')
                        const newSearchString = searchParams.toString()
                        navigate({
                            pathname: window.location.pathname,
                            search: newSearchString,
                        })
                    }
                },
            },
        )
    }

    const navigateImagingStep = () => {
        transitionToImaging(null, {
            onSuccess: () => {
                const searchParams = new URLSearchParams(location.search)
                searchParams.set('tag', '4')
                navigate({
                    pathname: ISSUE_ISSUE_DETAIL.replace(':issueId', issueId),
                    search: searchParams.toString(),
                })
            },
        })
    }

    const {
        mutateAsync: updateIssueDiagnosesAsync,
        isLoading: isUpdatingIssueDiagnostic,
        data: diagnostic,
    } = useUpdateDiagnosticTriage(initialValues?.id)

    const handleSave = useCallback(() => {
        if (initialValues?.id) {
            const { data } = diagnostic
            const conditionGoToImagingStep =
                data.cosmetic_damage === false &&
                data.powers_on === true &&
                data.software_issue === 1 &&
                data.liquid_damage === false

            if (conditionGoToImagingStep) {
                setShowPopupImagingStep(true)
                setShowPopup(false)
            } else {
                setShowPopupImagingStep(false)
                setShowPopup(true)
            }
        } else {
            setShowPopupImagingStep(false)
            setShowPopup(true)
        }
    }, [diagnostic, initialValues])

    const updateAsync = async (values) => {
        const prepareDataForUpdating = (values) => {
            const checkBoxes = [
                COSMETIC_DAMAGE,
                POWERS_ON,
                LIQUID_DAMAGE,
                SOFTWARE_ISSUE,
            ]
            const newValue = convertBooleanValue(checkBoxes, values, [
                SOFTWARE_ISSUE,
            ])

            switch (newValue[SOFTWARE_ISSUE]) {
                case true:
                case 'true':
                case 1:
                    newValue[SOFTWARE_ISSUE] = 1
                    break
                case false:
                case 'false':
                case 0:
                    newValue[SOFTWARE_ISSUE] = 0
                    break
                case 'n/a':
                case -1:
                    newValue[SOFTWARE_ISSUE] = -1
                    break
            }

            return newValue
        }
        const filterObject = (obj, keys) => {
            return keys.reduce((acc, key) => {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    acc[key] = obj[key]
                }
                return acc
            }, {})
        }

        const filtered = filterObject(prepareDataForUpdating(values), [
            COSMETIC_DAMAGE,
            POWERS_ON,
            LIQUID_DAMAGE,
            SOFTWARE_ISSUE,
        ])

        if (initialValues?.id && hasPics) {
            await updateIssueDiagnosesAsync(filtered)
        }
    }

    const Section = styled(Grid, {
        shouldForwardProp: (prop) => prop !== 'show',
    })(({ show }) => {
        return {
            ...(!show && {
                display: 'none',
            }),
        }
    })

    return (
        <>
            <StyledModal
                open={showPopup}
                handleClose={() => {
                    setShowPopup(false)
                }}
                stackProps={{
                    sx: { minWidth: 550 },
                }}
            >
                <SectionTitle title={t(`${localization}.message`)} />
                <Stack direction='row' gap={2}>
                    <LoadingButton
                        label={t('dialog.confirm')}
                        onClick={triageAndClosePopup}
                        loading={isSaving}
                        disabled={isSaving}
                    />
                    <LoadingButton
                        label={t('dialog.cancel')}
                        onClick={() => {
                            setShowPopup(false)
                        }}
                        bgColor='#102F44'
                    />
                </Stack>
            </StyledModal>
            <StyledModal
                open={showPopupImagingStep}
                handleClose={() => {
                    setShowPopupImagingStep(false)
                }}
                stackProps={{
                    sx: { minWidth: 550 },
                }}
            >
                <SectionTitle title={t(`${localization}.imagingStep`)} />
                <Stack direction='row' gap={2}>
                    <LoadingButton
                        label={t('dialog.confirm')}
                        onClick={navigateImagingStep}
                        loading={isSaving}
                        disabled={isSaving}
                    />
                    <LoadingButton
                        label={t('dialog.cancel')}
                        onClick={() => {
                            setShowPopupImagingStep(false)
                        }}
                        bgColor='#102F44'
                    />
                </Stack>
            </StyledModal>
            <PageName name={t(`${localization}.title`, { issueId })} />
            <Form
                onSubmit={() => {}}
                initialValues={initialValues}
                render={({ handleSubmit, values }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <FormAutoSave debounce={500} save={updateAsync} />
                            <Grid container spacing={3}>
                                <Section item xs={12} md={6} show={true}>
                                    <StyledPaper variant='outlined'>
                                        <TicketDetails data={ticketData} />
                                    </StyledPaper>
                                </Section>
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} show={true}>
                                            <StyledPaper variant='outlined'>
                                                <PicturesSection
                                                    isFinish={isFinished}
                                                    isInPictureStep={
                                                        isInPictureStep
                                                    }
                                                    setDisabledNextButton={() => {}}
                                                    isTriage={true}
                                                />
                                            </StyledPaper>
                                        </Grid>
                                        <Section
                                            item
                                            xs={12}
                                            show={hasPics || !isStartingTriage}
                                        >
                                            <StyledPaper variant='outlined'>
                                                <TriageCheckboxesSection
                                                    values={values}
                                                    isTriageDone={
                                                        !isStartingTriage
                                                    }
                                                />
                                            </StyledPaper>
                                        </Section>
                                        <Section
                                            item
                                            xs={12}
                                            show={isStartingTriage}
                                        >
                                            <Stack
                                                direction='row-reverse'
                                                spacing={2}
                                            >
                                                <LoadingButton
                                                    loading={
                                                        isUpdatingIssueDiagnostic
                                                    }
                                                    onClick={handleSave}
                                                    label={t(
                                                        'pages.issues.create.save',
                                                    )}
                                                />
                                            </Stack>
                                        </Section>
                                    </Grid>
                                </Grid>
                                <Section item xs={12} show={true}>
                                    <StyledPaper variant='outlined'>
                                        <IssueLog />
                                    </StyledPaper>
                                </Section>
                                <Section item xs={12} show={true}>
                                    <StyledPaper variant='outlined'>
                                        <DeviceHistory />
                                    </StyledPaper>
                                </Section>
                            </Grid>
                        </form>
                    )
                }}
            />
        </>
    )
}

export default Triage
