import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '~/store/auth/selector'
import { useUpdateTeamMutation } from './mutation'
import { useUsersTeamsQuery } from '~/pages/UserDetail/query'
import CustomSelection from '~/components/CustomSelection'

export const useTeamSelection = () => {
    const user = useSelector(selectUser)
    const { data } = useUsersTeamsQuery({
        menuOption: true,
        id: user?.id,
    })
    const { mutate: updateTeam } = useUpdateTeamMutation()

    const teams = useMemo(() => data?.data ?? [], [data])
    const userInOneTeamOnly = useMemo(
        () => !!(teams && teams?.length === 1 && user?.current_team?.name),
        [teams, user?.current_team?.name],
    )

    const [selectedTeam, setSelectedTeam] = useState(
        user?.current_team?.id ?? '',
    )

    const handleChangeTeam = (event) => {
        setSelectedTeam(event.target.value)
        updateTeam({ team_id: event.target.value })
    }

    const teamSelection = userInOneTeamOnly ? (
        user.current_team.name
    ) : (
        <CustomSelection
            selected={selectedTeam}
            onChange={handleChangeTeam}
            options={teams}
        />
    )

    return { teamSelection, selectedTeam }
}

export default useTeamSelection
