import axiosClient, { axiosFormClient } from '~/api/base'

const issueApi = {
    getDeviceIssues: (serial, params) =>
        axiosClient.get('api/issues', {
            params: { ...params, serial },
        }),
    getIssues: (params) => axiosClient.get('api/issues', { params }),
    getFormFieldsOptions: () => axiosClient.get('api/issue-states'),
    getAllIssueStatus: () => axiosClient.get('api/issue-status'),
    getAllServiceCenter: () => axiosClient.get('api/issue-service-center'),
    getIssue: (id) => axiosClient.get(`api/issues/${id}`),
    createIssue: (payload) => axiosFormClient.post('api/issues', payload),
    updateIssue: (id, payload) =>
        axiosFormClient.put(`api/issues/${id}`, payload),
    scanSerialForRepair: (id, serial) =>
        axiosClient.post(`api/issues/${id}/repair/scan-serial`, { serial }),

    scanSerial: (serial) =>
        axiosClient.post('api/issues/scan-serial', { serial }),

    scanReceivedPartsForRepair: (id, payload) =>
        axiosClient.post(`api/issues/${id}/repair/part-codes/scan/old`, {
            ...payload,
        }),

    saveReceivedPartsForRepair: (id, payload) =>
        axiosClient.post(`api/issues/${id}/repair/part-codes/save`, {
            ...payload,
        }),

    scanAdvanceRepair: (id, step) =>
        axiosClient.post(`api/issues/${id}/repair/next`, { step }),

    saveRepairTechnicianComment: (id, payload) =>
        axiosClient.post(`api/issues/${id}/repair/save-comment`, {
            ...payload,
        }),

    saveRepair: (id, payload) =>
        axiosClient.post(`api/issues/${id}/repair/save`, {
            ...payload,
        }),

    scanSerialForQC: (id, serial, defaultAgreeQc) =>
        axiosClient.post(`api/issues/${id}/quality-check/scan-serial`, {
            serial,
            defaultAgreeQc,
        }),

    getQCForIssue: (issueId) =>
        axiosClient.get(`api/issues/${issueId}/quality-check`),

    updateAnswersForQc: (issueId, answers) =>
        axiosClient.put(`api/issues/${issueId}/quality-check/update-answers`, {
            answers,
        }),

    updateAnswersOverruleForQc: (issueId, payload) =>
        axiosClient.post(
            `api/issues/${issueId}/quality-check/update-overrule-answers`,
            payload,
        ),

    uploadPicturesForQc: (id, payload) =>
        axiosFormClient.post(
            `api/issues/${id}/quality-check/pictures`,
            payload,
        ),

    getIssueQcPictures: (id) =>
        axiosClient.get(`api/issues/${id}/quality-check/pictures`),

    updateQcComment: (id, comment) =>
        axiosClient.post(`api/issues/${id}/quality-check/comment`, {
            comment,
        }),

    assignSupervisorToIssue: (issueId, userId) =>
        axiosClient.post(`api/issues/${issueId}/supervisor`, {
            user_id: userId,
        }),

    transitIssueState: (issueId, payload) =>
        axiosFormClient.put(`api/issues/${issueId}/transit-state`, payload),

    cancelIssue: (issueId) => axiosClient.post(`api/issues/${issueId}/cancel`),

    createANewFromOldIssue: (issueId) =>
        axiosClient.post(`api/issues/${issueId}/create-a-new-issue`),
    supervisorCreateNewIssue: (issueId) =>
        axiosClient.post(`api/issues/${issueId}/supervisor-create-new-issue`),

    supervisorMakeDeviceIsTotalLoss: (issueId) =>
        axiosClient.post(
            `api/issues/${issueId}/supervisor-make-device-is-loss`,
        ),
    hasPictureToDispatch: (id) =>
        axiosClient.get(`api/issues/${id}/has-picture-to-dispatch`),
    cancelImageIssue: (issueId) =>
        axiosClient.put(`api/issues/${issueId}/cancel-image-issue`),
    proceedIncorrectImageIssue: (issueId, defaultAgreeQc) =>
        axiosClient.post(`api/issues/${issueId}/proceed-incorrect-image`, {
            defaultAgreeQc,
        }),
    transitionToImaging: (issueId) =>
        axiosClient.put(`api/issues/${issueId}/transition-to-imaging`),
}

export default issueApi
