export const ProductColumnConfig = [
    {
        id: 'id',
        label: 'id',
        orderable: true,
    },
    {
        id: 'sku',
        label: 'sku',
        orderable: true,
    },
    {
        id: 'name',
        label: 'name',
        orderable: true,
    },
    {
        id: 'code',
        label: 'code',
        orderable: true,
    },
    {
        id: 'brand_id',
        label: 'brand_id',
        orderable: true,
    },
    {
        id: 'created_by',
        label: 'created_by',
        orderable: true,
    },
    {
        id: 'updated_by',
        label: 'updated_by',
        orderable: true,
    },
    {
        id: 'created_at',
        label: 'created_at',
        orderable: true,
    },
]
