import {
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import { useMemo } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const StyledTableCell = styled(TableCell)({
    align: 'left',
    color: 'inherit',
})
const StyledTypography = styled(Typography)({
    noWrap: true,
    fontSize: 10,
})

const LastScanLocation = ({ row }) => {
    // const { data, isLoading } = useLastScanQuery({ ticket_id: row?.ticket_id });
    const title = useMemo(() => {
        if (!row?.lines) {
            return <></>
        }
        const content = row?.lines?.map((line, key) => {
            return (
                <TableRow key={key}>
                    <StyledTableCell>
                        <StyledTypography>{line.location}</StyledTypography>
                    </StyledTableCell>
                    <StyledTableCell>
                        <StyledTypography>
                            <DateTimeLocale datetime={line.created_at} />
                        </StyledTypography>
                    </StyledTableCell>
                </TableRow>
            )
        })
        return (
            <>
                <Typography color='inherit' align='center'>
                    Last 30 Scans
                </Typography>
                <Table size='small'>
                    <TableBody>{content}</TableBody>
                </Table>
            </>
        )
    }, [row?.lines])
    return (
        <Stack direction='row'>
            <Tooltip title={title} placement='top'>
                <Typography variant='body2' align='left'>
                    {row?.rack_scan_line.location}
                </Typography>
            </Tooltip>
        </Stack>
    )
}

LastScanLocation.propTypes = { row: PropTypes.object }

export default LastScanLocation
