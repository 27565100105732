import { Autocomplete, Box, Chip, Grid, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { setFilterObject } from '~/store/filter/slice'

const MultiFilterTable = ({ filterObject, page }) => {
    const dispatch = useDispatch()

    const onChange = useCallback(
        (e, newValues) => {
            const newValue =
                newValues?.length > 0
                    ? newValues[newValues.length - 1].id
                    : null

            if (!newValue) return
            let values = filterObject?.value || []
            if (!values.includes(newValue)) {
                values = [...values, newValue]
            }

            dispatch(
                setFilterObject({
                    page,
                    filter: {
                        name: filterObject.label,
                        value: values,
                    },
                }),
            )
            filterObject.handleFunc(values)
        },
        [filterObject, dispatch, page],
    )

    const selectedItems = useMemo(() => {
        if (Array.isArray(filterObject?.value)) {
            return filterObject?.dataSet.filter((item) =>
                filterObject?.value?.includes(item.id),
            )
        } else {
            return []
        }
    }, [filterObject])

    const handleDelete = (indexToDelete) => {
        const newValues = selectedItems.filter(
            (item, index) => index !== indexToDelete,
        )
        const newIds = newValues.map((item) => item.id)
        dispatch(
            setFilterObject({
                page,
                filter: {
                    name: filterObject.label,
                    value: newIds,
                },
            }),
        )
        filterObject.handleFunc(newIds)
    }

    return (
        <Grid item xs={12} sm={filterObject?.sm || 2}>
            <Autocomplete
                multiple
                sx={{
                    mt: 1,
                }}
                id='multi-select-autocomplete'
                options={filterObject?.dataSet || []}
                value={selectedItems}
                getOptionLabel={(option) => option?.name ?? ''}
                renderInput={(params) => (
                    <TextField {...params} variant='outlined' />
                )}
                disableClearable
                onChange={onChange}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 0.5,
                                }}
                                key={option.id}
                            >
                                <Chip
                                    variant='outlined'
                                    label={option.name}
                                    onDelete={() => handleDelete(index)}
                                />
                            </Box>
                        )
                    })
                }
            />
        </Grid>
    )
}

MultiFilterTable.propTypes = {
    filterObject: PropTypes.object.isRequired,
    page: PropTypes.string,
}

export default MultiFilterTable
