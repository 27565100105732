import { useMutation, useQueryClient } from 'react-query'
import testDataApi from '~/api/testDataApi'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useCreatingShipmentForIssueTesting = () => {
    return useMutation(
        ['creating_shipment_for_issue'],
        async ({ issueId, trackingNumber, date }) => {
            return await testDataApi.createShipmentForIssue(issueId, {
                trackingNumber,
                date,
            })
        },
    )
}

export const useApproveQuotation = () => {
    return useMutation(
        ['approve_quotation'],
        async ({ issueId, quotation_approved }) => {
            return await testDataApi.approveQuotation(issueId, {
                quotation_approved,
            })
        },
    )
}

export const useTransitBomStateToWmsOrderMutate = (showSnackbar) => {
    const queryClient = useQueryClient()
    return useMutation(
        ['transit_bom_state_to_wms_order'],
        async ({ issueId }) => {
            return await testDataApi.transitBomStateToWmsOrder(issueId)
        },
        {
            onSuccess: (data) => {
                showSnackbar(
                    data?.message
                        ? data.message
                        : 'Transit bom state successfull',
                    'success',
                )
                queryClient.invalidateQueries(['test-data-issues'])
            },
            onError: (error) => {
                showSnackbar(parseApiErrorMessage(error), 'error')
            },
            retry: false,
        },
    )
}
