import PropTypes from 'prop-types'
import { Stack, Typography } from '@mui/material'
import { LoadingButton } from '~/components/Button'
import StyledModal from '~/components/StyledModal'
import { useTranslation } from 'react-i18next'
import {
    useRemoveTeamMutation,
    useSwitchActiveTeamMutation,
} from '~/pages/UserDetail/mutate'

const localization = 'pages.user.userDetail'

export const ActionTeamConfirm = ({
    open,
    onClose,
    userId,
    team,
    forDeleting = false,
}) => {
    const { t } = useTranslation()
    const confirmMsg = forDeleting
        ? t(`${localization}.confirmRemoveTeam`, {
              team: team.name,
          })
        : t(`${localization}.confirmActiveTeam`, {
              team: team.name,
          })

    const { mutate: activeTeam, isLoading: isSwitching } =
        useSwitchActiveTeamMutation()
    const { mutate: removeTeam, isLoading: isRemoving } =
        useRemoveTeamMutation()
    const doAction = () => {
        if (forDeleting) {
            removeTeam(
                { user_id: userId, team_id: team.id },
                {
                    onSettled: onClose,
                },
            )
        } else {
            activeTeam(
                { user_id: userId, team_id: team.id },
                {
                    onSettled: onClose,
                },
            )
        }
    }

    return (
        <StyledModal
            open={open}
            handleClose={onClose}
            stackProps={{
                sx: { minWidth: 550 },
            }}
        >
            <Typography align='center'>{confirmMsg}</Typography>
            <Stack direction='row' gap={2} sx={{ marginTop: '20px' }}>
                <LoadingButton
                    label={t('button.yes')}
                    onClick={doAction}
                    loading={isSwitching || isRemoving}
                />
                <LoadingButton
                    bgColor='#102F44'
                    label={t('button.no')}
                    onClick={onClose}
                    loading={isSwitching || isRemoving}
                />
            </Stack>
        </StyledModal>
    )
}

ActionTeamConfirm.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    userId: PropTypes.number,
    team: PropTypes.object,
    forDeleting: PropTypes.bool,
}

ActionTeamConfirm.defaultProps = {
    forDeleting: false,
}
