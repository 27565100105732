import { FormControl, Grid } from '@mui/material'
import InputField from '~/components/FinalForm/InputField'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import { useTranslation } from 'react-i18next'

import SelectField from '~/components/FinalForm/SelectField'
import { ADMINISTRATOR_DEFINITION_REGIONS_NEW } from '~/constants/Routes'
import { Field } from 'react-final-form'
import useRegionForm from '../hooks/useRegionForm'
import { useMemo } from 'react'
import PropTypes from 'prop-types'

const pagePath = ADMINISTRATOR_DEFINITION_REGIONS_NEW
function Body({ localization }) {
    const { t } = useTranslation()

    const { InputFieldGroup, selectGroup } = useRegionForm()

    const createSelectGroup = useMemo(() => {
        return selectGroup.filter(
            (item) => item.name !== 'main_service_center_id',
        )
    }, [selectGroup])

    return (
        <FormBodyWrapper>
            <Grid container spacing={2}>
                {InputFieldGroup.map((item, idx) => (
                    <Grid item xs={12} key={idx}>
                        <InputField
                            localization={localization}
                            name={item.name}
                            componentId={`${item.componentId}`}
                            pagePath={pagePath}
                            isRequired={item.isRequired}
                            placeholder={t(`${localization}.${item.name}`)}
                        />
                    </Grid>
                ))}
                {createSelectGroup.map((item, index) => {
                    return (
                        <Grid item xs={12} key={index}>
                            <FormControl sx={{ width: '100%' }}>
                                <Field
                                    name={item.name}
                                    component={SelectField}
                                    items={item.listResource}
                                    localization={localization}
                                    componentId={`${item.componentId}`}
                                    pagePath={pagePath}
                                    nameSelect={item.title}
                                    isRequired={item.required}
                                />
                            </FormControl>
                        </Grid>
                    )
                })}
            </Grid>
        </FormBodyWrapper>
    )
}

Body.propTypes = { localization: PropTypes.string }

export default Body
