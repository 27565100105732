export const TicketHeadCells = [
    {
        id: 'ticket_id',
        label: 'ticket',
        orderable: true,
    },
    {
        id: 'issue_id',
        label: 'issue',
    },
    {
        id: 'location',
        label: 'esdLocation',
        orderable: true,
    },
    {
        id: 'last_scan_location',
        label: 'lastScanLocation',
        orderable: true,
    },
    {
        id: 'scanned_at',
        label: 'lastScan',
        orderable: true,
    },
    {
        id: 'status_label',
        label: 'ticketStatus',
        orderable: true,
    },
    {
        id: 'state',
        label: 'state',
        orderable: true,
    },
]
