import PropTypes from 'prop-types'
import { Box, Grid, Toolbar as MuiToolbar } from '@mui/material'
import SearchToolBar from './SearchToolBar'

const Toolbar = ({ filterSearch, onFilterSearch, onEnterSearch }) => {
    return (
        <MuiToolbar
            sx={{
                bgcolor: '#f4f4f4',
            }}
        >
            <Grid container spacing={0.5}>
                <Grid
                    item
                    xs={12}
                    sm={10}
                    sx={{ display: { xs: 'none', sm: 'flex' } }}
                >
                    <Box sx={{ flexGrow: 1 }} />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <SearchToolBar
                        filterSearch={filterSearch}
                        onFilterSearch={onFilterSearch}
                        onEnterSearch={onEnterSearch}
                    />
                </Grid>
            </Grid>
        </MuiToolbar>
    )
}
Toolbar.propTypes = {
    filterSearch: PropTypes.any,
    onFilterSearch: PropTypes.func.isRequired,
    onEnterSearch: PropTypes.func.isRequired,
}
export default Toolbar
