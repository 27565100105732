import deviceApi from '~/api/deviceApi'
import { useQuery } from 'react-query'
import { removeEmptyValue } from '~/utils/helpers'

export const useFleetOverviewQuery = (params) => {
    return useQuery(
        ['device_fleet_overview', params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await deviceApi.getFleetOverview(p)

            return { data: data.data, total: data.meta.total }
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}
