import { useTranslation } from 'react-i18next'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_DEFINITION,
    ADMINISTRATOR_DEFINITION_WORKBENCH,
    ADMINISTRATOR_DEFINITION_WORKBENCH_DETAIL,
} from '~/constants/Routes'
import { useParams } from 'react-router-dom'
import Loading from '~/components/Loading'
import { useMemo } from 'react'
import { Form } from 'react-final-form'
import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import { useGetWorkbenchDetailQuery } from './query'
import ComponentTooltip from '~/components/ComponentTooltip'
import FormToolbar from '~/components/FinalForm/FormToolbar'
import { CloseButton } from '~/components/CancelButton'
import { LoadingButton } from '~/components/Button'
import useWorkbenchFormData from './hooks/useWorkbenchFormData'
import useActions from './hooks/useActions'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import WorkbenchForm from './WorkbenchForm'
import { Typography } from '@mui/material'
import useFormValidate from './hooks/useFormValidate'
import ToolBarButton from '~/components/ToolBarButton'

const WorkbenchDetail = () => {
    const { localization } = useWorkbenchFormData()
    const validate = useFormValidate()
    const { id } = useParams()
    const { t } = useTranslation()
    const {
        saveHandler,
        deleteHandler,
        isLoading: isLoadingAction,
    } = useActions()

    const { data: workbenchData, isLoading } = useGetWorkbenchDetailQuery(id)

    const workbench = useMemo(() => {
        return workbenchData || []
    }, [workbenchData])
    if (isLoading) {
        return <Loading />
    }

    const BreadComponents = [
        { route: ADMINISTRATOR, title: t(`breadCrumb.Administrator`) },
        {
            route: ADMINISTRATOR_DEFINITION,
            title: 'Definitions',
        },
        {
            route: ADMINISTRATOR_DEFINITION_WORKBENCH,
            title: 'workbenches',
        },
        {
            page: 'detail',
            title: id,
        },
    ]
    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_DEFINITION_WORKBENCH_DETAIL}
            >
                <div>
                    <PageName name={`Edit Workbench`} />
                </div>
            </ComponentTooltip>
            <Form
                validate={validate}
                onSubmit={saveHandler}
                initialValues={workbench}
                render={({ handleSubmit, form, values, dirty }) => (
                    <form onSubmit={handleSubmit}>
                        <FormToolbar>
                            <ToolBarButton
                                color='error'
                                handleAction={deleteHandler}
                                name={t(`button.delete`)}
                            />
                            <CloseButton
                                link={ADMINISTRATOR_DEFINITION_WORKBENCH}
                            />
                            <LoadingButton
                                disabled={!dirty}
                                label={t(`${localization}.save`)}
                                type={`submit`}
                                loading={isLoadingAction}
                            />
                        </FormToolbar>
                        <FormBodyWrapper>
                            {workbench ? (
                                <WorkbenchForm />
                            ) : (
                                <Typography>
                                    {t(`message.data_not_found`)}
                                </Typography>
                            )}
                        </FormBodyWrapper>
                    </form>
                )}
            />
        </>
    )
}
export default WorkbenchDetail
