import { List } from '@mui/material'
import MenuItem from '~/components/layout/SidebarMenu/MenuItem'
import { menuItems } from '~/components/layout/SidebarMenu/MenuItems/menuItems'

const Menu = () => {
    return (
        <List>
            {menuItems.map((i, index) => (
                <MenuItem key={index} item={i} />
            ))}
        </List>
    )
}

export default Menu
