import useQuery from '~/hooks/useQuery'
import issueApi from '~/api/issueApi'
import { parseApiErrorMessage, removeEmptyValue } from '~/utils/helpers'
import diagnosticApi from '~/api/diagnosticApi'
import { DEFAULT_CACHE_TTL } from '~/utils/constants'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { useNavigate } from 'react-router-dom'
import { ISSUE_ISSUE } from '~/constants/Routes'

export const useIssuesQuery = (params) => {
    const { openSnackbar } = useDialogContext()
    return useQuery(
        ['issues', params],
        async () => {
            const cleanParams = removeEmptyValue(params)
            if (typeof cleanParams.orderBy === 'undefined') {
                cleanParams.orderBy = 'id'
            }
            const { data } = await issueApi.getIssues(cleanParams)
            return { data: data.data, total: data.meta.total }
        },
        {
            retry: false,
            onError: (error) => {
                openSnackbar({
                    message: parseApiErrorMessage(error),
                    type: 'error',
                })
            },
        },
    )
}
export const useIssueQuery = (id, isConfirmForm = false) => {
    const { openSnackbar } = useDialogContext()
    const navigate = useNavigate()
    return useQuery(
        isConfirmForm ? ['issue_for_qc_form', id] : ['issue', id],
        async () => {
            const { data } = await issueApi.getIssue(id)
            return data
        },
        {
            onError: (error) => {
                openSnackbar({
                    message: parseApiErrorMessage(error),
                    type: 'error',
                })
                navigate(ISSUE_ISSUE)
            },
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retry: false,
        },
    )
}

export const useFormFieldsOptionsQuery = () => {
    return useQuery(['issue', 'get_form_fields_options'], async () => {
        const { data } = await issueApi.getFormFieldsOptions()
        return data
    })
}

export const useIssuePartCodesQuery = (issueId) => {
    return useQuery(['issue', 'get_issue_part_codes'], async () => {
        const { data } = await diagnosticApi.getPartIssueDiagnostic(issueId)
        return data.data
    })
}

export const useGetAllIssueStatusQuery = () => {
    return useQuery(['get_all_issue_status'], async () => {
        const { data } = await issueApi.getAllIssueStatus()
        return data
    })
}

export const useGetAllServiceCenterQuery = () => {
    return useQuery(['get_all_service_center'], async () => {
        const { data } = await issueApi.getAllServiceCenter()
        return data
    })
}

export const useIssueHasPictureToDispatch = (id) => {
    return useQuery(
        ['has_picture_to_dispatch', id],
        async () => {
            const { data } = await issueApi.hasPictureToDispatch(id)
            return data
        },
        {
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
        },
    )
}
export const useRefetchIssueQuery = (id) => {
    return useQuery(['issue_refetch', id], async () => {
        const { data } = await issueApi.getIssue(id)
        return data
    })
}
