import Stack from '@mui/material/Stack'
import { useMemo } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import Proptypes from 'prop-types'

function FieldWrapper({
    children,
    title,
    isRequired = false,
    tooltip = <></>,
}) {
    const titleComponent = useMemo(() => {
        if (isRequired) {
            return (
                <Stack
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='center'
                    spacing={2}
                >
                    <Typography>
                        {title}
                        <Box
                            component={'span'}
                            sx={{
                                color: (theme) => theme.palette.error.light,
                            }}
                        >
                            {' *'}
                        </Box>
                    </Typography>
                    {tooltip}
                </Stack>
            )
        }

        return (
            <Stack
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
                spacing={2}
            >
                <Typography>{title}</Typography>
                {tooltip}
            </Stack>
        )
    }, [isRequired, title, tooltip])
    return (
        <>
            <Grid item xs={12}>
                {titleComponent}
                {children}
            </Grid>
        </>
    )
}

FieldWrapper.propTypes = {
    children: Proptypes.element,
    title: Proptypes.string,
    isRequired: Proptypes.bool,
    tooltip: Proptypes.element,
}

export default FieldWrapper
