import styled from '@emotion/styled'
import { TableRow } from '@mui/material'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    td: {
        border: '0.1px solid lightgrey',
    },
}))

export default StyledTableRow
