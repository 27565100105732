import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Divider, Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Search from '~/components/Search/Search'
import StyledModal from '~/components/StyledModal'
import { parseApiErrorMessage } from '~/utils/helpers'
import DeleteOrderLine from '../DeleteOrderLine'
import { useImportOrderLineMutation } from '../mutation'
import beepOK from '~/assets/sound/beep-ok.wav'
import beepError from '~/assets/sound/beep-error.wav'
import { useQueryClient } from 'react-query'
import ErrorMessageInput from '~/components/ErrorMessageInput'

const ScanSerial = ({ setOpen, data, orderLineQty }) => {
    const { t } = useTranslation()
    const [searchInputs, setSearchInputs] = useState([
        { value: null, error: null, bgColor: '#00c04f' },
    ])
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
    const [serialDelete, setSerialDelete] = useState(null)
    const [serialSeleted, setSerialSeleted] = useState(null)
    const searchBarRefs = useRef([])
    const clientQuery = useQueryClient()

    const audioOK = useMemo(() => new Audio(beepOK), [])
    const audioError = useMemo(() => new Audio(beepError), [])
    const { mutate: importOrderLine } = useImportOrderLineMutation()

    const handleCloseScanSerial = () => {
        setOpen(false)
    }

    const handleEnterSearch = (event, index) => {
        const serial = event.target.value
        if (event.key !== 'Enter' || !serial || serial === '') return

        const isReady = serial.toUpperCase() === 'READY'
        const isDeleteLast = serial.toUpperCase() === 'DELETE_LAST'

        if (isReady) {
            handleCloseScanSerial()
            return
        }

        const updatedSearchInputs = [...searchInputs]
        importOrderLine(
            { serial, production_order_nr: data.production_order_nr },
            {
                onSuccess: () => {
                    updateSearchInput(
                        updatedSearchInputs,
                        serial,
                        index,
                        '#008631',
                        'completed',
                    )
                    audioOK.play()
                },
                onError: (e) => {
                    updateSearchInput(
                        updatedSearchInputs,
                        serial,
                        index,
                        '#c30010',
                        'error',
                        parseApiErrorMessage(e),
                    )
                    audioError.play()
                    if (isDeleteLast) {
                        // THIS CASE parseApiErrorMessage(e) WILL RETURN LAST SERIAL DELETED
                        setSerialSeleted(parseApiErrorMessage(e))
                        clientQuery.invalidateQueries('production-order')
                        clientQuery.invalidateQueries('production-order-line')
                    }
                },
            },
        )

        if (data.qty > orderLineQty) {
            updatedSearchInputs.push({
                value: null,
                error: null,
                bgColor: '',
                type: null,
            })
            setSearchInputs(updatedSearchInputs)
        }
    }

    const updateSearchInput = (
        updatedSearchInputs,
        value,
        index,
        bgColor,
        type,
        error = null,
    ) => {
        updatedSearchInputs[index] = { value, error, bgColor, type }
    }

    const deleteOrderLine = (serial) => {
        setOpenDeleteConfirm(true)
        setSerialDelete(serial)
        setSerialSeleted(null)
    }

    const handleClose = () => {
        setOpenDeleteConfirm(false)
    }

    useEffect(() => {
        if (serialSeleted !== null) {
            const serials =
                searchInputs.length > 0
                    ? searchInputs.filter((x) => x.value !== serialSeleted)
                    : []
            setSearchInputs(serials)
            setSerialSeleted(null)
        }
    }, [searchInputs, serialSeleted])

    const renderSearchInputs = () =>
        searchInputs.map((searchInput, index) => (
            <Grid item xs={12} md={3} key={index}>
                {searchInput.type ? (
                    <>
                        <Box
                            sx={{
                                border: '1px solid',
                                borderRadius: '50px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '55px',
                                backgroundColor: searchInput.bgColor,
                                color: '#fff',
                            }}
                        >
                            {searchInput.value}
                            <DeleteIcon
                                sx={{ marginLeft: '10px', cursor: 'pointer' }}
                                onClick={() =>
                                    deleteOrderLine(searchInput.value)
                                }
                            />
                        </Box>
                        <ErrorMessageInput>
                            {searchInput.error}
                        </ErrorMessageInput>
                    </>
                ) : (
                    <Search
                        autoFocus
                        ref={(ref) => (searchBarRefs.current[index] = ref)}
                        label={t('pages.production.orders.scanSerial')}
                        value={searchInput.value}
                        error={searchInput.error}
                        onKeyPress={(event) => handleEnterSearch(event, index)}
                    />
                )}
            </Grid>
        ))

    return (
        <>
            <StyledModal
                open={open}
                handleClose={() => handleCloseScanSerial()}
                stackStyles={{ width: 'auto' }}
                stackProps={{
                    width: '750px',
                }}
            >
                <Typography
                    variant='h5'
                    textAlign='center'
                    sx={{ marginBottom: '20px' }}
                >
                    {t('pages.production.orders.scanSerial')}
                    <Box sx={{ fontSize: '20px' }}>
                        ({orderLineQty}/{data?.qty})
                    </Box>
                </Typography>
                <Divider />
                <Grid container spacing={2}>
                    {data?.qty > orderLineQty && renderSearchInputs()}
                </Grid>
            </StyledModal>
            {openDeleteConfirm && (
                <DeleteOrderLine
                    openDeleteConfirm={openDeleteConfirm}
                    handleClose={handleClose}
                    serialDelete={serialDelete}
                    orderId={data?.id}
                    setSerialSeleted={setSerialSeleted}
                />
            )}
        </>
    )
}

ScanSerial.propTypes = {
    setOpen: PropTypes.func,
    data: PropTypes.object,
    orderLineQty: PropTypes.number,
}

export default ScanSerial
