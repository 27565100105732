import PropTypes from 'prop-types'
import TableFilters from '~/components/TableFilters'
import { useMemo } from 'react'
import {
    useBomRegionsQuery,
    useBomServiceCentersQuery,
    useBomStateQuery,
} from '~/pages/BOM/query'
import { titleize } from '~/utils/helpers'

const BomTableFilters = ({
    search,
    searchHandler,
    state,
    setState,
    region,
    setRegion,
    serviceCenter,
    setServiceCenter,
    onEnterSearch,
}) => {
    const { data: stateData, isSuccess: isSuccessState } = useBomStateQuery()
    const { data: serviceCentersData, isSuccess: isSuccessServiceCenter } =
        useBomServiceCentersQuery()
    const { data: regionsData, isSuccess: isSuccessRegions } =
        useBomRegionsQuery()

    const states = useMemo(() => {
        if (isSuccessState && stateData?.states.length > 0) {
            const result = stateData.states.map((state) => ({
                id: state,
                name: titleize(state.toLowerCase().replace('_', ' ')),
            }))
            result.unshift({ id: '', name: 'ALL' })
            return result
        }
        return [{ id: '', name: 'ALL' }]
    }, [isSuccessState, stateData])

    const serviceCenters = useMemo(() => {
        if (isSuccessServiceCenter && serviceCentersData?.data?.length > 0) {
            return [{ id: '', name: 'ALL' }, ...serviceCentersData.data]
        }
        return [{ id: '', name: 'ALL' }]
    }, [isSuccessServiceCenter, serviceCentersData])

    const regions = useMemo(() => {
        if (isSuccessRegions && regionsData?.data?.length > 0) {
            return [{ id: '', name: 'ALL' }, ...regionsData.data]
        }
        return [{ id: '', name: 'ALL' }]
    }, [isSuccessRegions, regionsData])

    const selectGroup = useMemo(() => {
        return [
            {
                label: 'State',
                handleFunc: setState,
                dataSet: states,
                value: state,
            },
            {
                label: 'Region',
                handleFunc: setRegion,
                dataSet: regions,
                value: region,
            },
            {
                label: 'Service Center',
                handleFunc: setServiceCenter,
                dataSet: serviceCenters,
                value: serviceCenter,
            },
        ]
    }, [
        setState,
        states,
        state,
        setRegion,
        regions,
        region,
        setServiceCenter,
        serviceCenters,
        serviceCenter,
    ])
    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='boms'
            page='boms'
        />
    )
}

BomTableFilters.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    state: PropTypes.string,
    setState: PropTypes.func,
    region: PropTypes.string,
    setRegion: PropTypes.func,
    serviceCenter: PropTypes.string,
    setServiceCenter: PropTypes.func,
    onEnterSearch: PropTypes.func,
}

export default BomTableFilters
