import { Switch } from '@mui/material'
import PropTypes from 'prop-types'

const ToggleField = ({ input: { onChange, value }, label, ...props }) => (
    <Switch
        label={label}
        checked={!!value}
        onChange={(event, isInputChecked) => onChange(isInputChecked)}
        {...props}
    />
)
ToggleField.propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
}

export default ToggleField
