import { Box, Grid } from '@mui/material'
import Tablet from '~/components/Tablet'
import PropTypes from 'prop-types'

const DispatchWorkOrderInfo = ({ t = (string) => string, dispatch }) => {
    return (
        <Box sx={{ px: 2, py: 1 }}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    {t(`Customer`)}
                </Grid>
                <Grid item xs={6}>
                    {dispatch?.branch?.customer_name}
                </Grid>
                <Grid item xs={6}>
                    {t(`Group`)}
                </Grid>
                <Grid item xs={6}>
                    N/A
                </Grid>
                <Grid item xs={6}>
                    {t(`Technician`)}
                </Grid>
                <Grid item xs={6}>
                    {dispatch?.contact?.name}
                </Grid>
                <Grid item xs={6}>
                    {t(`ServiceLevelAgreement`)}
                </Grid>
                <Grid item xs={6}>
                    N/A
                </Grid>
                <Grid item xs={6}>
                    {t(`Entitlement`)}
                </Grid>
                <Grid item xs={6}>
                    {dispatch?.device?.entitlements.map(
                        (entitlement, index) => (
                            <Grid item key={index}>
                                <Tablet sx={{ width: 'fit-content' }}>
                                    {entitlement.name}
                                </Tablet>
                            </Grid>
                        ),
                    )}
                </Grid>
                <Grid item xs={6}>
                    {t(`CustomerIdentifiedDamage`)}
                </Grid>
                <Grid item xs={6}>
                    No
                </Grid>
            </Grid>
        </Box>
    )
}

DispatchWorkOrderInfo.propTypes = {
    t: PropTypes.func,
    dispatch: PropTypes.object,
}

export default DispatchWorkOrderInfo
