import { Grid } from '@mui/material'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import { Field } from 'react-final-form'
import { ISSUE_RACKSCAN_SCAN_NEW } from '~/constants/Routes'
import SelectField from '~/components/FinalForm/SelectField'
import InputField from '~/components/FinalForm/InputField'
import PropTypes from 'prop-types'

const pagePath = ISSUE_RACKSCAN_SCAN_NEW

function Body({ localization, serviceCenterList, teamList, handleChangeTeam }) {
    return (
        <FormBodyWrapper>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputField
                        localization={localization}
                        name='name'
                        componentId={`scan_create_name`}
                        pagePath={pagePath}
                        isRequired={true}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Field
                        name='team_id'
                        component={SelectField}
                        items={teamList}
                        localization={localization}
                        onUpdate={handleChangeTeam}
                        navigateData={{
                            componentId: `scan_create_team`,
                        }}
                        pagePath={pagePath}
                        nameSelect='team'
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        name='service_center_id'
                        component={SelectField}
                        items={serviceCenterList}
                        localization={localization}
                        nameSelect='serviceCenter'
                        isRequired={true}
                        navigateData={{
                            componentId: `scan_create_service_center`,
                        }}
                        pagePath={pagePath}
                    />
                </Grid>
            </Grid>
        </FormBodyWrapper>
    )
}

Body.propTypes = {
    localization: PropTypes.string,
    serviceCenterList: PropTypes.array,
    teamList: PropTypes.array,
    handleChangeTeam: PropTypes.func,
}

export default Body
