import { createSlice } from '@reduxjs/toolkit'

export const accordionSlice = createSlice({
    name: 'accordion',
    initialState: {
        accordion: null,
    },
    reducers: {
        setAccordion: (state, action) => {
            state.accordion = action.payload
        },
    },
})

export const { setAccordion } = accordionSlice.actions

export default accordionSlice
