import ScrollToTop from 'react-scroll-up'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'

const ScrollUpButton = () => {
    return (
        <ScrollToTop showUnder={160} style={{ bottom: 0 }}>
            <ArrowCircleUpIcon fontSize='large' sx={{ color: '#76B72A' }} />
        </ScrollToTop>
    )
}

export default ScrollUpButton
