import { TableCell as MuiTableCell } from '@mui/material'
import useStyles from '../hooks/useStyles'
import PropTypes from 'prop-types'

const TableCell = ({ children, ...props }) => {
    const { classes } = useStyles()

    return (
        <MuiTableCell className={classes.tableCell} {...props} size='small'>
            {children}
        </MuiTableCell>
    )
}

TableCell.propTypes = { children: PropTypes.any }

export default TableCell
