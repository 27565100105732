import { FormControl, List, ListItem, ListItemText } from '@mui/material'
import { useCallback, useState } from 'react'
import { Field } from 'react-final-form'
import { truncateFileName } from '~/utils/helpers'
import PropTypes from 'prop-types'

const documentExtension = [
    '.doc',
    '.docx',
    '.xml',
    '.pdf',
    '.csv',
    '.xls',
    '.xlsx',
    '.txt',
]

const imageExtension = ['.png', '.jpg', '.gif', '.bmp', '.webp']

const InputFile = ({ form, nameField, fileType }) => {
    const [fileList, setFileList] = useState()
    const changeHandler = useCallback(
        (event) => {
            setFileList(event.target.files)
            form.change(nameField, event.target.files)
        },
        [form, nameField],
    )
    const files = fileList ? [...fileList] : []

    return (
        <FormControl sx={{ width: '100%' }}>
            <Field>
                {() => (
                    <input
                        id='file-import'
                        name={nameField}
                        type='file'
                        onChange={changeHandler}
                        multiple
                        accept={
                            fileType === 'document'
                                ? [...documentExtension]
                                : [...imageExtension]
                        }
                    />
                )}
            </Field>
            <List
                sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: 'background.paper',
                }}
                dense={true}
            >
                {files.map((file, i) => (
                    <ListItem alignItems='flex-start' key={file.name}>
                        <ListItemText primary={truncateFileName(file.name)} />
                    </ListItem>
                ))}
            </List>
        </FormControl>
    )
}

InputFile.propTypes = {
    form: PropTypes.object,
    nameField: PropTypes.string,
    fileType: PropTypes.string,
}

export default InputFile
