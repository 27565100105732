import { CircularProgress, TableBody, TableCell, TableRow } from '@mui/material'
import PropTypes from 'prop-types'

const TableLoading = ({ colSpan, height = null }) => {
    return (
        <TableBody>
            <TableRow>
                <TableCell
                    align='center'
                    colSpan={colSpan}
                    sx={{ py: 3, height: height ?? 'auto' }}
                >
                    <CircularProgress />
                </TableCell>
            </TableRow>
        </TableBody>
    )
}

TableLoading.propTypes = {
    colSpan: PropTypes.number,
    height: PropTypes.string,
}

export default TableLoading
