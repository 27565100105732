import axiosClient from '~/api/base'

const ticketApi = {
    getAllTickets: (params) => axiosClient.get('api/tickets', { params }),
    getTicketById: (id) => axiosClient.get(`api/tickets/${id}`),
    getAllDispatchPictureById: (ticketId) =>
        axiosClient.get(`api/tickets/${ticketId}/dispatch-pictures`),
    getTicketStates: () => axiosClient.get('api/ticket-states'),
    getTicketStatus: () => axiosClient.get('api/ticket-status'),
}

export default ticketApi
