import { Box, Typography } from '@mui/material'
import ImageList from '~/components/Image/ImageList'
import Loading from '~/components/Loading'
import { usePictureDispatchQuery } from '~/pages/Ticket/query'
import { useMemo } from 'react'
import PropTypes from 'prop-types'

const DispatchAttachments = ({ ticketId }) => {
    const { data, isLoading } = usePictureDispatchQuery(ticketId)

    const currentImageList = useMemo(() => {
        return (
            data?.data.map((v) => {
                const title = `Diagnostic Issue ID: ${v.pivot.issue_id}`
                return {
                    src: v.preview_url,
                    id: v.id,
                    title,
                    created_at: v.created_at,
                }
            }) || []
        )
    }, [data?.data])

    if (isLoading) {
        return <Loading />
    }

    return (
        <Box sx={{ px: 2, py: 1 }}>
            {currentImageList.length > 0 ? (
                <ImageList images={currentImageList} showRemoveImage={false} />
            ) : (
                <Typography variant='h6'>NO ATTACHMENTS FOUND</Typography>
            )}
        </Box>
    )
}

DispatchAttachments.propTypes = { ticketId: PropTypes.number }

export default DispatchAttachments
