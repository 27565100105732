import { ISSUE_BOM } from '~/constants/Routes'
import { useParams } from 'react-router-dom'
import BreadCrumb from '~/components/BreadCrumb'
import Loading from '~/components/Loading'
import BomDetail from '~/pages/BOM/Detail/BomDetail'
import { useIssueQuery } from '~/pages/Issue/query'
import { useTranslation } from 'react-i18next'
import usePermissions from '~/hooks/usePermission'

const BOMPage = () => {
    const { t } = useTranslation()
    const { issueId } = useParams()
    const { data: issue, isLoading } = useIssueQuery(issueId)
    const { permissions } = usePermissions()
    const hasEditBomRight = permissions.issue.bom.canEditBom

    const breadCrumbConfig = [
        { route: ISSUE_BOM, title: 'BOM' },
        { page: 'detail', title: issueId },
    ]

    if (isLoading) {
        return <Loading />
    }

    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            {hasEditBomRight ? (
                <BomDetail {...{ issue }} />
            ) : (
                <h2>{t(`message.you_do_not_have_permission`)}</h2>
            )}
        </>
    )
}
export default BOMPage
