import { DeviceReplacePartHeadCells } from '~/components/HeaderTable/DeviceReplacePartHeadCells'
import CustomTable from '~/components/Table'
import { useDeviceReplaceablePartQuery } from './query'
import PropTypes from 'prop-types'

const localization = 'pages.device.serial.detail.'

const DeviceReplaceablePart = ({ device }) => {
    return (
        <CustomTable
            id={device?.id.toString()}
            customQuery={useDeviceReplaceablePartQuery}
            headCells={DeviceReplacePartHeadCells}
            LOCALIZATION={localization}
        />
    )
}

DeviceReplaceablePart.propTypes = {
    device: PropTypes.object,
}

export default DeviceReplaceablePart
