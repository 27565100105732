import { makeStyles } from 'tss-react/mui'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import LaunchIcon from '@mui/icons-material/Launch'
import QrCodeIcon from '@mui/icons-material/QrCode'
import {
    Avatar,
    Box,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Typography,
} from '@mui/material'
import { CloseButton } from '~/components/CancelButton'
import useCameraSelection from '~/hooks/useCameraSelection'
import { Html5Qrcode } from 'html5-qrcode'
import { useCallback, useEffect, useState } from 'react'
import { useDeviceDetailSearchQuery } from '../Device/Serial/SerialDetail/query'
import './index.scss'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles()((theme) => ({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const CameraScanner = ({ setOpenCamera }) => {
    const style = useStyles()
    const [decodedTextList, setDecodedTextList] = useState([])
    const { cameraSelection, selectedCamera } = useCameraSelection()
    const [availableCamera, setAvailableCamera] = useState(true)
    const { t } = useTranslation()

    const [serial, setSerial] = useState('')
    const { isLoading } = useDeviceDetailSearchQuery(
        serial,
        setSerial,
        setOpenCamera,
    )

    const handleAccess = useCallback((code) => {
        setSerial(code)
    }, [])

    useEffect(() => {
        if (selectedCamera) {
            navigator.mediaDevices
                .getUserMedia({ video: { deviceId: selectedCamera } })
                .then((stream) => {
                    setAvailableCamera(true)
                })
                .catch(() => {
                    setAvailableCamera(false)
                })
        }
    }, [selectedCamera, setAvailableCamera])

    useEffect(() => {
        if (availableCamera) {
            const html5QrCode = new Html5Qrcode('code-reader')
            let isScannerStarted = false
            const startScanner = async () => {
                try {
                    await html5QrCode.start(
                        {
                            deviceId: selectedCamera,
                        },
                        { fps: 10, qrbox: { width: 800, height: 400 } },
                        (decodedText) => {
                            setDecodedTextList((prev) => {
                                if (!prev.includes(decodedText)) {
                                    return [decodedText, ...prev]
                                }
                                return prev
                            })
                        },
                    )
                    isScannerStarted = true
                } catch (error) {
                    console.error('Error starting the scanner:', error)
                }
            }
            startScanner()

            return () => {
                if (isScannerStarted) {
                    html5QrCode.stop().catch((error) => {
                        console.error('Error stopping the scanner:', error)
                    })
                }
            }
        }
    }, [availableCamera, selectedCamera])

    return (
        <Grid container spacing={1} className={style.container}>
            <Grid item xs={12}>
                {availableCamera ? (
                    <Box id='code-reader' />
                ) : (
                    <Box
                        width={900}
                        height={500}
                        sx={{ background: 'black' }}
                    />
                )}
            </Grid>

            <Grid item pl={1}>
                <Grid
                    container
                    direction='row'
                    justifyContent='flex-end'
                    alignItems='center'
                >
                    <Grid item>
                        <Typography>{t(`message.selectCamera`)}:</Typography>
                    </Grid>
                    <Grid item>{cameraSelection}</Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Paper variant='outlined'>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        mt={1}
                        mb={1}
                    >
                        <Grid item pl={1}>
                            <Typography>
                                Detected Codes ({decodedTextList.length})
                            </Typography>
                        </Grid>
                        <Grid item mr={1}>
                            <CloseButton
                                onClick={() => {
                                    setOpenCamera(false)
                                }}
                            />
                        </Grid>
                    </Grid>
                    {decodedTextList.length > 0 && <Divider />}
                    <List sx={{ maxHeight: 500, overflow: 'auto' }}>
                        {decodedTextList.map((code) => (
                            <ListItem
                                key={code}
                                secondaryAction={
                                    <IconButton edge='end'>
                                        {isLoading ? (
                                            <HourglassBottomIcon />
                                        ) : (
                                            <LaunchIcon
                                                onClick={() =>
                                                    handleAccess(code)
                                                }
                                            />
                                        )}
                                    </IconButton>
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        <QrCodeIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={code} />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </Grid>
        </Grid>
    )
}

CameraScanner.propTypes = { setOpenCamera: PropTypes.func }

export default CameraScanner
