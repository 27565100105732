import { Radio } from '@mui/material'
import PropTypes from 'prop-types'

const StyledRadio = ({ innerRef, input, ...props }) => {
    return <Radio size={'small'} {...input} {...props} />
}

StyledRadio.propTypes = { innerRef: PropTypes.any, input: PropTypes.any }

export default StyledRadio
