import {
    TableBody as MuiTableBody,
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { ProductionAddonParamHeadCells } from '~/components/HeaderTable/ProductionAddonHeadCells'
import TableLoading from '~/components/Table/TableLoading'
import ModifyParam from './ModifyParam'

const TableBody = ({ isLoading, results }) => {
    const [open, setOpen] = useState(false)
    const [keyParam, setKeyParam] = useState('')
    const [data, setData] = useState()
    if (isLoading) {
        return <TableLoading colSpan={ProductionAddonParamHeadCells.length} />
    }

    return (
        <>
            <MuiTableBody>
                {Object.keys(results).map((key) => {
                    return (
                        <TableRow
                            hover
                            tabIndex={-1}
                            key={key}
                            onClick={() => {
                                setOpen(true)
                                setKeyParam(key)
                                setData(results[key])
                            }}
                        >
                            <TableCell>{key}</TableCell>
                            {typeof results[key] === 'object' &&
                                Object.entries(results[key])?.map(
                                    ([innerKey, innerValue]) => (
                                        <>
                                            <TableCell>
                                                <b>{innerKey}:</b>{' '}
                                                {typeof innerValue === 'boolean'
                                                    ? innerValue
                                                        ? 'True'
                                                        : 'False'
                                                    : innerValue}
                                            </TableCell>
                                        </>
                                    ),
                                )}
                        </TableRow>
                    )
                })}
            </MuiTableBody>
            {open && (
                <ModifyParam
                    setOpen={setOpen}
                    keyParam={keyParam}
                    data={data}
                />
            )}
        </>
    )
}

TableBody.propTypes = {
    isLoading: PropTypes.bool,
    results: PropTypes.array,
}

const ParamTable = ({ isLoading, data }) => {
    return (
        <Paper sx={{ marginBottom: '10px' }}>
            <TableContainer
                sx={{ width: '100%' }}
                aria-labelledby='tableTitle'
                size='medium'
            >
                <Table>
                    <TableBody isLoading={isLoading} results={data} />
                </Table>
            </TableContainer>
        </Paper>
    )
}

ParamTable.propTypes = {
    isLoading: PropTypes.bool,
    data: PropTypes.array,
}

export default ParamTable
