import { useMutation, useQueryClient } from 'react-query'
import addonsApi from '~/api/production/addonsApi'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useImportAddonMutation = ({ showSnackbar }) => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['import_addon'],
        async (payload) => {
            const { data } = await addonsApi.importAddon(payload)
            return data.data
        },
        {
            onSuccess: (data) => {
                showSnackbar(
                    data?.message ? data?.message : 'Import successful!',
                    'success',
                )
                clientQuery.invalidateQueries('addons-list')
            },
            onError: (error) => {
                showSnackbar(parseApiErrorMessage(error), 'error')
            },
            retry: false,
        },
    )
}

export const useEditAddonMutation = ({ showSnackbar }) => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['edit_addon'],
        async ({ id, payload }) => {
            const { data } = await addonsApi.editAddon(id, payload)
            return data.data
        },
        {
            onSuccess: (data) => {
                showSnackbar(
                    data?.message ? data?.message : 'Update successful!',
                    'success',
                )
                clientQuery.invalidateQueries('addons-list')
                clientQuery.invalidateQueries('production-addon-detail')
            },
            onError: (error) => {
                showSnackbar(parseApiErrorMessage(error), 'error')
            },
            retry: false,
        },
    )
}

export const useEditParamMutation = ({ showSnackbar }) => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['edit_param'],
        async ({ id, old_key, key, ...rest }) => {
            const { data } = await addonsApi.editParam(id, old_key, key, rest)
            return data.data
        },
        {
            onSuccess: (data) => {
                showSnackbar(
                    data?.message ? data?.message : 'Update successful!',
                    'success',
                )
                clientQuery.invalidateQueries('addons-list')
                clientQuery.invalidateQueries('production-addon-detail')
            },
            onError: (error) => {
                showSnackbar(parseApiErrorMessage(error), 'error')
            },
            retry: false,
        },
    )
}

export const useAddNewParamMutation = ({ showSnackbar }) => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['add_new_param'],
        async ({ id, key, ...rest }) => {
            const { data } = await addonsApi.addNewParam(id, key, rest)
            return data.data
        },
        {
            onSuccess: (data) => {
                showSnackbar(
                    data?.message ? data?.message : 'Add successful!',
                    'success',
                )
                clientQuery.invalidateQueries('addons-list')
                clientQuery.invalidateQueries('production-addon-detail')
            },
            onError: (error) => {
                showSnackbar(parseApiErrorMessage(error), 'error')
            },
            retry: false,
        },
    )
}
