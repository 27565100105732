import {
    Table as MuiTable,
    TableBody as MuiTableBody,
    Paper,
    TableContainer,
    TableRow,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import DataNotFound from '~/components/DataNotFound'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'
import { WorkbenchHeadCells } from '~/components/HeaderTable/WorkbenchHeadCells'
import PaginationTable from '~/components/PaginationTable'
import ReferencedEntities from '~/components/ReferencedEntities'
import TableLoading from '~/components/Table/TableLoading'
import TableCell from '~/components/TableCell'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import WorkbenchTableFilters from './WorkbenchTableFilters'
import { useWorkbenchListQuery } from './query'

const TableBody = ({ workbenches, isLoading }) => {
    if (isLoading) {
        return <TableLoading colSpan={WorkbenchHeadCells.length} />
    }

    return (
        <MuiTableBody>
            {workbenches.length > 0 ? (
                workbenches.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                            <ReferencedEntities
                                isTableCell={true}
                                dataType={'workbenches-id'}
                                dataIdentifier={row.id}
                                dataDisplay={row.id}
                            />
                            <TableCell cellValue={row?.name} />
                            <TableCell cellValue={row?.location} />
                            <TableCell cellValue={row?.service_center_name} />
                            <TableCell
                                cellValue={
                                    <DateTimeLocale
                                        datetime={row?.created_at}
                                    />
                                }
                            />
                            <TableCell
                                cellValue={
                                    <DateTimeLocale
                                        datetime={row?.updated_at}
                                    />
                                }
                            />
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={WorkbenchHeadCells.length} />
            )}
        </MuiTableBody>
    )
}

TableBody.propTypes = {
    workbenches: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
}

const Table = () => {
    const [service, setService] = useState('')

    const {
        results: workbenches,
        data,
        setPage,
        isLoading,
        order,
        orderBy,
        page,
        search,
        sortHandler,
        changePageHandler,
        searchHandler,
        onEnterSearch,
    } = useTable({
        query: useWorkbenchListQuery,
        queryAttributes: {
            service,
        },
    })

    const refValue = useRef({
        service: '',
    })

    useEffect(() => {
        if (service !== refValue.current.service) {
            setPage(1)
            refValue.current.service = service
        }
    }, [setPage, service])

    return (
        <Paper>
            <WorkbenchTableFilters
                {...{
                    service,
                    setService,
                    search,
                    searchHandler,
                    onEnterSearch,
                }}
            />
            <TableContainer
                sx={{ minWidth: 750 }}
                aria-labelledby='tableTitle'
                size='medium'
            >
                <MuiTable>
                    <TableHeader
                        headCells={WorkbenchHeadCells}
                        localization={'pages.administrator.workbench.'}
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />
                    <TableBody {...{ workbenches, isLoading }} />
                </MuiTable>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}

export default Table
