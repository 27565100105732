import {
    Table as MuiTable,
    TableBody as MuiTableBody,
    Paper,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import PropTypes from 'prop-types'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'
import { ProductionOrderDetailHeadCells } from '~/components/HeaderTable/ProductionOderHeadCells'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'

const TableBody = ({ isLoading, object }) => {
    if (isLoading) {
        return <TableLoading colSpan={ProductionOrderDetailHeadCells.length} />
    }

    return (
        <MuiTableBody>
            <TableRow hover tabIndex={-1} key={object?.data?.id}>
                <TableCell>{object?.data?.qty}</TableCell>
                <TableCell>{object?.assigned}</TableCell>
                <TableCell>{object?.state ?? 'N/A'}</TableCell>
                <TableCell>
                    <DateTimeLocale datetime={object?.data?.delivery_date} />
                </TableCell>
                <TableCell>
                    <DateTimeLocale datetime={object?.data?.created_at} />
                </TableCell>
            </TableRow>
        </MuiTableBody>
    )
}

TableBody.propTypes = {
    isLoading: PropTypes.bool,
    object: PropTypes.object,
}

const OrderDetailTable = ({ isLoading, data }) => {
    return (
        <Paper>
            <TableContainer
                sx={{ width: '100%', marginBottom: '20px' }}
                aria-labelledby='tableTitle'
                size='medium'
            >
                <MuiTable>
                    <TableHeader
                        headCells={ProductionOrderDetailHeadCells}
                        localization={'pages.production.orders.'}
                        order='desc'
                        orderBy='id'
                        onRequestSort={() => {}}
                    />
                    <TableBody isLoading={isLoading} object={data} />
                </MuiTable>
            </TableContainer>
        </Paper>
    )
}

OrderDetailTable.propTypes = {
    isLoading: PropTypes.bool,
    data: PropTypes.object,
}

export default OrderDetailTable
