import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import arrayMutators from 'final-form-arrays'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { number, object, string } from 'yup'
import InputField from '~/components/FinalForm/InputField'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import StyledModal from '~/components/StyledModal'
import SubmitButton from '~/components/SubmitButton'
import { PRODUCTION_ADD_ONS } from '~/constants/Routes'
import useFormValidationCallback from '~/hooks/useFormValidationCallback'
import { useSnackbar } from '~/hooks/useSnackbar'
import { useAddNewParamMutation } from '../mutation'

const AddParam = ({ setOpen }) => {
    const { id } = useParams()
    const { t } = useTranslation()
    const [action, setAction] = useState('false')
    const [type, setType] = useState('string')
    const [helpText, setHelpText] = useState('')
    const handleClose = () => {
        setOpen(false)
    }
    const { showSnackbar, snackbar } = useSnackbar()

    const localization = 'pages.production.addons'
    const pagePath = PRODUCTION_ADD_ONS
    const schema = object().shape({
        key: string()
            .required()
            .matches(
                /^[a-z-]+$/,
                'Key must be lowercase with only letters and hyphens',
            ),
        display_name: string()
            .required()
            .matches(
                /^[A-Za-z -_ ()%]+$/,
                'Display name can only contain letters, spaces, hyphens, underscores, parentheses, and percent symbol',
            ),
        datatype: string().optional(),
        regex: string()
            .required()
            .matches(/^\/[^/]+\/$/, 'Regex must be a valid regular expression'),
        default: string().required(),
        version: number().required().min(0.0).max(10.0),
        help: string().optional(),
    })

    const { mutate: importParamMutate } = useAddNewParamMutation({
        showSnackbar,
    })
    const validate = useFormValidationCallback(schema)

    const clientQuery = useQueryClient()
    const submit = useCallback(
        (payload) => {
            const { key, ...rest } = payload
            importParamMutate(
                { id, key, ...rest },
                {
                    onSuccess: (data) => {
                        clientQuery.invalidateQueries([
                            'production-addon-detail',
                            id,
                        ])
                    },
                },
            )
        },

        [importParamMutate, id, clientQuery],
    )

    const onSubmit = useCallback(
        (values) => {
            submit({
                key: values.key,
                display_name: values.display_name,
                datatype: type,
                regex: values.regex,
                version: values.version,
                default: values.default,
                help: helpText,
                required: action,
            })
        },
        [action, helpText, submit, type],
    )

    const handleChange = (event) => {
        setAction(event.target.value)
    }

    const handleDataTypeChange = (event) => {
        setType(event.target.value)
    }

    const onHelpTextChange = (event) => {
        setHelpText(event.target.value)
    }

    return (
        <>
            {snackbar}
            <StyledModal
                open={open}
                handleClose={() => handleClose()}
                stackStyles={{ width: 'auto', borderRadius: '10px' }}
                stackProps={{
                    width: '60%',
                    padding: '2px 10px',
                }}
            >
                <Typography variant='h6' sx={{ marginTop: '15px' }}>
                    Add New Param
                </Typography>
                <Form
                    validate={validate}
                    onSubmit={onSubmit}
                    mutators={{
                        ...arrayMutators,
                    }}
                    keepDirtyOnReinitialize
                    render={({ handleSubmit }) => (
                        <FormBodyWrapper>
                            <Grid
                                container
                                justifyContent='space-between'
                                alignContent='space-around'
                                sx={{ marginBottom: '10px' }}
                            >
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '99%' }}>
                                        <InputField
                                            localization={localization}
                                            name={'key'}
                                            componentId={'key'}
                                            pagePath={pagePath}
                                            isRequired={true}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '99%' }}>
                                        <InputField
                                            localization={localization}
                                            name={'display_name'}
                                            componentId={'display_name'}
                                            pagePath={pagePath}
                                            isRequired={true}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                justifyContent='space-between'
                                alignContent='space-around'
                                sx={{ marginBottom: '10px' }}
                            >
                                <Grid item xs={6}>
                                    <FormControl
                                        sx={{ width: '99%', marginTop: '25px' }}
                                    >
                                        <InputLabel>Data Type</InputLabel>
                                        <Select
                                            value={type}
                                            label='Data Type'
                                            onChange={handleDataTypeChange}
                                        >
                                            <MenuItem value={'string'}>
                                                string
                                            </MenuItem>
                                            <MenuItem value={'number'}>
                                                number
                                            </MenuItem>
                                            <MenuItem value={'decimal'}>
                                                decimal
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '99%' }}>
                                        <InputField
                                            localization={localization}
                                            name={'version'}
                                            componentId={'version'}
                                            pagePath={pagePath}
                                            isRequired={true}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                justifyContent='space-between'
                                alignContent='space-around'
                                sx={{ marginBottom: '10px' }}
                            >
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '99%' }}>
                                        <InputField
                                            localization={localization}
                                            name={'regex'}
                                            componentId={'regex'}
                                            pagePath={pagePath}
                                            isRequired={true}
                                            defaultValue={'^[0-9]*$'}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '99%' }}>
                                        <InputField
                                            localization={localization}
                                            name={'default'}
                                            componentId={'default'}
                                            pagePath={pagePath}
                                            isRequired={true}
                                            defaultValue={'Hello'}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                justifyContent='space-between'
                                alignContent='space-around'
                                sx={{ marginBottom: '10px' }}
                            >
                                <Grid item xs={6}>
                                    <FormControl
                                        sx={{ width: '99%', marginTop: '25px' }}
                                    >
                                        <InputLabel>Required</InputLabel>
                                        <Select
                                            value={action}
                                            label='Required'
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={'false'}>
                                                False
                                            </MenuItem>
                                            <MenuItem value={'true'}>
                                                True
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                justifyContent='space-between'
                                alignContent='space-around'
                                sx={{ marginBottom: '10px' }}
                            >
                                <Grid item xs={12}>
                                    <Typography>Help</Typography>
                                    <FormControl sx={{ width: '99%' }}>
                                        <TextField
                                            value={helpText}
                                            onChange={onHelpTextChange}
                                            multiline
                                            minRows={5}
                                            maxRows={5}
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack direction='row-reverse' spacing={2}>
                                    <SubmitButton
                                        handleSubmit={handleSubmit}
                                        name={`${t('button.add')}`}
                                    />
                                </Stack>
                            </Grid>
                        </FormBodyWrapper>
                    )}
                />
            </StyledModal>
        </>
    )
}

AddParam.propTypes = {
    setOpen: PropTypes.func,
}

export default AddParam
