import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { IconButton } from '@mui/material'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import { DISPATCH_FAILED, PARTS_NOT_READY } from '../Detail/constants'
import EditStockLabelPopup from './EditStockLabelPopup'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import usePermissions from '~/hooks/usePermission'

const useStyle = makeStyles()(() => ({
    hidden: {
        visibility: 'hidden',
    },
}))
const StockLabelDisplayer = ({ bomId, stockLabel, bomState }) => {
    const { classes } = useStyle()
    const { permissions } = usePermissions()
    const [open, setOpen] = useState(false)
    const isAbleToEdit =
        bomState === DISPATCH_FAILED || bomState === PARTS_NOT_READY
    const isHavePermissionToEdit = permissions.issue.bom.canEditBomStockLabel
    const handleOpenModal = useCallback(() => {
        setOpen(true)
    }, [])

    return (
        <>
            {stockLabel || 'N/A'}
            <IconButton
                className={clsx(
                    !(isAbleToEdit && isHavePermissionToEdit) && classes.hidden,
                )}
                size='small'
                onClick={() => handleOpenModal()}
            >
                <BorderColorIcon color='primary' />
            </IconButton>

            {open && (
                <EditStockLabelPopup
                    message='Edit Stock label Information'
                    open={open}
                    onClose={() => setOpen(false)}
                    bomId={bomId}
                    stockLabel={stockLabel}
                    bomState={bomState}
                />
            )}
        </>
    )
}

StockLabelDisplayer.propTypes = {
    bomId: PropTypes.number,
    stockLabel: PropTypes.string,
    bomState: PropTypes.string,
}

export default StockLabelDisplayer
