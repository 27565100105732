import axiosClient from '~/api/base'

const issueTriageApi = {
    triageDone: (issueId) =>
        axiosClient.put(`api/issues/${issueId}/triage-done`),
    startTriage: (serial) =>
        axiosClient.post('api/issues/start-triage', { serial }),
}

export default issueTriageApi
