import { useQuery } from 'react-query'
import addonsApi from '~/api/production/addonsApi'
import { parseApiErrorMessage, removeEmptyValue } from '~/utils/helpers'

export const useAddonsListQuery = (params) => {
    return useQuery(['addons-list', params], async () => {
        const p = removeEmptyValue(params)
        const { data } = await addonsApi.getAddons(p)
        return { data: data?.data, total: data?.meta?.total }
    })
}

export const useUserQuery = () => {
    return useQuery(['users-list'], async () => {
        const { data } = await addonsApi.getUsers()
        return { data: data?.data }
    })
}

export const useAddOnDetailQuery = ({ id, showSnackbar }) => {
    return useQuery(
        ['production-addon-detail', id],
        async () => {
            const data = await addonsApi.getAddon(id)
            return data.data
        },
        {
            onSuccess: (data) => {},
            onError: (error) => {
                showSnackbar(parseApiErrorMessage(error), 'error')
            },
            retry: false,
        },
    )
}
