import TableFilters from '~/components/TableFilters'
import { usePartCodeTypeQuery } from '~/pages/PartCode/query'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const PartCodeTableFilters = ({
    search,
    searchHandler,
    type,
    setType,
    onEnterSearch,
}) => {
    const { data: partCodeTypeData, isSuccess: isSuccessPartTypeCode } =
        usePartCodeTypeQuery()

    const partCodeTypes = useMemo(() => {
        if (isSuccessPartTypeCode) {
            const arrayType = partCodeTypeData?.data.map((x) => {
                return {
                    id: x.type,
                    name: x.type,
                }
            })
            arrayType.unshift({ id: '', name: 'ALL' })
            return arrayType
        }
        return []
    }, [isSuccessPartTypeCode, partCodeTypeData?.data])

    const selectGroup = useMemo(() => {
        return [
            {
                label: 'Type',
                handleFunc: setType,
                dataSet: partCodeTypes,
                value: type,
            },
        ]
    }, [partCodeTypes, setType, type])
    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='partCode'
            page='partCode'
        />
    )
}
PartCodeTableFilters.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    type: PropTypes.string,
    setType: PropTypes.func,
    onEnterSearch: PropTypes.func,
}
export default PartCodeTableFilters
