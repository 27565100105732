import { FormControl, Grid, Paper, Stack, Typography } from '@mui/material'
import arrayMutators from 'final-form-arrays'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { number, object, string } from 'yup'
import BreadCrumb from '~/components/BreadCrumb'
import InputField from '~/components/FinalForm/InputField'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import { PRODUCTION_ADD_ONS, PRODUCTION_SYSTEMIMAGE } from '~/constants/Routes'
import useFormValidationCallback from '~/hooks/useFormValidationCallback'
import { useEditAddonMutation } from '../mutation'
import { useAddOnDetailQuery } from '../query'
import { Form } from 'react-final-form'
import ParamTable from './ParamTable'
import { LoadingButton } from '~/components/Button'
import { useCallback, useState } from 'react'
import AddParam from './AddParam'
import SubmitButton from '~/components/SubmitButton'
import { useSnackbar } from '~/hooks/useSnackbar'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const AddonDetail = () => {
    const { id } = useParams()
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const localization = 'pages.production.addons'
    const pagePath = PRODUCTION_ADD_ONS
    const { showSnackbar, snackbar } = useSnackbar()

    const breadCrumbConfig = [
        { route: PRODUCTION_SYSTEMIMAGE, title: 'Production' },
        { route: PRODUCTION_ADD_ONS, title: 'Addons' },
        { route: '#', title: 'detail' },
    ]

    const { data, isLoading } = useAddOnDetailQuery({ id, showSnackbar })
    const schema = object().shape({
        name: string().required(),
        version: number().required().min(0.0).max(10.0),
    })
    const { mutate: editAddonMutate } = useEditAddonMutation({ showSnackbar })
    const validate = useFormValidationCallback(schema)

    const submit = useCallback(
        (payload) => {
            editAddonMutate({ id, payload })
        },
        [editAddonMutate, id],
    )

    const onSubmit = useCallback(
        (values) => {
            submit({
                name: values.name,
                version: values.version,
            })
        },
        [submit],
    )

    return (
        <>
            {snackbar}
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <Grid
                container
                spacing={3}
                sx={{
                    marginBottom: '20px',
                }}
            >
                <Grid item xs={12} md={6}>
                    <Paper
                        sx={{
                            py: 2,
                            px: 2,
                            height: '100%',
                            marginBottom: '2px',
                        }}
                    >
                        <Grid item xs={12} md={12}>
                            <Typography sx={{ marginBottom: '5px' }}>
                                {t(localization + `.createdBy`)} :{' '}
                                {data?.data?.created_by?.name}
                            </Typography>
                            <Typography sx={{ marginBottom: '5px' }}>
                                {t(localization + `.created`)} :{' '}
                                <DateTimeLocale
                                    datetime={data?.data?.created_at}
                                />
                            </Typography>

                            <Typography sx={{ marginBottom: '5px' }}>
                                {t(localization + `.updatedBy`)} :{' '}
                                {data?.data?.updated_by?.name}
                            </Typography>
                            <Typography sx={{ marginBottom: '5px' }}>
                                {t(localization + `.created`)} :{' '}
                                <DateTimeLocale
                                    datetime={data?.data?.updated_at}
                                />
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}></Grid>
            </Grid>
            <Grid item xs={12} md={12}>
                <Form
                    validate={validate}
                    onSubmit={onSubmit}
                    mutators={{
                        ...arrayMutators,
                    }}
                    keepDirtyOnReinitialize
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <FormBodyWrapper>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputField
                                                localization={localization}
                                                name={'name'}
                                                componentId={'name'}
                                                pagePath={pagePath}
                                                isRequired={true}
                                                defaultValue={data?.data?.name}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputField
                                                localization={localization}
                                                name={'version'}
                                                componentId={'version'}
                                                pagePath={pagePath}
                                                isRequired={true}
                                                defaultValue={
                                                    data?.data?.version
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>Params</Typography>
                                        {data?.data?.params && (
                                            <ParamTable
                                                isLoading={isLoading}
                                                data={data?.data?.params}
                                            />
                                        )}
                                        <LoadingButton
                                            label={t(
                                                'pages.production.addons.addParam',
                                            )}
                                            onClick={() => {
                                                setOpen(true)
                                            }}
                                        />
                                        {open && <AddParam setOpen={setOpen} />}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack
                                            direction='row-reverse'
                                            spacing={2}
                                        >
                                            <SubmitButton
                                                handleSubmit={handleSubmit}
                                                name={`${t('button.save')}`}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </FormBodyWrapper>
                        </form>
                    )}
                />
            </Grid>
        </>
    )
}

export default AddonDetail
