import TableFilters from '~/components/TableFilters'
import { useRolesQuery } from '~/pages/Acl/Role/query'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const DisableUserTableFilters = ({
    search,
    searchHandler,
    role,
    setRole,
    onEnterSearch,
}) => {
    const { data: roleData, isSuccess: isSuccessRole } = useRolesQuery({
        menuSelect: true,
    })

    const roles = useMemo(() => {
        if (isSuccessRole) {
            const result = [...roleData?.data]
            result.unshift({ id: '', name: 'All' })
            return result
        }
        return []
    }, [roleData?.data, isSuccessRole])

    const selectGroup = useMemo(() => {
        return [
            {
                label: 'Role',
                handleFunc: setRole,
                dataSet: roles,
                value: role,
            },
        ]
    }, [role, roles, setRole])
    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='users'
            page='disable_users'
        />
    )
}
DisableUserTableFilters.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    role: PropTypes.any,
    setRole: PropTypes.func,
    onEnterSearch: PropTypes.func,
}
export default DisableUserTableFilters
