import { Grid, Typography } from '@mui/material'
import ComponentTooltip from '~/components/ComponentTooltip'
import { FLEET_SERIAL_DETAIL } from '~/constants/Routes'
import { Form } from 'react-final-form'
import { useUploadDocumentMutation } from '../mutation'
import StyledModal from '~/components/StyledModal'
import InputFile from './InputFile'
import SaveButtonPopup from './SaveButtonPopup'
import UploadModalTitle from './UploadModalTitle'
import PropTypes from 'prop-types'

const UploadDocument = ({ openUploadDocument, setOpenUploadDocument }) => {
    const { mutate: uploadDocument, isLoading } = useUploadDocumentMutation({
        setOpenUploadDocument,
    })

    const handleClose = () => {
        setOpenUploadDocument(false)
    }

    return (
        <StyledModal
            open={openUploadDocument}
            handleClose={() => handleClose()}
        >
            <Grid container spacing={0.5}>
                <UploadModalTitle
                    componentId={'upload_document_popup_title'}
                    title={'Upload Document'}
                />
                <Grid item xs={12} sm={12}>
                    <Form
                        onSubmit={uploadDocument}
                        render={({ handleSubmit, values, form }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid item xs={12}>
                                    <ComponentTooltip
                                        componentId={`upload_document_popup_file`}
                                        pagePath={FLEET_SERIAL_DETAIL}
                                    >
                                        <Typography>File</Typography>
                                    </ComponentTooltip>
                                    <InputFile
                                        form={form}
                                        nameField='documents'
                                        fileType='document'
                                    />
                                </Grid>
                                <SaveButtonPopup {...{ isLoading }} />
                            </form>
                        )}
                    />
                </Grid>
            </Grid>
        </StyledModal>
    )
}

UploadDocument.propTypes = {
    openUploadDocument: PropTypes.bool,
    setOpenUploadDocument: PropTypes.func,
}

export default UploadDocument
