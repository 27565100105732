import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import TableLoading from '~/components/Table/TableLoading'
import PartCodeListComponent from '~/components/partCodes/PartCodeListComponent'
import useTable from '~/hooks/useTable'
import { useModelPartCodeQuery } from '~/pages/Cause/query'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentCausePartCodes } from '~/store/partcode/selector'
import PartCodeListSelectorComponent from './PartCodeListSelectorComponent'
import PropTypes from 'prop-types'
import { removeEmptyValue } from '~/utils/helpers'
import { useTranslation } from 'react-i18next'

const PartCodeList = ({
    selectedPartCodeTypes,
    causeContent,
    selectedModelIds,
}) => {
    const { t } = useTranslation()
    const [filteredCode, setFilteredCode] = useState('')
    const currentCausePartCode = useSelector(selectCurrentCausePartCodes)

    const refValue = useRef({
        code: '',
        types: '',
    })

    const { data, setPage, isLoading, isSuccess, page, changePageHandler } =
        useTable({
            query: useModelPartCodeQuery,
            initialStates: {
                page: 1,
                order: null,
                orderBy: null,
                search: null,
            },
            queryAttributes: removeEmptyValue({
                model: causeContent.model_id,
                code: filteredCode,
                types: selectedPartCodeTypes,
                models: selectedModelIds,
            }),
        })

    useEffect(() => {
        if (
            filteredCode !== refValue.current.filteredCode ||
            selectedPartCodeTypes !== refValue.current.selectedPartCodeTypes
        ) {
            setPage(1)
            refValue.current.filteredCode = filteredCode
            refValue.current.selectedPartCodeTypes = selectedPartCodeTypes
        }
    }, [filteredCode, selectedPartCodeTypes, setPage])

    const renderedPartCodes = useMemo(() => {
        if (isSuccess) {
            return data?.data.map((partCode) => {
                const found = currentCausePartCode.find(
                    (cur) => cur.code === partCode.code,
                )
                return {
                    code: partCode.code,
                    name: partCode.name,
                    disabled: !!found,
                }
            })
        }
        return []
    }, [currentCausePartCode, data?.data, isSuccess])

    return (
        <PartCodeListComponent
            {...{
                filteredCode,
                setFilteredCode,
                data,
                page,
                changePage: changePageHandler,
            }}
        >
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('table.partCode')}</TableCell>
                        <TableCell>{t('table.partName')}</TableCell>
                        <TableCell align='center'>
                            {t('table.actions')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                {isLoading ? (
                    <TableLoading colSpan={3} />
                ) : (
                    <TableBody>
                        {renderedPartCodes.map((partCode) => (
                            <PartCodeListSelectorComponent
                                key={partCode.code}
                                partCode={partCode}
                                selectedModelIds={selectedModelIds}
                            />
                        ))}
                    </TableBody>
                )}
            </Table>
        </PartCodeListComponent>
    )
}

PartCodeList.propTypes = {
    selectedPartCodeTypes: PropTypes.array,
    causeContent: PropTypes.object,
    selectedModelIds: PropTypes.array,
}

PartCodeList.defaultProps = {
    selectedModelIds: [],
}

export default PartCodeList
