import { ADMINISTRATOR_DEFINITION_TEAM_DETAIL } from '~/constants/Routes'

export const TeamSessionHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
        clickable: true,
        link: ADMINISTRATOR_DEFINITION_TEAM_DETAIL,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        dataType: 'image_name',
        orderable: true,
        customVal: (row) => {
            return { url: row.media?.preview_url, name: row.name }
        },
    },
    {
        id: 'contact_person',
        numeric: false,
        disablePadding: false,
        label: 'contactPerson',
        orderable: false,
        relationship: 'name',
        customVal: (row) => {
            return row.contact_person?.name
        },
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'createdAt',
        orderable: false,
        dataType: 'datetime',
    },
    {
        id: 'updated_at',
        numeric: false,
        disablePadding: false,
        label: 'updatedAt',
        orderable: false,
        dataType: 'datetime',
    },
]

export const TeamHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
        clickable: true,
        link: ADMINISTRATOR_DEFINITION_TEAM_DETAIL,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        dataType: 'image_name',
        orderable: true,
        customVal: (row) => {
            return { url: row.media?.preview_url, name: row.name }
        },
    },
    {
        id: 'contact_person',
        numeric: false,
        disablePadding: false,
        label: 'contactPerson',
        orderable: false,
        customVal: (row) => {
            return row.contact_person?.name
        },
    },
    {
        id: 'service_center',
        numeric: false,
        disablePadding: false,
        label: 'serviceCenter',
        orderable: false,
        customVal: (row) => {
            return row.service_center?.name
        },
    },

    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'createdAt',
        orderable: false,
        dataType: 'datetime',
    },
    {
        id: 'updated_at',
        numeric: false,
        disablePadding: false,
        label: 'updatedAt',
        orderable: false,
        dataType: 'datetime',
    },
]
