import { useQuery } from 'react-query'
import tooltipApi from '~/api/tooltipApi'
import { CACHE_TTL } from '../utils/constants'

export const useComponentTooltipDataQuery = (params) => {
    params.locale = localStorage.getItem('LOCALE')
    return useQuery(
        ['component_tooltip', params],
        async () => {
            const { data } = await tooltipApi.getTooltipDataByUrl(params)
            if (Array.isArray(data.data) && !data.data?.length) return []
            return data.data
        },
        {
            retry: false,
            cacheTime: CACHE_TTL.ONE_MINUTE,
            staleTime: CACHE_TTL.ONE_MINUTE,
        },
    )
}
export default useComponentTooltipDataQuery
