import { Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import { PRODUCTION_SYSTEMIMAGE } from '~/constants/Routes'
import { useSnackbar } from '~/hooks/useSnackbar'
import { useImageQuery } from '../query'
import ImagesVersionsTable from './ImagesVersionsTable'

const ImageDetail = () => {
    const { id } = useParams()
    const { showSnackbar, snackbar } = useSnackbar()

    const { data } = useImageQuery({ id, showSnackbar })

    const breadCrumbConfig = [
        { route: PRODUCTION_SYSTEMIMAGE, title: 'Production' },
        { route: PRODUCTION_SYSTEMIMAGE, title: 'Images' },
        { route: '#', title: 'detail' },
    ]
    return (
        <>
            {snackbar}
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <PageName name={data?.data?.name + ' - ' + data?.data?.type} />
            <Grid
                container
                spacing={3}
                sx={{
                    marginTop: '10px',
                }}
            >
                <Grid item xs={12} md={12}>
                    <ImagesVersionsTable />
                </Grid>
            </Grid>
        </>
    )
}

export default ImageDetail
