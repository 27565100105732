import { useTranslation } from 'react-i18next'
import DeleteConfirmPopup from '~/components/DeleteConfirmPopup'
import { useDeleteOrderLineMutation } from './mutation'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

const DeleteOrderLine = ({
    openDeleteConfirm,
    handleClose,
    serialDelete,
    orderId,
    setSerialSeleted = () => {},
}) => {
    const { t } = useTranslation()

    const {
        mutate: deleteOrderLineMutate,
        isSuccess,
        isLoading,
    } = useDeleteOrderLineMutation()

    useEffect(() => {
        if (isSuccess) {
            setSerialSeleted(serialDelete)
            handleClose()
        }
    }, [handleClose, isSuccess, serialDelete, setSerialSeleted])

    return (
        <DeleteConfirmPopup
            {...{ openDeleteConfirm, handleClose, isLoading }}
            handleDelete={() => {
                deleteOrderLineMutate({
                    serial: serialDelete,
                    production_order_id: orderId,
                })
            }}
            informationDialog={{
                title: `${t('dialog.deleteOrderLine')}`,
                content: `${t('dialog.deleteOrderLineContent')}`,
            }}
        />
    )
}

DeleteOrderLine.propTypes = {
    openDeleteConfirm: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    serialDelete: PropTypes.string.isRequired,
    orderId: PropTypes.number,
    setSerialSeleted: PropTypes.func,
}

DeleteOrderLine.defaultProps = {
    setSerialSeleted: () => {},
}

export default DeleteOrderLine
