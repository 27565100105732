import { Avatar } from '@mui/material'
import AvatarImage from '../assets/images/avatar.jpg'
import PropTypes from 'prop-types'

const CustomAvatar = ({ url, ...props }) => {
    return <Avatar src={url || AvatarImage} {...props} />
}

CustomAvatar.propTypes = {
    url: PropTypes.string,
}

export default CustomAvatar
