import TableFilters from '~/components/TableFilters'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const PurchaseOrderTableFilters = ({
    search,
    searchHandler,
    onEnterSearch,
}) => {
    const selectGroup = useMemo(() => {
        return []
    }, [])
    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='purchaseOrders'
            page='purchaseOrders'
        />
    )
}
PurchaseOrderTableFilters.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    onEnterSearch: PropTypes.func,
}
export default PurchaseOrderTableFilters
