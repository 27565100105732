import Loading from '~/components/Loading'
import { ISSUE_OVERVIEW } from '~/constants/Routes'
import { useParams } from 'react-router-dom'
import { Layout } from '../components'
import { useTicketQuery } from '../query'
import { useMemo } from 'react'
import CustomCardHeader from '~/components/CustomCardHeader'
import {
    Card,
    CardContent,
    Divider,
    Grid,
    IconButton,
    Link,
    Stack,
    Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { renderBooleanValue, ticketExternalLink } from '~/utils/helpers'
import { OpenInNew } from '@mui/icons-material'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const TicketDetail = () => {
    const { id } = useParams()
    const { data, isLoading: isQueryLoading } = useTicketQuery(id)
    const breadCrumbConfig = [
        { route: ISSUE_OVERVIEW, title: 'Tickets' },
        { page: 'detail', title: id },
    ]

    const ticketData = useMemo(() => data ?? [], [data])

    const localization = 'pages.issues.overview'
    const { t } = useTranslation('translation', { keyPrefix: localization })

    if (isQueryLoading) {
        return (
            <Layout breadcrumbs={breadCrumbConfig}>
                <Loading />
            </Layout>
        )
    }
    return (
        <Layout breadcrumbs={breadCrumbConfig}>
            <Grid container spacing={2}>
                <Stack
                    direction='column'
                    spacing={3}
                    sx={{ width: '100%', mx: 2, mt: 2 }}
                >
                    <Card>
                        <CustomCardHeader
                            title={
                                <Link
                                    target='_blank'
                                    underline='none'
                                    href={ticketExternalLink(
                                        ticketData?.id,
                                        ticketData?.url,
                                    )}
                                >
                                    Ticket ID: {ticketData?.id}
                                    <IconButton
                                        sx={{
                                            padding: 0,
                                            marginLeft: '3px',
                                            marginBottom: '3px',
                                        }}
                                    >
                                        <OpenInNew />
                                    </IconButton>
                                </Link>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6} md={4}>
                                    <Typography
                                        variant='subtitle2'
                                        component='div'
                                    >
                                        {t('ticket_id')}
                                    </Typography>
                                    <Typography
                                        variant='primary'
                                        component='div'
                                    >
                                        {ticketData?.id ?? 'NULL'}
                                    </Typography>
                                </Grid>
                                {[
                                    'serial',
                                    'esd_location',
                                    'issue',
                                    'service_id',
                                    'service_center_tid',
                                    'brand',
                                    'ticket_status',
                                    'wo_status',
                                    'state',
                                    'school_id',
                                    'device_user_id',
                                    'contract_id',
                                ].map((fieldName, index) => (
                                    <Grid item xs={6} md={4} key={index}>
                                        <Typography
                                            variant='subtitle2'
                                            component='div'
                                        >
                                            {t(fieldName)}
                                        </Typography>
                                        <Typography
                                            variant='primary'
                                            component='div'
                                        >
                                            {ticketData?.[fieldName] ?? 'NULL'}
                                        </Typography>
                                    </Grid>
                                ))}
                                {[
                                    'damaged',
                                    'powers_on',
                                    'liquid_damage',
                                    'accidential_damage_warranty',
                                ].map((fieldName, index) => (
                                    <Grid item xs={6} md={4} key={index}>
                                        <Typography
                                            variant='subtitle2'
                                            component='div'
                                        >
                                            {t(fieldName)}
                                        </Typography>
                                        <Typography
                                            variant='primary'
                                            component='div'
                                        >
                                            {renderBooleanValue(
                                                ticketData?.[fieldName],
                                            )}
                                        </Typography>
                                    </Grid>
                                ))}
                                {[
                                    'damage_description',
                                    'cause_tid',
                                    'accessories',
                                    'loaner_id',
                                    'webshop_id',
                                    'image_version_tid',
                                    'entity_tid',
                                    'reject_reason',
                                    'previous_id',
                                ].map((fieldName, index) => (
                                    <Grid item xs={6} md={4} key={index}>
                                        <Typography
                                            variant='subtitle2'
                                            component='div'
                                        >
                                            {t(fieldName)}
                                        </Typography>
                                        <Typography
                                            variant='primary'
                                            component='div'
                                        >
                                            {ticketData?.[fieldName] ?? 'NULL'}
                                        </Typography>
                                    </Grid>
                                ))}
                                {[
                                    'battery_warranty_until',
                                    'subcription_end_date',
                                    'created_at',
                                ].map((fieldName, index) => (
                                    <Grid item xs={6} md={4} key={index}>
                                        <Typography
                                            variant='subtitle2'
                                            component='div'
                                        >
                                            {t(fieldName)}
                                        </Typography>
                                        <Typography
                                            variant='primary'
                                            component='div'
                                        >
                                            <DateTimeLocale
                                                datetime={
                                                    ticketData?.[fieldName]
                                                }
                                            />
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </CardContent>
                    </Card>
                </Stack>
            </Grid>
        </Layout>
    )
}
export default TicketDetail
