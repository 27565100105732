import { useQuery } from 'react-query'
import { removeEmptyValue } from '~/utils/helpers'
import testDataApi from '~/api/testDataApi'

export const useTestDataIssuesQuery = (params) => {
    return useQuery(['test-data-issues', params], async () => {
        const cleanParams = removeEmptyValue(params)
        if (typeof cleanParams.orderBy === 'undefined') {
            cleanParams.orderBy = 'id'
        }
        const { data } = await testDataApi.getIssues(cleanParams)
        return { data: data.data, total: data.meta.total }
    })
}
