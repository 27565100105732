import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

const useStyle = makeStyles()((theme) => ({
    button: {
        textTransform: 'none',
        backgroundColor: '#a59f9f',
        '&:hover': {
            backgroundColor: 'gray',
            color: 'with',
        },
    },
}))

const CancelButton = ({ name = 'Cancel' }) => {
    const navigate = useNavigate()
    const { classes } = useStyle()
    return (
        <Button
            variant='contained'
            className={classes.button}
            size='small'
            onClick={() => navigate(-1)}
        >
            {name}
        </Button>
    )
}

CancelButton.propTypes = { name: PropTypes.string }

export const CloseButton = ({ link, ...props }) => {
    const navigate = useNavigate()
    const { classes } = useStyle()
    return (
        <Button
            variant='contained'
            className={classes.button}
            size='small'
            onClick={() => navigate(link)}
            {...props}
        >
            Close
        </Button>
    )
}

CloseButton.propTypes = { link: PropTypes.string }

export default CancelButton
