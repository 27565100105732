import { componentApi, partcodeApi } from '~/api'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { removeEmptyValue } from '~/utils/helpers'

export const useComponentsQuery = (params) => {
    return useQuery(
        ['components', params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await componentApi.getComponents(p)
            return { data: data.data, total: data.meta.total }
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useComponentDetailQuery = () => {
    const { id } = useParams()
    return useQuery(
        ['component', id],
        async () => {
            const { data } = await componentApi.getComponentDetail(id)
            return data.data
        },
        {},
    )
}

export const useComponentDevicesQuery = (params) => {
    const { id } = useParams()
    return useQuery(
        ['component', id, params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await componentApi.getComponentDevices(id, p)
            return { data: data.data, total: data.meta.total }
        },
        {},
    )
}

export const usePartCodeQuery = (params = {}) => {
    return useQuery(
        ['partcodes', params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await partcodeApi.getPartCodeList(p)
            return { data: data.data, total: data.meta?.total }
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}
