import {
    Button,
    Table as MuiTable,
    TableBody as MuiTableBody,
    Paper,
    TableContainer,
    TableRow,
    Tooltip,
} from '@mui/material'
import cronstrue from 'cronstrue/i18n'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import DataNotFound from '~/components/DataNotFound'
import { ExportSchedulerHeadCells } from '~/components/HeaderTable/ExportSchedulerHeadCells'
import PaginationTable from '~/components/PaginationTable'
import ReferencedEntities from '~/components/ReferencedEntities'
import TableLoading from '~/components/Table/TableLoading'
import TableCell from '~/components/TableCell'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import { useExportSchedulerListQuery } from '../query'
import SchedulerTableFilters from './SchedulerTableFilters'

const TableBody = ({ isLoading, objects }) => {
    const getStatus = useCallback((row) => {
        if (row?.email_address) return 'Send email'
        return 'Store'
    }, [])
    if (isLoading) {
        return <TableLoading colSpan={ExportSchedulerHeadCells.length} />
    }

    const locale = localStorage.getItem('LOCALE')

    return (
        <MuiTableBody>
            {objects.length > 0 ? (
                objects.map((row, index) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                            <ReferencedEntities
                                isTableCell={true}
                                dataType={'schedule-id'}
                                dataIdentifier={row?.id}
                                dataDisplay={row?.id}
                            />
                            <TableCell cellValue={row?.name} />
                            <TableCell cellValue={getStatus(row)} />
                            {row?.cron ? (
                                <TableCell
                                    cellValue={
                                        <Tooltip
                                            title={cronstrue.toString(
                                                row?.cron,
                                                { locale },
                                            )}
                                            placement='top'
                                        >
                                            <Button size='small'>
                                                {row?.cron}
                                            </Button>
                                        </Tooltip>
                                    }
                                    type='component'
                                />
                            ) : (
                                <TableCell cellValue={row?.cron} />
                            )}
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={ExportSchedulerHeadCells.length} />
            )}
        </MuiTableBody>
    )
}

TableBody.propTypes = {
    isLoading: PropTypes.bool,
    objects: PropTypes.arrayOf(PropTypes.object),
}

const Table = () => {
    const [method, setMethod] = useState(null)
    const [status, setStatus] = useState(null)
    const {
        data,
        results,
        isLoading,
        order,
        page,
        orderBy,
        search,
        setPage,
        searchHandler,
        changePageHandler,
        sortHandler,
        onEnterSearch,
    } = useTable({
        query: useExportSchedulerListQuery,
        queryAttributes: { method, status },
        initialStates: {
            order: 'asc',
        },
    })

    const refValue = useRef({
        method: null,
        status: null,
    })

    useEffect(() => {
        if (
            method !== refValue.current.method ||
            status !== refValue.current.status
        ) {
            setPage(1)
            refValue.current.method = method
            refValue.current.status = status
        }
    }, [setPage, method, status])

    return (
        <Paper>
            <SchedulerTableFilters
                searchValue={search}
                searchHandler={searchHandler}
                status={status}
                setStatus={setStatus}
                method={method}
                setMethod={setMethod}
                onEnterSearch={onEnterSearch}
            />
            <TableContainer
                sx={{ minWidth: 750 }}
                aria-labelledby='tableTitle'
                size='medium'
            >
                <MuiTable>
                    <TableHeader
                        headCells={ExportSchedulerHeadCells}
                        localization={
                            'pages.administrator.dataScheduler.overview.'
                        }
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />
                    <TableBody isLoading={isLoading} objects={results} />
                </MuiTable>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}

export default Table
