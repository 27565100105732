import StyledModal from '~/components/StyledModal'
import { useDeleteProjectAddonMutation } from '../mutation'
import PropTypes from 'prop-types'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '~/components/Button'
import { useSnackbar } from '~/hooks/useSnackbar'

const ProjectDeleteAddonModel = ({
    row,
    setOpenDeleteAddon,
    openDeleteAddon,
}) => {
    const { t } = useTranslation()
    const { showSnackbar, snackbar } = useSnackbar()
    const { mutate: deleteProjectAddonMutate, isLoading } =
        useDeleteProjectAddonMutation({
            id: row?.production_project_id,
            showSnackbar,
        })

    const handleClose = () => {
        setOpenDeleteAddon(false)
    }

    const handleConfirm = () => {
        deleteProjectAddonMutate({
            addon_id: row?.addon_id,
            project_id: row?.production_project_id,
        })
        setOpenDeleteAddon(false)
    }
    return (
        <>
            {snackbar}
            <StyledModal
                open={openDeleteAddon}
                handleClose={() => handleClose()}
                stackProps={{
                    sx: {
                        minWidth: 500,
                        maxHeight: '95vh',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '10px',
                            backgroundColor: 'rgba(0, 0, 0, .5)',
                        },
                        '&::-webkit-scrollbar': {
                            width: '10px',
                        },
                    },
                }}
            >
                <Typography variant='h5'>
                    {' '}
                    {t('pages.production.projects.deleteAddon')} #
                    {row?.addon_id}
                </Typography>

                <Stack direction='row' gap={2} style={{ marginTop: '10px' }}>
                    <LoadingButton
                        label={t('button.delete')}
                        onClick={handleConfirm}
                        loading={isLoading}
                    />
                    <LoadingButton
                        label={t('button.close')}
                        onClick={handleClose}
                        loading={isLoading}
                    />
                </Stack>
            </StyledModal>
        </>
    )
}

ProjectDeleteAddonModel.propTypes = {
    row: PropTypes.object,
    setOpenDeleteAddon: PropTypes.func,
    openDeleteAddon: PropTypes.bool,
}

export default ProjectDeleteAddonModel
