import StyledModal from '~/components/StyledModal'
import {
    CircularProgress,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Stack,
    Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '~/components/Button'
import { useCallback, useState } from 'react'
import { useTeamsUserNotInQuery } from '~/pages/UserDetail/query'
import { useJoinTeamMutation } from '~/pages/UserDetail/mutate'

const localization = 'pages.user.userDetail'
const AddTeamPopup = ({ open, onClose, userId }) => {
    const { t } = useTranslation()
    const [selectedTeam, setSelectedTeam] = useState(0)

    const changeTeam = useCallback((event) => {
        setSelectedTeam(event.target.value)
    }, [])

    const addTeam = () => {
        joinTeam(
            { user_id: userId, team_id: selectedTeam },
            {
                onSettled: () => {
                    onClose()
                },
            },
        )
    }

    const { data: teams, isLoading } = useTeamsUserNotInQuery(userId)
    const { mutate: joinTeam, isLoading: isSaving } = useJoinTeamMutation()

    return (
        <StyledModal
            open={open}
            handleClose={onClose}
            stackProps={{
                width: 500,
                minHeight: 100,
                gap: 4,
            }}
        >
            <Typography variant='h6' align='center'>
                {t(`${localization}.joinTeam`)}
            </Typography>
            <Grid
                container
                direction='column'
                justifyContent='center'
                spacing={1}
            >
                <Grid item>
                    <FormControl fullWidth>
                        {!isLoading ? (
                            <Select value={selectedTeam} onChange={changeTeam}>
                                {teams.data?.map((team) => (
                                    <MenuItem
                                        key={team.id}
                                        value={team.id}
                                        name={team.name}
                                    >
                                        {team.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        ) : (
                            <CircularProgress thickness={3} size={20} />
                        )}
                    </FormControl>
                </Grid>
            </Grid>
            <Stack direction='row' gap={2}>
                <LoadingButton
                    label={t('button.save')}
                    onClick={addTeam}
                    disabled={selectedTeam === 0}
                    loading={isSaving}
                />
                <LoadingButton
                    bgColor='#102F44'
                    label={t('button.cancel')}
                    onClick={onClose}
                    loading={isSaving}
                />
            </Stack>
        </StyledModal>
    )
}

AddTeamPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    userId: PropTypes.number,
}

export default AddTeamPopup
