import {
    ADMINISTRATOR,
    ADMINISTRATOR_TEST_DATA_DIAGNOSE,
} from '~/constants/Routes'
import { Field, Form } from 'react-final-form'
import useFormValidationCallback from '../../../hooks/useFormValidationCallback'
import { object, string } from 'yup'
import arrayMutators from 'final-form-arrays'
import BreadCrumb from '~/components/BreadCrumb'
import Toolbar from '../../Acl/RoleCreate/Toolbar'
import ComponentTooltip from '~/components/ComponentTooltip'
import PageName from '~/components/PageName'
import { FormControl, Grid } from '@mui/material'
import InputField from '~/components/FinalForm/InputField'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import { useTestableDevicesQuery } from '~/pages/TestData/Diagnose/query'
import AutoCompleteField from '~/components/FinalForm/AutoCompleteField'
import { useCreatingDiagnoseTesting } from '~/pages/TestData/Diagnose/mutation'
import Loading from '~/components/Loading'
import { useDispatch, useSelector } from 'react-redux'
import { selectSnackbar } from '~/store/snackbar/selector'
import { useSnackbar } from '~/hooks/useSnackbar'
import { useEffect } from 'react'
import { unsetSnackbar } from '~/store/snackbar/slice'
import SelectField from '~/components/FinalForm/SelectField'
import {
    TEST_DIAGNOSE_STAGE,
    TEST_DIAGNOSE_TYPE,
} from '~/pages/TestData/Diagnose/constants'
import { useTranslation } from 'react-i18next'

const breadCrumbConfig = [
    { title: `Administrator`, route: ADMINISTRATOR },
    {
        title: `Test_Data_Diagnose`,
        route: ADMINISTRATOR_TEST_DATA_DIAGNOSE,
    },
]

const pagePath = ADMINISTRATOR_TEST_DATA_DIAGNOSE
const localization = 'pages.administrator.testData.diagnose'

const TestDataCreateDiagnose = () => {
    const testDiagnoseTypes = [
        { name: 'DELL', value: 'DELL' },
        { name: 'DELL_EV', value: 'DELL_EV' },
        { name: 'EV', value: 'EV' },
    ]
    const testDiagnoseStages = [
        { name: 'PREFILLED_DIAGNOSE', value: 'PREFILLED_DIAGNOSE' },
        { name: 'PREFILLED_BOM', value: 'PREFILLED_BOM' },
        { name: 'FINALIZED_BOM', value: 'FINALIZED_BOM' },
    ]
    const schema = object().shape({
        serial: string().required(),
        amount: string().required(),
    })

    const { t } = useTranslation()
    const { data: testableDevices, isLoading } = useTestableDevicesQuery()
    const validate = useFormValidationCallback(schema)
    const { mutate: createTestingDiagnose } = useCreatingDiagnoseTesting()
    const snackbarData = useSelector(selectSnackbar)
    const { showSnackbar, snackbar } = useSnackbar()
    const dispatch = useDispatch()
    useEffect(() => {
        if (snackbarData && snackbarData?.type === 'error') {
            showSnackbar(snackbarData.message, snackbarData.type)
            dispatch(unsetSnackbar({}))
        }
    }, [dispatch, showSnackbar, snackbarData])

    if (isLoading) {
        return <Loading />
    }

    return (
        <>
            {snackbar}
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_TEST_DATA_DIAGNOSE}
            >
                <div>
                    <PageName
                        name={t(`${localization}.create_diagnose_for_testing`)}
                    />
                </div>
            </ComponentTooltip>
            <Form
                validate={validate}
                onSubmit={createTestingDiagnose}
                mutators={{
                    ...arrayMutators,
                }}
                keepDirtyOnReinitialize
                // initialValues={initialValues}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Toolbar
                            localization={'pages.administrator.testData'}
                        />
                        <FormBodyWrapper>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Field
                                            name='serial'
                                            component={AutoCompleteField}
                                            items={testableDevices}
                                            localization={localization}
                                            componentId={`serial`}
                                            pagePath={pagePath}
                                            nameSelect='serial'
                                            isRequired={true}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputField
                                        localization={localization}
                                        name={'amount'}
                                        componentId={'amount'}
                                        pagePath={pagePath}
                                        isRequired={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Field
                                            name='type'
                                            component={SelectField}
                                            items={testDiagnoseTypes}
                                            localization={localization}
                                            defaultValue={TEST_DIAGNOSE_TYPE}
                                            componentId={`type_test_data`}
                                            pagePath={pagePath}
                                            nameSelect='type'
                                            isRequired={false}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Field
                                            name='stage'
                                            component={SelectField}
                                            items={testDiagnoseStages}
                                            localization={localization}
                                            defaultValue={TEST_DIAGNOSE_STAGE}
                                            componentId={`stage_test_data`}
                                            pagePath={pagePath}
                                            nameSelect='stage'
                                            isRequired={false}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </FormBodyWrapper>
                    </form>
                )}
            />
        </>
    )
}
export default TestDataCreateDiagnose
