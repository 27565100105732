import { Paper, TableContainer } from '@mui/material'
import PaginationTable from '~/components/PaginationTable'
import Search from '~/components/Search/Search'
import { debounce } from 'lodash'
import { useMemo } from 'react'
import PropTypes from 'prop-types'

const PartCodeListComponent = ({
    children,
    setFilteredCode,
    data,
    page,
    changePage,
}) => {
    const searchHandler = useMemo(() => {
        return debounce((event) => {
            if (!(event.target.value.length < 3)) {
                setFilteredCode(event.target.value)
            }
        }, 500)
    }, [setFilteredCode])
    return (
        <>
            <Search
                label={'Part Code'}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        setFilteredCode(e.target.value)
                        e.preventDefault()
                    }
                }}
                onChange={searchHandler}
                sx={{ mb: 2 }}
            />

            <TableContainer component={Paper}>{children}</TableContainer>
            <PaginationTable
                data={data}
                page={page}
                size='small'
                handleChangePage={changePage}
            />
        </>
    )
}

PartCodeListComponent.propTypes = {
    children: PropTypes.any,
    setFilteredCode: PropTypes.func,
    data: PropTypes.object,
    page: PropTypes.number,
    changePage: PropTypes.func,
}

export default PartCodeListComponent
