import axiosClient from '~/api/base'

const issueQcApi = {
    deleteQcPicture: (id) =>
        axiosClient.delete(`api/quality-check/pictures/${id}`),

    saveIssueQc: (issueId) =>
        axiosClient.post(`api/issues/${issueId}/quality-check/save`),

    supervisorAcceptQC: (issueId, comment) =>
        axiosClient.put(
            `api/issues/${issueId}/quality-check/supervisor-accept-qc`,
            {
                comment,
            },
        ),
}

export default issueQcApi
