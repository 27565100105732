import PropTypes from 'prop-types'
import { LoadingButton } from '@mui/lab'
import { makeStyles } from 'tss-react/mui'

const useStyle = makeStyles()((theme) => ({
    button: {
        textTransform: 'none',
        backgroundColor: '#76B72A',
        '&:hover': {
            backgroundColor: '#102F44',
            color: 'white',
        },
    },
}))

const SubmitButton = ({
    isLoading,
    handleSubmit,
    name,
    children,
    ...props
}) => {
    const { classes } = useStyle()
    return (
        <LoadingButton
            loading={isLoading}
            variant='contained'
            className={classes.button}
            size='small'
            type='submit'
            onClick={handleSubmit}
            {...props}
        >
            {name}
            {children}
        </LoadingButton>
    )
}

SubmitButton.propTypes = {
    isLoading: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    name: PropTypes.string,
    children: PropTypes.node,
}

export default SubmitButton
