export const ProductionAddonHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        orderable: true,
    },
    {
        id: 'version',
        numeric: false,
        disablePadding: false,
        label: 'version',
        orderable: false,
    },
    {
        id: 'filename',
        numeric: false,
        disablePadding: false,
        label: 'filename',
        orderable: true,
    },
    {
        id: 'project',
        numeric: false,
        disablePadding: false,
        label: 'project',
        orderable: false,
    },
    {
        id: 'created_by',
        numeric: false,
        disablePadding: false,
        label: 'createdBy',
        orderable: false,
    },
    {
        id: 'updated_by',
        numeric: false,
        disablePadding: false,
        label: 'updatedBy',
        orderable: false,
    },
    {
        id: 'updated_at',
        numeric: false,
        disablePadding: false,
        label: 'updated',
        orderable: true,
        dataType: 'datetime',
    },
]

export const ProductionAddonParamHeadCells = [
    {
        id: 'param',
        numeric: false,
        disablePadding: false,
        label: 'param',
        orderable: false,
    },
]
