import {
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import PropTypes from 'prop-types'
import DataNotFound from '~/components/DataNotFound'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'
import ExternalLink from '~/components/ExternalLink'
import { PurchaseOrderHeadCells } from '~/components/HeaderTable/PurchaseOrderHeadCells'
import PaginationTable from '~/components/PaginationTable'
import ReferencedEntities from '~/components/ReferencedEntities'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { EASY4U_CUSTOMER_ORDER_NUMBER } from '~/constants/externalLink'
import useTable from '~/hooks/useTable'
import PurchaseOrderTableFilters from '../PurchaseOrderTableFilters'
import { usePurchaseOrderQuery } from '../query'

const localization = 'pages.procurement.purchaseOrder.overview.'

const PurchaseOrderBodyTable = ({ isLoading, PurchaseOrders }) => {
    if (isLoading) {
        return <TableLoading colSpan={PurchaseOrderHeadCells.length} />
    }

    return (
        <TableBody>
            {PurchaseOrders.length > 0 ? (
                PurchaseOrders.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                            <ReferencedEntities
                                isTableCell={true}
                                dataType={'purchase-order-id'}
                                dataIdentifier={row.id}
                                dataDisplay={row.id}
                            />
                            <TableCell align='left'>{row.supplier}</TableCell>
                            <TableCell align='left'>{row.entity_id}</TableCell>
                            <TableCell align='left'>
                                {row.contact_name}
                            </TableCell>
                            <TableCell align='left'>
                                <DateTimeLocale datetime={row?.order_date} />
                            </TableCell>
                            <TableCell align='left'>
                                <DateTimeLocale
                                    datetime={row?.expected_delivery_date}
                                />
                            </TableCell>
                            <TableCell align='left'>
                                <ExternalLink
                                    extLink={EASY4U_CUSTOMER_ORDER_NUMBER}
                                    param={row.customer_order_number}
                                    nameButton='Visit'
                                />
                            </TableCell>
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={PurchaseOrderHeadCells.length} />
            )}
        </TableBody>
    )
}

PurchaseOrderBodyTable.propTypes = {
    isLoading: PropTypes.bool,
    PurchaseOrders: PropTypes.arrayOf(PropTypes.object),
}

const PurchaseOrderTable = () => {
    const {
        results: purchaseOrders,
        data,
        isLoading,
        page,
        search,
        order,
        orderBy,
        searchHandler,
        sortHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: usePurchaseOrderQuery,
    })

    return (
        <Paper sx={{ width: '100%', mb: 2 }}>
            <PurchaseOrderTableFilters
                search={search}
                searchHandler={searchHandler}
                onEnterSearch={onEnterSearch}
            />
            <Divider />
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby='tableTitle'
                    size='medium'
                >
                    <TableHeader
                        headCells={PurchaseOrderHeadCells}
                        localization={localization}
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />
                    <PurchaseOrderBodyTable
                        isLoading={isLoading}
                        PurchaseOrders={purchaseOrders}
                    />
                </Table>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}
export default PurchaseOrderTable
