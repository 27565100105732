import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import PageName from '~/components/PageName'
import { PROCUREMENT, PROCUREMENT_DELIVERY } from '~/constants/Routes'
import DeliveryTable from './DeliveryTable'

const Delivery = () => {
    const BreadComponents = [
        { route: PROCUREMENT, title: 'Procurement' },
        {
            route: PROCUREMENT_DELIVERY,
            title: 'Deliveries',
        },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={PROCUREMENT_DELIVERY}
            >
                <div>
                    <PageName name={'Deliveries'} />
                </div>
            </ComponentTooltip>
            <DeliveryTable />
        </>
    )
}

export default Delivery
