import styled from '@emotion/styled'
import {
    Box,
    FormControl,
    Modal,
    Stack,
    Typography,
    Grid,
    Divider,
} from '@mui/material'
import ComponentTooltip from '~/components/ComponentTooltip'
import ErrorMessageInput from '~/components/ErrorMessageInput'
import SelectField from '~/components/FinalForm/SelectField'
import FormInput from '~/components/FormInput/FormInput'
import AddSerialInput from '~/components/FormInput/AddSerialInput'
import SubmitButton from '~/components/SubmitButton'
import { DEFAULT_BRAND } from '~/constants/constants'
import { FLEET_SERIAL } from '~/constants/Routes'
import { useSnackbar } from '~/hooks/useSnackbar'
import { useCallback, useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useAddSerialMutation } from './mutate'
import { useVendorsAddSerialQuery, useDeviceStateQuery } from './query'
import AsterisksRequiredField from '~/components/FormInput/AsterisksRequiredField'
import PropTypes from 'prop-types'

const pagePath = FLEET_SERIAL

const localization = 'pages.device.serial.overview'

const StyledModal = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})
const AddSerialPopup = ({ openPopup, setOpenPopup }) => {
    const { t } = useTranslation()
    const [error, setError] = useState([])
    const { showSnackbar, snackbar } = useSnackbar()
    const { mutate: addSerial, isLoading } = useAddSerialMutation()

    const { data: vendorData } = useVendorsAddSerialQuery({
        has_laptop: 1,
    })

    const { data: deviceStateData } = useDeviceStateQuery({
        menuOption: true,
    })

    const vendors = useMemo(() => {
        if (vendorData) {
            const result = vendorData.data.map((vendor) => ({
                name: vendor.name,
                value: vendor.name,
            }))

            return result
        }
        return []
    }, [vendorData])

    const deviceStates = useMemo(() => {
        if (deviceStateData) {
            return deviceStateData.data.map((state) => ({
                name: state.tid,
                value: state.tid,
            }))
        }
        return []
    }, [deviceStateData])

    const submit = useCallback(
        (payload) =>
            addSerial(payload, {
                onSuccess: (data) => {
                    setOpenPopup(false)
                    setError([])
                    showSnackbar(
                        `${t(`message.addSerialSuccessfully`)} ${data.name}-${
                            data.api
                        }`,
                        'success',
                    )
                },
                onError: (e) => {
                    setError(
                        e.response?.data?.errors ?? e.response?.data?.message,
                    )
                },
            }),
        [addSerial, setOpenPopup, showSnackbar, t],
    )

    const onSubmit = useCallback(
        (values) => {
            submit(values)
        },
        [submit],
    )

    const handleClosePopup = () => {
        setOpenPopup(false)
        setError([])
    }

    return (
        <>
            {snackbar}
            <StyledModal
                open={openPopup}
                onClose={(e) => handleClosePopup()}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box
                    bgcolor={'background.default'}
                    color={'text.primary'}
                    p={3}
                    borderRadius={5}
                >
                    <ComponentTooltip
                        componentId={`serials_add_popup_title`}
                        pagePath={pagePath}
                        align='center'
                    >
                        <Typography
                            variant='h6'
                            color='gray'
                            textAlign='center'
                        >
                            Add serial
                        </Typography>
                    </ComponentTooltip>
                    <Divider sx={{ my: 2 }} />
                    <Form
                        onSubmit={onSubmit}
                        render={({
                            handleSubmit,
                            form,
                            submitting,
                            pristine,
                            values,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {error &&
                                            !error.name &&
                                            !error.brand &&
                                            !error.source && (
                                                <ErrorMessageInput>
                                                    <Box>{error}</Box>
                                                </ErrorMessageInput>
                                            )}
                                        <Stack direction='column' spacing={2}>
                                            <FormControl variant='outlined'>
                                                <ComponentTooltip
                                                    componentId={`serials_add_popup_serial`}
                                                    pagePath={pagePath}
                                                >
                                                    <Typography>
                                                        Serial{' '}
                                                        <AsterisksRequiredField />
                                                    </Typography>
                                                </ComponentTooltip>

                                                <Field
                                                    name='name'
                                                    type='text'
                                                    placeholder='Serial number'
                                                    title='Serial'
                                                    component={AddSerialInput}
                                                />
                                                {error.name && (
                                                    <ErrorMessageInput>
                                                        <Box>
                                                            {error.name[0]}
                                                        </Box>
                                                    </ErrorMessageInput>
                                                )}
                                            </FormControl>

                                            <FormControl variant='outlined'>
                                                <FormControl
                                                    sx={{ width: '100%' }}
                                                >
                                                    <Field
                                                        name='brand'
                                                        component={SelectField}
                                                        items={vendors}
                                                        localization={
                                                            localization
                                                        }
                                                        defaultValue={
                                                            DEFAULT_BRAND
                                                        }
                                                        componentId={`serials_add_popup_brand`}
                                                        pagePath={pagePath}
                                                        nameSelect='brand'
                                                        isRequired={true}
                                                    />
                                                </FormControl>
                                                {error.brand && (
                                                    <ErrorMessageInput>
                                                        <Box>
                                                            {error.brand[0]}
                                                        </Box>
                                                    </ErrorMessageInput>
                                                )}
                                            </FormControl>

                                            <FormControl variant='outlined'>
                                                <FormControl
                                                    sx={{ width: '100%' }}
                                                >
                                                    <Field
                                                        name='initial_state'
                                                        component={SelectField}
                                                        items={deviceStates}
                                                        localization={
                                                            localization
                                                        }
                                                        componentId={`serials_add_popup_state`}
                                                        pagePath={pagePath}
                                                        nameSelect='initial_state'
                                                        isRequired={true}
                                                    />
                                                </FormControl>
                                                {error.initial_state && (
                                                    <ErrorMessageInput>
                                                        <Box>
                                                            {
                                                                error
                                                                    .initial_state[0]
                                                            }
                                                        </Box>
                                                    </ErrorMessageInput>
                                                )}
                                            </FormControl>

                                            <FormControl variant='outlined'>
                                                <Typography>
                                                    Source{' '}
                                                    <AsterisksRequiredField />
                                                </Typography>
                                                <Field
                                                    name='source'
                                                    type='text'
                                                    placeholder='Source'
                                                    component={FormInput}
                                                    isRequired={true}
                                                />
                                                {error.source && (
                                                    <ErrorMessageInput>
                                                        <Box>
                                                            {error.source[0]}
                                                        </Box>
                                                    </ErrorMessageInput>
                                                )}
                                            </FormControl>

                                            <FormControl variant='outlined'>
                                                <Typography>PKID</Typography>
                                                <Field
                                                    name='pkid'
                                                    type='text'
                                                    placeholder='PKID'
                                                    component={FormInput}
                                                />
                                            </FormControl>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack
                                            direction='row-reverse'
                                            spacing={2}
                                        >
                                            <SubmitButton
                                                isLoading={isLoading}
                                                handleSubmit={handleSubmit}
                                                name={`${t('button.add')}`}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    />
                </Box>
            </StyledModal>
        </>
    )
}

AddSerialPopup.propTypes = {
    openPopup: PropTypes.bool,
    setOpenPopup: PropTypes.func,
}

export default AddSerialPopup
