import { TableCell, TableRow, TextField } from '@mui/material'
import { LoadingButton } from '~/components/Button'
import DataNotFound from '~/components/DataNotFound'
import PartCodeTableComponent from '~/components/PartCodeTable'
import useFormFieldValidationSyncCallback from '~/hooks/useFormFieldValidationSyncCallback'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectShowedPartCode } from '~/store/partcode/selector'
import {
    pushSelectedPartCodes,
    pushShowPartCodes,
    unsetSelectedPartCodes,
} from '~/store/partcode/slice'
import { renderBooleanValue, textValidateRegex } from '~/utils/helpers'
import { string } from 'yup'
import PropTypes from 'prop-types'
import { selectDevice } from '~/store/device/selector'
import { useTranslation } from 'react-i18next'

const localization = 'pages.device.serial.detail.'

const partCodeTableHeader = [
    {
        id: 'code',
        numeric: true,
        label: 'code',
    },
    {
        id: 'name',
        numeric: true,
        label: 'name',
    },
    {
        id: 'type',
        numeric: true,
        label: 'type',
    },
    {
        id: 'serializable',
        numeric: true,
        label: 'serializable',
        align: 'center',
    },
    {
        id: 'ppid',
        numeric: true,
        label: 'ppid',
        align: 'center',
    },
    {
        id: 'causes',
        numeric: true,
        label: 'causes',
        align: 'center',
    },
    {
        id: 'action',
        numeric: true,
        label: 'action',
        align: 'center',
    },
]

const PartCodePPIDComponent = ({
    partCode,
    ppid,
    setPpid,
    initComp,
    setInitComp,
}) => {
    const { t } = useTranslation()
    const [helperText, setHelperText] = useState('')
    const device = useSelector(selectDevice)
    const ppidRegex = device?.vendor?.ppid_validation_regex ?? null

    const ppidValidator = useFormFieldValidationSyncCallback(
        string().label(`PPID`).required(),
    )

    const ppidMaxCharactor = useFormFieldValidationSyncCallback(
        string().label('PPID').required(t('message.ppidAttemptedCharacter')),
        [ppidRegex],
    )

    useEffect(() => {
        if (partCode.serializable && ppid === '' && initComp === false) {
            setHelperText(ppidValidator())
            setInitComp(true)
        }

        if (
            ppid !== '' &&
            ppidRegex &&
            initComp === false &&
            !textValidateRegex(ppidRegex, ppid)
        ) {
            setHelperText(ppidMaxCharactor())
            setInitComp(true)
        }
    }, [
        ppid,
        partCode,
        ppidValidator,
        initComp,
        setInitComp,
        ppidMaxCharactor,
        ppidRegex,
    ])

    const onChange = useCallback(
        (e) => {
            setPpid(e.target.value)
            setHelperText('')
            setInitComp(false)
        },
        [setPpid, setInitComp],
    )
    return (
        <TextField
            onChange={onChange}
            error={!!helperText}
            helperText={helperText}
            defaultValue={ppid}
            disabled={partCode.added}
        />
    )
}

PartCodePPIDComponent.propTypes = {
    partCode: PropTypes.object,
    ppid: PropTypes.string,
    setPpid: PropTypes.func,
    initComp: PropTypes.bool,
    setInitComp: PropTypes.func,
}

const PartCodeRow = ({ partCode, initComp, setInitComp, setRemovedParts }) => {
    const [ppid, setPpid] = useState('')
    const showingPartCode = useSelector(selectShowedPartCode)

    const dispatch = useDispatch()
    useEffect(() => {
        const newPartCode = { ...partCode, qty: 1, ppid }
        dispatch(pushSelectedPartCodes(newPartCode))
    }, [dispatch, partCode, ppid])

    const detachPartCodeFromDispatch = useCallback(
        (partCode) => {
            const newShowedPartCode = showingPartCode?.filter(
                (part) => part.code !== partCode.code,
            )
            dispatch(pushShowPartCodes(newShowedPartCode))
            dispatch(unsetSelectedPartCodes(partCode.code))
        },
        [dispatch, showingPartCode],
    )

    const renderCauseTags = useCallback((causes) => {
        return causes?.map((x) => x.tag).join(', ')
    }, [])

    return (
        <TableRow
            key={partCode.code}
            sx={{
                '&:last-child td, &:last-child th': {
                    border: 0,
                },
            }}
        >
            <TableCell>
                {partCode.part_code
                    ? partCode.part_code
                    : partCode.code
                      ? partCode.code
                      : '---'}
            </TableCell>
            <TableCell align='left'>
                {partCode.name ? partCode.name : '---'}
            </TableCell>
            <TableCell align='left'>
                {partCode.type ? partCode.type : '---'}
            </TableCell>
            <TableCell align='center'>
                {renderBooleanValue(partCode.serializable)}
            </TableCell>
            <TableCell align='center'>
                <PartCodePPIDComponent
                    {...{
                        partCode,
                        ppid,
                        setPpid,
                        initComp,
                        setInitComp,
                    }}
                />
            </TableCell>
            <TableCell align='center'>
                {renderCauseTags(partCode.causes)}
            </TableCell>
            <TableCell align='center'>
                <LoadingButton
                    onClick={() => {
                        detachPartCodeFromDispatch(partCode)
                        if (typeof setRemovedParts === 'function') {
                            setRemovedParts((prevState) => {
                                if (typeof prevState === 'undefined') {
                                    prevState = {}
                                }
                                prevState[partCode.code] = true
                                return { ...prevState }
                            })
                        }
                    }}
                    label='Remove'
                    bgColor='#102F44'
                />
            </TableCell>
        </TableRow>
    )
}

PartCodeRow.propTypes = {
    partCode: PropTypes.object,
    initComp: PropTypes.bool,
    setInitComp: PropTypes.func,
    setRemovedParts: PropTypes.func,
}

const PartCodeTable = ({ initComp, setInitComp, setRemovedParts }) => {
    const showingPartCode = useSelector(selectShowedPartCode)

    return (
        <PartCodeTableComponent
            partCodeTableHeader={partCodeTableHeader}
            localization={localization}
        >
            {showingPartCode?.length >= 1 ? (
                showingPartCode?.map((partCode) => (
                    <PartCodeRow
                        key={partCode.code}
                        {...{
                            partCode,
                            initComp,
                            setInitComp,
                            setRemovedParts,
                        }}
                    />
                ))
            ) : (
                <DataNotFound colSpan={partCodeTableHeader.length} />
            )}
        </PartCodeTableComponent>
    )
}

PartCodeTable.propTypes = {
    initComp: PropTypes.bool,
    setInitComp: PropTypes.func,
    setRemovedParts: PropTypes.func,
}

export default PartCodeTable
