import { Box, FormControl, Grid, Typography } from '@mui/material'
import arrayMutators from 'final-form-arrays'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Field, Form, FormSpy } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingButton } from '~/components/Button'
import AutoCompleteInput from '~/components/FormInput/AutoCompleteInput'
import CheckboxesField from '~/components/FormInput/CheckboxesField'
import { selectDevice } from '~/store/device/selector'
import { selectShowedPartCode } from '~/store/partcode/selector'
import {
    pushShowPartCodes,
    unsetSelectedPartCodes,
} from '~/store/partcode/slice'
import { LOCALIZATION } from '../constants'
import { useDeviceCauseQuery } from '../query'
import Layout from './Section/Layout'

const CauseSection = ({
    show,
    serial = false,
    setSelectedCauses = () => {},
    setClickedCause = () => {},
    defaultCauses = [],
    setRemovedParts,
    dispatchContent,
}) => {
    const device = useSelector(selectDevice)
    const { t } = useTranslation()
    const locale = localStorage.getItem('LOCALE')
    const [searchCauseList, setSearchCauseList] = useState([])
    const [currentCauseList, setCurrentCauseList] = useState([])

    const onFetchCauseSuccess = (data) => {
        const causes = data.slice(0, 5)
        const mapValueCauses = causes.map((cause) => {
            if (defaultCauses?.some((c) => c.id === cause.id)) {
                return { ...cause, value: true }
            }
            return { ...cause, value: false }
        })
        prevCauses.current = mapValueCauses
        setCurrentCauseList(mapValueCauses)
        const causeIdList = []
        causes.forEach((c) => causeIdList.push(c.id))

        const currentSearchList = data
            ?.filter((s) => !causeIdList.includes(s.id))
            .map((obj) => {
                return { ...obj, value: obj }
            })

        setSearchCauseList(currentSearchList)
    }

    const { isLoading: isFetchingCauses } = useDeviceCauseQuery(
        {
            serial: serial ?? device?.serial,
            locale,
        },
        onFetchCauseSuccess,
    )

    const prevCauses = useRef(currentCauseList)

    const [showingPartCode, setShowingPartCode] = useState([])
    const dispatch = useDispatch()
    const storedShowingPartCode = useSelector(selectShowedPartCode)
    const missingCauses = useMemo(() => {
        if (storedShowingPartCode?.length > 0) {
            return storedShowingPartCode
                .filter((partCode) => {
                    return (
                        typeof partCode.cause_id !== 'undefined' &&
                        typeof partCode.causes === 'undefined'
                    )
                })
                .map((partCode) => {
                    return {
                        code: partCode.code,
                        cause_id: partCode.cause_id,
                    }
                })
        }
        return []
    }, [storedShowingPartCode])
    useEffect(() => {
        if (
            missingCauses.length > 0 &&
            typeof dispatchContent !== 'undefined' &&
            storedShowingPartCode?.length > 0
        ) {
            const hasCausePartCodes = storedShowingPartCode.map((partCode) => {
                const causes = dispatchContent.parts
                    .map((item) => {
                        if (item?.cause?.id === partCode.cause_id) {
                            return {
                                id: item.cause.id,
                                tag: item.cause.tid,
                            }
                        }
                        return null
                    })
                    .filter((item) => item !== null)
                    .filter(
                        (v, i, a) =>
                            a.findIndex(
                                (t) => t.id === v.id && t.tag === v.tag,
                            ) === i,
                    )
                return { ...partCode, causes }
            })
            dispatch(pushShowPartCodes(hasCausePartCodes))
            setShowingPartCode([...hasCausePartCodes])
        } else {
            setShowingPartCode(
                storedShowingPartCode?.length > 0
                    ? [...storedShowingPartCode]
                    : [],
            )
        }
    }, [missingCauses, storedShowingPartCode, dispatch, dispatchContent])

    const queryClient = useQueryClient()

    const updateCauseList = useCallback(
        (searchCause) => {
            const causes = [...currentCauseList, searchCause]
            prevCauses.current = causes

            setCurrentCauseList(causes)

            const causesSearchNewList = searchCauseList.filter(
                (cause) => cause.id !== searchCause.id,
            )
            setSearchCauseList(causesSearchNewList)
        },
        [currentCauseList, searchCauseList],
    )

    const handleUpdateCauseList = (causes) => {
        // find clicked cause
        const clickedCause = causes.find((cause) => {
            const itemY = prevCauses.current.find(
                (yItem) => yItem.id === cause.id,
            )
            return itemY && cause.value !== itemY.value
        })
        setClickedCause({
            causeId: clickedCause.id,
            causeTag: {
                id: clickedCause.id,
                tag: clickedCause.tid,
            },
            causeValue: clickedCause.value,
        })

        // case: uncheck cause
        // 1. remove cause tag in part code list
        // 2. if no has cause tag for part code, remove them from showing part codes.
        // if part code removed, unset selectedPartCode action
        if (!clickedCause.value) {
            const currentShowingPartCodes = [...showingPartCode]

            // filter and get part codes with cause has value true
            const detachUncheckCausePartCodes = currentShowingPartCodes.map(
                (item) => {
                    const causes = item?.causes?.filter(
                        (causeItem) => causeItem.id !== clickedCause.id,
                    )
                    return { ...item, causes }
                },
            )

            // remove part code has cause = [], mean that no has causes and no manual selected.
            const hasCausePartCodes = detachUncheckCausePartCodes.filter(
                (partCode) => partCode.causes?.length !== 0,
            )
            dispatch(pushShowPartCodes(hasCausePartCodes))

            const noHasCausePartCodes = detachUncheckCausePartCodes.filter(
                (partCode) => partCode.causes?.length === 0,
            )
            noHasCausePartCodes.forEach((partCode) => {
                dispatch(unsetSelectedPartCodes(partCode.code))
                if (typeof setRemovedParts === 'function') {
                    setRemovedParts((prevState) => {
                        if (typeof prevState === 'undefined') {
                            prevState = {}
                        }
                        prevState[partCode.code] = true
                        return { ...prevState }
                    })
                }
            })
        }

        setCurrentCauseList(causes)
        prevCauses.current = causes
        const selectedCauses = causes.filter((cause) => cause.value === true)
        setSelectedCauses(selectedCauses)
    }

    const refetchCauses = useCallback(() => {
        queryClient.invalidateQueries(['device_causes'])
    }, [queryClient])

    if (!show || !device) return <></>
    const onSubmit = async () => {}

    return (
        <Layout>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 2,
                    marginInline: 0,
                    pr: 4,
                }}
            >
                <Typography variant='h6' gutterBottom>
                    {t(`${LOCALIZATION}.rootCauses`)}
                </Typography>
                <LoadingButton
                    onClick={refetchCauses}
                    loading={isFetchingCauses}
                    label='Refresh'
                />
            </Box>
            <Form
                onSubmit={onSubmit}
                initialValues={{ causes: currentCauseList }}
                mutators={{
                    ...arrayMutators,
                }}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <FormSpy
                            subscription={{ values: true, dirtyFields: true }}
                            onChange={(ev) => {
                                if (
                                    ev.dirtyFields &&
                                    Object.prototype.hasOwnProperty.call(
                                        ev.dirtyFields,
                                        'causes',
                                    )
                                ) {
                                    handleUpdateCauseList(ev.values.causes)
                                }
                            }}
                        />
                        <Grid container direction='row-reverse'>
                            <Grid item xs={12} md={4}>
                                <FormControl sx={{ width: '100%', px: 4 }}>
                                    <Field
                                        name='cause_id'
                                        component={AutoCompleteInput}
                                        items={searchCauseList}
                                        onUpdate={updateCauseList}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter')
                                                e.preventDefault()
                                        }}
                                        noValue
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Grid container>
                                    <CheckboxesField
                                        localization={LOCALIZATION}
                                        name={`causes`}
                                        label={false}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )}
            />
        </Layout>
    )
}
CauseSection.propTypes = {
    show: PropTypes.bool,
    serial: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    setSelectedCauses: PropTypes.func,
    setClickedCause: PropTypes.func,
    defaultCauses: PropTypes.array,
    setRemovedParts: PropTypes.func,
    dispatchContent: PropTypes.object,
}

export default CauseSection
