import { connect } from 'react-redux'
import { selectUser } from '~/store/auth/selector'
import MasterLayout from './MasterLayout'

const mapStateToProps = (state) => ({
    isAuthenticated: !!selectUser(state),
})

const mapDispatch = {}

export default connect(mapStateToProps, mapDispatch)(MasterLayout)
