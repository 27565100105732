import dispatchApi from '~/api/dispatchApi'
import { useMutation, useQueryClient } from 'react-query'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { parseBlobErrorMessage } from '~/utils/helpers'

export const useExportDispatchMutation = () => {
    return useMutation(
        ['dispatch_export'],
        async (payload) => {
            const { data } = await dispatchApi.getDispatchExport(payload)
            return data
        },
        {
            onSuccess: (data) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `dispacth.csv`)
                document.body.appendChild(link)
                link.click()
            },
            onError: () => {},
            retry: false,
        },
    )
}

export const useDispatchScanSerialDashboardMutation = () => {
    return useMutation(async ({ serial }) => {
        const { data } = await dispatchApi.scanSerial(serial)

        return data
    })
}

export const useCloseDispatchMutation = () => {
    const queryClient = useQueryClient()
    const { openSnackbar } = useDialogContext()
    return useMutation(
        ['state_change_for_dispatch'],
        async ({ id }) => {
            const { data } = await dispatchApi.close(id)
            return data
        },
        {
            onSuccess: (data) => {
                openSnackbar({
                    message: data.message
                        ? data.message
                        : 'Successfully updated!',
                    type: 'success',
                })
                queryClient.invalidateQueries('dispatch_detail')
            },
        },
    )
}

export const useImportLenovoDispatchesMutation = () => {
    return useMutation(['lenovo_dispatches_import'], async (file) => {
        const data = new FormData()
        data.append('file', file)
        const results = await dispatchApi.importLenovoExcelFile(data)
        return results
    })
}

export const useExportLenovoDispatchMutation = () => {
    const { openSnackbar } = useDialogContext()
    return useMutation(
        ['export_lenovo_dispatches'],
        async () => {
            const { data } = await dispatchApi.lenovoDispatchesExport()
            return data
        },
        {
            onSuccess: (data) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'lenovo_dispatches.xlsx')
                document.body.appendChild(link)
                link.click()
            },
            onError: async (error) => {
                openSnackbar({
                    message: await parseBlobErrorMessage(error),
                    type: 'error',
                })
            },
            retry: false,
        },
    )
}
