import { ISSUE_ISSUE } from '~/constants/Routes'
import { useParams } from 'react-router-dom'
import { Layout } from '../components'
import IssueTabs from './IssueTabs'

const IssueDetail = () => {
    const { issueId } = useParams()

    const breadCrumbConfig = [
        { route: ISSUE_ISSUE, title: 'Issues' },
        { page: 'detail', title: issueId },
    ]

    return (
        <Layout breadcrumbs={breadCrumbConfig}>
            <IssueTabs />
        </Layout>
    )
}
export default IssueDetail
