import MuiLoadingButton from '@mui/lab/LoadingButton'
import { Button, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import ComponentTooltip from '~/components/ComponentTooltip'
import Search from '~/components/Search/Search'
import Dialog from '~/components/dialogs/Dialog'
import { ISSUE_DISPATCH_ADD } from '~/constants/Routes'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setDispatchSerial } from '~/store/dispatch/slice'
import {
    clearSelectedPartCodeTypes,
    clearSelectedPartCodes,
    clearShowedPartCode,
} from '~/store/partcode/slice'
import { LOCALIZATION } from '../constants'
import DeviceSection from './DeviceSection'
import Layout from './Section/Layout'

const pagePath = ISSUE_DISPATCH_ADD

const SerialSearchSection = ({
    searchedSerial,
    setSearchedSerial = () => {},
    step,
    setStep = () => {},
    setIsFoundSerialData = () => {},
    isEditDispatch = false,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [searchValue, setSearchValue] = useState('')
    const [addDispatchConfirmDialog, setAddDispatchConfirmDialog] = useState({
        isOpen: false,
        searchedSerial: '',
    })
    const handleEnterSearch = (event) => {
        if (event.key === 'Enter') {
            setSearchedSerial(null)
            event.preventDefault()
            setAddDispatchConfirmDialog((old) => ({
                ...old,
                isOpen: true,
                searchedSerial: event.target.value,
            }))
        }
    }

    const handleSearch = (e) => {
        setSearchValue(e.target.value.toUpperCase())
    }

    const handleConfirmSerial = () => {
        setAddDispatchConfirmDialog((old) => ({
            ...old,
            isOpen: false,
        }))
        setSearchedSerial(addDispatchConfirmDialog.searchedSerial)
        dispatch(setDispatchSerial(addDispatchConfirmDialog.searchedSerial))
        dispatch(clearSelectedPartCodes())
        dispatch(clearSelectedPartCodeTypes())
        dispatch(clearShowedPartCode())
    }

    useEffect(() => {
        setSearchValue(searchedSerial)
    }, [searchedSerial])

    return (
        <>
            {!isEditDispatch && (
                <Layout>
                    <ComponentTooltip
                        componentId={`dispatch_add_scan_serial`}
                        pagePath={pagePath}
                    >
                        <Typography>
                            {t(`${LOCALIZATION}.scanSerial`)}
                        </Typography>
                    </ComponentTooltip>
                    <Search
                        placeholder={t(`${LOCALIZATION}.scanSerial`)}
                        autoFocus
                        onKeyPress={handleEnterSearch}
                        onChange={handleSearch}
                        value={searchValue}
                    />
                </Layout>
            )}
            <DeviceSection
                {...{ searchedSerial, step, setStep, setIsFoundSerialData }}
                isCreateDispatch
            />
            <Dialog
                open={
                    !!(
                        addDispatchConfirmDialog.isOpen &&
                        addDispatchConfirmDialog.searchedSerial
                    )
                }
                onClose={() => {}}
                title={t(`${LOCALIZATION}.confirmAddDispatch`)}
                content={t(`${LOCALIZATION}.confirmAddDispatchContent`, {
                    serial: addDispatchConfirmDialog.searchedSerial,
                })}
                actions={
                    <>
                        <Button
                            autoFocus
                            onClick={() =>
                                setAddDispatchConfirmDialog((old) => ({
                                    ...old,
                                    isOpen: false,
                                }))
                            }
                        >
                            {t(`dialog.cancel`)}
                        </Button>
                        <MuiLoadingButton
                            loading={false}
                            onClick={handleConfirmSerial}
                        >
                            {t(`dialog.confirm`)}
                        </MuiLoadingButton>
                    </>
                }
            />
        </>
    )
}

SerialSearchSection.propTypes = {
    searchedSerial: PropTypes.string,
    setSearchedSerial: PropTypes.func,
    step: PropTypes.number,
    setStep: PropTypes.func,
    setIsFoundSerialData: PropTypes.func,
    isEditDispatch: PropTypes.bool,
}

SerialSearchSection.defaultProps = {
    setSearchedSerial: () => {},
    setStep: () => {},
    setIsFoundSerialData: () => {},
    isEditDispatch: false,
}

export default SerialSearchSection
