import {
    ADMINISTRATOR_USER_DETAIL,
    ADMINISTRATOR_USER_DISABLED_DETAIL,
} from '~/constants/Routes'
import LabelStatusUser from '~/pages/User/LabelStatusUser'

export const UserHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
        clickable: true,
        link: ADMINISTRATOR_USER_DETAIL,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'fullname',
        orderable: true,
        dataType: 'image_name',
        customVal: (row) => {
            return { url: row.media?.preview_url, name: row.name }
        },
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'email',
        orderable: true,
    },
    {
        id: 'role_name',
        numeric: false,
        disablePadding: false,
        label: 'role',
        orderable: true,
    },
    {
        id: 'team_name',
        numeric: false,
        disablePadding: false,
        label: 'team',
        orderable: true,
    },
    {
        id: 'workbench_name',
        numeric: false,
        disablePadding: false,
        label: 'workbench',
        orderable: true,
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'status',
        orderable: true,
        styledCell: (row) => {
            return row.status ? <LabelStatusUser status={row.status} /> : null
        },
    },
]

export const DisabledUserHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
        clickable: true,
        link: ADMINISTRATOR_USER_DISABLED_DETAIL,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'fullname',
        orderable: true,
        dataType: 'image_name',
        customVal: (row) => {
            return { url: row.media?.preview_url, name: row.name }
        },
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'email',
        orderable: true,
    },
    {
        id: 'role',
        numeric: false,
        disablePadding: false,
        label: 'role',
        orderable: true,
    },
    {
        id: 'updated_at',
        numeric: false,
        disablePadding: false,
        label: 'disabledDate',
        orderable: true,
        dataType: 'datetime',
    },
]
