import { Box, Toolbar } from '@mui/material'
import PropTypes from 'prop-types'

const TableToolbarContainer = ({ children }) => {
    return (
        <Toolbar>
            <Box sx={{ flexGrow: 1 }}></Box>
            {children}
        </Toolbar>
    )
}
TableToolbarContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
}
export default TableToolbarContainer
