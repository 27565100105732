import deliveryApi from '~/api/deliveryApi'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

export const useExportDeliveryMutation = () => {
    return useMutation(
        ['delivery_export'],
        async (payload) => {
            const { data } = await deliveryApi.getDeliveriesExport(payload)
            return data
        },
        {
            onSuccess: (data) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `procurement_delivery.csv`)
                document.body.appendChild(link)
                link.click()
            },
            onError: () => {},
            retry: false,
        },
    )
}

export const useExportDeliveryDetailMutation = () => {
    const { id } = useParams()

    return useMutation(
        ['delivery_export'],
        async (payload) => {
            const { data } = await deliveryApi.getDeliveryDetailExport(
                id,
                payload,
            )
            return data
        },
        {
            onSuccess: (data) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `procurement_delivery_detail.csv`)
                document.body.appendChild(link)
                link.click()
            },
            onError: () => {},
            retry: false,
        },
    )
}
