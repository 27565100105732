import { vendorApi } from '~/api'
import deviceApi from '~/api/deviceApi'
import deviceStateApi from '~/api/deviceStateApi'
import { useQuery } from 'react-query'
import { removeEmptyValue } from '~/utils/helpers'

export const useSerialOverviewQuery = (params) => {
    return useQuery(
        ['device_serial_overview', params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await deviceApi.getSerialOverview(p)
            localStorage.removeItem('SEARCH_SERIAL')
            return { data: data.data, total: data.meta.total }
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useVendorsQuery = () => {
    return useQuery(
        ['vendors'],
        async () => {
            const { data } = await vendorApi.getVendors()
            return data
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useVendorsAddSerialQuery = (param) => {
    return useQuery(
        ['vendors_add_serial', param],
        async () => {
            const { data } = await vendorApi.getVendors(param)
            return data
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useDeviceStateQuery = (params) => {
    return useQuery(
        ['device_states'],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await deviceStateApi.getDeviceStates(p)
            return { data: data.data, total: data.meta?.total }
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}
