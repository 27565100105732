import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import PageName from '~/components/PageName'
import { PROCUREMENT, PROCUREMENT_PURCHASEORDER } from '~/constants/Routes'
import PurchaseOrderTable from './PurchaseOrderTable'
import ListToolbarWrapper from '~/components/ListToolbarWrapper'

const PurchaseOrder = () => {
    const BreadComponents = [
        { route: PROCUREMENT, title: 'Procurement' },
        {
            route: PROCUREMENT_PURCHASEORDER,
            title: 'PurchaseOrders',
        },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={PROCUREMENT_PURCHASEORDER}
            >
                <div>
                    <PageName name={'Purchase order'} />
                </div>
            </ComponentTooltip>
            <ListToolbarWrapper />
            <PurchaseOrderTable />
        </>
    )
}

export default PurchaseOrder
