import orderConfirmationApi from '~/api/orderConfirmationApi'
import { useMutation } from 'react-query'

export const useExportOrderMutation = () => {
    return useMutation(
        ['order_export'],
        async (payload) => {
            const { data } = await orderConfirmationApi.getOrdersExport(payload)
            return data
        },
        {
            onSuccess: (data) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `order_confirmation.csv`)
                document.body.appendChild(link)
                link.click()
            },
            onError: () => {},
            retry: false,
        },
    )
}
