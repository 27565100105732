import { useCallback } from 'react'

const useFormFieldValidationSyncCallback = (schema) => {
    return useCallback(
        (value) => {
            try {
                schema.validateSync(value, { abortEarly: false })
            } catch (yupError) {
                if (!yupError.inner) {
                    return undefined
                }

                const errors = []
                if (yupError.inner.length === 0) {
                    errors.push(yupError.message)
                    return errors
                }

                yupError.inner.forEach((err) => {
                    errors.push(err.message)
                })

                return errors
            }

            return undefined
        },
        [schema],
    )
}

export default useFormFieldValidationSyncCallback
