export const LOCALIZATION = 'pages.dispatches.dispatch.overview.'

export const DispatchShipmentReportHeadCells = [
    {
        id: 'dispatch_id',
        numeric: false,
        disablePadding: false,
        label: 'dispatchId',
        orderable: false,
    },
    {
        id: 'callnumber',
        numeric: false,
        disablePadding: false,
        label: 'dispatchNumber',
        orderable: false,
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: 'action',
        orderable: false,
    },
]
