import { ADMINISTRATOR_DEFINITION_REGIONS_DETAIL } from '~/constants/Routes'

export const RegionHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
        clickable: true,
        link: ADMINISTRATOR_DEFINITION_REGIONS_DETAIL,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        orderable: true,
    },
    {
        id: 'main_service_center',
        numeric: false,
        disablePadding: false,
        label: 'mainService',
        orderable: false,
        relationship: 'name',
        customVal: (row) => {
            return row.main_service_center?.name
        },
    },

    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'createdAt',
        orderable: false,
        dataType: 'datetime',
    },
    {
        id: 'updated_at',
        numeric: false,
        disablePadding: false,
        label: 'updatedAt',
        orderable: false,
        dataType: 'datetime',
    },
]
