import FormBodyWrapper from '~/components/FormBodyWrapper'
import useTeamFormData from '../hooks/useTeamFormData'
import TeamForm from '../TeamForm'
import PropTypes from 'prop-types'

function Body({ localization, form }) {
    const { InputFieldGroup, selectGroup } = useTeamFormData()

    return (
        <FormBodyWrapper>
            <TeamForm
                {...{ localization, InputFieldGroup, selectGroup, form }}
            />
        </FormBodyWrapper>
    )
}

Body.propTypes = {
    localization: PropTypes.string,
    form: PropTypes.any,
}

export default Body
