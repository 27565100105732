import { Toolbar as MuiToolbar, Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_ROLE,
    ADMINISTRATOR_ROLE_CREATE,
} from '~/constants/Routes'
import { useNavigate } from 'react-router-dom'
import Table from './Table'
import { selectSnackbar } from '~/store/snackbar/selector'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from '~/hooks/useSnackbar'
import { useEffect } from 'react'
import { unsetSnackbar } from '~/store/snackbar/slice'
import ToolBarButton from '../../../components/ToolBarButton'
import AddIcon from '@mui/icons-material/Add'
import PageName from '~/components/PageName'
import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import PropTypes from 'prop-types'

const Toolbar = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <>
            <MuiToolbar disableGutters={false}>
                <Box sx={{ flexGrow: 1 }} />

                <Stack
                    direction='row'
                    justifyContent='right'
                    alignItems='right'
                    minWidth='46px'
                    spacing={2}
                >
                    <ToolBarButton
                        icon={<AddIcon />}
                        name={t(`${props.localization}.create`)}
                        handleAction={() => {
                            navigate(ADMINISTRATOR_ROLE_CREATE)
                        }}
                    />
                </Stack>
            </MuiToolbar>
        </>
    )
}

Toolbar.propTypes = { localization: PropTypes.string }

const Role = () => {
    const snackbarData = useSelector(selectSnackbar)
    const { showSnackbar, snackbar } = useSnackbar()
    const dispatch = useDispatch()
    useEffect(() => {
        if (snackbarData) {
            showSnackbar(snackbarData.message, snackbarData.type)
        }
        return () => {
            dispatch(unsetSnackbar({}))
        }
    })

    const BreadComponents = [
        { route: ADMINISTRATOR, title: 'Administrator' },
        {
            route: ADMINISTRATOR_ROLE,
            title: 'Roles',
        },
    ]

    return (
        <>
            {snackbar}
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_ROLE}
            >
                <div>
                    <PageName name='Roles' />
                </div>
            </ComponentTooltip>

            <Toolbar localization={`pages.administrator.roles`} />
            <Table />
        </>
    )
}

export default Role
