import { useMutation } from 'react-query'
import { bomApi } from '~/api/bomApi'

export const useUpdateBom = () => {
    return useMutation(async ({ id, payload }) => {
        return await bomApi.updateBom(id, payload)
    })
}

export const useRefreshBoms = () => {
    return useMutation(async ({ issue_id }) => {
        return await bomApi.refreshBoms(issue_id)
    })
}

export const useUpdateBomLabel = () => {
    return useMutation(async ({ id, payload }) => {
        return await bomApi.updateBomLabel(id, payload)
    })
}
