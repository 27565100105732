import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { deviceApi } from '~/api'
import ordersApi from '~/api/production/ordersApi'
import {
    FLEET_SERIAL,
    FLEET_SERIAL_DETAIL,
    PRODUCTION_ADD_ONS,
    PRODUCTION_ORDERS,
    PRODUCTION_ORDERS_DETAIL,
} from '~/constants/Routes'
import { setDevice, setMessageSearchSerial } from '~/store/device/slice'
import { parseApiErrorMessage } from '~/utils/helpers'

const ACTION_MAP = {
    [PRODUCTION_ORDERS]: {
        onSuccess: (data, navigate) => {
            navigate(
                PRODUCTION_ORDERS_DETAIL.replace(
                    ':production_order_nr',
                    data?.data?.production_order_nr,
                ) + '?autoopen=true',
            )
        },
        onError: (e, showSnackbar) => {
            showSnackbar(parseApiErrorMessage(e), 'error')
        },
    },
    [PRODUCTION_ADD_ONS]: {
        onSuccess: () => 'fetchAddOnsData',
        onError: () => 'fetchAddOnsData',
    },
    default: {
        onSuccess: (data, navigate, dispatch, serial, setOpenCamera) => {
            if (data.total) {
                localStorage.setItem('SEARCH_SERIAL', serial)
                navigate(FLEET_SERIAL)
            } else {
                dispatch(setDevice(data))
                navigate(FLEET_SERIAL_DETAIL.replace(':id', data.id))
            }
            setOpenCamera(false)
        },
        onError: (e, dispatch, setMessageSearchSerial, serial) => {
            dispatch(
                setMessageSearchSerial({ status: e.response.status, serial }),
            )
        },
    },
}

export const useGlobalQuery = ({
    pathname,
    serial,
    showSnackbar,
    setSerial = () => {},
    setOpenCamera = () => {},
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    return useQuery(
        ['scan-flow', serial],
        async () => {
            if (pathname.startsWith(PRODUCTION_ORDERS)) {
                const data = await ordersApi.getOrder(serial)
                return data.data
            } else if (pathname.startsWith(PRODUCTION_ADD_ONS)) {
                return 'fetchAddOnsData'
            } else {
                const { data } = await deviceApi.getDeviceDetailSearch(serial)
                return data.meta?.total
                    ? { data: data.data, total: data.meta.total }
                    : data.data
            }
        },
        {
            onSuccess: (data) => {
                const actions = ACTION_MAP[pathname] || ACTION_MAP.default
                actions.onSuccess(
                    data,
                    navigate,
                    dispatch,
                    serial,
                    setOpenCamera,
                )
            },
            onError: (e) => {
                const actions = ACTION_MAP[pathname] || ACTION_MAP.default
                actions.onError(e, dispatch, setMessageSearchSerial, serial)
            },
            retry: false,
            enabled: !!serial,
        },
    )
}
