import PropTypes from 'prop-types'
import { FormControl, MenuItem, Select, Typography } from '@mui/material'

const CameraSelection = ({ value, changeCamera, cameraList }) => {
    return (
        <FormControl sx={{ m: 1, width: 240 }} size='small'>
            <Select value={value} onChange={changeCamera}>
                {cameraList.map((camera) => (
                    <MenuItem
                        key={camera.deviceId}
                        value={camera.deviceId}
                        name={camera.label}
                    >
                        <Typography
                            sx={{
                                maxWidth: '170px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {camera.label}
                        </Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

CameraSelection.propTypes = {
    value: PropTypes.any,
    changeCamera: PropTypes.func,
    cameraList: PropTypes.array,
}

export default CameraSelection
