export const InvoiceHeadCells = [
    {
        id: 'suppliers.name',
        numeric: false,
        disablePadding: false,
        label: 'supplier',
        orderable: true,
    },
    {
        id: 'invoice_number',
        numeric: false,
        disablePadding: false,
        label: 'invoiceNumber',
        orderable: true,
    },
    {
        id: 'invoice_amount_incl',
        numeric: false,
        disablePadding: false,
        label: 'amountInclude',
        orderable: true,
    },
    {
        id: 'invoice_amount_excl',
        numeric: false,
        disablePadding: false,
        label: 'amountExclude',
        orderable: true,
    },
    {
        id: 'invoice_date',
        numeric: false,
        disablePadding: false,
        label: 'invoiceDate',
        orderable: true,
    },
    {
        id: 'invoice_expiration_date',
        numeric: false,
        disablePadding: false,
        label: 'invoiceExpirationDate',
        orderable: true,
    },
    {
        id: 'to_number',
        numeric: false,
        disablePadding: false,
        label: 'toNumber',
        orderable: true,
    },
    {
        id: 'to_name',
        numeric: false,
        disablePadding: false,
        label: 'toName',
        orderable: true,
    },
    {
        id: 'to_city',
        numeric: false,
        disablePadding: false,
        label: 'toCity',
        orderable: true,
    },
    {
        id: 'to_country',
        numeric: false,
        disablePadding: false,
        label: 'toCountry',
        orderable: true,
    },

    {
        id: 'invoice_customer',
        numeric: false,
        disablePadding: false,
        label: 'customer',
        orderable: true,
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'receivedAPI',
        orderable: true,
    },
]
