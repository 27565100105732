import { axiosClient, axiosFormClient, clientDownload } from '~/api/base'

class DeviceApi {
    getFleetOverview = (params) => {
        return axiosClient.get('api/devices/fleets', { params })
    }

    getFleetVendor = (params) => {
        return axiosClient.get('api/devices/fleets/vendors', { params })
    }

    getSerialOverview = (params) => {
        return axiosClient.get('api/devices/serials', {
            params,
        })
    }

    getDeviceDetail = (id) => axiosClient.get(`api/devices/${id}`)

    getDeviceDetailSearch = (serial) =>
        axiosClient.get(`api/devices/search/${serial}`)

    scanSerial = (serial, params) =>
        axiosClient.get(`api/devices/scan/${serial}`, { params })

    getDeviceEntitlements = (params) =>
        axiosClient.get(`api/devices/${params.device_id}/entitlements`, {
            params,
        })

    getDeviceComponents = (params) =>
        axiosClient.get(`api/devices/${params.device_id}/components`, {
            params,
        })

    getDeviceReplaceablePart = (params, id) =>
        axiosClient.get(`api/devices/${id}/part_codes`, {
            params,
        })

    getDeviceIntune = (params, id) =>
        axiosClient.get(`api/devices/${id}/intune`, {
            params,
        })

    getDeviceDispatches = (params) =>
        axiosClient.get(`api/devices/${params.id}/dispatches`, {
            params,
        })

    getDeviceIssues = (params) =>
        axiosClient.get(`api/devices/${params.device_id}/issues`, {
            params,
        })

    getFleetsExport = (payload) =>
        clientDownload.post('api/devices/fleets/export-fleet', payload)

    getVendorsExport = (payload) =>
        clientDownload.post('api/devices/fleets/vendors/export-vendor', payload)

    getVendorsImport = (payload) =>
        axiosFormClient.post('api/vendors/import-vendor', payload)

    getSerialsExport = (payload) =>
        clientDownload.post('api/devices/serials/export-serial', payload)

    getSerialQueueExport = () =>
        clientDownload.post('api/devices/serials/export-serial-queue')

    getSerialsImport = (payload) =>
        axiosFormClient.post('api/devices/serials/import-serial', payload)

    addSerial = (payload) => axiosClient.post('api/serials', payload)

    getDellCOEPImport = (payload) =>
        axiosFormClient.post('api/devices/serials/import-dell-coep', payload)

    getDellCoepSample = () =>
        clientDownload.post('api/devices/serials/export-dell-coep-sample')

    uploadPictures = (payload, id) => {
        return axiosFormClient.post(`api/devices/${id}/pictures`, payload)
    }

    uploadDocuments = (payload, id) => {
        const formData = new FormData()
        Array.from(payload.documents).forEach((file, i) => {
            formData.append('documents[]', file, file.name)
        })

        return axiosFormClient.post(`api/devices/${id}/documents`, formData)
    }

    downloadDeviceDocument = (id) =>
        clientDownload.post(`api/devices/documents/${id}/download`)

    deleteDeviceAttachment = (id) =>
        axiosClient.delete(`api/devices/attachment/${id}`)

    updateDeviceState = (id, payload) =>
        axiosClient.put(`api/devices/${id}/device_state`, payload)

    getDeviceImages = (id) => axiosClient.get(`api/devices/${id}/pictures`)

    getDeviceCheckPower = (serial) =>
        axiosClient.get(`api/device/${serial}/check-power`)

    getDeviceCheckHid = (serial) =>
        axiosClient.get(`api/device/${serial}/check-hid`)
}

export default new DeviceApi()
