import styled from '@emotion/styled'
import { Paper } from '@mui/material'

export const StyledPaper = styled(Paper)(({ theme }) => {
    return {
        borderRadius: 5,
        padding: theme.spacing(2),
        height: '100%',
    }
})
