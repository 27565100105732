import PropTypes from 'prop-types'
import { LoadingButton } from '@mui/lab'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

const DeleteConfirmPopup = ({
    openDeleteConfirm,
    handleClose,
    handleDelete,
    informationDialog,
    isLoading,
    NoDelete,
}) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const { t } = useTranslation()

    return (
        <Dialog
            fullScreen={fullScreen}
            open={openDeleteConfirm}
            onClose={handleClose}
        >
            <DialogTitle>{informationDialog.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {informationDialog.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {NoDelete ? (
                    <Button autoFocus onClick={handleClose}>
                        {t(`dialog.close`)}
                    </Button>
                ) : (
                    <>
                        <Button autoFocus onClick={handleClose}>
                            {t(`dialog.cancel`)}
                        </Button>
                        <LoadingButton
                            loading={isLoading}
                            onClick={handleDelete}
                            autoFocus
                        >
                            {t(`dialog.delete`)}
                        </LoadingButton>
                    </>
                )}
            </DialogActions>
        </Dialog>
    )
}

DeleteConfirmPopup.propTypes = {
    NoDelete: PropTypes.bool,
    openDeleteConfirm: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    informationDialog: PropTypes.object,
    isLoading: PropTypes.bool,
}

export default DeleteConfirmPopup
