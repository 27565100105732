import axiosClient from '~/api/base'

class WorkbenchApi {
    getWorkbenches = (params) => {
        return axiosClient.get('api/definition/workbenches', { params })
    }

    getWorkbenchDetail = (id) =>
        axiosClient.get(`api/definition/workbenches/${id}`)

    createWorkbench = (payload) => {
        return axiosClient.post('api/definition/workbenches', payload)
    }

    updateWorkbench = (id, payload) => {
        return axiosClient.put(`api/definition/workbenches/${id}`, payload)
    }

    deleteWorkbench = ({ id }) => {
        return axiosClient.delete(`api/definition/workbenches/${id}`)
    }

    getWorkbenchesWithoutPaginate = () => {
        return axiosClient.get('api/workbenches-without-paginate')
    }
}
export default new WorkbenchApi()
