import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import Loading from '~/components/Loading'
import PageName from '~/components/PageName'
import {
    PROCUREMENT,
    PROCUREMENT_PURCHASEORDER,
    PROCUREMENT_PURCHASEORDER_DETAIL,
} from '~/constants/Routes'
import { useParams } from 'react-router-dom'
import { useOrderLineDetailQuery } from '../query'
import PurchaseOrderLineDetail from './PurchaseOrderLine'

const PurchaseOrderLine = () => {
    const { order_id, order_line_id } = useParams()

    const { data: orderLineContent, isLoading } =
        useOrderLineDetailQuery(order_line_id)

    const BreadComponents = [
        { route: PROCUREMENT, title: 'Procurement' },
        {
            route: PROCUREMENT_PURCHASEORDER,
            title: 'PurchaseOrders',
        },
        {
            route: PROCUREMENT_PURCHASEORDER_DETAIL.replace(':id', order_id),
            title: 'OrderLines',
        },
        {
            page: 'detail',
            title: order_line_id,
        },
    ]

    if (isLoading) {
        return <Loading />
    }

    if (!orderLineContent) {
        return null
    }

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={PROCUREMENT_PURCHASEORDER_DETAIL}
            >
                <div>
                    <PageName name={`Order line - ${order_line_id}`} />
                </div>
            </ComponentTooltip>

            <PurchaseOrderLineDetail orderLineContent={orderLineContent} />
        </>
    )
}

export default PurchaseOrderLine
