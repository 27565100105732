import { createAsyncThunk } from '@reduxjs/toolkit'
import { ssoLoginApi } from './api'

export const invokeSsoLogin = createAsyncThunk(
    'auth/invokeSsoLogin',
    async (_, { rejectWithValue }) => {
        try {
            const response = await ssoLoginApi()

            return response.data.auth_url
        } catch (e) {
            rejectWithValue(e)
        }
    },
)
