import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useState } from 'react'
import TypographyTitleAccordion from '~/components/TypographyTitleAccordion'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const DispatchAccordions = ({ items = [], localization }) => {
    const [accordions, setAccordions] = useState([])

    const { t } = useTranslation('translation', { keyPrefix: localization })

    const handleChange = (panel) => (event) => {
        if (accordions.includes(panel)) {
            const filteredItems = accordions.filter((item) => item !== panel)
            setAccordions(filteredItems)
        }
        if (!accordions.includes(panel)) {
            accordions.push(panel)
            setAccordions([...accordions])
        }
    }

    return (
        <>
            {items.map((item, index) => (
                <Accordion
                    elevation={0}
                    sx={{ border: '1px solid rgb(230, 235, 241)', mt: 3 }}
                    expanded={accordions.includes(index)}
                    onChange={handleChange(index)}
                    key={index}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                    >
                        <TypographyTitleAccordion variant='h6'>
                            {t(item.title)}
                        </TypographyTitleAccordion>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails>{item.content}</AccordionDetails>
                </Accordion>
            ))}
        </>
    )
}

DispatchAccordions.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    localization: PropTypes.string,
}

export default DispatchAccordions
