import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Box,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Typography,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useGetAllRolesQuery } from './query'
import { useUpdateRoleMutation } from './mutate'
import Loading from '~/components/Loading'
import { USER_ACCOUNT_GUARD_NAME } from '~/constants/constants'
import { useSelector } from 'react-redux'
import { selectUserDetailInformation } from '~/store/user/selector'
import PropTypes from 'prop-types'

const UserData = ({ localization, handleSubmit }) => {
    const [isChanged, setIsChanged] = useState(false)
    const selectedUser = useSelector(selectUserDetailInformation)
    const [selectedRole, setSelectedRole] = useState(0)
    const { data: roles, isLoading } = useGetAllRolesQuery(
        USER_ACCOUNT_GUARD_NAME,
    )

    useEffect(() => {
        if (!isLoading) {
            const role = roles?.filter((v) => {
                return v.name === selectedUser.role_name
            })
            if (role?.length > 0) {
                setSelectedRole(role[0].id)
            }
        }
    }, [selectedUser, isLoading, roles])

    const onChangeRole = useCallback((event) => {
        setIsChanged(true)
        setSelectedRole(event.target.value)
    }, [])

    const { t } = useTranslation()
    const { mutate: updateRole, isLoading: isSaving } = useUpdateRoleMutation(
        {},
    )

    if (isLoading) {
        return <Loading />
    }
    return (
        <>
            <Box sx={{ m: 2 }}>
                <Typography gutterBottom variant='body1'>
                    {t(`${localization}.role`).toUpperCase()}
                </Typography>
                <Grid container spacing={2}>
                    {roles ? (
                        <Grid item md={9}>
                            <FormControl fullWidth>
                                <Select
                                    value={selectedRole}
                                    onChange={onChangeRole}
                                >
                                    {roles?.map((role) => (
                                        <MenuItem
                                            key={role.id}
                                            value={role.id}
                                            name={role.name}
                                        >
                                            {role.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    ) : (
                        <Typography></Typography>
                    )}
                    <Grid item md={3}>
                        <LoadingButton
                            variant='contained'
                            size='large'
                            disabled={!isChanged}
                            loading={isSaving}
                            sx={{
                                textTransform: 'none',
                                backgroundColor: '#76B72A',
                                height: '100%',
                            }}
                            type={`button`}
                            onClick={() => {
                                updateRole(selectedRole)
                            }}
                        >
                            {t(`${localization}.save`)}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

UserData.propTypes = {
    localization: PropTypes.string,
    handleSubmit: PropTypes.func,
}

export default UserData
