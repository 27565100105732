import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectShowedPartCodeForDiagnostic } from '~/store/partcode/selector'
import useFormFieldValidationSyncCallback from '~/hooks/useFormFieldValidationSyncCallback'
import { string } from 'yup'
import { useTranslation } from 'react-i18next'
import { pushShowPartCodesForDiagnostic } from '~/store/partcode/slice'
import { FormHelperText, TextField } from '@mui/material'
import { LOCALIZATION } from '~/pages/Diagnostic/constants'
import propTypes from 'prop-types'
import { textValidateRegex } from '~/utils/helpers'

const PartCodePPIDComponent = ({
    partCode,
    setPpid,
    finishDiagnostic,
    errorPPID = false,
    ppidRegex = null,
}) => {
    const [helperText, setHelperText] = useState('')
    const showingPartCodes = useSelector(selectShowedPartCodeForDiagnostic)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const stringValidator = useFormFieldValidationSyncCallback(
        string().label(`PPID`).required(),
    )

    const ppidMaxCharactor = useFormFieldValidationSyncCallback(
        string().label('PPID').required(t('message.ppidAttemptedCharacter')),
        [ppidRegex],
    )

    const onChange = useCallback(
        (e) => {
            const updatedShowingPartCodes = showingPartCodes.map((obj) => {
                if (obj.code === partCode.code) {
                    return {
                        ...obj,
                        old_ppid: e.target.value,
                    }
                } else {
                    return obj
                }
            })

            if (
                partCode.serializable &&
                stringValidator(e.target.value) &&
                ppidRegex &&
                !textValidateRegex(ppidRegex, e.target.value)
            ) {
                setHelperText(stringValidator(e.target.value))
            } else if (
                e.target.value !== '' &&
                ppidRegex &&
                !textValidateRegex(ppidRegex, e.target.value)
            ) {
                setHelperText(ppidMaxCharactor())
            } else {
                setPpid(e.target.value)
                setHelperText('')
            }
            dispatch(pushShowPartCodesForDiagnostic(updatedShowingPartCodes))
        },
        [
            showingPartCodes,
            partCode.serializable,
            partCode.code,
            stringValidator,
            ppidRegex,
            dispatch,
            ppidMaxCharactor,
            setPpid,
        ],
    )

    return (
        <>
            <TextField
                onChange={onChange}
                error={!!helperText}
                helperText={helperText}
                defaultValue={partCode.old_ppid}
                disabled={finishDiagnostic || !partCode.serializable}
                sx={{ maxWidth: '150px' }}
            />
            {errorPPID && (
                <FormHelperText error>
                    {'PPID' + t(LOCALIZATION + 'isRequire')}
                </FormHelperText>
            )}
        </>
    )
}

PartCodePPIDComponent.propTypes = {
    partCode: propTypes.object,
    setPpid: propTypes.func,
    finishDiagnostic: propTypes.bool,
    errorPPID: propTypes.bool,
    ppidRegex: propTypes.string,
}

export default PartCodePPIDComponent
