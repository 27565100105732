import axiosClient from './base'

class ProductApi {
    checkProductSku = (sku) => {
        return axiosClient.get(`api/check-product-sku-exist/${sku}`)
    }

    getList = (params) => axiosClient.get('api/products', { params })

    getProductDetail = (id, params) => {
        return axiosClient.get(`api/products/${id}`, { params })
    }

    getComponentsLinkedToProducts = (id, params) => {
        return axiosClient.get(
            `api/product/component-linked-to-products/${id}`,
            { params },
        )
    }

    getComponentsNotLinkToProducts = (id, params) => {
        return axiosClient.get(
            `api/product/component-not-linked-to-products/${id}`,
            { params },
        )
    }

    unlinkComponentToProduct = (payload) => {
        return axiosClient.post(
            'api/product/unlink-component-to-product',
            payload,
        )
    }

    linkComponentToProduct = (payload) => {
        return axiosClient.post(
            'api/product/link-component-to-product',
            payload,
        )
    }
}

export default new ProductApi()
