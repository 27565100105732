import { useNavigate } from 'react-router-dom'
import ToolBarButton from '../../components/ToolBarButton'
import { ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_CREATE } from '~/constants/Routes'
import { Box, Stack, Toolbar as MuiToolbar } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import PropTypes from 'prop-types'

const Toolbar = ({ localization }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <>
            <MuiToolbar disableGutters={false}>
                <Box sx={{ flexGrow: 1 }} />

                <Stack
                    direction='row'
                    justifyContent='right'
                    alignItems='right'
                    minWidth='46px'
                    spacing={2}
                >
                    <ToolBarButton
                        icon={<AddIcon />}
                        name={t(`${localization}.create`)}
                        handleAction={() => {
                            navigate(
                                ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_CREATE,
                            )
                        }}
                    />
                </Stack>
            </MuiToolbar>
        </>
    )
}

Toolbar.propTypes = { localization: PropTypes.string }

export default Toolbar
