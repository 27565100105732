import { useTranslation } from 'react-i18next'
import { useCallback, useState } from 'react'
import useFormFieldValidationSyncCallback from '~/hooks/useFormFieldValidationSyncCallback'
import { integer } from '~/constants/yupSchemas'
import { useDispatch, useSelector } from 'react-redux'
import { selectShowedPartCodeForDiagnostic } from '~/store/partcode/selector'
import { pushShowPartCodesForDiagnostic } from '~/store/partcode/slice'
import { TextField } from '@mui/material'
import propTypes from 'prop-types'

const PartCodeQtyComponent = ({ partCode, setQty, finishDiagnostic }) => {
    const { t } = useTranslation()
    const [helperText, setHelperText] = useState('')
    const integerValidator = useFormFieldValidationSyncCallback(
        integer(t, 'qty').required().min(1),
    )
    const showingPartCodes = useSelector(selectShowedPartCodeForDiagnostic)
    const dispatch = useDispatch()

    const onChange = useCallback(
        (e) => {
            const updatedShowingPartCodes = showingPartCodes.map((obj) => {
                if (obj.code === partCode.code) {
                    return {
                        ...obj,
                        qty: e.target.value,
                    }
                } else {
                    return obj
                }
            })

            if (integerValidator(e.target.value)) {
                setHelperText(integerValidator(e.target.value))
            } else if (!partCode.allow_multiple && e.target.value > 1) {
                setHelperText(
                    `Part ${partCode.code} is serializable part. Quantity can be only 1.`,
                )
            } else {
                setQty(parseInt(e.target.value))
                setHelperText('')
                dispatch(
                    pushShowPartCodesForDiagnostic(updatedShowingPartCodes),
                )
            }
        },
        [
            dispatch,
            integerValidator,
            partCode.code,
            partCode.allow_multiple,
            setQty,
            showingPartCodes,
        ],
    )
    return (
        <TextField
            onChange={onChange}
            error={!!helperText}
            helperText={helperText}
            defaultValue={partCode.qty || 1}
            disabled={finishDiagnostic}
            sx={{ maxWidth: '150px', minWidth: '50px' }}
        />
    )
}

PartCodeQtyComponent.propTypes = {
    partCode: propTypes.object,
    setQty: propTypes.func,
    finishDiagnostic: propTypes.bool,
}

export default PartCodeQtyComponent
