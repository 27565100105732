import { useMutation } from 'react-query'
import teamApi from '~/api/teamApi'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setSnackbar } from '~/store/snackbar/slice'
import { ADMINISTRATOR_DEFINITION_TEAM_DETAIL } from '~/constants/Routes'
import { useSupportObject } from '~/hooks/useSupportObject'
import { removeEmptyValue } from '~/utils/helpers'

export const useCreateTeamMutation = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return useMutation(
        ['team_create'],
        async (payload) => {
            return await teamApi.createTeam(payload)
        },
        {
            onSuccess: (data) => {
                dispatch(
                    setSnackbar({
                        message: data.message
                            ? data.message
                            : 'Create successful!',
                        type: 'success',
                    }),
                )

                navigate(
                    ADMINISTRATOR_DEFINITION_TEAM_DETAIL.replace(
                        ':id',
                        data.data.data.id,
                    ),
                )
                return data.data?.data
            },
            onError: (error) => {
                const message = error.response.data.message
                    ? error.response.data.message
                    : error.message
                      ? error.message
                      : 'Create failed!'
                dispatch(
                    setSnackbar({
                        message,
                        type: 'error',
                    }),
                )
            },
            retry: false,
        },
    )
}
export const useUpdateTeamMutation = ({ showSnackbar }) => {
    const { id, navigate } = useSupportObject()
    const dispatch = useDispatch()

    return useMutation(
        ['team_update'],
        async (payload) => {
            const p = removeEmptyValue(payload)
            return await teamApi.updateTeam(id, p)
        },
        {
            onSuccess: (data) => {
                dispatch(
                    setSnackbar({
                        message: data.message
                            ? data.message
                            : 'Update successful!',
                        type: 'success',
                    }),
                )
                navigate(
                    ADMINISTRATOR_DEFINITION_TEAM_DETAIL.replace(':id', id),
                )
                return data
            },
            onError: (error) =>
                showSnackbar(
                    error.message ? error.message : 'Save failed!',
                    'error',
                ),
            retry: false,
        },
    )
}
export const useAddMemberForTeamMutation = ({
    setOpenAddMember,
    setIdUser,
}) => {
    const { id, clientQuery } = useSupportObject()

    return useMutation(
        ['add_members_team'],
        async (payload) => {
            return await teamApi.addMemberForTeam(id, payload)
        },
        {
            onSuccess: (data) => {
                clientQuery.invalidateQueries('users_of_team')
                clientQuery.invalidateQueries('users_not_exist_team')
                setOpenAddMember(false)
                setIdUser(null)
            },

            retry: false,
        },
    )
}

export const useRemoveTeamMemberMutation = () => {
    const { id, clientQuery } = useSupportObject()

    return useMutation(
        ['remove_members_team'],
        async (payload) => {
            return await teamApi.removeMemberInTeam(id, payload)
        },
        {
            onSuccess: (data) => {
                clientQuery.invalidateQueries('users_of_team')
                clientQuery.invalidateQueries('team_detail')
            },

            retry: false,
        },
    )
}
