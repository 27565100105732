import CustomTable from '~/components/Table'
import { useDispatchPartsQuery } from './query'
import PropTypes from 'prop-types'

const headCells = [
    {
        id: 'part_code',
        key: 'part_code',
        numeric: false,
        disablePadding: false,
        label: 'PartCode',
        orderable: true,
    },
    {
        id: 'name', // TODO: unclear field data
        key: 'name',
        numeric: false,
        disablePadding: false,
        label: 'PartDescription',
        orderable: true,
    },
    {
        id: 'qty',
        key: 'qty',
        numeric: false,
        disablePadding: false,
        label: 'Quantity',
        orderable: true,
    },
    {
        id: 'cru', // TODO: unclear field data
        key: 'cru',
        numeric: false,
        disablePadding: false,
        label: 'CRU',
        orderable: false,
    },
    {
        id: 'waybill', // TODO: unclear field data
        key: 'waybill',
        numeric: false,
        disablePadding: false,
        label: 'WaybillNumber',
        orderable: false,
    },
]

const PartContent = ({ dispatchId }) => {
    return (
        <CustomTable
            id={dispatchId.toString()}
            headCells={headCells}
            customQuery={useDispatchPartsQuery}
            LOCALIZATION={'pages.dispatches.dispatch.detail.'}
        />
    )
}

PartContent.propTypes = { dispatchId: PropTypes.number }

export default PartContent
