import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import {
    PRODUCTION_IMPORT_PROJECTS,
    PRODUCTION_SYSTEMIMAGE,
} from '~/constants/Routes'
import ServersTable from './ProjectTable'
import { Box } from '@mui/material'
import { LoadingButton } from '~/components/Button'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const ProjectOverview = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const breadCrumbConfig = [
        { route: PRODUCTION_SYSTEMIMAGE, title: 'Production' },
        { route: '#', title: 'Projects' },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <PageName name={'Projects'} />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'right',
                    marginBottom: '10px',
                }}
            >
                <LoadingButton
                    label={t('pages.production.projects.importProject')}
                    onClick={() => navigate(PRODUCTION_IMPORT_PROJECTS)}
                />
            </Box>
            <ServersTable />
        </>
    )
}

export default ProjectOverview
