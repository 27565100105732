import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { Button } from '@mui/material'
import { useCallback } from 'react'

const useStyle = makeStyles()((theme) => ({
    button: {
        textTransform: 'none',
        backgroundColor: '#76B72A',
        '&:hover': {
            backgroundColor: '#102F44',
            color: 'white',
        },
    },
    redButton: {
        textTransform: 'none',
        backgroundColor: '#FF0000',
        '&:hover': {
            backgroundColor: '#102F44',
            color: 'white',
        },
    },
    grayButton: {
        textTransform: 'none',
        backgroundColor: '#a59f9f',
        '&:hover': {
            backgroundColor: 'gray',
            color: 'with',
        },
    },
}))

const ToolBarButton = ({ icon, color, handleAction, name, ...props }) => {
    const { classes } = useStyle()
    const colorButton = useCallback(() => {
        if (color === 'error') {
            return classes.redButton
        }
        if (color === 'close' || color === 'cancel') {
            return classes.grayButton
        }
        return classes.button
    }, [classes, color])
    return (
        <Button
            variant='contained'
            size='small'
            className={colorButton()}
            startIcon={icon}
            onClick={handleAction}
            {...props}
        >
            {name}
        </Button>
    )
}
ToolBarButton.propTypes = {
    icon: PropTypes.any,
    color: PropTypes.string,
    handleAction: PropTypes.func,
    name: PropTypes.string,
}
export default ToolBarButton
