import { deviceApi, partcodeApi, serviceCenterApi } from '~/api'
import causeApi from '~/api/causeApi'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { setDevice } from '~/store/device/slice'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { DEFAULT_CACHE_TTL } from '~/utils/constants'

export const useDeviceQuery = ({
    serial,
    step,
    setStep,
    setIsFoundSerialData,
    isCreateDispatch = false,
}) => {
    const { openSnackbar } = useDialogContext()
    const dispatch = useDispatch()
    return useQuery(
        ['dispatch_add_dispatch_device', serial],
        async () => {
            const params = {
                add_dispatch: true,
                create_dispatch: isCreateDispatch,
            }
            const { data, status } = await deviceApi.scanSerial(serial, params)
            if (status === 202) {
                openSnackbar({
                    message: data.message,
                    type: 'info',
                })
                return
            }

            return data.data
        },
        {
            onSuccess: (data) => {
                dispatch(setDevice(data))
                if (step === 1) {
                    setStep(2)
                }
                setIsFoundSerialData(true)
            },
            onError: () => {
                setIsFoundSerialData(false)
            },
            enabled: !!serial,
            retry: false,
        },
    )
}

export const useGetDeviceBySerialQuery = ({ serial }) => {
    return useQuery(
        ['get_device_by_serial', serial],
        async () => {
            const { data } = await deviceApi.scanSerial(serial, {})
            return data.data
        },
        {
            enabled: !!serial,
            retry: false,
        },
    )
}
export const useServiceCenterQuery = () =>
    useQuery(
        ['dispatch_add_dispatch_service_center'],
        async () => {
            const { data } = await serviceCenterApi.getAllServiceCenters()
            return data.data
        },
        { retry: false },
    )

export const usePartCodeQuery = (params) => {
    return useQuery(
        ['serial_part_codes', params],
        async () => {
            const { data } = await partcodeApi.getSerialPartCode(params)
            return { data: data.data, total: data.meta.total }
        },
        {
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
            enabled: !!params.serial,
            retry: false,
        },
    )
}

export const usePartCodeTypeQuery = (params) => {
    // const dispatchSerial = useSelector(selectDispatchSerial);
    return useQuery(
        ['serial_part_code_types', params],
        async () => {
            const { data } = await partcodeApi.getSerialPartCodeType(params)
            return data
        },
        {
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
            enabled: !!params.serial,
            retry: false,
        },
    )
}

export const useDeviceCauseQuery = (params, onSuccess = () => {}) => {
    return useQuery(
        ['device_causes', params],
        async () => {
            const { data } = await causeApi.getDeviceCause(params)
            return data.data
        },
        {
            enabled: !!params.serial,
            onSuccess,
        },
    )
}

export const useDeviceEntitlementQuery = ({ id, ...params }) => {
    const p = { ...params, device_id: id }
    return useQuery(['device_entitlements', id, params], async () => {
        const { data } = await deviceApi.getDeviceEntitlements(p)
        return { data: data.data, total: data.meta?.total }
    })
}
