import dataSchedulerApi from '~/api/dataSchedulerApi'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { setSchedulerData } from '~/store/scheduler/slice'
import { removeEmptyValue } from '~/utils/helpers'

export const useExportSchedulerListQuery = (params) => {
    return useQuery(
        ['export_scheduler', params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await dataSchedulerApi.getExportScheduler(p)
            return { data: data.data, total: data.meta?.total }
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useExportSchedulerDetailQuery = (id) => {
    const dispatch = useDispatch()
    return useQuery(
        ['export_scheduler_detail', id],
        async () => {
            const { data } = await dataSchedulerApi.getExportSchedulerDetail(id)
            return data.data
        },
        {
            onSuccess: (data) => {
                dispatch(setSchedulerData(data))
            },
            onError: () => {},
            retry: false,
        },
    )
}

export const useExportDatabaseListQuery = () => {
    return useQuery(
        ['export_databases'],
        async () => {
            const { data } = await dataSchedulerApi.getExportDatabaseList()
            return data
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}
