import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ToolBarButton from '~/components/ToolBarButton'
import ListToolbarWrapper from './ListToolbarWrapper'

const ListToolbar = ({ toolBarButton = [] }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <ListToolbarWrapper>
            {toolBarButton.map(
                (item, index) =>
                    item.isPermission && (
                        <ToolBarButton
                            key={index}
                            icon={item.icon}
                            handleAction={() =>
                                navigate(`${item.navigateLink}`)
                            }
                            name={t(`button.${item.button}`)}
                        />
                    ),
            )}
        </ListToolbarWrapper>
    )
}
ListToolbar.propTypes = {
    toolBarButton: PropTypes.array,
}

export default ListToolbar
