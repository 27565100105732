import invoiceApi from '~/api/invoiceApi'
import { useQuery } from 'react-query'
import { removeEmptyValue } from '~/utils/helpers'

export const useInvoicesQuery = (params) => {
    const p = removeEmptyValue(params)
    return useQuery(
        ['Invoices', params],
        async () => {
            const { data } = await invoiceApi.getInvoices(p)
            return { data: data.data, total: data.meta.total }
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}
