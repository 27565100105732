import { Button, FormControl, Grid, Typography } from '@mui/material'
import FormField from '../../components/FinalForm/FormField'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import FormTextArea from '~/components/FinalForm/FormTextArea'
import { useCallback, useEffect, useMemo, useState } from 'react'
import RadioInput from '~/components/FormInput/RadioInput'
import { Field } from 'react-final-form'
import FormRadioGroup from '~/components/FormRadioGroup'
import { useCheckStatementMutation, useDownloadFileMutation } from './mutation'
import cronstrue from 'cronstrue/i18n'
import { useDispatch, useSelector } from 'react-redux'
import { selectScheduler } from '~/store/scheduler/selector'
import CronTabDescription from './components/CronTabDescription'
import { LoadingButton } from '@mui/lab'
import { useExportDatabaseListQuery } from './query'
import InputField from '~/components/FinalForm/InputField'
import { ADMINISTRATOR_DATASCHEDULER_NEW } from '~/constants/Routes'
import { useTranslation } from 'react-i18next'
import SelectField from '~/components/FinalForm/SelectField'
import PropTypes from 'prop-types'
import { setSnackbar } from '~/store/snackbar/slice'
const pagePath = ADMINISTRATOR_DATASCHEDULER_NEW

const ButtonDownload = ({ handleDownload }) => {
    const scheduler = useSelector(selectScheduler)
    return (
        <Button
            variant='outlined'
            onClick={() =>
                handleDownload({
                    token: scheduler.path_token,
                })
            }
        >
            download
        </Button>
    )
}

ButtonDownload.propTypes = { handleDownload: PropTypes.func }

const Body = ({ localization, form, initialValues }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [translate, setTranslate] = useState('')
    const [resultCheckStatement, setResultCheckStatement] = useState('')
    const { mutate: downloadFile } = useDownloadFileMutation()
    const { mutate: checkStatement, isLoading } = useCheckStatementMutation({
        setResultCheckStatement,
    })

    const { data: databases } = useExportDatabaseListQuery()

    const databasesList = useMemo(() => {
        if (databases) {
            const result = databases?.data.map((database) => ({
                name: database.name,
                value: database.connection,
            }))

            return result
        }
        return []
    }, [databases])

    const selectGroup = useMemo(() => {
        return [
            {
                title: 'database',
                name: 'database',
                listResource: databasesList,
                required: true,
                componentId: 'schedule_form_database',
            },
        ]
    }, [databasesList])

    const handleDownload = ({ token }) => {
        downloadFile({
            path_token: token,
        })
    }

    const [showEmailField, setShowEmailField] = useState(
        !(initialValues && initialValues?.method !== 'emailAttachment'),
    )
    const [processSchedule, setProcessSchedule] = useState(
        !(initialValues && initialValues?.process !== 'schedule'),
    )
    const handleChangeMethodRadio = useCallback((e) => {
        setShowEmailField(true)
        if (e !== 'emailAttachment') {
            setShowEmailField(false)
        }
    }, [])

    const handleChangeProcessRadio = useCallback((e) => {
        setProcessSchedule(true)
        if (e !== 'schedule') {
            setProcessSchedule(false)
        }
    }, [])

    const locale = localStorage.getItem('LOCALE')
    const translateCronPattern = useCallback(() => {
        const cron = form.getFieldState('cron').value
        try {
            const content = cronstrue.toString(cron, { locale })
            setTranslate(content)
        } catch (e) {
            dispatch(
                setSnackbar({
                    message: e,
                    type: 'error',
                }),
            )
        }
    }, [dispatch, form, locale])

    const validateStatement = () => {
        const statement = form.getFieldState('statement').value
        const database = form.getFieldState('database').value

        checkStatement({
            statement,
            database,
        })
    }

    useEffect(() => {
        if (initialValues?.cron) {
            const content = cronstrue.toString(initialValues?.cron, {
                locale,
            })
            setTranslate(content)
        }
    }, [initialValues?.cron, initialValues?.path_token, locale])

    return (
        <FormBodyWrapper>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputField
                        localization={localization}
                        name={`name`}
                        componentId={`schedule_form_name`}
                        pagePath={pagePath}
                        isRequired={true}
                        placeholder={t(`${localization}.name`)}
                    />
                </Grid>
                {selectGroup.map((item, index) => {
                    return (
                        <Grid item xs={12} key={index}>
                            <FormControl sx={{ width: '100%' }}>
                                <Field
                                    name={item.name}
                                    component={SelectField}
                                    items={item.listResource}
                                    localization={localization}
                                    componentId={item.componentId}
                                    pagePath={pagePath}
                                    nameSelect={item.title}
                                    isRequired={item.required}
                                />
                            </FormControl>
                        </Grid>
                    )
                })}

                <Grid item xs={12}>
                    <FormTextArea
                        localization={localization}
                        name={`statement`}
                        label={`queryStatement`}
                        isRequired={true}
                        multiple
                        minRow={3}
                        componentId={`schedule_form_statement`}
                        pagePath={pagePath}
                    />
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={1}>
                                <LoadingButton
                                    loading={isLoading}
                                    onClick={() => validateStatement()}
                                >
                                    check
                                </LoadingButton>
                            </Grid>
                            <Grid item xs={11} mt={0.5}>
                                <Typography
                                    color={
                                        resultCheckStatement === 'correct'
                                            ? '#76b82a'
                                            : 'error'
                                    }
                                >
                                    {resultCheckStatement}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <FormRadioGroup
                        defaultValue={
                            initialValues?.method ?? 'emailAttachment'
                        }
                    >
                        <Field
                            name='method'
                            type='radio'
                            value='emailAttachment'
                            label='Attachment email'
                            component={RadioInput}
                            onUpdate={handleChangeMethodRadio}
                        />
                        <Field
                            name='method'
                            type='radio'
                            value='storage'
                            label='Storage'
                            component={RadioInput}
                            onUpdate={handleChangeMethodRadio}
                        />

                        {initialValues?.path_token &&
                            initialValues?.method !== 'emailAttachment' && (
                                <ButtonDownload
                                    handleDownload={handleDownload}
                                />
                            )}
                    </FormRadioGroup>
                </Grid>
                {showEmailField && (
                    <Grid item xs={12}>
                        <FormField
                            localization={localization}
                            name={`emails`}
                            NoTitle
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <FormRadioGroup
                        defaultValue={initialValues?.process ?? 'schedule'}
                    >
                        <Field
                            name='process'
                            type='radio'
                            value='schedule'
                            label='schedule'
                            component={RadioInput}
                            onUpdate={handleChangeProcessRadio}
                        />

                        <Field
                            name='process'
                            type='radio'
                            value='noSchedule'
                            label='No schedule'
                            component={RadioInput}
                            onUpdate={handleChangeProcessRadio}
                        />
                    </FormRadioGroup>
                </Grid>

                {processSchedule && (
                    <>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormField
                                        localization={localization}
                                        name={`cron`}
                                        label={`cronPattern`}
                                        isRequired={true}
                                        onChange={(val, prevVal) =>
                                            translateCronPattern(val, prevVal)
                                        }
                                    />
                                    <Grid container spacing={2}>
                                        <Grid item xs={1}>
                                            <Button
                                                onClick={translateCronPattern}
                                            >
                                                translate
                                            </Button>
                                        </Grid>
                                        <Grid item xs={11} mt={0.5}>
                                            {translate}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h6'>
                                How to use cron pattern
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <CronTabDescription />
                        </Grid>
                    </>
                )}
            </Grid>
        </FormBodyWrapper>
    )
}

Body.propTypes = {
    localization: PropTypes.string,
    form: PropTypes.object,
    initialValues: PropTypes.object,
}

export default Body
