import { useNavigate, useParams } from 'react-router-dom'
import { useTagDetailQuery } from '../query'
import BreadCrumb from '~/components/BreadCrumb'
import useFormValidationCallback from '~/hooks/useFormValidationCallback'
import { object, string } from 'yup'
import { ADMINISTRATOR, ADMINISTRATOR_NAMING_TAG } from '~/constants/Routes'
import { useTranslation } from 'react-i18next'
import { useDeleteTagMutation, useSaveTagMutation } from '../mutate'
import { useMemo } from 'react'
import FormToolbar from '~/components/FinalForm/FormToolbar'
import { LoadingButton } from '~/components/Button'
import { Form as FinalForm } from 'react-final-form'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import BodyForm from './BodyForm'

const NamingTagDetail = () => {
    const localization = `pages.administrator.namingTag`
    const { t } = useTranslation()
    const { id } = useParams()
    const { data, isLoading } = useTagDetailQuery(id)

    const { mutate: saveTagMutate, isLoading: loadingSave } =
        useSaveTagMutation()
    const { mutate: deleteTagMutate, isLoading: loadingDelete } =
        useDeleteTagMutation()
    const schema = object().shape({
        name: string().label(`Tag Name`).required(),
    })
    const navigate = useNavigate()
    const validate = useFormValidationCallback(schema)
    const breadCrumbConfig = useMemo(
        () => [
            { title: `Administrator`, route: ADMINISTRATOR },
            {
                title: `NamingTags`,
                route: ADMINISTRATOR_NAMING_TAG,
            },
            {
                title: `Naming tag ${data?.id}`,
                page: 'detail',
            },
        ],
        [data],
    )
    const { openSnackbar } = useDialogContext()

    const saveHandler = (values) => {
        saveTagMutate(
            { id, ...values },
            {
                onSuccess: (data) => {
                    openSnackbar({
                        message: data.message,
                        type: data.code,
                    })
                },
                onError: (e) => {
                    openSnackbar({
                        message: e?.response?.data.message,
                        type: 'error',
                        duration: 10000,
                    })
                },
            },
        )
    }
    const deleteHandler = () => {
        deleteTagMutate(id, {
            onSuccess: (data) => {
                openSnackbar({ message: data.message, type: data.code })
                navigate(ADMINISTRATOR_NAMING_TAG)
            },
            onError: (e) => {
                openSnackbar({
                    message: e?.response?.data,
                    type: 'error',
                    duration: 10000,
                })
            },
        })
    }

    if (isLoading) {
        return <></>
    }
    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <FinalForm
                validate={validate}
                onSubmit={() => {}}
                initialValues={data}
                render={({ handleSubmit, values, dirty }) => (
                    <form onSubmit={handleSubmit}>
                        <FormToolbar>
                            <LoadingButton
                                disabled={!dirty}
                                label={t(`${localization}.save`)}
                                onClick={() => saveHandler(values)}
                                loading={loadingSave}
                            />
                            <LoadingButton
                                label={t(`${localization}.delete`)}
                                onClick={deleteHandler}
                                loading={loadingDelete}
                            />
                        </FormToolbar>
                        <BodyForm {...{ localization }} />
                    </form>
                )}
            />
        </>
    )
}

export default NamingTagDetail
