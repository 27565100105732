import axiosClient, { clientDownload } from '~/api/base'

class DeliveryApi {
    getDeliveries = (params) => {
        return axiosClient.get('api/procurement/deliveries', {
            params,
        })
    }

    getDeliveryDetail = (id, params) =>
        axiosClient.get(`api/procurement/deliveries/${id}`, { params })

    getDeliveriesExport = (payload) =>
        clientDownload.post('api/procurement/deliveries/export', payload)

    getDeliveryDetailExport = (id, payload) =>
        clientDownload.post(`api/procurement/deliveries/${id}/export`, payload)
}

export default new DeliveryApi()
