export const SerialHeadCells = [
    {
        id: 'serial',
        numeric: false,
        disablePadding: false,
        label: 'Serial',
        orderable: true,
    },
    {
        id: 'vendor',
        numeric: false,
        disablePadding: false,
        label: 'Vendor',
        orderable: true,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
        orderable: true,
    },
    {
        id: 'shipdate',
        numeric: false,
        disablePadding: false,
        label: 'Shipdate',
        orderable: true,
    },
    {
        id: 'state',
        numeric: false,
        disablePadding: false,
        label: 'State',
    },
    {
        id: 'warrantyEnd',
        numeric: false,
        disablePadding: false,
        label: 'Warranty_End',
    },
    {
        id: 'entitlements',
        numeric: false,
        disablePadding: false,
        label: 'Entitlements',
    },
]
