import { useTranslation } from 'react-i18next'
import Table from './Table'
import BreadCrumb from '~/components/BreadCrumb'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_DEFINITION,
    ADMINISTRATOR_DEFINITION_WORKBENCH,
    ADMINISTRATOR_DEFINITION_WORKBENCH_NEW,
} from '~/constants/Routes'
import PageName from '~/components/PageName'
import AddIcon from '@mui/icons-material/Add'
import ComponentTooltip from '~/components/ComponentTooltip'
import TableToolbarContainer from '~/components/Table/TableToolbarContainer'
import ToolBarButton from '~/components/ToolBarButton'
import { useNavigate } from 'react-router-dom'
import useWorkbenchFormData from './hooks/useWorkbenchFormData'

const WorkBench = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { localization } = useWorkbenchFormData()

    const BreadComponents = [
        { route: ADMINISTRATOR, title: t(`breadCrumb.Administrator`) },
        {
            route: ADMINISTRATOR_DEFINITION,
            title: 'Definitions',
        },
        {
            route: ADMINISTRATOR_DEFINITION_WORKBENCH,
            title: 'workbenches',
        },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_DEFINITION_WORKBENCH}
            >
                <div>
                    <PageName name={t(`breadCrumb.workbenches`)} />
                </div>
            </ComponentTooltip>
            <TableToolbarContainer>
                <ToolBarButton
                    icon={<AddIcon />}
                    name={t(`${localization}.add`)}
                    handleAction={() => {
                        navigate(ADMINISTRATOR_DEFINITION_WORKBENCH_NEW)
                    }}
                />
            </TableToolbarContainer>
            <Table />
        </>
    )
}

export default WorkBench
