import { Box, LinearProgress, Typography } from '@mui/material'
import StyledModal from '~/components/StyledModal'
import { useParams } from 'react-router-dom'
import { useIssueQuery } from '../query'
import PartTable from './PartTable'
import RepairPage from './RepairPage'
import { useEffect, useMemo, useState } from 'react'
import RemovedPartTable from './RemovedPartTable'
import { useQueryClient } from 'react-query'
import PropTypes from 'prop-types'

const IssueRepairPage = ({ openScanReceivedPart, setOpenScanReceivedPart }) => {
    const queryClient = useQueryClient()
    const { issueId } = useParams()
    const {
        data,
        isLoading: isQueryLoading,
        isSuccess,
    } = useIssueQuery(issueId)

    const currStep = useMemo(() => {
        return data?.data?.repair?.current_step ?? 0
    }, [data?.data?.repair?.current_step])

    const [openRemovePart, setOpenRemovePart] = useState(
        currStep === 3 && !data?.data?.repair?.finished_at,
    )

    const [isInitialized, setIsInitialized] = useState(true)
    const [techComment, setTechComment] = useState('')

    useEffect(() => {
        if (isSuccess) {
            setTechComment(data?.data?.repair?.technician_comment ?? '')
        }
    }, [isSuccess, data])
    useEffect(() => {
        if (
            ['PARTS_READY', 'START_REPAIR'].includes(data?.data?.state) &&
            isInitialized
        ) {
            queryClient.removeQueries('part_issue')
            queryClient.removeQueries('issue')
            setIsInitialized(false)
        }
    }, [data, isInitialized, queryClient])

    if (isQueryLoading) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        )
    }

    return (
        <>
            <>
                {(currStep === 2 || currStep === 3) && (
                    <RepairPage
                        issueId={issueId}
                        issueData={data}
                        diagnosticId={data?.data?.diagnostic?.id}
                        finished={!!data?.data?.repair?.finished_at}
                        setOpenRemovePart={setOpenRemovePart}
                        techComment={techComment}
                        setTechComment={setTechComment}
                    />
                )}
            </>

            <StyledModal
                open={currStep === 1 && openScanReceivedPart === true}
                stackProps={{ alignItems: 'unset', width: 850 }}
            >
                <Typography
                    variant='h4'
                    sx={{
                        mb: 2,
                    }}
                >
                    Scan received part
                </Typography>
                <PartTable
                    issueId={issueId}
                    setOpenScanReceivedPart={setOpenScanReceivedPart}
                    ppidRegex={
                        data?.data?.ticket?.device?.vendor
                            ?.ppid_validation_regex
                    }
                />
            </StyledModal>

            <StyledModal
                open={openRemovePart}
                stackProps={{ alignItems: 'unset', width: 1100 }}
            >
                <Typography variant='h4' sx={{ mb: 2 }}>
                    Scan removed part
                </Typography>
                <RemovedPartTable
                    {...{ issueId, setOpenRemovePart }}
                    techComment={techComment}
                />
            </StyledModal>
        </>
    )
}

IssueRepairPage.propTypes = {
    openScanReceivedPart: PropTypes.bool,
    setOpenScanReceivedPart: PropTypes.func,
}

export default IssueRepairPage
