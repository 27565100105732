import { Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '~/components/Button'
import StyledModal from '~/components/StyledModal'

const ConfirmImageIssuePopup = ({
    open,
    onClose,
    message,
    confirmlHandling,
    loading,
}) => {
    const { t } = useTranslation()

    return (
        <StyledModal
            open={open}
            handleClose={onClose}
            stackProps={{
                width: 600,
                gap: 4,
            }}
        >
            <Typography variant='h6'>{message}</Typography>
            <Stack direction='row' gap={2}>
                <LoadingButton
                    label={t('button.cancel')}
                    bgColor='gray'
                    onClick={onClose}
                    loading={loading}
                />
                <LoadingButton
                    label={t('button.confirm')}
                    onClick={confirmlHandling}
                    loading={loading}
                />
            </Stack>
        </StyledModal>
    )
}

ConfirmImageIssuePopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    confirmlHandling: PropTypes.func,
    message: PropTypes.string.isRequired,
    loading: PropTypes.bool,
}

export default ConfirmImageIssuePopup
