import {
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'

import DataNotFound from '~/components/DataNotFound'

import TableHeader from '~/components/TableHeader'

import ExternalLink from '~/components/ExternalLink'
import { DeliveryHeadCells } from '~/components/HeaderTable/DeliveryHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import { PROCUREMENT_DELIVERY_DETAIL } from '~/constants/Routes'
import { COPACO_DISPATCH_NUMBER } from '~/constants/externalLink'
import useTable from '~/hooks/useTable'
import { useNavigate } from 'react-router-dom'
import { useDeliveriesQuery } from '../query'
import DeliveryListToolbar from './DeliveryListToolbar'
import DeliveryTableFilters from './DeliveryTableFilters'
import PropTypes from 'prop-types'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const localization = 'pages.procurement.delivery.overview.'

const DeliveryBodyTable = ({ isLoading, deliveries }) => {
    const navigate = useNavigate()
    if (isLoading) {
        return <TableLoading colSpan={DeliveryHeadCells.length} />
    }

    return (
        <TableBody>
            {deliveries.length > 0 ? (
                deliveries.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                            <TableCell
                                align='left'
                                sx={{ cursor: 'pointer' }}
                                onClick={() =>
                                    navigate(
                                        PROCUREMENT_DELIVERY_DETAIL.replace(
                                            ':id',
                                            row.id,
                                        ),
                                    )
                                }
                            >
                                {row.supplier_name}
                            </TableCell>
                            <TableCell align='left'>{row.route}</TableCell>
                            <TableCell align='left'>
                                <ExternalLink
                                    extLink={COPACO_DISPATCH_NUMBER}
                                    param={row.dispatch_number}
                                    tailParam={`&DocumentType=PAK`}
                                />
                            </TableCell>
                            <TableCell align='left'>
                                <DateTimeLocale
                                    datetime={row.dispatch_date}
                                    formatHour={true}
                                />
                            </TableCell>
                            <TableCell align='left'>{row.to_name_1}</TableCell>
                            <TableCell align='left'>{row.to_city}</TableCell>
                            <TableCell align='center'>
                                {row.to_country}
                            </TableCell>
                            <TableCell align='left'>
                                <DateTimeLocale datetime={row.created_at} />
                            </TableCell>
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={DeliveryHeadCells.length} />
            )}
        </TableBody>
    )
}

DeliveryBodyTable.propTypes = {
    isLoading: PropTypes.bool,
    deliveries: PropTypes.arrayOf(PropTypes.object),
}

const DeliveryTable = () => {
    const [supplier, setSupplier] = useState('')

    const {
        results: deliveries,
        data,
        isLoading,
        page,
        setPage,
        search,
        order,
        orderBy,
        searchHandler,
        sortHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useDeliveriesQuery,
        queryAttributes: {
            supplier,
        },
    })

    const refValue = useRef({
        supplier: '',
    })

    useEffect(() => {
        if (supplier !== refValue.current.supplier) {
            setPage(1)
            refValue.current.supplier = supplier
        }
    }, [setPage, supplier])

    return (
        <>
            <DeliveryListToolbar
                payload={{ order, orderBy, search, supplier }}
            />

            <Paper sx={{ width: '100%', mb: 2 }}>
                <DeliveryTableFilters
                    search={search}
                    searchHandler={searchHandler}
                    supplier={supplier}
                    setSupplier={setSupplier}
                    onEnterSearch={onEnterSearch}
                />
                <Divider />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby='tableTitle'
                        size='medium'
                    >
                        <TableHeader
                            headCells={DeliveryHeadCells}
                            localization={localization}
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                        />
                        <DeliveryBodyTable
                            isLoading={isLoading}
                            deliveries={deliveries}
                        />
                    </Table>
                </TableContainer>
                <PaginationTable
                    data={data}
                    page={page}
                    handleChangePage={changePageHandler}
                    size='medium'
                />
            </Paper>
        </>
    )
}

export default DeliveryTable
