import { Box, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'

const StateDisplayer = ({ state = '', errorMessage = '' }) => {
    if (state === 'ERROR') {
        return (
            <Tooltip arrow title={errorMessage} placement='top'>
                <span>{state}</span>
            </Tooltip>
        )
    }

    return <Box>{state}</Box>
}

StateDisplayer.propTypes = {
    state: PropTypes.string,
    errorMessage: PropTypes.string,
}

export default StateDisplayer
