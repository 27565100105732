import {
    Divider,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material'
import { useCallback, useMemo, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import DataNotFound from '~/components/DataNotFound'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { PART_COMPONENT_DETAIL } from '~/constants/Routes'
import useTable from '~/hooks/useTable'
import ComponentListToolbar from './ComponentListToolbar'
import ComponentTableFilters from './ComponentTableFilters'
import PartCodeDisplayer from './components/PartCodeDisplayer'
import { ComponentHeadCells } from './constants'
import { useComponentsQuery } from './query'
import PropTypes from 'prop-types'

const localization = 'pages.components.'

const ComponentTableBody = ({ isLoading, components }) => {
    const navigate = useNavigate()
    const handleClickDirection = useCallback(
        (row) => {
            navigate(PART_COMPONENT_DETAIL.replace(':id', row.id))
            const customPropertyComponent = {
                id: row.id,
                item_description: row.item_description,
                part_number: row.part_number,
            }
            localStorage.setItem(
                'DIRECT_COMPONENT',
                JSON.stringify(customPropertyComponent),
            )
        },
        [navigate],
    )

    if (isLoading) {
        return <TableLoading colSpan={ComponentHeadCells.length} />
    }

    return (
        <TableBody>
            {components.length > 0 ? (
                components.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                            <TableCell component='th' scope='row'>
                                <Link
                                    component='button'
                                    variant='inherit'
                                    to='#'
                                    underline='hover'
                                    onClick={() => handleClickDirection(row)}
                                >
                                    <Typography variant='body2'>
                                        {row.id}
                                    </Typography>
                                </Link>
                            </TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.part_number}</TableCell>
                            <TableCell>{row.item_description}</TableCell>
                            <TableCell>
                                <PartCodeDisplayer
                                    partCode={row.part_code?.code}
                                    componentId={row.id}
                                />
                            </TableCell>
                            <TableCell>{row.part_code?.name}</TableCell>
                            <TableCell>{row.part_code?.type}</TableCell>
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={ComponentHeadCells.length} />
            )}
        </TableBody>
    )
}

ComponentTableBody.propTypes = {
    isLoading: PropTypes.bool,
    components: PropTypes.arrayOf(PropTypes.object),
}

const ComponentTable = () => {
    const [type, setType] = useState('')

    const {
        data,
        isLoading,
        search,
        searchHandler,
        order,
        orderBy,
        sortHandler,
        page,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useComponentsQuery,
        initialStates: {
            order: 'asc',
        },
        queryAttributes: {
            type,
        },
    })

    const components = useMemo(() => {
        return data?.data || []
    }, [data])

    return (
        <>
            <ComponentListToolbar payload={{ search, type }} />
            <Paper sx={{ width: '100%', mb: 2 }}>
                <ComponentTableFilters
                    search={search}
                    searchHandler={searchHandler}
                    type={type}
                    setType={setType}
                    onEnterSearch={onEnterSearch}
                />
                <Divider />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby='tableTitle'
                        size='medium'
                    >
                        <TableHeader
                            headCells={ComponentHeadCells}
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                            localization={localization}
                        />

                        <ComponentTableBody
                            isLoading={isLoading}
                            components={components}
                        />
                    </Table>
                </TableContainer>

                <PaginationTable
                    data={data}
                    page={page}
                    size='medium'
                    handleChangePage={changePageHandler}
                />
            </Paper>
        </>
    )
}
export default ComponentTable
