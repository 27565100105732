import { Grid, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import PartContent from './PartsSession/PartContent'

import { useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '~/components/Button'
import { ISSUE_DISPATCH_EDIT } from '~/constants/Routes'
import { selectUserPermissions } from '~/store/auth/selector'
import DeviceSection from '../AddDispatch/components/DeviceSection'
import DispatchHistoryTable from './StatusHistorySession/DispatchHistoryTable'
import DispatchAccordions from './components/DispatchAccordions'
import DispatchAttachments from './components/DispatchAttachments'
import DispatchContactInfo from './components/DispatchContactInfo'
import DispatchDeliveryInfo from './components/DispatchDeliveryInfo'
import DispatchShippingInfo from './components/DispatchShippingInfo'
import DispatchWorkOrderInfo from './components/DispatchWorkOrderInfo'
import UpdateStateButton from './components/UpdateStateButton'
import { SUPER_ADMIN } from '~/constants/constants'

const localization = 'pages.dispatches.dispatch.detail'
const permissions = [SUPER_ADMIN, 'issues.dispatches.close']

const DispatchDetail = ({ dispatchContent }) => {
    const { t } = useTranslation('translation', { keyPrefix: localization })
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const items = useMemo(() => {
        return [
            {
                title: 'Attachments',
                content: (
                    <DispatchAttachments
                        ticketId={Number(dispatchContent?.ticket_id)}
                    />
                ),
            },
            {
                title: 'ContactInformation',
                content: (
                    <DispatchContactInfo dispatch={dispatchContent} t={t} />
                ),
            },
            {
                title: 'WorkOrderInformation',
                content: (
                    <DispatchWorkOrderInfo dispatch={dispatchContent} t={t} />
                ),
            },
            {
                title: 'PartsDetail',
                content: <PartContent dispatchId={dispatchContent?.id} />,
            },
            {
                title: 'ShippingInformation',
                content: (
                    <DispatchShippingInfo dispatch={dispatchContent} t={t} />
                ),
            },
            {
                title: 'DeliveryInformation',
                content: (
                    <DispatchDeliveryInfo dispatch={dispatchContent} t={t} />
                ),
            },
            {
                title: 'StatusHistory',
                content: (
                    <DispatchHistoryTable dispatchContent={dispatchContent} />
                ),
            },
        ]
    }, [dispatchContent, t])

    const userPermission = useSelector(selectUserPermissions)

    const isEditable = useMemo(() => {
        if (
            dispatchContent?.state !== 'BLOCKED' &&
            dispatchContent?.state !== 'NOT_SUBMITTED' &&
            dispatchContent?.state !== undefined
        ) {
            return false
        }

        if (
            !userPermission?.some(
                (perm) =>
                    perm === 'super_admin' ||
                    perm === 'issues.dispatches.edit' ||
                    perm === 'issues.dispatches.resubmit',
            )
        ) {
            return false
        }

        return true
    }, [dispatchContent?.state, userPermission])

    const hasCloseDispatchPermission = permissions.some((item) =>
        userPermission.includes(item),
    )

    return (
        <>
            {isEditable && (
                <Stack direction='row-reverse' sx={{ width: '100%', mb: 2 }}>
                    <LoadingButton
                        label='Edit'
                        onClick={() => {
                            navigate(
                                ISSUE_DISPATCH_EDIT.replace(
                                    ':id',
                                    dispatchContent?.id,
                                ),
                            )
                            queryClient.resetQueries('dispatch_detail')
                        }}
                    />
                </Stack>
            )}

            {dispatchContent?.state !== 'CLOSED' &&
                hasCloseDispatchPermission && <UpdateStateButton />}

            <DeviceSection
                searchedSerial={dispatchContent?.serial}
                setIsFoundSerialData={() => {}}
                dispatch={dispatchContent}
                isDispatchDetail
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DispatchAccordions
                        items={items}
                        localization={localization}
                    />
                </Grid>
            </Grid>
        </>
    )
}
DispatchDetail.propTypes = { dispatchContent: PropTypes.object.isRequired }

export default DispatchDetail
