import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import { PRODUCTION_PROJECTS, PRODUCTION_SYSTEMIMAGE } from '~/constants/Routes'
import { useSnackbar } from '~/hooks/useSnackbar'
import { useProjectQuery } from '../query'
import ProjectDetailTable from './ProjectDetailTable'
import ProjectAddonTable from './ProjectAddonTable'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '~/components/Button'
import { useState } from 'react'
import AssignAddon from './AssignAddon'
import UpdateProject from '../ProjectAction/UpdateProject'

const ProjectDetail = () => {
    const { t } = useTranslation()
    const { project_nr } = useParams()
    const [open, setOpen] = useState(false)
    const [openUpdateProject, setOpenUpdateProject] = useState(false)
    const { showSnackbar, snackbar } = useSnackbar()

    const { data, isLoading } = useProjectQuery({ project_nr, showSnackbar })

    const breadCrumbConfig = [
        { route: PRODUCTION_SYSTEMIMAGE, title: 'Production' },
        { route: PRODUCTION_PROJECTS, title: 'Projects' },
        { route: '#', title: 'detail' },
    ]

    if (isLoading) {
        return <CircularProgress />
    }

    return (
        <>
            {snackbar}
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <PageName
                name={
                    data?.data?.project_nr +
                    ' - ' +
                    data?.data?.os_version +
                    ' - (' +
                    data?.data?.external_id +
                    ')'
                }
            />
            <Grid
                container
                spacing={3}
                sx={{
                    marginBottom: '20px',
                }}
            >
                <Grid item xs={12} md={6}>
                    <Paper
                        sx={{
                            py: 2,
                            px: 2,
                            height: '100%',
                            marginBottom: '2px',
                        }}
                    >
                        <Grid item xs={12} md={12}>
                            {data?.data && (
                                <ProjectDetailTable
                                    isLoading={isLoading}
                                    data={data?.data}
                                />
                            )}
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ float: 'right', marginRight: '10px' }}>
                        <LoadingButton
                            label={t('pages.production.projects.assignAddon')}
                            onClick={() => {
                                setOpen(true)
                            }}
                            disabled={false}
                        />
                    </Box>
                    <Box sx={{ float: 'right', marginRight: '10px' }}>
                        <LoadingButton
                            label={t('pages.production.projects.editProject')}
                            onClick={() => {
                                setOpenUpdateProject(true)
                            }}
                            disabled={false}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Typography variant='h5' sx={{ marginBottom: '10px' }}>
                {t('pages.production.projects.projectAddon')}
            </Typography>
            <ProjectAddonTable projectId={data?.data?.id} />

            {open && (
                <AssignAddon projectId={data?.data?.id} setOpen={setOpen} />
            )}

            {openUpdateProject && (
                <UpdateProject
                    data={data?.data}
                    setOpenUpdateProject={setOpenUpdateProject}
                />
            )}
        </>
    )
}

export default ProjectDetail
