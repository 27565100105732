import { createSlice } from '@reduxjs/toolkit'

export const scheduleSlice = createSlice({
    name: 'scheduler',
    initialState: {
        scheduler: null,
    },
    reducers: {
        setSchedulerData: (state, action) => {
            state.scheduler = action.payload
        },
    },
})

export const { setSchedulerData } = scheduleSlice.actions

export default scheduleSlice
