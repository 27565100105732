import { Button as MuiButton } from '@mui/material'
import PropTypes from 'prop-types'

const Button = ({ children, ...props }) => {
    return (
        <MuiButton
            variant='contained'
            size='small'
            sx={{
                textTransform: 'none',
                backgroundColor: '#76B72A',
            }}
            {...props}
        >
            {children}
        </MuiButton>
    )
}

Button.propTypes = { children: PropTypes.element }
export default Button
