import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { userApi } from '~/api'
import { IMPERSONATE_TOKEN } from '~/constants/constants'
import { ADMINISTRATOR_USER } from '~/constants/Routes'
import { useSnackbar } from '~/hooks/useSnackbar'
import { setMessageDeleteUser } from '~/store/user/slice'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useUpdateStatusUserMutation = () => {
    return useMutation(
        ['update_status'],
        async ({ id, action }) => {
            const { data } = await userApi.updateStatusUser(id, { action })
            return data.data
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useUpdateDisableUserMutation = () => {
    const queryClient = useQueryClient()
    return useMutation(
        ['update_disable'],
        async ({ id, disabled }) => {
            const { data } = await userApi.updateDisableUser(id, { disabled })
            return data
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('user')
            },
            onError: () => {},
            retry: false,
        },
    )
}

export const useInviteUserMutation = () => {
    return useMutation(
        ['invite_user'],
        async ({ emails }) => {
            const { data } = await userApi.inviteUser({ emails })
            return data
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useDeleteInvitedUserMutation = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    return useMutation(
        ['delete'],
        async ({ id }) => {
            const { data } = await userApi.deleteInvitedUser(id)
            return data
        },
        {
            onSuccess: (data) => {
                navigate(ADMINISTRATOR_USER)
                dispatch(setMessageDeleteUser(data))
            },
            onError: () => {},
            retry: false,
        },
    )
}

export const useImpersonateUserMutation = () => {
    const { showSnackbar } = useSnackbar()

    return useMutation(
        async (userId) => {
            const { data } = await userApi.impersonateUser(userId)
            return data
        },
        {
            onSuccess: (data) => {
                if (data.auth_token) {
                    localStorage.setItem(IMPERSONATE_TOKEN, data.auth_token)
                    window.location.reload()
                }
            },
            onError: (error) => {
                showSnackbar(parseApiErrorMessage(error), 'error')
            },
            retry: false,
        },
    )
}
