import { DeviceComponentHeadCells } from '~/components/HeaderTable/DeviceComponentHeadCells'
import CustomTable from '~/components/Table'
import { useDeviceComponentsQuery } from './query'
import { PART_COMPONENT } from '~/constants/Routes'
import PropTypes from 'prop-types'
const localization = 'pages.device.serial.detail.'

const DeviceComponents = ({ device }) => {
    return (
        <CustomTable
            id={device?.id.toString()}
            customQuery={useDeviceComponentsQuery}
            headCells={DeviceComponentHeadCells}
            LOCALIZATION={localization}
            navigateTo={PART_COMPONENT}
            navigateAt={['id']}
        />
    )
}

DeviceComponents.propTypes = {
    device: PropTypes.object,
}

export default DeviceComponents
