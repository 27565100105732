import {
    FormControl,
    FormHelperText,
    MenuItem,
    Select as MuiSelect,
} from '@mui/material'
import BlackOutlineInput from '~/components/BlackOutlineInput'
import PropTypes from 'prop-types'

const Select = ({ items, input, meta, ...props }) => {
    const hasErrors = meta.touched && meta.invalid
    const helperText = hasErrors && (meta.error || meta.submitError)
    const handleChange = (event) => {
        input.onChange(event.target.value)
    }
    const inputProps = {
        ...input,
        ...props,
        onChange: handleChange,
    }
    return (
        <FormControl error={hasErrors}>
            <MuiSelect {...inputProps} input={<BlackOutlineInput />}>
                {items?.map((item, key) => {
                    return (
                        <MenuItem key={key} value={item.value}>
                            {item.name}
                        </MenuItem>
                    )
                })}
            </MuiSelect>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    )
}

Select.propTypes = {
    items: PropTypes.array,
    input: PropTypes.any,
    meta: PropTypes.object,
}

export default Select
