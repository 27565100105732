import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectPurchaseOrderLine } from '~/store/purchaseOrder/selector'

const OrderLineSession = () => {
    const purchaseOrderLine = useSelector(selectPurchaseOrderLine)
    return (
        <Grid container spacing={3}>
            <Grid item xs={4}>
                Ext article Id
            </Grid>
            <Grid item xs={8}>
                {purchaseOrderLine?.product_id}
            </Grid>
            <Grid item xs={4}>
                L number
            </Grid>
            <Grid item xs={8}>
                {purchaseOrderLine?.product_nr}
            </Grid>
            <Grid item xs={4}>
                Item number supplier
            </Grid>
            <Grid item xs={8}>
                {purchaseOrderLine?.supplier_code}
            </Grid>
            <Grid item xs={4}>
                Price ex VAT
            </Grid>
            <Grid item xs={8}>
                {purchaseOrderLine?.price}
            </Grid>
            <Grid item xs={4}>
                Quantity
            </Grid>
            <Grid item xs={8}>
                {purchaseOrderLine?.qty}
            </Grid>
            <Grid item xs={4}>
                Delivered
            </Grid>
            <Grid item xs={8}>
                {purchaseOrderLine?.delivered}
            </Grid>
        </Grid>
    )
}

export default OrderLineSession
