import { Divider, Paper, Table, TableContainer } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import PaginationTable from '~/components/PaginationTable'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import { useGetAllTicketQuery } from '../query'
import TicketTableBody from './TicketTableBody'
import TicketTableFilter from './TicketTableFilter'
import { headCells } from './constants'

const TicketTable = () => {
    const localization = `pages.issues.overview.`
    const [state, setState] = useState('')
    const [status, setStatus] = useState('')
    const {
        data,
        results,
        isLoading,
        page,
        setPage,
        order,
        orderBy,
        search,
        searchHandler,
        sortHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useGetAllTicketQuery,
        initialStates: {
            order: 'desc',
            orderBy: '',
            search: '',
            page: 1,
        },
        queryAttributes: {
            status,
            state,
        },
    })

    const refValue = useRef({
        search: '',
        status: '',
        state: '',
    })

    useEffect(() => {
        if (
            status !== refValue.current.status ||
            state !== refValue.current.state
        ) {
            setPage(1)
            refValue.current.status = status
            refValue.current.state = state
        }
    }, [status, state, setPage])

    return (
        <Paper>
            <TicketTableFilter
                search={search}
                searchHandler={searchHandler}
                onEnterSearch={onEnterSearch}
                status={status}
                setStatus={setStatus}
                state={state}
                setState={setState}
            />
            <Divider />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHeader
                        {...{
                            order,
                            orderBy,
                            localization,
                            headCells,
                        }}
                        onRequestSort={sortHandler}
                    />
                    <TicketTableBody {...{ isLoading, issues: results }} />
                </Table>
            </TableContainer>
            <PaginationTable
                {...{ data, page, size: 'small' }}
                handleChangePage={changePageHandler}
            />
        </Paper>
    )
}
export default TicketTable
