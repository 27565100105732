import PropTypes from 'prop-types'

import {
    Link,
    TableBody as MuiTableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import { useNavigate } from 'react-router-dom'
import { renderBooleanValue } from '~/utils/helpers'
import TableLoading from './TableLoading'
import DateTimeLocale from '../DateTimeLocale/DateTimeLocale'

const TableBody = ({
    isLoading,
    items,
    headCells,
    navigateTo,
    navigateAt,
    ...props
}) => {
    const navigate = useNavigate()
    const { sx } = { ...props.props }

    if (isLoading) {
        return <TableLoading colSpan={headCells.length} />
    }

    const dataKey = headCells.map((cell) => cell.key || cell.id)

    const renderedValue = (row, key) => {
        const dateTime = headCells.find(
            (x) => x.dataType === 'datetime' && (x.key === key || x.id === key),
        )

        const showHour = headCells.find(
            (x) =>
                x.dataType === 'datetime' &&
                x.notShowHour === true &&
                (x.key === key || x.id === key),
        )

        const booleanValue = headCells.find(
            (x) => x.dataType === 'boolean' && (x.key === key || x.id === key),
        )
        if (dateTime) {
            return <DateTimeLocale datetime={row[key]} formatHour={showHour} />
        }
        if (booleanValue && row[key] !== null) {
            return renderBooleanValue(row[key])
        }

        if (key.includes('.')) {
            const keyArr = key.split('.')

            let data = row

            keyArr.forEach((key) => {
                data = data[key]
            })

            return data
        }

        return row[key]
    }

    return (
        <MuiTableBody>
            {items.length > 0 ? (
                items.map((row, rowIndex) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.code || row.id}>
                            {dataKey.map((key, cellIndex) => {
                                if (
                                    navigateTo &&
                                    navigateAt.includes(dataKey[cellIndex])
                                ) {
                                    return (
                                        <TableCell
                                            align='left'
                                            key={`${key}-${rowIndex}-${cellIndex}`}
                                            sx={row.style ? sx : {}}
                                        >
                                            <Link
                                                component='button'
                                                variant='inherit'
                                                to='#'
                                                underline='hover'
                                                onClick={() =>
                                                    navigate(
                                                        `${navigateTo}/${row.id}`,
                                                    )
                                                }
                                            >
                                                <Typography
                                                    variant='body2'
                                                    align='left'
                                                >
                                                    {row[key]}
                                                </Typography>
                                            </Link>
                                        </TableCell>
                                    )
                                }

                                return (
                                    <TableCell
                                        align='left'
                                        key={`${key}-${rowIndex}-${cellIndex}`}
                                        sx={row.style ? sx : {}}
                                    >
                                        <>{renderedValue(row, key)}</>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={headCells.length} />
            )}
        </MuiTableBody>
    )
}

TableBody.propTypes = {
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    headCells: PropTypes.array,
    navigateTo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    navigateAt: PropTypes.arrayOf(PropTypes.string),
    props: PropTypes.any,
}

TableBody.defaultProps = {
    isLoading: false,
    items: [],
    headCells: [],
    navigateTo: false,
    navigateAt: [],
}

export default TableBody
