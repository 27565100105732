import PropTypes from 'prop-types'
import { Box, Pagination } from '@mui/material'
import { PER_PAGE } from '~/constants/constants'

const PaginationTable = ({ data, page, handleChangePage, size }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                py: 2,
                bgcolor: '#f4f4f4',
            }}
        >
            <Pagination
                count={
                    Math.ceil(data?.total / PER_PAGE) >= 1
                        ? Math.ceil(data?.total / PER_PAGE)
                        : 0
                }
                page={page}
                size={size}
                onChange={handleChangePage}
            />
        </Box>
    )
}

PaginationTable.propTypes = {
    data: PropTypes.object,
    page: PropTypes.number,
    handleChangePage: PropTypes.func,
    size: PropTypes.string,
}

export default PaginationTable
