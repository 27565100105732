import { FormControl, Grid, Stack, Toolbar } from '@mui/material'
import { Box } from '@mui/system'
import CancelButton from '~/components/CancelButton'
import SubmitButton from '~/components/SubmitButton'
import { useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { useActiveLocaleListQuery } from './query'
import AutoCompleteField from '~/components/FinalForm/AutoCompleteField'
import { USER_SETTING } from '~/constants/Routes'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import PropTypes from 'prop-types'
import { TIMEZONES } from '~/constants/constants'

const pagePath = USER_SETTING
const localization = 'pages.setting'
const SettingForm = ({ onSubmit, isLoading, name, initialValues }) => {
    const { data: locales } = useActiveLocaleListQuery({
        menuOption: true,
    })

    const localesList = useMemo(() => {
        if (locales) {
            return locales.map((locale) => ({
                name: `${locale.code} --- ${locale.country}`,
                value: locale.code,
            }))
        }
        return []
    }, [locales])

    const timezoneList = useMemo(() => {
        return TIMEZONES.map((tz) => ({
            name: tz,
            value: tz,
        }))
    }, [])

    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            subscription={{ values: true }}
            render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                    <Toolbar disableGutters={false}>
                        <Box sx={{ flexGrow: 1 }} />
                        <Stack direction='row-reverse' spacing={2}>
                            <SubmitButton
                                isLoading={isLoading}
                                handleSubmit={handleSubmit}
                                name={name}
                            />
                            <CancelButton />
                        </Stack>
                    </Toolbar>
                    <FormBodyWrapper>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormControl sx={{ width: '100%' }}>
                                    <Field
                                        name='locale'
                                        component={AutoCompleteField}
                                        items={localesList}
                                        localization={localization}
                                        componentId='setting_form_locale'
                                        pagePath={pagePath}
                                        nameSelect='locale'
                                        isRequired={true}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl sx={{ width: '100%' }}>
                                    <Field
                                        name='timezone'
                                        component={AutoCompleteField}
                                        items={timezoneList}
                                        localization={localization}
                                        componentId='setting_form_timezone'
                                        pagePath={pagePath}
                                        nameSelect='timezone'
                                        isRequired={true}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </FormBodyWrapper>
                </form>
            )}
        />
    )
}

SettingForm.propTypes = {
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    name: PropTypes.string,
    initialValues: PropTypes.any,
}

export default SettingForm
