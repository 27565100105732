import { useRegionsListQuery } from '~/pages/Region/query'
import { useUsersQuery } from '~/pages/User/query'
import { useMemo } from 'react'
import { useAddressesListQuery, useBranchesListQuery } from '../query'

const useServiceCenterFormData = () => {
    const { data: regions } = useRegionsListQuery({
        menuOption: true,
    })

    const { data: addresses } = useAddressesListQuery({
        menuOption: true,
    })

    const { data: branches } = useBranchesListQuery({
        menuOption: true,
    })

    const { data: users } = useUsersQuery({
        menuOption: true,
    })

    const regionsList = useMemo(() => {
        if (regions) {
            const result = regions.data.map((region) => ({
                name: region.name,
                value: region.id,
            }))

            return result
        }
        return []
    }, [regions])

    const addressesList = useMemo(() => {
        if (addresses) {
            const result = addresses.map((address) => ({
                name: `${address.street_1}, ${address.city}, ${address.zipcode}, ${address.iso_country?.name}`,
                value: address.id,
            }))

            return result
        }
        return []
    }, [addresses])

    const branchesList = useMemo(() => {
        if (branches) {
            const result = branches.map((branch) => ({
                name: branch.name,
                value: branch.id,
            }))

            return result
        }
        return []
    }, [branches])

    const usersList = useMemo(() => {
        if (users) {
            const result = users.data.map((user) => ({
                name: `${user.name} (${user.email})`,
                value: user.id,
            }))

            return result
        }
        return []
    }, [users])

    const InputFieldGroup = useMemo(() => {
        return [
            {
                name: 'name',
                componentId: 'service_center_form_name',
                isRequired: true,
            },
            {
                name: 'description',
                componentId: 'service_center_form_description',
            },
        ]
    }, [])

    const selectGroup = useMemo(() => {
        return [
            {
                title: 'contactPerson',
                name: 'contact_person_id',
                listResource: usersList,
                componentId: 'service_center_form_contactPerson',
            },
            {
                title: 'address',
                name: 'address_id',
                listResource: addressesList,
                required: true,
                componentId: 'service_center_form_address',
            },
        ]
    }, [addressesList, usersList])

    const selectGroupCreateForm = useMemo(() => {
        return [
            {
                title: 'region',
                name: 'region_id',
                listResource: regionsList,
                required: true,
                componentId: 'service_center_form_region',
            },
            ...selectGroup,
        ]
    }, [regionsList, selectGroup])

    return {
        InputFieldGroup,
        selectGroup,
        selectGroupCreateForm,
        branchesList,
    }
}

export default useServiceCenterFormData
