import { useMutation } from 'react-query'
import { dispatchApi } from '~/api'

export const useResubmitDispatchMutation = () => {
    return useMutation(
        ['dispatch_resubmit'],
        async (payload) => {
            const { data } = await dispatchApi.resubmitDispatch(payload)
            return data
        },
        {
            retry: false,
        },
    )
}
