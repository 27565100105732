import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { ToggleButton } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import Toolbar from '@mui/material/Toolbar'
import { styled, useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import stagingLogo from '~/assets/images/easy4D_staging.png'
import { ReactComponent as Easy4DeviceLogo } from '~/assets/images/logoeasy4device.svg'
import CornerRibbon from '~/components/CornerRibbon'
import Loading from '~/components/Loading'
import Menu from '~/components/Menu/Menu'
import StyledModal from '~/components/StyledModal'
import LanguagePopover from '~/components/language/LanguagePopover'
import { useSnackbar } from '~/hooks/useSnackbar'
import CameraScanner from '~/pages/Camera/CameraScanner'
import { useDeviceDetailSearchQuery } from '~/pages/Device/Serial/SerialDetail/query'
import { selectSearchSerial } from '~/store/device/selector'
import { setMessageSearchSerial } from '~/store/device/slice'
import { selectMenu } from '~/store/menu/selector'
import { setIsOpenMenu } from '~/store/menu/slice'
import AvatarUser from './AvatarUser'
import GlobalSearch from './GlobalSearch'
import ImpersonateUser from './ImpersonateUser'
import { getImpersonateToken, isProductionApp } from '~/utils/helpers'

const drawerWidth = 300

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const Main = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ open, theme }) => {
    const drawerWidthInPx = `${drawerWidth}px`
    return {
        maxWidth: `calc(100% - ${
            open ? drawerWidthInPx : theme.spacing(7)
        } - 1px)`,
        [theme.breakpoints.up('sm')]: {
            maxWidth: `calc(100% - ${
                open ? drawerWidthInPx : theme.spacing(8)
            } - 1px)`,
        },
    }
})

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}))

const BoxStyle = styled(Box)(({ theme }) => ({
    display: 'block',
    [theme.breakpoints.down('md')]: {
        display: 'none',
    },
}))

const Layout = ({ children }) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const [openCamera, setOpenCamera] = useState(false)
    const [serial, setSerial] = useState('')
    const dispatch = useDispatch()
    const openDrawer = useSelector(selectMenu)
    const { isLoading } = useDeviceDetailSearchQuery(serial, setSerial)

    const { showSnackbar, snackbar } = useSnackbar()
    const messageSearchSerial = useSelector(selectSearchSerial)
    useEffect(() => {
        if (messageSearchSerial && messageSearchSerial.status === 404) {
            showSnackbar(
                `${t('message.searchSerial')}${messageSearchSerial.serial}`,
                'error',
            )
            dispatch(setMessageSearchSerial(null))
            setSerial(null)
        }
    }, [dispatch, messageSearchSerial, showSnackbar, t])

    const handleDrawerOpen = () => {
        dispatch(setIsOpenMenu(true))
    }

    const handleDrawerClose = () => {
        dispatch(setIsOpenMenu(false))
    }

    return (
        <Box sx={{ display: 'flex' }}>
            {snackbar}
            <CssBaseline />
            <AppBar
                position='fixed'
                open={openDrawer}
                style={{ background: '#102F44' }}
            >
                <Toolbar>
                    <IconButton
                        color='inherit'
                        aria-label='open drawer'
                        onClick={handleDrawerOpen}
                        edge='start'
                        sx={{
                            marginRight: 5,
                            ...(openDrawer && { display: 'none' }),
                        }}
                    >
                        <KeyboardDoubleArrowRightIcon
                            style={{ color: '#76B72A' }}
                        />
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    {!isProductionApp && getImpersonateToken() && (
                        <ImpersonateUser />
                    )}
                    <GlobalSearch />

                    <BoxStyle sx={{ marginRight: '25px' }}>
                        <ToggleButton
                            value='check'
                            onChange={() => setOpenCamera(true)}
                        >
                            <Box
                                component='span'
                                className='material-symbols-rounded'
                                sx={{
                                    color: openCamera
                                        ? (theme) => theme.palette.green[100]
                                        : null,
                                }}
                            >
                                photo_camera
                            </Box>
                        </ToggleButton>
                    </BoxStyle>
                    <StyledModal
                        open={openCamera}
                        handleClose={setOpenCamera}
                        stackProps={{
                            width: '900px',
                            height: '900px',
                            padding: '2px 10px',
                            sx: {
                                overflow: 'scroll',
                                borderRadius: 0,
                            },
                        }}
                    >
                        <CameraScanner setOpenCamera={setOpenCamera} />
                    </StyledModal>

                    <Box sx={{ marginRight: '25px' }}>
                        <LanguagePopover />
                    </Box>
                    <AvatarUser />
                </Toolbar>
            </AppBar>
            <Drawer
                variant='permanent'
                open={openDrawer}
                style={{ backgroundColor: 'grey' }}
            >
                <DrawerHeader
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        ml: 2,
                    }}
                >
                    {import.meta.env.VITE_ENVIRONMENT === 'development' ? (
                        <img src={stagingLogo} alt='' width={120}></img>
                    ) : (
                        <Easy4DeviceLogo width={120} height={50} />
                    )}

                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardDoubleArrowRightIcon
                                style={{ color: '#76B72A' }}
                            />
                        ) : (
                            <KeyboardDoubleArrowLeftIcon
                                style={{ color: '#76B72A' }}
                            />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <Menu />
                </List>

                <Divider />
            </Drawer>
            {import.meta.env.VITE_ENVIRONMENT === 'development' && (
                <CornerRibbon
                    position='bottom-left'
                    fontColor='#f0f0f0'
                    backgroundColor='#FF0000'
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        zIndex: theme.zIndex.drawer + 1,
                    }}
                >
                    Development
                </CornerRibbon>
            )}
            <Main component='main' sx={{ flexGrow: 1, p: 3 }} open={openDrawer}>
                <DrawerHeader />
                {isLoading ? <Loading /> : <Box>{children}</Box>}
            </Main>
        </Box>
    )
}

Layout.propTypes = {
    children: PropTypes.any,
}

export default Layout
