import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import PageName from '~/components/PageName'
import { PROCUREMENT, PROCUREMENT_ORDERCONFIRMATION } from '~/constants/Routes'
import OrderConfirmationTable from './OrderConfirmationTable'

const OrderConfirmation = () => {
    const BreadComponents = [
        { route: PROCUREMENT, title: 'Procurement' },
        {
            route: PROCUREMENT_ORDERCONFIRMATION,
            title: 'OrderConfirmations',
        },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={PROCUREMENT_ORDERCONFIRMATION}
            >
                <div>
                    <PageName name={'Order confirmation'} />
                </div>
            </ComponentTooltip>
            <OrderConfirmationTable />
        </>
    )
}

export default OrderConfirmation
