export const TagColumnConfig = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        orderable: false,
    },
]
