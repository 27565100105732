import { Stack } from '@mui/material'
import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import ExternalLink from '~/components/ExternalLink'
import PageName from '~/components/PageName'
import { EASY4U_CUSTOMER_ORDER_NUMBER } from '~/constants/externalLink'
import {
    PROCUREMENT,
    PROCUREMENT_PURCHASEORDER,
    PROCUREMENT_PURCHASEORDER_DETAIL,
} from '~/constants/Routes'

import { useParams } from 'react-router-dom'
import PurchaseOrderDetailTable from './PurchaseOrderDetailTable'

const PurchaseOrderDetail = () => {
    const { id } = useParams()

    const BreadComponents = [
        { route: PROCUREMENT, title: 'Procurement' },
        {
            route: PROCUREMENT_PURCHASEORDER,
            title: 'PurchaseOrders',
        },
        {
            page: 'detail',
            title: id,
        },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <Stack direction='row' spacing={1}>
                <ComponentTooltip
                    componentId={`page_title`}
                    pagePath={PROCUREMENT_PURCHASEORDER_DETAIL}
                >
                    <div>
                        <PageName name={`Purchase Order -`} />
                        <ExternalLink
                            extLink={EASY4U_CUSTOMER_ORDER_NUMBER}
                            param={id}
                            nameButton={id}
                            sx={{ fontSize: 22 }}
                        />
                    </div>
                </ComponentTooltip>
            </Stack>

            <PurchaseOrderDetailTable />
        </>
    )
}

export default PurchaseOrderDetail
