import { useMutation, useQuery, useQueryClient } from 'react-query'
import { remittanceApi } from '~/api'

export const useDispatchRemittanceQuery = () => {
    return useQuery('dispatch_remittance', async () => {
        const { data } = await remittanceApi.getImportedRemittanceFiles()
        const fileNames = data.files
            ? data.files.map((filePath) => {
                  return filePath?.split('/').pop()
              })
            : []
        return {
            fileNames,
            oldestImport: data.oldest_import,
            latestImport: data.latest_import,
        }
    })
}

export const useDispatchRemittanceMutation = (params) => {
    const queryClient = useQueryClient()
    return useMutation(
        async (file) => {
            const data = new FormData()
            data.append('dispatch_remittance', file)
            const results = await remittanceApi.importRemittance(data)
            return results.data
        },

        {
            onSettled: () => {
                queryClient.invalidateQueries(['dispatch_remittance'])
            },
            retry: false,
        },
    )
}
