import { Grid } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuthTokenMutation } from './query'

function SsoAuthReceiver() {
    const search = useLocation()
    const code = useMemo(() => {
        return new URLSearchParams(search.search).get('code')
    }, [search])

    const { mutate } = useAuthTokenMutation()

    useEffect(() => {
        mutate(code)
    }, [code, mutate])

    return (
        <Grid
            container
            spacing={0}
            direction='column'
            alignItems='center'
            justifyContent='center'
            style={{ minHeight: '100vh' }}
        >
            <CircularProgress />
        </Grid>
    )
}

export default SsoAuthReceiver
