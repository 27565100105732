import { Grid, TextareaAutosize } from '@mui/material'
import SectionTitle from './components/SectionTitle'
import { useTranslation } from 'react-i18next'
import { LOCALIZATION } from './constants'
import PropTypes from 'prop-types'
import LoadingCircle from '~/components/LoadingCircle'

const DiagnosticDescription = ({ value, loading = false }) => {
    const { t } = useTranslation()

    return (
        <Grid container>
            <SectionTitle
                title={t(LOCALIZATION + `diagnosticsDescription`)}
                xs={12}
                md={12}
                lg={12}
                align='left'
                ml={2}
            />

            <Grid item xs={12} md={12} lg={12} align='left' m={2}>
                {loading ? (
                    <LoadingCircle />
                ) : (
                    <Textarea defaultValue={value} />
                )}
            </Grid>
        </Grid>
    )
}

DiagnosticDescription.propTypes = {
    value: PropTypes.array,
    loading: PropTypes.bool,
}

const Textarea = ({ input, defaultValue, ...props }) => {
    return (
        <TextareaAutosize
            {...input}
            {...props}
            disabled
            minRows={5}
            maxRows={5}
            defaultValue={defaultValue}
            style={{ width: '100%' }}
        />
    )
}

Textarea.propTypes = { input: PropTypes.object, defaultValue: PropTypes.string }

export default DiagnosticDescription
