import { CircularProgress, TableCell, TableRow } from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import PartCodeTableComponent from '~/components/PartCodeTable'
import { useIssuePartCodeQuery } from '~/pages/Diagnostic/query'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectShowedPartCodeForDiagnostic } from '~/store/partcode/selector'
import propTypes from 'prop-types'
import PartCodeRow from '~/pages/Diagnostic/Detail/PartCode/PartCodeRow'

const localization = 'pages.device.serial.detail.'

const ShowingPartCodeTable = ({
    showMaliciousCondition,
    showAccidentalCondition,
    finishDiagnostic,
    hasAddAdditionalPartsRight,
    errorPartCodes,
    ppidRegex,
}) => {
    const { issueId } = useParams()
    const showingPartCode = useSelector(selectShowedPartCodeForDiagnostic)

    const [flag, setFlag] = useState(true)

    const { isLoading, isSuccess } = useIssuePartCodeQuery(
        issueId,
        finishDiagnostic || flag,
    )

    useEffect(() => {
        if (isSuccess) {
            setFlag(false)
        }
    }, [isSuccess])

    const renderPartCode = useMemo(() => {
        return showingPartCode ?? []
    }, [showingPartCode])

    const hasManuallyAddedPart = useMemo(() => {
        return renderPartCode.some(
            (item) =>
                item.causes &&
                item.causes.some(
                    (cause) => cause.id === '#' || cause.id === null,
                ),
        )
    }, [renderPartCode])

    const partCodeTableHeader = useMemo(() => {
        const arr = [
            {
                id: 'code',
                numeric: true,
                label: 'code',
            },
            {
                id: 'name',
                numeric: true,
                label: 'name',
            },
            {
                id: 'type',
                numeric: true,
                label: 'type',
            },
            {
                id: 'broken',
                numeric: true,
                label: 'condition',
            },
            {
                id: 'qty',
                numeric: true,
                label: 'qty',
            },
            {
                id: 'ppid',
                numeric: true,
                label: 'ppid',
            },
            {
                id: 'causes',
                numeric: true,
                label: 'causes',
                align: 'center',
            },
        ]

        if (hasManuallyAddedPart) {
            arr.push({
                id: 'additionalDescription',
                numeric: true,
                label: 'additionalDescription',
                align: 'center',
            })
        }

        if (!finishDiagnostic) {
            arr.push({
                id: 'action',
                numeric: true,
                label: 'action',
                align: 'center',
            })
        }

        return arr
    }, [finishDiagnostic, hasManuallyAddedPart])

    if (isLoading)
        return (
            <PartCodeTableComponent
                partCodeTableHeader={partCodeTableHeader}
                localization={localization}
            >
                <TableRow>
                    <TableCell
                        align='center'
                        colSpan={partCodeTableHeader.length}
                        sx={{ py: 3 }}
                    >
                        <CircularProgress />
                    </TableCell>
                </TableRow>
            </PartCodeTableComponent>
        )

    return (
        <PartCodeTableComponent
            partCodeTableHeader={partCodeTableHeader}
            localization={localization}
        >
            {renderPartCode.length >= 1 ? (
                renderPartCode?.map((partCode) => (
                    <PartCodeRow
                        key={partCode.code}
                        {...{
                            partCode,
                            showMaliciousCondition,
                            showAccidentalCondition,
                            finishDiagnostic,
                            hasAddAdditionalPartsRight,
                            errorPartCodes,
                            ppidRegex,
                            hasManuallyAddedPart,
                        }}
                    />
                ))
            ) : (
                <DataNotFound colSpan={partCodeTableHeader.length} />
            )}
        </PartCodeTableComponent>
    )
}

ShowingPartCodeTable.propTypes = {
    showMaliciousCondition: propTypes.bool,
    showAccidentalCondition: propTypes.bool,
    finishDiagnostic: propTypes.bool,
    hasAddAdditionalPartsRight: propTypes.bool,
    errorPartCodes: propTypes.array,
    ppidRegex: propTypes.string,
}

export default ShowingPartCodeTable
