import { Box, Divider, Typography, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useCallback, useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { UploadFile } from '@mui/icons-material'
import ImportRemittanceTable from './ImportRemittanceTable'
import {
    useDispatchRemittanceMutation,
    useDispatchRemittanceQuery,
} from './query'
import { useSnackbar } from '~/hooks/useSnackbar'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const ImportRemittancePage = () => {
    const localization = 'pages.dispute.remittance'
    const { t } = useTranslation()
    const [selectedFile, setSelectedFile] = useState(false)
    const {
        mutate,
        isLoading,
        isSuccess,
        isError,
        data: mutationResult,
        error: mutationError,
    } = useDispatchRemittanceMutation()
    const { data: queryData } = useDispatchRemittanceQuery()
    const { showSnackbar, snackbar } = useSnackbar()
    const onSubmit = useCallback(
        (values) => {
            mutate(selectedFile)
        },
        [mutate, selectedFile],
    )

    useEffect(() => {
        if (isSuccess) {
            if (mutationResult?.code === 'success') {
                showSnackbar(mutationResult?.message, 'success')
            } else {
                showSnackbar(mutationResult?.message, 'error')
            }
        }

        if (isError) {
            showSnackbar(mutationError?.message, 'error')
        }
    }, [
        isSuccess,
        isError,
        mutationResult?.code,
        mutationResult?.message,
        showSnackbar,
        mutationError?.message,
    ])
    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0])
    }

    return (
        <>
            <Divider sx={{ marginBottom: 3 }} />
            <Grid container>
                {snackbar}
                <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit, form }) => (
                        <Grid
                            container
                            item
                            spacing={2}
                            columnSpacing={0}
                            xs={3}
                        >
                            <Grid item xs={0}>
                                <Typography
                                    sx={{
                                        color: '#102F44',
                                        fontFamily: "'Lato',sans-serif",
                                        fontSize: '1 rem',
                                        fontWeightL: 400,
                                        lineHeight: 1.66,
                                    }}
                                >
                                    {t(`${localization}.fileUpload`)}
                                </Typography>
                            </Grid>
                            <Grid item xs={'auto'}>
                                <Field>
                                    {(props) => (
                                        <div>
                                            <input
                                                name='file'
                                                type='file'
                                                onChange={changeHandler}
                                            />
                                        </div>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <LoadingButton
                                        variant='contained'
                                        color='primary'
                                        size='medium'
                                        fullWidth
                                        type='submit'
                                        onClick={handleSubmit}
                                        loading={isLoading}
                                        loadingPosition='start'
                                        startIcon={<UploadFile />}
                                        disabled={!selectedFile}
                                    >
                                        {t(
                                            `${localization}.import`,
                                        ).toUpperCase()}
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                />
            </Grid>
            <Divider sx={{ marginBottom: 3 }} />
            <Grid container spacing={2}>
                <Grid item>
                    <Typography
                        sx={{
                            color: '#102F44',
                            fontFamily: "'Lato',sans-serif",
                            fontSize: '1 rem',
                            fontWeightL: 400,
                            lineHeight: 1.66,
                        }}
                    >
                        {t(`${localization}.oldestImport`)}
                    </Typography>
                </Grid>
                <Grid item>
                    <DateTimeLocale datetime={queryData?.oldestImport} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item>
                    <Typography
                        sx={{
                            color: '#102F44',
                            fontFamily: "'Lato',sans-serif",
                            fontSize: '1 rem',
                            fontWeightL: 400,
                            lineHeight: 1.66,
                        }}
                    >
                        {t(`${localization}.latestImport`)}
                    </Typography>
                </Grid>
                <Grid item>
                    <DateTimeLocale datetime={queryData?.latestImport} />
                </Grid>
            </Grid>
            <Divider sx={{ marginBottom: 3 }} />
            <Grid container>
                <Typography
                    sx={{
                        color: '#102F44',
                        fontFamily: "'Lato',sans-serif",
                        fontSize: '1 rem',
                        fontWeightL: 400,
                        lineHeight: 1.66,
                    }}
                >
                    {t(`${localization}.importedFile`)}
                </Typography>
                <ImportRemittanceTable data={queryData?.fileNames} />
            </Grid>
        </>
    )
}

export default ImportRemittancePage
