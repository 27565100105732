import Loading from '~/components/Loading'
import Navigator from '~/components/Navigator'
import { KEY_AUTH_TOKEN } from '~/constants/constants'
import { useSnackbar } from '~/hooks/useSnackbar'
import ApprovedUser from '~/pages/Approve'
import Login from '~/pages/Login'
import SsoAuthReceiver from '~/pages/SsoAuthReceiver'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useProfileQuery } from './query'
import * as qs from 'qs'

const MasterLayout = ({ isAuthenticated }) => {
    const [cachedAuthToken] = useState(localStorage.getItem(KEY_AUTH_TOKEN))
    const { showSnackbar, snackbar } = useSnackbar()

    useEffect(() => {
        if (isAuthenticated) {
            return
        }

        const path = window.location.pathname
        const { auth } = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        })

        if (auth === '1') {
            showSnackbar('Please login', 'warning')
            localStorage.setItem('REDIRECT_URL', path)
        } else {
            localStorage.removeItem('REDIRECT_URL')
        }
    }, [isAuthenticated, showSnackbar])

    const { isLoading: isLoadingProfile } = useProfileQuery(!!cachedAuthToken)
    if (isLoadingProfile) {
        return <Loading />
    }

    if (!isAuthenticated) {
        return (
            <>
                {snackbar}
                <Routes>
                    <Route path='/login' element={<Login />} />
                    <Route
                        path='/sso/authorize'
                        element={<SsoAuthReceiver />}
                    />
                    <Route path='/approved/:token' element={<ApprovedUser />} />
                    <Route path='*' element={<Navigate to='/login' />} />
                </Routes>
            </>
        )
    }

    return <Navigator />
}

MasterLayout.propTypes = {
    isAuthenticated: PropTypes.bool,
}

MasterLayout.defaultProps = {
    isAuthenticated: false,
}

export default MasterLayout
