import {
    Divider,
    Table as MuiTable,
    Paper,
    TableContainer,
} from '@mui/material'
import { ServiceAccountColumnConfigs } from '~/components/HeaderTable/ServiceAccountColumnConfigs'
import PaginationTable from '~/components/PaginationTable'
import TableHeader from '~/components/TableHeader'
import { ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT } from '~/constants/Routes'
import useTable from '~/hooks/useTable'
import Loading from '../../components/Loading'
import TableBody from '../../components/Table/TableBody'
import ServiceAccountTableFilters from './ServiceAccountTableFilters'
import { useClientTokenListQuery } from './query'

const Table = () => {
    const {
        results,
        data,
        search,
        isLoading,
        page,
        order,
        orderBy,
        changePageHandler,
        searchHandler,
        sortHandler,
        onEnterSearch,
    } = useTable({
        query: useClientTokenListQuery,
    })

    if (isLoading) {
        return <Loading />
    }
    return (
        <Paper>
            <ServiceAccountTableFilters
                search={search}
                searchHandler={searchHandler}
                onEnterSearch={onEnterSearch}
            />
            <Divider />
            <TableContainer
                sx={{ minWidth: 750 }}
                aria-labelledby='tableTitle'
                size='medium'
            >
                <MuiTable>
                    <TableHeader
                        headCells={ServiceAccountColumnConfigs}
                        localization={'pages.administrator.serviceAccounts.'}
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />
                    <TableBody
                        isLoading={isLoading}
                        items={results}
                        headCells={ServiceAccountColumnConfigs}
                        navigateAt={['id']}
                        navigateTo={ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT}
                    />
                </MuiTable>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}

export default Table
