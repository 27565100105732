import propTypes from 'prop-types'
import ImageList from '~/components/Image/ImageList'

const QcPictures = ({
    pictures = [],
    deletePicture = () => {},
    isConfirmForm = false,
}) => {
    return (
        <ImageList
            images={pictures}
            handleRemoveImage={deletePicture}
            isFinish={false}
            imageListProps={{
                cols: 3,
                rowHeight: 150,
                height: 'content-fit',
                overflow: 'hidden',
            }}
            isPictureFromQC={true}
            showRemoveImage={!isConfirmForm}
        />
    )
}

QcPictures.propTypes = {
    pictures: propTypes.array,
    deletePicture: propTypes.func,
    isConfirmForm: propTypes.bool,
}

export default QcPictures
