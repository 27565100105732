import SearchIcon from '@mui/icons-material/Search'
import { CircularProgress, InputBase, ToggleButton } from '@mui/material'
import Box from '@mui/material/Box'
import { alpha, styled } from '@mui/material/styles'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSnackbar } from '~/hooks/useSnackbar'
import { useGlobalQuery } from './query'

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
}))

const BoxStyle = styled(Box)(({ theme }) => ({
    display: 'block',
    [theme.breakpoints.down('md')]: {
        display: 'none',
    },
}))

const GlobalSearch = () => {
    const { pathname } = useLocation()

    const [searchValue, setSearchValue] = useState('')
    const [selected, setSelected] = useState(false)
    const [serial, setSerial] = useState('')
    const searchElement = useRef()

    const { showSnackbar, snackbar } = useSnackbar()

    /**
     * BASE ON THE PATHNAME WE WILL HANDLE THE SCAN FLOW
     * PLEASE CAREFULL WHEN EDIT useGlobalQuery()
     */
    const { isLoading } = useGlobalQuery({
        pathname,
        serial,
        showSnackbar,
        setSerial,
    })

    const handleEnterSearch = useCallback(
        (event) => {
            if (event.key === 'Enter') {
                setSearchValue('')
                setSerial(event.target.value)
                if (!selected) {
                    searchElement.current.blur()
                }
            }
        },
        [selected],
    )

    const handleSearch = useCallback((event) => {
        setSearchValue(event.target.value.toUpperCase())
    }, [])

    const handleFocusSearch = useCallback(() => {
        setSelected(!selected)
        localStorage.setItem('AUTO_FOCUS_SEARCH', !selected)
    }, [selected])

    useEffect(() => {
        const timer = setInterval(function () {
            const autoFocus = localStorage.getItem('AUTO_FOCUS_SEARCH')
            if (autoFocus === 'true' && selected) {
                searchElement?.current?.focus()
            }
        }, 1000)
        return () => clearInterval(timer)
    }, [selected])

    if (isLoading) {
        return <CircularProgress />
    }

    return (
        <>
            {snackbar}
            <BoxStyle sx={{ marginRight: '25px' }}>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        inputRef={searchElement}
                        value={searchValue}
                        placeholder='Search…'
                        inputProps={{ 'aria-label': 'search' }}
                        onKeyPress={handleEnterSearch}
                        onChange={handleSearch}
                    />
                </Search>
            </BoxStyle>
            <BoxStyle sx={{ marginRight: '25px' }}>
                <ToggleButton
                    value='check'
                    selected={selected}
                    onChange={handleFocusSearch}
                >
                    <Box
                        component='span'
                        className='material-symbols-rounded'
                        sx={{
                            color: selected
                                ? (theme) => theme.palette.green[100]
                                : null,
                        }}
                    >
                        barcode_scanner
                    </Box>
                </ToggleButton>
            </BoxStyle>
        </>
    )
}

export default GlobalSearch
