import { useTranslation } from 'react-i18next'
import Table from './Table'
import { selectSnackbar } from '~/store/snackbar/selector'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from '~/hooks/useSnackbar'
import { useEffect } from 'react'
import { unsetSnackbar } from '~/store/snackbar/slice'
import Toolbar from './Toolbar'
import BreadCrumb from '~/components/BreadCrumb'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT,
} from '~/constants/Routes'
import PageName from '../../components/PageName'
import ComponentTooltip from '~/components/ComponentTooltip'

const ServiceAccount = () => {
    const { t } = useTranslation()
    const snackbarData = useSelector(selectSnackbar)
    const { showSnackbar, snackbar } = useSnackbar()
    const dispatch = useDispatch()
    useEffect(() => {
        if (snackbarData) {
            showSnackbar(snackbarData.message, snackbarData.type)
        }
        return () => {
            dispatch(unsetSnackbar({}))
        }
    })

    const BreadComponents = [
        { route: ADMINISTRATOR, title: t(`breadCrumb.Administrator`) },
        {
            route: ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT,
            title: 'ServiceAccounts',
        },
    ]

    return (
        <>
            {snackbar}
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`service_account_title`}
                pagePath={ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT}
            >
                <div>
                    <PageName name={t(`breadCrumb.ServiceAccounts`)} />
                </div>
            </ComponentTooltip>

            <Toolbar localization={`pages.administrator.serviceAccounts`} />
            <Table />
        </>
    )
}

export default ServiceAccount
