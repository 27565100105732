import { useMemo } from 'react'
import TableFilters from '~/components/TableFilters'
import { useVendorsQuery } from '~/pages/Device/Serial/Overview/query'
import PropTypes from 'prop-types'

const FleetSearchToolbar = ({
    search,
    searchHandler,
    vendor,
    setVendor,
    onEnterSearch,
}) => {
    const { data: vendorData, isSuccess: isSuccessVendor } = useVendorsQuery()

    const vendors = useMemo(() => {
        if (isSuccessVendor) {
            const result = [...vendorData?.data]
            result.unshift({ id: '', name: 'ALL' })
            return result
        }
        return []
    }, [isSuccessVendor, vendorData?.data])

    const selectGroup = useMemo(() => {
        return [
            {
                label: 'vendor',
                handleFunc: setVendor,
                dataSet: vendors,
                value: vendor,
            },
        ]
    }, [setVendor, vendor, vendors])

    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='fleet'
            page='fleet'
        />
    )
}
FleetSearchToolbar.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    vendor: PropTypes.any,
    setVendor: PropTypes.func,
    onEnterSearch: PropTypes.func,
}

export default FleetSearchToolbar
