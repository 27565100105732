import ComponentTable from './ComponentTable'
import { PART, PART_COMPONENT } from '~/constants/Routes'
import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import ComponentTooltip from '~/components/ComponentTooltip'

const Component = () => {
    const BreadComponents = [
        { route: PART, title: 'Parts' },
        {
            route: PART_COMPONENT,
            title: 'Components',
        },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={PART_COMPONENT}
            >
                <div>
                    <PageName name={'Components'} />
                </div>
            </ComponentTooltip>
            <ComponentTable />
        </>
    )
}
export default Component
