import {
    ADMINISTRATOR,
    ADMINISTRATOR_DEFINITION,
    ADMINISTRATOR_DEFINITION_TEAM,
    ADMINISTRATOR_DEFINITION_TEAM_NEW,
} from '~/constants/Routes'
import { useCreateTeamMutation } from '../mutation'
import { Form } from 'react-final-form'
import Body from './Body'
import BreadCrumb from '~/components/BreadCrumb'
import { object, string } from 'yup'
import useFormValidationCallback from '../../../hooks/useFormValidationCallback'
import { useTranslation } from 'react-i18next'
import PageName from '../../../components/PageName'
import ToolbarForm from '~/components/ToolbarForm'
import ComponentTooltip from '~/components/ComponentTooltip'
import arrayMutators from 'final-form-arrays'

const TeamCreate = () => {
    const localization = 'pages.administrator.definition.team.create'
    const { t } = useTranslation()

    const schema = object().shape({
        name: string().required(),
        description: string(),
    })
    const validate = useFormValidationCallback(schema)

    const { mutate: createTeam, isLoading } = useCreateTeamMutation()

    const BreadComponents = [
        { route: ADMINISTRATOR, title: t(`breadCrumb.Administrator`) },
        {
            route: ADMINISTRATOR_DEFINITION,
            title: 'Definitions',
        },
        {
            route: ADMINISTRATOR_DEFINITION_TEAM,
            title: 'Teams',
        },
        {
            page: 'detail',
            title: 'new',
        },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_DEFINITION_TEAM_NEW}
            >
                <div>
                    <PageName name={'Create Team'} />
                </div>
            </ComponentTooltip>
            <Form
                validate={validate}
                onSubmit={createTeam}
                mutators={{
                    ...arrayMutators,
                }}
                keepDirtyOnReinitialize
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <ToolbarForm
                            localization={localization}
                            isLoading={isLoading}
                        />
                        <Body localization={localization} form={form} />
                    </form>
                )}
            />
        </>
    )
}

export default TeamCreate
