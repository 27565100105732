import { useMemo } from 'react'
import { useDeviceModelSelectionQuery } from '../query'

/**
 * Query device models from the database, map it as name: name ?? description, value: id
 * @return {Object} return mapped deviceModelData in an object
 */
const useDeviceModel = () => {
    const { data: deviceModels } = useDeviceModelSelectionQuery({
        menuOption: true,
    })

    const deviceModelData = useMemo(() => {
        if (deviceModels) {
            const d = deviceModels.map((dm) => ({
                name: dm.name ?? dm.description,
                value: dm.id,
            }))
            return [{ name: 'All', value: 0 }, ...d]
        } else {
            return []
        }
    }, [deviceModels])

    return { deviceModelData }
}

export default useDeviceModel
