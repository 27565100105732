import {
    ADMINISTRATOR,
    ADMINISTRATOR_DEFINITION,
    ADMINISTRATOR_DEFINITION_WORKBENCH,
    ADMINISTRATOR_DEFINITION_WORKBENCH_NEW,
} from '~/constants/Routes'
import { Form } from 'react-final-form'
import BreadCrumb from '~/components/BreadCrumb'
import { useTranslation } from 'react-i18next'
import PageName from '../../components/PageName'
import ComponentTooltip from '~/components/ComponentTooltip'
import FormToolbar from '~/components/FinalForm/FormToolbar'
import CancelButton from '~/components/CancelButton'
import { LoadingButton } from '~/components/Button'
import useActions from './hooks/useActions'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import useWorkbenchFormData from './hooks/useWorkbenchFormData'
import WorkbenchForm from './WorkbenchForm'
import useFormValidate from './hooks/useFormValidate'

const WorkbenchCreate = () => {
    const { localization } = useWorkbenchFormData()
    const { t } = useTranslation()
    const validate = useFormValidate()
    const { createHandler, isLoading } = useActions()

    const BreadComponents = [
        { route: ADMINISTRATOR, title: t(`breadCrumb.Administrator`) },
        {
            route: ADMINISTRATOR_DEFINITION,
            title: 'Definitions',
        },
        {
            route: ADMINISTRATOR_DEFINITION_WORKBENCH,
            title: 'workbenches',
        },
        {
            page: 'detail',
            title: 'new',
        },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_DEFINITION_WORKBENCH_NEW}
            >
                <div>
                    <PageName name={'Create Workbench'} />
                </div>
            </ComponentTooltip>
            <Form
                validate={validate}
                onSubmit={createHandler}
                render={({ handleSubmit, values, dirty }) => (
                    <form onSubmit={handleSubmit}>
                        <FormToolbar>
                            <CancelButton />
                            <LoadingButton
                                disabled={!dirty}
                                label={t(`${localization}.save`)}
                                type={`submit`}
                                loading={isLoading}
                            />
                        </FormToolbar>
                        <FormBodyWrapper>
                            <WorkbenchForm />
                        </FormBodyWrapper>
                    </form>
                )}
            />
        </>
    )
}
export default WorkbenchCreate
