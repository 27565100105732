import { Box, Divider, Typography } from '@mui/material'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Loading from '~/components/Loading'
import SectionTitle from './components/SectionTitle'
import { LOCALIZATION } from './constants'
import { useDeviceCheckHidQuery, useDeviceCheckPowerQuery } from './query'
import { isNumber } from '~/utils/helpers'

const DeviceHIDDisplay = ({ data = {}, type }) => {
    const findFalseValues = useCallback((obj, path = '') => {
        const falseValues = []

        Object.entries(obj).forEach(([key, value]) => {
            const newPath = path ? `${path}.${key}` : key

            if (value && typeof value === 'object') {
                falseValues.push(...findFalseValues(value, newPath))
            } else if (value === false) {
                falseValues.push({
                    path: newPath,
                    value: false,
                })
            } else if (isNumber(value)) {
                falseValues.push({
                    path: newPath,
                    value,
                })
            }
        })

        return falseValues
    }, [])

    const getResults = useMemo(() => {
        const results = []
        const mod = [data]
        mod.forEach((item) => {
            let values
            const [key, value] = item
            const allFalseValues = findFalseValues(value)
            const hasNumber = allFalseValues.some((item) =>
                isNumber(item.value),
            )

            if (hasNumber) {
                values = allFalseValues
                    .map((item) => `\n• ${item.path}: ${item.value}`)
                    .join('\n')
            } else {
                values = _.isEmpty(allFalseValues)
                    ? 'OK'
                    : `ERROR\n${allFalseValues
                          .map((item) => '• ' + item.path)
                          .join('\n')}`
            }

            results.push({
                [key]: {
                    key,
                    values,
                },
            })
        })
        return results
    }, [data, findFalseValues])

    return (
        <>
            {getResults &&
                getResults.map((item) => {
                    return (
                        <Typography key={`hid-${item}`}>
                            {'    - '}
                            {`${Object.keys(item)[0]}: `}
                            <span
                                style={{
                                    color: Object.values(
                                        item,
                                    )[0].values.startsWith('ERROR')
                                        ? 'red'
                                        : 'green',
                                    whiteSpace: 'pre-wrap',
                                    position: 'relative',
                                    left: '3%',
                                }}
                            >
                                {`${Object.values(item)[0].values}`}
                            </span>
                        </Typography>
                    )
                })}
        </>
    )
}
DeviceHIDDisplay.propTypes = {
    data: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
}

const DeviceChecks = ({ deviceSerial }) => {
    const { t } = useTranslation()
    const {
        data: dataPower,
        isLoading,
        error,
        isError,
    } = useDeviceCheckPowerQuery({
        serial: deviceSerial,
    })

    const {
        data: dataHid,
        error: hidError,
        isError: hidIsError,
    } = useDeviceCheckHidQuery({
        serial: deviceSerial,
    })

    if (isLoading) {
        return <Loading />
    }

    if (isError || hidIsError) {
        return (
            <>
                {isError && (
                    <Typography sx={{ color: 'red' }}>
                        {error.response.data.message}
                    </Typography>
                )}

                {hidIsError && (
                    <Typography sx={{ color: 'red' }}>
                        {hidError.response.data.message}
                    </Typography>
                )}
            </>
        )
    }

    return (
        <>
            <Typography variant='h6'>
                {t(LOCALIZATION + `bios_info`)}:
            </Typography>
            <Typography>
                • {t(LOCALIZATION + `bios_version`)}: {dataPower?.bios_version}
            </Typography>
            <Typography>
                {' '}
                • {t(LOCALIZATION + `bios_vendor`)}: {dataPower?.bios_vendor}
            </Typography>
            <Typography>
                {' '}
                • {t(LOCALIZATION + `bios_date`)}: {dataPower?.bios_date}
            </Typography>
            <Typography>
                {' '}
                • {t(LOCALIZATION + `bios_revision`)}:{' '}
                {dataPower?.bios_revision}
            </Typography>

            <Divider></Divider>
            <Typography variant='h6'>
                {t(LOCALIZATION + `battery_state`)}:
            </Typography>
            {dataPower?.latest_checks_power ? (
                <>
                    <Typography>
                        • {t(LOCALIZATION + `battery_status`)}:
                        {dataPower?.latest_checks_power.status}
                    </Typography>
                    <Typography>
                        • {t(LOCALIZATION + `battery_capacity`)}:
                        {dataPower?.latest_checks_power.capacity}
                    </Typography>
                    <Typography>
                        • {t(LOCALIZATION + `battery_capacity_level`)}:
                        {dataPower?.latest_checks_power.capacity_level}
                    </Typography>{' '}
                </>
            ) : (
                <Typography>
                    {t(LOCALIZATION + `power_check_not_found`)}
                </Typography>
            )}

            <Divider></Divider>
            <Typography variant='h6'>
                {t(LOCALIZATION + `HID_result`)}:
            </Typography>
            {dataHid?.tests ? (
                <>
                    {Object.entries(dataHid?.tests ?? {}).map((testResult) => (
                        <DeviceHIDDisplay
                            key={`hid-test-${testResult}`}
                            data={testResult}
                            type={testResult}
                        />
                    ))}
                </>
            ) : (
                <Typography>
                    {' '}
                    {t(LOCALIZATION + `HID_check_not_found`)}
                </Typography>
            )}
        </>
    )
}
DeviceChecks.propTypes = {
    deviceSerial: PropTypes.any,
}

const SoftwareDiagnosticsSection = ({ deviceSerial }) => {
    const { t } = useTranslation()
    return (
        <>
            <SectionTitle title={t(LOCALIZATION + `software_diagnostics`)} />
            <Box sx={{ padding: 2 }}>
                <DeviceChecks deviceSerial={deviceSerial} />
            </Box>
        </>
    )
}

SoftwareDiagnosticsSection.propTypes = {
    deviceSerial: PropTypes.any,
}

export default SoftwareDiagnosticsSection
