import TableLoading from '~/components/Table/TableLoading'
import DataNotFound from '~/components/DataNotFound'
import { TableCell, TableRow, TableBody as MuiTableBody } from '@mui/material'
import PropTypes from 'prop-types'

const CauseTableBody = ({ isLoading, items }) => {
    if (isLoading) {
        return <TableLoading colSpan={3} />
    }

    return (
        <MuiTableBody>
            {items.length > 0 ? (
                items.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                            <TableCell align='left'>{row.name}</TableCell>
                            <TableCell align='left'>
                                {row.part_codes.reduce((a, b) => {
                                    return (a += b.code + ', ')
                                }, '')}
                            </TableCell>
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={3} />
            )}
        </MuiTableBody>
    )
}

CauseTableBody.propTypes = {
    isLoading: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
}

export default CauseTableBody
