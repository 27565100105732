import styled from '@emotion/styled'
import { TableCell, tableCellClasses } from '@mui/material'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#d8d8d8',
        border: '0.1px solid grey',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

export default StyledTableCell
