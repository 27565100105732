import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { object, string } from 'yup'
import { ADMINISTRATOR_DEFINITION_CAUSE_EDIT } from '~/constants/Routes'
import useFormValidationCallback from '~/hooks/useFormValidationCallback'
import { useSnackbar } from '~/hooks/useSnackbar'
import { setMessageCause } from '~/store/cause/slice'
import CauseForm from '../CauseForm'
import { useCreateCauseMutation } from '../mutation'
import { parseApiErrorMessage } from '~/utils/helpers'

const CreateCauseForm = ({ pagePath }) => {
    const { showSnackbar, snackbar } = useSnackbar()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { mutate: createCause, isLoading } = useCreateCauseMutation()

    const submit = useCallback(
        (payload) =>
            createCause(payload, {
                onSuccess: (data) => {
                    navigate(
                        ADMINISTRATOR_DEFINITION_CAUSE_EDIT.replace(
                            ':id',
                            data.data.id,
                        ),
                    )
                    dispatch(setMessageCause(data))
                },
                onError: (e) => {
                    showSnackbar(parseApiErrorMessage(e), 'error', 8000, {
                        whiteSpace: 'pre',
                    })
                },
            }),
        [createCause, dispatch, navigate, showSnackbar],
    )
    const schema = object().shape({
        tid: string().required(),
    })

    const validate = useFormValidationCallback(schema)

    const onSubmit = useCallback(
        (values) => {
            const params = {
                tid: values.tid,
                name: values.name,
                notes: values.notes,
                for_all_models: values.for_all_models,
            }

            if (
                !values.for_all_models &&
                typeof values.model_ids === 'undefined'
            ) {
                showSnackbar(t('pages.causes.validateForAllModels'), 'error')
                return
            }

            if (
                typeof values.model_ids !== 'undefined' &&
                !values.model_ids.includes(0)
            ) {
                params.model_ids = values.model_ids
            }

            submit(params)
        },
        [showSnackbar, submit, t],
    )

    return (
        <>
            {snackbar}
            <CauseForm
                validate={validate}
                onSubmit={onSubmit}
                isLoading={isLoading}
                name='Create'
                pagePath={pagePath}
            />
        </>
    )
}

CreateCauseForm.propTypes = { pagePath: PropTypes.string }

export default CreateCauseForm
