import { Grid, Switch, Typography } from '@mui/material'
import {
    ADMINISTRATOR_DEFINITION_REGIONS_DETAIL,
    ADMINISTRATOR_USER_DETAIL,
} from '~/constants/Routes'
import { Fragment, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getValue } from '~/utils/helpers'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from '~/hooks/useSnackbar'
import {
    useUpdateSeparateTriageMutation,
    useWmsEnableMutation,
} from '../mutation'

const ServiceCenterSession = ({ data }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const id = data?.id
    const { showSnackbar, snackbar } = useSnackbar()
    const [checked, setChecked] = useState(data?.wms_enabled)
    const [separateTriageChecked, setSeparateTriageChecked] = useState(
        data?.separate_triage,
    )

    const addressServiceCenter = useMemo(() => {
        if (data?.address) {
            return `${data?.address?.name} - ${data?.address?.zipcode} (${data?.address?.street_1}, ${data?.address?.city}, ${data?.address?.zipcode}, ${data?.address?.iso_country?.name})`
        }
    }, [data?.address])
    const dataList = [
        { title: 'Id', value: data?.id },
        { title: 'Name', value: data?.name },
        {
            title: 'Region',
            value: data?.region?.name,
            clickable: true,
            navigateLink: ADMINISTRATOR_DEFINITION_REGIONS_DETAIL.replace(
                ':id',
                data?.region?.id,
            ),
        },
        {
            title: 'Contact person',
            value: data?.contact_person?.name,
            clickable: true,
            navigateLink: ADMINISTRATOR_USER_DETAIL.replace(
                ':id',
                data?.contact_person?.id,
            ),
        },
        {
            title: 'Sent dispatch emails to contact person',
            value: data?.dell_td_email_to_contact,
            type: 'boolean',
        },
        { title: 'Address', value: addressServiceCenter },
        { title: 'Phone number', value: data?.phone_number },
        { title: 'Dell branch', value: data?.branch?.name },
        {
            title: 'Manual dispatch branch',
            value: data?.manual_dispatch_branch?.name,
        },
        { title: 'Description', value: data?.description },
        { title: 'Easy4U ID', value: data?.service_center_tid },
        { title: 'Created at', value: data?.created_at, type: 'date' },
        { title: 'Updated at', value: data?.updated_at, type: 'date' },
    ]

    const localization = 'pages.administrator.definition.serviceCenter.detail'
    const { mutate: wmsEnableMutate } = useWmsEnableMutation({
        id,
        showSnackbar,
    })

    const { mutate: separateTriageMutate } = useUpdateSeparateTriageMutation({
        id,
    })

    const handleChange = (e) => {
        setChecked(e.target.checked)
        wmsEnableMutate({ wmsEnable: e.target.checked })
    }

    const handleChangeSeparateTriage = (e) => {
        setSeparateTriageChecked(e.target.checked)
        separateTriageMutate({ separateTriage: e.target.checked })
    }

    return (
        <>
            {snackbar}
            <Grid container spacing={3}>
                {dataList.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            <Grid item xs={4}>
                                {item.title}
                            </Grid>
                            <Grid item xs={8}>
                                {getValue(navigate, item)}
                            </Grid>
                        </Fragment>
                    )
                })}

                <Fragment key={1}>
                    <Grid item xs={4}>
                        <Typography sx={{ marginTop: '6px' }}>
                            {t(localization + `.warehouseSetting`)}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Switch
                            label={1}
                            checked={checked}
                            onChange={(event, isInputChecked) =>
                                handleChange(event)
                            }
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Grid>
                </Fragment>

                <Fragment key={1}>
                    <Grid item xs={4}>
                        <Typography sx={{ marginTop: '6px' }}>
                            {t(localization + `.separateTriage`)}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Switch
                            label={1}
                            checked={separateTriageChecked}
                            onChange={(event) =>
                                handleChangeSeparateTriage(event)
                            }
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Grid>
                </Fragment>
            </Grid>
        </>
    )
}

ServiceCenterSession.propTypes = { data: PropTypes.object }

export default ServiceCenterSession
