import { Card, CardContent, Grid } from '@mui/material'
import {
    ADMINISTRATOR_DEFINITION_SERVICECENTER_DETAIL,
    ADMINISTRATOR_USER_DETAIL,
} from '~/constants/Routes'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectTeam } from '~/store/team/selector'
import { getValue } from '~/utils/helpers'
import React from 'react'

const TeamInformation = () => {
    const navigate = useNavigate()
    const team = useSelector(selectTeam)
    const dataList = [
        { title: 'Id', value: team?.id },
        { title: 'Name', value: team?.name },
        {
            title: 'Contact person',
            value: team?.contact_person?.name
                ? `${team?.contact_person?.name} (${team?.contact_person?.email})`
                : null,
            clickable: true,
            navigateLink: ADMINISTRATOR_USER_DETAIL.replace(
                ':id',
                team?.contact_person?.id,
            ),
        },
        {
            title: 'Service center',
            value: team?.service_center?.name,
            clickable: true,
            navigateLink: ADMINISTRATOR_DEFINITION_SERVICECENTER_DETAIL.replace(
                ':id',
                team?.service_center?.id,
            ),
        },
        { title: 'Created at', value: team?.created_at, type: 'date' },
        { title: 'Updated at', value: team?.updated_at, type: 'date' },
    ]

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    {dataList.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Grid item xs={4}>
                                    {item.title}
                                </Grid>
                                <Grid item xs={8}>
                                    {getValue(navigate, item)}
                                </Grid>
                            </React.Fragment>
                        )
                    })}
                </Grid>
            </CardContent>
        </Card>
    )
}

export default TeamInformation
