import styled from '@emotion/styled'
import { Grid, Box, Divider, Modal, Stack, Typography } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useCallback, useRef, useState } from 'react'
import { Field } from 'react-final-form'
import SubmitButton from '~/components/SubmitButton'
import { useTranslation } from 'react-i18next'
import FormInput from '~/components/FormInput/FormInput'
import ComponentTooltip from '~/components/ComponentTooltip'
import { ISSUE_RACKSCAN_SCAN_NEW } from '~/constants/Routes'
import AsterisksRequiredField from '~/components/FormInput/AsterisksRequiredField'
import PropTypes from 'prop-types'

const StyledModal = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})
const localization = 'pages.issues.rackScans.scan.create'

const regexpLocation = /^[a-zA-Z0-9][a-zA-Z0-9-\s]+$/
const regexpTicket = /^[0-9]+$/
const pagePath = ISSUE_RACKSCAN_SCAN_NEW

const AddScanLinePopup = ({
    openScan,
    setOpenScan,
    form,
    handleSubmit,
    isLoading,
    values,
}) => {
    const { t } = useTranslation()
    const inputRef = useRef([])
    const [lineList, setLineList] = useState([0])

    const handleKeyUp = (event, index, form, values) => {
        if (event.keyCode === 13) {
            const [name, idx] = index.split('_')
            if (name === 'location') {
                if (!inputRef.current[`ticket_${idx}`].value) {
                    inputRef.current[`ticket_${idx}`].focus()
                }
            }
            if (name === 'ticket') {
                const valueTicket = event.target.value

                if (!valueTicket) {
                    return
                }

                const next = parseInt(idx) + 1

                if (valueTicket) {
                    const list = [
                        ...lineList,
                        lineList[lineList.length - 1] + 1,
                    ]

                    setLineList(list)

                    if (
                        !regexpTicket.test(valueTicket) &&
                        regexpLocation.test(valueTicket)
                    ) {
                        setTimeout(() => {
                            form.change('id_' + idx + '.ticket', null)
                            form.change('id_' + next + '.location', valueTicket)
                            inputRef.current[`ticket_${next}`].focus()
                        }, 100)
                    }
                }
            }
        }
    }

    const handleLineRemove = useCallback(
        (form, item) => {
            const list = lineList.filter((x) => x !== item)
            setLineList(list)
            form.change('id_' + item, null)
        },
        [lineList],
    )

    const getValidatorLocation = (value, fieldName) => {
        if (!value) {
            return `${fieldName} is a required field`
        }

        if (!regexpLocation.test(value)) {
            return 'location is always an alpha numeric string'
        }

        return undefined
    }

    const getValidatorTicket = useCallback(
        (value, values, index, fieldName) => {
            if (!value) {
                return `${fieldName} is a required field`
            }

            if (!regexpTicket.test(value)) {
                return 'ticket can only contain numeric values'
            }

            return undefined
        },
        [],
    )

    return (
        <StyledModal
            open={openScan}
            onClose={() => setOpenScan(false)}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box
                bgcolor={'background.default'}
                color={'text.primary'}
                p={3}
                borderRadius={5}
                maxWidth={500}
            >
                <ComponentTooltip
                    componentId={`scan_create_popup_title`}
                    pagePath={ISSUE_RACKSCAN_SCAN_NEW}
                    align='center'
                >
                    <Typography variant='h6' color='gray' textAlign='center'>
                        Rack scan
                    </Typography>
                </ComponentTooltip>

                <Divider />
                <Grid
                    container
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Grid container>
                            {['location', 'ticket'].map((item, index) => (
                                <Grid item xs={6} key={index}>
                                    <ComponentTooltip
                                        componentId={`scan_create_popup_${item}`}
                                        pagePath={pagePath}
                                    >
                                        <Typography>
                                            {t(`${localization}.` + item)}
                                            <AsterisksRequiredField />
                                        </Typography>
                                    </ComponentTooltip>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            maxHeight: 500,
                            overflow: 'auto',
                        }}
                    >
                        {lineList.map((index) => {
                            return (
                                <Grid container spacing={2} key={index}>
                                    <Grid item xs={11}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Field
                                                    inputRef={(element) => {
                                                        inputRef.current[
                                                            `location_${index}`
                                                        ] = element
                                                    }}
                                                    name={`id_${index}[location]`}
                                                    onKeyUp={(e) =>
                                                        handleKeyUp(
                                                            e,
                                                            `location_${index}`,
                                                            form,
                                                            values,
                                                        )
                                                    }
                                                    variant='standard'
                                                    autoFocus
                                                    component={FormInput}
                                                    validate={(value) =>
                                                        getValidatorLocation(
                                                            value,
                                                            'location',
                                                        )
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field
                                                    inputRef={(element) => {
                                                        inputRef.current[
                                                            `ticket_${index}`
                                                        ] = element
                                                    }}
                                                    name={`id_${index}[ticket]`}
                                                    variant='standard'
                                                    onKeyUp={(e) =>
                                                        handleKeyUp(
                                                            e,
                                                            `ticket_${index}`,
                                                            form,
                                                            values,
                                                        )
                                                    }
                                                    component={FormInput}
                                                    validate={(value, values) =>
                                                        getValidatorTicket(
                                                            value,
                                                            values,
                                                            index,
                                                            'ticket',
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {lineList.length !== 1 && (
                                        <Grid
                                            item
                                            xs={1}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Box>
                                                <DeleteOutlineIcon
                                                    onClick={() => {
                                                        handleLineRemove(
                                                            form,
                                                            index,
                                                        )
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            )
                        })}
                    </Grid>

                    <Grid item xs={12}>
                        <Stack direction='row-reverse' spacing={2}>
                            <SubmitButton
                                isLoading={isLoading}
                                handleSubmit={handleSubmit}
                                name={`${t('button.closeAndSave')}`}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </StyledModal>
    )
}

AddScanLinePopup.propTypes = {
    openScan: PropTypes.bool,
    setOpenScan: PropTypes.func,
    form: PropTypes.any,
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    values: PropTypes.any,
}

export default AddScanLinePopup
