import PropTypes from 'prop-types'
import {
    Dialog as MuiDialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    useTheme,
} from '@mui/material'

const Dialog = ({
    open = false,
    onClose,
    title = '',
    content = '',
    actions,
}) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    return (
        <MuiDialog fullScreen={fullScreen} {...{ open, onClose }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{content}</DialogContentText>
            </DialogContent>
            <DialogActions>{actions}</DialogActions>
        </MuiDialog>
    )
}
Dialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.string,
    content: PropTypes.string,
    actions: PropTypes.string,
}

export default Dialog
