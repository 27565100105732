export const NameTagRuleColumnConfig = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
    },
    {
        id: 'device_name',
        numeric: false,
        disablePadding: false,
        label: 'device_name',
        orderable: true,
    },
    {
        id: 'component_id',
        numeric: false,
        disablePadding: false,
        label: 'component',
        orderable: true,
    },
    {
        id: 'device_tag_id',
        numeric: false,
        disablePadding: false,
        label: 'device_tag',
        orderable: true,
    },
]
