import dispatchApi from '~/api/dispatchApi'
import { useMutation } from 'react-query'
import { base64ImageToBlob } from '~/utils/helpers'

export const useCreateDispatchMutation = () => {
    return useMutation(
        ['dispatch_create'],
        async (payload) => {
            let p = { ...payload }
            if (Number.parseInt(payload.request_return_to_depot)) {
                p = { ...payload, parts: [] }
            }
            const { data } = await dispatchApi.createDispatch(p)
            return data
        },
        {
            retry: false,
        },
    )
}

export const useUploadPictureDispatch = () => {
    return useMutation(
        ['dispatch_pictures'],
        async ({ payload, id }) => {
            const formData = new FormData()
            let i = 0
            for (const item of payload) {
                let imgBlob = item.image
                if (!item?.notConvert) {
                    imgBlob = await base64ImageToBlob(item.image)
                }
                formData.append(`pictures[${i}]`, imgBlob)
                formData.append(`dispatch_enabled[${i}]`, item.checked)
                ++i
            }

            const { data } = await dispatchApi.uploadDispatchPicture(
                formData,
                id,
            )
            return data
        },
        {
            retry: false,
        },
    )
}
