import { useNavigate } from 'react-router-dom'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ADMINISTRATOR_TOOLTIP_CREATE } from '~/constants/Routes'
import { Stack, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { LoadingButton } from '~/components/Button'
import useComponentTooltip from './useComponentTooltip'

export const useTooltipTitle = (componentId, pagePath) => {
    const { componentTooltipData } = useComponentTooltip()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const getRenderTooltipData = useCallback(
        (url) => {
            if (!url) return []

            const filteredTooltipsByComponent = componentTooltipData?.filter(
                (x) => x.component === componentId,
            )

            const filteredTooltipsByUrl = filteredTooltipsByComponent?.filter(
                (x) => x.page_url === url,
            )

            if (filteredTooltipsByUrl?.length > 0) return filteredTooltipsByUrl

            const EndPositionNewUrl = url.lastIndexOf('/')
            const newUrl = url.substring(0, EndPositionNewUrl)

            return getRenderTooltipData(newUrl)
        },
        [componentId, componentTooltipData],
    )

    const renderTooltip = useMemo(() => {
        return getRenderTooltipData(pagePath)
    }, [getRenderTooltipData, pagePath])

    const handleCreateTooltip = useCallback(() => {
        navigate(ADMINISTRATOR_TOOLTIP_CREATE, {
            state: {
                pageUrl: pagePath,
                componentId,
            },
        })
    }, [componentId, navigate, pagePath])

    const titleTooltip = useMemo(() => {
        if (renderTooltip.length > 0)
            return (
                <Stack direction='row' spacing={1}>
                    <Typography>{renderTooltip[0].tooltip}</Typography>
                    {pagePath !== renderTooltip[0].page_url && (
                        <EditIcon
                            fontSize='small'
                            sx={{ cursor: 'pointer' }}
                            onClick={() => handleCreateTooltip()}
                        />
                    )}
                </Stack>
            )
        return (
            <>
                <LoadingButton
                    disabled={false}
                    label={t(`general.addTooltip`)}
                    onClick={() => handleCreateTooltip()}
                />
            </>
        )
    }, [renderTooltip, pagePath, t, handleCreateTooltip])

    return {
        titleTooltip,
    }
}

export default useTooltipTitle
