import axiosClient from '~/api/base'

class TooltipApi {
    getTooltipDataByUrl = (params) => {
        return axiosClient.get('api/tooltips/tooltip_data', {
            params,
        })
    }

    getTooltips = (params) => {
        return axiosClient.get('api/tooltips', { params })
    }

    getTooltip = ({ id }) => {
        return axiosClient.get(`api/tooltips/${id}`)
    }

    saveTooltip = ({ id, payload }) => {
        return axiosClient.put(`api/tooltips/${id}`, payload)
    }

    deleteTooltip = ({ id }) => {
        return axiosClient.delete(`api/tooltips/${id}`)
    }

    createTooltip = ({ payload }) => {
        return axiosClient.post('api/tooltips', payload)
    }
}

export default new TooltipApi()
