import { Divider, Paper } from '@mui/material'
import PaginationTable from '~/components/PaginationTable'
import TableContainer from '~/components/Table/TableContainer'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import { useModelCausesQuery } from '../../query'
import CauseTableBody from './CauseTableBody'
import CauseTableToolbar from './CauseTableToolbar'
import PropTypes from 'prop-types'

const tableCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        orderable: true,
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'description',
        orderable: true,
    },
]

const CauseTable = ({ id }) => {
    const {
        data,
        isLoading,
        search,
        searchHandler,
        sortHandler,
        changePageHandler,
        order,
        orderBy,
        page,
        results: items,
    } = useTable({
        query: useModelCausesQuery,
        queryAttributes: {
            id,
        },
    })

    const LOCALIZATION = ''

    return (
        <Paper sx={{ width: '100%', mb: 2 }}>
            <CauseTableToolbar
                filterSearch={search}
                onFilterSearch={searchHandler}
            />
            <Divider />
            <TableContainer>
                <TableHeader
                    headCells={tableCells}
                    localization={LOCALIZATION}
                    onRequestSort={sortHandler}
                    order={order}
                    orderBy={orderBy}
                />
                <CauseTableBody isLoading={isLoading} items={items} />
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}

CauseTable.propTypes = { id: PropTypes.string }

export default CauseTable
