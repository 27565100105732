import { QuestionMark } from '@mui/icons-material'
import {
    IconButton,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '~/components/Button'
import StyledModal from '~/components/StyledModal'
import { useSnackbar } from '~/hooks/useSnackbar'
import { textValidateRegex } from '~/utils/helpers'
import { useAddOnDetailQuery } from '../../Addons/query'
import { useUpdateProjectAddonMutation } from '../mutation'

const ProjectEditAddonModel = ({
    row,
    setOpenUpdateAddon,
    openUpdateAddon,
}) => {
    const { t } = useTranslation()
    const [inputFields, setInputFields] = useState([])
    const [formData, setFormData] = useState({})
    const { showSnackbar, snackbar } = useSnackbar()

    const { data: addonData } = useAddOnDetailQuery({
        id: row?.addon_id,
        showSnackbar,
    })

    // GENERATE DYNAMIC INPUT FIELDS BASED ON SELECTED's PARAMS
    useEffect(() => {
        if (addonData && addonData.data && addonData.data.params) {
            const params = Object.keys(addonData.data.params)
            const generateInputs = params.map((param, index) => ({
                keyData: param,
                label: addonData.data.params[param].display_name,
                required: addonData.data.params[param].required || false,
                helpText: addonData.data.params[param].help,
                defaultValue: row.params[param],
                regex: addonData.data.params[param].regex,
                dataType: addonData.data.params[param].datatype,
                value: row.params[param], // Initialize value for each field
                error: false,
            }))

            setInputFields(generateInputs)
            setFormData(generateInputs)
        } else {
            setInputFields([])
        }
    }, [addonData, row])

    const handleInputChange = (event, index) => {
        const updatedFields = [...inputFields]
        updatedFields[index].value = event.target.value
        updatedFields[index].error =
            updatedFields[index].required === 'true' &&
            !textValidateRegex(
                updatedFields[index].regex,
                updatedFields[index].value,
            )
        setInputFields(updatedFields)
        setFormData(updatedFields)
    }

    const handleClose = () => {
        setOpenUpdateAddon(false)
    }

    const { mutate: editProjectAddonMutate } = useUpdateProjectAddonMutation({
        id: row?.production_project_id,
        showSnackbar,
    })
    const handleConfirm = () => {
        let isValid = true // Flag to indicate form validity

        isValid = inputFields.every((field) => !field.error)

        if (!isValid) {
            return // Prevent form submission if validation fails
        }

        // Create the final object based on formData
        const finalParams = Object.fromEntries(
            Object.entries(formData).map(([key, item]) => [
                item.keyData,
                item.value ?? item.defaultValue,
            ]),
        )

        // Handle successful form submission
        editProjectAddonMutate({
            addon_id: row?.addon_id,
            params: finalParams,
            project_id: row?.production_project_id,
        })
    }

    const renderInputFields = () => {
        return inputFields.map((field, index) => (
            <>
                <Tooltip
                    title={field.helpText}
                    style={{
                        width: '100%',
                        justifyContent: 'left',
                        color: '#000',
                    }}
                    placement='bottom-start'
                >
                    <IconButton>
                        <Typography>{field.label}</Typography>
                        <QuestionMark style={{ fontSize: '15px' }} />
                    </IconButton>
                </Tooltip>
                <TextField
                    key={index}
                    name={field.keyData}
                    required={field.required}
                    defaultValue={field.defaultValue}
                    onChange={(event) => handleInputChange(event, index)}
                    style={{ width: '100%' }}
                    type={field.dataType}
                    error={field.error}
                />
            </>
        ))
    }

    return (
        <>
            {snackbar}

            <StyledModal
                open={openUpdateAddon}
                handleClose={() => handleClose()}
                stackProps={{
                    sx: {
                        minWidth: 500,
                        maxHeight: '95vh',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '10px',
                            backgroundColor: 'rgba(0, 0, 0, .5)',
                        },
                        '&::-webkit-scrollbar': {
                            width: '10px',
                        },
                    },
                }}
            >
                <Typography variant='h5'>
                    {' '}
                    {t('pages.production.projects.editAddon')}
                </Typography>

                {inputFields.length > 0 && renderInputFields()}

                <Stack direction='row' gap={2} style={{ marginTop: '10px' }}>
                    <LoadingButton
                        label={t('button.save')}
                        onClick={handleConfirm}
                    />
                    <LoadingButton
                        label={t('button.close')}
                        onClick={handleClose}
                    />
                </Stack>
            </StyledModal>
        </>
    )
}

ProjectEditAddonModel.propTypes = {
    row: PropTypes.object,
    openUpdateAddon: PropTypes.bool,
    setOpenUpdateAddon: PropTypes.func,
}

export default ProjectEditAddonModel
