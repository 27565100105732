import {
    Box,
    Grid,
    Table as MuiTable,
    TableBody as MuiTableBody,
    TableCell as MuiTableCell,
    Paper,
    Stack,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import DataNotFound from '~/components/DataNotFound'
import { TicketHeadCells } from '~/components/HeaderTable/TicketHeadCells'
import TableCell from '~/components/TableCell'
import StyledModal from '~/components/StyledModal'
import ResultStatus from './ResultStatus'
import { ImportLenovoHeadCells } from '~/components/HeaderTable/ImportLenovoHeadCells'
import StyledTableCell from '../Diagnostic/components/StyledTableCell'
import { useStyles } from 'tss-react'
import StyledTableRow from '../Diagnostic/components/StyledTableRow'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '~/components/Button'

const TableBody = ({ data }) => {
    const { classes } = useStyles()

    return (
        <MuiTableBody>
            {data.length > 0 ? (
                data.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                            <MuiTableCell
                                align='center'
                                className={classes.tableCell}
                            >
                                {row?.id}
                            </MuiTableCell>

                            <MuiTableCell
                                align='center'
                                className={classes.tableCell}
                            >
                                {row?.serial}
                            </MuiTableCell>

                            <TableCell
                                align='center'
                                className={classes.tableCell}
                                cellValue={<ResultStatus row={row} />}
                                type='component'
                            />
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={TicketHeadCells.length} />
            )}
        </MuiTableBody>
    )
}

TableBody.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
}

const TableOverall = ({ total, failed }) => {
    const { classes } = useStyles()

    const headerTable = ['Total', 'Failed', 'Success']

    const success = total - failed

    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        {headerTable.map((item) => (
                            <StyledTableCell align='center' key={item}>
                                {item}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <MuiTableBody>
                    <StyledTableRow>
                        <MuiTableCell
                            align='center'
                            className={classes.tableCell}
                        >
                            {total ?? '0'}
                        </MuiTableCell>
                        <MuiTableCell
                            align='center'
                            className={classes.tableCell}
                        >
                            {failed ?? '0'}
                        </MuiTableCell>
                        <MuiTableCell
                            align='center'
                            className={classes.tableCell}
                        >
                            {success ?? '0'}
                        </MuiTableCell>
                    </StyledTableRow>
                </MuiTableBody>
            </Table>
        </TableContainer>
    )
}
TableOverall.propTypes = {
    total: PropTypes.number,
    failed: PropTypes.number,
}

const ProgressResultTable = ({ data, total, failed, open, onClose }) => {
    const { classes } = useStyles()
    const { t } = useTranslation()

    return (
        <>
            <StyledModal
                stackProps={{
                    sx: {
                        minWidth: 850,
                    },
                }}
                open={open}
                handleClose={onClose}
            >
                <Box
                    bgcolor={'background.default'}
                    color={'text.primary'}
                    p={3}
                    borderRadius={5}
                    minWidth={750}
                >
                    <Typography
                        sx={{ marginBottom: 1 }}
                        variant='h6'
                        color='gray'
                        textAlign='center'
                    >
                        {`Progress Results`}
                    </Typography>

                    <Paper>
                        <TableOverall total={total} failed={failed} />
                    </Paper>

                    {data && data.length > 0 && (
                        <>
                            <Typography
                                sx={{ marginBottom: 1, marginTop: 2 }}
                                variant='h6'
                                color='gray'
                                textAlign='center'
                            >
                                {`Failed Details`}
                            </Typography>

                            <Paper>
                                <TableContainer
                                    sx={{ minWidth: 750, maxHeight: 600 }}
                                    aria-labelledby='tableTitle'
                                    size='medium'
                                >
                                    <MuiTable>
                                        <TableHead
                                            className={classes.tableHead}
                                        >
                                            <TableRow>
                                                {ImportLenovoHeadCells.map(
                                                    (item) => (
                                                        <StyledTableCell
                                                            align='center'
                                                            key={item.label}
                                                        >
                                                            {t(
                                                                `pages.dispatches.dispatch.import.${item.label}`,
                                                            )}
                                                        </StyledTableCell>
                                                    ),
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody data={data} />
                                    </MuiTable>
                                </TableContainer>
                            </Paper>
                        </>
                    )}
                </Box>
                <Grid item xs={12}>
                    <Stack direction='row-reverse' spacing={2}>
                        <LoadingButton
                            onClick={onClose}
                            label={t('button.close')}
                        />
                    </Stack>
                </Grid>
            </StyledModal>
        </>
    )
}
ProgressResultTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool,
    onClose: PropTypes.func,
    total: PropTypes.number,
    failed: PropTypes.number,
}
export default ProgressResultTable
