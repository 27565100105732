import { useTranslation } from 'react-i18next'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT,
    ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_DETAIL,
} from '~/constants/Routes'
import { useParams } from 'react-router-dom'
import Loading from '~/components/Loading'
import { useMemo, useState } from 'react'
import { useUpdateMutation } from './mutation'
import { useGetDetailQuery } from './query'
import { Form } from 'react-final-form'
import Body from './Body'
import Toolbar from './Toolbar'
import { object } from 'yup'
import { multipleIp, phone, regularEmail } from '~/constants/yupSchemas'
import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import { useSnackbar } from '~/hooks/useSnackbar'
import useFormValidationCallback from '~/hooks/useFormValidationCallback'
import ComponentTooltip from '~/components/ComponentTooltip'

const ServiceAccountDetail = () => {
    const [tokenValue, setTokenValue] = useState(null)
    const { showSnackbar, snackbar } = useSnackbar()
    const localization = 'pages.administrator.serviceAccountDetail'
    const { mutate: updateServiceAccount } = useUpdateMutation({
        showSnackbar,
    })
    const urlParams = useParams()
    const { t } = useTranslation()
    const { data, isLoading } = useGetDetailQuery({
        id: urlParams.id,
        setTokenValue,
        tokenValue,
    })
    const queryResponse = useMemo(() => {
        return data?.data || []
    }, [data])
    const schema = object().shape({
        contact_email: regularEmail(t).nullable(),
        contact: phone(t).nullable(),
        whitelist: multipleIp(t).nullable(),
    })
    const validate = useFormValidationCallback(schema)

    if (isLoading) {
        return <Loading />
    }

    const breadCrumbConfig = [
        { title: `Administrator`, route: ADMINISTRATOR },
        {
            title: `ServiceAccounts`,
            route: ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT,
        },
        { title: queryResponse?.name, page: 'detail' },
    ]
    return (
        <>
            {snackbar}
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <ComponentTooltip
                componentId={`service_account_detail_title`}
                pagePath={ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_DETAIL}
            >
                <div>
                    <PageName
                        name={`Edit Service Account ${queryResponse?.name}`}
                    />
                </div>
            </ComponentTooltip>

            <Form
                validate={validate}
                onSubmit={updateServiceAccount}
                initialValues={{
                    ...queryResponse,
                    role: queryResponse.role?.id,
                }}
                render={({ handleSubmit, form, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Toolbar
                            form={form}
                            object={queryResponse}
                            localization={localization}
                            showSnackbar={showSnackbar}
                            setTokenValue={setTokenValue}
                        />
                        <Body
                            tokenValue={tokenValue}
                            setTokenValue={setTokenValue}
                            data={queryResponse}
                            localization={localization}
                            showSnackbar={showSnackbar}
                        />
                    </form>
                )}
            />
        </>
    )
}
export default ServiceAccountDetail
