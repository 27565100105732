import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { CardMedia } from '@mui/material'
import MemberPlaceHolder from '../../../../assets/teams/member_placeholder.png'

const MemberCard = ({ member }) => {
    const memberAvatar = useMemo(() => {
        return member?.media?.preview_url || MemberPlaceHolder
    }, [member?.media?.preview_url])

    return (
        <CardMedia
            component='img'
            height='160'
            image={memberAvatar}
            alt='Member'
        />
    )
}

MemberCard.propTypes = {
    member: PropTypes.object.isRequired,
}

export default MemberCard
