import { TableBody, TableCell, TableRow } from '@mui/material'
import _ from 'lodash'
import DataNotFound from '~/components/DataNotFound'
import TableLoading from '~/components/Table/TableLoading'
import { ComponentDetailHeadCells } from '../constants'
import PropTypes from 'prop-types'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const ComponentDetailBodyTable = ({ isLoading, componentDetail = [] }) => {
    if (isLoading) {
        return <TableLoading colSpan={ComponentDetailHeadCells.length} />
    }

    return (
        <TableBody>
            {_.isArray(componentDetail) && !_.isEmpty(componentDetail) ? (
                componentDetail.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.name}>
                            <TableCell component='th' scope='row'>
                                {row.name}
                            </TableCell>
                            <TableCell align='left'>{row.count}</TableCell>
                            <TableCell align='left'>
                                {row.item_description}
                            </TableCell>
                            <TableCell align='left'>
                                <DateTimeLocale
                                    datetime={row.oldest}
                                    formatHour={true}
                                />
                            </TableCell>
                            <TableCell align='left'>
                                <DateTimeLocale
                                    datetime={row.newest}
                                    formatHour={true}
                                />
                            </TableCell>
                            <TableCell align='left'>
                                {row.in_warranty}
                            </TableCell>
                            <TableCell align='left'>
                                {row.out_of_warranty}
                            </TableCell>
                            <TableCell align='left'>
                                {row.expire_in_year_warranty}
                            </TableCell>
                            <TableCell align='left'>{row.active}</TableCell>
                            <TableCell align='left'>{row.stock}</TableCell>
                            <TableCell align='left'>{row.other}</TableCell>
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={ComponentDetailHeadCells.length} />
            )}
        </TableBody>
    )
}
ComponentDetailBodyTable.propTypes = {
    isLoading: PropTypes.bool,
    componentDetail: PropTypes.arrayOf(PropTypes.object).isRequired,
}

ComponentDetailBodyTable.propTypes = {
    isLoading: PropTypes.bool,
    componentDetail: PropTypes.array,
}

export default ComponentDetailBodyTable
