import {
    Box,
    Button,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography,
} from '@mui/material'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingButton } from '~/components/Button'
import DeleteConfirmPopup from '~/components/DeleteConfirmPopup'
import PictureCapturePopup from '~/components/PictureCapturePopup'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { DASHBOARD, ISSUE_ISSUE } from '~/constants/Routes'
import TicketDetails from '~/pages/Diagnostic/TicketDetails'
import { useTicketDetailsQuery } from '~/pages/Diagnostic/query'
import QCForm from './Component/QCForm'
import QcConfirmationForm from './Component/QcConfirmationForm'
import QcPictures from './Component/QcPictures'
import UploadQcPicturePopup from './Component/UploadQcPicturePopup'
import { qcFormCriteria } from './Component/qcFormCriteria'
import {
    useDeleteQcPicturesMutation,
    useSaveIssueQcMutation,
    useUpdateOverruleAnswersForQcMutation,
    useUpdateQcCommentMutation,
    useUploadPicturesForQcMutation,
} from './mutation'
import { useIssueQcImagesQuery } from './query'
import {
    idElementPrint,
    parseApiErrorMessage,
    serviceBonPrintLink,
} from '~/utils/helpers'
import usePermissions from '~/hooks/usePermission'

const ISSUE_QC_SUCCESS = 'QC Success'
const ISSUE_QC_FAILED = 'QC Failed'
const ISSUE_ASSIGNED_TO_SUPERVISOR = 'Assigned to Supervisor'

const printQcConfirmForm = (issue) => {
    const customFunc = (printElement) => {
        const peDoc = printElement.contentWindow.document
        const barcodePe = peDoc.getElementById('barcode-ticket-id-container')
        const buttonToHide = peDoc.getElementById('change-to-bar-code')

        barcodePe.style.display = 'inline'
        buttonToHide.style.display = 'none'
    }

    const additionalCss = [
        '.MuiTableCell-root { padding-top: 1px; padding-bottom: 1px; font-size: 11px; }',
        '.MuiRadio-root { padding: 0; }',
        'h5.MuiTypography-root { font-size: 15px; box-shadow: none; border-bottom: 1px solid gray; }',
        '.MuiPaper-root { padding: 0px; box-shadow: none; }',
    ]

    idElementPrint('qc-confirm-form', customFunc, additionalCss, null, () => {
        if (issue.url?.length > 0 && `${issue.data?.ticket_id}`.length > 0) {
            const url = serviceBonPrintLink(issue.data.ticket_id, issue.url)
            window.parent.postMessage(`openNewTab|${url}`, '*')
        }
    })
}

const QCFormSection = ({
    issueData,
    qcData,
    isFetchedData,
    isConfirmForm = false,
    qcConfirmDataOverrule,
    qcConfirmComment,
    qcConfirmPictures,
    isConfirmOverrule = false,
}) => {
    const { issueId: id } = useParams()

    const { t } = useTranslation()

    const navigate = useNavigate()

    const { openSnackbar } = useDialogContext()

    const queryClient = useQueryClient()

    const [openPictureCapturePopup, setOpenPictureCapturePopup] =
        useState(false)
    const { permissions } = usePermissions()

    const [isUpdatingAnswers, setIsUpdatingAnswers] = useState(false)
    const [openUploadPicture, setOpenUploadPicture] = useState(false)
    const [disableSaveButton, setDisableSaveButton] = useState(true)
    const [isInitialized, setIsInitialized] = useState(true)
    const [comment, setComment] = useState('')
    const [selectedFiles, setSelectedFiles] = useState([])
    const [openConfirmForm, setOpenConfirmForm] = useState(false)

    const [deletePictureId, setDeletePictureId] = useState(false)
    const [isOverrule, setIsOverrule] = useState(false)
    const { data: ticketData } = useTicketDetailsQuery(id)
    const { data } = useIssueQcImagesQuery(id)
    const [qcFormOverruleData, setQCFormOverruleData] = useState(null)
    const [overrulePictureFiles, setOverrulePictureFiles] = useState(
        data?.data ?? [],
    )
    const [deletePictureOverrule, setDeletePictureOverrule] = useState([])

    const trans = 'pages.issues.qualityCheck.qcForm.'

    const isShowOverruleButton = permissions.issue.canOverruleQC

    useEffect(() => {
        if (isInitialized && issueData?.data.state !== 'QC_DONE') {
            queryClient.removeQueries('issue_qc_images')
            setIsInitialized(false)
        }
    }, [isInitialized, issueData, queryClient])

    const { mutate: uploadPicture, isLoading: isUploadingPicture } =
        useUploadPicturesForQcMutation()

    const { mutate: deleteQcPicture, isLoading } = useDeleteQcPicturesMutation()

    const { mutate: updateComment } = useUpdateQcCommentMutation()

    const { mutate: saveIssueQc, isLoading: isSavingIssueQc } =
        useSaveIssueQcMutation()

    const {
        mutateAsync: updateOverruleData,
        isLoading: isUploadingDataOverrule,
    } = useUpdateOverruleAnswersForQcMutation()

    const onSubmit = useCallback(() => {
        if (selectedFiles.length <= 0) {
            setSelectedFiles([])
            return
        }
        if (isOverrule) {
            const picturesOverruleList = selectedFiles.map((v) => {
                if (v?.original_url) {
                    return v.image
                }
                return {
                    src: v.image,
                    image: v.image,
                    id: v.image,
                    checked: v?.checked,
                }
            })
            setOverrulePictureFiles((prev) => [
                ...prev,
                ...picturesOverruleList,
            ])
            setSelectedFiles([])
            setOpenPictureCapturePopup(false)
        } else {
            const picturesList = selectedFiles.map((v) => v.image)
            uploadPicture(
                { issueId: id, pictures: picturesList },
                {
                    onSettled: () => {
                        setSelectedFiles([])
                        setOpenPictureCapturePopup(false)
                        queryClient.invalidateQueries('issue_qc_images')
                    },
                },
            )
        }
    }, [selectedFiles, isOverrule, uploadPicture, id, queryClient])

    const onClose = () => {
        setSelectedFiles([])
        setOpenPictureCapturePopup(false)
    }

    const deletePicture = (id) => {
        setDeletePictureId(id)
    }

    const handleClose = () => {
        setDeletePictureId(false)
    }

    const pictures = useMemo(() => data?.data ?? [], [data])
    const currentPictures = useMemo(() => {
        return (
            data?.data &&
            data.data.map((image) => {
                return {
                    src: image.preview_url,
                    id: image.id,
                    is_current: true,
                    checked: false,
                    diagnosticPhoto: image.diagnosticPhoto,
                    created_at: image.created_at || null,
                }
            })
        )
    }, [data])

    const currentOverrulePictures = useMemo(() => {
        return (
            overrulePictureFiles &&
            overrulePictureFiles.map((image) => {
                return {
                    src: image.preview_url || image.src,
                    id: image.id,
                    is_current: true,
                    checked: false,
                    diagnosticPhoto: image.diagnosticPhoto || false,
                    created_at: image.created_at || null,
                }
            })
        )
    }, [overrulePictureFiles])

    const handleDelete = () => {
        if (isOverrule) {
            setOverrulePictureFiles((prev) =>
                prev.filter((image) => image.id !== deletePictureId),
            )
            setDeletePictureOverrule((prev) => [
                ...prev,
                pictures?.find((pic) => pic?.id === deletePictureId),
            ])
            setDeletePictureId(false)
            setSelectedFiles([])
            setOpenPictureCapturePopup(false)
        } else {
            deleteQcPicture(deletePictureId, {
                onSuccess: () => {
                    setDeletePictureId(false)
                    queryClient.invalidateQueries('issue_qc_images')
                },
            })
        }
    }

    const handleUpdateComment = useMemo(
        () =>
            _.debounce((comment) => {
                updateComment({ issueId: id, comment })
            }, 1500),
        [id, updateComment],
    )

    const openConfirmFormCallback = useCallback(() => {
        setOpenConfirmForm(true)
    }, [])

    const whenSaveIssueQcSuccessfully = useCallback(
        (data) => {
            queryClient.invalidateQueries(['issue', id])
            queryClient.invalidateQueries(['issue_quality_check', id])
            queryClient.invalidateQueries(['issue_logs'])

            let navigationPath = ''
            if (data?.status === ISSUE_QC_SUCCESS) {
                openSnackbar({
                    message: t(`${trans}message.success`),
                    type: 'success',
                })
                navigationPath = DASHBOARD
            } else if (data?.status === ISSUE_QC_FAILED) {
                openSnackbar({
                    message: t(`${trans}message.failed`),
                    type: 'error',
                })
                navigationPath = `${ISSUE_ISSUE}/${data.data.id}?tag=0`
            } else if (data?.status === ISSUE_ASSIGNED_TO_SUPERVISOR) {
                openSnackbar({
                    message: t(`${trans}message.assign2supervisor`),
                    type: 'error',
                })
                navigationPath = `${ISSUE_ISSUE}/${data.data.id}?tag=0`
            }

            printQcConfirmForm(issueData)
            navigate(navigationPath)
        },
        [id, issueData, navigate, openSnackbar, queryClient, t],
    )

    const handleSaveOverrule = useCallback(
        async (commentOverrule, qcPictures) => {
            const jsonValue = JSON.stringify(qcFormOverruleData ?? '{}')
            const newPictures = qcPictures
                .filter((qcPic) => !qcPic?.uuid)
                .map((qc) => {
                    return {
                        image: qc.image,
                        notConvert: qc?.notConvert || false,
                    }
                })
            const deletePics = deletePictureOverrule.map((dp) => dp.id)
            updateOverruleData(
                {
                    issueId: id,
                    answers: jsonValue,
                    deletePictures: deletePics,
                    comment: commentOverrule,
                    newPictures,
                },
                {
                    onSuccess: (data) => {
                        whenSaveIssueQcSuccessfully(data)
                    },
                    onError: (error) => {
                        openSnackbar({
                            message: parseApiErrorMessage(error),
                            type: 'error',
                        })
                    },
                },
            )
        },
        [
            deletePictureOverrule,
            id,
            openSnackbar,
            qcFormOverruleData,
            updateOverruleData,
            whenSaveIssueQcSuccessfully,
        ],
    )

    const handleSaveIssueQc = () => {
        saveIssueQc(id, {
            onSuccess: (data) => {
                whenSaveIssueQcSuccessfully(data)
            },
            onError: (error) => {
                openSnackbar({
                    message: parseApiErrorMessage(error),
                    type: 'error',
                })
            },
        })
    }

    useEffect(() => {
        if (!isOverrule) {
            setOverrulePictureFiles(data?.data)
            setQCFormOverruleData(qcData?.data?.answers)
        }
    }, [data?.data, isOverrule, qcData?.data?.answers])

    useEffect(() => {
        if (isFetchedData) {
            const answers = qcData.data.answers ?? {}
            let answered = 0
            qcFormCriteria.forEach((criteria) => {
                if (typeof answers[criteria.name] !== 'undefined') {
                    answered++
                }
            })

            setDisableSaveButton(answered < qcFormCriteria.length)

            const fetchedComment = qcData.data.comment

            setComment(fetchedComment)
        }
    }, [isFetchedData, qcData.data.answers, qcData.data.comment])

    return (
        <>
            {qcData?.data?.finished_at && !isConfirmOverrule && (
                <Stack direction='row-reverse'>
                    <LoadingButton
                        label={t('button.print')}
                        onClick={() => {
                            printQcConfirmForm(issueData)
                        }}
                    ></LoadingButton>

                    {isShowOverruleButton &&
                        issueData?.data.state === 'QC_DONE' && (
                            <LoadingButton
                                label={t(`${trans}overruleQualityCheck`)}
                                onClick={() => {
                                    setIsOverrule(true)
                                }}
                                style={{
                                    marginRight: '10px',
                                }}
                            ></LoadingButton>
                        )}
                </Stack>
            )}
            <Box id='qc-confirm-form'>
                <Typography variant='h5'>
                    {t(`${trans}issueQualityCheckSummary`)}
                </Typography>
                <Paper sx={{ px: 2, py: 1 }}>
                    <Stack gap={2}>
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                gap: '16px',
                            }}
                        >
                            <Paper sx={{ px: 2, py: 2 }}>
                                <TicketDetails
                                    data={ticketData}
                                    titleStype={{
                                        float: 'left',
                                    }}
                                />
                            </Paper>

                            <Paper sx={{ px: 2, py: 2 }} className='wont-print'>
                                <Stack gap={1}>
                                    <Typography variant='h5'>
                                        {t(`${trans}comments`)}
                                        <TableContainer
                                            component='div'
                                            sx={{
                                                border: '0.5px solid lightgray',
                                                borderRadius: '6px',
                                                marginTop: '15px',
                                            }}
                                        >
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            {t(
                                                                `${trans}issueDiagnose`,
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Stack
                                                                direction='row'
                                                                gap={1}
                                                                alignItems='center'
                                                            >
                                                                <Typography>
                                                                    {issueData
                                                                        ?.data
                                                                        ?.diagnostic
                                                                        ?.comments ??
                                                                        'NULL'}
                                                                </Typography>
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            {t(
                                                                `${trans}issueRepair`,
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {issueData?.data
                                                                ?.repair
                                                                ?.technician_comment ??
                                                                'NULL'}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Typography>
                                </Stack>
                                <Stack gap={1}>
                                    <Box sx={{ marginTop: '15px' }}>
                                        <Typography variant='h5'>
                                            {t(`${trans}miscellaneous`)}
                                        </Typography>
                                    </Box>
                                    <Stack gap={1}>
                                        <Typography
                                            sx={{
                                                fontSize: 18,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {t(`${trans}comment`)}
                                        </Typography>
                                        <TextField
                                            multiline
                                            maxRows={5}
                                            minRows={5}
                                            sx={{ width: '100%' }}
                                            value={
                                                isConfirmOverrule
                                                    ? qcConfirmComment
                                                    : comment
                                            }
                                            disabled={
                                                isOverrule
                                                    ? false
                                                    : !!qcData?.data
                                                          ?.finished_at ||
                                                      isConfirmForm
                                            }
                                            onChange={(e) => {
                                                const newComment =
                                                    e.target.value

                                                setComment(newComment)
                                                if (!isOverrule) {
                                                    handleUpdateComment(
                                                        newComment,
                                                    )
                                                }
                                            }}
                                        />

                                        <Typography
                                            sx={{
                                                fontSize: 18,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {t(`${trans}photos`)}
                                        </Typography>
                                        {((!qcData?.data?.finished_at &&
                                            !isConfirmForm) ||
                                            isOverrule) && (
                                            <Box>
                                                <Button
                                                    sx={{
                                                        width: 'fit-content',
                                                        mr: 1,
                                                    }}
                                                    onClick={() =>
                                                        setOpenUploadPicture(
                                                            true,
                                                        )
                                                    }
                                                >
                                                    {t(`${trans}uploadPhoto`)}
                                                </Button>
                                                <Button
                                                    sx={{
                                                        width: 'fit-content',
                                                    }}
                                                    onClick={() =>
                                                        setOpenPictureCapturePopup(
                                                            true,
                                                        )
                                                    }
                                                >
                                                    {t(`${trans}capturePhoto`)}
                                                </Button>
                                            </Box>
                                        )}
                                        {!isOverrule ? (
                                            <QcPictures
                                                pictures={
                                                    isConfirmOverrule
                                                        ? qcConfirmPictures
                                                        : pictures
                                                }
                                                deletePicture={deletePicture}
                                                isConfirmForm={isConfirmForm}
                                            />
                                        ) : (
                                            <QcPictures
                                                pictures={overrulePictureFiles}
                                                deletePicture={deletePicture}
                                                isConfirmForm={isConfirmForm}
                                            />
                                        )}
                                    </Stack>
                                </Stack>
                            </Paper>
                        </Box>
                    </Stack>
                    <Stack gap={1}>
                        <Paper sx={{ px: 2, py: 2 }}>
                            <Typography variant='h5' sx={{ mb: '8px' }}>
                                {t(`${trans}qcForm`)}
                            </Typography>
                            <QCForm
                                disabled={
                                    isOverrule
                                        ? false
                                        : !!qcData?.data?.finished_at ||
                                          isConfirmForm
                                }
                                data={
                                    isConfirmOverrule
                                        ? qcConfirmDataOverrule
                                        : qcData?.data?.answers
                                }
                                isFetchedData={isFetchedData}
                                setDisableSaveButton={setDisableSaveButton}
                                setQCFormOverruleData={setQCFormOverruleData}
                                isOverrule={isOverrule}
                                setIsUpdatingAnswers={setIsUpdatingAnswers}
                            />
                        </Paper>
                        {!qcData?.data?.finished_at && !isConfirmForm && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                                className='wont-print'
                            >
                                <LoadingButton
                                    label={t('button.save')}
                                    disabled={disableSaveButton}
                                    loading={
                                        isSavingIssueQc || isUpdatingAnswers
                                    }
                                    onClick={openConfirmFormCallback}
                                ></LoadingButton>
                            </Box>
                        )}
                        {isOverrule && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                                className='wont-print'
                            >
                                <LoadingButton
                                    label={t('button.save')}
                                    disabled={disableSaveButton}
                                    loading={
                                        isSavingIssueQc ||
                                        isUploadingDataOverrule ||
                                        isUpdatingAnswers
                                    }
                                    onClick={openConfirmFormCallback}
                                ></LoadingButton>
                            </Box>
                        )}
                    </Stack>
                </Paper>
            </Box>
            {(!qcData?.data?.finished_at || isOverrule) && (
                <>
                    <PictureCapturePopup
                        {...{
                            open: openPictureCapturePopup,
                            selectedFiles,
                            setSelectedFiles,
                            isLoading: isUploadingPicture,
                            currentImageList: isOverrule
                                ? currentOverrulePictures
                                : currentPictures,
                            onSubmit,
                            onClose,
                            imageLimit: 5,
                            handleRemoveCurrent: (item) => {
                                deletePicture(item.id)
                            },
                        }}
                    />

                    <UploadQcPicturePopup
                        open={openUploadPicture}
                        setOpenUploadPicture={setOpenUploadPicture}
                        isOverrule={isOverrule}
                        setOverrulePictures={setOverrulePictureFiles}
                    />
                </>
            )}
            <DeleteConfirmPopup
                openDeleteConfirm={!!deletePictureId}
                {...{ handleClose, isLoading }}
                handleDelete={handleDelete}
                informationDialog={{
                    title: `${t('dialog.deletePicture')}`,
                    content: `${t('dialog.deletePictureContent')}`,
                }}
            />

            <QcConfirmationForm
                open={openConfirmForm}
                setOpen={setOpenConfirmForm}
                handleSave={() => {
                    if (isOverrule) {
                        handleSaveOverrule(comment, overrulePictureFiles)
                    } else {
                        handleSaveIssueQc()
                    }
                }}
                qcDataOverrule={qcFormOverruleData}
                qcOverruleComment={comment}
                qcOverrulePictures={overrulePictureFiles}
                isConfirmOverrule={isOverrule}
                isSavingIssueQc={isSavingIssueQc || isUploadingDataOverrule}
            />
        </>
    )
}

QCFormSection.propTypes = {
    qcData: PropTypes.object,
    isFetchedData: PropTypes.bool,
    isConfirmForm: PropTypes.bool,
    issueData: PropTypes.object,
    qcConfirmDataOverrule: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    qcConfirmComment: PropTypes.string,
    qcConfirmPictures: PropTypes.arrayOf(PropTypes.object),
    isConfirmOverrule: PropTypes.bool,
}

export default QCFormSection
