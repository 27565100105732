import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

const ErrorMessageInput = ({ children }) => {
    return (
        <Box>
            <Typography
                sx={{
                    color: '#d32f2f',
                    fontFamily: "'Lato',sans-serif",
                    fontSize: '0.75rem',
                    fontWeightL: 400,
                    lineHeight: 1.66,
                    marginLeft: '14px',
                    marginTop: '10px',
                    marginBottom: '10px',
                }}
            >
                {children}
            </Typography>
        </Box>
    )
}

ErrorMessageInput.propTypes = { children: PropTypes.node.isRequired }

export default ErrorMessageInput
