import { number, object, string } from 'yup'
import useFormValidationCallback from '~/hooks/useFormValidationCallback'
import { useTranslation } from 'react-i18next'
import { workbenchLocation } from '~/constants/yupSchemas'

const useFormValidate = () => {
    const { t } = useTranslation()
    const schema = object().shape({
        name: string().label('Name').required(),
        location: workbenchLocation(t).label('Location').required(),
        service_center_id: number().label('Service center').required(),
    })
    return useFormValidationCallback(schema)
}
export default useFormValidate
