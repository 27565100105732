import {
    Table as MuiTable,
    TableBody as MuiTableBody,
    Paper,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import PropTypes from 'prop-types'
import { ProductionProjectDetailHeadCells } from '~/components/HeaderTable/ProductionProjectHeadCells'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { formatDateTime } from '~/utils/helpers'

const TableBody = ({ isLoading, object }) => {
    if (isLoading) {
        return (
            <TableLoading colSpan={ProductionProjectDetailHeadCells.length} />
        )
    }

    return (
        <MuiTableBody>
            <TableRow hover tabIndex={-1} key={object?.id}>
                <TableCell>{object?.language}</TableCell>
                <TableCell>{object?.target_group}</TableCell>
                <TableCell>{formatDateTime(object?.delivery_date)}</TableCell>
                <TableCell>{object?.state}</TableCell>
                <TableCell>{formatDateTime(object?.created_at)}</TableCell>
            </TableRow>
        </MuiTableBody>
    )
}

TableBody.propTypes = {
    isLoading: PropTypes.bool,
    object: PropTypes.object,
}

const ProjectDetailTable = ({ isLoading, data }) => {
    return (
        <Paper>
            <TableContainer
                sx={{ width: '100%', marginBottom: '20px' }}
                aria-labelledby='tableTitle'
                size='medium'
            >
                <MuiTable>
                    <TableHeader
                        headCells={ProductionProjectDetailHeadCells}
                        localization={'pages.production.projects.'}
                        order='desc'
                        orderBy='id'
                        onRequestSort={() => {}}
                    />
                    <TableBody isLoading={isLoading} object={data} />
                </MuiTable>
            </TableContainer>
        </Paper>
    )
}

ProjectDetailTable.propTypes = {
    isLoading: PropTypes.bool,
    data: PropTypes.object,
}

export default ProjectDetailTable
