import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import SectionTitle from './components/SectionTitle'
import { LOCALIZATION } from './constants'

const EntitlementsAccordions = ({
    isExpanded,
    handleChange,
    title,
    content,
}) => {
    const { t } = useTranslation()

    return (
        <Accordion
            elevation={0}
            sx={{ border: '0.5px solid lightgray', mt: 3 }}
            expanded={isExpanded}
            onChange={handleChange}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel2a-content'
                id='panel2a-header'
            >
                <SectionTitle
                    title={t(LOCALIZATION + title)}
                    xs={12}
                    md={12}
                    lg={12}
                    align='center'
                    gutterBottom={false}
                />
            </AccordionSummary>
            <AccordionDetails>{content}</AccordionDetails>
        </Accordion>
    )
}

EntitlementsAccordions.propTypes = {
    isExpanded: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    title: PropTypes.string,
    content: PropTypes.node,
}

export default EntitlementsAccordions
