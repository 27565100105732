import {
    Table as MuiTable,
    TableBody as MuiTableBody,
    TableCell as MuiTableCell,
    Paper,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DataNotFound from '~/components/DataNotFound'
import ExternalLink from '~/components/ExternalLink'
import { TicketHeadCells } from '~/components/HeaderTable/TicketHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableCell from '~/components/TableCell'
import TableHeader from '~/components/TableHeader'
import {
    EASY4U_TICKET_DEVELOPMENT,
    EASY4U_TICKET_PRODUCTION,
} from '~/constants/externalLink'
import useTable from '~/hooks/useTable'
import { useRackTicketListQuery } from '../query'
import LabelStateTicket from './LabelStateTicket'
import LastScanLocation from './LastScanLocation'
import RackTicketListToolbar from './RackTicketListToolbar'
import RackTicketTableFilters from './RackTicketTableFilters'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'
import ReferencedEntities from '~/components/ReferencedEntities'
import WarningTableCell from './WarningTableCell'

const TicketTableBody = ({ isLoading, tickets }) => {
    const setColorTicket = useCallback((row) => {
        if (
            row?.state === 'ACTION_REQUIRED' ||
            row?.location !== row?.rack_scan_line?.location
        ) {
            return 'red'
        }
        return ''
    }, [])

    const checkingShowingError = useCallback((row, message) => {
        return (
            row?.state === 'ACTION_REQUIRED' &&
            row?.actionRequiredMessage.includes(message)
        )
    }, [])

    const ticketsToRender = useMemo(() => {
        return tickets.map((ticket) => {
            return {
                ...ticket,
                url: ticket?.url
                    ? `${ticket?.url}admin/tickets/werkorder/`
                    : EASY4U_TICKET_PRODUCTION,
                rowColor: setColorTicket(ticket),
                latestDispatch: {
                    rma_code:
                        ticket?.latest_rack_ticket_dispatch?.dispatch_code,
                    status: ticket?.ticket?.ticket_status,
                },
            }
        })
    }, [tickets, setColorTicket])

    if (isLoading) {
        return <TableLoading colSpan={TicketHeadCells.length} />
    }

    return (
        <MuiTableBody>
            {tickets.length > 0 ? (
                ticketsToRender.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                            <MuiTableCell align='left'>
                                <ExternalLink
                                    extLink={
                                        import.meta.env.VITE_ENVIRONMENT ===
                                        'production'
                                            ? row?.url
                                            : EASY4U_TICKET_DEVELOPMENT
                                    }
                                    param={row.ticket_id}
                                />
                            </MuiTableCell>
                            <ReferencedEntities
                                isTableCell
                                dataType='issue-id'
                                dataIdentifier={row?.ticket?.issue?.id}
                            />

                            <TableCell
                                cellValue={
                                    <WarningTableCell
                                        showingError={checkingShowingError(
                                            row,
                                            'Incorrect ESD location',
                                        )}
                                    >
                                        {row.location}
                                    </WarningTableCell>
                                }
                                color={
                                    checkingShowingError(
                                        row,
                                        'Incorrect ESD location',
                                    )
                                        ? 'red'
                                        : ''
                                }
                            />
                            <TableCell
                                cellValue={<LastScanLocation row={row} />}
                                type={'component'}
                            />
                            <TableCell
                                cellValue={
                                    <DateTimeLocale
                                        datetime={row?.scanned_at}
                                    />
                                }
                                type={'component'}
                            />
                            <TableCell
                                cellValue={
                                    <WarningTableCell
                                        showingError={checkingShowingError(
                                            row,
                                            'Incorrect ticket status',
                                        )}
                                    >
                                        {row?.ticket?.ticket_status}
                                    </WarningTableCell>
                                }
                                color={
                                    checkingShowingError(
                                        row,
                                        'Incorrect ticket status',
                                    )
                                        ? 'red'
                                        : ''
                                }
                            />
                            <TableCell
                                cellValue={<LabelStateTicket row={row} />}
                                // color={row.rowColor}
                                type='component'
                            />
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={TicketHeadCells.length} />
            )}
        </MuiTableBody>
    )
}

TicketTableBody.propTypes = {
    isLoading: PropTypes.bool,
    tickets: PropTypes.arrayOf(PropTypes.object),
}

const Table = () => {
    const [state, setState] = useState('')
    const [team, setTeam] = useState('')
    const [reason, setReason] = useState('')

    const {
        results,
        data,
        isLoading,
        page,
        search,
        order,
        orderBy,
        setPage,
        changePageHandler,
        searchHandler,
        sortHandler,
        onEnterSearch,
    } = useTable({
        query: useRackTicketListQuery,
        queryAttributes: { state, team, reason },
    })

    const refValue = useRef({
        team: '',
        state: '',
        reason: '',
    })

    useEffect(() => {
        if (
            team !== refValue.current.team ||
            state !== refValue.current.state ||
            reason !== refValue.current.reason
        ) {
            setPage(1)
            refValue.current.team = team
            refValue.current.state = state
            refValue.current.reason = reason
        }
    }, [state, team, setPage, reason])

    return (
        <>
            <RackTicketListToolbar
                payload={{ order, orderBy, state, team, page, search }}
            />

            <Paper>
                <RackTicketTableFilters
                    search={search}
                    searchHandler={searchHandler}
                    state={state}
                    setState={setState}
                    team={team}
                    setTeam={setTeam}
                    reason={reason}
                    setReason={setReason}
                    onEnterSearch={onEnterSearch}
                    total={data?.total}
                />
                <TableContainer
                    sx={{ minWidth: 750 }}
                    aria-labelledby='tableTitle'
                    size='medium'
                >
                    <Typography
                        variant='body2'
                        fontWeight={'fontWeightMedium'}
                        fontSize=''
                        style={{ paddingLeft: '16px', marginTop: '20px' }}
                    >
                        {data?.total ?? 0} item(s) showing
                    </Typography>
                    <MuiTable>
                        <TableHeader
                            headCells={TicketHeadCells}
                            localization={
                                'pages.issues.rackScans.ticket.overview.'
                            }
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                        />
                        <TicketTableBody
                            isLoading={isLoading}
                            tickets={results}
                        />
                    </MuiTable>
                </TableContainer>
                <PaginationTable
                    data={data}
                    page={page}
                    handleChangePage={changePageHandler}
                    size='medium'
                />
            </Paper>
        </>
    )
}

export default Table
