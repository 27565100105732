import _ from 'lodash'
import { Component } from 'react'
import { FormSpy } from 'react-final-form'
import PropTypes from 'prop-types'

/**
 * @prop {async function} save - The "Save" function of the form
 * @prop {int} debounce - The delay between last update a value and saving it
 */
class AutoSave extends Component {
    constructor(props) {
        super(props)
        this.state = { values: props.values, submitting: false }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.timeout) {
            clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(this.save, this.props.debounce)
    }

    save = async () => {
        if (this.promise) {
            await this.promise
        }
        const { values, save } = this.props

        const isChanged = !_.isEqual(this.state.values, values)

        if (isChanged) {
            this.setState({ submitting: true, values })
            this.promise = save(values)
            await this.promise
            delete this.promise
            this.setState({ submitting: false })
        }
    }

    render() {
        return <></>
    }
}
AutoSave.propTypes = {
    values: PropTypes.any,
    save: PropTypes.func,
    debounce: PropTypes.number,
}

const FormAutoSave = (props) => (
    <FormSpy {...props} subscription={{ values: true }} component={AutoSave} />
)

export default FormAutoSave
