import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectShowedPartCode } from '~/store/partcode/selector'
import { pushShowPartCodes } from '~/store/partcode/slice'
import Button from '../Button'
import PropTypes from 'prop-types'

const PartCodeListSelectorComponent = ({ partCode }) => {
    const dispatch = useDispatch()
    const showingPartCode = useSelector(selectShowedPartCode)
    const currentPartCodes = useState(showingPartCode)
    const [disabledAddButton, setDisabledAddButton] = useState(false)

    useEffect(() => {
        const isAddedPart = currentPartCodes.some((showingPart) => {
            return showingPart.code === partCode.code
        })
        setDisabledAddButton(isAddedPart)
    }, [partCode.code, currentPartCodes])

    const addPart = () => {
        dispatch(pushShowPartCodes([...showingPartCode, partCode]))
    }

    return (
        <TableRow>
            <TableCell>{partCode.code}</TableCell>
            <TableCell>{partCode.name}</TableCell>
            <TableCell align='center'>
                <Button
                    disabled={partCode.added || disabledAddButton}
                    onClick={addPart}
                >
                    Add
                </Button>
            </TableCell>
        </TableRow>
    )
}

PartCodeListSelectorComponent.propTypes = { partCode: PropTypes.object }

export default PartCodeListSelectorComponent
