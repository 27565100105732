import {
    Autocomplete,
    FormControl,
    Grid,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import arrayMutators from 'final-form-arrays'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { number, object, string } from 'yup'
import InputField from '~/components/FinalForm/InputField'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import SubmitButton from '~/components/SubmitButton'
import { PRODUCTION_SERVERS } from '~/constants/Routes'
import useFormValidationCallback from '~/hooks/useFormValidationCallback'
import { useSnackbar } from '~/hooks/useSnackbar'
import { useUpdateServerInfoMutation } from '../mutation'
import { useServerOrgsQuery } from '../query'

const UpdateServerInfo = ({ data }) => {
    const { id } = useParams()
    const { t } = useTranslation()
    const localization = 'pages.production.servers'
    const pagePath = PRODUCTION_SERVERS
    const { showSnackbar, snackbar } = useSnackbar()
    const [orgId, setOrgId] = useState()
    const [orgName, setOrgName] = useState(data?.organisation?.name)

    const { data: org } = useServerOrgsQuery({ showSnackbar })

    const schema = object().shape({
        name: string().required(),
        organisation_id: number().optional(),
    })

    const { mutate: updateServerInfoMutate } = useUpdateServerInfoMutation({
        showSnackbar,
    })
    const validate = useFormValidationCallback(schema)
    const submit = useCallback(
        (payload) => {
            updateServerInfoMutate({ id, payload })
        },
        [updateServerInfoMutate, id],
    )

    const onSubmit = useCallback(
        (values) => {
            submit({
                name: values.name,
                organisation_id: orgId,
            })
        },
        [orgId, submit],
    )

    const handleChange = (event, newValue) => {
        setOrgId(newValue.id)
        setOrgName(newValue.label)
    }

    return (
        <Grid item xs={12} md={12}>
            {snackbar}
            <Form
                validate={validate}
                onSubmit={onSubmit}
                mutators={{
                    ...arrayMutators,
                }}
                keepDirtyOnReinitialize
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <FormBodyWrapper>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputField
                                            localization={localization}
                                            name={'name'}
                                            componentId={'name'}
                                            pagePath={pagePath}
                                            isRequired={true}
                                            defaultValue={data?.name}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Typography>{`${t(
                                            'pages.production.servers.organisation',
                                        )}`}</Typography>
                                        <Autocomplete
                                            id='organisation'
                                            name='organisation'
                                            value={orgName}
                                            options={
                                                org?.data?.map((option) => ({
                                                    label: option.name,
                                                    id: option.id,
                                                })) || []
                                            }
                                            onChange={handleChange}
                                            sx={{ mt: 1.5 }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t(
                                                        'pages.production.servers.organisation',
                                                    )}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction='row-reverse' spacing={2}>
                                        <SubmitButton
                                            handleSubmit={handleSubmit}
                                            name={`${t('button.save')}`}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </FormBodyWrapper>
                    </form>
                )}
            />
        </Grid>
    )
}

UpdateServerInfo.propTypes = {
    data: PropTypes.object,
}

export default UpdateServerInfo
