import BreadCrumb from '~/components/BreadCrumb'
import Loading from '~/components/Loading'
import { FLEET_OVERVIEW, FLEET_SERIAL } from '~/constants/Routes'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useDeviceDetailQuery, useDeviceDetailSearchQuery } from './query'
import SerialDetail from './SerialDetail'

const SerialPage = () => {
    const { id } = useParams()
    const { serial } = useParams()
    const { data: device, isLoading: loadingSerial } = useDeviceDetailQuery(id)

    const { data: deviceSearch, isLoading: loadingSearch } =
        useDeviceDetailSearchQuery(serial)

    const result = useMemo(() => {
        return device || deviceSearch
    }, [device, deviceSearch])

    const BreadComponents = [
        { route: FLEET_OVERVIEW, title: 'Fleet' },
        {
            route: FLEET_SERIAL,
            title: 'Serial',
        },
        {
            page: 'detail',
            title: device?.serial,
        },
    ]

    if (loadingSerial || loadingSearch) {
        return <Loading />
    }

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <SerialDetail device={result} />
        </>
    )
}
export default SerialPage
