import { Button, Stack, Tooltip } from '@mui/material'
import LabelStatus from '~/components/LabelStatus'
import { useMemo } from 'react'
import PropTypes from 'prop-types'

const ResultStatus = ({ row }) => {
    const color = useMemo(() => {
        switch (row?.status) {
            case 'Success':
                return 'success'
            default:
                return 'error'
        }
    }, [row.status])

    const showStatus = useMemo(() => {
        if (row?.error_message) {
            return (
                <Tooltip title={row?.error_message} placement='top'>
                    <Button color={color} size='small'>
                        {row.status}
                    </Button>
                </Tooltip>
            )
        }
        return (
            <Button color={color} size='small'>
                {row.status}
            </Button>
        )
    }, [color, row.error_message, row.status])

    return (
        <Stack direction='row' justifyContent='center'>
            <LabelStatus variant='ghost' color={color}>
                {showStatus}
            </LabelStatus>
        </Stack>
    )
}

ResultStatus.propTypes = { row: PropTypes.object }

export default ResultStatus
