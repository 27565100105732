import { useNavigate } from 'react-router-dom'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import { Form as FinalForm } from 'react-final-form'
import BreadCrumb from '~/components/BreadCrumb'
import useFormValidationCallback from '~/hooks/useFormValidationCallback'
import { object, string } from 'yup'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_NAMING_TAG,
    ADMINISTRATOR_NAMING_TAG_DETAIL,
} from '~/constants/Routes'
import FormToolbar from '~/components/FinalForm/FormToolbar'
import { LoadingButton } from '~/components/Button'
import { useTranslation } from 'react-i18next'
import { useCreateTagMutation } from '../mutate'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import BodyForm from './BodyForm'

const breadCrumbConfig = [
    { title: `Administrator`, route: ADMINISTRATOR },
    {
        title: `NamingTags`,
        route: ADMINISTRATOR_NAMING_TAG,
    },
    {
        title: `Create tag`,
        page: 'detail',
    },
]

const NamingTagCreate = () => {
    const localization = `pages.administrator.namingTag`
    const { mutate: createTagMutate, isLoading } = useCreateTagMutation()
    const schema = object().shape({
        name: string().label(`Tag Name`).required(),
    })
    const navigate = useNavigate()
    const validate = useFormValidationCallback(schema)
    const { t } = useTranslation()
    const { openSnackbar } = useDialogContext()

    const saveHandler = (values) => {
        createTagMutate(values, {
            onSuccess: (data) => {
                openSnackbar({
                    message: `Save successful.`,
                    type: `success`,
                })
                navigate(
                    ADMINISTRATOR_NAMING_TAG_DETAIL.replace(':id', data.id),
                )
            },
            onError: (e) => {
                openSnackbar({
                    message: e?.response?.data.message,
                    type: 'error',
                    duration: 10000,
                })
            },
        })
    }

    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <FinalForm
                validate={validate}
                onSubmit={() => {}}
                initialValues={{}}
                render={({ handleSubmit, values, dirty }) => (
                    <form onSubmit={handleSubmit}>
                        <FormToolbar>
                            <LoadingButton
                                disabled={!dirty}
                                label={t(`${localization}.save`)}
                                onClick={() => saveHandler(values)}
                                loading={isLoading}
                            />
                        </FormToolbar>
                        <FormBodyWrapper>
                            <BodyForm {...{ localization }} />
                        </FormBodyWrapper>
                    </form>
                )}
            />
        </>
    )
}

export default NamingTagCreate
