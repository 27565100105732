import PropTypes from 'prop-types'
import DataNotFound from '~/components/DataNotFound'
import StyledTableRow from '~/pages/Diagnostic/components/StyledTableRow'
import { TableBody, TableCell, Tooltip } from '@mui/material'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const WarrantyTableBody = ({ warrantyData, classes, getSphereIcon }) => {
    return (
        <TableBody>
            {!warrantyData || warrantyData.length === 0 ? (
                <DataNotFound colSpan={4} />
            ) : (
                warrantyData.map((item, index) => (
                    <StyledTableRow key={index}>
                        <TableCell className={classes.tableCell}>
                            <Tooltip
                                arrow
                                title={item.warranty_name}
                                placement='top'
                            >
                                <span>{item.warranty_code}</span>
                            </Tooltip>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            <DateTimeLocale
                                datetime={item.warranty_start}
                                formatHour
                            />
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            <DateTimeLocale
                                datetime={item.warranty_end}
                                formatHour
                            />
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            {getSphereIcon(item.warranty_end)}
                        </TableCell>
                    </StyledTableRow>
                ))
            )}
        </TableBody>
    )
}

WarrantyTableBody.propTypes = {
    warrantyData: PropTypes.array,
    classes: PropTypes.any,
    getSphereIcon: PropTypes.func,
}

export default WarrantyTableBody
