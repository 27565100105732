import {
    ADMINISTRATOR,
    ADMINISTRATOR_DATASCHEDULER,
    ADMINISTRATOR_DATASCHEDULER_DETAIL,
} from '~/constants/Routes'

import BreadCrumb from '~/components/BreadCrumb'
import { useTranslation } from 'react-i18next'
import PageName from '../../../components/PageName'
import { useSnackbar } from '~/hooks/useSnackbar'
import { useParams } from 'react-router-dom'
import { useExportSchedulerDetailQuery } from '../query'
import { useEffect } from 'react'
import Loading from '~/components/Loading'
import { selectSnackbar } from '~/store/snackbar/selector'
import { useDispatch, useSelector } from 'react-redux'
import { unsetSnackbar } from '~/store/snackbar/slice'
import SchedulerForm from './SchedulerForm'
import ComponentTooltip from '~/components/ComponentTooltip'

const DataSchedulerEdit = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const dispatch = useDispatch()
    const { showSnackbar, snackbar } = useSnackbar()
    const snackbarData = useSelector(selectSnackbar)

    const { data: exportScheduler, isLoading: loadingGetDetail } =
        useExportSchedulerDetailQuery(id)

    useEffect(() => {
        if (snackbarData) {
            showSnackbar(snackbarData.message, snackbarData.type)
        }
        return () => {
            dispatch(unsetSnackbar({}))
        }
    }, [dispatch, showSnackbar, snackbarData])

    const BreadComponents = [
        { route: ADMINISTRATOR, title: t(`breadCrumb.Administrator`) },
        {
            route: ADMINISTRATOR_DATASCHEDULER,
            title: 'dataScheduler',
        },
        {
            page: 'detail',
            title: id,
        },
    ]
    if (loadingGetDetail) {
        return <Loading />
    }

    return (
        <>
            {snackbar}
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_DATASCHEDULER_DETAIL}
            >
                <div>
                    <PageName name={'Edit Scheduler'} />
                </div>
            </ComponentTooltip>
            <SchedulerForm exportScheduler={exportScheduler} />
        </>
    )
}
export default DataSchedulerEdit
