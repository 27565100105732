import { useQuery } from 'react-query'
import testDataApi from '~/api/testDataApi'

export const useTestableDevicesQuery = () => {
    return useQuery(
        'testable-device',
        async () => {
            const { data } = await testDataApi.getTestableDevices()
            if (!data?.data?.length) {
                return []
            }
            return data.data.map((device) => {
                return {
                    name: device.serial,
                    value: device.serial,
                }
            })
        },
        {},
    )
}
