import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Box, Divider, Grid, Modal, Typography, Stack } from '@mui/material'
import SubmitButton from '~/components/SubmitButton'
import { useCallback, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useQueryClient } from 'react-query'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { useTranslation } from 'react-i18next'

const StyledModal = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})

const ErrorBox = styled(Box)({
    height: '7rem',
    overflow: 'auto',
})

const ErrorTypography = styled(Typography)({
    color: '#d32f2f',
    fontFamily: "'Lato',sans-serif",
    fontSize: '0.75rem',
    fontWeightL: 400,
    lineHeight: 1.66,
})

const UploadPopup = ({
    openUpload,
    setOpenUpload,
    actionImport,
    isLoading,
}) => {
    const [selectedFile, setSelectedFile] = useState(false)
    const [failures, setFailures] = useState([])
    const [error, setError] = useState()
    const { openSnackbar } = useDialogContext()
    const { t } = useTranslation()

    const queryClient = useQueryClient()

    const changeHandler = useCallback((event) => {
        setSelectedFile(event.target.files[0])
        setFailures([])
        setError()
    }, [])

    const onSubmit = useCallback(() => {
        actionImport(selectedFile, {
            onSettled: () => {
                setSelectedFile(false)
            },
            onSuccess: (data) => {
                if (data.data.status === 'success') {
                    setOpenUpload(false)
                    queryClient.invalidateQueries('users')
                    openSnackbar({
                        message: t(`message.importSuccess`),
                        type: 'success',
                    })
                }
            },
            onError: (e) => {
                setFailures(e.response.data.data)
                setError(e.response.data.message)
                openSnackbar({
                    message: t(`message.importFailed`),
                    type: 'error',
                })
            },
        })
    }, [
        actionImport,
        queryClient,
        selectedFile,
        setOpenUpload,
        openSnackbar,
        t,
    ])

    const handleClose = () => {
        setOpenUpload(false)
        setFailures([])
        setError()
    }

    return (
        <>
            <StyledModal open={openUpload} onClose={(e) => handleClose()}>
                <Box
                    bgcolor={'background.default'}
                    color={'text.primary'}
                    p={3}
                    borderRadius={5}
                    maxWidth={500}
                >
                    <Typography variant='h6' color='gray' textAlign='center'>
                        {t(`general.importFile`)}
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Form
                        onSubmit={onSubmit}
                        render={() => (
                            <Grid container spacing={2} xs={12}>
                                <Grid item xs={'auto'}>
                                    <Box>{t(`general.uploadCSV`)}</Box>
                                    <Field>
                                        {(props) => (
                                            <input
                                                id='file-import'
                                                name='file'
                                                type='file'
                                                onChange={changeHandler}
                                                accept='.csv'
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={12}>
                                    {failures && failures.length > 0 && (
                                        <ErrorBox>
                                            {failures.map((index) => (
                                                <ErrorTypography
                                                    key={index.row}
                                                >
                                                    (Row: {index.row}){' '}
                                                    {index.contents.map(
                                                        (content) => content,
                                                    )}
                                                </ErrorTypography>
                                            ))}
                                        </ErrorBox>
                                    )}
                                    {error && (
                                        <ErrorBox>
                                            <ErrorTypography>
                                                {error}
                                            </ErrorTypography>
                                        </ErrorBox>
                                    )}
                                </Grid>

                                <Grid item xs={12}>
                                    <Stack direction='row-reverse' spacing={2}>
                                        <SubmitButton
                                            isLoading={isLoading}
                                            handleSubmit={onSubmit}
                                            name={`${t('button.import')}`}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        )}
                    />
                </Box>
            </StyledModal>
        </>
    )
}
UploadPopup.propTypes = {
    openUpload: PropTypes.bool,
    setOpenUpload: PropTypes.func,
    actionImport: PropTypes.func,
    isLoading: PropTypes.bool,
}

export default UploadPopup
