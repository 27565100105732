import StyledModal from '~/components/StyledModal'
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import useTable from '~/hooks/useTable'
import { useComponentsQuery } from '~/pages/ComponentPage/query'
import { useState } from 'react'
import Search from '~/components/Search/Search'
import TableLoading from '~/components/Table/TableLoading'
import PaginationTable from '~/components/PaginationTable'
import { LoadingButton } from '~/components/Button'
import ConfirmPopup from '~/pages/BOM/components/ConfirmPopup'

const PartNumberList = ({ bomId, onClose, partNumber }) => {
    const { t } = useTranslation()

    const {
        results: components,
        data,
        isLoading,
        page,
        searchHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({ query: useComponentsQuery })
    const [selectPartNumber, setSelectPartNumber] = useState(null)

    return (
        <>
            <Search
                label={t(`pages.dispatches.dispatch.add.filter_part_number`)}
                onChange={searchHandler}
                onKeyDown={onEnterSearch}
                sx={{ mb: 2 }}
            />
            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('table.partName')}</TableCell>
                            <TableCell align='center'>
                                {t('table.action')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {isLoading ? (
                        <TableLoading colSpan={3} height='550px' />
                    ) : (
                        <TableBody>
                            {components.map((component) => (
                                <TableRow key={component.id}>
                                    <TableCell>
                                        {component.part_number}
                                    </TableCell>
                                    <TableCell align='center'>
                                        <LoadingButton
                                            onClick={() =>
                                                setSelectPartNumber(
                                                    component.part_number,
                                                )
                                            }
                                            label='Select'
                                            disabled={
                                                partNumber ===
                                                component.part_number
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                size='small'
                handleChangePage={changePageHandler}
            />
            <ConfirmPopup
                onClose={() => setSelectPartNumber(null)}
                closeAll={onClose}
                bomId={bomId}
                partNumber={selectPartNumber}
                message={`Do you want to set ${selectPartNumber} part number to this BOM?`}
            />
        </>
    )
}

PartNumberList.propTypes = {
    bomId: PropTypes.number,
    onClose: PropTypes.func,
    partNumber: PropTypes.string,
}

const PartNumberSelectionPopup = ({ bomId, open, onClose, partNumber }) => {
    return (
        <StyledModal
            open={open}
            handleClose={onClose}
            stackProps={{
                gap: 2,
                sx: {
                    minWidth: 800,
                    maxHeight: '95vh',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar-thumb': {
                        borderRadius: '10px',
                        backgroundColor: 'rgba(0, 0, 0, .5)',
                    },
                    '&::-webkit-scrollbar': {
                        width: '10px',
                    },
                },
            }}
        >
            <Box sx={{ width: '100%' }}>
                <PartNumberList
                    onClose={onClose}
                    bomId={bomId}
                    partNumber={partNumber}
                />
            </Box>
        </StyledModal>
    )
}

PartNumberSelectionPopup.propTypes = {
    bomId: PropTypes.number,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    partNumber: PropTypes.string,
}

export default PartNumberSelectionPopup
