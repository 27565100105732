import { Grid } from '@mui/material'
import { useState } from 'react'
import TeamIntroduce from './TeamIntroduce'
import TeamInformation from './TeamInformation'
import TeamMember from './TeamMember'
import AccordionDevice from '~/components/AccordionDevice'
import {
    ADMINISTRATOR_DEFINITION_TEAM,
    ADMINISTRATOR_DEFINITION_TEAM_EDIT,
} from '~/constants/Routes'
import { useSelector } from 'react-redux'
import { selectTeam } from '~/store/team/selector'
import ToolbarDetailPage from '~/components/ToolbarDetailPage'

const localization = 'pages.administrator.definition.team.detail'

const accordionsList = [
    {
        index: 1,
        title: 'teamMember',
        content: <TeamMember />,
    },
]

const TeamContent = () => {
    const team = useSelector(selectTeam)
    const accordionArray = accordionsList.map((item) => item.index)
    const [accordions, setAccordions] = useState(accordionArray)

    const handleChange = (panel) => (event) => {
        if (accordions.includes(panel)) {
            const filteredItems = accordions.filter((item) => item !== panel)
            setAccordions(filteredItems)
        }
        if (!accordions.includes(panel)) {
            accordions.push(panel)
            setAccordions([...accordions])
        }
    }

    const showAll = () => {
        setAccordions(accordionArray)
    }

    const hideAll = () => {
        setAccordions([])
    }
    const Buttons = [
        {
            navigateLink: ADMINISTRATOR_DEFINITION_TEAM,
            button: 'close',
            color: 'close',
        },
        {
            navigateLink: ADMINISTRATOR_DEFINITION_TEAM_EDIT.replace(
                ':id',
                team?.id,
            ),
            button: 'edit',
        },
    ]
    return (
        <>
            <ToolbarDetailPage
                showAll={showAll}
                hideAll={hideAll}
                Buttons={Buttons}
            />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                    <TeamIntroduce />
                </Grid>
                <Grid item xs={12} md={6} lg={9}>
                    <TeamInformation />
                </Grid>
                <Grid item xs={12}>
                    {accordionsList.map((item, index) => (
                        <AccordionDevice
                            localization={localization}
                            item={item}
                            accordions={accordions}
                            handleChange={handleChange}
                            key={index}
                        />
                    ))}
                </Grid>
            </Grid>
        </>
    )
}

export default TeamContent
