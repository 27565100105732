import styled from '@emotion/styled'
import {
    AppBar,
    Avatar,
    Box,
    CircularProgress,
    Stack,
    Toolbar,
    Typography,
} from '@mui/material'
import { Container } from '@mui/system'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as Easy4DeviceLogo } from '../../../assets/images/e4dLogo.svg'
import { invokeSsoLogin } from '../../../store/auth/action'
import { selectSsoRedirectUrl } from '../../../store/auth/selector'
import LanguagePopover from '../../../components/language/LanguagePopover'

const LoginHeader = () => {
    const { t } = useTranslation()
    const [isLoggingIn, setIsLoggingIn] = React.useState(false)
    const dispatch = useDispatch()

    const doLogin = React.useCallback(() => {
        dispatch(invokeSsoLogin())
        setIsLoggingIn(true)
    }, [dispatch])

    const ssoRedirectUrl = useSelector(selectSsoRedirectUrl)

    if (ssoRedirectUrl) {
        window.location.href = ssoRedirectUrl
    }
    const AvatarUser = styled(Avatar)(({ theme }) => ({
        color: '#76B82A',
        backgroundColor: '#ffffff',
        transition: '0.5s',
        cursor: 'pointer',
        '&:hover': {
            color: '#102F44',
        },
    }))

    return (
        <AppBar
            position='static'
            style={{ background: '#102F44', height: '90px' }}
        >
            <Container sx={{ marginTop: '20px' }}>
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}>
                        <Box
                            sx={{
                                width: '170px',
                                height: '65px',
                            }}
                        >
                            <Easy4DeviceLogo width={120} height={50} />
                        </Box>
                    </Box>
                    <Box sx={{ paddingBottom: '25px', marginRight: '25px' }}>
                        <LanguagePopover />
                    </Box>

                    <Stack
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                        onClick={doLogin}
                        minWidth='46px'
                    >
                        <AvatarUser src='/broken-image.jpg' />
                        {!isLoggingIn ? (
                            <Typography
                                variant='caption'
                                display='block'
                                gutterBottom
                            >
                                {t('login.loginButton')}
                            </Typography>
                        ) : (
                            <CircularProgress size='25px' />
                        )}
                    </Stack>
                </Toolbar>
            </Container>
        </AppBar>
    )
}
export default LoginHeader
