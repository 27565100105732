import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Autocomplete,
    Box,
    FormControl,
    Grid,
    TextField,
    Typography,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useUpdateTimezoneMutation } from './mutate'
import { TIMEZONES } from '~/constants/constants'
import { useSelector } from 'react-redux'
import { selectUserDetailInformation } from '~/store/user/selector'
import PropTypes from 'prop-types'

const UserTimezone = ({ localization, handleSubmit }) => {
    const [isChanged, setIsChanged] = useState(false)
    const selectedUser = useSelector(selectUserDetailInformation)
    const [selectedRole, setSelectedRole] = useState('Africa/Bangui')

    useEffect(() => {
        setSelectedRole(selectedUser?.timezone)
    }, [selectedUser?.timezone])

    useEffect(() => {
        if (
            (selectedRole !== null || selectedRole !== '') &&
            TIMEZONES.includes(selectedRole)
        ) {
            setIsChanged(true)
        } else {
            setIsChanged(false)
        }
    }, [selectedRole])

    const timezoneList = useMemo(() => {
        return TIMEZONES.map((tz) => ({
            name: tz,
            value: tz,
        }))
    }, [])

    const onChangeRole = useCallback((event, newValue) => {
        setIsChanged(true)
        setSelectedRole(newValue)
    }, [])

    const { t } = useTranslation()
    const { mutate: updateRole, isLoading: isSaving } =
        useUpdateTimezoneMutation({})

    return (
        <>
            <Box sx={{ m: 2 }}>
                <Typography gutterBottom variant='body1'>
                    {t(`${localization}.timezone`).toUpperCase()}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item md={9}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id='auto'
                                value={selectedRole}
                                onChange={onChangeRole}
                                options={timezoneList.map(
                                    (option) => option.name,
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3}>
                        <LoadingButton
                            variant='contained'
                            size='large'
                            disabled={!isChanged}
                            loading={isSaving}
                            sx={{
                                textTransform: 'none',
                                backgroundColor: '#76B72A',
                                height: '100%',
                            }}
                            type={`button`}
                            onClick={() => {
                                updateRole(selectedRole)
                            }}
                        >
                            {t(`${localization}.save`)}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

UserTimezone.propTypes = {
    localization: PropTypes.string,
    handleSubmit: PropTypes.func,
}

export default UserTimezone
