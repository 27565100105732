import { FormControl, Grid, Typography } from '@mui/material'
import ComponentTooltip from '~/components/ComponentTooltip'
import InputField from '~/components/FinalForm/InputField'
import SelectField from '~/components/FinalForm/SelectField'
import { ADMINISTRATOR_DEFINITION_TEAM_NEW } from '~/constants/Routes'

import { useCallback } from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
const pagePath = ADMINISTRATOR_DEFINITION_TEAM_NEW

const TeamForm = ({ localization, InputFieldGroup, selectGroup, form }) => {
    const { t } = useTranslation()

    const changeHandler = useCallback(
        (event) => {
            form.change('avatar', event.target.files[0])
        },
        [form],
    )
    return (
        <Grid container spacing={2}>
            {InputFieldGroup.map((item, idx) => (
                <Grid item xs={12} key={idx}>
                    <InputField
                        localization={localization}
                        name={item.name}
                        componentId={`${item.componentId}`}
                        pagePath={pagePath}
                        isRequired={item.isRequired}
                        placeholder={t(`${localization}.${item.name}`)}
                    />
                </Grid>
            ))}
            {selectGroup.map((item, index) => {
                return (
                    <Grid item xs={12} key={index}>
                        <FormControl sx={{ width: '100%' }}>
                            <Field
                                name={item.name}
                                component={SelectField}
                                items={item.listResource}
                                localization={localization}
                                componentId={`${item.componentId}`}
                                pagePath={pagePath}
                                nameSelect={item.title}
                                isRequired={item.required}
                            />
                        </FormControl>
                    </Grid>
                )
            })}
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <ComponentTooltip
                        componentId={`team_form_avatar`}
                        pagePath={ADMINISTRATOR_DEFINITION_TEAM_NEW}
                    >
                        <Typography>Avatar</Typography>
                    </ComponentTooltip>
                    <FormControl sx={{ width: '100%' }}>
                        <Field>
                            {(props) => (
                                <input
                                    id='file-import'
                                    name='avatar'
                                    type='file'
                                    onChange={changeHandler}
                                />
                            )}
                        </Field>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    )
}

TeamForm.propTypes = {
    localization: PropTypes.string,
    InputFieldGroup: PropTypes.array,
    selectGroup: PropTypes.array,
    form: PropTypes.object,
}

export default TeamForm
