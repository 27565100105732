import branchApi from '~/api/branchApi'
import dispatchApi from '~/api/dispatchApi'
import { useQuery } from 'react-query'
import { removeEmptyValue } from '~/utils/helpers'

export const useDispatchesQuery = (params) => {
    return useQuery(
        ['dispatches', params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await dispatchApi.getDispatches(p)
            return { data: data.data, total: data.meta.total }
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useBranchQuery = (params) => {
    return useQuery(
        ['branch_customer', params],
        async () => {
            const { data } = await branchApi.getBranches(params)
            return data
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useDispatchStatusQuery = () => {
    return useQuery(
        ['dispatch_status'],
        async () => {
            const { data } = await dispatchApi.getDispatchStatus()
            return data
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useDispatchUserQuery = () => {
    return useQuery(
        ['dispatch_user'],
        async () => {
            const { data } = await dispatchApi.getDispatchUser()
            return data
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useDispatchStateQuery = () => {
    return useQuery(
        ['dispatch_state'],
        async () => {
            const { data } = await dispatchApi.getDispatchState()
            return data
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}
