export const EASY4U_CUSTOMER_ORDER_NUMBER =
    'https://easy4u.nl/admin/inkoop-orders/bewerken/'

export const EASY4U_TICKET_DEVELOPMENT =
    'https://staging.easy4u.nl/admin/tickets/'

export const EASY4U_TICKET_PRODUCTION = 'https://easy4u.nl/admin/tickets/'

export const COPACO_ORDER_NUMBER = 'https://www.copaco.com/nl-nl/orders/'

export const COPACO_DISPATCH_NUMBER =
    'https://www.copaco.com/Copaco/web/WFS/copaco-Nederland-Site/nl_NL/smb-responsive/EUR/ViewOrders-Document?DocumentID='

export const COPACO_INVOICE_NUMBER =
    'https://www.copaco.com/Copaco/web/WFS/copaco-Nederland-Site/nl_NL/smb-responsive/EUR/ViewOrders-Document?DocumentID='
