import { userApi } from '~/api'
import { useMutation, useQueryClient } from 'react-query'

export const useUpdateSettingUserMutation = () => {
    const queryClient = useQueryClient()
    return useMutation(
        ['update_disable'],
        async ({ id, payload }) => {
            const { data } = await userApi.updateSettingUser(id, { payload })
            return data
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('user')
            },
            onError: () => {},
            retry: false,
        },
    )
}
