import { Grid } from '@mui/material'
import { ADMINISTRATOR_DEFINITION_SERVICECENTER_DETAIL } from '~/constants/Routes'
import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { getValue } from '~/utils/helpers'
import PropTypes from 'prop-types'

const RegionSession = ({ data }) => {
    const navigate = useNavigate()
    const dataList = [
        { title: 'Id', value: data?.id },
        { title: 'Name', value: data?.name },
        {
            title: 'Dell Tech Direct API connector',
            value: data?.dell_dispatch_api?.configurations?.name,
        },
        {
            title: 'Main service center',
            value: data?.main_service_center?.name,
            clickable: true,
            navigateLink: ADMINISTRATOR_DEFINITION_SERVICECENTER_DETAIL.replace(
                ':id',
                data?.main_service_center?.id,
            ),
        },
        { title: 'Description', value: data?.description },
        { title: 'Created at', value: data?.created_at, type: 'date' },
        { title: 'Updated at', value: data?.updated_at, type: 'date' },
    ]

    return (
        <Grid container spacing={3}>
            {dataList.map((item, index) => {
                return (
                    <Fragment key={index}>
                        <Grid item xs={4}>
                            {item.title}
                        </Grid>
                        <Grid item xs={8}>
                            {getValue(navigate, item)}
                        </Grid>
                    </Fragment>
                )
            })}
        </Grid>
    )
}

RegionSession.propTypes = { data: PropTypes.object }

export default RegionSession
