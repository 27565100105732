import { Box } from '@mui/system'

const AsterisksRequiredField = () => {
    return (
        <Box
            component={'span'}
            sx={{
                color: (theme) => theme.palette.error.light,
            }}
        >
            {' *'}
        </Box>
    )
}

export default AsterisksRequiredField
