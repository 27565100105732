import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { useNavigate, useParams } from 'react-router-dom'
import {
    useCreateMutation,
    useDeleteMutation,
    useSaveMutation,
} from '../mutate'
import {
    ADMINISTRATOR_TOOLTIP,
    ADMINISTRATOR_TOOLTIP_DETAIL,
} from '~/constants/Routes'

const useActions = () => {
    const { openSnackbar } = useDialogContext()
    const navigate = useNavigate()
    const { mutate: saveMutate, isLoading } = useSaveMutation()
    const { mutate: deleteMutate, isLoading: isLoadingDelete } =
        useDeleteMutation()
    const { mutate: createMutate, isLoading: isLoadingCreate } =
        useCreateMutation()
    const { id } = useParams()

    const onErrorCallback = (e) => {
        openSnackbar({
            message: e?.response?.data.message,
            type: 'error',
            duration: 10000,
        })
    }

    const saveHandler = (values) => {
        saveMutate(
            { id, ...values },
            {
                onSuccess: (data) => {
                    openSnackbar({ message: data.message, type: data.code })
                },
                onError: onErrorCallback,
            },
        )
    }
    const deleteHandler = () => {
        deleteMutate(id, {
            onSuccess: (data) => {
                navigate(ADMINISTRATOR_TOOLTIP)
                openSnackbar({ message: data.message, type: data.code })
            },
            onError: onErrorCallback,
        })
    }
    const createHandler = (values) => {
        createMutate(values, {
            onSuccess: (data) => {
                openSnackbar({
                    message: `Save successful.`,
                    type: `success`,
                })
                navigate(ADMINISTRATOR_TOOLTIP_DETAIL.replace(':id', data.id))
            },
            onError: onErrorCallback,
        })
    }

    return {
        saveHandler,
        deleteHandler,
        createHandler,
        isLoading: isLoading || isLoadingDelete || isLoadingCreate,
    }
}

export default useActions
