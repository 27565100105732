import { Box, LinearProgress } from '@mui/material'
import Search from '~/components/Search/Search'
import { StyledPaper } from '~/components/StyledPaper'
import CheckListPartCodeType from './CheckListPartCodeType'
import { debounce } from 'lodash'
import { useMemo } from 'react'
import PropTypes from 'prop-types'

const CheckListPartCodeSection = ({
    isLoadingPartCodeTypes,
    renderedPartCodeTypes,
    selectedPartCodeTypes,
    handleToggle,
    setTypeNameFilter,
}) => {
    const searchHandler = useMemo(() => {
        return debounce((event) => {
            if (!(event.target.value.length < 3)) {
                setTypeNameFilter(event.target.value)
            }
        }, 500)
    }, [setTypeNameFilter])

    return (
        <StyledPaper>
            <Search
                label='Search type'
                onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                        event.preventDefault()
                        setTypeNameFilter(event.target.value)
                    }
                }}
                onChange={searchHandler}
            />
            {isLoadingPartCodeTypes ? (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            ) : (
                <CheckListPartCodeType
                    {...{
                        renderedPartCodeTypes,
                        selectedPartCodeTypes,
                        handleToggle,
                    }}
                    isLoading={isLoadingPartCodeTypes}
                />
            )}
        </StyledPaper>
    )
}

CheckListPartCodeSection.propTypes = {
    isLoadingPartCodeTypes: PropTypes.bool,
    renderedPartCodeTypes: PropTypes.arrayOf(PropTypes.object),
    selectedPartCodeTypes: PropTypes.arrayOf(PropTypes.string),
    handleToggle: PropTypes.func,
    setTypeNameFilter: PropTypes.func,
}

export default CheckListPartCodeSection
