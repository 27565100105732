import { axiosClient } from '~/api/base'

class TestDataApi {
    getIssues = (params) => axiosClient.get('api/test-data/issues', { params })

    getTestableDevices = () => axiosClient.get('api/test-data/device/testable')

    diagnosePrefilled = (payload) =>
        axiosClient.post('api/test-data/diagnose/prefilled', payload)

    createShipmentForIssue = (issueId, payload) =>
        axiosClient.post(`api/test-data/issue/${issueId}/shipment`, payload)

    approveQuotation = (issueId, payload) =>
        axiosClient.put(
            `api/test-data/issue/${issueId}/approve-quotation`,
            payload,
        )

    transitBomStateToWmsOrder = (issueId) =>
        axiosClient.post(
            `api/test-data/issue/${issueId}/transit-bom-state-to-wms-order`,
        )
}

export default new TestDataApi()
