import TableFilters from '~/components/TableFilters'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const RegionTableFilters = ({ search, searchHandler, onEnterSearch }) => {
    const selectGroup = useMemo(() => {
        return []
    }, [])
    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='regions'
            page='regions'
        />
    )
}
RegionTableFilters.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    onEnterSearch: PropTypes.func,
}
export default RegionTableFilters
