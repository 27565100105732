import {
    Box,
    Link,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material'
import StyledModal from '~/components/StyledModal'
import { useNavigate } from 'react-router-dom'
import { ISSUE_DISPATCH_DETAIL } from '~/constants/Routes'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const DispatchWarningPopup = ({ open, onClose, dispatches }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    return (
        <StyledModal
            open={open}
            handleClose={onClose}
            stackProps={{
                width: 550,
                gap: 2,
                maxHeight: 200,
                overflow: 'auto',
                overflowX: 'hidden',
                overflowY: 'scroll',
            }}
        >
            {dispatches?.type === 'STATE_AFTER_ISSUED' && (
                <>
                    <Box>{t('general.stateAfterIssued')}</Box>
                    <Box sx={{ width: '100%' }}>
                        <Table size='small'>
                            <TableBody>
                                {dispatches?.dispatches.map((v) => (
                                    <>
                                        <TableRow>
                                            <TableCell>
                                                <Link
                                                    component='button'
                                                    variant='inherit'
                                                    to='#'
                                                    underline='hover'
                                                    onClick={() =>
                                                        navigate(
                                                            ISSUE_DISPATCH_DETAIL.replace(
                                                                ':id',
                                                                v,
                                                            ),
                                                        )
                                                    }
                                                    target='_blank'
                                                >
                                                    <Typography
                                                        variant='body2'
                                                        align='center'
                                                    >
                                                        {t(
                                                            'pages.dispatches.dispatch.overview.dispatchId',
                                                        )}{' '}
                                                        : {v}
                                                    </Typography>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </>
            )}

            {dispatches?.type === 'STATE_BEFORE_ISSUED' && (
                <>
                    <Box>{t('general.stateBeforeIssued')}</Box>
                    <Box sx={{ width: '100%' }}>
                        <Table size='small'>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Link
                                            component='button'
                                            variant='inherit'
                                            to='#'
                                            underline='hover'
                                            onClick={() =>
                                                navigate(
                                                    ISSUE_DISPATCH_DETAIL.replace(
                                                        ':id',
                                                        dispatches?.dispatches,
                                                    ),
                                                )
                                            }
                                            target='_blank'
                                        >
                                            <Typography
                                                variant='body2'
                                                align='center'
                                            >
                                                {t(
                                                    'pages.dispatches.dispatch.overview.dispatchId',
                                                )}{' '}
                                                :{dispatches?.dispatches}
                                            </Typography>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </>
            )}
        </StyledModal>
    )
}

DispatchWarningPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    dispatches: PropTypes.array,
}

export default DispatchWarningPopup
