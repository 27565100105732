import { FormControl, MenuItem, Select } from '@mui/material'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const PartToChangeConditionComponent = ({
    showMaliciousCondition,
    showAccidentalCondition = true,
    handleChange,
    finishDiagnostic,
    condition,
}) => {
    const { t } = useTranslation()
    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 80 }} size='small'>
                <Select
                    onChange={handleChange}
                    autoWidth
                    disabled={finishDiagnostic}
                    value={condition || ''}
                >
                    <MenuItem value='warranty'>
                        {t('pages.diagnostic.warranty')}
                    </MenuItem>
                    {showAccidentalCondition && (
                        <MenuItem value='accidental'>
                            {t('pages.diagnostic.accidental')}
                        </MenuItem>
                    )}
                    {(finishDiagnostic || showMaliciousCondition) && (
                        <MenuItem value='malicious'>
                            {t('pages.diagnostic.malicious')}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </div>
    )
}

PartToChangeConditionComponent.propTypes = {
    showMaliciousCondition: propTypes.bool,
    showAccidentalCondition: propTypes.bool,
    handleChange: propTypes.func,
    finishDiagnostic: propTypes.bool,
    condition: propTypes.string,
}

export default PartToChangeConditionComponent
