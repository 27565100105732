import MenuItem from '@mui/material/MenuItem'
import DownloadIcon from '@mui/icons-material/Download'
import ListToolbarWrapper from '~/components/ListToolbarWrapper'
import { useExportRackTicketMutation } from '../mutation'
import { useTranslation } from 'react-i18next'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import ActionMenu from '~/components/ActionMenu'
import PropTypes from 'prop-types'

const RackTicketListToolbar = ({ payload }) => {
    const { mutate: exportRackTicket, isLoading } =
        useExportRackTicketMutation()
    const { t } = useTranslation()

    return (
        <ListToolbarWrapper>
            <ActionMenu>
                <MenuItem
                    onClick={() => exportRackTicket(payload)}
                    disabled={isLoading}
                >
                    {isLoading ? <HourglassTopIcon /> : <DownloadIcon />}
                    {t('button.export')}
                </MenuItem>
            </ActionMenu>
        </ListToolbarWrapper>
    )
}

RackTicketListToolbar.propTypes = { payload: PropTypes.object }

export default RackTicketListToolbar
