import {
    Table as MuiTable,
    TableBody as MuiTableBody,
    Paper,
    TableContainer,
} from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import { TeamSessionHeadCells } from '~/components/HeaderTable/TeamHeadCells'
import PaginationTable from '~/components/PaginationTable'
import FilterSearch from '~/components/Search/FilterSearch'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import Row from '~/components/TableRow'
import useTable from '~/hooks/useTable'
import { useTranslation } from 'react-i18next'
import { useTeamServiceCenterQuery } from '../query'
import PropTypes from 'prop-types'

const TableBody = ({ isLoading, objects }) => {
    if (isLoading) {
        return <TableLoading colSpan={TeamSessionHeadCells.length} />
    }

    return (
        <MuiTableBody>
            {objects.length > 0 ? (
                objects.map((row, index) => {
                    return (
                        <Row
                            key={index}
                            row={row}
                            columnsConfig={TeamSessionHeadCells}
                        />
                    )
                })
            ) : (
                <DataNotFound colSpan={TeamSessionHeadCells.length} />
            )}
        </MuiTableBody>
    )
}

TableBody.propTypes = {
    isLoading: PropTypes.bool,
    objects: PropTypes.arrayOf(PropTypes.object),
}

const TeamSession = () => {
    const { t } = useTranslation()
    const {
        results,
        data,
        isLoading,
        page,
        order,
        orderBy,
        changePageHandler,
        searchHandler,
        sortHandler,
        onEnterSearch,
    } = useTable({
        query: useTeamServiceCenterQuery,
    })

    return (
        <Paper>
            <FilterSearch
                placeholder={`${t(`general.searchFor`)} ${t(`general.teams`)}`}
                onSearchChange={searchHandler}
                onEnterSearch={onEnterSearch}
            />
            <TableContainer
                sx={{ minWidth: 750 }}
                aria-labelledby='tableTitle'
                size='medium'
            >
                <MuiTable>
                    <TableHeader
                        headCells={TeamSessionHeadCells}
                        localization={
                            'pages.administrator.definition.team.overview.'
                        }
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />
                    <TableBody isLoading={isLoading} objects={results} />
                </MuiTable>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}

export default TeamSession
