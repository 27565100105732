import { useQuery } from 'react-query'
import { removeEmptyValue } from '~/utils/helpers'
import { vendorApi, namingTagApi, componentApi } from '~/api'

export const useNamingTagQuery = (params) => {
    return useQuery(
        ['naming_tags', params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await namingTagApi.getTagList(p)
            return { data: data.data, total: data.meta?.total }
        },
        {
            retry: false,
        },
    )
}
export const useGetAllNamingTagsQuery = () => {
    return useQuery(
        ['naming_tags'],
        async () => {
            const { data } = await namingTagApi.getAllTags()
            if (Array.isArray(data.data) && !data.data?.length) return []
            return data.data.map((tag) => {
                return {
                    name: tag.name,
                    value: tag.id,
                }
            })
        },
        {
            retry: false,
        },
    )
}
export const useTagRuleQuery = (params) => {
    return useQuery(
        ['tag_rules', params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await namingTagApi.getRuleList(p)
            return { data: data.data, total: data.meta?.total }
        },
        {
            retry: false,
        },
    )
}
export const useTagRuleDetailQuery = (id) => {
    return useQuery(
        ['tag_rule_detail', id],
        async () => {
            const { data } = await namingTagApi.getRuleDetail(id)
            return data.data
        },
        {
            retry: false,
        },
    )
}
export const useTagDetailQuery = (id) => {
    return useQuery(
        ['tag_rule_detail', id],
        async () => {
            const { data } = await namingTagApi.getTagDetail(id)
            return data.data
        },
        {
            retry: false,
        },
    )
}

export const useVendorQuery = (id) => {
    return useQuery(
        ['vendors', id],
        async () => {
            const { data } = await vendorApi.getVendors(id)
            if (Array.isArray(data.data) && !data.data?.length) return []
            return data.data.map((vendor) => {
                return { name: vendor.name, value: vendor.id }
            })
        },
        {
            retry: false,
        },
    )
}
export const useComponentQuery = (vendorId) => {
    return useQuery(
        ['components_tag_rule', vendorId],
        async () => {
            const { data } = await componentApi.getComponentsByVendor(vendorId)
            if (Array.isArray(data.data) && !data.data?.length) return []
            return data.data.map((component) => {
                const itemNumber =
                    component.item_numbers && component.item_numbers.length > 0
                        ? component.item_numbers[0].item_number
                        : ''

                return {
                    name: `${component.part_number} -- ${itemNumber}`,
                    value: component.id,
                }
            })
        },
        {
            enabled: !!vendorId,
            retry: false,
        },
    )
}
