import {
    Divider,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import { PurchaseOrderDetailHeadCells } from '~/components/HeaderTable/PurchaseOrderDetailHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { PROCUREMENT_PURCHASEORDER_LINE_DETAIL } from '~/constants/Routes'
import useTable from '~/hooks/useTable'
import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PurchaseOrderTableFilters from '../PurchaseOrderTableFilters'
import { usePurchaseOrderDetailQuery } from '../query'
import PropTypes from 'prop-types'

const localization = 'pages.procurement.purchaseOrder.detail.'

const PurchaseOrderDetailBodyTable = ({ isLoading, PurchaseOrderDetail }) => {
    const navigate = useNavigate()
    const { id } = useParams()

    const directionsTo = useCallback(
        (lineId) => {
            navigate(
                PROCUREMENT_PURCHASEORDER_LINE_DETAIL.replace(
                    ':order_id',
                    id,
                ).replace(':order_line_id', lineId),
            )
        },
        [id, navigate],
    )

    if (isLoading) {
        return <TableLoading colSpan={PurchaseOrderDetailHeadCells.length} />
    }

    return (
        <TableBody>
            {PurchaseOrderDetail.length > 0 ? (
                PurchaseOrderDetail.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                            <TableCell component='th' scope='row'>
                                <Link
                                    component='button'
                                    variant='inherit'
                                    to='#'
                                    underline='hover'
                                    onClick={() => directionsTo(row.id)}
                                >
                                    <Typography variant='body2' align='right'>
                                        {row.id}
                                    </Typography>
                                </Link>
                            </TableCell>
                            <TableCell align='left'>{row.product_id}</TableCell>
                            <TableCell align='left'>{row.product_nr}</TableCell>
                            <TableCell align='left'>
                                {row.supplier_code}
                            </TableCell>
                            <TableCell align='left'>{row.price}</TableCell>
                            <TableCell align='left'>{row.qty}</TableCell>
                            <TableCell align='left'>{row.delivered}</TableCell>
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={PurchaseOrderDetailHeadCells.length} />
            )}
        </TableBody>
    )
}

PurchaseOrderDetailBodyTable.propTypes = {
    isLoading: PropTypes.bool,
    PurchaseOrderDetail: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
}

const PurchaseOrderDetailTable = () => {
    const { id } = useParams()

    const {
        results,
        data,
        isLoading,
        page,
        search,
        order,
        orderBy,
        searchHandler,
        sortHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: usePurchaseOrderDetailQuery,
        queryAttributes: {
            id,
        },
    })

    return (
        <Paper sx={{ width: '100%', mb: 2 }}>
            <PurchaseOrderTableFilters
                search={search}
                searchHandler={searchHandler}
                onEnterSearch={onEnterSearch}
            />
            <Divider />
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby='tableTitle'
                    size='medium'
                >
                    <TableHeader
                        headCells={PurchaseOrderDetailHeadCells}
                        localization={localization}
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />
                    <PurchaseOrderDetailBodyTable
                        isLoading={isLoading}
                        PurchaseOrderDetail={results}
                    />
                </Table>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}
export default PurchaseOrderDetailTable
