import { Card, CardContent, Grid, Typography } from '@mui/material'
import CheckListPartCodeSection from '~/components/partCodes/CheckListPartCodeSection'
import { StyledPaper } from '~/components/StyledPaper'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import PartCodeList from '../components/PartCode/PartCodeList'
import { useModelPartCodeTypeQuery } from '../query'
import PartCodeTable from './PartCodeTable'
import { setCauseModel } from '~/store/cause/slice'
import PropTypes from 'prop-types'
import { removeEmptyValue } from '~/utils/helpers'

const CausePartCodeSection = ({ causeContent, disabled, selectedModelIds }) => {
    const modelId = causeContent.model_id
    const dispatch = useDispatch()
    const [typeNameFilter, setTypeNameFilter] = useState('')
    const [selectedPartCodeTypes, setSelectedPartCodeTypes] = useState([])
    const {
        data: partCodeType,
        isLoading: isLoadingPartCodeTypes,
        isFetched,
    } = useModelPartCodeTypeQuery(
        removeEmptyValue({ type: typeNameFilter, model: modelId }),
    )

    useEffect(() => {
        if (isFetched) {
            dispatch(setCauseModel(modelId))
        }
    }, [dispatch, modelId, isFetched])

    const renderedPartCodeTypes = useMemo(() => {
        if (isLoadingPartCodeTypes || !partCodeType?.types) return []
        return partCodeType.types.map((type) => {
            return { name: type.type, label: type.type }
        })
    }, [partCodeType, isLoadingPartCodeTypes])

    const handleToggle = (e, code) => {
        if (e.target.checked) {
            setSelectedPartCodeTypes([...selectedPartCodeTypes, code.name])
            return
        }
        const currentSelectedPartCodeTypes = selectedPartCodeTypes.filter(
            (type) => type !== code.name,
        )
        setSelectedPartCodeTypes(currentSelectedPartCodeTypes)
    }

    return (
        <Card>
            <CardContent>
                <Typography variant='h6' gutterBottom>
                    Part code
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <PartCodeTable disabled={disabled} />
                    </Grid>
                </Grid>
                {disabled ? (
                    <></>
                ) : (
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <CheckListPartCodeSection
                                {...{
                                    isLoadingPartCodeTypes,
                                    renderedPartCodeTypes,
                                    selectedPartCodeTypes,
                                    handleToggle,
                                    setTypeNameFilter,
                                }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <StyledPaper>
                                <PartCodeList
                                    {...{
                                        selectedPartCodeTypes,
                                        causeContent,
                                        selectedModelIds,
                                    }}
                                />
                            </StyledPaper>
                        </Grid>
                    </Grid>
                )}
            </CardContent>
        </Card>
    )
}

CausePartCodeSection.propTypes = {
    causeContent: PropTypes.object,
    disabled: PropTypes.bool,
    selectedModelIds: PropTypes.array,
}

CausePartCodeSection.defaultProps = {
    selectedModelIds: [],
}

export default CausePartCodeSection
