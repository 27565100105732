import { useMemo } from 'react'
import Proptypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import { Field } from 'react-final-form'
import FormInput from '../FormInput/FormInput'
import { useTranslation } from 'react-i18next'
import ComponentTooltip from '~/components/ComponentTooltip'
import AsterisksRequiredField from '~/components/FormInput/AsterisksRequiredField'

const InputField = ({
    localization,
    name,
    label,
    component,
    disabled,
    isRequired,
    autoFocus,
    pagePath,
    componentId,
    ...props
}) => {
    const { t } = useTranslation()

    const title = useMemo(
        () => (
            <ComponentTooltip componentId={componentId} pagePath={pagePath}>
                <Typography>
                    {label
                        ? t(`${localization}.` + label)
                        : t(`${localization}.` + name)}
                    {isRequired && <AsterisksRequiredField />}
                </Typography>
            </ComponentTooltip>
        ),
        [isRequired, label, localization, name, componentId, pagePath, t],
    )

    return (
        <Grid item xs={12}>
            {title}
            <Field
                {...props}
                name={name}
                component={component}
                disabled={disabled}
                autoFocus={autoFocus}
            />
        </Grid>
    )
}

InputField.defaultProps = {
    component: FormInput,
}

InputField.propTypes = {
    localization: Proptypes.string.isRequired,
    name: Proptypes.string,
    label: Proptypes.string,
    component: Proptypes.oneOfType([Proptypes.object, Proptypes.func]),
    disabled: Proptypes.bool,
    isRequired: Proptypes.bool,
    autoFocus: Proptypes.bool,
    componentId: Proptypes.string,
    pagePath: Proptypes.string,
}

export default InputField
