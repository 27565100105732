import { ADMINISTRATOR_DATASCHEDULER_DETAIL } from '~/constants/Routes'

export const ExportSchedulerHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
        clickable: true,
        link: ADMINISTRATOR_DATASCHEDULER_DETAIL,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        orderable: true,
    },
    {
        id: 'method',
        numeric: false,
        disablePadding: false,
        label: 'method',
        orderable: false,
    },
    {
        id: 'cron',
        numeric: false,
        disablePadding: false,
        label: 'cron',
        orderable: false,
    },
]
