export const DispatchHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
    },
    {
        id: 'serial',
        numeric: true,
        disablePadding: false,
        label: 'Serial',
        orderable: true,
    },
    {
        id: 'ticket_id',
        numeric: true,
        disablePadding: false,
        label: 'ticket',
        orderable: true,
    },
    {
        id: 'esd_location',
        numeric: true,
        disablePadding: false,
        label: 'esdLocation',
        orderable: true,
    },
    {
        id: 'dispatch_code',
        numeric: true,
        disablePadding: false,
        label: 'Code',
        orderable: true,
    },
    {
        id: 'state',
        disablePadding: false,
        label: 'State',
        orderable: true,
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
        orderable: true,
    },
    {
        id: 'service_center_name',
        numeric: true,
        disablePadding: false,
        label: 'serviceCenter',
        orderable: true,
    },
    {
        id: 'branch_name',
        numeric: true,
        disablePadding: false,
        label: 'Branch',
        orderable: true,
    },
    {
        id: 'user',
        numeric: true,
        disablePadding: false,
        label: 'User',
        orderable: true,
        align: 'center',
    },
    {
        id: 'created_manually',
        numeric: false,
        disablePadding: false,
        label: 'CreatedManually',
        orderable: true,
        align: 'center',
    },
    {
        id: 'created_at',
        numeric: true,
        disablePadding: false,
        label: 'Created',
        orderable: true,
    },
    {
        id: 'updated_at',
        numeric: true,
        disablePadding: false,
        label: 'Updated',
        orderable: true,
    },
]
