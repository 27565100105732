import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectPurchaseOrderLine } from '~/store/purchaseOrder/selector'
import { capitalize } from '~/utils/helpers'

const OrderSpecificationSession = () => {
    const purchaseOrderLine = useSelector(selectPurchaseOrderLine)

    return (
        <Grid container spacing={3}>
            <Grid item xs={4}>
                Specification Id
            </Grid>
            <Grid item xs={8}>
                {purchaseOrderLine?.purchase_order_specification?.id}
            </Grid>
            <Grid item xs={4}>
                Screen diameter
            </Grid>
            <Grid item xs={8}>
                {
                    purchaseOrderLine?.purchase_order_specification
                        ?.screendiameter
                }
            </Grid>
            <Grid item xs={4}>
                Touch screen
            </Grid>
            <Grid item xs={8}>
                {capitalize(
                    (!!purchaseOrderLine?.purchase_order_specification
                        ?.touch).toString(),
                )}
            </Grid>
            <Grid item xs={4}>
                Processor name
            </Grid>
            <Grid item xs={8}>
                {
                    purchaseOrderLine?.purchase_order_specification?.processor
                        .name
                }
            </Grid>
            <Grid item xs={4}>
                Processor brand
            </Grid>
            <Grid item xs={8}>
                {
                    purchaseOrderLine?.purchase_order_specification?.processor
                        .brand
                }
            </Grid>
            <Grid item xs={4}>
                Memory size
            </Grid>
            <Grid item xs={8}>
                {purchaseOrderLine?.purchase_order_specification?.memory_size}
            </Grid>
            <Grid item xs={4}>
                Disk space
            </Grid>
            <Grid item xs={8}>
                {purchaseOrderLine?.purchase_order_specification?.diskspace}
            </Grid>
        </Grid>
    )
}

export default OrderSpecificationSession
