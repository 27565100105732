import { useSnackbar } from '~/hooks/useSnackbar'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useUpdateSettingUserMutation } from './mutation'
import SettingForm from './SettingForm'
import PropTypes from 'prop-types'
import { setUserTimeZone } from '~/store/auth/slice'
import { useDispatch } from 'react-redux'

const Setting = ({ user }) => {
    const { t } = useTranslation()
    const { id } = useParams()
    const dispatch = useDispatch()
    const { showSnackbar, snackbar } = useSnackbar()

    const { mutate: updateSettingUser, isLoading } =
        useUpdateSettingUserMutation()

    const initial = useMemo(
        () => ({
            locale: user.locale_code,
            timezone: user.timezone,
        }),
        [user.locale_code, user.timezone],
    )
    const submit = useCallback(
        (payload) => {
            updateSettingUser(
                { id, payload },
                {
                    onSuccess: () => {
                        showSnackbar(t(`message.updateSuccess`), 'success')
                        dispatch(setUserTimeZone(payload?.timezone))
                    },
                    onError: (e) => {
                        showSnackbar(
                            e.response?.data?.errors
                                ? e.response?.data?.message
                                : '',
                            'error',
                        )
                    },
                },
            )
        },

        [dispatch, id, showSnackbar, t, updateSettingUser],
    )

    const onSubmit = useCallback(
        (values) => {
            submit({
                locale: values.locale,
                timezone: values.timezone,
            })
        },
        [submit],
    )
    return (
        <>
            {snackbar}
            <SettingForm
                onSubmit={onSubmit}
                isLoading={isLoading}
                name='Update'
                initialValues={initial}
            />
        </>
    )
}

Setting.propTypes = { user: PropTypes.object }

export default Setting
