import productApi from '~/api/productApi'
import { useQuery } from 'react-query'
import { removeEmptyValue } from '~/utils/helpers'

export const useProductsListQuery = (params) => {
    return useQuery(
        ['products_list', params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await productApi.getList(p)
            return { data: data.data, total: data.meta?.total }
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}
