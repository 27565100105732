import { Stack, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import useTooltipTitle from '~/hooks/useTooltipTitle'

const ComponentTooltip = ({
    children,
    componentId,
    pagePath,
    align = 'left',
    placement = 'right',
    noStack = false,
}) => {
    const { titleTooltip } = useTooltipTitle(componentId, pagePath)

    if (noStack) {
        return (
            <Tooltip arrow title={titleTooltip} placement={placement}>
                <span>{children}</span>
            </Tooltip>
        )
    }

    return (
        <Stack
            direction='row'
            justifyContent={align}
            alignItems='center'
            spacing={2}
            sx={{
                marginBottom: 1,
            }}
        >
            <Tooltip arrow title={titleTooltip} placement={placement}>
                {children}
            </Tooltip>
        </Stack>
    )
}

ComponentTooltip.propTypes = {
    componentId: PropTypes.string.isRequired,
    pagePath: PropTypes.string.isRequired,
    children: PropTypes.node,
    align: PropTypes.string,
    placement: PropTypes.string,
    noStack: PropTypes.bool,
}

export default ComponentTooltip
