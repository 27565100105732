import { TableBody, TableHead, TableRow } from '@mui/material'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ComponentTooltip from '~/components/ComponentTooltip'
import { DIAGNOSTIC } from '~/constants/Routes'
import StyledRadio from './components/StyledRadio'
import StyledTableRow from './components/StyledTableRow'
import TableCell from './components/TableCell'
import { pushShowPartCodesForDiagnostic } from '~/store/partcode/slice'

import {
    ACCIDENTIAL_DAMAGE,
    CHECKBOX_DIAGNOSTICS,
    COMPLAINT_FOUND,
    LIQUID_DAMAGE,
    LIQUID_DAMAGE_TOTAL_LOSS,
    LOCALIZATION,
    MALICIOUS_DAMAGE,
    POWERS_ON,
    SOFTWARE_ISSUE,
} from './constants'
import { selectShowedPartCodeForDiagnostic } from '~/store/partcode/selector'
import usePermissions from '~/hooks/usePermission'

const CheckboxesSection = ({
    setShowDiagnosticsSection,
    setShowAccidentalCondition,
    setDisabledNextButton,
    form,
    values,
    finishDiagnostic,
    setShowMaliciousCondition,
    setIsWaterTotalDamaged,
    setDiagnosticSaveButton,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const showingPartCodes = useSelector(selectShowedPartCodeForDiagnostic)

    const [diagnosticsList, setDiagnosticsList] = useState([])
    const [showTotalLoss, setShowTotalLoss] = useState(false)
    const [showMaliciousDamage, setShowMaliciousDamage] = useState(false)
    const { permissions } = usePermissions()

    const hasAccidentalDamagePermission =
        permissions.issue.diagnostic.canCheckAccidentalDamge

    const hasMaliciousDamagePermission =
        permissions.issue.diagnostic.canCheckMaliciousDamge

    const checkToShowComplaintFound = useCallback((newForm) => {
        const powersOn = newForm?.getFieldState(POWERS_ON)?.value
        const softwareIssue = newForm?.getFieldState(SOFTWARE_ISSUE)?.value
        const liquidDamage = newForm?.getFieldState(LIQUID_DAMAGE)?.value
        const accidentalDamage =
            newForm?.getFieldState(ACCIDENTIAL_DAMAGE)?.value
        const maliciousDamage = newForm?.getFieldState(MALICIOUS_DAMAGE)?.value

        const isPowersOn = !!(
            powersOn === 'true' ||
            powersOn === true ||
            powersOn === 1
        )
        const isSoftwareIssue = !(
            softwareIssue === 'false' ||
            softwareIssue === false ||
            softwareIssue === 0
        )
        const isLiquidDamage = !(
            liquidDamage === 'false' ||
            liquidDamage === false ||
            liquidDamage === 0
        )
        const isAccidentalDamage = !(
            accidentalDamage === 'false' ||
            accidentalDamage === false ||
            accidentalDamage === 0
        )
        const isMaliciousDamage = !(
            maliciousDamage === 'false' ||
            maliciousDamage === false ||
            maliciousDamage === 0
        )

        return (
            isPowersOn === true &&
            isSoftwareIssue === false &&
            isLiquidDamage === false &&
            isAccidentalDamage === false &&
            isMaliciousDamage === false
        )
    }, [])

    const handleClick = useCallback(
        (e) => {
            if (!e) return

            if (e.target.name === POWERS_ON) {
                setShowDiagnosticsSection(e.target.value === 'true')
            }

            if (e.target.name === LIQUID_DAMAGE) {
                if (e.target.value === 'true') {
                    setShowTotalLoss(true)
                }

                if (e.target.value === 'false') {
                    setShowTotalLoss(false)
                    setIsWaterTotalDamaged(false)
                    form.change(LIQUID_DAMAGE_TOTAL_LOSS, null)
                }
            }

            if (e.target.name === ACCIDENTIAL_DAMAGE) {
                if (e.target.value === 'true') {
                    setShowMaliciousCondition(false)
                    setShowMaliciousDamage(false)
                    form.change(MALICIOUS_DAMAGE, null)
                }

                if (e.target.value === 'false') {
                    setShowMaliciousCondition(true)
                    setShowMaliciousDamage(true)
                }
            }

            if (e.target.name === LIQUID_DAMAGE_TOTAL_LOSS) {
                setIsWaterTotalDamaged(e.target.value === 'true')
            }

            let diagnosticsArray = []

            if (!diagnosticsList.includes(e.target.name)) {
                diagnosticsArray = [...diagnosticsList, e.target.name]
                setDiagnosticsList(diagnosticsArray)
            }

            if (e.target.name === MALICIOUS_DAMAGE) {
                if (e.target.value === 'true') {
                    setShowMaliciousCondition(true)
                }

                if (e.target.value === 'false') {
                    setShowMaliciousCondition(false)
                    const updatedShowingPartCodes = showingPartCodes.map(
                        (obj) => {
                            if (obj.condition === 'malicious') {
                                return {
                                    ...obj,
                                    condition: null,
                                }
                            }
                            return obj
                        },
                    )
                    dispatch(
                        pushShowPartCodesForDiagnostic(updatedShowingPartCodes),
                    )
                }
            }

            if (
                !showTotalLoss &&
                diagnosticsArray.length === CHECKBOX_DIAGNOSTICS.length - 1
            ) {
                setDisabledNextButton(false)
            }
            form.change(e.target.name, e.target.value)

            if (checkToShowComplaintFound(form)) {
                form.change(COMPLAINT_FOUND, 'false')
            }

            if (!checkToShowComplaintFound(form)) {
                form.change(COMPLAINT_FOUND, null)
            }
        },
        [
            checkToShowComplaintFound,
            diagnosticsList,
            dispatch,
            form,
            setDisabledNextButton,
            setShowMaliciousCondition,
            setIsWaterTotalDamaged,
            setShowDiagnosticsSection,
            showTotalLoss,
            showingPartCodes,
        ],
    )

    const showComplaintFound = useMemo(() => {
        return checkToShowComplaintFound(form)
    }, [checkToShowComplaintFound, form])

    const displayedCheckBoxes = useMemo(() => {
        let checkboxes = CHECKBOX_DIAGNOSTICS

        if (!showTotalLoss) {
            checkboxes = checkboxes.filter(
                (x) => x !== LIQUID_DAMAGE_TOTAL_LOSS,
            )
        }

        if (!showMaliciousDamage) {
            checkboxes = checkboxes.filter((x) => x !== MALICIOUS_DAMAGE)
        }

        if (!showComplaintFound) {
            checkboxes = checkboxes.filter((x) => x !== COMPLAINT_FOUND)
        }

        return checkboxes
    }, [showTotalLoss, showMaliciousDamage, showComplaintFound])

    useEffect(() => {
        const hasAccidentalPermission =
            (values[ACCIDENTIAL_DAMAGE] === true ||
                values[ACCIDENTIAL_DAMAGE] === 'true') &&
            !hasAccidentalDamagePermission

        const hasMaliciousPermission =
            (values[MALICIOUS_DAMAGE] === true ||
                values[MALICIOUS_DAMAGE] === 'true') &&
            !hasMaliciousDamagePermission

        const isStillStepDiagnostic = displayedCheckBoxes.some(
            (diagnostic) => values[diagnostic] === null,
        )

        const finalActiveButton = [
            isStillStepDiagnostic,
            hasAccidentalPermission,
            hasMaliciousPermission,
        ].some((item) => item === true)

        setDisabledNextButton(finalActiveButton)
        setDiagnosticSaveButton(finalActiveButton)
    }, [
        setDisabledNextButton,
        values,
        displayedCheckBoxes,
        hasAccidentalDamagePermission,
        hasMaliciousDamagePermission,
        setDiagnosticSaveButton,
    ])

    const loadTotalLoss = useCallback(() => {
        if (
            (values[LIQUID_DAMAGE] === true ||
                values[LIQUID_DAMAGE] === 'true') &&
            values[LIQUID_DAMAGE_TOTAL_LOSS] !== undefined
        ) {
            setShowTotalLoss(true)
        }
        if (
            values[LIQUID_DAMAGE] === false ||
            values[LIQUID_DAMAGE] === 'false'
        ) {
            setShowTotalLoss(false)
        }
    }, [values])

    useEffect(() => {
        loadTotalLoss()
    }, [loadTotalLoss])

    const loadMaliciousDamage = useCallback(() => {
        if (
            values[MALICIOUS_DAMAGE] === false ||
            values[MALICIOUS_DAMAGE] === 'false'
        ) {
            setShowMaliciousCondition(false)
        }

        if (
            values[MALICIOUS_DAMAGE] === true ||
            values[MALICIOUS_DAMAGE] === 'true'
        ) {
            setShowMaliciousCondition(true)
        }
    }, [setShowMaliciousCondition, values])

    useEffect(() => {
        loadMaliciousDamage()
    }, [loadMaliciousDamage])

    const loadAccidentalDamage = useCallback(() => {
        if (
            values[ACCIDENTIAL_DAMAGE] === true ||
            values[ACCIDENTIAL_DAMAGE] === 'true'
        ) {
            setShowMaliciousDamage(false)
            setShowMaliciousCondition(false)
        }
        if (
            (values[ACCIDENTIAL_DAMAGE] === false ||
                values[ACCIDENTIAL_DAMAGE] === 'false') &&
            values[MALICIOUS_DAMAGE] !== undefined
        ) {
            setShowMaliciousDamage(true)
        }
    }, [setShowMaliciousCondition, values])

    useEffect(() => {
        loadAccidentalDamage()
    }, [loadAccidentalDamage])

    const loadAccidentalCondition = useCallback(() => {
        setShowAccidentalCondition(true)
        if (
            (values[ACCIDENTIAL_DAMAGE] === false ||
                values[ACCIDENTIAL_DAMAGE] === 'false') &&
            (values[MALICIOUS_DAMAGE] === false ||
                values[MALICIOUS_DAMAGE] === 'false')
        ) {
            setShowAccidentalCondition(false)
        }
    }, [setShowAccidentalCondition, values])

    useEffect(() => {
        loadAccidentalCondition()
    }, [loadAccidentalCondition])

    return (
        <TableContainer
            sx={{
                borderTop: '0.5px solid lightgray',
            }}
        >
            <Table>
                <TableHead>
                    <TableRow
                        sx={{
                            backgroundColor: '#f2f2f2',
                        }}
                    >
                        <TableCell
                            sx={{
                                border: '0.5px solid lightgray !important',
                            }}
                        >
                            {t(LOCALIZATION + `device`)}
                        </TableCell>
                        <TableCell
                            sx={{
                                border: '0.5px solid lightgray !important',
                            }}
                        >
                            {t(LOCALIZATION + `yes`)}
                        </TableCell>
                        <TableCell
                            sx={{
                                border: '0.5px solid lightgray !important',
                            }}
                        >
                            {t(LOCALIZATION + `no`)}
                        </TableCell>
                        <TableCell
                            sx={{
                                border: '0.5px solid lightgray !important',
                            }}
                        >
                            N/A
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {displayedCheckBoxes.map((item) => (
                        <StyledTableRow key={item}>
                            <TableCell>
                                <ComponentTooltip
                                    componentId={`diagnostics_device_information_${item}`}
                                    pagePath={DIAGNOSTIC}
                                    placement='top'
                                >
                                    <div>{t(LOCALIZATION + `${item}`)}</div>
                                </ComponentTooltip>
                            </TableCell>
                            <CheckboxesCells
                                name={item}
                                {...{
                                    values,
                                    handleClick,
                                    finishDiagnostic,
                                    hasAccidentalDamagePermission,
                                    hasMaliciousDamagePermission,
                                }}
                            />
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

CheckboxesSection.propTypes = {
    setShowDiagnosticsSection: PropTypes.func,
    setShowAccidentalCondition: PropTypes.func,
    setDisabledNextButton: PropTypes.func,
    form: PropTypes.object,
    values: PropTypes.object,
    finishDiagnostic: PropTypes.bool,
    setShowMaliciousCondition: PropTypes.func,
    setIsWaterTotalDamaged: PropTypes.func,
    setDiagnosticSaveButton: PropTypes.func,
}

const CheckboxesCells = ({ name, values, handleClick, finishDiagnostic }) => {
    return (
        <>
            <TableCell>
                <Field
                    disabled={finishDiagnostic}
                    name={name}
                    type='radio'
                    value={'true'}
                    checked={
                        values[name] === 'true' ||
                        values[name] === true ||
                        values[name] === 1
                    }
                    component={StyledRadio}
                    onClick={handleClick}
                />
            </TableCell>
            <TableCell>
                <Field
                    disabled={finishDiagnostic}
                    name={name}
                    type='radio'
                    value={'false'}
                    checked={
                        values[name] === 'false' ||
                        values[name] === false ||
                        values[name] === 0
                    }
                    component={StyledRadio}
                    onClick={handleClick}
                />
            </TableCell>
            <TableCell>
                {name === SOFTWARE_ISSUE && (
                    <Field
                        disabled={finishDiagnostic}
                        name={name}
                        type='radio'
                        value={'n/a'}
                        checked={values[name] === 'n/a' || values[name] === -1}
                        component={StyledRadio}
                        onClick={handleClick}
                    />
                )}
            </TableCell>
        </>
    )
}

CheckboxesCells.propTypes = {
    name: PropTypes.string,
    values: PropTypes.object,
    handleClick: PropTypes.func,
    finishDiagnostic: PropTypes.bool,
}

export default CheckboxesSection
