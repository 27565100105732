export const qcFormCriteria = [
    {
        name: 'serviceSticker',
        NA: false,
        leftSide: true,
    },
    {
        name: 'housing',
        NA: false,
        leftSide: true,
    },
    {
        name: 'screws',
        NA: false,
        leftSide: true,
    },
    {
        name: 'hinges',
        NA: false,
        leftSide: true,
    },
    {
        name: 'dcIn',
        NA: false,
        leftSide: false,
    },
    {
        name: 'opratingSystem',
        NA: false,
        leftSide: true,
    },
    {
        name: 'battery',
        NA: false,
        leftSide: false,
    },
    {
        name: 'display',
        NA: false,
        leftSide: false,
    },
    {
        name: 'touchScreen',
        NA: true,
        leftSide: false,
    },
    {
        name: 'tabletMode',
        NA: true,
        leftSide: false,
    },
    {
        name: 'keyboard',
        NA: false,
        leftSide: false,
    },
    {
        name: 'touchPad',
        NA: false,
        leftSide: false,
    },
    {
        name: 'webcam',
        NA: false,
        leftSide: false,
    },
    {
        name: 'wfc',
        NA: true,
        leftSide: false,
    },
    {
        name: 'microphone',
        NA: false,
        leftSide: false,
    },
    {
        name: 'speaker',
        NA: false,
        leftSide: false,
    },
    {
        name: 'audioJack',
        NA: false,
        leftSide: false,
    },
    {
        name: 'otherButton',
        NA: true,
        leftSide: false,
    },
    {
        name: 'wifi',
        NA: false,
        leftSide: false,
    },
    {
        name: 'usbA',
        NA: true,
        leftSide: false,
    },
    {
        name: 'usbC',
        NA: true,
        leftSide: false,
    },
    {
        name: 'domainEnrollment',
        NA: true,
        leftSide: false,
    },
    {
        name: 'systemCleaned',
        NA: false,
        leftSide: true,
    },
]
