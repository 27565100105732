import TableFilters from '~/components/TableFilters'
import { useMemo } from 'react'
import { useDeviceStateQuery, useVendorsQuery } from './query'
import PropTypes from 'prop-types'

const SerialTableFilters = ({
    search,
    searchHandler,
    vendor,
    setVendor,
    state,
    setState,
    onEnterSearch,
}) => {
    const { data: vendorData, isSuccess: isSuccessVendor } = useVendorsQuery()

    const { data: deviceStateData, isSuccess: isSuccessDeviceState } =
        useDeviceStateQuery({
            menuOption: true,
        })

    const vendors = useMemo(() => {
        if (isSuccessVendor) {
            const result = vendorData?.data
            result.unshift({ id: '', name: 'ALL' })
            return result
        }
        return []
    }, [isSuccessVendor, vendorData?.data])

    const states = useMemo(() => {
        if (isSuccessDeviceState) {
            const result = deviceStateData?.data.map(function (obj) {
                return { ...obj, id: obj.tid, name: obj.tid }
            })
            result.unshift({ id: '', name: 'ALL' })
            return result
        }
        return []
    }, [deviceStateData?.data, isSuccessDeviceState])

    const selectGroup = useMemo(() => {
        return [
            {
                label: 'vendor',
                handleFunc: setVendor,
                dataSet: vendors,
                value: vendor,
            },
            {
                label: 'state',
                handleFunc: setState,
                dataSet: states,
                value: state,
            },
        ]
    }, [setState, setVendor, state, states, vendor, vendors])

    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='serials'
            page='serials'
        />
    )
}

SerialTableFilters.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    vendor: PropTypes.string,
    setVendor: PropTypes.func,
    state: PropTypes.string,
    setState: PropTypes.func,
    onEnterSearch: PropTypes.func,
}

export default SerialTableFilters
