import { Paper, Table, TableBody, TableContainer } from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import { DeviceReplacePartHeadCells } from '~/components/HeaderTable/DeviceReplacePartHeadCells'
import PaginationTable from '~/components/PaginationTable'
import FilterSearch from '~/components/Search/FilterSearch'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useCausePartCodeQuery } from '../query'

import useTable from '~/hooks/useTable'
import { useDispatch } from 'react-redux'
import { setCurrentCausePartCode } from '~/store/partcode/slice'
import PartCodeTableComponent from './PartCodeTableComponent'
import PropTypes from 'prop-types'

const localization = 'pages.device.serial.detail.'
const CausePartTableBody = ({
    isLoading,
    causePartCode,
    partCodeTableHeader,
    disabled,
}) => {
    if (isLoading) {
        return <TableLoading colSpan={partCodeTableHeader.length} />
    }
    return (
        <TableBody>
            {causePartCode.length >= 1 ? (
                causePartCode?.map((partCode) => (
                    <PartCodeTableComponent
                        key={partCode.code}
                        partCode={partCode}
                        disabled={disabled}
                    />
                ))
            ) : (
                <DataNotFound colSpan={partCodeTableHeader.length} />
            )}
        </TableBody>
    )
}

CausePartTableBody.propTypes = {
    isLoading: PropTypes.bool,
    causePartCode: PropTypes.array,
    partCodeTableHeader: PropTypes.array,
    disabled: PropTypes.bool,
}

const PartCodeTable = ({ disabled }) => {
    const { id } = useParams()

    const cause = useMemo(() => id, [id])

    const {
        data,
        isLoading,
        results,
        order,
        orderBy,
        page,
        sortHandler,
        searchHandler,
        changePageHandler,
        isSuccess,
        onEnterSearch,
    } = useTable({
        query: useCausePartCodeQuery,
        initialStates: {
            page: 1,
            search: '',
        },
        queryAttributes: {
            cause,
        },
    })

    const { t } = useTranslation()
    const dispatch = useDispatch()

    const causePartCode = useMemo(() => {
        if (isSuccess) {
            dispatch(setCurrentCausePartCode(results))
            return results
        }
        return []
    }, [dispatch, isSuccess, results])

    const partCodeTableHeader = useMemo(() => {
        let header = [
            ...DeviceReplacePartHeadCells,
            {
                id: 'action',
                numeric: true,
                disablePadding: false,
                label: 'action',
                orderable: true,
            },
        ]

        if (disabled) {
            header = [...DeviceReplacePartHeadCells]
        }
        return header
    }, [disabled])

    return (
        <>
            <FilterSearch
                placeholder={`${t(`general.searchFor`)} ${t(
                    `general.partCode`,
                )}`}
                onSearchChange={searchHandler}
                onEnterSearch={onEnterSearch}
            />
            <Paper>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                        <TableHeader
                            headCells={partCodeTableHeader}
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                            localization={localization}
                        />
                        <CausePartTableBody
                            {...{
                                isLoading,
                                causePartCode,
                                partCodeTableHeader,
                                disabled,
                            }}
                        />
                    </Table>
                </TableContainer>
                <PaginationTable
                    data={data}
                    page={page}
                    size='small'
                    handleChangePage={changePageHandler}
                />
            </Paper>
        </>
    )
}
PartCodeTable.propTypes = {
    disabled: PropTypes.bool,
}

export default PartCodeTable
