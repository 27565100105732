import axiosClient from '~/api/base'

class AddressApi {
    getAddresses = (params) => {
        return axiosClient.get('api/addresses', { params })
    }

    createAddress = (id, payload) => {
        return axiosClient.post('api/addresses', payload)
    }
}
export default new AddressApi()
