import { Box, IconButton, Stack } from '@mui/material'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import PartCodeEditPopup from './PartCodeEditPopup'
import { useState } from 'react'
import PropTypes from 'prop-types'

const PartCodeDisplayer = ({ partCode = null, componentId }) => {
    const [openEditPopup, setOpenEditPopup] = useState(false)

    return (
        <>
            <Box>
                <Stack direction='row' alignItems='center' gap={1}>
                    <Box>{partCode ?? ''}</Box>
                    <IconButton
                        size='small'
                        onClick={() => setOpenEditPopup(true)}
                    >
                        {partCode ? (
                            <BorderColorIcon color='primary' />
                        ) : (
                            <AddCircleIcon color='primary' />
                        )}
                    </IconButton>
                </Stack>
            </Box>

            {openEditPopup && (
                <PartCodeEditPopup
                    open
                    onClose={() => setOpenEditPopup(false)}
                    partCode={partCode}
                    componentId={componentId}
                />
            )}
        </>
    )
}

PartCodeDisplayer.propTypes = {
    partCode: PropTypes.string,
    componentId: PropTypes.number,
}

export default PartCodeDisplayer
