import propTypes from 'prop-types'
import { useMemo } from 'react'
import TableFilters from '~/components/TableFilters'

const AddonTableSearchAndFilter = ({
    search,
    searchHandler,
    onEnterSearch,
    setUser,
    user,
    userQuery,
    isUserQuerySuccess,
}) => {
    const userData = useMemo(() => {
        if (isUserQuerySuccess) {
            const result = userQuery?.data?.map((item) => ({
                id: item?.name,
                name: item?.name,
            }))
            result.unshift({ id: '', name: 'All' })
            return result
        }
        return []
    }, [isUserQuerySuccess, userQuery])

    const selectGroup = useMemo(() => {
        return [
            {
                label: 'User',
                handleFunc: setUser,
                dataSet: userData,
                value: user,
            },
        ]
    }, [user, userData, setUser])

    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='Addons'
            page='Addons'
        />
    )
}

AddonTableSearchAndFilter.propTypes = {
    search: propTypes.string,
    searchHandler: propTypes.func,
    onEnterSearch: propTypes.func,
    setUser: propTypes.func,
    user: propTypes.string,
    userQuery: propTypes.any,
    isUserQuerySuccess: propTypes.bool,
}

export default AddonTableSearchAndFilter
