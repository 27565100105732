import propTypes from 'prop-types'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

const TypographyTitleAccordion = styled(Typography)(({ theme }) => ({
    color: `${theme.palette.blue[100]}`,
    transition: '0.5s',
    '&:hover': {
        color: `${theme.palette.green[100]}`,
    },
}))

const AccordionDevice = ({ item, accordions, handleChange, localization }) => {
    const { t } = useTranslation()
    return (
        <Accordion
            elevation={0}
            sx={{ border: '1px solid rgb(230, 235, 241)', mt: 3 }}
            expanded={accordions.includes(item.index)}
            onChange={handleChange(item.index)}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel2a-content'
                id='panel2a-header'
            >
                <TypographyTitleAccordion variant='h6'>
                    {t(`${localization}.${item.title}`)}
                </TypographyTitleAccordion>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>{item.content}</AccordionDetails>
        </Accordion>
    )
}

AccordionDevice.propTypes = {
    item: propTypes.object,
    accordions: propTypes.array,
    handleChange: propTypes.func,
    localization: propTypes.string,
}

export default AccordionDevice
