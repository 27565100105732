import RadioInput from './RadioInput'
import FormRadioGroup from '../FormRadioGroup'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

const SingleRadioField = ({ name, defaultValue, options, onUpdate, style }) => {
    return (
        <FormRadioGroup {...{ defaultValue, style }}>
            {options.map((option, key) => {
                return (
                    <Field
                        key={key}
                        type='radio'
                        component={RadioInput}
                        label={String(option.value)}
                        {...{ onUpdate, key, name }}
                        {...option}
                    />
                )
            })}
        </FormRadioGroup>
    )
}

SingleRadioField.propTypes = {
    name: PropTypes.string,
    defaultValue: PropTypes.any,
    options: PropTypes.arrayOf(PropTypes.object),
    onUpdate: PropTypes.func,
    style: PropTypes.any,
}

export default SingleRadioField
