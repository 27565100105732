import { FormControl, Grid } from '@mui/material'
import InputField from '~/components/FinalForm/InputField'
import SelectField from '~/components/FinalForm/SelectField'
import { ADMINISTRATOR_DEFINITION_TEAM_NEW } from '~/constants/Routes'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import useWorkbenchFormData from './hooks/useWorkbenchFormData'
const pagePath = ADMINISTRATOR_DEFINITION_TEAM_NEW

const WorkbenchForm = () => {
    const { t } = useTranslation()
    const { InputFieldGroup, selectGroup, localization } =
        useWorkbenchFormData()

    return (
        <Grid container spacing={2}>
            {selectGroup.map((item, index) => {
                return (
                    <Grid item xs={12} key={index}>
                        <FormControl sx={{ width: '100%' }}>
                            <Field
                                name={item.name}
                                component={SelectField}
                                items={item.listResource}
                                localization={localization}
                                componentId={item.componentId}
                                pagePath={pagePath}
                                nameSelect={item.title}
                                isRequired={item.isRequired}
                            />
                        </FormControl>
                    </Grid>
                )
            })}
            {InputFieldGroup.map((item, idx) => (
                <Grid item xs={12} key={idx}>
                    <InputField
                        localization={localization}
                        name={item.name}
                        componentId={`${item.componentId}`}
                        pagePath={pagePath}
                        isRequired={item.isRequired}
                        placeholder={t(`${localization}.${item.name}`)}
                    />
                </Grid>
            ))}
        </Grid>
    )
}

export default WorkbenchForm
