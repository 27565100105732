import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectUserPermissions } from '~/store/auth/selector'
import MenuItemGroup from './MenuItemGroup'
import MenuItemLink from './MenuItemLink'

const MenuItem = ({ item }) => {
    const userPermissions = useSelector(selectUserPermissions)

    const hasChildren = item.permissions_group?.some((item) =>
        userPermissions.includes(item),
    )

    const isGroupMenu = (item) => {
        const children = {
            parent: item.title,
            permissions: [],
            has_children: false,
        }
        item.children?.forEach((child) => {
            children.permissions.push(child.permission)
            if (!children.has_children) {
                children.has_children = child.children?.length > 0
            }
        })

        const rights = children.permissions.filter((p) =>
            userPermissions.includes(p),
        )

        return children.has_children || rights.length > 0
    }

    if (userPermissions.includes('super_admin')) {
        if (item.children) {
            return <MenuItemGroup key={item.title} item={item} />
        } else {
            return <MenuItemLink key={item.title} item={item} />
        }
    }

    if (item.children && hasChildren) {
        return isGroupMenu(item) ? (
            <MenuItemGroup key={item} item={item} />
        ) : (
            <MenuItemLink key={item} item={item} />
        )
    }

    if (userPermissions.includes(item.permission)) {
        return <MenuItemLink key={item} item={item} />
    }

    return null
}

MenuItem.propTypes = {
    item: PropTypes.object,
}

export default MenuItem
