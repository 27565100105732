import { useMutation, useQueryClient } from 'react-query'
import { useSupportObject } from '~/hooks/useSupportObject'
import { removeEmptyValue } from '~/utils/helpers'
import workbenchApi from '~/api/workbenchApi'

export const useCreateWorkbenchMutation = () => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['workbench_create'],
        async (payload) => {
            const {
                data: { data },
            } = await workbenchApi.createWorkbench(payload)
            return data
        },
        {
            onSettled: async () => clientQuery.invalidateQueries('workbenches'),
        },
    )
}
export const useUpdateWorkbenchMutation = () => {
    const { id, clientQuery } = useSupportObject()

    return useMutation(
        ['workbench_update'],
        async (payload) => {
            const p = removeEmptyValue(payload)
            const {
                data: { data },
            } = await workbenchApi.updateWorkbench(id, p)
            return data
        },
        {
            onSettled: async () => clientQuery.invalidateQueries('workbenches'),
        },
    )
}

export const useDeleteWorkbenchMutation = () => {
    const { clientQuery } = useSupportObject()
    return useMutation(
        ['workbench_delete'],
        async (id) => {
            const {
                data: { data },
            } = await workbenchApi.deleteWorkbench({ id })
            return data
        },
        {
            onSettled: () => clientQuery.invalidateQueries('workbenches'),
        },
    )
}
