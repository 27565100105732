import { Box, Grid, Toolbar as MuiToolbar } from '@mui/material'
import SearchToolBar from '~/components/SearchToolBar'
import PropTypes from 'prop-types'

const Toolbar = ({ filterSearch, onFilterSearch }) => {
    return (
        <MuiToolbar>
            <Grid container spacing={0.5}>
                <Grid
                    item
                    xs={12}
                    sm={10}
                    sx={{ display: { xs: 'none', sm: 'flex' } }}
                >
                    <Box sx={{ flexGrow: 1 }} />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <SearchToolBar
                        filterSearch={filterSearch}
                        onFilterSearch={onFilterSearch}
                        onEnterSearch={() => {}}
                    />
                </Grid>
            </Grid>
        </MuiToolbar>
    )
}

Toolbar.propTypes = {
    filterSearch: PropTypes.string,
    onFilterSearch: PropTypes.func,
}

export default Toolbar
