import { axiosClient } from '~/api/base'

class NamingTagApi {
    getAllTags = () => axiosClient.get('api/device_tags/all_tags')
    getTagList = (params) => axiosClient.get('api/device_tags/tags', { params })

    getTagDetail = (id) => axiosClient.get(`api/device_tags/tags/${id}`)
    createTag = (formValues) =>
        axiosClient.post('api/device_tags/tags', formValues)

    saveTag = ({ id, ...formValues }) =>
        axiosClient.put(`api/device_tags/tags/${id}`, formValues)

    deleteTag = (id) => axiosClient.delete(`api/device_tags/tags/${id}`)

    getRuleList = (params) =>
        axiosClient.get('api/device_tags/rules', { params })

    getRuleDetail = (id) => axiosClient.get(`api/device_tags/rules/${id}`)
    applyRule = (id) => axiosClient.get(`api/device_tags/rules/${id}/apply`)
    revokeRule = (id) => axiosClient.get(`api/device_tags/rules/${id}/revoke`)
    deleteRule = (id) => axiosClient.delete(`api/device_tags/rules/${id}`)
    saveRule = ({ id, ...formValues }) =>
        axiosClient.put(`api/device_tags/rules/${id}`, formValues)

    createRule = (formValues) =>
        axiosClient.post('api/device_tags/rules', formValues)
}

export default new NamingTagApi()
