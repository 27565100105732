import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ordersApi from '~/api/production/ordersApi'
import { PRODUCTION_ORDERS_DETAIL } from '~/constants/Routes'
import { setSnackbar } from '~/store/snackbar/slice'
import { removeEmptyValue } from '~/utils/helpers'

export const useOrdersListQuery = (params) => {
    return useQuery(['production-orders', params], async () => {
        const p = removeEmptyValue(params)
        const { data } = await ordersApi.getOrders(p)
        return { data: data?.data, total: data?.meta?.total }
    })
}

export const useOrderQuery = (orderNr, location) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    return useQuery(
        ['production-order', orderNr],
        async () => {
            const data = await ordersApi.getOrder(orderNr)
            return data.data
        },
        {
            onSuccess: (data) => {
                if (location.search === '' || location.search === undefined) {
                    navigate(
                        PRODUCTION_ORDERS_DETAIL.replace(
                            ':production_order_nr',
                            data?.data?.production_order_nr,
                        ),
                    )
                }
            },
            onError: (error) => {
                let message = 'Scan failed!'
                if (error.response.data.message) {
                    message = error.response.data.message
                } else if (error.message) {
                    message = error.message
                }
                dispatch(
                    setSnackbar({
                        message,
                        type: 'error',
                    }),
                )
            },
            retry: false,
            enabled: !!orderNr,
        },
    )
}

export const useOrderLineQuery = ({ production_order_nr, ...params }) => {
    return useQuery(['production-order-line', params], async () => {
        const data = await ordersApi.getOrderLine(production_order_nr, params)
        return data.data
    })
}
