import useTable from '~/hooks/useTable'
import { useMemo } from 'react'
import { useDellStockListQuery } from './query'
import TableToolbarContainer from '~/components/Table/TableToolbarContainer'
import { Divider, Paper, TableBody, TableRow } from '@mui/material'
import SearchToolBar from '~/components/SearchToolBar'
import TableHeader from '~/components/TableHeader'
import PaginationTable from '~/components/PaginationTable'
import TableContainer from '~/components/Table/TableContainer'
import { TableCell } from '~/components/TableCell'
import DataNotFound from '~/components/DataNotFound'
import TableLoading from '~/components/Table/TableLoading'
import { DellStockColumnConfig } from '~/components/HeaderTable/DellStockColumnConfig'
import PropTypes from 'prop-types'

const TableContent = ({ results, isLoading }) => {
    const colSpan = useMemo(() => {
        return DellStockColumnConfig.length
    }, [])

    if (isLoading) {
        return <TableLoading colSpan={colSpan} />
    }
    return (
        <TableBody>
            {results.length > 0 ? (
                results.map((row) => (
                    <TableRow hover tabIndex={-1} key={row.id}>
                        <TableCell cellValue={row?.id} />
                        <TableCell
                            cellValue={row?.supplier_depot?.depot_code}
                        />
                        <TableCell cellValue={row?.supplier_depot?.name} />
                        <TableCell cellValue={row?.supplier_depot?.owner} />
                        <TableCell cellValue={row?.part_number} />
                        <TableCell cellValue={row?.part_description} />
                        <TableCell cellValue={row?.name} />
                        <TableCell cellValue={row?.qty} />
                        <TableCell cellValue={row?.price_usd} />
                        <TableCell cellValue={row?.price_eur} />
                        <TableCell cellValue={row?.price_gbp} />
                        <TableCell cellValue={row?.date} />
                    </TableRow>
                ))
            ) : (
                <DataNotFound colSpan={colSpan} />
            )}
        </TableBody>
    )
}

TableContent.propTypes = {
    results: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
}

const DellStockTable = () => {
    const {
        order,
        page,
        orderBy,
        search,
        data,
        isLoading,
        results,
        sortHandler,
        searchHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({ query: useDellStockListQuery })
    return (
        <Paper>
            <TableToolbarContainer>
                <SearchToolBar
                    filterSearch={search}
                    onFilterSearch={searchHandler}
                    placeholder={`dell_stocks`}
                    sx={{
                        width: '20%',
                    }}
                    onEnterSearch={onEnterSearch}
                />
            </TableToolbarContainer>
            <Divider />
            <TableContainer>
                <TableHeader
                    headCells={DellStockColumnConfig}
                    localization={'pages.administrator.dellStock.'}
                    onRequestSort={sortHandler}
                    order={order}
                    orderBy={orderBy}
                />
                <TableContent results={results} isLoading={isLoading} />
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}

export default DellStockTable
