import {
    ADMINISTRATOR,
    ADMINISTRATOR_DEFINITION,
    ADMINISTRATOR_DEFINITION_REGIONS,
    ADMINISTRATOR_DEFINITION_REGIONS_NEW,
} from '~/constants/Routes'
import { useCreateRegionMutation } from '../mutation'
import { Form } from 'react-final-form'
import Body from './Body'
import BreadCrumb from '~/components/BreadCrumb'
import { object, string } from 'yup'
import useFormValidationCallback from '../../../hooks/useFormValidationCallback'
import { useTranslation } from 'react-i18next'
import PageName from '../../../components/PageName'
import ToolbarForm from '~/components/ToolbarForm'
import ComponentTooltip from '~/components/ComponentTooltip'
import PropTypes from 'prop-types'

const RegionCreate = ({ handleSubmit }) => {
    const localization = 'pages.administrator.definition.regions.edit'
    const { t } = useTranslation()

    const schema = object().shape({
        name: string().required(),
        description: string().nullable(),
    })
    const validate = useFormValidationCallback(schema)

    const { mutate: createRegion, isLoading } = useCreateRegionMutation({})

    const BreadComponents = [
        { route: ADMINISTRATOR, title: t(`breadCrumb.Administrator`) },
        {
            route: ADMINISTRATOR_DEFINITION,
            title: 'Definitions',
        },
        {
            route: ADMINISTRATOR_DEFINITION_REGIONS,
            title: 'Regions',
        },
        {
            page: 'detail',
            title: 'new',
        },
    ]
    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_DEFINITION_REGIONS_NEW}
            >
                <div>
                    <PageName name={'Create Region'} />
                </div>
            </ComponentTooltip>
            <Form
                validate={validate}
                onSubmit={createRegion}
                render={() => (
                    <form onSubmit={handleSubmit}>
                        <ToolbarForm
                            localization={localization}
                            isLoading={isLoading}
                        />
                        <Body localization={localization} />
                    </form>
                )}
            />
        </>
    )
}

RegionCreate.propTypes = { handleSubmit: PropTypes.func }

export default RegionCreate
