import { useUpdateBom } from '~/pages/BOM/mutate'
import { useQueryClient } from 'react-query'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { useCallback } from 'react'
import StyledModal from '~/components/StyledModal'
import { Stack, Typography } from '@mui/material'
import { LoadingButton } from '~/components/Button'
import PropTypes from 'prop-types'
import { removeEmptyValue } from '~/utils/helpers'

const ConfirmPopup = ({
    onClose = () => {},
    closeAll = () => {},
    partNumber = null,
    sku = null,
    message,
    bomId,
}) => {
    const { mutate, isLoading } = useUpdateBom()
    const queryClient = useQueryClient()
    const { openSnackbar } = useDialogContext()
    const payload = removeEmptyValue({
        part_number: partNumber,
        sku,
    })

    const updateBOM = useCallback(() => {
        mutate(
            { id: bomId, payload },

            {
                onSuccess: () => {
                    onClose()
                    closeAll()
                    queryClient.invalidateQueries('bom_detail')
                    openSnackbar({
                        message: 'Update success!',
                        type: 'success',
                    })
                },
                onError: () => {
                    onClose()
                    openSnackbar({
                        message: 'Update failed!',
                        type: 'error',
                    })
                },
            },
        )
    }, [mutate, bomId, onClose, closeAll, queryClient, openSnackbar, payload])

    return (
        <StyledModal
            open={!!partNumber || !!sku}
            handleClose={onClose}
            stackProps={{
                width: 600,
                gap: 4,
            }}
        >
            <Typography variant='h6'>{message}</Typography>
            <Stack direction='row' gap={2}>
                <LoadingButton
                    label='Confirm'
                    onClick={updateBOM}
                    loading={isLoading}
                />
                <LoadingButton
                    label='Cancel'
                    onClick={onClose}
                    loading={isLoading}
                />
            </Stack>
        </StyledModal>
    )
}

ConfirmPopup.propTypes = {
    onClose: PropTypes.func,
    closeAll: PropTypes.func,
    partNumber: PropTypes.string,
    sku: PropTypes.string,
    message: PropTypes.string.isRequired,
    bomId: PropTypes.number,
}

export default ConfirmPopup
