export const ProductionServerHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
    },
    {
        id: 'hostname',
        numeric: false,
        disablePadding: false,
        label: 'hostname',
        orderable: false,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        orderable: false,
    },
    {
        id: 'organisation',
        numeric: false,
        disablePadding: false,
        label: 'organisation',
        orderable: false,
    },
    {
        id: 'lastip',
        numeric: false,
        disablePadding: false,
        label: 'lastip',
        orderable: false,
    },
    {
        id: 'lastseen',
        numeric: false,
        disablePadding: false,
        label: 'lastseen',
        orderable: false,
        dataType: 'datetime',
    },
]
