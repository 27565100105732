import { Typography } from '@mui/material'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import useTeamFormData from '../hooks/useTeamFormData'
import TeamForm from '../TeamForm'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function Body({ data, localization, form }) {
    const { InputFieldGroup, selectGroup } = useTeamFormData()
    const { t } = useTranslation()

    return (
        <FormBodyWrapper>
            {data ? (
                <TeamForm
                    {...{ localization, InputFieldGroup, selectGroup, form }}
                />
            ) : (
                <Typography>{t(`message.data_not_found`)}</Typography>
            )}
        </FormBodyWrapper>
    )
}

Body.propTypes = {
    data: PropTypes.any,
    localization: PropTypes.string,
    form: PropTypes.any,
}

export default Body
