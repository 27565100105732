import { useNavigate } from 'react-router-dom'
import { Form } from 'react-final-form'
import BreadCrumb from '~/components/BreadCrumb'
import useFormValidationCallback from '~/hooks/useFormValidationCallback'
import { number, object, string } from 'yup'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_NAMING_TAG,
    ADMINISTRATOR_NAMING_TAG_RULE_DETAIL,
} from '~/constants/Routes'
import FormToolbar from '~/components/FinalForm/FormToolbar'
import { LoadingButton } from '~/components/Button'
import { useTranslation } from 'react-i18next'
import { useCreateTagRuleMutation } from '../mutate'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import PageName from '~/components/PageName'
import ComponentTooltip from '~/components/ComponentTooltip'
import BodyForm from './BodyForm'

const breadCrumbConfig = [
    { title: `Administrator`, route: ADMINISTRATOR },
    {
        title: `NamingTags`,
        route: ADMINISTRATOR_NAMING_TAG,
    },
    {
        title: `Create tag rule`,
        page: 'detail',
    },
]

const NamingTagRuleCreate = () => {
    const { openSnackbar } = useDialogContext()
    const localization = `pages.administrator.namingTag`
    const { t } = useTranslation()
    const { mutate: createRuleMutate, isLoading } = useCreateTagRuleMutation()
    const schema = object().shape({
        device_name: string().label(`Device Name`).required(),
        vendor_id: number().label(`Vendor`).required(),
        component_id: number().label(`Component`).required(),
        device_tag_id: number().label(`Device Tag`).required(),
    })
    const navigate = useNavigate()
    const validate = useFormValidationCallback(schema)

    const saveHandler = (values) => {
        createRuleMutate(values, {
            onSuccess: (data) => {
                openSnackbar({
                    message: `Save successful.`,
                    type: `success`,
                })
                navigate(
                    ADMINISTRATOR_NAMING_TAG_RULE_DETAIL.replace(
                        `:id`,
                        data.id,
                    ),
                )
            },
            onError: (e) => {
                openSnackbar({
                    message: e?.response?.data,
                    type: 'error',
                    duration: 10000,
                })
            },
        })
    }

    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <ComponentTooltip
                componentId={`page_title_naming_rule`}
                pagePath={ADMINISTRATOR_NAMING_TAG}
            >
                <div>
                    <PageName name='Create Tag Rules' />
                </div>
            </ComponentTooltip>
            <Form
                validate={validate}
                onSubmit={saveHandler}
                render={({ handleSubmit, values, dirty }) => (
                    <form onSubmit={handleSubmit}>
                        <FormToolbar>
                            <LoadingButton
                                disabled={!dirty}
                                label={t(`${localization}.save`)}
                                type='submit'
                                loading={isLoading}
                            />
                        </FormToolbar>
                        <BodyForm {...{ localization, values }} />
                    </form>
                )}
            />
        </>
    )
}

export default NamingTagRuleCreate
