import { FormControl, Grid } from '@mui/material'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import { Field } from 'react-final-form'
import { ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_CREATE } from '~/constants/Routes'
import InputField from '~/components/FinalForm/InputField'
import SelectField from '~/components/FinalForm/SelectField'
import PropTypes from 'prop-types'

const pagePath = ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_CREATE

function Body({ localization, rolesList }) {
    return (
        <FormBodyWrapper>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputField
                        localization={localization}
                        name='name'
                        componentId={`service_account_create_name`}
                        pagePath={pagePath}
                        isRequired={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl sx={{ width: '100%' }}>
                        <Field
                            name='role'
                            component={SelectField}
                            items={rolesList}
                            localization={localization}
                            nameSelect='role'
                            componentId={`service_account_create_role`}
                            pagePath={pagePath}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <InputField
                        localization={localization}
                        name='contact'
                        componentId={`service_account_create_contact`}
                        pagePath={pagePath}
                    />
                </Grid>

                <Grid item xs={12}>
                    <InputField
                        localization={localization}
                        name='contact_email'
                        componentId={`service_account_create_contact_email`}
                        pagePath={pagePath}
                    />
                </Grid>

                <Grid item xs={12}>
                    <InputField
                        localization={localization}
                        name='description'
                        componentId={`service_account_create_description`}
                        pagePath={pagePath}
                    />
                </Grid>

                <Grid item xs={12}>
                    <InputField
                        localization={localization}
                        name='whitelist'
                        componentId={`service_account_create_whitelist`}
                        pagePath={pagePath}
                    />
                </Grid>
            </Grid>
        </FormBodyWrapper>
    )
}

Body.propTypes = { localization: PropTypes.string, rolesList: PropTypes.array }

export default Body
