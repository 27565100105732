import axiosClient, { clientDownload } from '~/api/base'

class ServiceCenterApi {
    createDataScheduler = (payload) => {
        if (payload.emails) {
            const p = {
                ...payload,
                emails: payload.emails.replaceAll(' ', ''),
            }
            return axiosClient.post('api/export-schedule', p)
        }

        return axiosClient.post('api/export-schedule', payload)
    }

    updateDataScheduler = (id, payload) => {
        if (payload.emails) {
            const p = {
                ...payload,
                emails: payload.emails.replaceAll(' ', ''),
            }
            return axiosClient.put(`api/export-schedule/${id}`, p)
        }

        return axiosClient.put(`api/export-schedule/${id}`, payload)
    }

    updateStatusScheduler = (id, payload) =>
        axiosClient.put(`api/export-schedule/${id}/update-status`, payload)

    getExportScheduler = (params) => {
        return axiosClient.get('api/export-schedule', { params })
    }

    getExportSchedulerDetail = (id) =>
        axiosClient.get(`api/export-schedule/${id}`)

    getDownLoadFile = (path_token) =>
        clientDownload.post('api/data-scheduler/download', path_token)

    checkStatement = (payload) => {
        return axiosClient.post('api/export-schedule/check-statement', payload)
    }

    getExportDatabaseList = () =>
        axiosClient.get('api/export-schedule/databases')
}
export default new ServiceCenterApi()
