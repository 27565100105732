import { createSlice } from '@reduxjs/toolkit'

export const causeSlice = createSlice({
    name: 'causeState',
    initialState: {
        causeMessage: null, // for action create or update
        causeModel: null,
    },
    reducers: {
        setMessageCause: (state, action) => {
            state.causeMessage = action.payload
        },
        setCauseModel: (state, action) => {
            state.causeModel = action.payload
        },
    },
})

export const { setMessageCause, setCauseModel } = causeSlice.actions

export default causeSlice
