export const PurchaseOrderDetailHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
    },
    {
        id: 'product_id',
        numeric: false,
        disablePadding: false,
        label: 'productId',
        orderable: true,
    },
    {
        id: 'product_nr',
        numeric: false,
        disablePadding: false,
        label: 'productNr',
        orderable: true,
    },
    {
        id: 'supplier_code',
        numeric: false,
        disablePadding: false,
        label: 'supplierCode',
        orderable: true,
    },
    {
        id: 'price',
        numeric: false,
        disablePadding: false,
        label: 'price',
        orderable: true,
    },
    {
        id: 'qty',
        numeric: false,
        disablePadding: false,
        label: 'qty',
        orderable: true,
    },
    {
        id: 'delivered',
        numeric: false,
        disablePadding: false,
        label: 'delivered',
        orderable: true,
    },
]
