import { Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const SectionTitle = ({ title, extra, gutterBottom = true, ...progs }) => {
    return (
        <Grid item xs={12} md={12} lg={12} align='center' {...progs}>
            <Typography variant='h5' gutterBottom={gutterBottom}>
                {title} {extra}
            </Typography>
        </Grid>
    )
}

SectionTitle.propTypes = {
    title: PropTypes.string.isRequired,
    extra: PropTypes.any,
    gutterBottom: PropTypes.bool,
}

export default SectionTitle
