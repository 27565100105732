import rackTicketApi from '~/api/rackTicketApi'
import { useMutation, useQueryClient } from 'react-query'

export const useUpdateStateTicketMutation = () => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['update_state_ticket'],
        async ({ id, payload }) => {
            const { data } = await rackTicketApi.updateStateTicket(id, payload)
            return data.data
        },
        {
            onSuccess: () => {},
            onError: () => {},
            onSettled: () => clientQuery.invalidateQueries('rack_tickets'),
            retry: false,
        },
    )
}

export const useExportRackTicketMutation = () => {
    return useMutation(
        ['rack_export'],
        async (payload) => {
            const { data } = await rackTicketApi.getRackTicketsExport(payload)
            return data
        },
        {
            onSuccess: (data) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `rack_tickets.xlsx`)
                document.body.appendChild(link)
                link.click()
            },
            onError: () => {},
            retry: false,
        },
    )
}
