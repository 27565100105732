import Search from './Search'
import { Paper } from '@mui/material'
import { Box } from '@mui/system'
import PropTypes from 'prop-types'

const FilterSearch = ({
    placeholder = '',
    onEnterSearch = () => {},
    onSearchChange = () => {},
}) => {
    return (
        <Paper
            sx={{
                p: '2px 4px',
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'right',
                bgcolor: '#f4f4f4',
            }}
        >
            <Box sx={{ flexFlow: 1 }} />
            <Box
                sx={{
                    bgcolor: '#ffffff',
                    borderRadius: '4px',
                    my: 1,
                    mr: 1,
                }}
            >
                <Search
                    placeholder={placeholder}
                    onChange={onSearchChange}
                    onKeyDown={onEnterSearch}
                />
            </Box>
        </Paper>
    )
}

FilterSearch.propTypes = {
    placeholder: PropTypes.string,
    onEnterSearch: PropTypes.func,
    onSearchChange: PropTypes.func,
}

export default FilterSearch
