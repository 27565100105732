import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Button from '~/pages/Dispatch/AddDispatch/components/Button'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectShowedPartCodeForDiagnostic } from '~/store/partcode/selector'
import { pushShowPartCodesForDiagnostic } from '~/store/partcode/slice'
import PropTypes from 'prop-types'

const PartCodeListSelectorComponent = ({ partCode }) => {
    const dispatch = useDispatch()
    const showingPartCode = useSelector(selectShowedPartCodeForDiagnostic)
    const [disabledAddButton, setDisabledAddButton] = useState(false)

    useEffect(() => {
        const isAddedPart = showingPartCode.some((showingPart) => {
            return showingPart.code === partCode.code
        })
        setDisabledAddButton(isAddedPart)
    }, [partCode.code, showingPartCode])

    const addPart = () => {
        dispatch(pushShowPartCodesForDiagnostic([...showingPartCode, partCode]))
    }

    return (
        <TableRow>
            <TableCell>{partCode.code}</TableCell>
            <TableCell>{partCode.name}</TableCell>
            <TableCell align='center'>
                <Button
                    type='submit'
                    disabled={partCode.added || disabledAddButton}
                    onClick={addPart}
                >
                    Add
                </Button>
            </TableCell>
        </TableRow>
    )
}

PartCodeListSelectorComponent.propTypes = { partCode: PropTypes.object }

export default PartCodeListSelectorComponent
