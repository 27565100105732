import DeleteIcon from '@mui/icons-material/Delete'
import {
    Button,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@mui/material'
import DeleteConfirmPopup from '~/components/DeleteConfirmPopup'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectDevice } from '~/store/device/selector'
import { truncateFileName } from '~/utils/helpers'
import UploadDocument from './Component/UploadDocument'
import {
    useDeleteAttachmentMutation,
    useDownloadDocumentMutation,
} from './mutation'

const DeviceDocuments = () => {
    const [openUploadDocument, setOpenUploadDocument] = useState(false)
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
    const [deletePictureId, setDeleteDocumentId] = useState()
    const device = useSelector(selectDevice)
    const { t } = useTranslation()

    const { mutate: actionDownloadDocument } = useDownloadDocumentMutation()

    const {
        mutate: deleteDevicePicture,
        isSuccess,
        isLoading,
    } = useDeleteAttachmentMutation()

    const attachments = useMemo(() => {
        const document = device?.media
            ? device?.media.filter((x) => x.collection_name === 'documents')
            : []

        return document.length > 0
            ? document.sort((item1, item2) => {
                  return item2.id - item1.id
              })
            : []
    }, [device])

    const deleteDocument = (id) => {
        setOpenDeleteConfirm(true)
        setDeleteDocumentId(id)
    }

    const handleClose = () => {
        setOpenDeleteConfirm(false)
    }

    useEffect(() => {
        if (isSuccess) {
            setOpenDeleteConfirm(false)
        }
    }, [isSuccess])

    return (
        <>
            {openUploadDocument && (
                <UploadDocument
                    {...{ openUploadDocument, setOpenUploadDocument }}
                />
            )}
            <Button
                sx={{ textTransform: 'none', fontWeight: 'normal' }}
                onClick={() => {
                    setOpenUploadDocument(true)
                }}
            >
                Upload Documents
            </Button>
            <List sx={{ maxHeight: 465, overflow: 'auto' }}>
                {attachments.length > 0 &&
                    attachments.map((item) => (
                        <ListItem key={item.id}>
                            <IconButton>
                                <DeleteIcon
                                    onClick={() => deleteDocument(item.id)}
                                />
                            </IconButton>
                            <ListItemText
                                primary={
                                    <Link
                                        component='button'
                                        variant='inherit'
                                        to='#'
                                        underline='hover'
                                        onClick={() =>
                                            actionDownloadDocument({
                                                id: item.id,
                                                fileName: item.file_name,
                                            })
                                        }
                                        target='_blank'
                                    >
                                        <Typography>
                                            {truncateFileName(item.file_name)}
                                        </Typography>
                                    </Link>
                                }
                            />
                        </ListItem>
                    ))}
            </List>

            <DeleteConfirmPopup
                {...{ openDeleteConfirm, handleClose, isLoading }}
                handleDelete={() => {
                    deleteDevicePicture(deletePictureId)
                }}
                informationDialog={{
                    title: `${t('dialog.deleteDocument')}`,
                    content: `${t('dialog.deleteDocumentContent')}`,
                }}
            />
        </>
    )
}

export default DeviceDocuments
