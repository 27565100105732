import { TableCell, TableRow } from '@mui/material'
import { LoadingButton } from '~/components/Button'
import { renderBooleanValue } from '~/utils/helpers'
import { useDetachPartCodeFromCause } from '../mutation'
import PropTypes from 'prop-types'

const PartCodeTableComponent = ({ partCode, disabled = false }) => {
    const {
        mutate: detachPartCodeFromCause,
        isLoading: isLoadingRemovePartCode,
    } = useDetachPartCodeFromCause()
    return (
        <TableRow
            key={partCode.code}
            sx={{
                '&:last-child td, &:last-child th': {
                    border: 0,
                },
            }}
        >
            <TableCell>{partCode.code ? partCode.code : '---'}</TableCell>
            <TableCell align='left'>
                {partCode.name ? partCode.name : '---'}
            </TableCell>
            <TableCell align='left'>
                {partCode.type ? partCode.type : '---'}
            </TableCell>
            <TableCell align='left'>
                {renderBooleanValue(partCode.serializable)}
            </TableCell>
            {disabled ? (
                <></>
            ) : (
                <TableCell align='left'>
                    <LoadingButton
                        loading={isLoadingRemovePartCode}
                        onClick={() =>
                            detachPartCodeFromCause({
                                part_code: partCode.code,
                            })
                        }
                        label='Remove'
                        bgColor='#102F44'
                    />
                </TableCell>
            )}
        </TableRow>
    )
}

PartCodeTableComponent.propTypes = {
    partCode: PropTypes.object,
    disabled: PropTypes.bool,
}

export default PartCodeTableComponent
