import styled from '@emotion/styled'

import { Button } from '@mui/material'

const AccordionsButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    backgroundColor: `${theme.palette.green[100]}`,
    color: `white`,
    border: '1px solid #ccc',
    '&:hover': {
        backgroundColor: `${theme.palette.blue[100]}`,
        color: 'white',
    },
}))

export default AccordionsButton
