import Proptypes from 'prop-types'
import { TextField } from '@mui/material'

const inputLabelProps = {
    shrink: true,
}

const FormInput = ({ innerRef, input, meta, variant, title, ...props }) => {
    const hasErrors = meta.touched && meta.invalid
    const helperText = hasErrors && (meta.error || meta.submitError)

    return (
        <TextField
            {...input}
            {...props}
            fullWidth
            ref={innerRef}
            error={hasErrors}
            helperText={helperText}
            InputLabelProps={inputLabelProps}
            variant={variant ?? 'outlined'}
            sx={{ width: '100%' }}
        />
    )
}

FormInput.defaultProps = {
    innerRef: null,
    input: {},
    meta: {},
    title: '',
}

FormInput.propTypes = {
    innerRef: Proptypes.oneOfType([
        Proptypes.func,
        Proptypes.shape({ current: Proptypes.any }),
    ]),
    input: Proptypes.objectOf(Proptypes.any),
    meta: Proptypes.objectOf(Proptypes.any),
    title: Proptypes.string,
    variant: Proptypes.string,
}

export default FormInput
