import { useMutation, useQueryClient } from 'react-query'
import ordersApi from '~/api/production/ordersApi'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useImportOrderLineMutation = () => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['import_order_line'],
        async (payload) => {
            const { data } = await ordersApi.importOrderLine(payload)
            return data.data
        },
        {
            onSuccess: () => {
                clientQuery.invalidateQueries('production-order')
                clientQuery.invalidateQueries('production-order-line')
            },
            retry: false,
        },
    )
}

export const useDeleteOrderLineMutation = () => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['delete_order_line'],
        async (payload) => {
            const data = await ordersApi.deleteOrderLine(payload)
            return data
        },
        {
            onSuccess: () => {
                clientQuery.invalidateQueries(['production-order-line'])
                clientQuery.invalidateQueries(['production-order'])
            },
            onError: () => {},
            retry: false,
        },
    )
}

export const useEnrollingSerialMutation = () => {
    const clientQuery = useQueryClient()
    const { openSnackbar } = useDialogContext()
    return useMutation(
        ['enrolling_serial'],
        async (payload) => {
            console.log(payload)
            const data = await ordersApi.enrollingSerial(payload)
            return data
        },
        {
            onSuccess: (data) => {
                clientQuery.invalidateQueries(['production-order-line'])
                clientQuery.invalidateQueries(['production-order'])
                openSnackbar({
                    message: data?.data?.message ?? 'Enrolling successfully',
                    type: 'success',
                })
            },
            onError: (error) => {
                openSnackbar({
                    message: parseApiErrorMessage(error),
                    type: 'error',
                })
            },
            retry: false,
        },
    )
}
