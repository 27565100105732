import { userApi } from '~/api'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { useMutation, useQueryClient } from 'react-query'

export const useUpdateWorkbenchMutation = () => {
    const queryClient = useQueryClient()
    const { openSnackbar } = useDialogContext()
    return useMutation(
        ['change_workbench'],
        async (payload) => {
            const { data } = await userApi.updateWorkbench(payload)
            return data
        },
        {
            onSuccess: (data) => {
                openSnackbar({
                    message: data.message
                        ? data.message
                        : 'Successfully updated!',
                    type: 'success',
                })
                queryClient.invalidateQueries('user')
                queryClient.invalidateQueries('users')
            },
        },
    )
}
