import {
    Table as MuiTable,
    TableContainer as MuiTableContainer,
} from '@mui/material'
import PropTypes from 'prop-types'

const TableContainer = ({ children }) => {
    return (
        <MuiTableContainer
            sx={{ minWidth: 750 }}
            aria-labelledby='tableTitle'
            size='medium'
        >
            <MuiTable>{children}</MuiTable>
        </MuiTableContainer>
    )
}
TableContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
}
export default TableContainer
