import { useQuery } from 'react-query'
import projectsApi from '~/api/production/projectsApi'
import { parseApiErrorMessage, removeEmptyValue } from '~/utils/helpers'

export const useProjectsListQuery = (params) => {
    return useQuery(['production-projects', params], async () => {
        const p = removeEmptyValue(params)
        const { data } = await projectsApi.getProjects(p)
        return { data: data?.data, total: data?.meta?.total }
    })
}

export const useProjectQuery = ({ project_nr, showSnackbar }) => {
    return useQuery(
        ['production-project', project_nr],
        async () => {
            const data = await projectsApi.getProject(project_nr)
            return data.data
        },
        {
            onSuccess: (data) => {},
            onError: (error) => {
                showSnackbar(parseApiErrorMessage(error), 'error')
            },
            retry: false,
        },
    )
}

export const useProjectAddonsQuery = ({ projectId }) => {
    return useQuery(
        ['production-project-addons', projectId],
        async () => {
            const { data } = await projectsApi.getProjectsAddons(projectId)
            return { data: data.data, total: data.meta.total }
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useListAddonsQuery = (projectId) => {
    return useQuery(
        ['list-addons', projectId],
        async () => {
            const { data } = await projectsApi.getListAddons(projectId)
            return { data: data.data }
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useListDataForFilterQuery = () => {
    return useQuery(
        ['list-data-for-filter'],
        async () => {
            const { data } = await projectsApi.getDataForFillter()
            return data
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useListDataForInsertProjectQuery = () => {
    return useQuery(
        ['list-data-for-insert-project'],
        async () => {
            const { data } = await projectsApi.getDataForInsertProject()
            return data
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}
