import { authenticateApi } from '~/api'
import { setApiClientAuthToken } from '~/api/base'
import { KEY_AUTH_TOKEN } from '~/constants/constants'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

export const useAuthTokenMutation = () => {
    const navigate = useNavigate()

    return useMutation(
        async (code) => {
            const { data } = await authenticateApi.ssoAuthorizeApi(code)
            return data
        },
        {
            onSuccess: (data) => {
                if (data.auth_token) {
                    localStorage.setItem(KEY_AUTH_TOKEN, data.auth_token)
                    setApiClientAuthToken(data.auth_token)
                    window.location.reload()
                }

                if (data.message) {
                    navigate({
                        pathname: '/login',
                        search: `?status=${data.message}`,
                    })
                }
            },
            onError: () => {
                navigate({
                    pathname: '/login',
                    search: '?error=authenticate-failed',
                })
            },
            retry: false,
        },
    )
}
