import { authenticateApi } from '~/api'
import { IMPERSONATE_TOKEN, KEY_AUTH_TOKEN } from '~/constants/constants'
import { useQuery, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { setUser, setUserPermissions } from '~/store/auth/slice'
import { useDialogContext } from '../providers/StyledDialogContext'
import { setApiClientAuthToken } from '~/api/base'
import { getImpersonateToken } from '~/utils/helpers'
import { useTranslation } from 'react-i18next'

export const useProfileQuery = (enabled) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const { openSnackbar } = useDialogContext()
    return useQuery(
        'profile',
        async () => {
            const { data } = await authenticateApi.getUserProfile()
            return data.data
        },
        {
            enabled,
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                dispatch(setUser(data))
                const permissions = data.permissions.reduce((acc, cur) => {
                    return [...acc, cur.name]
                }, [])
                dispatch(setUserPermissions(permissions))
            },
            onError: (e) => {
                if (e.response.status === 401) {
                    if (getImpersonateToken()) {
                        openSnackbar({
                            message: t('message.terminateImpersonateMode'),
                            type: 'error',
                        })
                        localStorage.removeItem(IMPERSONATE_TOKEN)
                        setApiClientAuthToken(
                            localStorage.getItem(KEY_AUTH_TOKEN),
                        )
                        queryClient.invalidateQueries('profile')
                    } else {
                        localStorage.removeItem(KEY_AUTH_TOKEN)
                    }
                }
            },
        },
    )
}
