import { useTranslation } from 'react-i18next'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectShowedPartCodeForDiagnostic } from '~/store/partcode/selector'
import { pushShowPartCodesForDiagnostic } from '~/store/partcode/slice'
import { TextField } from '@mui/material'
import propTypes from 'prop-types'

const PartCodeAdditionalDescriptionComponent = ({
    partCode,
    setAdditionalDesc,
    finishDiagnostic,
}) => {
    const { t } = useTranslation()
    const [helperText, setHelperText] = useState('')
    const showingPartCodes = useSelector(selectShowedPartCodeForDiagnostic)
    const dispatch = useDispatch()

    const onChange = useCallback(
        (e) => {
            const updatedShowingPartCodes = showingPartCodes.map((obj) => {
                if (obj.code === partCode.code) {
                    return {
                        ...obj,
                        additional_description: e.target.value,
                    }
                } else {
                    return obj
                }
            })

            setAdditionalDesc(e.target.value)
            setHelperText('')
            dispatch(pushShowPartCodesForDiagnostic(updatedShowingPartCodes))
            if (e.target.value?.length === 0) {
                setHelperText(
                    t(
                        'pages.device.serial.detail.additionalDescriptionRequiredMsg',
                    ),
                )
            }
        },
        [dispatch, partCode, setAdditionalDesc, showingPartCodes, t],
    )
    return (
        <TextField
            onChange={onChange}
            error={!!helperText}
            helperText={helperText}
            defaultValue={partCode.additional_description || ''}
            disabled={finishDiagnostic}
            sx={{ maxWidth: '150px', minWidth: '50px' }}
        />
    )
}

PartCodeAdditionalDescriptionComponent.propTypes = {
    partCode: propTypes.object,
    setAdditionalDesc: propTypes.func,
    finishDiagnostic: propTypes.bool,
}

export default PartCodeAdditionalDescriptionComponent
