import { axiosClient } from '~/api/base'

class VendorApi {
    // get vendors

    getVendors = (params) => {
        return axiosClient.get('api/vendors', {
            params,
        })
    }
}

export default new VendorApi()
