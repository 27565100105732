import InputField from '~/components/FinalForm/InputField'
import { ADMINISTRATOR_NAMING_TAG_CREATE } from '~/constants/Routes'

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const pagePath = ADMINISTRATOR_NAMING_TAG_CREATE

const BodyForm = ({ localization }) => {
    const { t } = useTranslation()

    const InputFieldGroup = useMemo(() => {
        return [
            {
                name: 'name',
                componentId: 'name_tag_form_name',
                isRequired: true,
            },
        ]
    }, [])
    return (
        <>
            {InputFieldGroup.map((item) => (
                <InputField
                    key={item.name}
                    localization={localization}
                    name={item.name}
                    componentId={`${item.componentId}`}
                    pagePath={pagePath}
                    isRequired={item.isRequired}
                    placeholder={t(`${localization}.${item.name}`)}
                />
            ))}
        </>
    )
}

BodyForm.propTypes = { localization: PropTypes.string }

export default BodyForm
