import { namingTagApi } from '~/api'
import { useMutation, useQueryClient } from 'react-query'

export const useApplyTagRuleMutation = () => {
    const clientQuery = useQueryClient()

    return useMutation(
        ['apply_tag_rule'],
        async (id) => {
            const {
                data: { data },
            } = await namingTagApi.applyRule(id)
            return data
        },
        {
            onSettled: () => clientQuery.invalidateQueries('tag_rule_detail'),
        },
    )
}
export const useRevokeTagRuleMutation = () => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['revoke_tag_rule'],
        async (id) => {
            const {
                data: { data },
            } = await namingTagApi.revokeRule(id)
            return data
        },
        {
            onSettled: () => clientQuery.invalidateQueries('tag_rule_detail'),
        },
    )
}

export const useSaveTagRuleMutation = () => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['save_tag_rule'],
        async (values) => {
            const {
                data: { data },
            } = await namingTagApi.saveRule(values)
            return data
        },
        {
            onSettled: async () =>
                clientQuery.invalidateQueries('tag_rule_detail'),
        },
    )
}
export const useCreateTagRuleMutation = () => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['save_tag_rule'],
        async (values) => {
            const {
                data: { data },
            } = await namingTagApi.createRule(values)
            return data
        },
        {
            onSettled: async () =>
                clientQuery.invalidateQueries('tag_rule_detail'),
        },
    )
}

export const useDeleteTagRuleMutation = () => {
    return useMutation(['delete_tag_rule'], async (id) => {
        const {
            data: { data },
        } = await namingTagApi.deleteRule(id)
        return data
    })
}
export const useCreateTagMutation = () => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['create_tag'],
        async (values) => {
            const {
                data: { data },
            } = await namingTagApi.createTag(values)
            return data
        },
        {
            onSettled: async () =>
                clientQuery.invalidateQueries('tag_rule_detail'),
        },
    )
}

export const useSaveTagMutation = () => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['save_tag'],
        async (values) => {
            const {
                data: { data },
            } = await namingTagApi.saveTag(values)
            return data
        },
        {
            onSettled: async () =>
                clientQuery.invalidateQueries('tag_rule_detail'),
        },
    )
}
export const useDeleteTagMutation = () => {
    return useMutation(['delete_tag'], async (id) => {
        const {
            data: { data },
        } = await namingTagApi.deleteTag(id)
        return data
    })
}
