import { LoadingButton } from '@mui/lab'
import { Grid, Stack } from '@mui/material'
import PropTypes from 'prop-types'

const SaveButtonPopup = ({ isLoading }) => {
    return (
        <Grid
            container
            spacing={0.5}
            direction='row'
            justifyContent='flex-end'
            alignItems='flex-end'
            mb={0}
        >
            <Grid item xs={12}>
                <Stack direction='column' spacing={2}>
                    <LoadingButton
                        type='submit'
                        variant='contained'
                        size='large'
                        loading={isLoading}
                        sx={{
                            textTransform: 'none',
                            backgroundColor: '#76B72A',
                        }}
                    >
                        Save
                    </LoadingButton>
                </Stack>
            </Grid>
        </Grid>
    )
}

SaveButtonPopup.propTypes = { isLoading: PropTypes.bool }

export default SaveButtonPopup
