import { useTranslation } from 'react-i18next'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_DEFINITION,
    ADMINISTRATOR_DEFINITION_SERVICECENTER,
    ADMINISTRATOR_DEFINITION_SERVICECENTER_EDIT,
} from '~/constants/Routes'
import { useParams } from 'react-router-dom'
import Loading from '~/components/Loading'
import { useMemo } from 'react'
import { Form } from 'react-final-form'
import Body from './Body'
import { object, string } from 'yup'
import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import useFormValidationCallback from '~/hooks/useFormValidationCallback'
import { useGetServiceCenterDetailQuery } from '../query'
import { useSnackbar } from '~/hooks/useSnackbar'
import ToolbarForm from '~/components/ToolbarForm'
import { useUpdateServiceCenterMutation } from '../mutation'
import ComponentTooltip from '~/components/ComponentTooltip'
import { phone } from '~/constants/yupSchemas'

const localization = 'pages.administrator.definition.serviceCenter.form'
const ServiceCenterEdit = () => {
    const { showSnackbar, snackbar } = useSnackbar()
    const { id } = useParams()
    const { t } = useTranslation()

    const { mutate: updateServiceCenter, isLoading: loadingUpdate } =
        useUpdateServiceCenterMutation({ showSnackbar })

    const schema = object().shape({
        name: string().required().label('name'),
        description: string().nullable().label('description'),
        phone_number: phone().required().label('Phone Number'),
    })
    const validate = useFormValidationCallback(schema)

    const { data, isLoading } = useGetServiceCenterDetailQuery(id)

    const queryResponse = useMemo(() => {
        return data?.data || []
    }, [data])

    if (isLoading) {
        return <Loading />
    }

    const BreadComponents = [
        { route: ADMINISTRATOR, title: t(`breadCrumb.Administrator`) },
        {
            route: ADMINISTRATOR_DEFINITION,
            title: 'Definitions',
        },
        {
            route: ADMINISTRATOR_DEFINITION_SERVICECENTER,
            title: 'ServiceCenter',
        },
        {
            page: 'detail',
            title: id,
        },
    ]
    return (
        <>
            {snackbar}
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_DEFINITION_SERVICECENTER_EDIT}
            >
                <div>
                    <PageName name={`Edit Service Center`} />
                </div>
            </ComponentTooltip>
            <Form
                validate={validate}
                onSubmit={updateServiceCenter}
                initialValues={queryResponse}
                render={({ handleSubmit, form, values }) => (
                    <form onSubmit={handleSubmit}>
                        <ToolbarForm
                            localization={localization}
                            isLoading={loadingUpdate}
                        />
                        <Body
                            data={queryResponse}
                            localization={localization}
                        />
                    </form>
                )}
            />
        </>
    )
}
export default ServiceCenterEdit
