import { useQuery } from 'react-query'
import serversApi from '~/api/production/serversApi'
import { parseApiErrorMessage, removeEmptyValue } from '~/utils/helpers'

export const useServersListQuery = (params) => {
    return useQuery(['production-servers', params], async () => {
        const p = removeEmptyValue(params)
        const { data } = await serversApi.getServers(p)
        return { data: data?.data, total: data?.meta?.total }
    })
}

export const useServerQuery = ({ id, showSnackbar }) => {
    return useQuery(
        ['production-server', id],
        async () => {
            const data = await serversApi.getServer(id)
            return data.data
        },
        {
            onSuccess: (data) => {},
            onError: (error) => {
                showSnackbar(parseApiErrorMessage(error), 'error')
            },
            retry: false,
        },
    )
}

export const useServerOrgsQuery = ({ params, showSnackbar }) => {
    return useQuery(
        ['production-servers-orgs', params],
        async () => {
            const data = await serversApi.getServerOrgs(params)
            return data.data
        },
        {
            onSuccess: (data) => {},
            onError: (error) => {
                showSnackbar(parseApiErrorMessage(error), 'error')
            },
            retry: false,
        },
    )
}
