import { createSlice } from '@reduxjs/toolkit'

export const deviceSlice = createSlice({
    name: 'device',
    initialState: {
        device: null,
        fleetState: null,
        vendorState: null,
        serialState: null,
        searchSerial: null,
        errorMessage: null,
    },
    reducers: {
        setDevice: (state, action) => {
            state.device = action.payload
        },
        setMessageDeviceError: (state, action) => {
            state.errorMessage = action.payload
        },
        setFleetState: (state, action) => {
            state.fleetState = action.payload
        },
        setVendorState: (state, action) => {
            state.vendorState = action.payload
        },
        setSerialState: (state, action) => {
            state.serialState = action.payload
        },
        setMessageSearchSerial: (state, action) => {
            state.searchSerial = action.payload
        },
    },
})

export const {
    setDevice,
    setFleetState,
    setVendorState,
    setSerialState,
    setMessageSearchSerial,
    setMessageDeviceError,
} = deviceSlice.actions

export default deviceSlice
