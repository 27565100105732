import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import PageName from '~/components/PageName'
import { ISSUE_ISSUE, ISSUE_RACKSCAN_TICKET } from '~/constants/Routes'

import Table from './Table'

const Ticket = () => {
    const BreadComponents = [
        { route: ISSUE_ISSUE, title: 'Issues' },
        { route: ISSUE_RACKSCAN_TICKET, title: 'rackScans' },
        {
            route: '#',
            title: 'ticket',
        },
    ]
    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ISSUE_RACKSCAN_TICKET}
            >
                <div>
                    <PageName name={'Tickets'} />
                </div>
            </ComponentTooltip>
            <Table />
        </>
    )
}
export default Ticket
