export const DeviceComponentHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
    },
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'name',
        orderable: true,
    },
    {
        id: 'part_number',
        numeric: true,
        disablePadding: false,
        label: 'partNumber',
        orderable: true,
    },
    {
        id: 'item_description',
        numeric: true,
        disablePadding: false,
        label: 'itemDescription',
        orderable: true,
    },
    {
        id: 'serializable',
        numeric: true,
        disablePadding: false,
        label: 'serializable',
        orderable: true,
    },
    {
        id: 'revision',
        numeric: true,
        disablePadding: false,
        label: 'Revision',
        orderable: true,
    },
    {
        id: 'returnable',
        numeric: true,
        disablePadding: false,
        label: 'Returnable',
        orderable: true,
    },
    {
        id: 'needs_serial',
        numeric: true,
        disablePadding: false,
        label: 'needSerial',
        orderable: true,
    },
]
