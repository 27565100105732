import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import FormInputTextArea from '~/components/FormInput/FormInputTextArea'
import ComponentTooltip from '~/components/ComponentTooltip'
import AsterisksRequiredField from '~/components/FormInput/AsterisksRequiredField'

const FormTextArea = ({
    localization,
    name,
    label,
    component,
    disabled,
    isRequired,
    autoFocus,
    pagePath,
    componentId,
    ...props
}) => {
    const { t } = useTranslation()

    const title = (
        <ComponentTooltip componentId={componentId} pagePath={pagePath}>
            <Typography>
                {label
                    ? t(`${localization}.` + label)
                    : t(`${localization}.` + name)}
                {isRequired && <AsterisksRequiredField />}
            </Typography>
        </ComponentTooltip>
    )

    return (
        <Grid item xs={12}>
            {title}
            <Field
                name={name}
                title={
                    label
                        ? t(`${localization}.` + label)
                        : t(`${localization}.` + name)
                }
                component={component}
                disabled={disabled}
                autoFocus={autoFocus}
            />
        </Grid>
    )
}

FormTextArea.defaultProps = {
    component: FormInputTextArea,
}

FormTextArea.propTypes = {
    localization: PropTypes.string.isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    disabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    autoFocus: PropTypes.bool,
    componentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pagePath: PropTypes.string,
}

export default FormTextArea
