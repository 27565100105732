export const DispatchHistoryHeadCells = [
    {
        id: 'updated_at',
        numeric: false,
        disablePadding: false,
        label: 'UpdateTimeLocal',
        orderable: true,
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'StatusDescription',
        orderable: true,
    },
    {
        id: 'waybill',
        numeric: true,
        disablePadding: false,
        label: 'WaybillNumber',
        orderable: true,
    },
    {
        id: 'dps_number',
        numeric: true,
        disablePadding: false,
        label: 'DpsNumber',
        orderable: true,
    },
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'UserName',
        orderable: true,
    },
]
