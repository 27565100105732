import PropTypes from 'prop-types'
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'

const SearchToolBar = ({
    filterSearch,
    onFilterSearch,
    placeholder,
    onEnterSearch,
    ...props
}) => {
    const { t } = useTranslation()

    return (
        <FormControl
            sx={{
                m: 1,
                width: '100%',
                bgcolor: '#ffffff',
            }}
            {...props}
        >
            <OutlinedInput
                id='outlined-adornment-amount'
                inputProps={{ 'aria-label': 'search' }}
                defaultValue={filterSearch}
                onChange={onFilterSearch}
                onKeyDown={onEnterSearch}
                startAdornment={
                    <InputAdornment position='start'>
                        <SearchIcon />
                    </InputAdornment>
                }
                placeholder={
                    placeholder
                        ? `${t('general.searchFor')} ${t(
                              `general.${placeholder}`,
                          )}`
                        : null
                }
            />
        </FormControl>
    )
}

SearchToolBar.propTypes = {
    filterSearch: PropTypes.any,
    onFilterSearch: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    onEnterSearch: PropTypes.func.isRequired,
}

export default SearchToolBar
