import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import PageName from '~/components/PageName'
import { ISSUE_DISPATCH, ISSUE_ISSUE } from '~/constants/Routes'
import DispatchesTable from './DispatchesTable'

const BreadComponents = [
    { route: ISSUE_ISSUE, title: 'Issues' },
    {
        route: '#',
        title: 'Dispatches',
    },
]
const Dispatch = () => {
    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ISSUE_DISPATCH}
            >
                <div>
                    <PageName name={'Dispatches'} />
                </div>
            </ComponentTooltip>

            <DispatchesTable />
        </>
    )
}
export default Dispatch
