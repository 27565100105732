import LabelStatus from '~/components/LabelStatus'
import { useTranslation } from 'react-i18next'
import { capitalize } from '~/utils/helpers'
import PropTypes from 'prop-types'

const LabelStatusUser = ({ status }) => {
    const { t } = useTranslation()

    return (
        <LabelStatus
            variant='ghost'
            color={
                (status === 'rejected' && 'error') ||
                (status === 'active' && 'success') ||
                (status === 'invited' && 'primary') ||
                'warning'
            }
        >
            {capitalize(t(`pages.user.userDetail.${status}`))}
        </LabelStatus>
    )
}

LabelStatusUser.propTypes = { status: PropTypes.string }

export default LabelStatusUser
