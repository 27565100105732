import TableFilters from '~/components/TableFilters'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const statusData = [
    { id: '', name: 'ALL' },
    { id: 'enable', name: 'ENABLE' },
    { id: 'disabled', name: 'DISABLED' },
]

const methodData = [
    { id: '', name: 'ALL' },
    { id: 'sendEmail', name: 'SEND EMAIL' },
    { id: 'store', name: 'STORE' },
]
const SchedulerTableFilters = ({
    search,
    searchHandler,
    status,
    setStatus,
    method,
    setMethod,
    onEnterSearch,
}) => {
    const selectGroup = useMemo(() => {
        return [
            {
                label: 'Status',
                handleFunc: setStatus,
                dataSet: statusData,
                value: status,
            },
            {
                label: 'Method',
                handleFunc: setMethod,
                dataSet: methodData,
                value: method,
            },
        ]
    }, [method, setMethod, setStatus, status])
    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='scheduler'
            page='scheduler'
        />
    )
}
SchedulerTableFilters.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    status: PropTypes.string,
    setStatus: PropTypes.func,
    method: PropTypes.string,
    setMethod: PropTypes.func,
    onEnterSearch: PropTypes.func,
}
export default SchedulerTableFilters
