import { Launch } from '@mui/icons-material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {
    Box,
    CircularProgress,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from '@mui/material'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TypographyTitleAccordion from '~/components/TypographyTitleAccordion'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { ISSUE_ISSUE_DETAIL } from '~/constants/Routes'
import DeviceEntitlements from '~/pages/Device/Serial/SerialDetail/DeviceEntitlements'
import { formatDateTime, getMaxDate } from '../../../../utils/helpers'
import DispatchWarningPopup from '../../component/DispatchWarningPopup'
import { DEVICE_DETAIL_LOCALIZATION } from '../constants'
import { useDeviceQuery } from '../query'
import Layout from './Section/Layout'
import { selectFormatDateState } from '~/store/formatdate/selector'
import { useSelector } from 'react-redux'

const DeviceSection = ({
    searchedSerial,
    step,
    setStep,
    setIsFoundSerialData,
    dispatch,
    isDispatchDetail = false,
    isCreateDispatch = false,
}) => {
    const { t } = useTranslation()
    const format = useSelector(selectFormatDateState)
    const [openPopup, setOpenPopup] = useState(false)
    const { data, isLoading, error } = useDeviceQuery({
        serial: searchedSerial,
        step,
        setStep,
        setIsFoundSerialData,
        isCreateDispatch,
    })

    const dispatches = useMemo(() => {
        if (error && error.response) {
            const { data: responseData, status } = error.response
            if (status === 409) {
                setOpenPopup(true)
                return responseData
            }
            return []
        }
    }, [error])

    const { openSnackbar } = useDialogContext()

    const deviceInformation = useMemo(() => {
        if (!data) return
        let tagsName = 'N/A'
        if (data?.device_tags && data?.device_tags.length) {
            tagsName = data?.device_tags
                .map((tags) => {
                    return tags.name
                })
                .join(`, `)
        }

        const manually = dispatch?.issue_id
            ? `Issue ${dispatch?.issue_id}`
            : 'Manual'

        const dispatchInfo = [
            {
                style: 'subtitle1',
                value: `Reference number : ${dispatch?.name ?? 'N/A'}`,
            },
            {
                style: 'subtitle1',
                value: `Created by : ${dispatch?.created_by?.name ?? 'N/A'}`,
            },
            {
                style: 'subtitle1',
                value: `Creation : ${manually}`,
                linkAble: dispatch?.issue_id,
                linkValue: dispatch?.issue_id,
            },
            {
                style: 'subtitle1',
                value: `Created at : ${formatDateTime(
                    dispatch?.created_at,
                    format,
                )}`,
            },
            {
                style: 'subtitle1',
                value: `Updated at : ${formatDateTime(
                    dispatch?.updated_at,
                    format,
                )}`,
            },
            {
                style: 'subtitle1',
                value: `Dispatch code : ${dispatch?.dispatch_code ?? 'N/A'}`,
                copyAble: true,
                copyValue: dispatch?.dispatch_code,
            },
            {
                style: 'subtitle1',
                value: `Status : ${dispatch?.status ?? 'N/A'}`,
            },
            {
                style: 'subtitle1',
                value: `DPS Number : ${dispatch?.dps_number ?? 'N/A'}`,
            },
            {
                style: 'subtitle1',
                value: `Waybill : ${dispatch?.waybill ?? 'N/A'}`,
            },
            {
                style: 'subtitle1',
                value: `Notes : ${dispatch?.notes ?? 'N/A'}`,
            },
            {
                style: 'subtitle1',
                value: `Troubleshooting notes : ${
                    dispatch?.troubleshooting_note ?? 'N/A'
                }`,
            },
            {
                style: 'subtitle1',
                value: `Service center : ${
                    dispatch?.service_center?.name ?? 'N/A'
                }`,
            },
            {
                style: 'subtitle1',
                value: `Ticket ID : ${dispatch?.ticket_id ?? 'N/A'}`,
            },
            {
                style: 'subtitle1',
                value: `ESD Location : ${dispatch?.esd_location ?? 'N/A'}`,
            },
            {
                style: 'subtitle1',
                value: `Description of problem : ${
                    dispatch?.description_of_problem ?? 'N/A'
                }`,
            },
        ]

        const info = [
            {
                style: 'subtitle1',
                value: `${t(
                    `${DEVICE_DETAIL_LOCALIZATION}.tags`,
                )} : ${tagsName}`,
            },
            {
                style: 'subtitle1',
                value: `${t(`${DEVICE_DETAIL_LOCALIZATION}.productNumber`)} : ${
                    data?.procurement_serial?.product_nr ?? 'N/A'
                }`,
            },
            {
                style: 'subtitle1',
                value: `${t(`${DEVICE_DETAIL_LOCALIZATION}.Vendor`)} : ${
                    data?.vendor?.name
                } | ${t(`${DEVICE_DETAIL_LOCALIZATION}.Family`)} : ${
                    data?.family
                }`,
            },
            {
                style: 'subtitle2',
                value: `${t(`${DEVICE_DETAIL_LOCALIZATION}.Model`)} : ${
                    data?.device_model?.description ?? 'N/A'
                } `,
            },
            {
                style: 'subtitle1',
                value: `${t(`${DEVICE_DETAIL_LOCALIZATION}.pkid`)} : ${
                    data?.pkid ?? 'N/A'
                } `,
            },
            {
                style: 'subtitle1',
                value: `${t(
                    `${DEVICE_DETAIL_LOCALIZATION}.express_servicecode`,
                )} : ${formatDateTime(data?.express_servicecode, format)} `,
            },
            {
                style: 'subtitle2',
                value: `${t(
                    `${DEVICE_DETAIL_LOCALIZATION}.latestEntitlementEndDate`,
                )} : ${formatDateTime(
                    data?.endDateEntitlement,
                    format,
                    'Europe/Amsterdam',
                    true,
                )} `,
            },
        ]

        if (isDispatchDetail) {
            return [...dispatchInfo, ...info]
        } else {
            return [...info]
        }
    }, [data, dispatch, isDispatchDetail, t, format])

    const lastUpdate = useMemo(() => {
        if (!data) return
        const updatedDevice = data?.updated_at
            ? data?.updated_at.toString()
            : null

        const procurementSerial = data?.procurement_serial
        const updatedProcurementSerial = procurementSerial?.updated_at
            ? procurementSerial?.updated_at.toString()
            : null

        const serialQueue = data?.serial_queue
        const updatedSerialQueue = serialQueue?.updated_at
            ? serialQueue?.updated_at.toString()
            : null

        return getMaxDate([
            updatedDevice,
            updatedProcurementSerial,
            updatedSerialQueue,
        ])
    }, [data])

    const copyValue = useCallback(
        (index) => {
            navigator.clipboard.writeText(deviceInformation[index].copyValue)
            openSnackbar({
                type: 'info',
                message: 'Copied to clipboard',
            })
        },
        [deviceInformation, openSnackbar],
    )

    if (!searchedSerial && !isDispatchDetail) return <></>

    if (!searchedSerial && isDispatchDetail) {
        return (
            <Paper
                sx={{
                    width: '100%',
                    px: 1,
                    py: 2,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '20px',
                }}
            >
                There is no serial in this dispatch!
            </Paper>
        )
    }
    if (isLoading)
        return (
            <Layout>
                <Grid
                    container
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                >
                    <CircularProgress />
                </Grid>
            </Layout>
        )

    if (!_.isEmpty(dispatches) && isCreateDispatch) {
        return (
            <DispatchWarningPopup
                open={openPopup}
                onClose={() => setOpenPopup(false)}
                dispatches={dispatches}
            />
        )
    }

    if (!data)
        return (
            <Layout>
                <Box>
                    <Typography gutterBottom align='center' variant='subtitle1'>
                        {t(`message.Notfound`)}
                    </Typography>
                </Box>
            </Layout>
        )
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Paper
                    sx={{
                        height: '100%',
                    }}
                >
                    <List dense={true}>
                        <ListItem>
                            <ListItemText
                                primary={
                                    <TypographyTitleAccordion
                                        gutterBottom
                                        variant='h6'
                                        component='div'
                                    >
                                        {data?.serial}
                                    </TypographyTitleAccordion>
                                }
                            />
                        </ListItem>
                        {deviceInformation.map((item, index) => {
                            return (
                                <ListItem
                                    key={index}
                                    sx={{ paddingTop: 0, paddingBottom: 0 }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography variant={item.style}>
                                                {item.copyAble ? (
                                                    <>
                                                        <span
                                                            id={`copy-${index}`}
                                                        >
                                                            {item.value}
                                                        </span>
                                                        <IconButton
                                                            onClick={(e) =>
                                                                copyValue(index)
                                                            }
                                                        >
                                                            <ContentCopyIcon />
                                                        </IconButton>
                                                    </>
                                                ) : item.linkAble ? (
                                                    <>
                                                        {item.value}
                                                        <Link
                                                            href={
                                                                ISSUE_ISSUE_DETAIL.replace(
                                                                    ':issueId',
                                                                    item?.linkValue,
                                                                ) + '?tag=1'
                                                            }
                                                            sx={{
                                                                verticalAlign:
                                                                    'top',
                                                            }}
                                                        >
                                                            <Launch
                                                                sx={{
                                                                    marginLeft:
                                                                        '5px',
                                                                }}
                                                            />
                                                        </Link>
                                                    </>
                                                ) : (
                                                    <>{item.value}</>
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            )
                        })}
                        <ListItem>
                            <Box
                                component='span'
                                className='material-symbols-rounded'
                                sx={{
                                    color: data?.state?.icon_color,
                                }}
                            >
                                {data?.state?.icon}
                            </Box>
                            <ListItemText
                                primary={`${t(
                                    `${DEVICE_DETAIL_LOCALIZATION}.lastUpdate`,
                                )}: ${formatDateTime(lastUpdate, format)} `}
                            />
                        </ListItem>
                    </List>
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper
                    sx={{
                        py: 1,
                        px: 2,
                        height: '100%',
                    }}
                >
                    <Typography variant='h6' gutterBottom>
                        {t(`${DEVICE_DETAIL_LOCALIZATION}.entitlements`)}
                    </Typography>
                    <DeviceEntitlements deviceId={data.id} />
                </Paper>
            </Grid>
        </Grid>
    )
}

DeviceSection.propTypes = {
    searchedSerial: PropTypes.string,
    step: PropTypes.number,
    setStep: PropTypes.func,
    setIsFoundSerialData: PropTypes.func,
    dispatch: PropTypes.object,
    isDispatchDetail: PropTypes.bool,
    isCreateDispatch: PropTypes.bool,
}

export default DeviceSection
