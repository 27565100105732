import styled from '@emotion/styled'
import {
    Box,
    Divider,
    FormControl,
    Grid,
    Modal,
    Stack,
    Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import SubmitButton from '~/components/SubmitButton'
import { useCreateAddressMutation } from '../mutation'
import ErrorMessageInput from '~/components/ErrorMessageInput'
import { useMemo } from 'react'
import { useCountriesListQuery, useTimezonesListQuery } from '../query'
import { object, string } from 'yup'
import useFormValidationCallback from '~/hooks/useFormValidationCallback'

import ComponentTooltip from '~/components/ComponentTooltip'
import InputField from '~/components/FinalForm/InputField'
import SelectField from '~/components/FinalForm/SelectField'
import { ADMINISTRATOR_DEFINITION_SERVICECENTER_NEW } from '~/constants/Routes'
import PropTypes from 'prop-types'

const localization = 'pages.administrator.definition.address.create'
const pagePath = ADMINISTRATOR_DEFINITION_SERVICECENTER_NEW
const StyledModal = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})

const AddAddressPopup = ({ openAddAddress, setOpenAddAddress }) => {
    const { t } = useTranslation()
    const schema = object().shape({
        name: string().required(),
        street_1: string().required(),
        city: string().required(),
        zipcode: string().required(),
        iso_country_code: string().required(),
        timezone_zone: string().required(),
    })
    const validate = useFormValidationCallback(schema)

    const handleClose = () => {
        setOpenAddAddress(false)
    }

    const {
        mutate: createAddress,
        isLoading,
        isError,
    } = useCreateAddressMutation({ setOpenAddAddress })

    const { data: countries } = useCountriesListQuery({
        menuOption: true,
    })

    const countriesList = useMemo(() => {
        if (countries) {
            return countries.map((country) => ({
                name: country.name,
                value: country.code,
            }))
        }
        return []
    }, [countries])

    const { data: timezones } = useTimezonesListQuery({
        menuOption: true,
    })

    const timezonesList = useMemo(() => {
        if (timezones) {
            return timezones.map((timezone) => ({
                name: timezone.zone,
                value: timezone.zone,
            }))
        }
        return []
    }, [timezones])

    const inputFullWidthFields = useMemo(() => {
        return [
            {
                name: 'name',
                isRequired: true,
                componentId: 'address_popup_name',
            },
            {
                name: 'street_1',
                isRequired: true,
                componentId: 'address_popup_street1',
            },
            {
                name: 'street_2',
                isRequired: false,
                componentId: 'address_popup_street2',
            },
            {
                name: 'street_3',
                isRequired: false,
                componentId: 'address_popup_street3',
            },
            {
                name: 'city',
                isRequired: true,
                componentId: 'address_popup_city',
            },
        ]
    }, [])

    const inputHalfWidthFields = useMemo(() => {
        return [
            {
                name: 'zipcode',
                isRequired: true,
                componentId: 'address_popup_zipcode',
            },
            {
                name: 'state',
                isRequired: false,
                componentId: 'address_popup_state',
            },
            {
                name: 'iso_country_code',
                isRequired: true,
                component: SelectField,
                listResource: countriesList,
                componentId: 'address_popup_country',
            },
            {
                name: 'timezone_zone',
                isRequired: true,
                component: SelectField,
                listResource: timezonesList,
                componentId: 'address_popup_timezone',
            },
        ]
    }, [countriesList, timezonesList])

    return (
        <StyledModal
            open={openAddAddress}
            onClose={() => handleClose()}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box
                bgcolor={'background.default'}
                color={'text.primary'}
                p={3}
                borderRadius={5}
                width={700}
            >
                <ComponentTooltip
                    componentId={`address_popup_title`}
                    pagePath={pagePath}
                    align='center'
                >
                    <Typography variant='h6' color='gray' textAlign='center'>
                        Add Address
                    </Typography>
                </ComponentTooltip>

                <Divider sx={{ my: 2 }} />
                {isError && (
                    <ErrorMessageInput>
                        {t(`message.somethingWrong`)}
                    </ErrorMessageInput>
                )}
                <Form
                    onSubmit={createAddress}
                    validate={validate}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {inputFullWidthFields.map((item, idx) => {
                                        return (
                                            <Grid item xs={12} key={idx}>
                                                <InputField
                                                    localization={localization}
                                                    name={item.name}
                                                    componentId={`${item.componentId}`}
                                                    pagePath={pagePath}
                                                    isRequired={item.isRequired}
                                                    placeholder={t(
                                                        `${localization}.${item.name}`,
                                                    )}
                                                />
                                            </Grid>
                                        )
                                    })}
                                    <Grid container spacing={2}>
                                        {inputHalfWidthFields.map(
                                            (item, idx) => {
                                                return (
                                                    <Grid item xs={6} key={idx}>
                                                        {!item.component && (
                                                            <InputField
                                                                localization={
                                                                    localization
                                                                }
                                                                name={item.name}
                                                                componentId={`${item.componentId}`}
                                                                pagePath={
                                                                    pagePath
                                                                }
                                                                isRequired={
                                                                    item.isRequired
                                                                }
                                                                placeholder={t(
                                                                    `${localization}.${item.name}`,
                                                                )}
                                                            />
                                                        )}
                                                        {item.component ===
                                                            SelectField && (
                                                            <FormControl
                                                                sx={{
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <Field
                                                                    name={
                                                                        item.name
                                                                    }
                                                                    component={
                                                                        item.component
                                                                    }
                                                                    items={
                                                                        item.listResource
                                                                    }
                                                                    localization={
                                                                        localization
                                                                    }
                                                                    componentId={
                                                                        item.componentId
                                                                    }
                                                                    pagePath={
                                                                        pagePath
                                                                    }
                                                                    nameSelect={
                                                                        item.title ??
                                                                        item.name
                                                                    }
                                                                    isRequired={
                                                                        item.isRequired
                                                                    }
                                                                />
                                                            </FormControl>
                                                        )}
                                                    </Grid>
                                                )
                                            },
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction='row-reverse' spacing={2}>
                                        <SubmitButton
                                            isLoading={isLoading}
                                            handleSubmit={handleSubmit}
                                            name={`${t('button.add')}`}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                />
            </Box>
        </StyledModal>
    )
}

AddAddressPopup.propTypes = {
    openAddAddress: PropTypes.bool,
    setOpenAddAddress: PropTypes.func,
}

export default AddAddressPopup
