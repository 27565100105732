import {
    ISSUE_ISSUE,
    ISSUE_RACKSCAN_SCAN,
    ISSUE_RACKSCAN_SCAN_DETAIL,
    ISSUE_RACKSCAN_TICKET,
} from '~/constants/Routes'
import BreadCrumb from '~/components/BreadCrumb'
import PageName from '../../../components/PageName'
import { useParams } from 'react-router-dom'
import { useScanDetailQuery } from '../query'
import Loading from '~/components/Loading'
import { useEffect } from 'react'
import RackScanContent from './RackScanContent'
import { useDispatch, useSelector } from 'react-redux'
import { selectSnackbar } from '~/store/snackbar/selector'
import { unsetSnackbar } from '~/store/snackbar/slice'
import { useSnackbar } from '~/hooks/useSnackbar'
import ComponentTooltip from '~/components/ComponentTooltip'

const ScanDetail = () => {
    const { id } = useParams()
    const dispatch = useDispatch()

    const { data, isLoading } = useScanDetailQuery(id)
    const snackbarData = useSelector(selectSnackbar)
    const { showSnackbar, snackbar } = useSnackbar()
    useEffect(() => {
        if (snackbarData) {
            showSnackbar(snackbarData.message, snackbarData.type)
        }
        return () => {
            dispatch(unsetSnackbar({}))
        }
    })

    const BreadComponents = [
        { route: ISSUE_ISSUE, title: 'Issues' },
        { route: ISSUE_RACKSCAN_TICKET, title: 'rackScans' },
        {
            route: ISSUE_RACKSCAN_SCAN,
            title: 'scan',
        },
        {
            page: 'detail',
            title: id,
        },
    ]

    if (isLoading) {
        return <Loading />
    }
    return (
        <>
            {snackbar}
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ISSUE_RACKSCAN_SCAN_DETAIL}
            >
                <div>
                    <PageName name={'Scan Information'} />
                </div>
            </ComponentTooltip>

            <RackScanContent rackScan={data.data} />
        </>
    )
}
export default ScanDetail
