import { Paper, Table, TableContainer } from '@mui/material'
import PaginationTable from '~/components/PaginationTable'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import { useUsersTeamsQuery } from '~/pages/UserDetail/query'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectUserDetailInformation } from '~/store/user/selector'
import UserTeamBody from './UserTeamBody'
import { headCells } from './constants'
import { useMemo } from 'react'

const localization = 'pages.userTeam.'

const UserTeams = () => {
    const { id } = useParams()

    const selectUser = useSelector(selectUserDetailInformation)
    const currentTeamId = selectUser?.current_team_id

    const {
        results: teams,
        data,
        isLoading,
        page,
        order,
        orderBy,
        sortHandler,
        changePageHandler,
    } = useTable({
        query: useUsersTeamsQuery,
        queryAttributes: {
            id,
        },
    })

    const copiedHeadCells = useMemo(() => {
        if (selectUser.status === 'active' && teams.length === 1) {
            return headCells.slice(0, -1)
        }
        return headCells
    }, [selectUser, teams])

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHeader
                        {...{
                            localization,
                            headCells: copiedHeadCells,
                            onRequestSort: sortHandler,
                            order,
                            orderBy,
                        }}
                    />
                    <UserTeamBody {...{ isLoading, teams, currentTeamId }} />
                </Table>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </>
    )
}

export default UserTeams
