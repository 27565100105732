import { FormControl, Stack, Typography } from '@mui/material'
import ComponentTooltip from '~/components/ComponentTooltip'
import AsterisksRequiredField from '~/components/FormInput/AsterisksRequiredField'
import FormInput from '~/components/FormInput/FormInput'
import { ISSUE_DISPATCH_ADD } from '~/constants/Routes'
import { integer } from '~/constants/yupSchemas'
import useFormFieldValidationSyncCallback from '~/hooks/useFormFieldValidationSyncCallback'
import { useMemo } from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectUser } from '~/store/auth/selector'
import { string } from 'yup'
import { LOCALIZATION } from '../constants'
import { useServiceCenterQuery } from '../query'
import Select from './Input/Select'
import Layout from './Section/Layout'
import PropTypes from 'prop-types'
const pagePath = ISSUE_DISPATCH_ADD

const ServiceCenterSection = ({
    show,
    setTicketId,
    defaultCenter,
    isInEditPage = false,
    innerRef,
}) => {
    const { t } = useTranslation()
    const { data, isLoading } = useServiceCenterQuery({}, { enabled: !!show })

    const user = useSelector(selectUser)

    const items = useMemo(() => {
        if (isLoading || data === undefined) return
        return data.map((item) => ({ value: item?.id, name: item?.name }))
    }, [data, isLoading])
    const intValidator = useFormFieldValidationSyncCallback(
        integer(t).required(),
    )
    const stringValidator = useFormFieldValidationSyncCallback(
        string().required(),
    )
    const stringLengthValidator = useFormFieldValidationSyncCallback(
        string().required().max(50),
    )
    const defaultServiceCenter = useMemo(() => {
        if (defaultCenter) {
            return defaultCenter
        }
        if (user?.current_team?.service_center) {
            return user?.current_team?.service_center.id
        }
        if (user?.service_centers?.length === 1) {
            return user?.service_centers[0].id
        }
        return undefined
    }, [
        defaultCenter,
        user?.current_team?.service_center,
        user?.service_centers,
    ])

    if (!show || isLoading) return <></>
    return (
        <Layout>
            <Stack spacing={2} ref={innerRef}>
                <FormControl>
                    <ComponentTooltip
                        componentId={`dispatch_add_service_center`}
                        pagePath={pagePath}
                    >
                        <Typography>
                            {t(`${LOCALIZATION}.serviceCenter`)}
                            <AsterisksRequiredField />
                        </Typography>
                    </ComponentTooltip>
                    <Field
                        name='service_center'
                        component={Select}
                        initialValue={defaultServiceCenter}
                        items={items}
                        sx={{ width: '100%' }}
                        validate={(value) => intValidator(value)}
                        disabled={isInEditPage}
                    />
                </FormControl>
                <FormControl>
                    <ComponentTooltip
                        componentId={`dispatch_add_ticket_id`}
                        pagePath={pagePath}
                    >
                        <Typography>
                            {t(`${LOCALIZATION}.ticketId`)}
                            <AsterisksRequiredField />
                        </Typography>
                    </ComponentTooltip>
                    <Field
                        name='ticket_id'
                        component={FormInput}
                        sx={{ width: '100%' }}
                        validate={(value) => stringValidator(value)}
                        onBlur={(e) => setTicketId(e.target.value)}
                        disabled={isInEditPage}
                    />
                </FormControl>
                <FormControl>
                    <ComponentTooltip
                        componentId={`dispatch_add_esd_location`}
                        pagePath={pagePath}
                    >
                        <Typography>
                            {t(`${LOCALIZATION}.esdLocation`)}
                            <AsterisksRequiredField />
                        </Typography>
                    </ComponentTooltip>
                    <Field
                        name='esd_location'
                        component={FormInput}
                        sx={{ width: '100%' }}
                        validate={(value) => stringValidator(value)}
                        disabled={isInEditPage}
                    />
                </FormControl>
                <FormControl>
                    <ComponentTooltip
                        componentId={`dispatch_add_description_of_problem`}
                        pagePath={pagePath}
                    >
                        <Typography>
                            {t(`${LOCALIZATION}.descriptionOfProblem`)}
                            <AsterisksRequiredField />
                        </Typography>
                    </ComponentTooltip>
                    <Field
                        name='description_of_problem'
                        component={FormInput}
                        sx={{ width: '100%' }}
                        validate={(value) => stringLengthValidator(value)}
                        disabled={isInEditPage}
                    />
                </FormControl>
            </Stack>
        </Layout>
    )
}

ServiceCenterSection.propTypes = {
    show: PropTypes.bool,
    setTicketId: PropTypes.func,
    defaultCenter: PropTypes.any,
    isInEditPage: PropTypes.bool,
    innerRef: PropTypes.any,
}
export default ServiceCenterSection
