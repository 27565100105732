import {
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import DataNotFound from '~/components/DataNotFound'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'
import { FleetHeadCells } from '~/components/HeaderTable/FleetHeadCells'
import PaginationTable from '~/components/PaginationTable'
import ReferencedEntities from '~/components/ReferencedEntities'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import FleetListToolbar from './FleetListToolbar'
import FleetSearchToolbar from './FleetSearchToolbar'
import { useFleetOverviewQuery } from './query'

const localization = 'pages.device.fleet.table.'

const FleetTableBody = ({ isLoading, fleet }) => {
    if (isLoading) {
        return <TableLoading colSpan={FleetHeadCells.length} />
    }

    return (
        <TableBody>
            {fleet.length > 0 ? (
                fleet.map((row, index) => {
                    return (
                        <TableRow hover tabIndex={-1} key={index}>
                            <ReferencedEntities
                                isTableCell={true}
                                dataType={'#'}
                                dataIdentifier={row.vendor_name}
                                dataDisplay={row.vendor_name}
                            />
                            <TableCell align='left'>{row.category}</TableCell>
                            <TableCell align='left'>
                                {row.device_name}
                            </TableCell>
                            <TableCell align='left'>
                                {row.device_tag_name}
                            </TableCell>
                            <TableCell align='center'>
                                {row.device_count}
                            </TableCell>
                            <TableCell align='center'>
                                <DateTimeLocale
                                    datetime={row.oldest}
                                    formatHour={true}
                                />
                            </TableCell>
                            <TableCell align='center'>
                                <DateTimeLocale
                                    datetime={row.newest}
                                    formatHour={true}
                                />
                            </TableCell>
                            <TableCell align='center'>
                                {row.in_warranty ?? 0}
                            </TableCell>
                            <TableCell align='center'>
                                {row.out_of_warranty ?? 0}
                            </TableCell>
                            <TableCell align='center'>
                                {row.expire_in_year_warranty ?? 0}
                            </TableCell>
                            <TableCell align='center'>
                                {row.active ?? 0}
                            </TableCell>
                            <TableCell align='center'>
                                {row.stock ?? 0}
                            </TableCell>
                            <TableCell align='center'>
                                {row.other ?? 0}
                            </TableCell>
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={FleetHeadCells.length} />
            )}
        </TableBody>
    )
}

FleetTableBody.propTypes = {
    isLoading: PropTypes.bool,
    fleet: PropTypes.arrayOf(PropTypes.object),
}

const FleetTable = () => {
    const [vendor, setVendor] = useState('')

    const {
        results: fleet,
        data,
        isLoading,
        page,
        setPage,
        order,
        orderBy,
        search,
        searchHandler,
        sortHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useFleetOverviewQuery,
        queryAttributes: {
            vendor,
        },
    })

    const refValue = useRef({
        vendor: '',
    })

    useEffect(() => {
        if (vendor !== refValue.current.vendor) {
            setPage(1)
            refValue.current.vendor = vendor
        }
    }, [setPage, vendor])

    return (
        <>
            <FleetListToolbar payload={{ vendor, search, order, orderBy }} />

            <Paper sx={{ width: '100%', mb: 2 }}>
                <FleetSearchToolbar
                    searchValue={search}
                    searchHandler={searchHandler}
                    vendor={vendor}
                    setVendor={setVendor}
                    onEnterSearch={onEnterSearch}
                />
                <Divider />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby='tableTitle'
                        size='medium'
                    >
                        <TableHeader
                            headCells={FleetHeadCells}
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                            localization={localization}
                        />
                        <FleetTableBody isLoading={isLoading} fleet={fleet} />
                    </Table>
                </TableContainer>
                <PaginationTable
                    data={data}
                    page={page}
                    size='medium'
                    handleChangePage={changePageHandler}
                />
            </Paper>
        </>
    )
}
export default FleetTable
