import TableFilters from '~/components/TableFilters'
import { useServiceCenterListQuery } from '~/pages/ServiceCenter/query'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const WorkbenchTableFilters = ({
    search,
    searchHandler,
    service,
    setService,
    onEnterSearch,
}) => {
    const { data: serviceCenterData, isSuccess: isSuccessServiceCenter } =
        useServiceCenterListQuery({
            menuOption: true,
        })

    const ServiceCenter = useMemo(() => {
        if (isSuccessServiceCenter) {
            const result = [...serviceCenterData?.data]
            result.unshift({ id: '', name: 'ALL' })
            return result
        }
        return []
    }, [isSuccessServiceCenter, serviceCenterData?.data])

    const selectGroup = useMemo(() => {
        return [
            {
                label: 'Center',
                handleFunc: setService,
                dataSet: ServiceCenter,
                value: service,
            },
        ]
    }, [ServiceCenter, service, setService])
    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='workbenches'
            page='workbenches'
        />
    )
}
WorkbenchTableFilters.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    service: PropTypes.string,
    setService: PropTypes.func,
    onEnterSearch: PropTypes.func,
}
export default WorkbenchTableFilters
