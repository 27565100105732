import { useQuery } from 'react-query'
import imagesApi from '~/api/production/imagesApi'
import { parseApiErrorMessage, removeEmptyValue } from '~/utils/helpers'

export const useImagesQuery = (params) => {
    return useQuery(['production-images', params], async () => {
        const p = removeEmptyValue(params)
        const { data } = await imagesApi.getProductionImages(p)
        return { data: data?.data, total: data?.meta?.total }
    })
}

export const useImageQuery = ({ id, showSnackbar }) => {
    return useQuery(
        ['production-image', id],
        async () => {
            const { data } = await imagesApi.getProductionImage(id)
            return data
        },
        {
            onSuccess: (data) => {},
            onError: (error) => {
                showSnackbar(parseApiErrorMessage(error), 'error')
            },
            retry: false,
        },
    )
}

export const useImageVersionsQuery = ({ id, showSnackbar }) => {
    return useQuery(
        ['production-image-versions', id],
        async () => {
            const data = await imagesApi.getProductionImageVersions(id)
            return data.data
        },
        {
            onSuccess: (data) => {},
            onError: (error) => {
                showSnackbar(parseApiErrorMessage(error), 'error')
            },
            retry: false,
        },
    )
}
