import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import PageName from '~/components/PageName'
import { ADMINISTRATOR, ADMINISTRATOR_USER } from '~/constants/Routes'
import UsersTable from './UsersTable'

const User = () => {
    const BreadComponents = [
        { route: ADMINISTRATOR, title: 'Administrator' },
        {
            route: ADMINISTRATOR_USER,
            title: 'Users',
        },
    ]
    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_USER}
            >
                <div>
                    <PageName name='Users' />
                </div>
            </ComponentTooltip>
            <UsersTable />
        </>
    )
}
export default User
