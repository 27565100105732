import {
    useDellTechDirectApiQuery,
    useServiceCenterRegionQuery,
} from '~/pages/Region/query'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

const useRegionForm = () => {
    const { id } = useParams()
    const { data: serviceCenterRegion } = useServiceCenterRegionQuery({
        id,
    })
    const { data: dellTechDirectData } = useDellTechDirectApiQuery()

    const dellTechDirectApiList = useMemo(() => {
        if (dellTechDirectData) {
            const result = dellTechDirectData.data.map((config) => ({
                name: config.configurations?.name,
                value: config.id,
            }))

            return result
        }
        return []
    }, [dellTechDirectData])

    const serviceCenterData = useMemo(() => {
        if (serviceCenterRegion) {
            return serviceCenterRegion.data.map((sc) => ({
                name: sc.name,
                value: sc.id,
            }))
        }
        return []
    }, [serviceCenterRegion])

    const selectGroup = useMemo(() => {
        return [
            {
                title: 'dell_tech_direct_api_connector',
                name: 'dell_dispatch_api_id',
                listResource: dellTechDirectApiList,
                componentId: 'region_form_dell_dispatch_connection',
                required: true,
            },
            {
                title: 'mainServiceCenter',
                name: 'main_service_center_id',
                listResource: serviceCenterData,
                componentId: 'region_form_service_center',
            },
        ]
    }, [dellTechDirectApiList, serviceCenterData])

    const InputFieldGroup = useMemo(() => {
        return [
            {
                name: 'name',
                componentId: 'region_form_name',
                isRequired: true,
            },
            {
                name: 'description',
                componentId: 'region_form_description',
            },
        ]
    }, [])

    return {
        InputFieldGroup,
        selectGroup,
    }
}

export default useRegionForm
