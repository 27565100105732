import styled from '@emotion/styled'
import { OutlinedInput } from '@mui/material'

const BlackOutlineInput = styled(OutlinedInput)({
    root: {
        '&$focused $notchedOutline': {
            borderColor: 'blue',
        },
    },
    focused: {},
    notchedOutline: {},
})

export default BlackOutlineInput
