import diagnosticApi from '~/api/diagnosticApi'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import useQuery from '~/hooks/useQuery'
import { useDispatch, useSelector } from 'react-redux'
import { selectShowedPartCodeForDiagnostic } from '~/store/partcode/selector'
import { pushShowPartCodesForDiagnostic } from '~/store/partcode/slice'
import { DIAGNOSTIC, DIAGNOSTIC_DETAIL } from '~/constants/Routes'
import { useSupportObject } from '~/hooks/useSupportObject'
import { setDiagnosticSerial, setIssueDiagnostic } from '~/store/diagnose/slice'
import { DEFAULT_CACHE_TTL } from '~/utils/constants'
import { deviceApi } from '~/api'
import { setSnackbar } from '~/store/snackbar/slice'
import { parseErrorMessageAndShow, parseApiErrorMessage } from '~/utils/helpers'

export const usePictureQuery = (issueId) => {
    const dispatch = useDispatch()
    const { openSnackbar } = useDialogContext()
    return useQuery(
        ['diagnostic_picture', issueId],
        async () => {
            const { data } = await diagnosticApi.getPictures(issueId)
            return data
        },
        {
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
            onSuccess: (data) => {
                if (data.issue_diagnostic) {
                    dispatch(
                        setIssueDiagnostic({
                            issueId,
                            issueDiagnostic: data.issue_diagnostic,
                        }),
                    )
                }
            },
            onError: (error) => {
                parseErrorMessageAndShow(error, openSnackbar)
            },
        },
    )
}

export const useScanIssueQuery = ({ serial, enabled, setError }) => {
    const { navigate, dispatch } = useSupportObject()
    return useQuery(
        ['diagnostic_scan_issue', serial],
        async () => {
            const { data } = await diagnosticApi.scanIssue(serial)
            return data.data
        },
        {
            onSuccess: (data) => {
                dispatch(setDiagnosticSerial(serial))
                if (data.diagnostic?.id) {
                    navigate(
                        DIAGNOSTIC_DETAIL.replace(':issueId', data.id).replace(
                            ':diagnosticId',
                            data.diagnostic.id,
                        ),
                    )
                } else {
                    navigate(DIAGNOSTIC.replace(':issueId', data.id))
                }
            },
            onError: () => {
                setError('Issue not found')
            },
            enabled,
            retry: false,
        },
    )
}

export const useRootCausesQuery = (diagnosticsId, params) => {
    const { openSnackbar } = useDialogContext()
    return useQuery(
        ['diagnostic_root_causes', params],
        async () => {
            const apiFunc = params.saved
                ? diagnosticApi.getSavedCauses
                : diagnosticApi.getRootCauses

            const { data } = await apiFunc(diagnosticsId, params)
            return data.data
        },
        {
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
            onError: (error) => {
                parseErrorMessageAndShow(error, openSnackbar)
            },
            enabled: !!diagnosticsId,
        },
    )
}

export const useIssueDiagnoseQuery = ({ id, isConfirmForm = false }) => {
    const { openSnackbar } = useDialogContext()
    return useQuery(
        [
            isConfirmForm
                ? 'issue_diagnostic_confirm_form'
                : 'issue_diagnostic',
            id,
        ],
        async () => {
            const { data } = await diagnosticApi.getIssueDiagnostic(id)
            return data.data
        },
        {
            onError: (error) => {
                parseErrorMessageAndShow(error, openSnackbar)
            },
            enabled: !!id,
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
        },
    )
}

export const useTotalLossCheckQuery = ({ id, isTriggerTotalLossCheck }) => {
    return useQuery(
        ['total_loss_check', id],
        async () => {
            const { data } = await diagnosticApi.getTotalLossCheck(id)
            return data
        },
        {
            enabled: !!isTriggerTotalLossCheck || !!id,
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
        },
    )
}

// get part code through issue -> cause ->parts
export const useCausesPartIssueDiagnosticQuery = (issueId, isFetchPartCode) => {
    const showingPartCode = useSelector(selectShowedPartCodeForDiagnostic)
    const dispatch = useDispatch()
    const { openSnackbar } = useDialogContext()
    return useQuery(
        ['cause_part_issue_diagnostic', issueId],
        async () => {
            const { data } =
                await diagnosticApi.getCausePartIssueDiagnostic(issueId)
            return data.data
        },
        {
            onSuccess: (data) => {
                const newCausePartCode = data.map((partCode) => {
                    return {
                        ...partCode,
                        broken: null,
                    }
                })
                const partCodeList = [...showingPartCode]

                if (partCodeList.length === 0) {
                    dispatch(pushShowPartCodesForDiagnostic(newCausePartCode))
                }
            },
            onError: (error) => {
                parseErrorMessageAndShow(error, openSnackbar)
            },
            enabled: !!isFetchPartCode,
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
        },
    )
}

// get part code through issue ->parts
export const useIssuePartCodeQuery = (
    issueId,
    useIssuePartCodeQuery,
    isDispatchedData,
) => {
    const { openSnackbar } = useDialogContext()
    const dispatch = useDispatch()
    return useQuery(
        ['part_issue', issueId],
        async () => {
            const { data } = await diagnosticApi.getPartIssueDiagnostic(issueId)
            return data.data
        },
        {
            onSuccess: (data) => {
                if (
                    typeof isDispatchedData === 'undefined' ||
                    isDispatchedData === true
                ) {
                    dispatch(pushShowPartCodesForDiagnostic(data))
                }
            },
            onError: (error) => {
                parseErrorMessageAndShow(error, openSnackbar)
            },
            enabled: !!useIssuePartCodeQuery,
            retry: false,
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
        },
    )
}

export const useGetDiagnosisFromIssueQuery = (issueId) => {
    return useQuery(
        ['diagnosis_from_issue', issueId],
        async () => {
            const { data } = await diagnosticApi.getDiagnosisFromIssue(issueId)
            return data.data
        },
        {
            retry: false,
        },
    )
}

export const useTicketDetailsQuery = (id) => {
    const { openSnackbar } = useDialogContext()
    return useQuery(
        ['ticket_details', id],
        async () => {
            const { data } = await diagnosticApi.getTicketDetails(id)
            return data
        },
        {
            onError: (error) => {
                parseErrorMessageAndShow(error, openSnackbar)
            },
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
        },
    )
}

export const useIssueLogsQuery = (issueData) => {
    const { openSnackbar } = useDialogContext()
    return useQuery(
        ['issue_logs', issueData],
        async () => {
            const { data } = await diagnosticApi.getIssueLogs(
                issueData.id,
                issueData,
            )
            return { data: data.data, total: data.total }
        },
        {
            onError: (error) => {
                parseErrorMessageAndShow(error, openSnackbar)
            },
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
        },
    )
}

export const useDeviceHistoriesQuery = (params) => {
    const { openSnackbar } = useDialogContext()
    return useQuery(
        ['device_histories', params],
        async () => {
            const { data } = await diagnosticApi.getDeviceHistories(
                params.id,
                params,
            )
            return { data: data.data, total: data.total }
        },
        {
            onError: (error) => {
                parseErrorMessageAndShow(error, openSnackbar)
            },
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
        },
    )
}

export const useDeviceHistoriesViaSerialQuery = (params) => {
    const { openSnackbar } = useDialogContext()
    return useQuery(
        ['device_histories_via_serial', params],
        async () => {
            const { data } = await diagnosticApi.getDeviceHistoryForIssue(
                params.id,
                params,
            )
            return { data: data.data, total: data.total }
        },
        {
            onError: (error) => {
                parseErrorMessageAndShow(error, openSnackbar)
            },
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
        },
    )
}

export const useDeviceImagesQuery = (params) => {
    const dispatch = useDispatch()
    return useQuery(
        ['device_image_via_serial', params],
        async () => {
            const { data } = await diagnosticApi.getDeviceImageForIssue(
                params.id,
                params,
            )
            return { data: data.data, total: data.total }
        },
        {
            onError: (error) => {
                dispatch(
                    setSnackbar({
                        message: parseApiErrorMessage(error),
                        type: 'error',
                    }),
                )
            },
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
        },
    )
}

export const useDeviceCheckPowerQuery = (params) => {
    const dispatch = useDispatch()
    return useQuery(
        ['device_check_power', params],
        async () => {
            const { data } = await deviceApi.getDeviceCheckPower(params.serial)
            return data.data
        },
        {
            retry: false,
            onError: (error) => {
                dispatch(
                    setSnackbar({
                        message: parseApiErrorMessage(error),
                        type: 'error',
                    }),
                )
            },
        },
    )
}

export const useDeviceCheckHidQuery = (params) => {
    const dispatch = useDispatch()
    return useQuery(
        ['device_check_hid', params],
        async () => {
            const { data } = await deviceApi.getDeviceCheckHid(params.serial)
            return data.data
        },
        {
            retry: false,
            onError: (error) => {
                dispatch(
                    setSnackbar({
                        message: parseApiErrorMessage(error),
                        type: 'error',
                    }),
                )
            },
        },
    )
}
