import roleApi from '~/api/acl/roleApi'
import { useQuery } from 'react-query'
import permissionApi from '~/api/acl/permissionApi'

export const useRolesQuery = (params) => {
    return useQuery(
        ['acl_roles_detail', params],
        async () => {
            const { data } = await roleApi.getRole(params.id)
            return { data: data.data }
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useGetAllPermissionsQuery = (guardName) => {
    return useQuery(
        ['acl_roles_detail', 'get_all_permissions', guardName],
        async () => {
            const { data } =
                await permissionApi.getAllPermissionsByGuard(guardName)
            return data.data
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}
