import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import PropTypes from 'prop-types'

const ItemList = ({ items }) => {
    return (
        <TableContainer component={Paper}>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Item number</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {items?.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>{item.id}</TableCell>
                            <TableCell>{item.item_number}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

ItemList.propTypes = {
    items: PropTypes.array.isRequired,
}

export default ItemList
