import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { LoadingButton } from '~/components/Button'
import { useAttachPartCodeToCause } from '~/pages/Cause/mutation'
import PropTypes from 'prop-types'

const PartCodeListSelectorComponent = ({ partCode, selectedModelIds }) => {
    const { mutate: attachPartCodeToCause, isLoading } =
        useAttachPartCodeToCause()
    const addPart = () => {
        attachPartCodeToCause({
            part_code: partCode.code,
            model_ids: selectedModelIds,
        })
    }

    return (
        <TableRow>
            <TableCell>{partCode.code}</TableCell>
            <TableCell>{partCode.name}</TableCell>
            <TableCell align='center'>
                <LoadingButton
                    disabled={partCode.disabled}
                    loading={isLoading}
                    onClick={addPart}
                    label='Add'
                />
            </TableCell>
        </TableRow>
    )
}

PartCodeListSelectorComponent.propTypes = {
    partCode: PropTypes.object,
    selectedModelIds: PropTypes.array,
}

PartCodeListSelectorComponent.defaultProps = {
    selectedModelIds: [],
}

export default PartCodeListSelectorComponent
