import purchaseOrderApi from '~/api/purchaseOrderApi'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { setPurchaseOrderLineDetail } from '~/store/purchaseOrder/slice'
import { removeEmptyValue } from '~/utils/helpers'

export const usePurchaseOrderQuery = (params) => {
    const p = removeEmptyValue(params)
    return useQuery(
        ['PurchaseOrder', params],
        async () => {
            const { data } = await purchaseOrderApi.getPurchaseOrders(p)
            return { data: data.data, total: data.meta.total }
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const usePurchaseOrderDetailQuery = ({ id, ...params }) => {
    return useQuery(
        ['Purchase_order_detail', id, params],
        async () => {
            const { data } = await purchaseOrderApi.getPurchaseOrderDetail(
                id,
                params,
            )
            return { data: data.data, total: data.meta.total }
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useOrderLineDetailQuery = (id) => {
    const dispatch = useDispatch()
    return useQuery(
        ['Purchase_order_detail', id],
        async () => {
            const { data } =
                await purchaseOrderApi.getPurchaseOrderLineDetail(id)
            return data.data
        },
        {
            onSuccess: (data) => {
                dispatch(
                    setPurchaseOrderLineDetail({
                        ...data,
                    }),
                )
            },
            onError: () => {},
            retry: false,
        },
    )
}
