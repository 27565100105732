import { createSlice } from '@reduxjs/toolkit'

export const procurementStateSlice = createSlice({
    name: 'procurementState',
    initialState: {
        orderState: null,
        invoiceState: null,
        deliveryState: null,
        deliveryDetailState: null,
    },
    reducers: {
        setOrderState: (state, action) => {
            state.orderState = action.payload
        },
        setInvoiceState: (state, action) => {
            state.invoiceState = action.payload
        },
        setDeliveryState: (state, action) => {
            state.deliveryState = action.payload
        },
        setDeliveryDetailState: (state, action) => {
            state.deliveryDetailState = action.payload
        },
    },
})

export const {
    setOrderState,
    setInvoiceState,
    setDeliveryState,
    setDeliveryDetailState,
} = procurementStateSlice.actions

export default procurementStateSlice
