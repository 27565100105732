import axiosClient, { clientDownload } from '~/api/base'

class InvoiceApi {
    getInvoices = (params) => {
        return axiosClient.get('api/procurement/invoices', { params })
    }

    getInvoicesExport = (payload) =>
        clientDownload.post('api/procurement/invoices/export', payload)
}

export default new InvoiceApi()
