import PageName from '~/components/PageName'
import BreadCrumb from '~/components/BreadCrumb'
import { PART, PART_DELL_STOCK } from '~/constants/Routes'
import ListToolbar from '~/components/ListToolbar'
import DellStockTable from './DellStockTable'
import ComponentTooltip from '~/components/ComponentTooltip'

const DellStock = () => {
    const BreadComponents = [
        { route: PART, title: 'Parts' },
        {
            route: PART_DELL_STOCK,
            title: 'dellStock',
        },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={PART_DELL_STOCK}
            >
                <div>
                    <PageName name='Dell Stock' />
                </div>
            </ComponentTooltip>

            <ListToolbar />
            <DellStockTable />
        </>
    )
}

export default DellStock
