import { Grid, Typography } from '@mui/material'
import StyledModal from '~/components/StyledModal'
import InputFile from '~/pages/Device/Serial/SerialDetail/Component/InputFile'
import SaveButtonPopup from '~/pages/Device/Serial/SerialDetail/Component/SaveButtonPopup'
import UploadModalTitle from '~/pages/Device/Serial/SerialDetail/Component/UploadModalTitle'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'
import { LOCALIZATION } from '../constants'
import { useTranslation } from 'react-i18next'

const UploadDispatchPicturePopup = ({
    open,
    setOpenUploadPicture,
    setSelectedFiles,
}) => {
    const { t } = useTranslation()
    const handleSubmit = (values) => {
        setSelectedFiles((files) => [
            ...files,
            {
                src: values.pictures.item(0),
                image: URL.createObjectURL(values.pictures.item(0)),
                id: URL.createObjectURL(values.pictures.item(0)),
                checked: false,
                notConvert: true,
            },
        ])
        setOpenUploadPicture(false)
    }

    const handleClose = () => {
        setOpenUploadPicture(false)
    }

    return (
        <StyledModal open={open} handleClose={() => handleClose()}>
            <Grid container spacing={0.5}>
                <UploadModalTitle
                    componentId={'upload_picture_popup_title'}
                    title={t(`${LOCALIZATION}.uploadPicture`)}
                />
                <Grid item xs={12} sm={12}>
                    <Form
                        onSubmit={handleSubmit}
                        render={({ handleSubmit, form }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid item xs={12}>
                                    <Typography>File</Typography>
                                    <InputFile
                                        form={form}
                                        nameField='pictures'
                                    />
                                </Grid>
                                <SaveButtonPopup />
                            </form>
                        )}
                    />
                </Grid>
            </Grid>
        </StyledModal>
    )
}

UploadDispatchPicturePopup.propTypes = {
    open: PropTypes.bool,
    setOpenUploadPicture: PropTypes.func,
    setSelectedFiles: PropTypes.func,
}

export default UploadDispatchPicturePopup
