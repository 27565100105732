import { axiosClient } from '~/api/base'

class DeviceStateApi {
    getDeviceStates = (params) => {
        return axiosClient.get('api/device-states', {
            params,
        })
    }
}

export default new DeviceStateApi()
