import StyledModal from '~/components/StyledModal'
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import useTable from '~/hooks/useTable'
import Search from '~/components/Search/Search'
import TableLoading from '~/components/Table/TableLoading'
import { LoadingButton } from '~/components/Button'
import PaginationTable from '~/components/PaginationTable'
import { usePartCodeQuery } from '~/pages/PartCode/query'
import {
    useAttachPartCodeIntoModelMutation,
    useModelPartCodesQuery,
} from '~/pages/Model/Details/query'
import Loading from '~/components/Loading'
import { useCallback, useEffect, useState } from 'react'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { parseApiErrorMessage } from '~/utils/helpers'

const PartCodeList = ({ modelId, addedPartCodes }) => {
    const { t } = useTranslation()
    const [existedCodes, setExistedCodes] = useState({})
    const { mutate: attachPartCode, isLoading: isAttaching } =
        useAttachPartCodeIntoModelMutation()
    const { openSnackbar } = useDialogContext()
    useEffect(() => {
        const partCodes = {}
        addedPartCodes.forEach((partCode) => {
            partCodes[partCode.code] = true
        })
        setExistedCodes({ ...partCodes })
    }, [addedPartCodes])

    const {
        results: partCodes,
        data,
        isLoading,
        page,
        searchHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: usePartCodeQuery,
    })

    const isExisted = (partCode) => {
        return typeof existedCodes[partCode] !== 'undefined'
    }

    const addPartCode = useCallback(
        (code) => {
            attachPartCode(
                { modelId, code },
                {
                    onSuccess: () => {
                        setExistedCodes((prevState) => {
                            prevState[code] = true
                            return { ...prevState }
                        })
                        openSnackbar({
                            message: t(`message.attachSuccess`),
                            type: 'success',
                        })
                    },
                    onError: (error) => {
                        openSnackbar({
                            message: parseApiErrorMessage(error),
                            type: 'error',
                        })
                    },
                },
            )
        },
        [attachPartCode, modelId, openSnackbar, t],
    )

    return (
        <>
            <Search
                label={t(`pages.dispatches.dispatch.add.filter_part_code`)}
                onChange={searchHandler}
                onKeyDown={onEnterSearch}
                sx={{ mb: 2 }}
            />
            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {t(`pages.diagnostic.partCode`)}
                            </TableCell>
                            <TableCell>
                                {t(`pages.diagnostic.partName`)}
                            </TableCell>
                            <TableCell align='center'>
                                {t(`pages.components.action`)}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {isLoading ? (
                        <TableLoading colSpan={3} height='550px' />
                    ) : (
                        <TableBody>
                            {partCodes.map((partCode, idx) => (
                                <TableRow key={idx}>
                                    <TableCell>{partCode.code}</TableCell>
                                    <TableCell>{partCode.name}</TableCell>
                                    <TableCell align='center'>
                                        <LoadingButton
                                            disabled={
                                                isExisted(partCode.code) ||
                                                isAttaching
                                            }
                                            onClick={() => {
                                                addPartCode(partCode.code)
                                            }}
                                            label={
                                                isExisted(partCode.code)
                                                    ? t(`button.added`)
                                                    : t(`button.add`)
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                size='small'
                handleChangePage={changePageHandler}
            />
        </>
    )
}

PartCodeList.propTypes = {
    modelId: PropTypes.string,
    addedPartCodes: PropTypes.arrayOf(PropTypes.object),
}

const PartCodeSelectionPopup = ({ modelId, open, onClose }) => {
    const { data, isLoading } = useModelPartCodesQuery({
        id: modelId,
        all: true,
    })
    return (
        <StyledModal
            open={open}
            handleClose={onClose}
            stackProps={{
                width: 800,
                gap: 2,
            }}
        >
            <Box sx={{ width: '100%' }}>
                {isLoading ? (
                    <Loading />
                ) : (
                    <PartCodeList
                        modelId={modelId}
                        addedPartCodes={data.data}
                    />
                )}
            </Box>
        </StyledModal>
    )
}

PartCodeSelectionPopup.propTypes = {
    modelId: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

export default PartCodeSelectionPopup
