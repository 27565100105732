import { axiosFormClient } from './base'

class RemittanceApi {
    importRemittance = (payload) =>
        axiosFormClient.post('api/dispatch_remittance', payload)

    getImportedRemittanceFiles = () =>
        axiosFormClient.get('api/dispatch_remittance')
}

export default new RemittanceApi()
