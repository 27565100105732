import {
    Table as MuiTable,
    TableBody as MuiTableBody,
    Paper,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import PropTypes from 'prop-types'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'
import { ProductionImageHeadCells } from '~/components/HeaderTable/ProductionImageHeadCells'
import PaginationTable from '~/components/PaginationTable'
import ReferencedEntities from '~/components/ReferencedEntities'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import { useImagesQuery } from '../query'

const TableBody = ({ isLoading, results }) => {
    if (isLoading) {
        return <TableLoading colSpan={ProductionImageHeadCells.length} />
    }

    return (
        <MuiTableBody>
            {results.length > 0 &&
                results.map((row, index) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row?.id}>
                            <ReferencedEntities
                                isTableCell={true}
                                dataType={'images-id'}
                                dataIdentifier={row.id}
                                dataDisplay={row.id}
                            />
                            <ReferencedEntities
                                isTableCell={true}
                                dataType={'#'}
                                dataIdentifier={row?.company?.name}
                                dataDisplay={row?.company?.name}
                            />
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.type}</TableCell>
                            <TableCell>{row.versions.length}</TableCell>
                            <TableCell>
                                <DateTimeLocale datetime={row.updated_at} />
                            </TableCell>
                        </TableRow>
                    )
                })}
        </MuiTableBody>
    )
}

TableBody.propTypes = {
    isLoading: PropTypes.bool,
    results: PropTypes.arrayOf(PropTypes.object),
}

const ImagesTable = () => {
    const {
        data,
        results,
        isLoading,
        page,
        order,
        orderBy,
        changePageHandler,
        sortHandler,
    } = useTable({
        query: useImagesQuery,
        initialStates: {
            orderBy: 'desc',
        },
        queryAttributes: {},
    })

    return (
        <Paper>
            <TableContainer
                sx={{ width: '100%' }}
                aria-labelledby='tableTitle'
                size='medium'
            >
                <MuiTable>
                    <TableHeader
                        headCells={ProductionImageHeadCells}
                        localization={'pages.production.images.'}
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />
                    <TableBody isLoading={isLoading} results={results} />
                </MuiTable>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                size='medium'
                handleChangePage={changePageHandler}
            />
        </Paper>
    )
}

export default ImagesTable
