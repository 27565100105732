const FONT_PRIMARY = "'Lato', sans-serif"

const typography = {
    fontFamily: FONT_PRIMARY,
    // fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
}

export default typography
