import {
    ADMINISTRATOR,
    ADMINISTRATOR_DEFINITION,
    ADMINISTRATOR_DEFINITION_REGIONS,
    ADMINISTRATOR_DEFINITION_REGIONS_DETAIL,
} from '~/constants/Routes'
import BreadCrumb from '~/components/BreadCrumb'
import { useTranslation } from 'react-i18next'
import PageName from '../../../components/PageName'
import { useParams } from 'react-router-dom'
import { useGetRegionDetailQuery } from '../query'
import RegionContent from './RegionContent'
import Loading from '~/components/Loading'
import { selectSnackbar } from '~/store/snackbar/selector'
import { useSnackbar } from '~/hooks/useSnackbar'
import { unsetSnackbar } from '~/store/snackbar/slice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ComponentTooltip from '~/components/ComponentTooltip'

const RegionDetail = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const { data, isLoading } = useGetRegionDetailQuery(id)
    const snackbarData = useSelector(selectSnackbar)
    const { showSnackbar, snackbar } = useSnackbar()
    const dispatch = useDispatch()
    useEffect(() => {
        if (snackbarData) {
            showSnackbar(snackbarData.message, snackbarData.type)
        }
        return () => {
            dispatch(unsetSnackbar({}))
        }
    })

    const BreadComponents = [
        { route: ADMINISTRATOR, title: t(`breadCrumb.Administrator`) },
        {
            route: ADMINISTRATOR_DEFINITION,
            title: 'Definitions',
        },
        {
            route: ADMINISTRATOR_DEFINITION_REGIONS,
            title: 'Regions',
        },
        {
            page: 'detail',
            title: id,
        },
    ]
    if (isLoading) {
        return <Loading />
    }
    return (
        <>
            {snackbar}
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_DEFINITION_REGIONS_DETAIL}
            >
                <div>
                    <PageName name={'Region Information'} />
                </div>
            </ComponentTooltip>
            <RegionContent region={data.data} />
        </>
    )
}
export default RegionDetail
