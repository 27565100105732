import { ADMINISTRATOR, ADMINISTRATOR_USER } from '~/constants/Routes'
import { useSnackbar } from '~/hooks/useSnackbar'
import {
    useDeleteInvitedUserMutation,
    useImpersonateUserMutation,
    useUpdateDisableUserMutation,
    useUpdateStatusUserMutation,
} from '~/pages/User/mutation'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectSnackbar } from '~/store/snackbar/selector'
import { unsetSnackbar } from '~/store/snackbar/slice'
import { useUsersProfileQuery } from '../query'

const useUser = () => {
    const { id } = useParams()

    const { t } = useTranslation()

    const [newStatus, setNewStatus] = useState('')
    const snackbarData = useSelector(selectSnackbar)
    const dispatch = useDispatch()
    const localization = 'pages.user.userDetail'

    const [isDelete, setIsDelete] = useState(false)
    const { showSnackbar, snackbar } = useSnackbar()

    const { mutate: updateStatusUser, isSuccess: isSuccessUpdateStatus } =
        useUpdateStatusUserMutation()

    const { mutate: updateDisableUser } = useUpdateDisableUserMutation()

    const { mutate: deleteInvitedUser } = useDeleteInvitedUserMutation()

    const { mutate: impersonateUser } = useImpersonateUserMutation()

    const { data: user, isLoading } = useUsersProfileQuery(
        id,
        newStatus,
        isSuccessUpdateStatus,
    )
    const BreadComponents = [
        { route: ADMINISTRATOR, title: 'Administrator' },
        {
            route: ADMINISTRATOR_USER,
            title: 'Users',
        },
        {
            page: 'detail',
            title: user?.name,
        },
    ]

    const changeDisableUser = ({ id, disabled }) => {
        updateDisableUser({
            id,
            disabled,
        })
    }

    const changeUserStatus = ({ id, action }) => {
        updateStatusUser({
            id,
            action,
        })
        setNewStatus(action)
    }

    const deleteUser = () => {
        setIsDelete(true)
    }

    const handleClose = () => {
        setIsDelete(false)
    }

    useEffect(() => {
        if (isSuccessUpdateStatus) {
            showSnackbar(t('message.' + newStatus), 'info')
        }
        if (snackbarData) {
            showSnackbar(snackbarData.message, snackbarData.type)
        }
        return () => {
            dispatch(unsetSnackbar({}))
        }
    }, [
        dispatch,
        isSuccessUpdateStatus,
        newStatus,
        showSnackbar,
        snackbarData,
        t,
    ])
    return {
        id,
        localization,
        isDelete,
        snackbar,
        deleteInvitedUser,
        isLoading,
        BreadComponents,
        changeDisableUser,
        changeUserStatus,
        deleteUser,
        impersonateUser,
        handleClose,
        user,
        t,
    }
}

export default useUser
