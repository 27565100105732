import PropTypes from 'prop-types'
import ErrorIcon from '@mui/icons-material/Error'
import { Typography } from '@mui/material'

const WarningTableCell = ({ children, showingError }) => {
    return (
        <Typography
            variant='body2'
            align='left'
            sx={{ display: 'flex', alignItems: 'center' }}
        >
            {children}
            {showingError && (
                <ErrorIcon
                    sx={{
                        fontSize: '1.2rem',
                        color: 'red',
                        marginLeft: '5px',
                    }}
                />
            )}
        </Typography>
    )
}
WarningTableCell.propTypes = {
    children: PropTypes.any,
    showingError: PropTypes.bool,
}
export default WarningTableCell
