import {
    ADMINISTRATOR,
    ADMINISTRATOR_ROLE,
    ADMINISTRATOR_ROLE_DETAIL,
} from '~/constants/Routes'
import { useParams } from 'react-router-dom'
import Loading from '~/components/Loading'
import { useMemo } from 'react'
import { useUpdateMutation } from './mutate'
import { useGetAllPermissionsQuery, useRolesQuery } from './query'
import { Form } from 'react-final-form'
import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import Body from './Body'
import Toolbar from './Toolbar'
import arrayMutators from 'final-form-arrays'
import ComponentTooltip from '~/components/ComponentTooltip'
import PropTypes from 'prop-types'

const RoleDetail = () => {
    const localization = 'pages.administrator.roleDetail'
    const { mutate: updateRole } = useUpdateMutation({})
    const urlParams = useParams()
    const { data, isLoading } = useRolesQuery({ id: urlParams.id })
    const queryResponse = useMemo(() => {
        return data?.data || []
    }, [data])
    const { data: permissions } = useGetAllPermissionsQuery(
        queryResponse.guard_name,
    )

    const BreadComponents = [
        { route: ADMINISTRATOR, title: 'Administrator' },
        {
            route: ADMINISTRATOR_ROLE,
            title: 'Roles',
        },
        {
            page: 'detail',
            title: queryResponse.name,
        },
    ]

    const initialValues = useMemo(() => {
        let permissionValues = []
        const permissionNamesOfRole = queryResponse.permissions
            ? queryResponse.permissions.map((permission) => {
                  return permission.name
              })
            : []
        if (permissions) {
            permissionValues = permissions.map((permission) => {
                let value = false
                if (permissionNamesOfRole.indexOf(permission.name) !== -1) {
                    value = true
                }
                return { name: permission.name, value }
            })
        }
        return {
            ...queryResponse,
            permissions: permissionValues,
        }
    }, [permissions, queryResponse])

    if (isLoading) {
        return <Loading />
    }
    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_ROLE_DETAIL}
            >
                <div>
                    <PageName name='Role Information' />
                </div>
            </ComponentTooltip>
            <Form
                onSubmit={updateRole}
                mutators={{
                    ...arrayMutators,
                }}
                initialValues={initialValues}
                render={({ values, ...props }) => (
                    <form onSubmit={props.handleSubmit}>
                        <Toolbar
                            object={queryResponse}
                            localization={localization}
                        />
                        <Body
                            data={queryResponse}
                            localization={localization}
                        />
                    </form>
                )}
            />
        </>
    )
}

RoleDetail.propTypes = { handleSubmit: PropTypes.func }

export default RoleDetail
