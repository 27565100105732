export const DeviceReplacePartHeadCells = [
    {
        id: 'code',
        numeric: false,
        disablePadding: false,
        label: 'code',
        orderable: true,
    },
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'name',
        orderable: true,
    },
    {
        id: 'type',
        numeric: true,
        disablePadding: false,
        label: 'type',
        orderable: true,
    },
    {
        id: 'serializable',
        numeric: true,
        disablePadding: false,
        label: 'serializable',
        orderable: true,
    },
]
