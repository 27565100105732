import productApi from '~/api/productApi'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { useMutation, useQueryClient } from 'react-query'

export const useLinkComponentToProductMutation = () => {
    const queryClient = useQueryClient()
    const { openSnackbar } = useDialogContext()
    return useMutation(
        ['link-component-to-product'],
        async (payload) => {
            const { data } = await productApi.linkComponentToProduct(payload)
            return data
        },
        {
            onSuccess: (data) => {
                openSnackbar({
                    message: data.message
                        ? data.message
                        : 'Successfully linked',
                    type: 'success',
                })
                queryClient.invalidateQueries('components-linked-to-products')
                queryClient.invalidateQueries(
                    'components-not-linked-to-products',
                )
            },
            onError: () => {},
            retry: false,
        },
    )
}

export const useUnlinkComponentToProductMutation = () => {
    const queryClient = useQueryClient()
    const { openSnackbar } = useDialogContext()
    return useMutation(
        ['unlink-component-to-product'],
        async (payload) => {
            const { data } = await productApi.unlinkComponentToProduct(payload)
            return data
        },
        {
            onSuccess: (data) => {
                openSnackbar({
                    message: data.message
                        ? data.message
                        : 'Successfully Unlink',
                    type: 'success',
                })
                queryClient.invalidateQueries('components-linked-to-products')
            },
            onError: () => {},
            retry: false,
        },
    )
}
