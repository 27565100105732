import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import PageName from '~/components/PageName'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_DEFINITION,
    ADMINISTRATOR_DEFINITION_CAUSE,
    ADMINISTRATOR_DEFINITION_CAUSE_NEW,
} from '~/constants/Routes'
import CreateCauseForm from './CreateCause'

const pagePath = ADMINISTRATOR_DEFINITION_CAUSE_NEW
const CauseCreate = () => {
    const BreadComponents = [
        { route: ADMINISTRATOR, title: 'Administrator' },
        {
            route: ADMINISTRATOR_DEFINITION,
            title: 'Definitions',
        },
        {
            route: ADMINISTRATOR_DEFINITION_CAUSE,
            title: 'Causes',
        },
        {
            page: 'detail',
            title: 'new',
        },
    ]
    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip componentId={`page_title`} pagePath={pagePath}>
                <div>
                    <PageName name='Create Cause' />
                </div>
            </ComponentTooltip>
            <CreateCauseForm pagePath={pagePath} />
        </>
    )
}
export default CauseCreate
