import axiosClient from '~/api/base'

class DispatchShipmentReportApi {
    getDispatchShipmentReport = (params) => {
        return axiosClient.get('api/dispatch-shipment-reports', {
            params,
        })
    }

    getPartsDispatchShipmentReport = (dispatchId) => {
        return axiosClient.get(
            `api/dispatch-shipment-reports/${dispatchId}/parts`,
        )
    }
}

export default new DispatchShipmentReportApi()
