import styled from '@emotion/styled'
import { Grid, Box, Divider, Modal, Stack, Typography } from '@mui/material'
import { PersonAdd } from '@mui/icons-material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ComponentTooltip from '~/components/ComponentTooltip'
import ErrorMessageInput from '~/components/ErrorMessageInput'
import UserFormInput from '~/components/FormInput/UserFormInput'
import SubmitButton from '~/components/SubmitButton'
import { ADMINISTRATOR_USER } from '~/constants/Routes'
import { email } from '~/constants/yupSchemas'
import useFormValidationCallback from '~/hooks/useFormValidationCallback'
import { useSnackbar } from '~/hooks/useSnackbar'
import { useCallback, useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { object } from 'yup'
import { useInviteUserMutation } from './mutation'
import PropTypes from 'prop-types'

const StyledModal = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})

const InviteUserPopup = ({ openInvite, setOpenInvite }) => {
    const { t } = useTranslation()
    const [emailInput, setEmailList] = useState([0])
    const { mutate: inviteUser, isLoading } = useInviteUserMutation()
    const { showSnackbar, snackbar } = useSnackbar()
    const [existUser, setExistUser] = useState([])

    const queryClient = useQueryClient()

    const submit = useCallback(
        (payload) =>
            inviteUser(payload, {
                onSuccess: (data) => {
                    if (
                        data.message === 'existed user' &&
                        data.status === 'fail'
                    ) {
                        setExistUser(data.emails)
                    } else {
                        setOpenInvite(false)
                        queryClient.invalidateQueries('users')
                        showSnackbar(t('message.successInviteUser'), 'success')
                    }
                },
                onError: (e) => {
                    showSnackbar(t('message.somethingWrong'), 'error')
                },
            }),
        [inviteUser, queryClient, setOpenInvite, showSnackbar, t],
    )

    const handleEmailAdd = useCallback(
        (item) => {
            const list = [...emailInput, item + 1]
            setEmailList(list)
        },
        [emailInput],
    )

    const handleEmailRemove = useCallback(
        (form, item) => {
            const list = emailInput.filter((x) => x !== item)

            setEmailList(list)
            form.change('email_' + item, null)
        },
        [emailInput],
    )

    const listMail = useMemo(() => {
        const modifiedEmailList = emailInput.reduce(
            (previousValue, currentValue) => {
                return {
                    ...previousValue,
                    ['email_' + currentValue]: email(t),
                }
            },
            {},
        )
        return modifiedEmailList
    }, [emailInput, t])

    const schema = object().shape(listMail)

    const validate = useFormValidationCallback(schema)

    const onSubmit = useCallback(
        (values) => {
            const emails = Object.values(values).filter((i) => !!i)
            submit({
                emails,
            })
        },
        [submit],
    )

    return (
        <>
            {snackbar}
            <StyledModal
                open={openInvite}
                onClose={(e) => setOpenInvite(false)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box
                    bgcolor={'background.default'}
                    color={'text.primary'}
                    p={3}
                    borderRadius={5}
                    maxWidth={500}
                >
                    <ComponentTooltip
                        componentId={`users_invite_popup_title`}
                        pagePath={ADMINISTRATOR_USER}
                        align='center'
                    >
                        <Typography
                            variant='h6'
                            color='gray'
                            textAlign='center'
                        >
                            {t('pages.user.inviteUser.inviteUser')}
                        </Typography>
                    </ComponentTooltip>
                    <Divider />
                    <Form
                        validate={validate}
                        onSubmit={onSubmit}
                        render={({ handleSubmit, form }) => (
                            <Grid container>
                                <Grid item md={12} xs={12}>
                                    <Box>
                                        <Grid container spacing={2}>
                                            {emailInput.map((count) => (
                                                <Grid item xs={12} key={count}>
                                                    <Stack direction='row'>
                                                        <Field
                                                            name={`email_${count}`}
                                                            title={t(
                                                                'pages.user.email',
                                                            ).toUpperCase()}
                                                            placeholder='example@rentcompany.nl'
                                                            component={
                                                                UserFormInput
                                                            }
                                                        />
                                                        {emailInput.length !==
                                                            1 && (
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    justifyContent:
                                                                        'center',
                                                                }}
                                                            >
                                                                <DeleteOutlineIcon
                                                                    onClick={() => {
                                                                        handleEmailRemove(
                                                                            form,
                                                                            count,
                                                                        )
                                                                    }}
                                                                />
                                                            </Box>
                                                        )}
                                                    </Stack>
                                                </Grid>
                                            ))}
                                            {emailInput.length < 5 && (
                                                <Stack
                                                    direction='row'
                                                    gap={1}
                                                    mt={2}
                                                    mb={3}
                                                >
                                                    <PersonAdd
                                                        color='primary'
                                                        onClick={() =>
                                                            handleEmailAdd(
                                                                emailInput[
                                                                    emailInput.length -
                                                                        1
                                                                ],
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                            )}
                                            <Grid item xs={12}>
                                                <Stack
                                                    direction='column'
                                                    spacing={2}
                                                >
                                                    {existUser.length > 0 && (
                                                        <ErrorMessageInput>
                                                            {t(
                                                                'message.existedUser',
                                                            )}
                                                            {existUser.map(
                                                                (email) => (
                                                                    <Box
                                                                        key={
                                                                            email
                                                                        }
                                                                    >
                                                                        {email}
                                                                    </Box>
                                                                ),
                                                            )}
                                                        </ErrorMessageInput>
                                                    )}
                                                    <Box>
                                                        <Stack
                                                            direction='row-reverse'
                                                            spacing={2}
                                                        >
                                                            <SubmitButton
                                                                isLoading={
                                                                    isLoading
                                                                }
                                                                handleSubmit={
                                                                    handleSubmit
                                                                }
                                                                name={`${t(
                                                                    'pages.user.inviteUser.invite',
                                                                )}`}
                                                            />
                                                        </Stack>
                                                    </Box>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    />
                </Box>
            </StyledModal>
        </>
    )
}

InviteUserPopup.propTypes = {
    openInvite: PropTypes.bool,
    setOpenInvite: PropTypes.func,
}

export default InviteUserPopup
