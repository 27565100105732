import {
    Divider,
    TableBody as MuiTableBody,
    Paper,
    Table,
    TableContainer,
} from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import { DisabledUserHeadCells } from '~/components/HeaderTable/UserHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import Row from '~/components/TableRow'
import useTable from '~/hooks/useTable'
import { useEffect, useRef, useState } from 'react'
import { useDisabledUsersQuery } from '../query'
import DisableUserTableFilters from './DisableUserTableFilters'
import PropTypes from 'prop-types'

const localization = 'pages.user.'

const DisabledUserTableBody = ({ isLoading, disabledUsers }) => {
    if (isLoading) {
        return <TableLoading colSpan={DisabledUserHeadCells.length} />
    }

    return (
        <MuiTableBody>
            {disabledUsers.length > 0 ? (
                disabledUsers.map((user) => {
                    return (
                        <Row
                            row={user}
                            columnsConfig={DisabledUserHeadCells}
                            key={user.id}
                        />
                    )
                })
            ) : (
                <DataNotFound colSpan={DisabledUserHeadCells.length} />
            )}
        </MuiTableBody>
    )
}

DisabledUserTableBody.propTypes = {
    isLoading: PropTypes.bool,
    disabledUsers: PropTypes.arrayOf(PropTypes.object),
}

const DisabledUsersTable = () => {
    const [role, setRole] = useState('')

    const {
        results: disabledUsers,
        data,
        isLoading,
        page,
        order,
        orderBy,
        search,
        setPage,
        changePageHandler,
        searchHandler,
        sortHandler,
        onEnterSearch,
    } = useTable({
        query: useDisabledUsersQuery,
        queryAttributes: {
            role,
        },
    })

    const refValue = useRef({
        role: '',
    })

    useEffect(() => {
        if (role !== refValue.current.role) {
            setPage(1)
            refValue.current.role = role
        }
    }, [role, setPage])

    return (
        <Paper sx={{ width: '100%', mb: 2 }}>
            <DisableUserTableFilters
                {...{
                    search,
                    searchHandler,
                    role,
                    setRole,
                    onEnterSearch,
                }}
            />
            <Divider />
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby='tableTitle'
                    size='medium'
                >
                    <TableHeader
                        headCells={DisabledUserHeadCells}
                        localization={localization}
                        onRequestSort={sortHandler}
                        order={order}
                        orderBy={orderBy}
                    />
                    <DisabledUserTableBody
                        isLoading={isLoading}
                        disabledUsers={disabledUsers}
                    />
                </Table>
            </TableContainer>
            <PaginationTable
                data={data}
                page={page}
                handleChangePage={changePageHandler}
                size='medium'
            />
        </Paper>
    )
}
export default DisabledUsersTable
