import {
    Table as MuiTable,
    TableBody as MuiTableBody,
    Paper,
    TableContainer,
} from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import { ServiceCenterHeadCells } from '~/components/HeaderTable/ServiceCenterHeadCells'
import PaginationTable from '~/components/PaginationTable'
import FilterSearch from '~/components/Search/FilterSearch'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import Row from '~/components/TableRow'
import useTable from '~/hooks/useTable'
import { useTranslation } from 'react-i18next'
import { useServiceCenterRegionQuery } from '../query'
import PropTypes from 'prop-types'

const TableBody = ({ isLoading, objects }) => {
    if (isLoading) {
        return <TableLoading colSpan={ServiceCenterHeadCells.length} />
    }

    return (
        <MuiTableBody>
            {objects.length > 0 ? (
                objects.map((row) => {
                    return (
                        <Row
                            key={row.id}
                            row={row}
                            columnsConfig={ServiceCenterHeadCells}
                        />
                    )
                })
            ) : (
                <DataNotFound colSpan={ServiceCenterHeadCells.length} />
            )}
        </MuiTableBody>
    )
}

TableBody.propTypes = { isLoading: PropTypes.bool, objects: PropTypes.array }

const ServiceCenterSession = () => {
    const { t } = useTranslation()
    const {
        results,
        data,
        isLoading,
        page,
        order,
        orderBy,
        changePageHandler,
        searchHandler,
        sortHandler,
        onEnterSearch,
    } = useTable({
        query: useServiceCenterRegionQuery,
    })
    return (
        <>
            <FilterSearch
                placeholder={`${t(`general.searchFor`)} ${t(
                    `general.serviceCenter`,
                )}`}
                onSearchChange={searchHandler}
                onEnterSearch={onEnterSearch}
            />
            <Paper>
                <TableContainer
                    sx={{ minWidth: 750 }}
                    aria-labelledby='tableTitle'
                    size='medium'
                >
                    <MuiTable>
                        <TableHeader
                            headCells={ServiceCenterHeadCells}
                            localization={
                                'pages.administrator.definition.serviceCenter.overview.'
                            }
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                        />
                        <TableBody isLoading={isLoading} objects={results} />
                    </MuiTable>
                </TableContainer>
                <PaginationTable
                    data={data}
                    page={page}
                    handleChangePage={changePageHandler}
                    size='medium'
                />
            </Paper>
        </>
    )
}

export default ServiceCenterSession
