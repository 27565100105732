import { FormControl, Grid } from '@mui/material'
import { Field, useField } from 'react-final-form'
import InputField from '~/components/FinalForm/InputField'

import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectField from '~/components/FinalForm/SelectField'
import { ADMINISTRATOR_DEFINITION_SERVICECENTER_NEW } from '~/constants/Routes'
import NewAddress from './components/NewAddress'

const pagePath = ADMINISTRATOR_DEFINITION_SERVICECENTER_NEW

const ServiceCenterForm = ({
    localization,
    InputFieldGroup,
    selectGroup,
    branchesList,
}) => {
    const emailDirectionOptions = [
        { name: 'Yes', value: true },
        { name: 'No', value: false },
    ]
    const { t } = useTranslation()

    const [selectedBranchId, setSelectedBranchId] = useState('')

    const { input: branchIdInput } = useField('branch_id')
    const { input: manualDispatchBranchIdInput } = useField(
        'manual_dispatch_branch_id',
    )

    useEffect(() => {
        if (!manualDispatchBranchIdInput.value && branchIdInput.value) {
            manualDispatchBranchIdInput.onChange(branchIdInput.value)
        }
    }, [branchIdInput.value, manualDispatchBranchIdInput])

    const handleBranchChange = (value) => {
        setSelectedBranchId(value)
        branchIdInput.onChange(value)
    }

    return (
        <Grid container spacing={2}>
            {InputFieldGroup.map((item, idx) => (
                <Grid item xs={12} key={idx}>
                    <InputField
                        localization={localization}
                        name={item.name}
                        componentId={`${item.componentId}`}
                        pagePath={pagePath}
                        isRequired={item.isRequired}
                        placeholder={t(`${localization}.${item.name}`)}
                    />
                </Grid>
            ))}

            {selectGroup.map((item, index) => {
                return (
                    <Grid item xs={12} key={index}>
                        <FormControl sx={{ width: '100%' }}>
                            <Field
                                name={item.name}
                                component={SelectField}
                                items={item.listResource}
                                localization={localization}
                                componentId={`${item.componentId}`}
                                pagePath={pagePath}
                                nameSelect={item.title}
                                isRequired={item.required}
                            />
                        </FormControl>
                        {item.title === 'address' && <NewAddress />}
                    </Grid>
                )
            })}
            <Grid item xs={12}>
                <FormControl sx={{ width: '100%' }}>
                    <Field
                        name='dell_td_email_to_contact'
                        component={SelectField}
                        items={emailDirectionOptions}
                        localization={localization}
                        componentId='service_center_form_dell_email_direction'
                        pagePath={pagePath}
                        nameSelect='dellEmailDirection'
                        isRequired={false}
                        defaultValue={false}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <InputField
                    localization={localization}
                    name='service_center_tid'
                    componentId='service_center_tid'
                    pagePath={pagePath}
                    isRequired={false}
                    placeholder={t(`${localization}.Easy4UID`)}
                    label='Easy4UID'
                />
            </Grid>
            <Grid item xs={12}>
                <InputField
                    localization={localization}
                    name='phone_number'
                    componentId='service_center_form_phone'
                    pagePath={pagePath}
                    isRequired={true}
                    placeholder={t(`${localization}.phoneNumber`)}
                    label='phoneNumber'
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl sx={{ width: '100%' }}>
                    <Field
                        name='branch_id'
                        component={SelectField}
                        items={branchesList}
                        localization={localization}
                        componentId='service_center_form_dell_branch'
                        pagePath={pagePath}
                        nameSelect='dellBranch'
                        isRequired={true}
                        onChange={(event) =>
                            handleBranchChange(event.target.value)
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl sx={{ width: '100%' }}>
                    <Field
                        name='manual_dispatch_branch_id'
                        component={SelectField}
                        items={branchesList}
                        localization={localization}
                        componentId='service_center_form_manual_dispatch_branch'
                        pagePath={pagePath}
                        nameSelect='manualDispatchBranch'
                        isRequired={true}
                        defaultValue={selectedBranchId}
                    />
                </FormControl>
            </Grid>
        </Grid>
    )
}

ServiceCenterForm.propTypes = {
    localization: PropTypes.string,
    InputFieldGroup: PropTypes.array,
    selectGroup: PropTypes.array,
    branchesList: PropTypes.array,
}

export default ServiceCenterForm
