import TableFilters from '~/components/TableFilters'
import { useMemo } from 'react'
import PropTypes from 'prop-types'

const DispatchesTableFilters = ({ search, searchHandler, onEnterSearch }) => {
    const selectGroup = useMemo(() => {
        return []
    }, [])
    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='dispatches'
            page='dispatches'
        />
    )
}

DispatchesTableFilters.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    onEnterSearch: PropTypes.func,
}

export default DispatchesTableFilters
