import { FormControl, MenuItem, Select, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const CustomSelection = ({ selected, options = [], onChange }) => {
    return (
        <FormControl sx={{ m: 1, width: 240 }} size='small'>
            <Select value={selected} onChange={onChange}>
                {options.map((option) => (
                    <MenuItem
                        key={option.id}
                        value={option.id}
                        name={option.name}
                    >
                        <Typography
                            sx={{
                                maxWidth: '170px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {option.name}
                        </Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

CustomSelection.propTypes = {
    selected: PropTypes.any,
    options: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
    ),
    onChange: PropTypes.func,
}

export default CustomSelection
