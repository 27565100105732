export const ProductionImageHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
    },
    {
        id: 'company_id',
        numeric: false,
        disablePadding: false,
        label: 'company_id',
        orderable: false,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        orderable: false,
    },
    {
        id: 'type',
        numeric: false,
        disablePadding: false,
        label: 'type',
        orderable: false,
    },
    {
        id: 'versions',
        numeric: false,
        disablePadding: false,
        label: 'versions',
        orderable: false,
    },
    {
        id: 'lastseen',
        numeric: false,
        disablePadding: false,
        label: 'lastseen',
        orderable: false,
        dataType: 'datetime',
    },
]

export const ProductionImageVersionHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
    },
    {
        id: 'type',
        numeric: false,
        disablePadding: false,
        label: 'type',
        orderable: false,
    },
    {
        id: 'version',
        numeric: false,
        disablePadding: false,
        label: 'version',
        orderable: false,
    },
    {
        id: 'version_minor',
        numeric: false,
        disablePadding: false,
        label: 'version_minor',
        orderable: false,
    },
    {
        id: 'edition',
        numeric: false,
        disablePadding: false,
        label: 'edition',
        orderable: false,
    },
    {
        id: 'language_code',
        numeric: false,
        disablePadding: false,
        label: 'language_code',
        orderable: false,
    },
    {
        id: 'lastseen',
        numeric: false,
        disablePadding: false,
        label: 'lastseen',
        orderable: false,
        dataType: 'datetime',
    },
]
