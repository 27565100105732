import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import { ISSUE_BOM } from '~/constants/Routes'
import BomTable from '~/pages/BOM/Overview/BomTable'
import { useTranslation } from 'react-i18next'
import usePermissions from '~/hooks/usePermission'

const breadCrumbConfig = [
    { route: ISSUE_BOM, title: 'BOM' },
    { route: '#', title: 'BOM' },
]

const BOMOverview = () => {
    const { permissions } = usePermissions()
    const { t } = useTranslation()
    const hasViewBomRight = permissions.issue.bom.canViewBom

    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            {hasViewBomRight ? (
                <>
                    <PageName name={'BOM'} />
                    <BomTable />
                </>
            ) : (
                <h2>{t(`message.you_do_not_have_permission`)}</h2>
            )}
        </>
    )
}
export default BOMOverview
