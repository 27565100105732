import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import PageName from '~/components/PageName'
import {
    PROCUREMENT,
    PROCUREMENT_DELIVERY,
    PROCUREMENT_DELIVERY_DETAIL,
} from '~/constants/Routes'
import { useParams } from 'react-router-dom'
import DeliveryDetailTable from './DeliveryDetailTable'

const DeliveryDetail = () => {
    const { id } = useParams()
    const BreadComponents = [
        { route: PROCUREMENT, title: 'Procurement' },
        {
            route: PROCUREMENT_DELIVERY,
            title: 'Deliveries',
        },
        {
            page: 'detail',
            title: id,
        },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={PROCUREMENT_DELIVERY_DETAIL}
            >
                <div>
                    <PageName name={`Delivery - ${id}`} />
                </div>
            </ComponentTooltip>
            <DeliveryDetailTable />
        </>
    )
}

export default DeliveryDetail
