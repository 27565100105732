import { Divider, Grid, Typography } from '@mui/material'
import ComponentTooltip from '~/components/ComponentTooltip'
import { FLEET_SERIAL_DETAIL } from '~/constants/Routes'
import PropTypes from 'prop-types'

const UploadModalTitle = ({ componentId, title }) => {
    return (
        <Grid item xs={12} sm={12}>
            <ComponentTooltip
                componentId={componentId}
                pagePath={FLEET_SERIAL_DETAIL}
                align='center'
            >
                <Typography variant='h6' color='gray' textAlign='center'>
                    {title}
                </Typography>
            </ComponentTooltip>
            <Divider sx={{ mb: 2 }} />
        </Grid>
    )
}

UploadModalTitle.propTypes = {
    componentId: PropTypes.number,
    title: PropTypes.string,
}

export default UploadModalTitle
