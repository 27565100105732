import axiosClient from '~/api/base'

class LocaleApi {
    getLocales = (params) => {
        return axiosClient.get('api/locales', {
            params,
        })
    }
}

export default new LocaleApi()
