import serviceAccApi from '~/api/serviceAccountApi'
import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT } from '~/constants/Routes'
import { setSnackbar } from '~/store/snackbar/slice'

export const useUpdateMutation = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    return useMutation(
        ['client_token_create'],
        async (values) => {
            return await serviceAccApi.create(values)
        },
        {
            onSuccess: (data) => {
                dispatch(
                    setSnackbar({
                        message: data.message
                            ? data.message
                            : 'Create successful!',
                        type: 'success',
                    }),
                )

                navigate(ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT)
                return data.data?.data
            },
            onError: (error) => {
                const message = error.response.data.message
                    ? error.response.data.message
                    : error.message
                      ? error.message
                      : 'Create failed!'
                dispatch(
                    setSnackbar({
                        message,
                        type: 'error',
                    }),
                )
                navigate(ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT)
            },
            retry: false,
        },
    )
}
