import tooltipApi from '~/api/tooltipApi'
import { useQuery } from 'react-query'

export const useTooltipQuery = (params) => {
    return useQuery(
        ['tooltips', params],
        async () => {
            const { data } = await tooltipApi.getTooltips(params)
            return { data: data.data, total: data.meta?.total }
        },
        {
            retry: false,
        },
    )
}
export const useTooltipDetailQuery = ({ id }) => {
    return useQuery(
        ['tooltip', id],
        async () => {
            const { data } = await tooltipApi.getTooltip({ id })
            return data.data
        },
        {
            onSuccess: (data) => {},
            retry: false,
        },
    )
}
