import Proptypes from 'prop-types'
import { Box, Paper } from '@mui/material'

const FormBodyWrapper = ({ children }) => {
    return (
        <>
            <Paper
                sx={{
                    width: '100%',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'left',
                    justifyContent: 'left',
                }}
            >
                <Box
                    bgcolor={'background.default'}
                    color={'text.primary'}
                    p={3}
                    borderRadius={5}
                    width={'100%'}
                >
                    {children}
                </Box>
            </Paper>
        </>
    )
}

FormBodyWrapper.propTypes = {
    children: Proptypes.oneOfType([
        Proptypes.node,
        Proptypes.arrayOf(Proptypes.node),
    ]).isRequired,
}

export default FormBodyWrapper
