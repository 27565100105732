export const headCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: false,
        label: 'id',
        orderable: true,
    },
    {
        id: 'ticket_id',
        numeric: true,
        disablePadding: false,
        label: 'ticket_id',
        orderable: true,
    },
    {
        id: 'esd_location',
        numeric: true,
        disablePadding: false,
        label: 'esd_location',
        orderable: true,
    },
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'name',
        orderable: true,
    },
    {
        id: 'brand',
        numeric: true,
        disablePadding: false,
        label: 'brand',
        orderable: true,
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'status',
        orderable: true,
    },
    {
        id: 'state',
        numeric: true,
        disablePadding: false,
        label: 'state',
        orderable: true,
    },
    {
        id: 'previous_id',
        numeric: true,
        disablePadding: false,
        label: 'previous_id',
        orderable: true,
    },
    {
        id: 'created_at',
        numeric: true,
        disablePadding: false,
        label: 'created_at',
        orderable: true,
    },
    {
        id: 'updated_at',
        numeric: false,
        disablePadding: false,
        label: 'updated_at',
        orderable: true,
    },
]

export const ASSIGNED_TO_SUPERVISOR = 'ASSIGNED_TO_SUPERVISOR'
export const CANCELLED = 'CANCELLED'
export const REJECTED = 'REJECTED'
export const CLOSED = 'CLOSED'
export const CHROMEBOOK = 'chromebook'
