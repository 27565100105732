export const ProductionOderLineHeadCells = [
    {
        id: 'serial',
        numeric: false,
        disablePadding: false,
        label: 'Serial',
        orderable: false,
    },
    {
        id: 'state',
        numeric: false,
        disablePadding: false,
        label: 'state',
        orderable: false,
    },
    {
        id: 'server',
        numeric: false,
        disablePadding: false,
        label: 'server',
        orderable: false,
    },
    {
        id: 'bios',
        numeric: false,
        disablePadding: false,
        label: 'bios',
        orderable: false,
    },
    {
        id: 'battery',
        numeric: false,
        disablePadding: false,
        label: 'battery',
        orderable: false,
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'created',
        orderable: false,
        dataType: 'datetime',
    },
    {
        id: 'remove_at',
        numeric: false,
        disablePadding: false,
        label: 'action',
        orderable: false,
        type: 'button',
        action: 'delete',
    },
]
