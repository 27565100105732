import axiosClient, { axiosFormClient } from '~/api/base'

class TeamApi {
    getTeams = (params) => {
        return axiosClient.get('api/definition/teams', { params })
    }

    getTeamDetail = (id) => axiosClient.get(`api/definition/teams/${id}`)

    createTeam = (payload) => {
        const formData = new FormData()
        Object.keys(payload).forEach((key) => {
            formData.append(key, payload[key])
        })

        return axiosFormClient.post('api/definition/teams', formData)
    }

    updateTeam = (id, payload) => {
        const formData = new FormData()
        Object.keys(payload).forEach((key) => {
            formData.append(key, payload[key])
        })

        return axiosFormClient.post(`api/definition/teams/${id}`, formData)
    }

    getUsersOfTeam = (id) => axiosClient.get(`api/definition/teams/${id}/users`)

    getUsersNotExistInTeam = (id) =>
        axiosClient.get(`api/definition/teams/${id}/no_belongTo_users`)

    addMemberForTeam = (id, payload) => {
        return axiosClient.post(`api/definition/teams/${id}/users`, payload)
    }

    removeMemberInTeam = (id, payload) => {
        return axiosClient.put(
            `api/definition/teams/${id}/remove-member`,
            payload,
        )
    }
}
export default new TeamApi()
