import { Grid } from '@mui/material'
import {
    ADMINISTRATOR_DEFINITION_SERVICECENTER_DETAIL,
    ADMINISTRATOR_DEFINITION_TEAM_DETAIL,
    ADMINISTRATOR_USER_DETAIL,
} from '~/constants/Routes'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { getValue } from '~/utils/helpers'
import PropTypes from 'prop-types'

const RackScanSession = ({ data }) => {
    const navigate = useNavigate()
    const dataList = useMemo(() => {
        return [
            { title: 'Id', value: data?.id },
            { title: 'Name', value: data?.name },
            {
                title: 'Team',
                value: data?.team?.name,
                clickable: true,
                navigateLink: ADMINISTRATOR_DEFINITION_TEAM_DETAIL.replace(
                    ':id',
                    data?.team?.id,
                ),
            },
            {
                title: 'Service Center',
                value: data?.service_center?.name,
                clickable: true,
                navigateLink:
                    ADMINISTRATOR_DEFINITION_SERVICECENTER_DETAIL.replace(
                        ':id',
                        data?.service_center?.id,
                    ),
            },
            {
                title: 'Created by',
                value: data?.created_by_person?.name,
                clickable: true,
                navigateLink: ADMINISTRATOR_USER_DETAIL.replace(
                    ':id',
                    data?.created_by_person?.id,
                ),
            },
            { title: 'Created at', value: data?.created_at, type: 'date' },
            { title: 'Updated at', value: data?.updated_at, type: 'date' },
        ]
    }, [data])

    return (
        <>
            {dataList.map((item, index) => {
                return (
                    <Grid container spacing={3} key={index} py={1}>
                        <Grid item xs={4}>
                            {item.title}
                        </Grid>
                        <Grid item xs={8}>
                            {getValue(navigate, item)}
                        </Grid>
                    </Grid>
                )
            })}
        </>
    )
}

RackScanSession.propTypes = { data: PropTypes.object }

export default RackScanSession
