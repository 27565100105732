export const OrderConfirmationHeadCells = [
    {
        id: 'suppliers.name',
        numeric: false,
        disablePadding: false,
        label: 'supplier',
        orderable: true,
    },
    {
        id: 'customer_order_number',
        numeric: false,
        disablePadding: false,
        label: 'ourPO',
        orderable: true,
    },
    {
        id: 'order_number',
        numeric: false,
        disablePadding: false,
        label: 'supplierOrder',
        orderable: true,
    },
    {
        id: 'order_date',
        numeric: false,
        disablePadding: false,
        label: 'orderDate',
        orderable: true,
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'status',
        orderable: true,
    },
    {
        id: 'complete_delivery',
        numeric: false,
        disablePadding: false,
        label: 'deliveryStatus',
        orderable: true,
    },
    {
        id: 'response_code',
        numeric: false,
        disablePadding: false,
        label: 'responseCode',
        orderable: true,
    },
    {
        id: 'end_user_information',
        numeric: false,
        disablePadding: false,
        label: 'information',
        orderable: true,
    },

    {
        id: 'to_city',
        numeric: false,
        disablePadding: false,
        label: 'city',
        orderable: true,
    },
    {
        id: 'amount_excl_vat',
        numeric: false,
        disablePadding: false,
        label: 'amountExclVat',
        orderable: true,
    },

    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'receivedAPI',
        orderable: true,
    },
]
