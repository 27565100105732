import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectPurchaseOrderLine } from '~/store/purchaseOrder/selector'
import { capitalize } from '~/utils/helpers'

const OrderEntitlementSession = () => {
    const purchaseOrderLine = useSelector(selectPurchaseOrderLine)

    return (
        <Grid container spacing={3}>
            <Grid item xs={4}>
                Entitlement Id
            </Grid>
            <Grid item xs={8}>
                {purchaseOrderLine?.purchase_order_entitlement?.id}
            </Grid>
            <Grid item xs={4}>
                Warranty period (months)
            </Grid>
            <Grid item xs={8}>
                {purchaseOrderLine?.purchase_order_entitlement?.duration}
            </Grid>
            <Grid item xs={4}>
                ADP
            </Grid>
            <Grid item xs={8}>
                {capitalize(
                    (!!purchaseOrderLine?.purchase_order_entitlement
                        ?.has_adp).toString(),
                )}
            </Grid>
            <Grid item xs={4}>
                Collect & Return
            </Grid>
            <Grid item xs={8}>
                {capitalize(
                    (!!purchaseOrderLine?.purchase_order_entitlement
                        ?.has_car).toString(),
                )}
            </Grid>
            <Grid item xs={4}>
                Flex ProSupport
            </Grid>
            <Grid item xs={8}>
                {capitalize(
                    (!!purchaseOrderLine?.purchase_order_entitlement
                        ?.has_pro).toString(),
                )}
            </Grid>
            <Grid item xs={4}>
                Onsite Service (ND)
            </Grid>
            <Grid item xs={8}>
                {capitalize(
                    (!!purchaseOrderLine?.purchase_order_entitlement
                        ?.has_onsite).toString(),
                )}
            </Grid>
            <Grid item xs={4}>
                Battery warranty (months)
            </Grid>
            <Grid item xs={8}>
                {purchaseOrderLine?.purchase_order_entitlement?.batt}
            </Grid>
        </Grid>
    )
}

export default OrderEntitlementSession
