import TagTable from './Tag/Table'
import RuleTable from './Rule/Table'

const NamingTag = () => {
    return (
        <>
            <TagTable />
            <RuleTable />
        </>
    )
}

export default NamingTag
