import PropTypes from 'prop-types'
import { Box } from '@mui/material'

const Tablet = ({ index = null, children, sx }) => {
    return (
        <Box
            component='div'
            sx={{
                p: 1,
                mt: index === 0 ? 0 : 1,
                bgcolor: '#fff',
                color: 'grey.800',
                border: '1px solid',
                borderColor: 'grey.300',
                borderRadius: 4,
                fontSize: '0.875rem',
                fontWeight: '700',
                ...sx,
            }}
        >
            {children}
        </Box>
    )
}

Tablet.propTypes = {
    index: PropTypes.number,
    children: PropTypes.node,
    sx: PropTypes.object,
}

export default Tablet
