export const ProductionOrderHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
    },
    {
        id: 'production_order_nr',
        numeric: false,
        disablePadding: false,
        label: 'orderNumber',
        orderable: true,
    },
    {
        id: 'project_nr',
        numeric: false,
        disablePadding: false,
        label: 'projectNumber',
        orderable: false,
    },
    {
        id: 'image_name',
        numeric: false,
        disablePadding: false,
        label: 'image',
        orderable: false,
    },
    {
        id: 'qty',
        numeric: false,
        disablePadding: false,
        label: 'qty',
        orderable: true,
    },
    {
        id: 'assigned',
        numeric: false,
        disablePadding: false,
        label: 'assigned',
        orderable: false,
    },
    {
        id: 'service_center_name',
        numeric: false,
        disablePadding: false,
        label: 'serviceLocation',
        orderable: false,
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'created',
        orderable: false,
        dataType: 'datetime',
    },
    {
        id: 'updated_at',
        numeric: false,
        disablePadding: false,
        label: 'updated',
        orderable: false,
        dataType: 'datetime',
    },
]

export const ProductionOrderDetailHeadCells = [
    {
        id: 'qty',
        numeric: false,
        disablePadding: false,
        label: 'qty',
        orderable: false,
    },
    {
        id: 'assigned',
        numeric: false,
        disablePadding: false,
        label: 'assigned',
        orderable: false,
    },
    {
        id: 'state',
        numeric: false,
        disablePadding: false,
        label: 'state',
        orderable: false,
    },
    {
        id: 'delivery_date',
        numeric: false,
        disablePadding: false,
        label: 'deliveryDate',
        orderable: false,
        dataType: 'datetime',
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'created',
        orderable: false,
        dataType: 'datetime',
    },
]
