import { Button } from '@mui/material'
import { useState } from 'react'
import AddAddressPopup from './AddAddressPopup'
import { useTranslation } from 'react-i18next'
import usePermissions from '~/hooks/usePermission'

const NewAddress = () => {
    const [openAddAddress, setOpenAddAddress] = useState(false)
    const { t } = useTranslation()
    const { permissions } = usePermissions()

    const isPermission = permissions.administrator.definitions.canCreateAddress

    if (isPermission) {
        return (
            <>
                <Button
                    sx={{ textTransform: 'none', fontWeight: 'normal' }}
                    onClick={() => setOpenAddAddress(true)}
                >
                    {t('button.newAddress')}
                </Button>

                <AddAddressPopup
                    openAddAddress={openAddAddress}
                    setOpenAddAddress={setOpenAddAddress}
                />
            </>
        )
    }

    return null
}

export default NewAddress
