import { axiosClient } from '~/api/base'

class AuthenticateApi {
    getUserProfile = () => axiosClient.get('api/me')

    ssoLoginApi = () => axiosClient.post('api/sso/microsoft')

    ssoAuthorizeApi = (code) =>
        axiosClient.post('api/sso/microsoft/authorize', { code })
}

export default new AuthenticateApi()
