import {
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import { DeliveryDetailHeadCells } from '~/components/HeaderTable/DeliveryHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import { useParams } from 'react-router-dom'
import { useDeliveryDetailQuery } from '../query'
import DeliveryDetailToolbar from './DeliveryDetailToolbar'
import DetailDeliveryTableFilters from './DetailDeliveryTableFilters'
import PropTypes from 'prop-types'

const localization = 'pages.procurement.delivery.detail.'

const DeliveryDetailBodyTable = ({ isLoading, delivery }) => {
    if (isLoading) {
        return <TableLoading colSpan={DeliveryDetailHeadCells.length} />
    }

    return (
        <TableBody>
            {delivery.length > 0 ? (
                delivery.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                            <TableCell align='left'>
                                {row.total_number_of_units}
                            </TableCell>
                            <TableCell align='left'>{row.trc_sku}</TableCell>
                            <TableCell align='left'>
                                {row.supplier_sku}
                            </TableCell>
                            <TableCell align='left'>{row.name}</TableCell>
                            <TableCell align='left'>{row.vendor_sku}</TableCell>
                            <TableCell align='left'>{row.qty}</TableCell>
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={DeliveryDetailHeadCells.length} />
            )}
        </TableBody>
    )
}

DeliveryDetailBodyTable.propTypes = {
    isLoading: PropTypes.bool,
    delivery: PropTypes.object,
}

const DeliveryDetailTable = () => {
    const { id } = useParams()

    const {
        data,
        isLoading,
        order,
        orderBy,
        page,
        search,
        searchHandler,
        changePageHandler,
        sortHandler,
        results: delivery,
        onEnterSearch,
    } = useTable({
        query: useDeliveryDetailQuery,
        queryAttributes: {
            id,
        },
    })

    return (
        <>
            <DeliveryDetailToolbar payload={{ search, order, orderBy }} />

            <Paper sx={{ width: '100%', mb: 2 }}>
                <DetailDeliveryTableFilters
                    search={search}
                    searchHandler={searchHandler}
                    onEnterSearch={onEnterSearch}
                />
                <Divider />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby='tableTitle'
                        size='medium'
                    >
                        <TableHeader
                            headCells={DeliveryDetailHeadCells}
                            localization={localization}
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                        />
                        <DeliveryDetailBodyTable
                            isLoading={isLoading}
                            delivery={delivery}
                        />
                    </Table>
                </TableContainer>
                <PaginationTable
                    data={data}
                    page={page}
                    handleChangePage={changePageHandler}
                    size='medium'
                />
            </Paper>
        </>
    )
}

export default DeliveryDetailTable
