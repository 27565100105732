import roleApi from '~/api/acl/roleApi'
import userApi from '~/api/userApi'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { setUserDetailInformation } from '~/store/user/slice'
import { removeEmptyValue } from '~/utils/helpers'
import { DEFAULT_CACHE_TTL } from '~/utils/constants'

export const useUsersProfileQuery = (id, status, isSuccess) => {
    const dispatch = useDispatch()
    return useQuery(
        ['user', id, 'profile', status],
        async () => {
            const { data } = await userApi.getUserProfile(id)
            return data.data
        },
        {
            onSuccess: (data) => {
                dispatch(setUserDetailInformation(data))
            },
            onError: () => {},
            enabled: status === '' ? true : !!isSuccess,
        },
    )
}

export const useGetAllRolesQuery = (guardName) => {
    return useQuery(
        ['user_detail', 'get_all_roles'],
        async () => {
            const { data } = await roleApi.getRolesByGuard(guardName)
            return data.data
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useUsersTeamsQuery = ({ ...params }) => {
    const { openSnackbar } = useDialogContext()
    const { t } = useTranslation()
    return useQuery(
        ['user_teams', params],
        async () => {
            const p = removeEmptyValue(params)

            const { data } = await userApi.getUserTeams(p)
            return data
        },
        {
            onSuccess: () => {},
            onError: (data) => {
                openSnackbar({
                    message:
                        data?.response?.data?.message ??
                        t('userIsNotPartOfaTeam'),
                    type: 'error',
                })
            },
        },
    )
}

export const useTeamsUserNotInQuery = (userId) => {
    return useQuery(
        ['teams_user_not_in', userId],
        async () => {
            const { data } = await userApi.getTeamsUserNotIn(userId)
            return data
        },
        {
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
        },
    )
}

export const useGetTeamsByServiceCenter = (
    id,
    name,
    userCurrentServiceCenterId,
) => {
    const { openSnackbar } = useDialogContext()
    const { t } = useTranslation('translation', { keyPrefix: 'general' })
    return useQuery({
        queryKey: ['get_teams_by_service_center', id],
        queryFn: async () => {
            const { data } = await userApi.getTeamsByServiceCenter(id)

            if (data.data.length === 0) {
                openSnackbar({
                    message: t('notFoundForServiceCenter', { sc: name }),
                    type: 'warning',
                })
            } else if (id !== userCurrentServiceCenterId) {
                openSnackbar({
                    message: t('shouldSwitchTeam'),
                    type: 'warning',
                })
            }

            return data.data
        },
        cacheTime: DEFAULT_CACHE_TTL,
        staleTime: DEFAULT_CACHE_TTL,
        enabled: !!id,
    })
}
