import { createSlice } from '@reduxjs/toolkit'

export const issueSlice = createSlice({
    name: 'issue',
    initialState: {
        canProceed: false,
    },
    reducers: {
        toggleCanProceed: (state, action) => {
            state.canProceed = !!action.payload
        },
    },
})

export const { toggleCanProceed } = issueSlice.actions

export default issueSlice
