export const PartCodeHeadCells = [
    {
        id: 'code',
        numeric: false,
        disablePadding: false,
        label: 'Code',
        orderable: true,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
        orderable: false,
    },
    {
        id: 'type',
        numeric: false,
        disablePadding: false,
        label: 'Type',
        orderable: false,
    },
    {
        id: 'count',
        numeric: true,
        disablePadding: false,
        label: 'Model',
        orderable: true,
    },
]
