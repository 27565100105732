import { Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import { LoadingButton } from '~/components/Button'
import DataNotFound from '~/components/DataNotFound'
import PaginationTable from '~/components/PaginationTable'
import StyledModal from '~/components/StyledModal'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ComponentRow from './ComponentRow'
import { ComponentHeadCells } from './constants'
import { useUnlinkComponentToProductMutation } from './mutation'
import { useComponentsLinkedToProductsQuery } from './query'
import PropTypes from 'prop-types'

const localization = 'pages.components.'

const ComponentTableBody = ({ isLoading, components }) => {
    const { t } = useTranslation()
    const [openUnlinkConponentPopup, setOpenUnlinkConponentPopup] =
        useState(false)
    const [productComponentId, setProductComponentId] = useState(null)

    const handleCloseUnlinkConponentPopup = () => {
        setOpenUnlinkConponentPopup(false)
    }

    const { mutate: unlinkComponent, isLoading: loadingUnLink } =
        useUnlinkComponentToProductMutation()

    const confirmUnlink = (componentId) => {
        setOpenUnlinkConponentPopup(true)
        setProductComponentId(componentId)
    }

    const handleRemoveComponent = () => {
        unlinkComponent({ component_id: productComponentId })
    }

    if (isLoading) {
        return <TableLoading colSpan={ComponentHeadCells.length} />
    }

    return (
        <>
            <TableBody>
                {components.length > 0 ? (
                    components.map((row) => (
                        <ComponentRow
                            key={row.id}
                            row={row}
                            confirmUnlink={confirmUnlink}
                        />
                    ))
                ) : (
                    <DataNotFound colSpan={ComponentHeadCells.length} />
                )}
            </TableBody>
            <StyledModal
                open={openUnlinkConponentPopup}
                handleClose={handleCloseUnlinkConponentPopup}
            >
                <Typography m={3}>{t(`pages.components.areUSure`)}</Typography>
                <LoadingButton
                    loading={loadingUnLink}
                    label='Confirm'
                    onClick={() => {
                        handleRemoveComponent()
                        setOpenUnlinkConponentPopup(false)
                    }}
                />
            </StyledModal>
        </>
    )
}

ComponentTableBody.propTypes = {
    isLoading: PropTypes.bool,
    components: PropTypes.arrayOf(PropTypes.object),
}

const ProductComponent = () => {
    const {
        data,
        isLoading,
        order,
        orderBy,
        sortHandler,
        page,
        changePageHandler,
    } = useTable({
        query: useComponentsLinkedToProductsQuery,
        initialStates: {
            order: 'asc',
        },
        queryAttributes: {},
    })

    const components = useMemo(() => {
        return data?.data || []
    }, [data])

    return (
        <>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-label='collapsible table'
                        size='medium'
                    >
                        <TableHeader
                            headCells={ComponentHeadCells}
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                            localization={localization}
                        />

                        <ComponentTableBody
                            isLoading={isLoading}
                            components={components}
                        />
                    </Table>
                </TableContainer>

                <PaginationTable
                    data={data}
                    page={page}
                    size='medium'
                    handleChangePage={changePageHandler}
                />
            </Paper>
        </>
    )
}

export default ProductComponent
