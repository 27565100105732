import dispatchApi from '~/api/dispatchApi'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { useMutation, useQueryClient } from 'react-query'

export const useMappingPartCodeDispatch = (
    dispatchId,
    setDispatchId,
    isClose,
) => {
    const { openSnackbar } = useDialogContext()
    const queryClient = useQueryClient()
    return useMutation(
        ['mapping_partCode'],
        async (payload) => {
            const { data } = await dispatchApi.mappingPartCode(
                dispatchId,
                payload,
            )
            return data
        },
        {
            onSuccess: (data) => {
                if (data.message) {
                    openSnackbar({
                        message: data.message,
                        type: 'success',
                    })
                }
                if (isClose) {
                    setDispatchId(null)
                }
                queryClient.invalidateQueries('dispatch_Shipment_Report')
            },
            onError: () => {
                openSnackbar({
                    message: 'Mapping Failed',
                    type: 'error',
                })
            },
            retry: false,
        },
    )
}
