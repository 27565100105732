import { FormControl, Grid, Stack, Typography } from '@mui/material'
import arrayMutators from 'final-form-arrays'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { object, string } from 'yup'
import InputField from '~/components/FinalForm/InputField'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import SubmitButton from '~/components/SubmitButton'
import {
    PRODUCTION_PROJECTS,
    PRODUCTION_PROJECTS_DETAIL,
} from '~/constants/Routes'
import useFormValidationCallback from '~/hooks/useFormValidationCallback'
import { useSnackbar } from '~/hooks/useSnackbar'
import { useUpdateProjectMutation } from '../mutation'
import StyledModal from '~/components/StyledModal'
import { LoadingButton } from '~/components/Button'
import SelectField from '~/components/FinalForm/SelectField'
import { useListDataForInsertProjectQuery } from '../query'

const UpdateProject = ({ data, setOpenUpdateProject }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { snackbar, showSnackbar } = useSnackbar()
    const handleClose = () => {
        setOpenUpdateProject(false)
    }

    const localization = 'pages.production.projects'
    const pagePath = PRODUCTION_PROJECTS
    const schema = object().shape({
        project_nr: string().required(),
        entity_tid: string().required(),
        active: string().required(),
        os_version: string().required(),
        language: string().required(),
        target_group: string().required(),
        state: string().required(),
    })

    const { mutate: importProjectMutate, isLoading } = useUpdateProjectMutation(
        {
            id: data?.id,
            showSnackbar,
        },
    )
    const validate = useFormValidationCallback(schema)

    const submit = useCallback(
        (payload) => {
            importProjectMutate(payload, {
                onSuccess: (data) => {
                    navigate(
                        PRODUCTION_PROJECTS_DETAIL.replace(
                            ':project_nr',
                            data.project_nr,
                        ),
                    )
                },
            })
        },
        [importProjectMutate, navigate],
    )

    const onSubmit = useCallback(
        (values) => {
            submit({
                project_nr: values.project_nr,
                entity_tid: values.entity_tid,
                service_center_id: values.service_center_id,
                external_id: values.external_id,
                production_image_id: values.production_image_id,
                active: values.active,
                os_version: values.os_version,
                language: values.language,
                target_group: values.target_group,
                delivery_date: values.delivery_date,
                intune_group_tag: values.intune_group_tag,
                state: values.state,
            })
        },
        [submit],
    )

    const { data: dataForInsert } = useListDataForInsertProjectQuery()

    const serviceCenters = useMemo(() => {
        if (dataForInsert && dataForInsert.serviceCenters) {
            return dataForInsert.serviceCenters.map((item) => ({
                name: item.name,
                value: item.id,
            }))
        }
        return []
    }, [dataForInsert])

    const orgs = useMemo(() => {
        if (dataForInsert && dataForInsert.orgs) {
            return dataForInsert.orgs.map((item) => ({
                name: item.name,
                value: item.external_id,
            }))
        }
        return []
    }, [dataForInsert])

    const images = useMemo(() => {
        if (dataForInsert && dataForInsert.images) {
            return dataForInsert.images.map((item) => ({
                name: item.name,
                value: item.id,
            }))
        }
        return []
    }, [dataForInsert])

    const actives = useMemo(() => {
        return [
            { name: 'True', value: 1 },
            { name: 'False', value: 0 },
        ]
    }, [])

    return (
        <>
            {snackbar}
            <StyledModal
                open={open}
                handleClose={() => {
                    handleClose()
                }}
                stackProps={{
                    sx: {
                        minWidth: 800,
                        maxHeight: '95vh',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '10px',
                            backgroundColor: 'rgba(0, 0, 0, .5)',
                        },
                        '&::-webkit-scrollbar': {
                            width: '10px',
                        },
                    },
                }}
            >
                <Typography variant='h5' sx={{ marginTop: '10px' }}>
                    {t('pages.production.projects.editProject')}
                </Typography>
                <Form
                    validate={validate}
                    onSubmit={onSubmit}
                    mutators={{
                        ...arrayMutators,
                    }}
                    keepDirtyOnReinitialize
                    render={({ handleSubmit }) => (
                        <FormBodyWrapper>
                            <Grid
                                container
                                justifyContent='space-between'
                                alignContent='space-around'
                                sx={{ marginBottom: '10px' }}
                            >
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '99%' }}>
                                        <InputField
                                            localization={localization}
                                            name={'project_nr'}
                                            componentId={'project_nr'}
                                            pagePath={pagePath}
                                            isRequired={true}
                                            defaultValue={data?.project_nr}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '99%' }}>
                                        <InputField
                                            localization={localization}
                                            name={'entity_tid'}
                                            componentId={'entity_tid'}
                                            pagePath={pagePath}
                                            isRequired={true}
                                            defaultValue={data?.entity_tid}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                justifyContent='space-between'
                                alignContent='space-around'
                                sx={{ marginBottom: '10px' }}
                            >
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '99%' }}>
                                        <Field
                                            name='service_center_id'
                                            component={SelectField}
                                            items={serviceCenters}
                                            localization={localization}
                                            componentId='service_center_id'
                                            pagePath={pagePath}
                                            nameSelect='service_center_id'
                                            isRequired={false}
                                            defaultValue={
                                                data?.service_center_id
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '99%' }}>
                                        <Field
                                            name='external_id'
                                            component={SelectField}
                                            items={orgs}
                                            localization={localization}
                                            componentId='external_id'
                                            pagePath={pagePath}
                                            nameSelect='external_id'
                                            isRequired={false}
                                            defaultValue={data?.external_id}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                justifyContent='space-between'
                                alignContent='space-around'
                                sx={{ marginBottom: '10px' }}
                            >
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '99%' }}>
                                        <Field
                                            name='production_image_id'
                                            component={SelectField}
                                            items={images}
                                            localization={localization}
                                            componentId='production_image_id'
                                            pagePath={pagePath}
                                            nameSelect='production_image_id'
                                            isRequired={false}
                                            defaultValue={
                                                data?.production_image_id
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '99%' }}>
                                        <Field
                                            name='active'
                                            component={SelectField}
                                            items={actives}
                                            localization={localization}
                                            componentId='active'
                                            pagePath={pagePath}
                                            nameSelect='active'
                                            isRequired={false}
                                            defaultValue={data?.active}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                justifyContent='space-between'
                                alignContent='space-around'
                                sx={{ marginBottom: '10px' }}
                            >
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '99%' }}>
                                        <InputField
                                            localization={localization}
                                            name={'os_version'}
                                            componentId={'os_version'}
                                            pagePath={pagePath}
                                            isRequired={true}
                                            defaultValue={data?.os_version}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '99%' }}>
                                        <InputField
                                            localization={localization}
                                            name={'language'}
                                            componentId={'language'}
                                            pagePath={pagePath}
                                            isRequired={true}
                                            defaultValue={data?.language}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                justifyContent='space-between'
                                alignContent='space-around'
                                sx={{ marginBottom: '10px' }}
                            >
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '99%' }}>
                                        <InputField
                                            localization={localization}
                                            name={'target_group'}
                                            componentId={'target_group'}
                                            pagePath={pagePath}
                                            isRequired={true}
                                            defaultValue={data?.target_group}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '99%' }}>
                                        <InputField
                                            localization={localization}
                                            name={'intune_group_tag'}
                                            componentId={'intune_group_tag'}
                                            pagePath={pagePath}
                                            isRequired={false}
                                            defaultValue={
                                                data?.intune_group_tag
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                justifyContent='space-between'
                                alignContent='space-around'
                                sx={{ marginBottom: '10px' }}
                            >
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '99%' }}>
                                        <InputField
                                            localization={localization}
                                            name={'delivery_date'}
                                            componentId={'delivery_date'}
                                            pagePath={pagePath}
                                            isRequired={false}
                                            defaultValue={data?.delivery_date}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '99%' }}>
                                        <InputField
                                            localization={localization}
                                            name={'state'}
                                            componentId={'state'}
                                            pagePath={pagePath}
                                            isRequired={true}
                                            defaultValue={data?.state}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction='row-reverse' spacing={2}>
                                    <LoadingButton
                                        label={t('button.close')}
                                        onClick={handleClose}
                                        loading={isLoading}
                                    />
                                    <SubmitButton
                                        handleSubmit={handleSubmit}
                                        name={`${t('button.edit')}`}
                                        loading={isLoading}
                                    />
                                </Stack>
                            </Grid>
                        </FormBodyWrapper>
                    )}
                />
            </StyledModal>
        </>
    )
}

UpdateProject.propTypes = {
    data: PropTypes.object,
    setOpenUpdateProject: PropTypes.func,
}

export default UpdateProject
