import PropTypes from 'prop-types'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {
    CircularProgress,
    Collapse,
    Grid,
    Link,
    Stack,
    TableCell as MuiTableCell,
    Typography,
} from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomAvatar from './CustomAvatar'

export const TableCell = ({ cellValue, color, type }) => {
    return (
        <MuiTableCell component='th' scope='row'>
            {type === 'component' ? (
                cellValue
            ) : (
                <Typography variant='body2' align='left' color={color}>
                    {cellValue}
                </Typography>
            )}
        </MuiTableCell>
    )
}

TableCell.propTypes = {
    cellValue: PropTypes.any,
    color: PropTypes.string,
    type: PropTypes.string,
}

export const TableClickableCell = ({ cellValue, link }) => {
    const navigate = useNavigate()
    return (
        <MuiTableCell component='th' scope='row'>
            <Link
                component='button'
                variant='inherit'
                to='#'
                underline='hover'
                onClick={() => navigate(link)}
            >
                <Typography variant='body2' align='left'>
                    {cellValue}
                </Typography>
            </Link>
        </MuiTableCell>
    )
}

TableClickableCell.propTypes = {
    cellValue: PropTypes.any,
    link: PropTypes.string,
}

export const TableActionCell = ({ item, row }) => {
    const [loading, setLoading] = useState(false)
    return (
        <MuiTableCell component='th' scope='row'>
            {loading ? (
                <CircularProgress size={30} />
            ) : (
                <DeleteOutlineIcon
                    onClick={() => item.handleAction(row.id, setLoading)}
                    color='error'
                />
            )}
        </MuiTableCell>
    )
}

TableActionCell.propTypes = {
    item: PropTypes.shape({
        handleAction: PropTypes.func.isRequired,
    }),
    row: PropTypes.shape({
        id: PropTypes.any,
    }),
}

export const StyledCell = ({ item, row }) => {
    return <MuiTableCell>{item.styledCell(row)}</MuiTableCell>
}

StyledCell.propTypes = {
    item: PropTypes.shape({
        styledCell: PropTypes.func.isRequired,
    }),
    row: PropTypes.object,
}

export const TableImageCell = ({ item, row }) => {
    return (
        <MuiTableCell component='th' scope='row'>
            <Stack direction='row' spacing={1}>
                <CustomAvatar
                    alt={item.name}
                    url={`${item.customVal(row).url}`}
                />
                <Grid
                    container
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='center'
                >
                    <Typography>{item.customVal(row).name}</Typography>
                </Grid>
            </Stack>
        </MuiTableCell>
    )
}
TableImageCell.propTypes = {
    item: PropTypes.shape({
        customVal: PropTypes.func.isRequired,
        name: PropTypes.string,
    }),
    row: PropTypes.object,
}

export const useTableCollapsibleCell = ({ items }) => {
    const [open, setOpen] = useState(false)
    const cell = (
        <MuiTableCell component='th' scope='row' align='left'>
            <Collapse in={open} timeout={'auto'} unmountOnExit>
                {items ? (
                    items.map((item) => (
                        <Typography variant='body2' key={item}>
                            {item}
                        </Typography>
                    ))
                ) : (
                    <Typography variant='body2'>{''}</Typography>
                )}
            </Collapse>
        </MuiTableCell>
    )

    return { cell, setOpen, open }
}

export default TableCell
