import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import { PRODUCTION_SYSTEMIMAGE } from '~/constants/Routes'
import AddonsTable from './AddonsTable'
import { LoadingButton } from '~/components/Button'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import AddAddOn from './AddAddOn'
import { Box } from '@mui/material'

const AddonsOverview = () => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const breadCrumbConfig = [
        { route: PRODUCTION_SYSTEMIMAGE, title: 'Production' },
        { route: '#', title: 'Addons' },
    ]

    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <PageName name={'Addons'} />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'right',
                    marginBottom: '10px',
                }}
            >
                <LoadingButton
                    label={t('button.add')}
                    onClick={() => {
                        setOpen(true)
                    }}
                    loading={false}
                />
            </Box>
            <AddonsTable />
            {open && <AddAddOn setOpen={setOpen} />}
        </>
    )
}

export default AddonsOverview
