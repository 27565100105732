import { Grid, IconButton, Paper, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import TypographyTitleAccordion from '~/components/TypographyTitleAccordion'
import { useTranslation } from 'react-i18next'
import { useGetDeviceBySerialQuery } from '~/pages/Dispatch/AddDispatch/query'
import { DEVICE_DETAIL_LOCALIZATION } from '~/pages/Dispatch/AddDispatch/constants'
import DeviceEntitlements from '~/pages/Device/Serial/SerialDetail/DeviceEntitlements'
import TableHeader from '~/components/TableHeader'
import TableContainer from '~/components/Table/TableContainer'
import useTable from '~/hooks/useTable'
import { useListBomDetailByIssueQuery } from '~/pages/BOM/query'
import { headCells } from '~/pages/BOM/Detail/constants'
import PaginationTable from '~/components/PaginationTable'
import BomDetailBodyTable from '~/pages/BOM/Detail/BomDetailBodyTable'
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded'
import { useQueryClient } from 'react-query'
import { useRefreshBoms } from '~/pages/BOM/mutate'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import DeviceInformation from './DeviceInformation'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useRefetchIssueQuery } from '~/pages/Issue/query'
import { useParams } from 'react-router-dom'

const BomDetail = ({ issue }) => {
    const queryClient = useQueryClient()
    const { issueId } = useParams()
    const { data: device, isLoading: loadingDevice } =
        useGetDeviceBySerialQuery({ serial: issue.data.name })
    const { data: refetchData } = useRefetchIssueQuery(issueId)
    const [isFetch, setIsFetch] = useState(false)
    const intervalRef = useRef(null)

    const localization = 'pages.bom.'
    const { t } = useTranslation()
    const {
        data,
        results: boms,
        isLoading,
        page,
        order,
        orderBy,
        changePageHandler,
        sortHandler,
    } = useTable({
        query: useListBomDetailByIssueQuery,
        queryAttributes: {
            issueId: issue.data.id,
        },
    })
    const { openSnackbar } = useDialogContext()
    const { mutate, isLoading: isRefreshing } = useRefreshBoms()

    const deviceInformations = useMemo(
        () => [
            {
                key: t(`${localization}serial`),
                value: issue.data?.name,
            },
            {
                key: t(`${localization}issue_id`),
                value: issue.data?.id,
            },
            {
                key: t(`${localization}bom_state`),
                value: issue.data?.bom_state,
            },
            {
                key: t(`${localization}device_model_name`),
                value:
                    device?.name ??
                    device?.device_model?.name ??
                    device?.device_model?.description ??
                    'N/A',
            },
        ],
        [
            device?.device_model?.description,
            device?.device_model?.name,
            device?.name,
            issue.data?.bom_state,
            issue.data?.id,
            issue.data?.name,
            t,
        ],
    )
    const checkDifferent = useCallback(() => {
        queryClient.invalidateQueries('issue_refetch')

        if (
            refetchData?.data?.updated_at !== issue.data.updated_at ||
            refetchData?.data?.bom_state !== issue.data.bom_state ||
            !_.isEqual(refetchData.data, issue.data)
        ) {
            setIsFetch(false)
            queryClient.invalidateQueries('issue')
            clearInterval(intervalRef.current)
        }
    }, [issue.data, queryClient, refetchData?.data])

    useEffect(() => {
        if (isFetch) {
            checkDifferent()
            intervalRef.current = setInterval(checkDifferent, 10000)
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
        }
    }, [checkDifferent, isFetch])

    const handleRefresh = () => {
        mutate(
            { issue_id: issue.data.id },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries('bom_detail')
                    setIsFetch(true)
                    openSnackbar({
                        message: t(`message.refreshSucess`),
                        type: 'success',
                    })
                },
                onError: () => {
                    openSnackbar({
                        message: t(`message.refreshFailed`),
                        type: 'error',
                    })
                },
            },
        )
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            py: 1,
                            px: 2,
                            height: '100%',
                        }}
                    >
                        <Typography variant='h6' gutterBottom>
                            {t(
                                `${DEVICE_DETAIL_LOCALIZATION}.device_information`,
                            )}
                        </Typography>
                        <DeviceInformation
                            deviceInfors={deviceInformations}
                            isLoading={loadingDevice}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            py: 1,
                            px: 2,
                            height: '100%',
                        }}
                    >
                        <TypographyTitleAccordion
                            gutterBottom
                            variant='h6'
                            component='div'
                        >
                            {t(`${localization}bom_title`)}
                            {!isRefreshing && (
                                <IconButton
                                    size='small'
                                    onClick={handleRefresh}
                                >
                                    <RefreshRoundedIcon color='primary' />
                                </IconButton>
                            )}
                        </TypographyTitleAccordion>
                        <TableContainer>
                            <TableHeader
                                headCells={headCells}
                                localization={localization}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={sortHandler}
                            />
                            <BomDetailBodyTable
                                boms={boms}
                                isLoading={isLoading || isRefreshing}
                                bomState={issue.data.bom_state}
                            />
                        </TableContainer>
                        <PaginationTable
                            data={data}
                            page={page}
                            handleChangePage={changePageHandler}
                            size='medium'
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            py: 1,
                            px: 2,
                            height: '100%',
                        }}
                    >
                        <Typography variant='h6' gutterBottom>
                            {t(`${DEVICE_DETAIL_LOCALIZATION}.entitlements`)}
                        </Typography>
                        {!loadingDevice && device && (
                            <DeviceEntitlements deviceId={device.id} />
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}

BomDetail.propTypes = {
    issue: PropTypes.object,
}

export default BomDetail
