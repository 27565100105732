import orderConfirmationApi from '~/api/orderConfirmationApi'
import { useQuery } from 'react-query'
import { removeEmptyValue } from '~/utils/helpers'

export const useOrderConfirmationsQuery = (params) => {
    const p = removeEmptyValue(params)
    return useQuery(
        ['OrderConfirmation', params],
        async () => {
            const { data } = await orderConfirmationApi.getOrderConfirmations(p)
            return { data: data.data, total: data.meta.total }
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useSuppliersQuery = () => {
    return useQuery(
        ['suppliers'],
        async () => {
            const { data } = await orderConfirmationApi.getSuppliers()
            return data
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useOrderStatusesQuery = () => {
    return useQuery(
        ['order_statuses'],
        async () => {
            const { data } = await orderConfirmationApi.getOrderStatuses()
            return data
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}
