export const ImportLenovoHeadCells = [
    {
        id: 'id',
        label: 'no',
    },
    {
        id: 'serial',
        label: 'serial',
    },
    {
        id: 'status',
        label: 'status',
    },
]
