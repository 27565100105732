import issueApi from '~/api/issueApi'
import issueQcApi from '~/api/issueQcApi'
import { useMutation } from 'react-query'
import { base64ImageToBlob } from '~/utils/helpers'

export const useScanSerialForQcMutation = () =>
    useMutation(async ({ issueId, serial, defaultAgreeQc }) => {
        const { data } = await issueApi.scanSerialForQC(
            issueId,
            serial,
            defaultAgreeQc,
        )

        return data
    })

export const useUpdateAnswersForQcMutation = (dataIssue) =>
    useMutation(async ({ issueId, answers }) => {
        if (dataIssue?.data.state !== 'QC_DONE') {
            const { data } = await issueApi.updateAnswersForQc(issueId, answers)

            return data
        }
        return null
    })

export const useUpdateOverruleAnswersForQcMutation = () =>
    useMutation(
        async ({ issueId, answers, deletePictures, comment, newPictures }) => {
            const formData = new FormData()

            formData.append('answers', answers)
            deletePictures.forEach((picID) => {
                formData.append('deletePictures[]', picID)
            })
            formData.append('comment', comment)

            let i = 0
            for (const picture of newPictures) {
                let imgBlob = picture.image
                if (!picture?.notConvert) {
                    imgBlob = await base64ImageToBlob(picture.image)
                }
                formData.append(`pictures[${i}]`, imgBlob)
                ++i
            }

            const { data } = await issueApi.updateAnswersOverruleForQc(
                issueId,
                formData,
            )

            return data
        },
    )

export const useUploadPicturesForQcMutation = () =>
    useMutation(async ({ issueId, pictures, upload = false }) => {
        const formData = new FormData()
        let i = 0
        for (const picture of pictures) {
            let imgBlob = picture
            if (!upload) {
                imgBlob = await base64ImageToBlob(picture)
            }
            formData.append(`pictures[${i}]`, imgBlob)
            ++i
        }
        const { data } = await issueApi.uploadPicturesForQc(issueId, formData)
        return data
    })

export const useUpdateQcCommentMutation = () =>
    useMutation(async ({ issueId, comment }) => {
        const { data } = await issueApi.updateQcComment(issueId, comment)
        return data
    })

export const useDeleteQcPicturesMutation = () =>
    useMutation(async (pictureId) => {
        const { data } = await issueQcApi.deleteQcPicture(pictureId)
        return data
    })

export const useSaveIssueQcMutation = () =>
    useMutation(async (issueId) => {
        const { data } = await issueQcApi.saveIssueQc(issueId)
        return data
    })

export const useSupervisorAcceptQCMutation = () =>
    useMutation(async ({ issueId, comment }) => {
        const { data } = await issueQcApi.supervisorAcceptQC(issueId, comment)
        return data
    })
