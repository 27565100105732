import { createSlice } from '@reduxjs/toolkit'

export const formatDateStateSlice = createSlice({
    name: 'formatDateState',
    initialState: {
        formatDateState: 'yyyy-MM-dd',
    },
    reducers: {
        setformatDateState: (state, action) => {
            state.formatDateState = action.payload
        },
    },
})

export const { setformatDateState } = formatDateStateSlice.actions

export default formatDateStateSlice
