import { Modal, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

const StyledModal = ({
    children,
    open,
    handleClose,
    stackProps,
    modalProps,
    noStack = false,
    disableBackdropClick = false,
    sx,
}) => {
    useEffect(() => {
        if (open) {
            localStorage.setItem('AUTO_FOCUS_SEARCH', false)
        }
    }, [open])

    const handleCloseModal = (_, reason) => {
        if (
            disableBackdropClick &&
            (reason === 'backdropClick' || reason === 'escapeKeyDown')
        ) {
            return
        }

        handleClose()
        localStorage.setItem('AUTO_FOCUS_SEARCH', true)
    }
    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...sx,
            }}
            {...modalProps}
        >
            {noStack ? (
                <>{children}</>
            ) : (
                <Stack
                    bgcolor={'background.default'}
                    color={'text.primary'}
                    p={3}
                    borderRadius={5}
                    width={500}
                    direction='column'
                    justifyContent='space-between'
                    alignItems='center'
                    {...stackProps}
                >
                    {children}
                </Stack>
            )}
        </Modal>
    )
}

StyledModal.propTypes = {
    children: PropTypes.node,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    stackProps: PropTypes.object,
    modalProps: PropTypes.object,
    noStack: PropTypes.bool,
    sx: PropTypes.object,
    disableBackdropClick: PropTypes.bool,
}

export default StyledModal
