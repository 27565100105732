import { Box, Checkbox, List, TextField, Typography } from '@mui/material'
import { LoadingButton } from '~/components/Button'
import StyledModal from '~/components/StyledModal'
import PropTypes from 'prop-types'
import { useGetRootCausesBySerialAndPartCodesMutation } from '~/pages/Diagnostic/mutation'
import { useEffect, useState } from 'react'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Search from '~/components/Search/Search'
import { useTranslation } from 'react-i18next'

const locale = localStorage.getItem('LOCALE')

const RepairCannotBeFinishedPopup = ({
    isOpen,
    closeHandler,
    comment,
    isLoading,
    setComment,
    setCommentError,
    commentError,
    onRepairCannotBeFinishedSaveCommentClick,
}) => {
    const LOCALIZATION = 'pages.issues.repair.'
    const { t } = useTranslation()
    return (
        <StyledModal
            open={isOpen}
            handleClose={closeHandler}
            stackProps={{
                gap: 2,
            }}
        >
            <Typography variant='h6'>
                {t(`${LOCALIZATION}titleCannotRepair`)}
            </Typography>
            <TextField
                multiline
                value={comment}
                minRows={5}
                maxRows={5}
                style={{
                    width: '100%',
                }}
                onChange={(e) => {
                    setComment(e.target.value)
                    setCommentError('')
                }}
                error={commentError !== ''}
                helperText={commentError}
            />
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    mt: 2,
                }}
            >
                <LoadingButton
                    label={'Cancel'}
                    onClick={closeHandler}
                    loading={false}
                />
                <LoadingButton
                    label={'Save'}
                    onClick={onRepairCannotBeFinishedSaveCommentClick}
                    loading={isLoading}
                />
            </Box>
        </StyledModal>
    )
}

RepairCannotBeFinishedPopup.propTypes = {
    isOpen: PropTypes.bool,
    comment: PropTypes.string,
    commentError: PropTypes.string,
    isLoading: PropTypes.bool,
    closeHandler: PropTypes.func,
    setComment: PropTypes.func,
    setCommentError: PropTypes.func,
    onRepairCannotBeFinishedSaveCommentClick: PropTypes.func,
}
const DoaPopup = ({
    isOpen,
    serial,
    partCodes,
    closeHandler,
    selectedCauses,
    setSelectedCauses,
    onRepairCannotBeFinishedSaveCommentClick,
    isLoadingSubmit,
}) => {
    const LOCALIZATION = 'pages.issues.repair.'
    const { openSnackbar } = useDialogContext()
    const { t } = useTranslation()
    const [rootCauses, setRootCauses] = useState([])
    const [search, setSearch] = useState('')
    const {
        mutate: getRootCauses,
        isLoading,
        data,
        isError,
        error,
    } = useGetRootCausesBySerialAndPartCodesMutation(locale)

    useEffect(() => {
        if (isOpen && serial?.length > 0 && partCodes?.length > 0) {
            getRootCauses({ serial, partCodes, search })
        }
    }, [getRootCauses, isOpen, serial, partCodes, search])

    useEffect(() => {
        if (isError) {
            openSnackbar({
                message:
                    error?.response?.data?.message ||
                    t(`${LOCALIZATION}message.errorFromApi`),
                type: 'error',
            })
            return
        }
        if (data) {
            if (data?.data?.data?.length === 0) {
                openSnackbar({
                    message: t(`${LOCALIZATION}message.notFound`),
                    type: 'error',
                })
            }
            setRootCauses(data?.data?.data)
        }
    }, [data, openSnackbar, isError, error, t])

    useEffect(() => {
        if (!isOpen) {
            setRootCauses([])
            setSearch('')
            setSelectedCauses({})
        }
    }, [isOpen, setSelectedCauses])

    const handleToggle = (e, cause) => {
        const newSelectedCauses = { ...selectedCauses }
        if (e.target.checked) {
            newSelectedCauses[cause.id] = true
        } else {
            delete newSelectedCauses[cause.id]
        }
        setSelectedCauses({ ...newSelectedCauses })
    }

    return (
        <StyledModal
            open={isOpen}
            handleClose={closeHandler}
            stackProps={{
                gap: 2,
            }}
        >
            <Typography variant='h6'>
                {t(`${LOCALIZATION}titleCannotRepair`)}
            </Typography>
            {!isError && (
                <Box>
                    <Search
                        label={t(`${LOCALIZATION}search`)}
                        autoFocus
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                setSearch(e.target.value)
                            }
                        }}
                    />
                </Box>
            )}
            {rootCauses.length > 0 ? (
                <List
                    sx={{
                        position: 'relative',
                        overflow: 'auto',
                        maxHeight: 200,
                        '& ul': { padding: 0 },
                    }}
                >
                    {rootCauses.map((cause, index) => {
                        return (
                            <ListItem key={index} disablePadding>
                                <ListItemIcon>
                                    <Checkbox
                                        edge='start'
                                        tabIndex={-1}
                                        disableRipple
                                        onChange={(e) => handleToggle(e, cause)}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={`${cause.name} (${cause.tid})`}
                                />
                            </ListItem>
                        )
                    })}
                </List>
            ) : (
                <Box>
                    <Typography gutterBottom align='center' variant='subtitle1'>
                        {t(`${LOCALIZATION}message.notFound`)}
                    </Typography>
                </Box>
            )}
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    mt: 2,
                }}
            >
                <LoadingButton
                    label={'Cancel'}
                    onClick={closeHandler}
                    loading={isLoading || isLoadingSubmit}
                />
                <LoadingButton
                    label={'Save'}
                    onClick={onRepairCannotBeFinishedSaveCommentClick}
                    loading={isLoading || isLoadingSubmit}
                    disabled={Object.keys(selectedCauses).length === 0}
                />
            </Box>
        </StyledModal>
    )
}

DoaPopup.propTypes = {
    isOpen: PropTypes.bool,
    serial: PropTypes.string,
    partCodes: PropTypes.arrayOf(PropTypes.string),
    selectedCauses: PropTypes.object,
    closeHandler: PropTypes.func,
    setSelectedCauses: PropTypes.func,
    onRepairCannotBeFinishedSaveCommentClick: PropTypes.func,
    isLoadingSubmit: PropTypes.bool,
}

export { RepairCannotBeFinishedPopup, DoaPopup }
