import BlackOutlineInput from '~/components/BlackOutlineInput'
import {
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import ComponentTooltip from '~/components/ComponentTooltip'
import AsterisksRequiredField from '~/components/FormInput/AsterisksRequiredField'
import Proptypes from 'prop-types'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

function SelectField({
    nameSelect,
    localization,
    isRequired,
    input,
    onUpdate,
    meta,
    children,
    items,
    componentId,
    pagePath,
    ...props
}) {
    const { t } = useTranslation()
    const hasErrors = meta.touched && meta.invalid
    const helperText = hasErrors && (meta.error || meta.submitError)
    const handleChange = (event) => {
        if (onUpdate) {
            onUpdate(event.target.value)
        }
        input.onChange(event.target.value)
    }

    const inputProps = {
        ...input,
        onChange: handleChange,
    }
    const title = (
        <ComponentTooltip componentId={componentId} pagePath={pagePath}>
            <Typography>
                {t(`${localization}.${nameSelect}`)}
                {isRequired && <AsterisksRequiredField />}
            </Typography>
        </ComponentTooltip>
    )

    return (
        <FormControl sx={{ minWidth: 120 }} error={!!helperText}>
            {title}
            <Select
                {...inputProps}
                {...props}
                input={<BlackOutlineInput />}
                MenuProps={MenuProps}
            >
                {items?.map((item, key) => {
                    return (
                        <MenuItem key={key} value={item.value}>
                            {item.name}
                        </MenuItem>
                    )
                })}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    )
}

SelectField.propTypes = {
    nameSelect: Proptypes.string,
    localization: Proptypes.string,
    isRequired: Proptypes.bool,
    input: Proptypes.object,
    onUpdate: Proptypes.func,
    meta: Proptypes.object,
    children: Proptypes.object,
    items: Proptypes.array,
    componentId: Proptypes.string,
    pagePath: Proptypes.string,
}

export default SelectField
