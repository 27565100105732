import { useMutation, useQueryClient } from 'react-query'
import serviceCenterApi from '~/api/serviceCenterApi'
import { setSnackbar } from '~/store/snackbar/slice'
import { ADMINISTRATOR_DEFINITION_SERVICECENTER_DETAIL } from '~/constants/Routes'
import { useSupportObject } from '~/hooks/useSupportObject'
import addressApi from '~/api/addressApi'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useCreateServiceCenterMutation = () => {
    const { dispatch, navigate } = useSupportObject()
    return useMutation(
        ['service_center_create'],
        async (payload) => {
            return await serviceCenterApi.createServiceCenter(payload)
        },
        {
            onSuccess: (data) => {
                dispatch(
                    setSnackbar({
                        message: data.message
                            ? data.message
                            : 'Create successful!',
                        type: 'success',
                    }),
                )

                navigate(
                    ADMINISTRATOR_DEFINITION_SERVICECENTER_DETAIL.replace(
                        ':id',
                        data.data?.data.id,
                    ),
                )
                return data.data?.data
            },
            onError: (error) => {
                const message = error.response.data.message
                    ? error.response.data.message
                    : error.message
                      ? error.message
                      : 'Create failed!'
                dispatch(
                    setSnackbar({
                        message,
                        type: 'error',
                    }),
                )
            },
            retry: false,
        },
    )
}

export const useUpdateServiceCenterMutation = ({ showSnackbar }) => {
    const { id, dispatch, navigate } = useSupportObject()
    return useMutation(
        ['service_center_update'],
        async (payload) => {
            return await serviceCenterApi.updateServiceCenter(id, payload)
        },
        {
            onSuccess: (data) => {
                dispatch(
                    setSnackbar({
                        message: data.message
                            ? data.message
                            : 'Update successful!',
                        type: 'success',
                    }),
                )
                navigate(
                    ADMINISTRATOR_DEFINITION_SERVICECENTER_DETAIL.replace(
                        ':id',
                        id,
                    ),
                )
                return data
            },
            onError: (error) =>
                showSnackbar(
                    error.message ? error.message : 'Save failed!',
                    'error',
                ),
            retry: false,
        },
    )
}

export const useCreateAddressMutation = ({ setOpenAddAddress }) => {
    const { id, clientQuery } = useSupportObject()
    return useMutation(
        ['address_create'],
        async (payload) => {
            return await addressApi.createAddress(id, payload)
        },
        {
            onSuccess: (data) => {
                clientQuery.invalidateQueries('address')
                setOpenAddAddress(false)
            },

            retry: false,
        },
    )
}

export const useWmsEnableMutation = ({ id, showSnackbar }) => {
    const clientQuery = useQueryClient()
    return useMutation(
        ['wms_enable_flag'],
        async (payload) => {
            const data = await serviceCenterApi.updateWmsEnable(id, payload)
            return data
        },
        {
            onSuccess: (data) => {
                clientQuery.invalidateQueries(['service_center_detail', id])
                showSnackbar(
                    data?.message ?? 'successfully updated!',
                    'success',
                )
            },
            onError: (e) => {
                showSnackbar(parseApiErrorMessage(e), 'error')
            },
            retry: false,
        },
    )
}

export const useUpdateSeparateTriageMutation = ({ id }) => {
    const clientQuery = useQueryClient()
    const { dispatch } = useSupportObject()

    return useMutation(
        ['update_separate_triage'],
        async (payload) => {
            const data = await serviceCenterApi.updateSeparateTriage(
                id,
                payload,
            )
            return data
        },
        {
            onSuccess: (data) => {
                clientQuery.invalidateQueries(['service_center_detail', id])
                dispatch(
                    setSnackbar({
                        message: data?.message ?? 'successfully updated!',
                        type: 'success',
                    }),
                )
            },
            onError: (e) => {
                dispatch(
                    setSnackbar({
                        message: parseApiErrorMessage(e),
                        type: 'error',
                    }),
                )
            },
            retry: false,
        },
    )
}
