import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material'
import { Box, Button, Grid, IconButton } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import DeleteConfirmPopup from '~/components/DeleteConfirmPopup'
import ImageList from '~/components/Image/ImageList'
import PictureCapturePopup from '~/components/PictureCapturePopup'
import { selectDevice } from '~/store/device/selector'
import StyledModal from '../../../../components/StyledModal'
import UploadPicture from './Component/UploadPicture'
import {
    useDeleteAttachmentMutation,
    useUploadCapturePictureMutation,
} from './mutation'
import { useDeviceImageQuery } from './query'

const DevicePictures = () => {
    const [openUploadPicture, setOpenUploadPicture] = useState(false)
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
    const [deletePictureId, setDeletePictureId] = useState()
    const [selectedFiles, setSelectedFiles] = useState([])
    const [open, setOpen] = useState(false)
    const { mutate: uploadPicture, isLoading: isPictureLoading } =
        useUploadCapturePictureMutation()

    const {
        mutate: deleteDevicePicture,
        isSuccess,
        isLoading,
    } = useDeleteAttachmentMutation()

    const { t } = useTranslation()

    const device = useSelector(selectDevice)

    const attachments = useMemo(() => {
        const images = device?.media
            ? device?.media.filter((x) => x.collection_name === 'images')
            : []

        return images.length > 0
            ? images.sort((item1, item2) => {
                  return item2.id - item1.id
              })
            : []
    }, [device])

    const deletePicture = (id) => {
        setOpenDeleteConfirm(true)
        setDeletePictureId(id)
    }

    const handleClose = () => {
        setOpenDeleteConfirm(false)
    }

    useEffect(() => {
        if (isSuccess) {
            setOpenDeleteConfirm(false)
        }
    }, [isSuccess])

    const { id } = useParams()
    const { data: deviceImages } = useDeviceImageQuery(id)
    const [imageId, setImageId] = useState(false)

    const data = useMemo(() => {
        if (
            typeof deviceImages === 'undefined' ||
            typeof attachments === 'undefined'
        ) {
            return []
        }
        const images = deviceImages.concat(attachments)
        images.sort((a, b) => {
            return new Date(a.created_at) - new Date(b.created_at)
        })

        return images.map((image) => {
            const clonedImage = { ...image }
            clonedImage.title = image.name
            if (clonedImage.collection_name === 'diagnostic-photos') {
                clonedImage.title = `Diagnostic Issue ID: ${image.model_id}`
            } else if (clonedImage.collection_name === 'quality-check-photos') {
                clonedImage.title = `QC Issue ID: ${image.model_id}`
            }
            return clonedImage
        })
    }, [deviceImages, attachments])

    const onSubmit = useCallback(() => {
        const payload = selectedFiles?.map((file) => {
            return file.image
        })

        uploadPicture(payload, {
            onSuccess: () => {
                setOpen(false)
                setSelectedFiles([])
            },
        })
    }, [selectedFiles, uploadPicture])

    return (
        <>
            {openUploadPicture && (
                <UploadPicture
                    {...{ openUploadPicture, setOpenUploadPicture }}
                />
            )}
            <Button
                sx={{ textTransform: 'none', fontWeight: 'normal' }}
                onClick={() => {
                    setOpenUploadPicture(true)
                }}
            >
                {t('button.uploadPicture')}
            </Button>

            <Button
                sx={{ textTransform: 'none', fontWeight: 'normal' }}
                onClick={() => {
                    setOpen(true)
                }}
            >
                {t('button.capturePicture')}
            </Button>

            <Grid container>
                {data.length > 0 && (
                    <ImageList
                        images={data}
                        handleRemoveImage={deletePicture}
                        isFinish={false}
                        imageListProps={{
                            cols: 6,
                            rowHeight: 140,
                        }}
                        isPictureFromQC={true}
                    />
                )}
            </Grid>
            <DeleteConfirmPopup
                {...{ openDeleteConfirm, handleClose, isLoading }}
                handleDelete={() => {
                    deleteDevicePicture(deletePictureId)
                }}
                informationDialog={{
                    title: `${t('dialog.deletePicture')}`,
                    content: `${t('dialog.deletePictureContent')}`,
                }}
            />
            <PictureCapturePopup
                {...{
                    open,
                    selectedFiles,
                    setSelectedFiles,
                    isLoading: isPictureLoading,
                    currentImageList:
                        data &&
                        data.map((image) => {
                            return {
                                src: image.preview_url,
                                id: image.id,
                                checked: false,
                                is_current: true,
                            }
                        }),
                    onSubmit,
                    onClose: () => {
                        setOpen(false)
                        setSelectedFiles([])
                    },
                    imageLimit: 5,
                    handleRemoveCurrent: (item) => {
                        deletePicture(item.id)
                    },
                }}
            />
            <StyledModal
                open={typeof imageId === 'number'}
                handleClose={() => setImageId(false)}
                stackProps={{
                    sx: { width: 1400, height: 800, position: 'relative' },
                }}
            >
                <Box>
                    <img
                        src={data[imageId]?.preview_url ?? ''}
                        alt={data[imageId]?.file_name ?? ''}
                        style={{
                            objectFit: 'cover',
                            maxWidth: 1300,
                        }}
                    />
                </Box>

                {imageId !== false && parseInt(`${imageId}`) !== 0 && (
                    <IconButton
                        sx={{
                            position: 'absolute',
                            left: '10px',
                            top: '50%',
                        }}
                        onClick={() =>
                            setImageId((prev) => (prev === 0 ? prev : prev - 1))
                        }
                    >
                        <ArrowBackIosNew />
                    </IconButton>
                )}

                {imageId !== false &&
                    parseInt(`${imageId}`) !== data?.length - 1 && (
                        <IconButton
                            sx={{
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                            }}
                            onClick={() =>
                                setImageId((prev) =>
                                    prev === data?.length - 1 ? prev : prev + 1,
                                )
                            }
                        >
                            <ArrowForwardIos />
                        </IconButton>
                    )}
            </StyledModal>
        </>
    )
}

export default DevicePictures
