import { Box, Button, Stack, Toolbar as MuiToolbar } from '@mui/material'
import ToolBarButton from '~/components/ToolBarButton'
import { useTranslation } from 'react-i18next'
import { useRevokeServiceAccountMutation } from './mutation'
import PropTypes from 'prop-types'

const Toolbar = ({ localization, showSnackbar, setTokenValue }) => {
    const { t } = useTranslation()
    const { mutate: revokeServiceAccountMutate } =
        useRevokeServiceAccountMutation({ showSnackbar, setTokenValue })
    return (
        <>
            <MuiToolbar disableGutters={false}>
                <Box sx={{ flexGrow: 1 }}></Box>

                <Stack
                    direction='row'
                    justifyContent='right'
                    alignItems='right'
                    minWidth='46px'
                    spacing={2}
                >
                    <ToolBarButton
                        handleAction={revokeServiceAccountMutate}
                        name={t(`${localization}.revoke`)}
                    />
                    <Button
                        variant='contained'
                        size='small'
                        sx={{
                            textTransform: 'none',
                            backgroundColor: '#76B72A',
                        }}
                        type={`submit`}
                    >
                        {t(`${localization}.save`)}
                    </Button>
                </Stack>
            </MuiToolbar>
        </>
    )
}

Toolbar.propTypes = {
    localization: PropTypes.string,
    showSnackbar: PropTypes.func,
    setTokenValue: PropTypes.func,
}

export default Toolbar
