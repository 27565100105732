import CustomTable from '~/components/Table'
import { useIssuesQuery } from './query'
import PropTypes from 'prop-types'

const localization = `pages.issues.overview.`

const issueTableHeader = [
    {
        id: 'id',
        numeric: true,
        disablePadding: false,
        label: 'id',
    },
    {
        id: 'ticket_id',
        numeric: true,
        disablePadding: false,
        label: 'ticket_id',
        orderable: true,
    },
    {
        id: 'esd_location',
        numeric: true,
        disablePadding: false,
        label: 'esd_location',
        orderable: true,
    },
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'name',
        orderable: true,
    },
    {
        id: 'description',
        numeric: true,
        disablePadding: false,
        label: 'description',
        orderable: true,
    },
    {
        id: 'service_id',
        numeric: true,
        disablePadding: false,
        label: 'service_id',
        orderable: true,
    },
    {
        id: 'service_center_tid',
        numeric: true,
        disablePadding: false,
        label: 'service_center_tid',
        orderable: true,
    },
    {
        id: 'brand',
        numeric: true,
        disablePadding: false,
        label: 'brand',
        orderable: true,
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'status',
        orderable: true,
    },
    {
        id: 'state',
        numeric: true,
        disablePadding: false,
        label: 'state',
        orderable: true,
    },
    {
        id: 'school_id',
        numeric: true,
        disablePadding: false,
        label: 'school_id',
        orderable: true,
    },
    {
        id: 'device_user_id',
        numeric: true,
        disablePadding: false,
        label: 'device_user_id',
        orderable: true,
    },
    {
        id: 'contract_id',
        numeric: true,
        disablePadding: false,
        label: 'contract_id',
        orderable: true,
    },
    {
        id: 'damaged',
        numeric: true,
        disablePadding: false,
        label: 'damaged',
        orderable: true,
        dataType: 'boolean',
    },
    {
        id: 'damage_description',
        numeric: true,
        disablePadding: false,
        label: 'damage_description',
        orderable: true,
    },
    {
        id: 'powers_on',
        numeric: true,
        disablePadding: false,
        label: 'powers_on',
        orderable: true,
        dataType: 'boolean',
    },
    {
        id: 'liquid_damage',
        numeric: true,
        disablePadding: false,
        label: 'liquid_damage',
        orderable: true,
        dataType: 'boolean',
    },
    {
        id: 'cause_tid',
        numeric: true,
        disablePadding: false,
        label: 'cause_tid',
        orderable: true,
    },
    {
        id: 'accessories',
        numeric: true,
        disablePadding: false,
        label: 'accessories',
        orderable: true,
    },
    {
        id: 'loaner_id',
        numeric: true,
        disablePadding: false,
        label: 'loaner_id',
        orderable: true,
    },
    {
        id: 'webshop_id',
        numeric: true,
        disablePadding: false,
        label: 'webshop_id',
        orderable: true,
    },
    {
        id: 'image_version_tid',
        numeric: true,
        disablePadding: false,
        label: 'image_version_tid',
        orderable: true,
    },
    {
        id: 'language',
        numeric: true,
        disablePadding: false,
        label: 'language',
        orderable: true,
    },
    {
        id: 'entity_tid',
        numeric: true,
        disablePadding: false,
        label: 'entity_tid',
        orderable: true,
    },
    {
        id: 'reject_reason',
        numeric: true,
        disablePadding: false,
        label: 'reject_reason',
        orderable: true,
    },
    {
        id: 'previous_id',
        numeric: true,
        disablePadding: false,
        label: 'previous_id',
        orderable: true,
    },
    {
        id: 'created_at',
        numeric: true,
        disablePadding: false,
        label: 'created_at',
        orderable: true,
        dataType: 'datetime',
    },
]
const DeviceIssueList = ({ device }) => {
    return (
        <CustomTable
            id={device?.id.toString()}
            customQuery={useIssuesQuery}
            headCells={issueTableHeader}
            LOCALIZATION={localization}
        />
    )
}

DeviceIssueList.propTypes = {
    device: PropTypes.object,
}

export default DeviceIssueList
