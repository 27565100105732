import { PART, PART_COMPONENT } from '~/constants/Routes'
import { Layout } from '~/pages/Ticket/components'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { Paper, Table, TableContainer } from '@mui/material'
import TableHeader from '~/components/TableHeader'
import PaginationTable from '~/components/PaginationTable'
import { useComponentDetailQuery, useComponentDevicesQuery } from '../query'
import { ComponentDetailHeadCells } from '../constants'
import ComponentDetailBodyTable from './ComponentDetailTable'
import ListToolbarWrapper from '~/components/ListToolbarWrapper'
import useTable from '~/hooks/useTable'
import FilterSearch from '~/components/Search/FilterSearch'
import { useTranslation } from 'react-i18next'
import ItemList from '../components/ItemList.jsx.jsx'
import PageName from '~/components/PageName'

const localization = 'pages.components.'

const ComponentDetail = () => {
    const { data: componentDetail } = useComponentDetailQuery()
    const {
        order,
        page,
        orderBy,
        data,
        isLoading,
        results,
        sortHandler,
        searchHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useComponentDevicesQuery,
        initialStates: { orderBy: '' },
    })
    const { id } = useParams()
    const { t } = useTranslation()

    const breadCrumbConfig = [
        { route: PART, title: 'Parts' },
        { route: PART_COMPONENT, title: 'Components' },
        { page: 'detail', title: id },
    ]

    const pageTitle = useMemo(() => {
        if (componentDetail) {
            return `Part #: ${componentDetail.part_number}, ${componentDetail.item_description}`
        }
        return ''
    }, [componentDetail])

    return (
        <Layout breadcrumbs={breadCrumbConfig} pageName={pageTitle}>
            <ListToolbarWrapper />
            <Paper sx={{ width: '100%', mb: 2 }}>
                <FilterSearch
                    placeholder={`${t(`general.searchFor`)} ${t(
                        `general.deviceName`,
                    )}`}
                    onSearchChange={searchHandler}
                    onEnterSearch={onEnterSearch}
                />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby='tableTitle'
                        size='medium'
                    >
                        <TableHeader
                            headCells={ComponentDetailHeadCells}
                            localization={localization}
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                        />
                        <ComponentDetailBodyTable
                            {...{ isLoading }}
                            componentDetail={results}
                        />
                    </Table>
                </TableContainer>
                <PaginationTable
                    data={data}
                    page={page}
                    handleChangePage={changePageHandler}
                    size='medium'
                />
            </Paper>
            <Paper
                sx={{
                    marginTop: 10,
                    px: 2,
                    py: 1,
                }}
            >
                <PageName name={'Item Numbers List'} />
                <ItemList
                    items={componentDetail?.item_numbers || []}
                ></ItemList>
            </Paper>
        </Layout>
    )
}

export default ComponentDetail
