import { Grid, Typography } from '@mui/material'
import StyledModal from '~/components/StyledModal'
import InputFile from '~/pages/Device/Serial/SerialDetail/Component/InputFile'
import SaveButtonPopup from '~/pages/Device/Serial/SerialDetail/Component/SaveButtonPopup'
import UploadModalTitle from '~/pages/Device/Serial/SerialDetail/Component/UploadModalTitle'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'
import { usePictureMutation } from './mutation'
import { setIsOpen } from '~/store/diagnose/slice'
import { useDispatch } from 'react-redux'
import { LOCALIZATION } from './constants'
import { useTranslation } from 'react-i18next'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { parseApiErrorMessage } from '~/utils/helpers'

const UploadDiagnosticPicturePopup = ({
    open,
    setOpenUploadPicture,
    refetch,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { openSnackbar } = useDialogContext()
    const { mutate: pictureMutate, isLoading: isPictureLoading } =
        usePictureMutation()
    const handleSubmit = (values) => {
        pictureMutate([{ image: values.pictures.item(0), upload: true }], {
            onSettled: () => {
                refetch()
                setOpenUploadPicture(false)
                dispatch(setIsOpen(true))
            },
            onError: (e) => {
                openSnackbar({
                    message: parseApiErrorMessage(e),
                    type: 'error',
                })
            },
        })
    }

    const handleClose = () => {
        setOpenUploadPicture(false)
    }

    return (
        <StyledModal open={open} handleClose={() => handleClose()}>
            <Grid container spacing={0.5}>
                <UploadModalTitle
                    componentId={'upload_picture_popup_title'}
                    title={t(`${LOCALIZATION}uploadPicture`)}
                />
                <Grid item xs={12} sm={12}>
                    <Form
                        onSubmit={handleSubmit}
                        render={({ handleSubmit, form }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid item xs={12}>
                                    <Typography>File</Typography>
                                    <InputFile
                                        form={form}
                                        nameField='pictures'
                                    />
                                </Grid>
                                <SaveButtonPopup
                                    {...{ isLoading: isPictureLoading }}
                                />
                            </form>
                        )}
                    />
                </Grid>
            </Grid>
        </StyledModal>
    )
}

UploadDiagnosticPicturePopup.propTypes = {
    open: PropTypes.bool,
    setOpenUploadPicture: PropTypes.func,
    refetch: PropTypes.func,
}

export default UploadDiagnosticPicturePopup
