import AccordionsButton from '~/components/AccordianButton'
import ListToolbarWrapper from '~/components/ListToolbarWrapper'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const PurchaseOrderLineToolbar = ({ showAll, hideAll }) => {
    const { t } = useTranslation()
    return (
        <ListToolbarWrapper>
            <AccordionsButton onClick={hideAll}>
                {t(`pages.dispatches.dispatch.detail.hideAll`)}
            </AccordionsButton>
            <AccordionsButton onClick={showAll}>
                {t(`pages.dispatches.dispatch.detail.showALl`)}
            </AccordionsButton>
        </ListToolbarWrapper>
    )
}

PurchaseOrderLineToolbar.propTypes = {
    showAll: PropTypes.func,
    hideAll: PropTypes.func,
}

export default PurchaseOrderLineToolbar
