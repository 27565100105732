import DownloadIcon from '@mui/icons-material/Download'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import { MenuItem } from '@mui/material'
import ActionMenu from '~/components/ActionMenu'
import ListToolbarWrapper from '~/components/ListToolbarWrapper'
import { useTranslation } from 'react-i18next'
import { useExportFleetMutation } from './mutate'
import PropTypes from 'prop-types'

const FleetListToolbar = ({ payload }) => {
    const { mutate: exportFleet, isLoading } = useExportFleetMutation()
    const { t } = useTranslation()

    return (
        <ListToolbarWrapper>
            <ActionMenu>
                <MenuItem
                    onClick={() => exportFleet(payload)}
                    disabled={isLoading}
                >
                    {isLoading ? <HourglassTopIcon /> : <DownloadIcon />}
                    {t('button.export')}
                </MenuItem>
            </ActionMenu>
        </ListToolbarWrapper>
    )
}

FleetListToolbar.propTypes = { payload: PropTypes.object }

export default FleetListToolbar
