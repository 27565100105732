import { useSelector } from 'react-redux'
import { selectTeam } from '~/store/team/selector'
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material'
import TeamPlaceHolder from '../../../assets/teams/team_placeholder.png'

const TeamIntroduce = () => {
    const team = useSelector(selectTeam)
    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardMedia
                component='img'
                height='140'
                image={
                    team.media?.preview_url
                        ? `${team.media?.preview_url}`
                        : TeamPlaceHolder
                }
                alt='team_image'
            />
            <CardContent>
                <Typography gutterBottom variant='h5' component='div'>
                    {team.name}
                </Typography>
                <Box maxHeight={80} mb={1} overflow='auto'>
                    <Typography variant='body2' color='text.secondary'>
                        {team.description}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    )
}

export default TeamIntroduce
