export const headCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: false,
        label: 'id',
        orderable: true,
    },
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'name',
        orderable: false,
    },
    {
        id: 'description',
        numeric: true,
        disablePadding: false,
        label: 'description',
        orderable: false,
    },
    {
        id: 'service_center_id',
        numeric: true,
        disablePadding: false,
        label: 'serviceCenter',
        orderable: false,
    },
    {
        id: 'created_at',
        numeric: true,
        disablePadding: false,
        label: 'created_at',
        orderable: true,
    },
    {
        id: 'active',
        numeric: true,
        disablePadding: false,
        label: 'active',
        orderable: true,
    },
    {
        id: 'action',
        numeric: true,
        disablePadding: false,
        label: 'action',
        orderable: false,
    },
]
