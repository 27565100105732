import axiosClient from '~/api/base'

class ProjectsApi {
    getProjects = (params) => {
        return axiosClient.get('api/production/projects', { params })
    }

    getProjectsAddons = (id) => {
        return axiosClient.get(`api/production/projects-addons/${id}`)
    }

    getProject = (project_nr) => {
        return axiosClient.get(`api/production/projects/${project_nr}`)
    }

    getListAddons = (id) => {
        return axiosClient.get(`api/production/list-addons/${id}`)
    }

    getDataForFillter = () => {
        return axiosClient.get('api/production/list-data-filter-by-project')
    }

    getDataForInsertProject = () => {
        return axiosClient.get('api/production/list-data-for-insert-project')
    }

    assignAddonToProject = (payload) => {
        return axiosClient.post(
            'api/production/assign-addon-to-project',
            payload,
        )
    }

    importProject = (payload) => {
        return axiosClient.post('api/production/imprort-project', payload)
    }

    updateProject = ({ id, payload }) => {
        return axiosClient.post(`api/production/update-project/${id}`, payload)
    }

    editProjectAddon = (payload) => {
        return axiosClient.post('api/production/edit-project-addon', payload)
    }

    deleteProjectAddon = (payload) => {
        return axiosClient.post('api/production/delete-project-addon', payload)
    }
}

export default new ProjectsApi()
