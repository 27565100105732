import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import DataNotFound from '~/components/DataNotFound'
import TableLoading from '~/components/Table/TableLoading'
import SectionTitle from './components/SectionTitle'
import StyledTableCell from './components/StyledTableCell'
import StyledTableRow from './components/StyledTableRow'
import { LOCALIZATION } from './constants'
import useStyles from './hooks/useStyles'
import { useDeviceImagesQuery } from './query'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const DeviceImages = ({ isBeforeRepair = true, title }) => {
    const { t } = useTranslation()
    return (
        <Grid container>
            <SectionTitle
                title={t(LOCALIZATION + title)}
                xs={12}
                md={12}
                lg={12}
                align='center'
            />
            <DeviceImagesTable isBeforeRepair={isBeforeRepair} />
        </Grid>
    )
}

DeviceImages.propTypes = {
    isBeforeRepair: PropTypes.bool,
    title: PropTypes.string,
}

const DeviceImagesTable = ({ isBeforeRepair }) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    const { issueId } = useParams()
    const { data, isLoading } = useDeviceImagesQuery({
        id: issueId,
        isBeforeRepair,
    })

    const headerTable = ['BIOS', 'schoolID', 'projectID', 'timestamp']

    return (
        <Table component={Paper} className={classes.tableContainer}>
            <TableHead>
                <TableRow>
                    {headerTable.map((item) => (
                        <StyledTableCell
                            align='center'
                            key={item}
                            className={clsx(
                                item === 'timestamp' && classes.minSizeDateTime,
                            )}
                        >
                            {t(LOCALIZATION + item)}
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {isLoading ? (
                    <TableLoading />
                ) : data?.data?.id ? (
                    <StyledTableRow>
                        <TableCell className={classes.tableCell}>
                            {data.data?.bios}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            {data.data?.school_id || 'N/A'}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            {data.data?.project_id || 'N/A'}
                        </TableCell>
                        <TableCell
                            className={clsx(
                                classes.tableCell,
                                classes.minSizeDateTime,
                            )}
                        >
                            <DateTimeLocale datetime={data.data?.timestamp} />
                        </TableCell>
                    </StyledTableRow>
                ) : (
                    <DataNotFound colSpan={headerTable.length} />
                )}
            </TableBody>
        </Table>
    )
}
DeviceImagesTable.propTypes = {
    isBeforeRepair: PropTypes.bool,
}

export default DeviceImages
