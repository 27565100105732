import { createSlice } from '@reduxjs/toolkit'
import { invokeSsoLogin } from './action'

export const userSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        ssoRedirectUrl: null,
        userPermission: [],
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
        },
        setUserPermissions: (state, action) => {
            state.userPermission = action.payload
        },
        logout: (state) => {
            state.user = null
        },
        setUserTimeZone: (state, action) => {
            state.user = { ...state.user, timezone: action.payload }
        },
    },
    extraReducers: {
        [invokeSsoLogin.fulfilled]: (state, action) => {
            state.ssoRedirectUrl = action.payload
        },
    },
})

export const { logout, setUser, setUserPermissions, setUserTimeZone } =
    userSlice.actions

export default userSlice
