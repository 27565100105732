import { createSlice } from '@reduxjs/toolkit'

export const dispatchSlice = createSlice({
    name: 'dispatch',
    initialState: {
        dispatch: null,
        dispatchState: null,
        dispatchSerial: null,
    },
    reducers: {
        setDispatchDetail: (state, action) => {
            state.dispatch = action.payload
        },
        setDispatchState: (state, action) => {
            state.dispatchState = action.payload
        },
        setDispatchSerial: (state, action) => {
            state.dispatchSerial = action.payload
        },
    },
})

export const { setDispatchDetail, setDispatchState, setDispatchSerial } =
    dispatchSlice.actions

export default dispatchSlice
