import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import ListToolbarWrapper from '~/components/ListToolbarWrapper'
import PageName from '~/components/PageName'
import {
    ISSUE_DISPATCH,
    ISSUE_DISPATCH_IMPORT_EXPORT,
    ISSUE_ISSUE,
} from '~/constants/Routes'
import { Grid } from '@mui/material'
import ImportDispatch from './ImportDispatch'
import ToolBarButton from '~/components/ToolBarButton'
import { Download, HourglassTop } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useExportLenovoDispatchMutation } from '~/pages/Dispatch/mutate'

const BreadComponents = [
    { route: ISSUE_ISSUE, title: 'Issues' },
    {
        route: ISSUE_DISPATCH,
        title: 'Dispatches',
    },
    {
        route: '#',
        title: 'Import/Export',
    },
]
const DispatchesImportExport = () => {
    const { t } = useTranslation()
    const { mutate: exportLenovoDispatches, isLoading } =
        useExportLenovoDispatchMutation()

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ISSUE_DISPATCH_IMPORT_EXPORT}
            >
                <div>
                    <PageName name={'Dispatch Import/Export'} />
                </div>
            </ComponentTooltip>
            <ListToolbarWrapper>
                <ToolBarButton
                    disabled={isLoading}
                    icon={isLoading ? <HourglassTop /> : <Download />}
                    name={t(`button.export`)}
                    handleAction={() => exportLenovoDispatches()}
                />
            </ListToolbarWrapper>
            <Grid container spacing={3}>
                <ImportDispatch />
            </Grid>
        </>
    )
}
export default DispatchesImportExport
