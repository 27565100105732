import {
    Box,
    Divider,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'

import DataNotFound from '~/components/DataNotFound'

import TableHeader from '~/components/TableHeader'

import ExternalLink from '~/components/ExternalLink'
import { InvoiceHeadCells } from '~/components/HeaderTable/InvoiceHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import { COPACO_INVOICE_NUMBER } from '~/constants/externalLink'
import useTable from '~/hooks/useTable'
import { useInvoicesQuery } from '../query'
import InvoiceListToolbar from './InvoiceListToolbar'
import InvoiceTableFilters from './InvoiceTableFilters'
import PropTypes from 'prop-types'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'
import { ReactComponent as OcrInvoice } from '~/assets/images/devices/ocr.svg'
import { useLocation } from 'react-router-dom'

const localization = 'pages.procurement.invoice.overview.'

const InvoiceBodyTable = ({ isLoading, invoices }) => {
    if (isLoading) {
        return <TableLoading colSpan={InvoiceHeadCells.length} />
    }

    return (
        <TableBody>
            {invoices.length > 0 ? (
                invoices.map((row) => {
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                            <TableCell align='left'>
                                {row.supplier_name}
                            </TableCell>
                            <TableCell align='left'>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <ExternalLink
                                        extLink={COPACO_INVOICE_NUMBER}
                                        param={row.invoice_number.toString()}
                                        tailParam={`&DocumentType=FAC`}
                                    />
                                    {row.invoice_ocr_processed !== null && (
                                        <Link
                                            target='_blank'
                                            underline='none'
                                            href={
                                                row.invoice_pdf_ocr_version_link
                                            }
                                        >
                                            <OcrInvoice
                                                style={{ height: '1rem' }}
                                            />
                                        </Link>
                                    )}
                                </Box>
                            </TableCell>
                            <TableCell align='left'>
                                {row.invoice_amount_incl}
                            </TableCell>
                            <TableCell align='left'>
                                {row.invoice_amount_excl}
                            </TableCell>
                            <TableCell align='left'>
                                <DateTimeLocale
                                    datetime={row.invoice_date}
                                    formatHour={true}
                                />
                            </TableCell>
                            <TableCell align='left'>
                                <DateTimeLocale
                                    datetime={row.invoice_expiration_date}
                                    formatHour={true}
                                />
                            </TableCell>
                            <TableCell align='left'>{row.to_number}</TableCell>
                            <TableCell align='left'>{row.to_name}</TableCell>
                            <TableCell align='left'>{row.to_city}</TableCell>
                            <TableCell align='left'>{row.to_country}</TableCell>
                            <TableCell align='left'>
                                {row.invoice_customer.customer_name1 && (
                                    <Box component='span'>{`${row.invoice_customer.customer_name1}`}</Box>
                                )}
                                {row.invoice_customer.customer_country && (
                                    <Box component='span'>{`Country: ${row.invoice_customer.customer_country}`}</Box>
                                )}
                            </TableCell>
                            <TableCell align='left'>
                                <DateTimeLocale datetime={row.created_at} />
                            </TableCell>
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={InvoiceHeadCells.length} />
            )}
        </TableBody>
    )
}

InvoiceBodyTable.propTypes = {
    isLoading: PropTypes.bool,
    invoices: PropTypes.arrayOf(PropTypes.object),
}

const InvoiceTable = () => {
    const [supplier, setSupplier] = useState('')
    const location = useLocation()
    const invoiceNumber = new URLSearchParams(location.search).get('invoice_nr')

    const {
        results: invoices,
        data,
        isLoading,
        search,
        page,
        setPage,
        setSearch,
        order,
        orderBy,
        sortHandler,
        searchHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useInvoicesQuery,
        queryAttributes: {
            supplier,
        },
    })

    const refValue = useRef({
        supplier: '',
    })

    useEffect(() => {
        if (supplier !== refValue.current.supplier) {
            setPage(1)
            refValue.current.supplier = supplier
        }
        if (invoiceNumber) {
            setPage(1)
            setSearch(invoiceNumber)
        }
    }, [supplier, setPage, invoiceNumber, setSearch])

    return (
        <>
            <InvoiceListToolbar
                payload={{ supplier, search, order, orderBy }}
            />

            <Paper sx={{ width: '100%', mb: 2 }}>
                <InvoiceTableFilters
                    search={invoiceNumber ?? search}
                    searchHandler={searchHandler}
                    supplier={supplier}
                    setSupplier={setSupplier}
                    onEnterSearch={onEnterSearch}
                />
                <Divider />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby='tableTitle'
                        size='medium'
                    >
                        <TableHeader
                            headCells={InvoiceHeadCells}
                            localization={localization}
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                        />
                        <InvoiceBodyTable
                            isLoading={isLoading}
                            invoices={invoices}
                        />
                    </Table>
                </TableContainer>
                <PaginationTable
                    data={data}
                    page={page}
                    handleChangePage={changePageHandler}
                    size='medium'
                />
            </Paper>
        </>
    )
}

export default InvoiceTable
