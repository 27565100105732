import { ISSUE_RACKSCAN_SCAN_DETAIL } from '~/constants/Routes'

export const RackScanHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'id',
        orderable: true,
        clickable: true,
        link: ISSUE_RACKSCAN_SCAN_DETAIL,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        orderable: true,
    },
    {
        id: 'service_center_id',
        numeric: false,
        disablePadding: false,
        label: 'serviceCenter',
        orderable: false,
        customVal: (row) => row.service_center?.name,
    },
    {
        id: 'team_id',
        numeric: false,
        disablePadding: false,
        label: 'team',
        orderable: false,
        customVal: (row) => row.team?.name,
    },
    {
        id: 'created_by',
        numeric: false,
        disablePadding: false,
        label: 'createBy',
        orderable: false,
        customVal: (row) => row.created_by_person?.name,
    },

    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'created',
        orderable: false,
        dataType: 'datetime',
    },
    {
        id: 'updated_at',
        numeric: false,
        disablePadding: false,
        label: 'updated',
        orderable: false,
        dataType: 'datetime',
    },
]
