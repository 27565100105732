import JsBarcode from 'jsbarcode'
import { useEffect } from 'react'
import PropTypes from 'prop-types'

const BarcodeGenerator = ({
    barcodeString = 'placeholder',
    barcodeKey = '',
    options = {},
    ...props
}) => {
    useEffect(() => {
        JsBarcode(`#barcode-${barcodeKey}`, barcodeString, {
            format: 'code128',
            ...options,
        })
    }, [barcodeKey, barcodeString, options])

    return (
        <div {...props}>
            <svg id={`barcode-${barcodeKey}`}></svg>
        </div>
    )
}

BarcodeGenerator.propTypes = {
    barcodeKey: PropTypes.string,
    barcodeString: PropTypes.string.isRequired,
    options: PropTypes.object,
}

export default BarcodeGenerator
